function positionBuffer (width, height, thickness) {
  const x = width/2
  const y = height
  const d = thickness
  const angle = Math.atan(y/x)
  const c = x-(d/Math.sin(angle))-(d/Math.tan(angle))
  const e = y-(d/Math.cos(angle*(Math.PI/180)))

  console.log(x,y,d)

  return [
    -x, 0, 0, 
    -c, 0, d,
    x, 0, 0,

    -c, 0, d,
    c, 0, d,
    x, 0, 0,

    -x, 0, 0,
    0, 0, y,
    -c, 0, d,

    -c, 0, d,
    0, 0, y,
    0, 0, e,

    x, 0, 0,
    c, 0, d,
    0, 0, y,

    c, 0, d,
    0, 0, e,
    0, 0, y,

    -c, 0, d,
    0, 0, e,
    c, 0, d,
  ]
}

// defines position of the textures
function uvBuffer () { 
  return [
    // top
    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,

    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,

    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,

    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,

    1, 0,
    0, 1,
    1, 1,

    1, 0,
    0, 0,
    0, 1,
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  return [
    // top
    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,
  ]
}

function groupBuffer () {
  return [
    {
      start: 0,
      count: 18,
      materialIndex: 0
    },
    {
      start: 18,
      count: 3,
      materialIndex: 1
    }
  ]
}

export { positionBuffer, uvBuffer, normalBuffer, groupBuffer }