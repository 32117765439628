import * as graphql from '@/functions/graphql.js'
import * as errorHandeling from '@/functions/errorHandeling.js'

const state = {
  CMSComponents: [],
  menuColors: [],
  menuGlasTypes: [],
  menuInfoboxSettings: [],
  menuUISettings: [],
  textures: null,
  plankTypes: null,  
  plankLocations: null,
  newComponent: null,
  types: null,
  infoboxes: null, // for the dropdown
  modelCategories:null,
  models: null,
  bergingTypes: null,
  schuifwandTypes: [],
  schuifwandWidthRanges: [],
  users: null,
  backups: null,
  materials: [],
  globalSettings: {}
}

const cleanBreedtes = (breedtes) => {
  for (let i = 0; i < breedtes.length; i++) {
    const element = breedtes[i];
    element.artikelNummer = cleanWhitespace(element.artikelNummer);
  }
  return breedtes;
}
function cleanWhitespace(text) {
  return text.replace(/\s+/gi, '');
}

const mutations = {
  setCMSComponents (state, data) {
    state.CMSComponents = data
  },

  updateCMSComponents (state, c) {
    const index = state.CMSComponents.findIndex((cms) => cms.id === c.id)
    state.CMSComponents[index] = c
  },

  addCMSComponent (state, c) {
    state.CMSComponents.push(c)
    state.newComponent = c
  },

  removeCMSComponent (state, id) {
    const cIndex = state.CMSComponents.findIndex((c) => c.id === id)
    state.CMSComponents.splice(cIndex, 1)
  },

  setMenuColors (state, data) {
    state.menuColors = data
  },

  setMenuGlasTypes (state, data) {
    state.menuGlasTypes = data
  },

  setMenuIsolatieTypes (state, data) {
    state.menuIsolatieTypes = data
  },

  setMenuInfoboxSettings (state, data) {
    state.menuInfoboxSettings = data
  },

  setMenuUISettings (state, data) {
    state.menuUISettings = data
  },

  setTextures (state, data) {
    state.textures = data
  },

  setTextureTypes (state, data) {
    state.textureTypes = data
  },

  setPlankTypes (state, data) {
    state.plankTypes = data
  },

  setPlankLocations (state, data) {
    state.plankLocations = data
  },

  setModelsInfo (state, data) {
    state.types = data.getTypes
    state.infoboxes = data.getInfoboxes
    state.modelCategories = data.getModelCategories
    state.bergingTypes = data.getBergingTypes
    state.models = data.getModels
  },

  setTypes (state, data) {
    state.types = data
  },

  setModelCategories (state, data) {
    state.modelCategories = data
  },

  setBergingTypes (state, data) {
    state.bergingTypes = data
  },

  setSchuifwandTypes (state, data) {
    state.schuifwandTypes = data
  },

  schuifwandWidthRanges (state, data) {
    state.schuifwandWidthRanges = data
  },

  setUsers (state, data) {
    state.users = data
  },

  setUser (state, data) {
    state.user = data
  },

  setBackups (state, data) {
    state.backups = data
  },

  setMaterials (state, data) {
    state.materials = data
  },

  setGlobalSettings (state, data) {
    state.globalSettings = data
  }
}

const actions = {
  async getCMSComponents (context) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getCMSComponents {
            id
            code
            combinedCode
            category
            glasTypes {
              name
              acronym
              code
            }
            name
            shortName
            variantShortName
            size
            trueOnline
            keyWords
            options {
              name
              subCode
            }
            colors {
              name
              hexCode
              code
            }
            dimensions {
              x
              y
              z
              xo
              yo
              zo
            }
            margins {
              xPos1
              xPos2
              xNeg1
              xNeg2
              yPos1
              yPos2
              yNeg1
              yNeg2
              zPos1
              zPos2
              zNeg1
              zNeg2
            }
            wallOptions {
              left
              center
              right
            }
            facadeOptions {
              left
              center
              right
            }
            variants {
              name
              code
            }
          }
        }
      `
    } // variants added to query for TASK-1438

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch components.')
    }

    context.commit('setCMSComponents', response.data.getCMSComponents)
  },

  /* -------------------------------------------------------------------------- */
  /*                             update menu kleuren                            */
  /* -------------------------------------------------------------------------- */

      async getMenuColors (context) {
        // config graphQL data
        const graphqlQuery = {
          query: `
            {
              getMenuColors {
                id
                name
                hexCode
                open
              }
            }
          `
        }

        

        // sending request to server
        const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

        // if error
        if (response.errors) {
          errorHandeling.throwError(context, response, 'Failed to fetch menu colors.')
        }

        context.commit('setMenuColors', response.data.getMenuColors)
        },

      async updateMenuColors (context, colors) {

        let inputColor = colors.map((x) => {
          return `{
            id: "${x.id}",
            name: "${x.name}", 
            hexCode: "${x.hexCode}",
            open: ${x.open}
          }`
        })

        // config graphQL data
        const graphqlQuery = {
          query: `
            mutation {
              updateMenuColors(colors: [${inputColor}]) {
                id
                name
                hexCode
                open
              }
            }
          `
        }

        // sending request to server
        const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

        // if error
        if (response.errors) {
          errorHandeling.throwError(context, response, 'Failed to update menu colors.')
        }

    context.commit('setMenuColors', response.data.updateMenuColors)
    await context.dispatch('updateDWTables', {
      succesText: 'Menu kleuren succesvol aangepast.',
      tableData: response.data.updateMenuColors,
      menuName: "updateMenuColors"
    })
  },

  /* ---------------------------- end menu kleuren ---------------------------- */


  /* -------------------------------------------------------------------------- */
  /*                             update menu glastypes                          */
  /* -------------------------------------------------------------------------- */
    
      async getMenuGlasTypes (context) {
        // config graphQL data
        const graphqlQuery = {
          query: `
            {
              getMenuGlasTypes {
                id
                name
                acronym
              }
            }
          `
        }


      // sending request to server
      const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

      // if error
      if (response.errors) {
        errorHandeling.throwError(context, response, 'Failed to fetch menu glastypes.')
      }

      context.commit('setMenuGlasTypes', response.data.getMenuGlasTypes)
      },

      async updateMenuGlasTypes (context, glasTypes) {

        let inputGlasType = glasTypes.map((x) => {
          return `{
            id: "${x.id}",
            name: "${x.name}", 
            acronym: "${x.acronym}"
          }`
        })

        // config graphQL data
        const graphqlQuery = {
          query: `
            mutation {
              updateMenuGlasTypes(glastypes: [${inputGlasType}]) {
                id
                name
                acronym
              }
            }
          `
        }

        // sending request to server
        const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

        // if error
        if (response.errors) {
          errorHandeling.throwError(context, response, 'Failed to update menu Glastypes.')
        }

        context.commit('setMenuGlasTypes', response.data.updateMenuGlasTypes)
        await context.dispatch('updateDWTables', {succesText: 'Menu Glastypes succesvol aangepast.', data: glasTypes, menuID: 1})
      },

  /* ---------------------------- end menu glastypes ---------------------------- */

/* -------------------------------------------------------------------------- */
  /*                             menu isolatietypes                          */
  /* -------------------------------------------------------------------------- */
    
  async getMenuIsolatieTypes (context) {
    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getMenuIsolatieTypes {
            id
            name
            description
          }
        }
      `
    }


  // sending request to server
  const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

  // if error
  if (response.errors) {
    errorHandeling.throwError(context, response, 'Failed to fetch menu isolatietypes.')
  }
  console.log('response.data',response.data);
  context.commit('setMenuIsolatieTypes', response.data.getMenuIsolatieTypes);
  },

  async updateMenuIsolatieTypes (context, isolatieTypes) {

    let inputIsolatieType = isolatieTypes.map((x) => {
      return `{
        id: "${x.id}",
        name: "${x.name}", 
        description: "${x.description}"
      }`
    })

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateMenuIsolatieTypes(isolatietypes: [${inputIsolatieType}]) {
            id
            name
            description
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update menu Isolatietypes.')
    }

    context.commit('setMenuIsolatieTypes', response.data.updateMenuIsolatieTypes)
    await context.dispatch('updateDWTables', {succesText: 'Menu Isolatietypes succesvol aangepast.', data: isolatieTypes, menuID: 1})
  },

/* ---------------------------- end menu isolatietypes ---------------------------- */

/* -------------------------------------------------------------------------- */
/*                            update menu infoboxsettings                     */
/* -------------------------------------------------------------------------- */  

      async getMenuInfoboxSettings (context) {
        // config graphQL data
        const graphqlQuery = {
          query: `
            {
              getMenuInfoboxSettings {
                id
                uiIndex
                name
                title
                header
                urlEnding
              }
            }
          `
        }


      // sending request to server
      const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

      // if error
      if (response.errors) {
        errorHandeling.throwError(context, response, 'Failed to fetch menu infoboxsettings.')
      }

      context.commit('setMenuInfoboxSettings', response.data.getMenuInfoboxSettings)
      },

      async updateMenuInfoboxSettings (context, infoBoxSettings) {
        // console.log('infoBoxSettings',infoBoxSettings)

        let inputInfo = infoBoxSettings.map((x) => {
          return `{
            id: "${x.id}",
            name: "${x.name}", 
            title: "${x.title}",
            header: "${x.header}",
          }`
        })

        // config graphQL data
        const graphqlQuery = {
          query: `
            mutation {
              updateMenuInfoboxSettings(infoboxsettings: [${inputInfo}]) {
                id
                uiIndex
                name
                title
                header
                urlEnding
              }
            }
          `
        }

        // sending request to server
        console.log('query', graphqlQuery)
        const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

        // if error
        if (response.errors) {
          errorHandeling.throwError(context, response, 'Failed to update menu infoboxsettings.')
        }

        context.commit('setMenuInfoboxSettings', response.data.updateMenuInfoboxSettings)
        // await context.dispatch('updateDWTables', {succesText: 'Menu infoboxsettings succesvol aangepast.', data: infoBoxSettings, menuID: 1})
      },

      async updateInfoboxImage (context, uploadObject) {
        // console.log('uploadObject',uploadObject)
        let input = `{
            id: "${uploadObject.id}",
            image: "${uploadObject.image}",
            urlEnding: "${uploadObject.urlEnding }",
          }`

        // config graphQL data
        const graphqlQuery = {
          query: `
            mutation {
              updateInfoboxImage(infoboxImageUpload: ${input}) {
                id
                uiIndex
                name
                title
                header
                urlEnding
              }
            }
          `
        }

        // sending request to server
        const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

        // if error
        if (response.errors) {
          errorHandeling.throwError(context, response, 'Failed to update infobox image.')
        }

        context.commit('setMenuInfoboxSettings', response.data.updateMenuInfoboxSettings)
        // await context.dispatch('updateDWTables', {succesText: 'Menu infoboxsettings succesvol aangepast.', data: infoBoxSettings, menuID: 1})
      },
/* ---------------------------- end menu infoboxsettings ------------------------- */


/* -------------------------------------------------------------------------- */
/*                            update menu uisettings                     */
/* -------------------------------------------------------------------------- */  

async getMenuUISettings (context) {
  // config graphQL data
  const graphqlQuery = {
    query: `
      {
        getMenuUISettings {
          id
          showProductDetails
          showPrice
          extraDetails {
            id
            text
          }
          extraQuestions {
            id
            text
            options {
              id
              text
            }
          }
        }
      }
    `
  }


// sending request to server
const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

// if error
if (response.errors) {
  errorHandeling.throwError(context, response, 'Failed to fetch menu uisettings.')
}

context.commit('setMenuUISettings', response.data.getMenuUISettings)
},

async updateMenuUISettings (context, uiSettings) {
  // console.log('uiSettings',uiSettings)

  let inputInfo = uiSettings.map((x) => {
    let extraDetails = x.extraDetails.map((detail) => {
      return `{
        id: "${detail.id}",
        text: "${detail.text}"
      }`
    }).join(',');

    let extraQuestions = x.extraQuestions.map((question) => {
      let options = question.options.map((option) => {
        return `{
          id: "${option.id}",
          text: "${option.text}"
        }`
      }).join(',');

      return `{
        id: "${question.id}",
        text: "${question.text}",
        options: [${options}]
      }`
    }).join(',');

    return `{
      id: "${x.id}",
      showProductDetails: ${x.showProductDetails}, 
      showPrice: ${x.showPrice},
      extraDetails: [${extraDetails}],
      extraQuestions: [${extraQuestions}]
    }`;
  });

  // config graphQL data
  const graphqlQuery = {
    query: `
      mutation {
        updateMenuUISettings(uisettings: [${inputInfo}]) {
          id
          showProductDetails
          showPrice
          extraDetails {
            id
            text
          }
          extraQuestions {
            id
            text
            options {
              id
              text
            }
          }
        }
      }
    `
  }

  // sending request to server
  console.log(graphqlQuery)
  const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

  // if error
  if (response.errors) {
    errorHandeling.throwError(context, response, 'Failed to update menu uisettings.')
  }

  context.commit('setMenuUISettings', response.data.updateMenuUISettings)
  // await context.dispatch('updateDWTables', {succesText: 'Menu uisettings succesvol aangepast.', data: uiSettings, menuID: 1})
},

// async updateInfoboxImage (context, uploadObject) {
//   // console.log('uploadObject',uploadObject)
//   let input = `{
//       id: "${uploadObject.id}",
//       image: "${uploadObject.image}",
//       urlEnding: "${uploadObject.urlEnding }",
//     }`

//   // config graphQL data
//   const graphqlQuery = {
//     query: `
//       mutation {
//         updateInfoboxImage(infoboxImageUpload: ${input}) {
//           id
//           uiIndex
//           name
//           title
//           header
//           urlEnding
//         }
//       }
//     `
//   }

//   // sending request to server
//   const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

//   // if error
//   if (response.errors) {
//     errorHandeling.throwError(context, response, 'Failed to update infobox image.')
//   }

//   context.commit('setMenuInfoboxSettings', response.data.updateMenuInfoboxSettings)
//   // await context.dispatch('updateDWTables', {succesText: 'Menu infoboxsettings succesvol aangepast.', data: infoBoxSettings, menuID: 1})
// },
/* ---------------------------- end menu uisettings ------------------------- */

/* -------------------------------------------------------------------------- */
/*                            update menu textures                            */
/* -------------------------------------------------------------------------- */

  async getTextures (context) {
    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getTextures {
            id
            name
            url
            textureType
            plankType
            color
            locations
            scalingX
            scalingY
            emissive
            emissiveColor
            metalness
            order
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch texture files.')
    }
    context.commit('setTextures', response.data.getTextures)
  },

  async getTextureTypes (context) {
    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getTextureTypes {
            id
            name
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch texture types.')
    }
    context.commit('setTextureTypes', response.data.getTextureTypes)
  },

  async getPlankTypes (context) {
    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getPlankTypes {
            id
            name
            image
            connection
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch plank types.')
    }
    context.commit('setPlankTypes', response.data.getPlankTypes)
  },

  async getPlankLocations (context) {
    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getPlankLocations {
            id
            name
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch plank locations.')
    }
    context.commit('setPlankLocations', response.data.getPlankLocations)
  },

  async updateTextures(context, Textures) {
    //return new Promise((resolve) => {
    console.log(Textures)
    const remove = Textures.remove.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}",
        url: "${x.url}",
      }`
    })

    const update = Textures.update.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}",
        url: "${x.url}",
        image: "${x.tempImageStorage}",
        textureType: "${x.textureType}",
        plankType: "${x.plankType}",
        color: "${x.color}",
        locations: ${JSON.stringify(x.locations)},
        ${x.order ? 'order: '+x.order : ''}
      }`
    })

    const insert = Textures.insert.map((x) => {
      return `{
        id: "", 
        name: "${x.name}",
        url: "${x.url}",
        image: "${x.tempImageStorage}",
        textureType: "${x.textureType}",
        plankType: "${x.plankType}",
        color: "${x.color}",
        locations: ${JSON.stringify(x.locations)},
        ${x.order ? 'order: '+x.order : ''}
      }`
    })

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateTextures(remove: [${remove}], update: [${update}], insert: [${insert}]) {
            id
            name
            url
            textureType
            plankType
            color
            locations
            order
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update textures.')
    }

    context.commit('setTextures', response.data.updateTextures)
    await context.dispatch('updateDWTables', {
      succesText: 'Textures succesvol aangepast.',
      tableData: response.data.updateTextures,
      menuName: "updateTextures"
    })
  },

  async updatePlankTypes(context, PlankTypes) {
    console.log(PlankTypes.remove)
    const remove = PlankTypes.remove.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}",
        image: "${x.image}",
      }`
    })

    const update = PlankTypes.update.map((x) => {
      console.log(PlankTypes.update)
      return `{
        id: "${x.id}", 
        name: "${x.name}",
        image: "${x.image}",
        tempImage: "${x.tempImageStorage}",
        connection: "${x.connection}",
      }`
    })

    const insert = PlankTypes.insert.map((x) => {
      return `{
        id: "", 
        name: "${x.name}",
        image: "${x.image}",
        tempImage: "${x.tempImageStorage}",
        connection: "${x.connection}",
      }`
    })



    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updatePlankTypes(remove: [${remove}], update: [${update}], insert: [${insert}]) {
            id
            name
            image
            connection
          }
        }
      `
    }
    console.log(graphqlQuery)
    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update plank types.')
    }

    context.commit('setPlankTypes', response.data.updatePlankTypes)
    await context.dispatch('updateDWTables', {
      succesText: 'Plank types succesvol aangepast.',
      tableData: response.data.updatePlankTypes,
      menuName: "updatePlankTypes"
    })
  },

  async getModelsInfo (context) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getTypes {
            id
            name
            urls {
              dev
              tes
              pro
            }
            index
          }

          getInfoboxes {
            id
            uiIndex
            name
          }

          getModelCategories {
            id
            name
          }

          getBergingTypes {
            id
            name
            order
          }

          getModels {
            id
            name
            type {
              id
              name
              urls {
                dev
                tes
                pro
              }
              index
            }
            infobox {
              id 
              uiIndex
              name
            }
            infoboxIsOnline
            category {
              id
              name
            }
            online
            maatwerk {
              enabled
              width {
                min
                max
              }
              depth {
                min
                max
              }
            }
            standaardmatenDisabled
            wall {
              minWallWidth
              needsSpaceForStaander
            }
            roofFinishes 
            wallFinishes {
              wandBuiten
              wandBinnen
              tussenWandLinks
              tussenWandRechts
              topgevelBuiten
              topgevelBinnen
              topgevelLinks
              topgevelRechts
            }
            overstekKlosRound
            availableOptions {
              fundering
              dakgoot
              lichtstraten
              ledverlichting
            }
            defaultSizes {
              enabled
              diepte
              hoogtePaal
              maximaleMuurBreedte
              staanderDikte
              poer {
                z
                y
              }
              schoor {
                enabled
                sz
                sy
                sx
                sox
                wallMinWidth
              }
              ringBalk {
                y
                z
              }
              extraRingBalk {
                enabled
                gordingVorm
                y
                z
                position
              }
              ringbalkTussenPaal {
                enabled
                y
                z
              }
              gordingBalk {
                y
                z
                afstandTussenGordingen
                position
                ox
                oy
                oyl
              }
              ringBalkGording {
                y
                z
                position
              }
              overstekKlos {
                enabled
                z
                zp
                xb
                xo
                y
                yk
                position{
                  left
                  right
                }
                zDik
                zDikPaal
                klosVoEnabled
                klosVoEditable
                xBovVo
                xOndVo
                yHooVo
                yKopVo
                klosAcEnabled
                klosAcEditable
                xBovAc
                xOndAc
                yHooAc
                yKopAc
                klosLiEnabled
                klosLiEditable
                xBovLi
                xOndLi
                yHooLi
                yKopLi
                klosReEnabled
                klosReEditable
                xBovRe
                xOndRe
                yHooRe
                yKopRe
              }
              boeideel {
                enabled
                y
                z
                position
                lijstEnabled
                ly
                lz
                lijstPosition
                dpy
                dpz
                dpOffsetKopkant
                dpOffsetGootzijde
                nokpositie
                byl
                douglasPlanken
                horizontaleOffset
              }
              trim {
                enabled
                y
                z
                overhang
                color
                position
              }
              dak {
                offset
                helling
                overhang
                beschotDikte
                dakpanOverhang
                dakpanOverhangAchter
                gordingSchoorHoek
                gordingSchoorOffset
              }
              dakgoot {
                enabled
                y
                offset
                twoPipesWidth
                threePipesWidth
              }
              wall {
                position
                wz
                height {
                  position
                  value
                }
              }
              omlijstingPalen
              veranda {
                enabled
                hoogte
                dikte
                extraBreedte
                material
              }
              breedtes {
                breedte
                bergingType
                artikelNummer
                wallDivision {
                  width
                  bergingEnabled
                }
                enabled
              }
            }
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch model info.')
    }

    context.commit('setModelsInfo', response.data)
  },

  async updateTypes (context, data) {

    const remove = data.remove.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}",
        urls: {
          dev: "${x.urls.dev}",
          tes: "${x.urls.tes}",
          pro: "${x.urls.pro}",
        },
        index: ${x.index}
      }`
    })

    const update = data.update.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}",
        urls: {
          dev: "${x.urls.dev}",
          tes: "${x.urls.tes}",
          pro: "${x.urls.pro}",
        },
        index: ${x.index}
      }`
    })

    const insert = data.insert.map((x) => {
      return `{
        id: "", 
        name: "${x.name}",
        urls: {
          dev: "${x.urls.dev}",
          tes: "${x.urls.tes}",
          pro: "${x.urls.pro}",
        },
        index: ${x.index}
      }`
    })



    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateTypes(remove: [${remove}], update: [${update}], insert: [${insert}]) {
            id
            name
            urls {
              dev
              tes
              pro
            }
            index
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update model types.')
    }

    context.commit('setTypes', response.data.updateTypes)
    await context.dispatch('updateDWTables', {
      succesText: 'Types succesvol aangepast.',
      tableData: response.data.updateTypes,
      menuName: "updateTypes"
    })
  },

  async updateModelCategories (context, data) {

    const remove = data.remove.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}"
      }`
    })

    const update = data.update.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}"
      }`
    })

    const insert = data.insert.map((x) => {
      return `{
        id: "", 
        name: "${x.name}"
      }`
    })

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateModelCategories(remove: [${remove}], update: [${update}], insert: [${insert}]) {
            id
            name
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update model categories.')
    }

    context.commit('setModelCategories', response.data.updateModelCategories)
    await context.dispatch('updateDWTables', {
      succesText: 'Model categorieën succesvol aangepast.',
      tableData: response.data.updateModelCategories,
      menuName: "updateModelCategories"
    })
  },

  async updateBergingTypes (context, data) {

    const remove = data.remove.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}",
        order: ${x.order}
      }`
    })

    const update = data.update.map((x) => {
      return `{
        id: "${x.id}", 
        name: "${x.name}"
        order: ${x.order}
      }`
    })

    const insert = data.insert.map((x) => {
      return `{
        id: "", 
        name: "${x.name}",
        order: ${x.order}
      }`
    })

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateBergingTypes(remove: [${remove}], update: [${update}], insert: [${insert}]) {
            id
            name
            order
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update berging types.')
    }
    console.log(response)
    context.commit('setBergingTypes', response.data.updateBergingTypes)
    await context.dispatch('updateDWTables', {
      succesText: 'Berging types succesvol aangepast.',
      tableData: response.data.updateBergingTypes,
      menuName: "updateBergingTypes"
    })
  },

  async getSchuifwandTypes (context) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getSchuifwandTypes {
            id
            minimaleBreedte
            maximaleBreedte
            glasplaatAantal
            glasplaatBreedte
            glasplaatDikte
            bovenrailHoogte
            onderrailHoogte
            railDikte
            glaspaneelHartAfstand
            glasprofielStaanderBreedte
            glasprofielStaanderDikte
            glasprofielLiggerBreedte
            glasprofielLiggerDikte
            handvatVerticalePositionering
            handvatHorizontalePositionering
            schuifrichting
            artikelnummers
            order
            image
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch schuifwand types.')
    }

    function mergeRanges(objects) {
      // Create a copy of the original array to avoid modifying it
      const sortedObjects = JSON.parse(JSON.stringify(objects)).sort((a, b) => a.minimaleBreedte - b.minimaleBreedte);
  
      const mergedRanges = [];
      let currentRange = sortedObjects[0];
  
      for (let i = 1; i < sortedObjects.length; i++) {
        const nextRange = sortedObjects[i];
        
        // If there is a gap between the current range and the next range, add the current range to the merged ranges
        if (currentRange.maximaleBreedte < nextRange.minimaleBreedte) {
            mergedRanges.push(currentRange);
            currentRange = nextRange;
        } else {
            // Merge the ranges
            currentRange.maximaleBreedte = Math.max(currentRange.maximaleBreedte, nextRange.maximaleBreedte);
        }
      }

      // Add the last range to the merged ranges
      mergedRanges.push(currentRange);

      return mergedRanges;
    }
    
    const schuifwandWidthRanges = mergeRanges(response.data.getSchuifwandTypes);
    const schuifwandWidthRangesMinMax = schuifwandWidthRanges.map((r) => { return {min: r.minimaleBreedte, max: r.maximaleBreedte }});
    context.commit('schuifwandWidthRanges', schuifwandWidthRangesMinMax)

    context.commit('setSchuifwandTypes', response.data.getSchuifwandTypes)
  },

  async updateUsers (context, data) {

    const update = data.update.map((x) => {
      return `{
        id: "${x.id}", 
        email: "${x.email}"
        superUser: ${x.superUser}
        collectionsAllowedToEdit: [${x.collectionsAllowedToEdit.map((y) => { return `"${y}"` })}]
      }`
    })

    console.log(update)

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateUsers(update: [${update}]) {
            id
            email
            superUser
            collectionsAllowedToEdit
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update users.')
    }
    console.log(response)
    context.commit('setUsers', response.data.updateUsers)
    await context.dispatch('updateDWTables', {
      succesText: 'Gebruikers succesvol aangepast.',
      tableData: response.data.updateUsers,
      menuName: "updateUsers"
    })
  },

  async migrateDatabase (context, {source, destination}) {

    const graphqlQuery = {
      query: `
        mutation {
          migrateComponents(source: ${source}, destination: ${destination})
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to migrate database.')
    }
    context.dispatch('showSucces', "Database succesfully migrated.")

  },

  async createBackup (context, database) {

    const graphqlQuery = {
      query: `
        mutation {
          createBackup(database: "${database}")
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to create backup')
    }
    context.dispatch('showSucces', `Succesfully created backup of ${database}.`)
    context.dispatch('getBackups', database)

  },

  async getBackups (context, database) {

    const graphqlQuery = {
      query: `
        mutation {
          getBackups(database: "${database}") {
            filename
            dateString
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch backups')
    }

    context.commit('setBackups', response.data.getBackups)

  },

  async restoreBackup (context, {database, filename, shouldBackupDatabaseBeforeRestore}) {

    const graphqlQuery = {
      query: `
        mutation {
          restoreBackup(database: "${database}", filename: "${filename}", shouldBackup: ${shouldBackupDatabaseBeforeRestore}) {
            filename
            dateString
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to restore backup')
    }
    context.commit('setBackups', response.data.restoreBackup)
    context.dispatch('showSucces', `Succesfully restored backup.`)

  },    

  async createModel (context, data) {

    const sizes = data.defaultSizes.map((x) => {
      return `{
        enabled: ${x.enabled},
        diepte: ${x.diepte},
        hoogtePaal: ${x.hoogtePaal},
        maximaleMuurBreedte: ${x.maximaleMuurBreedte},
        staanderDikte: ${x.staanderDikte},
        poer: {
          z: ${x.poer.z},
          y: ${x.poer.y},
        }
        schoor: {
          enabled: ${x.schoor.enabled}
          sz: ${x.schoor.sz},
          sy: ${x.schoor.sy},
          sx: ${x.schoor.sx},
          sox: ${x.schoor.sox}
          wallMinWidth: ${x.schoor.wallMinWidth}
        },
        ringBalk: {
          y: ${x.ringBalk.y},
          z: ${x.ringBalk.z},
        },
        extraRingBalk: {
          enabled: ${x.extraRingBalk.enabled},
          gordingVorm: ${x.extraRingBalk.gordingVorm},
          y: ${x.extraRingBalk.y},
          z: ${x.extraRingBalk.z},
          position: ${x.extraRingBalk.position},
        },
        ringbalkTussenPaal: {
          enabled: ${x.ringbalkTussenPaal.enabled},
          y: ${x.ringbalkTussenPaal.y},
          z: ${x.ringbalkTussenPaal.z},
        }        
        gordingBalk: {
          y: ${x.gordingBalk.y},
          z: ${x.gordingBalk.z},
          afstandTussenGordingen: ${x.gordingBalk.afstandTussenGordingen},
          position: ${x.gordingBalk.position},
          ox: ${x.gordingBalk.ox},
          oy: ${x.gordingBalk.oy},
          oyl: ${x.gordingBalk.oyl}
        },
        ringBalkGording: {
          y: ${x.ringBalkGording.y},
          z: ${x.ringBalkGording.z},
          position: ${x.ringBalkGording.position}
        }
        overstekKlos: {
          enabled: ${x.overstekKlos.enabled},
          z: ${x.overstekKlos.z},
          zp: ${x.overstekKlos.zp},
          xb: ${x.overstekKlos.xb},
          xo: ${x.overstekKlos.xo},
          y: ${x.overstekKlos.y},
          yk: ${x.overstekKlos.yk},
          position: {
            left: ${x.overstekKlos.position.left},
            right: ${x.overstekKlos.position.right}
          },
          zDik: ${x.overstekKlos.zDik},
          zDikPaal: ${x.overstekKlos.zDikPaal},
          klosVoEnabled: ${x.overstekKlos.klosVoEnabled},
          klosVoEditable: ${x.overstekKlos.klosVoEditable},
          xBovVo: ${x.overstekKlos.xBovVo},
          xOndVo: ${x.overstekKlos.xOndVo},
          yHooVo: ${x.overstekKlos.yHooVo},
          yKopVo: ${x.overstekKlos.yKopVo},
          klosAcEnabled: ${x.overstekKlos.klosAcEnabled},
          klosAcEditable: ${x.overstekKlos.klosAcEditable},
          xBovAc: ${x.overstekKlos.xBovAc},
          xOndAc: ${x.overstekKlos.xOndAc},
          yHooAc: ${x.overstekKlos.yHooAc},
          yKopAc: ${x.overstekKlos.yKopAc},
          klosLiEnabled: ${x.overstekKlos.klosLiEnabled},
          klosLiEditable: ${x.overstekKlos.klosLiEditable},
          xBovLi: ${x.overstekKlos.xBovLi},
          xOndLi: ${x.overstekKlos.xOndLi},
          yHooLi: ${x.overstekKlos.yHooLi},
          yKopLi: ${x.overstekKlos.yKopLi},
          klosReEnabled: ${x.overstekKlos.klosReEnabled},
          klosReEditable: ${x.overstekKlos.klosReEditable},
          xBovRe: ${x.overstekKlos.xBovRe},
          xOndRe: ${x.overstekKlos.xOndRe},
          yHooRe: ${x.overstekKlos.yHooRe},
          yKopRe: ${x.overstekKlos.yKopRe}
        },
        boeideel: {
          enabled: ${x.boeideel.enabled},
          y: ${x.boeideel.y},
          z: ${x.boeideel.z},
          position: ${x.boeideel.position},
          lijstEnabled: ${x.boeideel.lijstEnabled},
          ly: ${x.boeideel.ly},
          lz: ${x.boeideel.lz},
          lijstPosition: ${x.boeideel.lijstPosition},
          dpy: ${x.boeideel.dpy},
          dpz: ${x.boeideel.dpz},
          dpOffsetKopkant: ${x.boeideel.dpOffsetKopkant},
          dpOffsetGootzijde: ${x.boeideel.dpOffsetGootzijde},
          nokpositie: ${x.boeideel.nokpositie},
          byl: ${x.boeideel.byl},
          douglasPlanken: ${x.boeideel.douglasPlanken},
          horizontaleOffset: ${x.boeideel.horizontaleOffset}
        },
        trim: {
          enabled: ${x.trim.enabled},
          y: ${x.trim.y},
          z: ${x.trim.z},
          overhang: ${x.trim.overhang},
          color: ${JSON.stringify(x.trim.color).replace(/"([^"]+)":/g, '$1:')}
          position: ${x.trim.position},
        }
        dak: {
          offset: ${x.dak.offset},
          helling: ${x.dak.helling},
          overhang: ${x.dak.overhang},
          beschotDikte: ${x.dak.beschotDikte},
          dakpanOverhang: ${x.dak.dakpanOverhang},
          dakpanOverhangAchter: ${x.dak.dakpanOverhangAchter},
          gordingSchoorHoek: ${x.dak.gordingSchoorHoek},
          gordingSchoorOffset: ${x.dak.gordingSchoorOffset},
        }
        dakgoot: {
          enabled: ${x.dakgoot.enabled},
          y: ${x.dakgoot.y},
          offset: ${x.dakgoot.offset},
          twoPipesWidth: ${x.dakgoot.twoPipesWidth},
          threePipesWidth: ${x.dakgoot.threePipesWidth},
        }
        wall: {
          position: "${x.wall.position}",
          wz: ${x.wall.wz},
          height: ${JSON.stringify(x.wall.height).replace(/"([^"]+)":/g, '$1:')},
        }
        omlijstingPalen: ${x.omlijstingPalen},
        veranda: {
          enabled: ${x.veranda.enabled},
          hoogte: ${x.veranda.hoogte},
          dikte: ${x.veranda.dikte},
          extraBreedte: ${x.veranda.extraBreedte},
          material: "${x.veranda.material}"
        }
        breedtes: ${JSON.stringify(cleanBreedtes(x.breedtes)).replace(/"([^"]+)":/g, '$1:')}
      },`
    })

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          createModel(modelInfo: {
            name: "${data.name}",
            type: {
              id: "${data.type.id}",
              name: "${data.type.name}"
            },
            infobox: {
              id: "${data.infobox.id}",
              uiIndex: "${data.infobox.uiIndex}",
              name: "${data.infobox.name}"
            },
            infoboxIsOnline: ${data.infoboxIsOnline},
            category: {
              id: "${data.category.id}",
              name: "${data.category.name}"
            },
            online: ${data.online},
            maatwerk: {
              enabled: ${data.maatwerk.enabled},
              width: {
                min: ${data.maatwerk.width.min},
                max: ${data.maatwerk.width.max}
              },
              depth: {
                min: ${data.maatwerk.depth.min},
                max: ${data.maatwerk.depth.max}
              }
            },
            standaardmatenDisabled: ${data.standaardmatenDisabled},
            wall: {
              minWallWidth: ${data.wall.minWallWidth},
              needsSpaceForStaander: ${data.wall.needsSpaceForStaander}
            },
            roofFinishes: ${JSON.stringify(data.roofFinishes)},
            wallFinishes: ${JSON.stringify(data.wallFinishes).replace(/"([^"]+)":/g, '$1:')}
            overstekKlosRound: ${data.overstekKlosRound},
            availableOptions: {
              fundering: ${data.availableOptions.fundering},
              dakgoot: ${data.availableOptions.dakgoot},
              lichtstraten: ${data.availableOptions.lichtstraten},
              ledverlichting: ${data.availableOptions.ledverlichting},
            },
            defaultSizes: [${sizes}],
          }) {
            id
            name
            type {
              id
              name
              urls {
                dev
                tes
                pro
              }
              index
            }
            category {
              id
              name
            }
            online
            defaultSizes {
              enabled
              diepte
              hoogtePaal
              maximaleMuurBreedte
              staanderDikte
              poer {
                z
                y
              }
              schoor {
                enabled
                sz
                sy
                sx
                sox
                wallMinWidth
              }
              ringBalk {
                y
                z
              }
              extraRingBalk {
                enabled
                gordingVorm
                y
                z
                position
              }
              ringbalkTussenPaal {
                enabled
                y
                z
              }
              gordingBalk {
                y
                z
                afstandTussenGordingen
                position
                ox
                oy
                oyl
              }
              ringBalkGording {
                y
                z
                position
              }
              overstekKlos {
                enabled
                z
                zp
                xb
                xo
                y
                yk
                position{
                  left
                  right
                }
                zDik
                zDikPaal
                klosVoEnabled
                klosVoEditable
                xBovVo
                xOndVo
                yHooVo
                yKopVo
                klosAcEnabled
                klosAcEditable
                xBovAc
                xOndAc
                yHooAc
                yKopAc
                klosLiEnabled
                klosLiEditable
                xBovLi
                xOndLi
                yHooLi
                yKopLi
                klosReEnabled
                klosReEditable
                xBovRe
                xOndRe
                yHooRe
                yKopRe
              }
              boeideel {
                enabled
                y
                z
                position
                lijstEnabled
                ly
                lz
                lijstPosition
                dpy
                dpz
                dpOffsetKopkant
                dpOffsetGootzijde
                nokpositie
                byl
                douglasPlanken
                horizontaleOffset
              }
              trim {
                enabled
                y
                z
                overhang
                color
                position
              }
              dak {
                offset
                helling
                overhang
                beschotDikte
                dakpanOverhang
                dakpanOverhangAchter
                gordingSchoorHoek
                gordingSchoorOffset
              }
              dakgoot {
                enabled
                y
                offset
                twoPipesWidth
                threePipesWidth
              }
              wall {
                position
                wz
                height {
                  position
                  value
                }
              }
              omlijstingPalen
              veranda {
                enabled
                hoogte
                dikte
                extraBreedte
                material
              }
              breedtes {
                breedte
                bergingType
                artikelNummer
                wallDivision {
                  width
                  bergingEnabled
                }
                enabled
              }
            }
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to create model.')
    }

    await context.dispatch('getModelsInfo')
    //context.commit('setModel', response.data.createModel)
    await context.dispatch('showSucces', 'Model succesvol gecreërd.')
  },

  async updateModel (context, data) {
    console.log(data)
    const sizes = data.defaultSizes.map((x) => {
      return `{
        enabled: ${x.enabled},
        diepte: ${x.diepte},
        hoogtePaal: ${x.hoogtePaal},
        maximaleMuurBreedte: ${x.maximaleMuurBreedte},
        staanderDikte: ${x.staanderDikte},
        poer: {
          z: ${x.poer.z},
          y: ${x.poer.y},
        }
        schoor: {
          enabled: ${x.schoor.enabled}
          sz: ${x.schoor.sz},
          sy: ${x.schoor.sy},
          sx: ${x.schoor.sx},
          sox: ${x.schoor.sox}
          wallMinWidth: ${x.schoor.wallMinWidth}
        },
        ringBalk: {
          y: ${x.ringBalk.y},
          z: ${x.ringBalk.z},
        },
        extraRingBalk: {
          enabled: ${x.extraRingBalk.enabled},
          gordingVorm: ${x.extraRingBalk.gordingVorm},
          y: ${x.extraRingBalk.y},
          z: ${x.extraRingBalk.z},
          position: ${x.extraRingBalk.position},
        },
        ringbalkTussenPaal: {
          enabled: ${x.ringbalkTussenPaal.enabled},
          y: ${x.ringbalkTussenPaal.y},
          z: ${x.ringbalkTussenPaal.z},
        }
        gordingBalk: {
          y: ${x.gordingBalk.y},
          z: ${x.gordingBalk.z},
          afstandTussenGordingen: ${x.gordingBalk.afstandTussenGordingen},
          position: ${x.gordingBalk.position}
          ox: ${x.gordingBalk.ox},
          oy: ${x.gordingBalk.oy},
          oyl: ${x.gordingBalk.oyl}
        },
        ringBalkGording: {
          y: ${x.ringBalkGording.y},
          z: ${x.ringBalkGording.z},
          position: ${x.ringBalkGording.position}
        }
        overstekKlos: {
          enabled: ${x.overstekKlos.enabled},
          z: ${x.overstekKlos.z},
          zp: ${x.overstekKlos.zp},
          xb: ${x.overstekKlos.xb},
          xo: ${x.overstekKlos.xo},
          y: ${x.overstekKlos.y},
          yk: ${x.overstekKlos.yk},
          position: {
            left: ${x.overstekKlos.position.left},
            right: ${x.overstekKlos.position.right}
          },
          zDik: ${x.overstekKlos.zDik},
          zDikPaal: ${x.overstekKlos.zDikPaal},
          klosVoEnabled: ${x.overstekKlos.klosVoEnabled},
          klosVoEditable: ${x.overstekKlos.klosVoEditable},
          xBovVo: ${x.overstekKlos.xBovVo},
          xOndVo: ${x.overstekKlos.xOndVo},
          yHooVo: ${x.overstekKlos.yHooVo},
          yKopVo: ${x.overstekKlos.yKopVo},
          klosAcEnabled: ${x.overstekKlos.klosAcEnabled},
          klosAcEditable: ${x.overstekKlos.klosAcEditable},
          xBovAc: ${x.overstekKlos.xBovAc},
          xOndAc: ${x.overstekKlos.xOndAc},
          yHooAc: ${x.overstekKlos.yHooAc},
          yKopAc: ${x.overstekKlos.yKopAc},
          klosLiEnabled: ${x.overstekKlos.klosLiEnabled},
          klosLiEditable: ${x.overstekKlos.klosLiEditable},
          xBovLi: ${x.overstekKlos.xBovLi},
          xOndLi: ${x.overstekKlos.xOndLi},
          yHooLi: ${x.overstekKlos.yHooLi},
          yKopLi: ${x.overstekKlos.yKopLi},
          klosReEnabled: ${x.overstekKlos.klosReEnabled},
          klosReEditable: ${x.overstekKlos.klosReEditable},
          xBovRe: ${x.overstekKlos.xBovRe},
          xOndRe: ${x.overstekKlos.xOndRe},
          yHooRe: ${x.overstekKlos.yHooRe},
          yKopRe: ${x.overstekKlos.yKopRe}
        },
        boeideel: {
          enabled: ${x.boeideel.enabled},
          y: ${x.boeideel.y},
          z: ${x.boeideel.z},
          position: ${x.boeideel.position},
          lijstEnabled: ${x.boeideel.lijstEnabled},
          ly: ${x.boeideel.ly},
          lz: ${x.boeideel.lz},
          lijstPosition: ${x.boeideel.lijstPosition},
          dpy: ${x.boeideel.dpy},
          dpz: ${x.boeideel.dpz},
          dpOffsetKopkant: ${x.boeideel.dpOffsetKopkant},
          dpOffsetGootzijde: ${x.boeideel.dpOffsetGootzijde},
          nokpositie: ${x.boeideel.nokpositie},
          byl: ${x.boeideel.byl},
          douglasPlanken: ${x.boeideel.douglasPlanken},
          horizontaleOffset: ${x.boeideel.horizontaleOffset}
        },
        trim: {
          enabled: ${x.trim.enabled},
          y: ${x.trim.y},
          z: ${x.trim.z},
          overhang: ${x.trim.overhang},
          color: ${JSON.stringify(x.trim.color).replace(/"([^"]+)":/g, '$1:')}
          position: ${x.trim.position},
        }
        dak: {
          offset: ${x.dak.offset},
          helling: ${x.dak.helling},
          overhang: ${x.dak.overhang},
          beschotDikte: ${x.dak.beschotDikte},
          dakpanOverhang: ${x.dak.dakpanOverhang},
          dakpanOverhangAchter: ${x.dak.dakpanOverhangAchter},
          gordingSchoorHoek: ${x.dak.gordingSchoorHoek},
          gordingSchoorOffset: ${x.dak.gordingSchoorOffset},
        }
        dakgoot: {
          enabled: ${x.dakgoot.enabled},
          y: ${x.dakgoot.y},
          offset: ${x.dakgoot.offset},
          twoPipesWidth: ${x.dakgoot.twoPipesWidth},
          threePipesWidth: ${x.dakgoot.threePipesWidth},
        }
        wall: {
          position: "${x.wall.position}",
          wz: ${x.wall.wz},
          height: ${JSON.stringify(x.wall.height).replace(/"([^"]+)":/g, '$1:')},
        }
        omlijstingPalen: ${x.omlijstingPalen},
        veranda: {
          enabled: ${x.veranda.enabled},
          hoogte: ${x.veranda.hoogte},
          dikte: ${x.veranda.dikte},
          extraBreedte: ${x.veranda.extraBreedte},
          material: "${x.veranda.material}"
        }
        breedtes: ${JSON.stringify(cleanBreedtes(x.breedtes)).replace(/"([^"]+)":/g, '$1:')}
      },`
    })

    console.log("sizes", sizes, data.defaultSizes)

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateModel(modelInfo: {
            id: "${data.id}",
            name: "${data.name}",
            type: {
              id: "${data.type.id}",
              name: "${data.type.name}",
              urls: {
                dev: "${data.type.urls.dev}",
                tes: "${data.type.urls.tes}",
                pro: "${data.type.urls.pro}",
              }
              index: ${data.type.index}
            },
            infobox: {
              id: "${data.infobox.id}",
              uiIndex: "${data.infobox.uiIndex}",
              name: "${data.infobox.name}"
            },
            infoboxIsOnline: ${data.infoboxIsOnline},
            category: {
              id: "${data.category.id}",
              name: "${data.category.name}"
            },
            online: ${data.online},
            maatwerk: {
              enabled: ${data.maatwerk.enabled},
              width: {
                min: ${data.maatwerk.width.min},
                max: ${data.maatwerk.width.max}
              },
              depth: {
                min: ${data.maatwerk.depth.min},
                max: ${data.maatwerk.depth.max}
              }
            },
            standaardmatenDisabled: ${data.standaardmatenDisabled},
            wall: {
              minWallWidth: ${data.wall.minWallWidth},
              needsSpaceForStaander: ${data.wall.needsSpaceForStaander}
            },
            roofFinishes: ${JSON.stringify(data.roofFinishes)},
            wallFinishes: ${JSON.stringify(data.wallFinishes).replace(/"([^"]+)":/g, '$1:')}
            overstekKlosRound: ${data.overstekKlosRound},
            availableOptions: {
              fundering: ${data.availableOptions.fundering},
              dakgoot: ${data.availableOptions.dakgoot},
              lichtstraten: ${data.availableOptions.lichtstraten},
              ledverlichting: ${data.availableOptions.ledverlichting},
            },
            defaultSizes: [${sizes}],
          }) {
            id
            name
            type {
              id
              name
              urls {
                dev
                tes
                pro
              }
              index
            }
            category {
              id
              name
            }
            online
            maatwerk {
              enabled
              width {
                min
                max
              }
              depth {
                min
                max
              }
            }
            standaardmatenDisabled
            wall {
              minWallWidth
              needsSpaceForStaander
            }
            roofFinishes
            wallFinishes {
              wandBuiten
              wandBinnen
              tussenWandLinks
              tussenWandRechts
              topgevelBuiten
              topgevelBinnen
              topgevelLinks
              topgevelRechts
            }
            overstekKlosRound
            availableOptions {
              fundering
              dakgoot
              lichtstraten
              ledverlichting
            }
            defaultSizes {
              enabled
              diepte
              hoogtePaal
              maximaleMuurBreedte
              staanderDikte
              poer {
                z
                y
              }
              schoor {
                enabled
                sz
                sy
                sx
                sox
                wallMinWidth
              }
              ringBalk {
                y
                z
              }
              extraRingBalk {
                enabled
                gordingVorm
                y
                z
                position
              }
              ringbalkTussenPaal {
                enabled
                y
                z
              }
              gordingBalk {
                y
                z
                afstandTussenGordingen
                position
                ox
                oy
                oyl
              }
              ringBalkGording {
                y
                z
                position
              }
              overstekKlos {
                enabled
                z
                zp
                xb
                xo
                y
                yk
                position{
                  left
                  right
                }
                zDik
                zDikPaal
                klosVoEnabled
                klosVoEditable
                xBovVo
                xOndVo
                yHooVo
                yKopVo
                klosAcEnabled
                klosAcEditable
                xBovAc
                xOndAc
                yHooAc
                yKopAc
                klosLiEnabled
                klosLiEditable
                xBovLi
                xOndLi
                yHooLi
                yKopLi
                klosReEnabled
                klosReEditable
                xBovRe
                xOndRe
                yHooRe
                yKopRe
              }
              boeideel {
                enabled
                y
                z
                position
                lijstEnabled
                ly
                lz
                lijstPosition
                dpy
                dpz
                dpOffsetKopkant
                dpOffsetGootzijde
                nokpositie
                byl
                douglasPlanken
                horizontaleOffset
              }
              trim {
                enabled
                y
                z
                overhang
                color
                position
              }
              dak {
                offset
                helling
                overhang
                beschotDikte
                dakpanOverhang
                dakpanOverhangAchter
                gordingSchoorHoek
                gordingSchoorOffset
              }
              dakgoot {
                enabled
                y
                offset
                twoPipesWidth
                threePipesWidth
              }
              wall {
                position
                wz
                height {
                  position
                  value
                }
              }
              omlijstingPalen
              veranda {
                enabled
                hoogte
                dikte
                extraBreedte
                material
              }
              breedtes {
                breedte
                bergingType
                artikelNummer
                wallDivision {
                  width
                  bergingEnabled
                }
                enabled
              }
            }
          }
        }
      `
    } 
    console.log(graphqlQuery)
    console.log(JSON.stringify(data.wallFinishes))
    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update model.')
    }

    await context.dispatch('getModelsInfo')
    await context.dispatch('updateDWTables', {
      succesText: 'Model succesvol geupdate.',
      tableData: response.data.updateModel,
      menuName: "updateModel"
    })
  },

  async createSchuifwandType (context, data) {
    console.log("data", data)
    const graphqlQuery = {
      query: `
        mutation {
          createSchuifwandType(schuifwandType: {
            minimaleBreedte: ${data.minimaleBreedte},
            maximaleBreedte: ${data.maximaleBreedte},
            glasplaatAantal: ${data.glasplaatAantal},
            glasplaatBreedte: ${data.glasplaatBreedte},
            glasplaatDikte: ${data.glasplaatDikte},
            bovenrailHoogte: ${data.bovenrailHoogte},
            onderrailHoogte: ${data.onderrailHoogte},
            railDikte: ${data.railDikte},
            glaspaneelHartAfstand: ${data.glaspaneelHartAfstand},
            glasprofielStaanderBreedte: ${data.glasprofielStaanderBreedte},
            glasprofielStaanderDikte: ${data.glasprofielStaanderDikte},
            glasprofielLiggerBreedte: ${data.glasprofielLiggerBreedte},
            glasprofielLiggerDikte: ${data.glasprofielLiggerDikte},
            handvatVerticalePositionering: ${data.handvatVerticalePositionering},
            handvatHorizontalePositionering: ${data.handvatHorizontalePositionering},
            schuifrichting: "${data.schuifrichting}",
            artikelnummers: ${JSON.stringify(data.artikelnummers)},
            order: ${data.order},
            ${data.image ? 'image: "'+data.image+'"' : ''}
          }) {
            id
            minimaleBreedte
            maximaleBreedte
            glasplaatAantal
            glasplaatBreedte
            glasplaatDikte
            bovenrailHoogte
            onderrailHoogte
            railDikte
            glaspaneelHartAfstand
            glasprofielStaanderBreedte
            glasprofielStaanderDikte
            glasprofielLiggerBreedte
            glasprofielLiggerDikte
            handvatVerticalePositionering
            handvatHorizontalePositionering
            schuifrichting
            artikelnummers
            order
            image
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to create schuifwand type.')
    }

    context.commit('setSchuifwandTypes', response.data.createSchuifwandType)
    context.dispatch('showSucces', 'Schuifwand type succesvol gecreërd.')
  },

  async updateSchuifwandType (context, data) {
    console.log(data)
    const graphqlQuery = {
      query: `
        mutation {
          updateSchuifwandType(schuifwandType: {
            id: "${data.id}",
            minimaleBreedte: ${data.minimaleBreedte},
            maximaleBreedte: ${data.maximaleBreedte},
            glasplaatAantal: ${data.glasplaatAantal},
            glasplaatBreedte: ${data.glasplaatBreedte},
            glasplaatDikte: ${data.glasplaatDikte},
            bovenrailHoogte: ${data.bovenrailHoogte},
            onderrailHoogte: ${data.onderrailHoogte},
            railDikte: ${data.railDikte},
            glaspaneelHartAfstand: ${data.glaspaneelHartAfstand},
            glasprofielStaanderBreedte: ${data.glasprofielStaanderBreedte},
            glasprofielStaanderDikte: ${data.glasprofielStaanderDikte},
            glasprofielLiggerBreedte: ${data.glasprofielLiggerBreedte},
            glasprofielLiggerDikte: ${data.glasprofielLiggerDikte},
            handvatVerticalePositionering: ${data.handvatVerticalePositionering},
            handvatHorizontalePositionering: ${data.handvatHorizontalePositionering},
            schuifrichting: "${data.schuifrichting}",
            artikelnummers: ${JSON.stringify(data.artikelnummers)},
            order: ${data.order},
            ${data.image ? 'image: "'+data.image+'"' : ''}
          }) {
            id
            minimaleBreedte
            maximaleBreedte
            glasplaatAantal
            glasplaatBreedte
            glasplaatDikte
            bovenrailHoogte
            onderrailHoogte
            railDikte
            glaspaneelHartAfstand
            glasprofielStaanderBreedte
            glasprofielStaanderDikte
            glasprofielLiggerBreedte
            glasprofielLiggerDikte
            handvatVerticalePositionering
            handvatHorizontalePositionering
            schuifrichting
            artikelnummers
            order
            image
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update schuifwand type.')
    }

    context.commit('setSchuifwandTypes', response.data.updateSchuifwandType)
    context.dispatch('showSucces', 'Schuifwand type succesvol geupdate.')
  },

  async deleteSchuifwandType (context, id) {
    const graphqlQuery = {
      query: `
        mutation {
          deleteSchuifwandType(id: "${id}") {
            id
            minimaleBreedte
            maximaleBreedte
            glasplaatAantal
            glasplaatBreedte
            glasplaatDikte
            bovenrailHoogte
            onderrailHoogte
            railDikte
            glaspaneelHartAfstand
            glasprofielStaanderBreedte
            glasprofielStaanderDikte
            glasprofielLiggerBreedte
            glasprofielLiggerDikte
            handvatVerticalePositionering
            handvatHorizontalePositionering
            schuifrichting
            artikelnummers
            order
            image
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to delete schuifwand type.')
    }

    context.commit('setSchuifwandTypes', response.data.deleteSchuifwandType)
    console.log("response.data.deleteSchuifwandType", response.data.deleteSchuifwandType)
    context.dispatch('showSucces', 'Schuifwand type succesvol verwijderd.')
  },

  async setTextureScaling (context, data) {
    console.log(context, data)

    const textureData = {
      id: data.id,
      name: data.name,
      url: data.previewURL,
      scalingX: parseFloat(data.scalingX),
      scalingY: parseFloat(data.scalingY),
      emissive: parseFloat(data.emissive),
      emissiveColor: data.emissiveColor,
      metalness: parseFloat(data.metalness),
    }
    
    const graphqlQuery = {
      query: `
        mutation {
          updateTexture(update: ${JSON.stringify(textureData).replace(/"([^"]+)":/g, '$1:')}) {
            id
            name
            url
            textureType
            plankType
            color
            locations
          }
        }
      `
    }
    console.log(graphqlQuery)

    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    console.log(response)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update texture.')
    }

  },

  async getUsers (context) {
    const graphqlQuery = {
      query: `
        {
          getUsers {
            id
            email
            superUser
            collectionsAllowedToEdit
          }
        }
      `
    }

    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to get users.')
    }

    context.commit('setUsers', response.data.getUsers)
  },

  async getUser (context) {
    const graphqlQuery = {
      query: `
        {
          getUser {
            id
            email
            superUser
            collectionsAllowedToEdit
          }
        }
      `
    }

    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to get user.')
    }

    context.commit('setUser', response.data.getUser)
  },

  async getMaterials (context) {
    const graphqlQuery = {
      query: `
        {
          getMaterials {
            id
            name
            width
            height
            color
            diffusemap
            normalmap
            aomap
            metalnessmap
            roughnessmap
          }
        }
      `
    }

    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to get materials.')
    }

    context.commit('setMaterials', response.data.getMaterials)
  },

  async updateMaterials (context, materials) {
    const remove = materials.remove.map((x) => {
      return `{
        id: "${x.id}"
      }`
    })

    const update = materials.update.map((x) => {
      console.log(materials.update)
      return `{
        id: "${x.id}", 
        name: "${x.name}",
        width: ${x.width},
        height: ${x.height},
        color: "${x.color}",
        diffusemap: "${x.diffusemap}",
        normalmap: "${x.normalmap}",
        aomap: "${x.aomap}",
        metalnessmap: "${x.metalnessmap}",
        roughnessmap: "${x.roughnessmap}"
      }`
    })

    const insert = materials.insert.map((x) => {
      return `{
        id: "", 
        name: "${x.name}",
        width: ${x.width},
        height: ${x.height},
        color: "${x.color}",
        diffusemap: "${x.diffusemap}",
        normalmap: "${x.normalmap}",
        aomap: "${x.aomap}",
        metalnessmap: "${x.metalnessmap}",
        roughnessmap: "${x.roughnessmap}"
      }`
    })



    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateMaterials(remove: [${remove}], update: [${update}], insert: [${insert}]) {
            id
            name
            width
            height
            color
            diffusemap
            normalmap
            aomap
            metalnessmap
            roughnessmap
          }
        }
      `
    }
    console.log(graphqlQuery)
    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update materials.')
    }

    context.commit('setMaterials', response.data.updateMaterials)
  },

  async getGlobalSettings (context) {
    const graphqlQuery = {
      query: `
        {
          getGlobalSettings {
            lichtstraatBorderMargin
            lichtstraatBetweenMargin
            lichtstraatMapColor
            lichtstraatMapLineColor
            lichtstraatMapLineColorSelected
          }
        }
      `
    }
    console.log(graphqlQuery)
    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch global settings.')
    }

    context.commit('setGlobalSettings', response.data.getGlobalSettings)
  },

  async updateGlobalSettings (context, data) {
    const graphqlQuery = {
      query: `
        mutation {
          updateGlobalSettings(
            lichtstraatBorderMargin: ${data.lichtstraatBorderMargin}, 
            lichtstraatBetweenMargin: ${data.lichtstraatBetweenMargin},
            lichtstraatMapColor: "${data.lichtstraatMapColor}",
            lichtstraatMapLineColor: "${data.lichtstraatMapLineColor}",
            lichtstraatMapLineColorSelected: "${data.lichtstraatMapLineColorSelected}"
          ) {
            lichtstraatBorderMargin
            lichtstraatBetweenMargin
            lichtstraatMapColor
            lichtstraatMapLineColor
            lichtstraatMapLineColorSelected
          } 
        }
      `
    }
    console.log(graphqlQuery)
    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update global settings.')
    }

    context.commit('setGlobalSettings', response.data.updateGlobalSettings)
  }
}

const getters = {

  getCMSComponents (state) {
    return state.CMSComponents
  },

  getMenuColors (state) {
    return state.menuColors
  },

  getMenuGlasTypes (state) {
    return state.menuGlasTypes
  },

  getMenuIsolatieTypes (state) {
    return state.menuIsolatieTypes
  },

  getMenuInfoboxSettings (state) {
    return state.menuInfoboxSettings
  },

  getMenuUISettings (state) {
    return state.menuUISettings
  },

  getTextures (state) {
    return state.textures
  },

  getTextureTypes (state) {
    return state.textureTypes
  },

  getPlankTypes (state) {
    return state.plankTypes
  },

  getPlankLocations (state) {
    return state.plankLocations
  },

  getNewComponent (state) {
    return state.newComponent
  },

  getTypes (state) {
    return state.types
  },

  getInfoboxes (state) {
    return state.infoboxes
  },

  getModelCategories (state) {
    return state.modelCategories
  },

  getBergingTypes (state) {
    return state.bergingTypes
  },

  getSchuifwandTypes (state) {
    return state.schuifwandTypes
  },

  getSchuifwandWidthRanges (state) {
    return state.schuifwandWidthRanges
  },

  getModels (state) {
    return state.models
  },

  getUsers (state) {
    return state.users
  },

  getUser (state) {
    return state.user
  },

  getBackups (state) {
    return state.backups
  },

  getMaterials (state) {
    return state.materials
  },

  getGlobalSettings (state) {
    return state.globalSettings
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}