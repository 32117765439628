import * as triangleBuffer from '@/three/buffer-geometries/triangle.js'
import * as planeBuffer from '@/three/buffer-geometries/plane.js'
import * as trianglePlaneBorderBuffer from '@/three/buffer-geometries/trianglePlaneBorder.js'
import SelectFrame from './selectFrameTopgevel.js'
import store from '../../../store';
import { SUBTRACTION, Brush, Evaluator } from 'three-bvh-csg';

export default class Topgevel {
  constructor (THREE, scene, textureLoader, modelType, topgevels, x, y, z, degrees, gy, rbgz, division, hover, totalOverhang, dx, ox, oy, kapschuurConfig) {
    this.THREE = THREE
    this.scene = scene
    this.textureLoader = textureLoader
    this.modelType = modelType
    this.x = x
    this.y = y
    this.z = z
    this.degrees = degrees
    this.gy = gy
    this.rbgz = rbgz
    this.division = division
    this.hover = hover
    this.totalOverhang = totalOverhang
    this.dx = dx
    this.ox = ox
    this.oy = oy
    this.kapschuurConfig = kapschuurConfig
    this.dikte = 18
    this.settings = {
      binnenMuur: false,
      binnenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
      buitenMuur: false,
      buitenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
    }
    this.topgevelArray = []
    this.bouw(THREE, scene, textureLoader, topgevels, x, y, z, degrees, gy, rbgz, division, hover, totalOverhang, dx, ox, oy)
  }

  bouw (THREE, scene, textureLoader, topgevels, x, y, z, degrees, gy, rbgz, division, hover, totalOverhang, dx, ox, oy) {
    // console.log('bouw topgevels',topgevels)
    const group = new this.THREE.Group()
    this.group = group
    const mapGroup = new this.THREE.Group()
    this.mapGroup = mapGroup

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z

    const schuineGordingHeight = (breedte/2*Math.tan(degrees * (Math.PI/180))) - (gy/(Math.sin((90 - degrees) * (Math.PI/180))))
    const milaanGordingHeight = ((breedte/2+ox)*Math.tan(degrees * (Math.PI/180))) + oy
    const height = (this.modelType.isMilaan || this.modelType.isStockholm) ? milaanGordingHeight : schuineGordingHeight

    let geometry = new this.THREE.BufferGeometry()
    geometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.positionBuffer(height, this.dikte, degrees)), 3))
    geometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.uvBuffer(height, this.dikte, degrees, 1000, 1000)), 2))
    geometry.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.normalBuffer()), 3))
    geometry.groups = triangleBuffer.groupBuffer()

    let topgevel = new Brush( geometry, new this.THREE.MeshBasicMaterial( {color: 0xff0000} ) );
    //topgevel.position.x = z/2
    //topgevel.updateMatrixWorld();
    if(this.modelType.isMilaan || this.modelType.isStockholm){
      const cutCube = new Brush( new this.THREE.BoxGeometry( 500, 500, 500 ).translate( 0, 250, 0 ), new this.THREE.MeshBasicMaterial( {color: 0xff0000} ) );
      cutCube.rotation.z = Math.PI/2 + degrees * (Math.PI/180)
      cutCube.position.x = -breedte/2 + 25/Math.cos(degrees * (Math.PI/180))
      cutCube.updateMatrixWorld();

      const cutCube2 = new Brush( new this.THREE.BoxGeometry( 500, 500, 500 ).translate( 0, 250, 0 ), new this.THREE.MeshBasicMaterial( {color: 0xff0000} ) );
      cutCube2.rotation.z = Math.PI/2 + degrees * (Math.PI/180)
      cutCube2.position.x = breedte/2 - 25/Math.cos(degrees * (Math.PI/180))
      cutCube2.rotation.y = Math.PI
      cutCube2.updateMatrixWorld();

      const evaluator = new Evaluator();
      topgevel = evaluator.evaluate( topgevel, cutCube, SUBTRACTION );
      if(!this.modelType.isStockholm) {
        topgevel = evaluator.evaluate( topgevel, cutCube2, SUBTRACTION );
      }
    }

    const selectFrame = new SelectFrame(THREE, height, rbgz+5 +((this.modelType.isMilaan||this.modelType.isStockholm)?2*this.dikte:0), degrees)
    selectFrame.selectbox.position.x = breedte/2
    selectFrame.selectbox.position.z = rbgz/2
    this.selectFrame = selectFrame

    const buitenMuurTexture = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}/${this.settings.buitenMuurTexture.url}`)
    this.textureSettings(buitenMuurTexture, 1 , 0.46 , 0)

    const binnenMuurTexture = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}/${this.settings.binnenMuurTexture.url}`)
    this.textureSettings(binnenMuurTexture, 1 , 0.46 , 0)

    const buitenMuurMaterial = new this.THREE.MeshStandardMaterial({
      map: buitenMuurTexture,
    });

    const binnenMuurMaterial = new this.THREE.MeshStandardMaterial({
      map: binnenMuurTexture,
    });

    let topgevelBuiten = topgevel.clone()
    topgevelBuiten.material = buitenMuurMaterial
    topgevelBuiten.castShadow = true
    topgevelBuiten.receiveShadow = true
    topgevelBuiten.position.x = breedte/2
    topgevelBuiten.position.z = ((this.modelType.isMilaan||this.modelType.isStockholm)?-this.dikte:this.dikte/2)+10
    

    let topgevelBinnen = topgevel.clone()
    topgevelBinnen.material = binnenMuurMaterial
    topgevelBuiten.castShadow = true
    topgevelBuiten.receiveShadow = true
    topgevelBinnen.position.x = breedte/2
    topgevelBinnen.position.z = rbgz-((this.modelType.isMilaan||this.modelType.isStockholm)?-this.dikte:this.dikte/2)-10
    

    topgevelBuiten.visible = false
    topgevelBuiten.name = "topgevelBuiten"
    topgevelBinnen.visible = false
    topgevelBinnen.name = "topgevelBinnen"

    const plusIconMap = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/plusicon.png`);
    textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/minicon.png`);
    //const minIconMap = new THREE.TextureLoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/minicon.png`);
    plusIconMap.anisotropy = 16;
    plusIconMap.encoding = THREE.sRGBEncoding;
    const spriteMaterial = new THREE.MeshBasicMaterial( { map: plusIconMap, side: THREE.DoubleSide, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );
    const spriteGeometry = new THREE.BufferGeometry()
    spriteGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(400, 400)), 3))
    spriteGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    spriteGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
    const plusIconBuiten = new this.THREE.Mesh( spriteGeometry, spriteMaterial );
    plusIconBuiten.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
    plusIconBuiten.name = "plusIconTopgevelBuiten"
    plusIconBuiten.position.x = breedte/2 - 200
    plusIconBuiten.position.y = height/2 + 100
    plusIconBuiten.position.z = -7 -((this.modelType.isMilaan||this.modelType.isStockholm)?this.dikte:0)
    plusIconBuiten.visible = hover

    const plusIconBinnen = plusIconBuiten.clone()
    plusIconBinnen.name = "plusIconTopgevelBinnen"
    plusIconBinnen.position.z = rbgz+7 +((this.modelType.isMilaan||this.modelType.isStockholm)?this.dikte:0)


    const mapTriangleGeometry = new THREE.BufferGeometry()
    mapTriangleGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(trianglePlaneBorderBuffer.positionBuffer(breedte*0.3, breedte*0.3*0.3, 20)), 3))
    mapTriangleGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(trianglePlaneBorderBuffer.uvBuffer(breedte*0.3, breedte*0.3*0.3, 20)), 2))
    mapTriangleGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(trianglePlaneBorderBuffer.normalBuffer()), 3))
    mapTriangleGeometry.groups = trianglePlaneBorderBuffer.groupBuffer()
    const mapTriangleMaterial = this.shaderMaterial('#505051')
    const transparentMaterial = new this.THREE.MeshBasicMaterial({color: 0xffffff, side: THREE.DoubleSide, transparent: true, opacity: 0.0, depthWrite: false,});
    const mapTriangleBinnen = new THREE.Mesh(mapTriangleGeometry, [mapTriangleMaterial, transparentMaterial])
    mapTriangleBinnen.position.set(z/2,0,dx+100)
    mapTriangleBinnen.name = "mapTriangleBinnen"
    const mapTriangleBuiten = mapTriangleBinnen.clone()
    mapTriangleBuiten.rotation.y=Math.PI
    mapTriangleBuiten.position.z = -100
    mapTriangleBuiten.name = "mapTriangleBuiten"

    const gevelGroep = new this.THREE.Group()
    gevelGroep.add(topgevelBuiten, topgevelBinnen, plusIconBuiten, plusIconBinnen, selectFrame.selectbox)
    const gevelMapGroep = new this.THREE.Group()
    gevelMapGroep.add(mapTriangleBinnen, mapTriangleBuiten)

    let offset = 0
    for(let i=0; i<division.length+1; i++) {
      const gevelGroepClone = gevelGroep.clone()
      const gevelMapGroepClone = gevelMapGroep.clone()

      gevelGroepClone.position.z = i===division.length ? x : offset//(i==0||i==(division.length)) ? i==0 ? 0 : x : x-Math.abs(staandersX.children[staandersX.children.length - i].position.z) - rbgz*1.5
      gevelMapGroepClone.position.z = gevelGroepClone.position.z

      if(i<division.length) {
        offset += division[i].width + dx
      }

      //if last in for loop, rotate groups 180 degrees
      if(i==division.length) {
        gevelGroepClone.rotation.y = Math.PI
        gevelGroepClone.getObjectByName("topgevelBuiten").rotation.y = Math.PI
        gevelGroepClone.getObjectByName("topgevelBinnen").rotation.y = Math.PI
        gevelMapGroepClone.getObjectByName("mapTriangleBinnen").position.x = breedte-(z/2)
        gevelMapGroepClone.getObjectByName("mapTriangleBuiten").position.x = breedte-(z/2)
        gevelMapGroepClone.rotation.y = Math.PI
        gevelGroepClone.position.x = breedte
        gevelMapGroepClone.position.x = breedte
      }

      if(i==0 || i==division.length) {
        if(i==0) {
          gevelMapGroepClone.getObjectByName("mapTriangleBuiten").position.z =  -100 - (dx-100)/2 - totalOverhang.l
        } else {
          gevelMapGroepClone.getObjectByName("mapTriangleBuiten").position.z =  -100 - (dx-100)/2 - totalOverhang.r
        }
      } else {
        gevelMapGroepClone.getObjectByName("mapTriangleBuiten").position.z =  -100
      }

      if(this.modelType.isMilaan || this.modelType.isStockholm) {
        if(i !== 0 && i !== division.length) {
          // gevelGroepClone.getObjectByName("topgevelBuiten").position.z = -rbgz*1.5 + this.dikte/2 
          // gevelGroepClone.getObjectByName("plusIconTopgevelBuiten").position.z = -rbgz*1.5 - this.dikte/2 - 7
          // gevelGroepClone.getObjectByName("topgevelBinnen").position.z = -this.dikte/2
          // gevelGroepClone.getObjectByName("plusIconTopgevelBinnen").position.z = this.dikte/2 + 7
          // gevelGroepClone.getObjectByName("topgevelSelectbox").position.z = -rbgz*0.75
          // gevelGroepClone.position.z = this.topgevelArray[i].group.position.z -rbgz -this.dikte
          // gevelMapGroepClone.position.z = gevelGroepClone.position.z
          gevelGroepClone.position.z = gevelGroepClone.position.z + (dx-rbgz)/2
          gevelMapGroepClone.position.z = gevelGroepClone.position.z - (dx-rbgz)/2
        }
      }

      // TPLA-20 topgevel isolatie knoppen
      const retourHeeftIsolatie = topgevels[i]?.heeftIsolatie
      const mapTopIsoMainGroup = this.createMapTopgevelIsolatieMainGroup(retourHeeftIsolatie);
      const hoverTopIsoMainGroup = this.createHoverTopgevelIsolatieMainGroup(retourHeeftIsolatie);
      if(topgevels[i]?.binnenMuur || topgevels[i]?.buitenMuur) {
        // console.log('topgevel group',topgevel.group)
        gevelMapGroepClone.add(mapTopIsoMainGroup)
        gevelGroepClone.add(hoverTopIsoMainGroup);
      }
      mapTopIsoMainGroup?.position.set(
        (gevelMapGroepClone.getObjectByName('mapTriangleBinnen').position.x)+150, 
        0, 
        (gevelMapGroepClone.getObjectByName('mapTriangleBinnen').position.z)+650
      )
      hoverTopIsoMainGroup?.position.set(
        gevelGroepClone.getObjectByName('plusIconTopgevelBuiten').position.x+200,
        gevelGroepClone.getObjectByName('plusIconTopgevelBuiten').position.y+175,
        gevelGroepClone.getObjectByName('plusIconTopgevelBuiten').position.z-450,
      )
      mapTopIsoMainGroup.visible = true; // toon meteen
      hoverTopIsoMainGroup.visible = false; // pas tonen in menu /walls

      if(topgevels == undefined || topgevels.length == null || topgevels.length == 0) {
        this.topgevelArray.push({
          group: gevelGroepClone, 
          mapGroup: gevelMapGroepClone,
          mapTopIsoMainGroup: mapTopIsoMainGroup,
          hoverTopIsoMainGroup: hoverTopIsoMainGroup,
          heeftIsolatie: false,
          isolatieId: null,
          binnenMuur: false,
          binnenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
          buitenMuur: false,
          buitenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
        })
      } else {
        this.topgevelArray.push({
          group: gevelGroepClone, 
          mapGroup: gevelMapGroepClone,
          mapTopIsoMainGroup: mapTopIsoMainGroup,
          hoverTopIsoMainGroup: hoverTopIsoMainGroup,
          heeftIsolatie: topgevels[i].heeftIsolatie,
          isolatieId: topgevels[i].isolatieId,
          binnenMuur: topgevels[i].binnenMuur,
          binnenMuurTexture: topgevels[i].binnenMuurTexture,
          buitenMuur: topgevels[i].buitenMuur,
          buitenMuurTexture: topgevels[i].buitenMuurTexture,
        })
        gevelGroepClone.getObjectByName("topgevelBinnen").visible = topgevels[i].binnenMuur
        gevelGroepClone.getObjectByName("topgevelBuiten").visible = topgevels[i].buitenMuur
        const plusIconMap = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/plusicon.png`);
        plusIconMap.anisotropy = 16;
        plusIconMap.encoding = this.THREE.sRGBEncoding;
        const minIconMap = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/minicon.png`);
        minIconMap.anisotropy = 16;
        minIconMap.encoding = this.THREE.sRGBEncoding;

        const binnenMuurTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${topgevels[i].binnenMuurTexture.url}`);
        this.textureSettings(binnenMuurTexture, 1 , 0.46 , 0)
        const buitenMuurTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${topgevels[i].buitenMuurTexture.url}`);
        this.textureSettings(buitenMuurTexture,  1 , 0.46 , 0)
        gevelGroepClone.getObjectByName('topgevelBinnen').material = new this.THREE.MeshStandardMaterial( { map: binnenMuurTexture} );
        gevelGroepClone.getObjectByName('topgevelBuiten').material = new this.THREE.MeshStandardMaterial( { map: buitenMuurTexture} );

        const plusIconBinnen = gevelGroepClone.getObjectByName('plusIconTopgevelBinnen')
        plusIconBinnen.material = new this.THREE.MeshBasicMaterial( { map: topgevels[i].binnenMuur ? minIconMap : plusIconMap, side: this.THREE.DoubleSide, transparent: true, alphaTest: 0.5} );
        const plusIconBuiten = gevelGroepClone.getObjectByName('plusIconTopgevelBuiten')
        plusIconBuiten.material = new this.THREE.MeshBasicMaterial( { map: topgevels[i].buitenMuur ? minIconMap : plusIconMap, side: this.THREE.DoubleSide, transparent: true, alphaTest: 0.5} );
      
        const triangleBinnen = gevelMapGroepClone.getObjectByName('mapTriangleBinnen')
        triangleBinnen.material = [this.shaderMaterial('#505051'), topgevels[i].binnenMuur ? this.shaderMaterial(topgevels[i].binnenMuurTexture.hex) : new this.THREE.MeshBasicMaterial({color: 0xffffff, side: this.THREE.DoubleSide, transparent: true, opacity: 0.0, depthWrite: false})]
      
        const triangleBuiten = gevelMapGroepClone.getObjectByName('mapTriangleBuiten')
        triangleBuiten.material = [this.shaderMaterial('#505051'), topgevels[i].buitenMuur ? this.shaderMaterial(topgevels[i].buitenMuurTexture.hex) : new this.THREE.MeshBasicMaterial({color: 0xffffff, side: this.THREE.DoubleSide, transparent: true, opacity: 0.0, depthWrite: false})]
      }

      this.group.add(gevelGroepClone)
      this.mapGroup.add(gevelMapGroepClone)
    }

    const length = (height/Math.tan(degrees * Math.PI / 180))*2
    store.dispatch('updateTopgevelSize', {length, height})
  }

  update (modelType, x, y, z, degrees, gy, rbgz, division, totalOverhang, dx, ox, oy, kapschuurConfig) {
    this.modelType = modelType
    this.x = x
    this.y = y
    this.z = z
    this.degrees = degrees
    this.gy = gy
    this.rbgz = rbgz
    this.division = division
    this.totalOverhang = totalOverhang
    this.dx = dx
    this.ox = ox
    this.oy = oy
    this.kapschuurConfig = kapschuurConfig

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z

    let currentPosition = 0
    let newPositions = []
    for(let i=0; i<division.length+1; i++) {
      let position
      position = i===division.length ? x : currentPosition
      if(this.modelType.isMilaan || this.modelType.isStockholm) {
        if(i !== 0 && i !== division.length) {
          position = position + (dx-rbgz)/2
        }
      }
      newPositions.push(position)
      if (i<division.length) {
        currentPosition += division[i].width + dx
      }
    }
    const oldPositions = this.topgevelArray.map((topgevel) => topgevel.group.position.z)

    function bindClosestValues(arrayA, arrayB) {
      // Step 1: Sort both arrays
      const sortedA = [...arrayA].sort((a, b) => a - b);
      const sortedB = [...arrayB].sort((a, b) => a - b);
    
      // Step 2: Initialize pointers and result structures
      let i = 0, j = 0;
      const bindings = [];
      const unboundA = [];
      const unboundB = [];
      const usedB = new Set();
    
      // Step 3: Use two pointers to find closest pairs
      while (i < sortedA.length && j < sortedB.length) {
        const a = sortedA[i];
        const b = sortedB[j];
    
        // Check if the current b is the closest to the current a
        if (!usedB.has(j) && (j === sortedB.length - 1 || Math.abs(a - b) <= Math.abs(a - sortedB[j + 1]))) {
          // check if the current a is the closest to the current b
          if (i === sortedA.length - 1 || Math.abs(a - b) <= Math.abs(sortedA[i + 1] - b)) {
            bindings.push({ a, b });
            usedB.add(j);
          } else {
            unboundA.push(a);
          }
          i++;
        } else {
          j++;
        }
      }
    
      // Step 4: Collect any remaining unbound elements from arrayA
      for (let k = i; k < sortedA.length; k++) {
        unboundA.push(sortedA[k]);
      }
    
      // Step 5: Collect any remaining unbound elements from arrayB
      for (let k = 0; k < sortedB.length; k++) {
        if (!usedB.has(k)) {
          unboundB.push(sortedB[k]);
        }
      }
    
      // Return the result
      return {
        bindings,
        unboundA,
        unboundB
      };
    }

    function findInsertIndex(arr, num) {
      let low = 0;
      let high = arr.length;
    
      while (low < high) {
        const mid = Math.floor((low + high) / 2);
        if (arr[mid] < num) {
          low = mid + 1;
        } else {
          high = mid;
        }
      }
      return low;
    }
    
    const result = bindClosestValues(oldPositions, newPositions);
    // console.log("topgevel recalculation bindings", oldPositions, newPositions, result);

    if(result.unboundB.length) {
      for(const newTopgevel of result.unboundB) {
        //add more topgevels 
        const newGroup = this.topgevelArray[0].group.clone()
        const newMapGroup = this.topgevelArray[0].mapGroup.clone()

        this.topgevelArray.splice(
          findInsertIndex(newPositions, newTopgevel),
          0,
          {
            group: newGroup, 
            mapGroup: newMapGroup,
            binnenMuur: false,
            binnenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
            buitenMuur: false,
            buitenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
          }
        )

        this.group.add(newGroup)
        this.mapGroup.add(newMapGroup)

        this.updateTopgevel(newGroup.getObjectByName("topgevelSelectbox"), {
          binnenMuur: this.topgevelArray[this.topgevelArray.length-1].binnenMuur,
          binnenMuurTexture: this.topgevelArray[this.topgevelArray.length-1].binnenMuurTexture,
          buitenMuur: this.topgevelArray[this.topgevelArray.length-1].buitenMuur,
          buitenMuurTexture: this.topgevelArray[this.topgevelArray.length-1].buitenMuurTexture,
        })
      }
    } else if(result.unboundA.length) {
      //remove topgevels
      for(const unusedTopgevel of result.unboundA) {
        const index = this.topgevelArray.map((topgevel) => topgevel.group.position.z).indexOf(unusedTopgevel)
        this.group.remove(this.topgevelArray[index].group)
        this.mapGroup.remove(this.topgevelArray[index].mapGroup)
        this.topgevelArray.splice(index, 1)
      }
    }

    // OUD ALGORITME, DEZE PLAKTE OF VERWIJDERDE DE MUUR ALTIJD AAN HET EIND
    // if(division.length+1 > this.topgevelArray.length) {
    //   while(division.length+1 > this.topgevelArray.length) {
    //     //add more topgevels 
    //     const newGroup = this.topgevelArray[0].group.clone()
    //     const newMapGroup = this.topgevelArray[0].mapGroup.clone()

    //     this.topgevelArray.push({
    //       group: newGroup, 
    //       mapGroup: newMapGroup,
    //       binnenMuur: false,
    //       binnenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
    //       buitenMuur: false,
    //       buitenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
    //     })

    //     this.group.add(newGroup)
    //     this.mapGroup.add(newMapGroup)

    //     this.updateTopgevel(newGroup.getObjectByName("topgevelSelectbox"), {
    //       binnenMuur: this.topgevelArray[this.topgevelArray.length-1].binnenMuur,
    //       binnenMuurTexture: this.topgevelArray[this.topgevelArray.length-1].binnenMuurTexture,
    //       buitenMuur: this.topgevelArray[this.topgevelArray.length-1].buitenMuur,
    //       buitenMuurTexture: this.topgevelArray[this.topgevelArray.length-1].buitenMuurTexture,
    //     })
    //   }
    // } else if(division.length+1 < this.topgevelArray.length) {
    //   //remove topgevels
    //   while(this.topgevelArray.length > division.length+1) {
    //     this.group.remove(this.topgevelArray[this.topgevelArray.length-1].group)
    //     this.mapGroup.remove(this.topgevelArray[this.topgevelArray.length-1].mapGroup)
    //     this.topgevelArray.pop()
    //   }
    // }


    const schuineGordingHeight = (breedte/2*Math.tan(degrees * (Math.PI/180))) - (gy/(Math.sin((90 - degrees) * (Math.PI/180))))
    const milaanGordingHeight = ((breedte/2+ox)*Math.tan(degrees * (Math.PI/180))) + oy
    const height = (this.modelType.isMilaan||this.modelType.isStockholm) ? milaanGordingHeight : schuineGordingHeight

    this.topgevelArray[0].mapGroup.getObjectByName("mapTriangleBinnen").geometry.attributes.position.array = new Float32Array(trianglePlaneBorderBuffer.positionBuffer(breedte*0.3, breedte*0.3*0.3, 20))
    this.topgevelArray[0].mapGroup.getObjectByName("mapTriangleBinnen").geometry.attributes.position.needsUpdate = true
    this.topgevelArray[0].mapGroup.getObjectByName("mapTriangleBinnen").geometry.attributes.uv.array = new Float32Array(trianglePlaneBorderBuffer.uvBuffer(breedte*0.3, breedte*0.3*0.3, 20))
    this.topgevelArray[0].mapGroup.getObjectByName("mapTriangleBinnen").geometry.attributes.uv.needsUpdate = true

    let offset = 0
    for(let i=0; i<this.topgevelArray.length; i++) {

      this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBuiten").position.x = breedte/2 - 200
      this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBuiten").position.y = height/2 + 100
      this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBuiten").position.z = -7 -((this.modelType.isMilaan||this.modelType.isStockholm)?this.dikte:0)
      this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBinnen").position.x = breedte/2 - 200
      this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBinnen").position.y = height/2 + 100
      this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBinnen").position.z = rbgz+7 +((this.modelType.isMilaan||this.modelType.isStockholm)?this.dikte:0)

      this.topgevelArray[i].group.getObjectByName("topgevelSelectbox").position.x = breedte/2
      this.topgevelArray[i].group.getObjectByName("topgevelSelectbox").position.z = rbgz/2

      this.topgevelArray[i].group.getObjectByName("topgevelBuiten").geometry.attributes.position.array = new Float32Array(triangleBuffer.positionBuffer(height, this.dikte, degrees))
      this.topgevelArray[i].group.getObjectByName("topgevelBuiten").geometry.attributes.position.needsUpdate = true
      this.topgevelArray[i].group.getObjectByName("topgevelBuiten").geometry.attributes.uv.array = new Float32Array(triangleBuffer.uvBuffer(height, this.dikte, degrees, 1000, 1000))
      this.topgevelArray[i].group.getObjectByName("topgevelBuiten").geometry.attributes.uv.needsUpdate = true

      this.topgevelArray[i].group.getObjectByName("topgevelBinnen").geometry.attributes.position.array = new Float32Array(triangleBuffer.positionBuffer(height, this.dikte, degrees))
      this.topgevelArray[i].group.getObjectByName("topgevelBinnen").geometry.attributes.position.needsUpdate = true
      this.topgevelArray[i].group.getObjectByName("topgevelBinnen").geometry.attributes.uv.array = new Float32Array(triangleBuffer.uvBuffer(height, this.dikte, degrees, 1000, 1000))
      this.topgevelArray[i].group.getObjectByName("topgevelBinnen").geometry.attributes.uv.needsUpdate = true

      let geometry = new this.THREE.BufferGeometry()
      geometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.positionBuffer(height, this.dikte, degrees)), 3))
      geometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.uvBuffer(height, this.dikte, degrees, 1000, 1000)), 2))
      geometry.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.normalBuffer()), 3))
      geometry.groups = triangleBuffer.groupBuffer()

      let topgevel = new Brush( geometry, new this.THREE.MeshBasicMaterial( {color: 0xff0000} ) );
      //topgevel.position.x = z/2
      //topgevel.updateMatrixWorld();
      if(this.modelType.isMilaan || this.modelType.isStockholm){
        const cutCube = new Brush( new this.THREE.BoxGeometry( 500, 500, 500 ).translate( 0, 250, 0 ), new this.THREE.MeshBasicMaterial( {color: 0xff0000} ) );
        cutCube.rotation.z = Math.PI/2 + degrees * (Math.PI/180)
        cutCube.position.x = -breedte/2 + 25/Math.cos(degrees * (Math.PI/180))
        cutCube.updateMatrixWorld();

        const cutCube2 = new Brush( new this.THREE.BoxGeometry( 500, 500, 500 ).translate( 0, 250, 0 ), new this.THREE.MeshBasicMaterial( {color: 0xff0000} ) );
        cutCube2.rotation.z = Math.PI/2 + degrees * (Math.PI/180)
        cutCube2.position.x = breedte/2 - 25/Math.cos(degrees * (Math.PI/180))
        cutCube2.rotation.y = Math.PI
        cutCube2.updateMatrixWorld();

        const evaluator = new Evaluator();
        topgevel = evaluator.evaluate( topgevel, cutCube, SUBTRACTION );
        if(!this.modelType.isStockholm) {
          topgevel = evaluator.evaluate( topgevel, cutCube2, SUBTRACTION );
        }
      }

      const buitenMuurTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}/${this.topgevelArray[i].buitenMuurTexture.url}`)
      this.textureSettings(buitenMuurTexture, 1 , 0.46 , 0)

      const binnenMuurTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}/${this.topgevelArray[i].binnenMuurTexture.url}`)
      this.textureSettings(binnenMuurTexture, 1 , 0.46 , 0)

      const buitenMuurMaterial = new this.THREE.MeshStandardMaterial({
        map: buitenMuurTexture,
      });

      const binnenMuurMaterial = new this.THREE.MeshStandardMaterial({
        map: binnenMuurTexture,
      });

      let topgevelBuiten = topgevel.clone()
      topgevelBuiten.material = buitenMuurMaterial
      topgevelBuiten.castShadow = true
      topgevelBuiten.receiveShadow = true
      topgevelBuiten.position.x = breedte/2
      topgevelBuiten.position.z = ((this.modelType.isMilaan||this.modelType.isStockholm)?-this.dikte:this.dikte/2)+10
      

      let topgevelBinnen = topgevel.clone()
      topgevelBinnen.material = binnenMuurMaterial
      topgevelBinnen.castShadow = true
      topgevelBinnen.receiveShadow = true
      topgevelBinnen.position.x = breedte/2
      topgevelBinnen.position.z = rbgz-((this.modelType.isMilaan||this.modelType.isStockholm)?-this.dikte:this.dikte/2)-10

      const buitenVisible = this.topgevelArray[i].group.getObjectByName("topgevelBuiten").visible
      const binnenVisible = this.topgevelArray[i].group.getObjectByName("topgevelBinnen").visible
      /*if(this.modelType.isMilaan){
        const g_cutCube = new this.THREE.BoxGeometry( 500, 500, 500 );
        g_cutCube.translate( 0, 250, 0 );
        const m_cutCube = new this.THREE.MeshBasicMaterial( {color: 0xff0000} );
        const cutCube = new this.THREE.Mesh( g_cutCube, m_cutCube );
        cutCube.rotation.z = Math.PI/2 + degrees * (Math.PI/180)
        cutCube.position.x = 25/Math.cos(degrees * (Math.PI/180)) 
        cutCube.updateMatrix()
        const cutCube2 = cutCube.clone()
        cutCube2.position.x = z - 25/Math.cos(degrees * (Math.PI/180))
        cutCube2.rotation.y = Math.PI
        cutCube2.updateMatrix()
        
        topgevelBuiten.updateMatrix()
        topgevelBuiten = CSG.subtract(topgevelBuiten, cutCube)
        topgevelBuiten = CSG.subtract(topgevelBuiten, cutCube2)
        topgevelBuiten.updateMatrix()
        topgevelBinnen.updateMatrix()
        topgevelBinnen = CSG.subtract(topgevelBinnen, cutCube)
        topgevelBinnen = CSG.subtract(topgevelBinnen, cutCube2)
        topgevelBinnen.updateMatrix()
      }*/
      topgevelBuiten.visible = buitenVisible
      topgevelBuiten.name = "topgevelBuiten"
      topgevelBinnen.visible = binnenVisible
      topgevelBinnen.name = "topgevelBinnen"

      this.topgevelArray[i].group.getObjectByName("topgevelBuiten").removeFromParent()
      this.topgevelArray[i].group.getObjectByName("topgevelBinnen").removeFromParent()

      this.topgevelArray[i].group.add(topgevelBuiten)
      this.topgevelArray[i].group.add(topgevelBinnen)

      this.topgevelArray[i].group.getObjectByName("topgevelBuiten").position.x = breedte/2
      this.topgevelArray[i].group.getObjectByName("topgevelBuiten").position.z = ((this.modelType.isMilaan||this.modelType.isStockholm)?-this.dikte:this.dikte/2)+10
      this.topgevelArray[i].group.getObjectByName("topgevelBinnen").position.x = breedte/2
      this.topgevelArray[i].group.getObjectByName("topgevelBinnen").position.z = rbgz-((this.modelType.isMilaan||this.modelType.isStockholm)?-this.dikte:this.dikte/2)-10

      this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBinnen").position.x = z/2
      this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBuiten").position.x = z/2

      this.topgevelArray[i].group.position.x = 0
      this.topgevelArray[i].mapGroup.position.x = 0
      this.topgevelArray[i].group.rotation.y = 0
      this.topgevelArray[i].mapGroup.rotation.y = 0

      //group positions
      //this.topgevelArray[i].group.position.z = (i==0||i==(staandersX.children.length+1)) ? i==0 ? 0 : x : Math.abs(staandersX.children[i-1].position.z) + rbgz/2
      
      this.topgevelArray[i].group.position.z = i===division.length ? x : offset//(i==0||i==(staandersX.children.length+1)) ? i==0 ? 0 : x : x-Math.abs(staandersX.children[staandersX.children.length - i].position.z) - rbgz*1.5  
      this.topgevelArray[i].mapGroup.position.z = this.topgevelArray[i].group.position.z

      if(i<division.length) {
        offset += division[i].width + dx
      }

      //if last in for loop, rotate groups 180 degrees
      if(i==division.length) {
        this.topgevelArray[i].group.rotation.y = Math.PI
        this.topgevelArray[i].group.getObjectByName("topgevelBuiten").rotation.y = Math.PI
        this.topgevelArray[i].group.getObjectByName("topgevelBinnen").rotation.y = Math.PI
        this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBinnen").position.x = breedte-(z/2)
        this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBuiten").position.x = breedte-(z/2)
        this.topgevelArray[i].mapGroup.rotation.y = Math.PI
        this.topgevelArray[i].group.position.x = breedte
        this.topgevelArray[i].mapGroup.position.x = breedte
      }

      this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBinnen").position.z = dx+100
      if(i==0 || i==division.length) {
        if(i==0) {
          this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBuiten").position.z =  -100 - (dx-100)/2 - totalOverhang.l
        } else {
          this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBuiten").position.z =  -100 - (dx-100)/2 - totalOverhang.r
        }
      } else {
        this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBuiten").position.z =  -100
      }

      if(this.modelType.isMilaan || this.modelType.isStockholm) {
        if(i !== 0 && i !== division.length) {
          // this.topgevelArray[i].group.getObjectByName("topgevelBuiten").position.z = -rbgz*1.5 + this.dikte/2 
          // this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBuiten").position.z = -rbgz*1.5 - this.dikte/2 - 7
          // this.topgevelArray[i].group.getObjectByName("topgevelBinnen").position.z = -this.dikte/2
          // this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBinnen").position.z = this.dikte/2 + 7
          // this.topgevelArray[i].group.getObjectByName("topgevelSelectbox").position.z = -rbgz*0.75
          // this.topgevelArray[i].group.position.z = this.topgevelArray[i].group.position.z -rbgz -this.dikte
          // this.topgevelArray[i].mapGroup.position.z = this.topgevelArray[i].group.position.z
          //this.topgevelArray[i].mapGroup.getObjectByName("mapTriangleBinnen").position.z = dx+100
          this.topgevelArray[i].group.position.z = this.topgevelArray[i].group.position.z + (dx-rbgz)/2
          this.topgevelArray[i].mapGroup.position.z = this.topgevelArray[i].group.position.z - (dx-rbgz)/2
        }
      }

      const selectFrame = new SelectFrame(this.THREE, height, rbgz+5 +((this.modelType.isMilaan||this.modelType.isStockholm)?2*this.dikte:0), degrees)
      selectFrame.selectbox.position.x = breedte/2
      selectFrame.selectbox.position.z = rbgz/2
      this.selectFrame = selectFrame
      this.topgevelArray[i].group.getObjectByName("topgevelSelectbox")?.removeFromParent()
      this.topgevelArray[i].group.add(selectFrame.selectbox)

      console.log("plusIconTopgevelBuiten", this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBuiten").position.x, this.topgevelArray[i].group.getObjectByName("plusIconTopgevelBuiten").parent.position.x)

    }

    const length = (height/Math.tan(degrees * Math.PI / 180))*2
    store.dispatch('updateTopgevelSize', {length, height})
  }

  setTopgevelHoverShowInFuture(data) {
    this.topgevelArray.filter(gevel => gevel.group.uuid == data.topgevel.parent.uuid)[0].showInFuture = data.value
  }

  showPreview(data) {
    const topgevel = this.topgevelArray.filter(gevel => gevel.group.uuid == data.topgevel.parent.uuid)[0].group
    const targetedTopgevel = topgevel.getObjectByName('topgevel'+(data.topgevel.name.substring(data.topgevel.name.length-6,data.topgevel.name.length)))
    targetedTopgevel.visible = true
    targetedTopgevel.material.transparent = true
    targetedTopgevel.material.opacity = 0.75
    targetedTopgevel.material.map.needsUpdate = true;
    targetedTopgevel.material.needsUpdate = true;
  }

  hidePreview(hoveredTopgevel) {
    console.log(hoveredTopgevel)
    const topgevel = this.topgevelArray.filter(gevel => gevel.group.uuid == hoveredTopgevel.parent.uuid)[0].group
    const targetedTopgevel = topgevel.getObjectByName('topgevel'+(hoveredTopgevel.name.substring(hoveredTopgevel.name.length-6,hoveredTopgevel.name.length)))
    targetedTopgevel.visible = false
    targetedTopgevel.material.transparent = false
    targetedTopgevel.material.opacity = 1
    targetedTopgevel.material.map.needsUpdate = true;
    targetedTopgevel.material.needsUpdate = true;
  }

  addTopgevel(data) {
    const topgevel = this.topgevelArray.filter(gevel => gevel.group.uuid == data.topgevel.parent.uuid)[0] || this.topgevelArray.filter(gevel => gevel.mapGroup.uuid == data.topgevel.parent.uuid)[0]
    const side = (data.topgevel.name.substring(data.topgevel.name.length-6,data.topgevel.name.length))
    console.log("topgevel & data", topgevel, data)
    const targetedTopgevel = topgevel.group.getObjectByName('topgevel'+side)
    if(targetedTopgevel) {
      topgevel[side.toLowerCase()+"Muur"] = !topgevel[side.toLowerCase()+"Muur"]
      topgevel[side.toLowerCase()+'MuurTexture'] = data.settings["topgevel"+side+"Texture"]

      console.log('if(targetedTopgevel) --- topgevel',topgevel)

      targetedTopgevel.visible = topgevel[side.toLowerCase()+"Muur"]
      targetedTopgevel.material.transparent = false
      targetedTopgevel.material.opacity = 1
      targetedTopgevel.material.map.needsUpdate = true;
      targetedTopgevel.material.needsUpdate = true;
      console.log(data.settings, "topgevel" + side + "Texture", data.settings["topgevel" + side + "Texture"])
      const newMuurTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${data.settings["topgevel" + side + "Texture"].url}`);
      this.textureSettings(newMuurTexture, 1 , 0.46 , 0)
      targetedTopgevel.material = new this.THREE.MeshStandardMaterial( { map: newMuurTexture} );

      const plusIconMap = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/plusicon.png`);
      plusIconMap.anisotropy = 16;
      plusIconMap.encoding = this.THREE.sRGBEncoding;
      const minIconMap = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/minicon.png`);
      minIconMap.anisotropy = 16;
      minIconMap.encoding = this.THREE.sRGBEncoding;

      const plusIcon = topgevel.group.getObjectByName('plusIconTopgevel'+side)
      plusIcon.material = new this.THREE.MeshBasicMaterial( { map: topgevel[side.toLowerCase()+"Muur"] ? minIconMap : plusIconMap, side: this.THREE.DoubleSide, transparent: true, alphaTest: 0.5} );
      
      const triangle = topgevel.mapGroup.getObjectByName('mapTriangle'+side)
      triangle.material = [this.shaderMaterial('#505051'), topgevel[side.toLowerCase()+"Muur"] ? this.shaderMaterial(topgevel[side.toLowerCase()+"MuurTexture"].hex) : new this.THREE.MeshBasicMaterial({color: 0xffffff, side: this.THREE.DoubleSide, transparent: true, opacity: 0.0, depthWrite: false})]
    
      if(!topgevel[side.toLowerCase()+"Muur"]) {
        topgevel.group.getObjectByName("topgevelSelectbox").visible = false
      }
    }

    // TPLA-20
    if(topgevel.binnenMuur || topgevel.buitenMuur) {
      // console.log('topgevel group',topgevel.group)
      topgevel.mapGroup.add(topgevel.mapTopIsoMainGroup)
      topgevel.group.add(topgevel.hoverTopIsoMainGroup);
    } else {
      topgevel.mapTopIsoMainGroup?.removeFromParent();
      topgevel.hoverTopIsoMainGroup?.removeFromParent();
    }
    topgevel.mapTopIsoMainGroup?.position.set(
      (topgevel.mapGroup.getObjectByName('mapTriangleBinnen').position.x)+150, 
      0, 
      (topgevel.mapGroup.getObjectByName('mapTriangleBinnen').position.z)+650
    )
    // console.log('topgevel.group.position.x',topgevel.group.position.x);
    topgevel.hoverTopIsoMainGroup?.position.set(
      // topgevel.group.position.x/2,
      topgevel.group.getObjectByName('plusIconTopgevelBuiten').position.x+200,
      topgevel.group.getObjectByName('plusIconTopgevelBuiten').position.y+175,
      topgevel.group.getObjectByName('plusIconTopgevelBuiten').position.z-450,
    )
    // console.log('topgevel in addTopgevel',topgevel)

    const topgevels = []
    this.topgevelArray.forEach((topgevel) => {
      topgevels.push({
        binnenMuur: topgevel.binnenMuur,
        binnenMuurTexture: topgevel.binnenMuurTexture,
        buitenMuur: topgevel.buitenMuur,
        buitenMuurTexture: topgevel.buitenMuurTexture,
        heeftIsolatie: topgevel.heeftIsolatie,
        isolatieId: topgevel.isolatieId
      })
    })
    store.dispatch('updateTopgevelArrayConfig', topgevels)
    
  }

  updateTopgevel(wall, settings) {
    // console.log('updateTopgevel',wall, settings)
    const topgevel = this.topgevelArray.filter(gevel => gevel.group.uuid == wall.parent.uuid)[0]

    topgevel.binnenMuur = settings.binnenMuur
    topgevel.buitenMuur = settings.buitenMuur
    topgevel.group.getObjectByName('topgevelBinnen').visible = settings.binnenMuur
    topgevel.group.getObjectByName('topgevelBuiten').visible = settings.buitenMuur

    topgevel.binnenMuurTexture = settings.binnenMuurTexture
    topgevel.buitenMuurTexture = settings.buitenMuurTexture

    topgevel.mapGroup.getObjectByName('mapTriangleBinnen').material = [this.shaderMaterial('#505051'), settings.binnenMuur ? this.shaderMaterial(settings.binnenMuurTexture.hex) : new this.THREE.MeshBasicMaterial({color: 0xffffff, side: this.THREE.DoubleSide, transparent: true, opacity: 0.0, depthWrite: false})]
    topgevel.mapGroup.getObjectByName('mapTriangleBuiten').material = [this.shaderMaterial('#505051'), settings.buitenMuur ? this.shaderMaterial(settings.buitenMuurTexture.hex) : new this.THREE.MeshBasicMaterial({color: 0xffffff, side: this.THREE.DoubleSide, transparent: true, opacity: 0.0, depthWrite: false})]

    const binnenMuurTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.binnenMuurTexture.url}`);
    this.textureSettings(binnenMuurTexture, 1 , 0.46 , 0)
    const buitenMuurTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.buitenMuurTexture.url}`);
    this.textureSettings(buitenMuurTexture,  1 , 0.46 , 0)
    topgevel.group.getObjectByName('topgevelBinnen').material = new this.THREE.MeshStandardMaterial( { map: binnenMuurTexture} );
    topgevel.group.getObjectByName('topgevelBuiten').material = new this.THREE.MeshStandardMaterial( { map: buitenMuurTexture} );

    const plusIconMap = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/plusicon.png`);
    plusIconMap.anisotropy = 16;
    plusIconMap.encoding = this.THREE.sRGBEncoding;
    const minIconMap = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/minicon.png`);
    minIconMap.anisotropy = 16;
    minIconMap.encoding = this.THREE.sRGBEncoding;

    topgevel.group.getObjectByName('plusIconTopgevelBinnen').material = new this.THREE.MeshBasicMaterial( { map: settings.binnenMuur ? minIconMap : plusIconMap, side: this.THREE.DoubleSide, transparent: true, alphaTest: 0.5} );
    topgevel.group.getObjectByName('plusIconTopgevelBuiten').material = new this.THREE.MeshBasicMaterial( { map: settings.buitenMuur ? minIconMap : plusIconMap, side: this.THREE.DoubleSide, transparent: true, alphaTest: 0.5} );

    topgevel.group.getObjectByName("topgevelSelectbox").visible = topgevel.group.getObjectByName("topgevelSelectbox").visible ? (settings.binnenMuur || settings.buitenMuur) : false


    // TPLA-20
    if(topgevel.binnenMuur || topgevel.buitenMuur) {
      topgevel.mapGroup.add(topgevel.mapTopIsoMainGroup)
      topgevel.group.add(topgevel.hoverTopIsoMainGroup);
    } else {
      topgevel.mapTopIsoMainGroup?.removeFromParent();
      topgevel.hoverTopIsoMainGroup?.removeFromParent();
    }
    topgevel.mapTopIsoMainGroup?.position.set(
      (topgevel.mapGroup.getObjectByName('mapTriangleBinnen').position.x)+150, 
      0, 
      (topgevel.mapGroup.getObjectByName('mapTriangleBinnen').position.z)+650
    )
    topgevel.hoverTopIsoMainGroup?.position.set(
      // topgevel.group.position.x/2,
      topgevel.group.getObjectByName('plusIconTopgevelBuiten').position.x+200,
      topgevel.group.getObjectByName('plusIconTopgevelBuiten').position.y+225,
      topgevel.group.getObjectByName('plusIconTopgevelBuiten').position.z-450,
    )

    const topgevels = []
    this.topgevelArray.forEach((topgevel) => {
      topgevels.push({
        binnenMuur: topgevel.binnenMuur,
        binnenMuurTexture: topgevel.binnenMuurTexture,
        buitenMuur: topgevel.buitenMuur,
        buitenMuurTexture: topgevel.buitenMuurTexture,
        heeftIsolatie: topgevel.heeftIsolatie,
        isolatieId: topgevel.isolatieId
      })
    })
    store.dispatch('updateTopgevelArrayConfig', topgevels)
  }

  removeWall(wall) {
    const topgevel = this.topgevelArray.filter(gevel => gevel.group.uuid == wall.parent.uuid)[0]
    this.updateTopgevel(wall, {
      binnenMuur: false,
      binnenMuurTexture: topgevel.binnenMuurTexture,
      buitenMuur: false,
      buitenMuurTexture: topgevel.buitenMuurTexture
    })
    //this.topgevelArray = this.topgevelArray.filter(gevel => gevel.group.uuid != wall.parent.uuid)
  }

  selectTopgevel(data) {
    data.visible = true
  }

  deselectTopgevel(data) {
    data.visible = false
  }

  togglePlusIcons(on) {
    this.hover = on
    this.topgevelArray.forEach(topgevel => {
      const plusIconBuiten = topgevel.group.getObjectByName('plusIconTopgevelBuiten')
      const plusIconBinnen = topgevel.group.getObjectByName('plusIconTopgevelBinnen')
      plusIconBuiten.visible = on
      plusIconBinnen.visible = on
      // isolatie knoppen
      const isoButtonsGroup = topgevel.hoverTopIsoMainGroup;
      isoButtonsGroup.visible = on;
    })
  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
  }

  shaderMaterial(hex) {
    const c = new this.THREE.Color(hex)
    const fragmentShader = `void main() {gl_FragColor = vec4(vec3(${c.r},${c.g},${c.b}),1.0);}`
    const material = new this.THREE.ShaderMaterial({fragmentShader})
    return material
  }

  createMapTopgevelIsolatieMainGroup(retourHeeftIsolatie=false) {
    const mainGroup = new this.THREE.Group();
    mainGroup.name = 'topgevelIsolatieMapButtonMainGroup';
    const mapTopActiveGroup = this.getTopIsoIcon(this.THREE, this.textureLoader, 'Map', true);
    const mapTopInactiveGroup = this.getTopIsoIcon(this.THREE, this.textureLoader, 'Map', false);
    mapTopActiveGroup.visible = retourHeeftIsolatie ? true : false; // name = 'topgevelMapButtonGroupActive'
    mapTopInactiveGroup.visible = retourHeeftIsolatie ? false : true; // name = 'topgevelMapButtonGroupInactive'
    mainGroup.add(mapTopActiveGroup);
    mainGroup.add(mapTopInactiveGroup);

    // lower the position of one button to help the raycaster hit the other button first
    if(retourHeeftIsolatie) { 
      // hide the OFF button
      mapTopInactiveGroup.position.set(0, -1, 0);
    } else {
      // hide the ON button
      mapTopActiveGroup.position.set(0, -1, 0);
    }
    return mainGroup;
  }

  createHoverTopgevelIsolatieMainGroup(retourHeeftIsolatie=false) {
    const mainGroup = new this.THREE.Group();
    mainGroup.name = 'topgevelIsolatieHoverButtonMainGroup';
    const hoverTopActiveGroup = this.getTopIsoIcon(this.THREE, this.textureLoader, 'Hover', true);
    const hoverTopInactiveGroup = this.getTopIsoIcon(this.THREE, this.textureLoader, 'Hover', false);
    hoverTopActiveGroup.visible = true;//false; // name = 'topIsolatieHoverButtonGroupActive'
    hoverTopInactiveGroup.visible = true; // name = 'topIsolatieHoverButtonGroupInactive'
    if(retourHeeftIsolatie) {
      hoverTopActiveGroup.getObjectByName("topIsolatieHoverButtonActive").visible = true;
      hoverTopInactiveGroup.getObjectByName("topIsolatieHoverButtonInactive").visible = false;
    }
    mainGroup.add(hoverTopActiveGroup);
    mainGroup.add(hoverTopInactiveGroup);
    return mainGroup;
  }

  getTopIsoIcon(THREE, textureloader, viewName, isActiveGroup) {
    const isoIconGroup = new THREE.Group();

    const previewsServer = import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER;
    const anisotropy = 16;
    const encoding = THREE.sRGBEncoding;

    if(viewName !== 'Hover' && viewName !== 'Map') throw new Error('viewName must be Hover or Map');
    const planeSize = viewName === 'Hover' ? 100 : 300;
    let resultGroupName;
    let resultName;
    let textureName;
    if(isActiveGroup) {
      resultGroupName = `topIsolatie${viewName}ButtonGroupActive`;
      resultName = `topIsolatie${viewName}ButtonActive`
      textureName = '/textures/ui/Wandisolatie_active.png';
    } else {
      resultGroupName = `topIsolatie${viewName}ButtonGroupInactive`;
      resultName = `topIsolatie${viewName}ButtonInactive`
      textureName = '/textures/ui/Wandisolatie_inactive.png';
    }

    isoIconGroup.name = resultGroupName //'topIsolatieMapButtonGroupActive'
    const graphic = textureloader.load(`${previewsServer}${textureName}`);
    graphic.anisotropy = anisotropy;
    graphic.encoding = encoding;
    
    let result;
    if(viewName === 'Map') {
      const material = new THREE.MeshBasicMaterial( { map: graphic, side: THREE.DoubleSide, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );
      const geometry = new THREE.BufferGeometry()
      geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(planeSize, planeSize)), 3))
      geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
      geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
      result = new THREE.Mesh( geometry, material );
      result.name = resultName // 'topIsolatieMapButtonActive'
      const vector = new THREE.Vector3( 0, Math.PI, 0); // flip the graphic around
      result.rotation.setFromVector3(vector);
    } else {
      const material = new THREE.SpriteMaterial( { 
        map: graphic,
        // depthTest: false,
        // depthWrite: false,
        sizeAttenuation: false
      } ); 
      result = new THREE.Sprite( material ); 
      result.scale.set(0.03,0.03,0.03);
      result.name = resultName // 'topIsolatieHoverButtonActive'
      result.visible = !isActiveGroup ? true : false;
    }

    isoIconGroup.add(result);

    return isoIconGroup;
  }
}