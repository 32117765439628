import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
import { Brush } from 'three-bvh-csg';

export default class SelectFrame {
  constructor (THREE, set, heightForBorstwering) {
    this.THREE = THREE
    this.set = set
    this.group = null
    this.bouw(THREE, set, heightForBorstwering)
  }

  bouw(THREE, set, heightForBorstwering) {
    const totalHeight = heightForBorstwering ? heightForBorstwering : set.my

    // geometry
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(set.mx, 200, set.mz)), 3))
    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry.setIndex(boxBuffer.indexBuffer());
    geometry.groups = boxBuffer.groupBuffer()

    // texture
    // eslint-disable-next-line no-unused-vars
    const white = new this.THREE.MeshBasicMaterial({ 
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.5,
      depthWrite: false,
    });
    const transparent = new this.THREE.MeshBasicMaterial({ 
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.0,
      depthWrite: false,
    });
    // eslint-disable-next-line no-unused-vars
    const gradient = new THREE.ShaderMaterial({
      uniforms: {
        color1: {
          value: new THREE.Color("#ffffff")
        },
        color2: {
          value: new THREE.Color("#ffffff")
        }
      },
      vertexShader: `
        varying vec2 vUv;
    
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color1;
        uniform vec3 color2;
      
        varying vec2 vUv;
        
        void main() {
          // y < 0 = transparent, > 1 = opaque
          float alpha = smoothstep(0.5, 0.0, vUv.y);

          // y < 1 = color1, > 2 = color2
          float colorMix = smoothstep(1.0, 2.0, vUv.y);
          
          gl_FragColor = vec4(mix(color1, color2, colorMix), alpha);
        }
      `,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
    });

    // mesh
    const hoverLine0 = new Brush(geometry, [
      transparent,
      transparent,
      transparent,
      transparent,
      gradient,
      gradient,
    ])

    const hoverLine2 = hoverLine0.clone()
    hoverLine2.rotation.z = Math.PI
    hoverLine2.position.y = totalHeight - 200

    const geometry1 = new THREE.BufferGeometry()
    geometry1.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(totalHeight, 200, set.mz)), 3))
    geometry1.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry1.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry1.setIndex(boxBuffer.indexBuffer());
    geometry1.groups = boxBuffer.groupBuffer()

    const hoverLine1 = new Brush(geometry1, [
      transparent,
      transparent,
      transparent,
      transparent,
      gradient,
      gradient,
    ])

    hoverLine1.rotation.z = 0.5 * Math.PI
    hoverLine1.position.y = totalHeight / 2 - 100
    hoverLine1.position.x = set.mx / 2 - 100
    
    const hoverLine3 = hoverLine1.clone()
    hoverLine3.rotation.z = 1.5 * Math.PI
    hoverLine3.position.x = - set.mx / 2 + 100

    const group = new this.THREE.Group()
    group.name = "selectFrame"
    group.add(hoverLine0)
    group.add(hoverLine1)
    group.add(hoverLine2)
    group.add(hoverLine3)

    this.group = group
  }

  update (set, heightForBorstwering) {
    const totalHeight = heightForBorstwering ? heightForBorstwering : set.my

    // this.group.children[0].geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(set.mx, 200, set.mz))
    // this.group.children[0].geometry.attributes.position.needsUpdate = true

    // this.group.children[2].rotation.z = Math.PI
    // this.group.children[2].position.y = set.my - 200

    // this.group.children[1].geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(set.my, 200, set.mz))
    // this.group.children[1].geometry.attributes.position.needsUpdate = true

    // this.group.children[1].rotation.z = 0.5 * Math.PI
    // this.group.children[1].position.y = set.my / 2 - 100
    // this.group.children[1].position.x = set.mx / 2 - 100
    
    // this.group.children[3].position.y = set.my / 2 - 100
    // this.group.children[3].rotation.z = 1.5 * Math.PI
    // this.group.children[3].position.x = - set.mx / 2 + 100

    while(this.group.children.length > 0){ 
      this.group.remove(this.group.children[0]); 
    }

    // geometry
    const geometry = new this.THREE.BufferGeometry()
    geometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(set.mx, 200, set.mz)), 3))
    geometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry.setIndex(boxBuffer.indexBuffer());
    geometry.groups = boxBuffer.groupBuffer()

    // texture
    const transparent = new this.THREE.MeshBasicMaterial({ 
      color: 0xffffff,
      side: this.THREE.DoubleSide,
      transparent: true,
      opacity: 0.0,
      depthWrite: false,
    });
    // eslint-disable-next-line no-unused-vars
    const gradient = new this.THREE.ShaderMaterial({
      uniforms: {
        color1: {
          value: new this.THREE.Color("#ffffff")
        },
        color2: {
          value: new this.THREE.Color("#ffffff")
        }
      },
      vertexShader: `
        varying vec2 vUv;
    
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color1;
        uniform vec3 color2;
      
        varying vec2 vUv;
        
        void main() {
          // y < 0 = transparent, > 1 = opaque
          float alpha = smoothstep(0.5, 0.0, vUv.y);

          // y < 1 = color1, > 2 = color2
          float colorMix = smoothstep(1.0, 2.0, vUv.y);
          
          gl_FragColor = vec4(mix(color1, color2, colorMix), alpha);
        }
      `,
      transparent: true,
      side: this.THREE.DoubleSide,
      depthWrite: false,
    });

    // mesh
    const hoverLine0 = new Brush(geometry, [
      transparent,
      transparent,
      transparent,
      transparent,
      gradient,
      gradient,
    ])

    const hoverLine2 = hoverLine0.clone()
    hoverLine2.rotation.z = Math.PI
    hoverLine2.position.y = totalHeight - 200

    const geometry1 = new this.THREE.BufferGeometry()
    geometry1.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(totalHeight, 200, set.mz)), 3))
    geometry1.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry1.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry1.setIndex(boxBuffer.indexBuffer());
    geometry1.groups = boxBuffer.groupBuffer()

    const hoverLine1 = new Brush(geometry1, [
      transparent,
      transparent,
      transparent,
      transparent,
      gradient,
      gradient,
    ])

    hoverLine1.rotation.z = 0.5 * Math.PI
    hoverLine1.position.y = totalHeight / 2 - 100
    hoverLine1.position.x = set.mx / 2 - 100
    
    const hoverLine3 = hoverLine1.clone()
    hoverLine3.rotation.z = 1.5 * Math.PI
    hoverLine3.position.x = - set.mx / 2 + 100

    this.group.add(hoverLine0)
    this.group.add(hoverLine1)
    this.group.add(hoverLine2)
    this.group.add(hoverLine3)

  }
}