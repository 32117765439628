import Vloer from './components/vloer.js'
import RingBalk from './components/ringbalk.js'
import Staanders from './components/staander.js'
import Gording from './components/gording.js'
import Topgevel from './components/topgevel.js'
import Boeideel from './components/boeideel.js'
import Dakbeschot from './components/dakbeschot.js'
import Dakbedekking from './components/dakbedekking.js'
import Trim from './components/trim.js'
import Goot from './components/goot.js'
import Daklijn from './components/daklijn.js'
import Schoor from './components/muren/schoor.js'
import Muur from './components/muren/muur.js'
import VerandaMuur from './components/verandaMuur.js'
import LichtstraatManager from './components/lichtstraatManager.js'
//import HoverLine from './components/muren/hoverLine.js'
import HoverBox from './components/muren/hoverBox.js'
//import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import store from '../../store';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'
import SteellookComponent from './components/muren/steellookComponent'
// import { SUBTRACTION, Brush, Evaluator } from 'three-bvh-csg';
//import NiceWalls from './components/niceWalls.js'
import { toRaw } from 'vue'
// import Betonband from './muren/betonBand.js'
import * as planeBuffer from '@/three/buffer-geometries/plane.js'

export default class ParijsFrame {

  constructor (THREE, scene, textureLoader, cubeLoader, typeId, modelId, afmetingen, modelSettings, textureFrame, textureBoeidelen, textureHoekprofielen, colorTrim, dakAfwerking, fundering, walls, topgevels, hover, retourLink, modelsObjArray, dakgoot, lichtstraten) {

    console.log("afmetingen.division", afmetingen.division, afmetingen)
    this.THREE = THREE
    this.scene = scene
    this.typeId = typeId
    this.modelId = modelId
    this.x = afmetingen.x // 4320
    this.y = afmetingen.y // 2300
    this.z = afmetingen.z // 2720
    this.division = afmetingen.division 
    this.ringBalkGroote = afmetingen.ringBalk
    this.staander = afmetingen.staanderGrootte
    this.staanderGrootte = afmetingen.staanderGrootte // 120
    this.schoor = afmetingen.schoor
    this.wallConfig = afmetingen.wall
    this.textureFrame = textureFrame
    this.textureBoeidelen = textureBoeidelen
    this.textureHoekprofielen = textureHoekprofielen
    this.colorTrim = colorTrim
    this.dakAfwerking = dakAfwerking
    this.fundering = fundering
    this.dakgoot = dakgoot
    this.lichtstraten = lichtstraten
    this.textureLoader = textureLoader
    this.cubeLoader = cubeLoader
    this.ringBalk = null
    this.staanders = null
    this.gording = null
    this.boeideel = null
    this.dakbedekking = null
    this.dakbeschot = null
    this.trim = null
    this.daklijn = null
    this.muur = null
    this.schoor0 = null
    this.muren = []
    this.muurInstances = []
    this.muurGroup = null
    this.mapMuurGroup = null
    this.modelMuurGroup = null
    this.movingBeam = null
    this.movingStartPosition = null
    this.movingStartPlanePosition = null
    this.movingWall = null
    this.movingBeamPositionArray = []
    this.wallHover = false
    this.frame = null
    this.aantalMuurVakken = afmetingen.aantalMuurVakken
    this.selectedWallComponent = null
    // this.betonBand = betonBand
    this.config = {
      minWallWidth: modelSettings.minWallWidth,
      needsSpaceForStaander: modelSettings.needsSpaceForStaander,
      maxWallWidth: afmetingen.maximaleMuurBreedte
    }
    this.fz = 60
    this.lastEditedWall = null
    this.hasFinishedPlacingNewWall = true
    // console.log(font)
    //this.textloader = new FontLoader()
    //this.font = this.textloader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/fonts/Calibri_Regular.json`) //HIERO

    this.isPlatdak = typeId === "629a060f2fbe252c87361bdc" || typeId === "629a060f2fbe252c87361be0"
    this.isVeranda = typeId === "629a060f2fbe252c87361be0"
    this.isZadeldak = typeId === "629a060f2fbe252c87361bdd"
    this.isKapschuur = typeId === "629a060f2fbe252c87361bde"
    this.isMonaco = modelId === "62ac739bfa81c784ec4209eb" || modelId === "66961ed934d59d03d3581d84"
    this.isMilaan = modelId === "62ac73a7fa81c784ec4209ef" || modelId === "669621b634d59d03d3581d92"
    this.isStockholm = modelId === "6503faece0cd6b12f5d31b2d" || modelId === "662bb1aa1b012b3f25578d36"
    this.isLissabon = modelId === "6503fb04e0cd6b12f5d31b2e" || modelId === "662bb19c1b012b3f25578d35"
    this.isParijs = modelId === "62ac6eb754a242046ed38e51"
    this.isLagos = modelId === "651d3ff22ec1ec7443e54de9"
    console.log(this.isPlatdak, this.isZadeldak, this.isKapschuur)

    this.shouldShowPlusIcons = false

    this.achtermuurSnapRange = 250

    console.log(modelsObjArray)
    this.bouwFrame(THREE, scene, textureLoader, cubeLoader, afmetingen, textureFrame, textureBoeidelen, textureHoekprofielen, colorTrim, dakAfwerking, fundering, walls, topgevels, hover, retourLink, modelsObjArray, dakgoot, lichtstraten)
    this.setFoundation(this.fundering)
  }

  bouwFrame (THREE, scene, textureLoader, cubeLoader, afmetingen, textureFrame, textureBoeidelen, textureHoekprofielen, colorTrim, dakAfwerking, fundering, walls, topgevels, hover, retourLink, modelsObjArray, dakgoot, lichtstraten) {
    
    const x = afmetingen.x
    const y = afmetingen.y
    const z = afmetingen.z
    const division = afmetingen.division
    const ringBalkGrootte = afmetingen.ringBalk
    const extraRingBalk = afmetingen.extraRingBalk
    const ringbalkTussenPaal = afmetingen.ringbalkTussenPaal
    const staanderGrootte = afmetingen.staanderGrootte
    const schoor = afmetingen.schoor
    //const aantalMuurVakken = afmetingen.aantalMuurVakken
    const gordingBalk = afmetingen.gordingBalk
    const ringBalkGording = afmetingen.ringBalkGording
    const overstekKlos = JSON.parse(JSON.stringify(afmetingen.overstekKlos))
    const boeideelSize = afmetingen.boeideel
    const trimSize = afmetingen.trim
    const round = afmetingen.round
    const dak = afmetingen.dak
    const dakgootSize = afmetingen.dakgoot
    const op = afmetingen.omlijstingPalen
    const wallConfig = afmetingen.wall
    const veranda = afmetingen.veranda

    const overstekConfig = store.getters.getOverstekConfig
    if (!overstekConfig.voEnabled) overstekKlos.klosVoEnabled = false
    if (!overstekConfig.acEnabled) overstekKlos.klosAcEnabled = false
    if (!overstekConfig.liEnabled) overstekKlos.klosLiEnabled = false
    if (!overstekConfig.reEnabled) overstekKlos.klosReEnabled = false

    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0

    let gy = gordingBalk.y
    let gz = gordingBalk.z
    let ox = gordingBalk.ox
    let oy = gordingBalk.oy
    let oyl = gordingBalk.oyl
    let kz = overstekKlos.enabled? overstekKlos.zDik :0 // dikte tussen
    let kzp = overstekKlos.enabled? overstekKlos.zDikPaal :0 // dikte paal
    let kx = overstekKlos.enabled? overstekKlos.xBovVo :0 // lengte bovenkant
    let kxo = overstekKlos.enabled? overstekKlos.xOndVo :0 // lengte onderkant
    let kyo = overstekKlos.enabled? overstekKlos.yKopVo :0 // hoogte kopkant
    let ky = overstekKlos.enabled? overstekKlos.yHooVo :0 // hoogte
    let kl = overstekKlos.enabled? overstekKlos.klosLiEnabled :false
    let kr = overstekKlos.enabled? overstekKlos.klosReEnabled :false
    let kp = {a: overstekKlos.klosVoEnabled&&overstekKlos.klosAcEnabled, v:true, l:overstekKlos.klosLiEnabled, r:overstekKlos.klosReEnabled}
    let gs = gordingBalk.afstandTussenGordingen
    let rbgy = ringBalkGording.y
    let rbgz = ringBalkGording.z
    let rgbp = ringBalkGording.position
    let rby = ringBalkGrootte.dy
    let rbz = ringBalkGrootte.dz
    let dy = boeideelSize.y //170
    let dz = boeideelSize.z //18 
    let lijstEnabled = boeideelSize.lijstEnabled
    let dsz = boeideelSize.lz //18
    let dsy = boeideelSize.ly //60 
    let lijstPosition = boeideelSize.lijstPosition
    let dpy = boeideelSize.dpy
    let dpz = boeideelSize.dpz
    let dpok = boeideelSize.dpOffsetKopkant
    let dpog = boeideelSize.dpOffsetGootzijde
    let np = boeideelSize.nokpositie
    let byl = boeideelSize.byl
    let boeideelHorizontaleOffset = boeideelSize.horizontaleOffset
    let douglasPlanken = boeideelSize.douglasPlanken
    let rbtp = ringbalkTussenPaal
    let rbtpy = ringbalkTussenPaal.y
    let rbtpz = ringbalkTussenPaal.z
    let gsh = dak.gordingSchoorHoek
    let gso = dak.gordingSchoorOffset

    this.ox = ox
    this.oy = oy
    this.dak = {}
    this.dak.helling = dak.helling
    this.rbtpy = rbtpy
    this.gy = gy
    this.veranda = veranda

    const modelType = {
      isPlatdak: this.isPlatdak,
      isVeranda: this.isVeranda,
      isZadeldak: this.isZadeldak,
      isKapschuur: this.isKapschuur,
      isMonaco: this.isMonaco,
      isMilaan: this.isMilaan,
      isStockholm: this.isStockholm,
      isLissabon: this.isLissabon,
      isParijs: this.isParijs,
      isLagos: this.isLagos
    }
    this.modelType = modelType
    this.topgevelSpace = (!modelType.isPlatdak?z*0.3*0.3:0)
    this.poer = afmetingen.poer
    this.wallConfig = wallConfig
    this.dy = dy
    this.snijpuntY = (Math.tan(dak.helling*Math.PI/180)*z + 1523 + 2300)/2
    this.snijpuntX = (this.snijpuntY - 2300) / Math.tan(dak.helling*Math.PI/180)
    this.kapschuurConfig = {
      topX: this.snijpuntX,
      achtergevelHoogte: 1523,
      extraBalkjeDikte: 70
    }
    const radians = dak.helling*(Math.PI/180)
    const HalfDepth = this.modelType.isKapschuur ? (z-(y-this.kapschuurConfig.achtergevelHoogte)/Math.tan(radians))/2 : z/2
    const yMax = Math.tan(radians) * (HalfDepth + (dy/Math.sin(radians)) - (byl/Math.tan(radians)) + boeideelHorizontaleOffset) + boeideelSize.position + byl
    const yMin = boeideelSize.position
    const totalWidthVoor = (yMax-yMin+(dpy/(Math.cos(radians))) - (dpy*Math.cos(radians)))/Math.tan(radians) + (Math.cos(radians)*dpog) -(byl/Math.tan(radians))
    const totalWidthAchter = !this.modelType.isLissabon ? (yMax-yMin+(y-this.kapschuurConfig.achtergevelHoogte)+(dpy/(Math.cos(radians))) - (dpy*Math.cos(radians)))/Math.tan(radians) + (Math.cos(radians)*dpog) -(byl/Math.tan(radians)) : dy-staanderGrootte.dx
    const absoluteWidth = this.modelType.isKapschuur 
      ? Math.round((totalWidthVoor+totalWidthAchter)/2)
      : Math.round(totalWidthVoor)
    const totalOverhang = {
      a: veranda?.enabled
        ? veranda.dikte
        : this.modelType.isLissabon ? totalWidthAchter : (!this.isPlatdak?(absoluteWidth-z/2):((kl&&kr ? xBovAc : 0)+dz+((!lijstEnabled || lijstPosition)?0:dsz))),
      v: this.modelType.isLissabon ? Math.round(totalWidthVoor-this.snijpuntX) : (!this.isPlatdak?(absoluteWidth-z/2):(xBovVo+dz+((!lijstEnabled || lijstPosition)?0:dsz))),
      l: ((kl&&this.isPlatdak) ? xBovLi : 0)+dz+((!lijstEnabled || lijstPosition)?0:dsz) +(!this.isPlatdak?dak.overhang+dpok:0),
      r: ((kr&&this.isPlatdak) ? xBovRe : 0)+dz+((!lijstEnabled || lijstPosition)?0:dsz) +(!this.isPlatdak?dak.overhang+dpok:0)
    }
    this.totalOverhang = totalOverhang

    // vloer
    let spacing = 3500

    const vloer = new Vloer(THREE, textureLoader, x, z, spacing)

    // ringbalk
    const ringBalk = new RingBalk(THREE, textureLoader, x, y, z, ringBalkGrootte.dy, ringBalkGrootte.dz, staanderGrootte.dx, extraRingBalk.enabled, extraRingBalk.gordingVorm, extraRingBalk.z, extraRingBalk.y, extraRingBalk.position, kz, kzp, kx, kxo, kyo, ky, gy, overstekKlos, overstekKlos.enabled, modelType, dak.helling, ox, oy, rbtpy, this.kapschuurConfig, textureFrame)
    ringBalk.group.position.y = y


    // Staanders
    const staanders = new Staanders(THREE, scene, textureLoader, x, y, z, division, staanderGrootte.dx, staanderGrootte.dz, textureFrame, fundering, afmetingen.maximaleMuurBreedte, totalOverhang, op, dz, kx, kp, boeideelSize.position, this.topgevelSpace, this.poer, modelType, this.kapschuurConfig)

    // Gording
    const gording = new Gording(THREE, scene, textureLoader, textureFrame, x, y, z, division, gy, gz, overstekKlos, kp, gs, rbgy, rbgz, rgbp, rby, rbz, gordingBalk.position, staanders.staandersX, round, extraRingBalk.enabled, extraRingBalk.enabled&&extraRingBalk.position ? ringBalkGrootte.dz-extraRingBalk.z : 0, modelType, dak.helling, rbtp, rbtpy, rbtpz, ox, oy, oyl, gsh, gso, dak.overhang, staanderGrootte, this.kapschuurConfig, ringBalkGrootte.dy, ringBalkGrootte.dz,)
    gording.group.position.y = gordingBalk.position//y +  ringBalkGrootte.dy
    gording.group.position.x = modelType.isPlatdak ? (overstekKlos.klosVoEnabled?-overstekKlos.xBovVo:0) : 0

    // Topgevel
    const topgevel = new Topgevel(THREE, scene, textureLoader, modelType, topgevels, x, y, z, dak.helling, gy, rbgz, division, hover, totalOverhang, staanderGrootte.dx, ox, oy, this.kapschuurConfig)
    topgevel.group.position.y = y + ringBalkGrootte.dy

    // Boeideel
    const boeideel = new Boeideel(THREE, textureLoader, modelType, x, y, z, dy, dz, dsz, dsy, gy, overstekKlos, textureBoeidelen, textureHoekprofielen, textureFrame, lijstPosition, boeideelSize.enabled, lijstEnabled, kp, op, y-boeideelSize.position, dak.helling, dak.overhang, boeideelSize.position, dpy, dpz, dpok, dpog, np, byl, douglasPlanken, this.wallConfig, this.kapschuurConfig, staanderGrootte.dx, boeideelHorizontaleOffset)
    boeideel.group.position.y = boeideelSize.position//y + dy / 2 + gy + gy - dyo

    // Dakbeschot
    let by = dak.beschotDikte
    let dpo = dak.dakpanOverhang
    let dpoa = dak.dakpanOverhangAchter

    const textures = store.getters.getTextures
    const selectedFrameColor = store.getters.getSelectedFrameColor
    const filteredTexture = textures.filter(texture => texture.textureType === "62e3e09a990280c3716422dd").filter(texture => texture.color === selectedFrameColor.colorId)
    
    const dakbeschot = new Dakbeschot(THREE, textureLoader, x, y, z, by, overstekKlos, dak.offset, filteredTexture[0].url, dakAfwerking, kp, modelType, dak.helling, dak.overhang, dpo, dpoa, ox, oy, this.kapschuurConfig)
    dakbeschot.group.position.y = gordingBalk.position + (this.isPlatdak ? gy :rbtpy) + (by / 2)//y + gy + gy + (by / 2)

    // Dakbedekking
    let tz = trimSize.z
    let ty = trimSize.y
    let offset = trimSize.overhang+0.1

    let dby = boeideelSize.enabled ? (boeideelSize.position+dy-(gordingBalk.position+gy+by)) : ((trimSize.position+ty/2-10-gordingBalk.position-gy) - by - 10)
    let dbz = !boeideelSize.enabled&&extraRingBalk.enabled ? (y+ringBalkGrootte.dy > gordingBalk.position+gy+by ? ringBalkGrootte.dz-tz/2 +offset +10 : 10) : (dz+(lijstEnabled?dsz:0))

    const dakbedekking = new Dakbedekking(THREE, textureLoader, x, y, z, dz, dby, dbz, dsz, tz, offset, overstekKlos, lijstPosition, lijstEnabled, kp)
    dakbedekking.group.position.y = gordingBalk.position + gy + by + 10//y + gy + gy + by + 10
    dakbedekking.group.visible = this.isPlatdak ? dakAfwerking.name === 'EPDM' : false

    // trim
    let trimColor = trimSize.enabled ? store.getters.getMenuColors.filter(color => color.id.toString() === trimSize.color[0].toString())[0] : {hexcode: "#000000"}
    // const trim = new Trim(THREE, cubeLoader, x, y, z, tz, ty, kx, dz, dsz, (lijstPosition||!lijstEnabled) ? offset - dsz : offset, trimColor, kp)
    const trim = new Trim(THREE, textureLoader, colorTrim, cubeLoader, x, y, z, tz, ty, overstekKlos, dz, dsz, (lijstPosition||!lijstEnabled) ? offset - dsz : offset, trimColor)
    trim.group.position.y = trimSize.position+ty/2//y + gy + gy + dy - dyo
    
    trim.group.visible = trimSize.enabled ? dakAfwerking.name === 'EPDM' : false

    //goot
    const goot = new Goot(THREE, x, y, z, dakgootSize, dakgoot, dak.overhang, staanderGrootte.dx, staanders, walls, modelType, this.kapschuurConfig, dz)

    //daklijn
    const daklijn = new Daklijn(THREE, textureLoader, x, z, totalOverhang, this.topgevelSpace, veranda)

    // veranda muur
    this.verandaMuur = new VerandaMuur(THREE, modelType, x, z, veranda)

    this.topgevel = topgevel
    this.goot = goot


    // muren
    console.log(totalOverhang)
    const schoor0 = new Schoor(THREE, textureLoader, textureFrame, schoor)

    const muurGroup = new this.THREE.Group()
    muurGroup.name="WallGroup"
    const mapMuurGroup = new this.THREE.Group()
    const modelMuurGroup = new this.THREE.Group()


    const nStaandersX = staanders.nStaandersX
    const nStaandersZ = staanders.nStaandersZ


    if(retourLink) {
      console.log("muren:",JSON.parse(JSON.stringify(walls)), JSON.parse(JSON.stringify(fundering)), JSON.parse(JSON.stringify(hover)))
      walls.forEach(muur => {
        console.log(muur)
        this.muren.push({
          mx: muur.width,
          my: y,
          mz: staanderGrootte.dx,
          x: muur.position.z,
          y: muur.position.y,
          z: muur.position.x,
          rotation: muur.rotation,
          tussenMuur: muur.tussenMuur,
          configData: muur
        })
      })

    } else {
    
      // muren x as

      //const nXSpacing = (x - (2 + nStaandersX) * staanderGrootte.dx) / ( nStaandersX + 1)


      let voorwandXSpacing = staanderGrootte.dx
      let achterwandXSpacing = x - staanderGrootte.dx
      for (let i = 0; i < nStaandersX+1; i++) {
        //voorwand
        this.muren.push({
          mx: division[i].width,
          my: y,
          mz: staanderGrootte.dx,
          x: voorwandXSpacing,
          y: 0,
          z: /*this.wallConfig.position === "buiten" ? (this.fz/2) : */staanderGrootte.dx / 2,
          rotation: 1.5 * Math.PI
        })
        voorwandXSpacing += division[i].width
        voorwandXSpacing += staanderGrootte.dx

        //achterwand
        this.muren.push({
          mx: division[(nStaandersX)-i].width,
          my: y,
          mz: staanderGrootte.dx,
          x: achterwandXSpacing,
          y: 0,
          z: /*this.wallConfig.position === "buiten" ? (z - this.fz/2) : */z - staanderGrootte.dx / 2,
          rotation: 0.5 * Math.PI
        })
        achterwandXSpacing -= division[(nStaandersX)-i].width
        achterwandXSpacing -= staanderGrootte.dx
      }

      // muren z as

      const nZSpacing = (z - (2 + nStaandersZ) * staanderGrootte.dz) / ( nStaandersZ + 1)

      for (let i = 0; i < nStaandersZ+1; i++) {
        this.muren.push({
          mx: nZSpacing,
          my: y,
          mz: staanderGrootte.dz,
          x: /*this.wallConfig.position === "buiten" ? (this.fz/2) : */staanderGrootte.dz / 2,
          y: 0,
          z: nZSpacing * (i + 1) + (i + 1) * staanderGrootte.dx,
          rotation: Math.PI
        })

        this.muren.push({
          mx: nZSpacing,
          my: y,
          mz: staanderGrootte.dz,
          x: /*this.wallConfig.position === "buiten" ? (x - this.fz/2) : */x - staanderGrootte.dz / 2,
          y: 0,
          z: nZSpacing * (i) + (i + 1) * staanderGrootte.dx,
          rotation: 0 * Math.PI
        })
      }

      // tussen muren z as
      // console.log(staanders)
      const nTussenZ = nStaandersX
      let tussenXSpacing = staanderGrootte.dx/2

      for (let i=0; i<nTussenZ; i++) {
        tussenXSpacing += division[i].width + staanderGrootte.dx
        for (let j = 0; j < nStaandersZ+1; j++) {
          this.muren.push({
            mx: nZSpacing,
            my: y,
            mz: staanderGrootte.dz,
            x: tussenXSpacing,
            y: 0,
            z: z - (j+1)*staanderGrootte.dx - j*nZSpacing,
            rotation: Math.PI,
            tussenMuur: true,
          })
        }
      }
    }

    // print muren
    // console.log(this.muren)

    // create lichtstraat groups
    this.lichtstraatGroup = new THREE.Group()
    this.lichtstraatModelGroup = new THREE.Group()
    this.lichtstraatMapGroup = new THREE.Group()

    let previousWallElement = null
    let previousBackWallElement = null
    let wallElementHistory = {}
    let frontWallIndex = 0
    let backWallIndex = 0
    const textloader = new FontLoader()
    textloader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/fonts/Calibri_Regular.json`, (font) => {

      // lichtstraat manager
      this.lichtstraatManager = new LichtstraatManager(THREE, modelType, textureFrame, x, y, z, gordingBalk.position+gy, staanderGrootte.dx, dak.beschotDikte, gy, gz, font, lichtstraten)
      this.lichtstraatGroup.add(this.lichtstraatManager.group)
      this.lichtstraatModelGroup.add(this.lichtstraatManager.modelGroup)
      this.lichtstraatMapGroup.add(this.lichtstraatManager.mapGroup)

    // textloader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {      
      // console.log(font)
      const wallArray = []
      this.font = font
      // console.log(this.muren)
      for (let i = 0; i < this.muren.length; i++) {

        const set = this.muren[i]
        // console.log(set)
        const hoverLine0 = new HoverBox(
          {
            colorInsideOutline: 0xE3E6E9,
            colorInsideFill: 0xDAA877,
            colorOutsideOutline: 0xB3B6B9,
            colorOutsideFill: 0xDAA877,
            active: { 
              inside: true, 
              outside: true 
            },
            x: set.mx,//-400,
            z: 500, 
            ox: 500, 
            br: 50, 
            cp: 10, 
            t: 20,
            s: (500-staanderGrootte.dx)/2,
            d: 0,
            dx: staanderGrootte.dx,
          }
        ) 

        console.log('bouwFrame walls[i]',walls[i])
        const retourHeeftIsolatie = (retourLink && walls[i].heeftIsolatie)
        const hoverIsoMainGroup = this.createHoverIsoMainGroup(retourHeeftIsolatie);
        const mapIsoMainGroup = this.createMapIsoMainGroup(retourHeeftIsolatie);

        let muur
        //alert(retourLink && walls[i].isSteellook)
        if(retourLink && walls[i].isSteellook && !walls[i].isBorstwering) {
          const steellookModels = modelsObjArray.find(obj => obj.code === walls[i].modelCode)
          // console.log('retourLink steellookModels',steellookModels)
          muur = new SteellookComponent(THREE, textureLoader, this.y, set, steellookModels.component.dimensions, steellookModels.model.clone(), steellookModels.map.clone(), {frameTexture: textureFrame, y: y, dx: staanderGrootte.dx, dz: staanderGrootte.dz}, font, totalOverhang, walls[i].modelCode, walls[i], this.topgevelSpace, this.poer, this.wallConfig, this.dy, this.textureHoekprofielen, walls[i].hasBetonBand)
        } else {
          const models = []
          // console.log('bouwFrame modelsObjArray',modelsObjArray)
          walls[i].modelArray.forEach((modelArray) => {
            if(modelArray) {
              console.log('bouwFrame modelArray.forEach',modelArray,modelArray?.lastCode)
              models.push({ // check me - TASK 1394 / TASK 1455
                lastCode: modelArray.lastCode, // TASK-1568
                code: modelArray.code,
                color: modelArray.color,
                option: modelArray.option,
                side: modelArray.side,
                model: modelsObjArray.find(obj => (
                  modelArray.lastCode ? obj.code === modelArray.lastCode : // TASK-1568
                  (modelArray.color && obj.color) ? obj.color.code === modelArray.color.code : 
                  obj.code === modelArray.code) 
                  && obj.option.subCode === modelArray.option.subCode)
                  .model.clone(),
                map: modelsObjArray.find(obj => (
                  modelArray.lastCode ? obj.code === modelArray.lastCode : // TASK-1568
                  (modelArray.color && obj.color) ? obj.color.code === modelArray.color.code : 
                  obj.code === modelArray.code) 
                  && obj.option.subCode === modelArray.option.subCode)
                  .map.clone(),
                component: modelsObjArray.find(obj => (
                  modelArray.lastCode ? obj.code === modelArray.lastCode : // TASK-1568
                  (modelArray.color && obj.color) ? obj.color.code === modelArray.color.code : 
                  obj.code === modelArray.code) 
                  && obj.option.subCode === modelArray.option.subCode)
                  .component
              })
            } else {
              models.push(null)
            }
          })
          const borstweringModel = modelsObjArray.find(obj => obj.code === walls[i].modelCode)
          let borstweringComponent
          if (borstweringModel && walls[i].isBorstwering) {
            borstweringComponent = {
              wall: null,
              dimensions: borstweringModel.component.dimensions,
              model: borstweringModel.model.clone(),
              map: borstweringModel.map.clone(),
              component: borstweringModel.component,
              settings: {},
              modelCode: walls[i].modelCode
            }
            const smallY = this.y - (borstweringModel.component.dimensions.y - borstweringModel.component.dimensions.yo)
            set.my = smallY
            set.y = smallY
          }
          muur = new Muur(THREE, textureLoader, walls[i], x, y, z, set, schoor, schoor0, hoverLine0, staanderGrootte, font, {frameTexture: textureFrame, y: y, dx: staanderGrootte.dx, dz: staanderGrootte.dz}, hover, fundering, models, totalOverhang, this.topgevelSpace, this.poer, this.wallConfig, dy, textureHoekprofielen, this.config, modelType, this.kapschuurConfig, ox, oy, dak.helling, rbtpy, gy, veranda, borstweringComponent, hoverIsoMainGroup,mapIsoMainGroup)
          if (walls[i].isBorstwering) {
            muur.isSteellook = true
          }
        }

        const newMuur = muur.group
        const newMapMuur = muur.mapGroup
        const newModelMuur = muur.modelGroup

        newMuur.rotation.y = set.rotation
        newMuur.position.z = set.x + muur.groupOffset.z
        newMuur.position.x = set.z + muur.groupOffset.x

        // console.log(muur.groupOffset.x, newMuur.position.x)

        newMapMuur.rotation.y = set.rotation
        newMapMuur.position.z = set.x
        newMapMuur.position.x = set.z

        newModelMuur.rotation.y = set.rotation
        newModelMuur.position.z = set.x + muur.groupOffset.z
        newModelMuur.position.x = set.z + muur.groupOffset.x

        const nStaandersZ = staanders.nStaandersZ
        if(!retourLink) {
          if(i<(this.muren.length-((nStaandersZ+1) * (nStaandersX+2)))) {
            if(i % 2) {
              // achterwand
              wallElementHistory["achterMuur"+(nStaandersX-Math.floor(i/2))] = muur
              if(previousBackWallElement /*&& (division[backWallIndex].bergingEnabled === division[backWallIndex-1].bergingEnabled)*/) {
                previousBackWallElement.childWall = muur
                previousBackWallElement.settings.Cuuid = previousBackWallElement.childWall.settings.uuid
                previousBackWallElement.showWallStaander(this.textureFrame)
                previousBackWallElement.wallStaander.position.x = previousBackWallElement.set.mx + previousBackWallElement.staanderObject.dx/2
                previousBackWallElement.mapWallStaander.position.x = previousBackWallElement.set.mx + previousBackWallElement.staanderObject.dx/2
                muur.parentWall = previousBackWallElement
                muur.settings.Puuid = muur.parentWall.settings.uuid
              } 
              previousBackWallElement = muur
              backWallIndex++
              console.log(backWallIndex)
            } else {
              // voorwand
              wallElementHistory["voorMuur"+i/2] = muur
              if(previousWallElement /*&& (division[frontWallIndex].bergingEnabled === division[frontWallIndex-1].bergingEnabled)*/) {
                previousWallElement.childWall = muur
                previousWallElement.settings.Cuuid = previousWallElement.childWall.settings.uuid
                previousWallElement.showWallStaander(this.textureFrame)
                previousWallElement.wallStaander.position.x = previousWallElement.set.mx + previousWallElement.staanderObject.dx/2
                previousWallElement.mapWallStaander.position.x = previousWallElement.set.mx + previousWallElement.staanderObject.dx/2
                muur.parentWall = previousWallElement
                muur.settings.Puuid = muur.parentWall.settings.uuid
              } 
              previousWallElement = muur
              frontWallIndex++
              console.log(frontWallIndex)
            }
          } else {
            // zijwanden
            // console.log(i, nStaandersZ+1, nStaandersX+2)
            //muur.group.position.y=(300*i)
            if(i<(this.muren.length-((nStaandersZ+1) * (nStaandersX)))) {
              //buitenste wanden
              if(i % 2) {
                //rechterzijde
                if(wallElementHistory.rechterZijwand) {
                  wallElementHistory.rechterZijwand.childWall = muur
                  wallElementHistory.rechterZijwand.settings.Cuuid = wallElementHistory.rechterZijwand.childWall.settings.uuid
                  wallElementHistory.rechterZijwand.showWallStaander(this.textureFrame)
                  wallElementHistory.rechterZijwand.wallStaander.position.x = wallElementHistory.rechterZijwand.set.mx + wallElementHistory.rechterZijwand.staanderObject.dx/2
                  wallElementHistory.rechterZijwand.mapWallStaander.position.x = wallElementHistory.rechterZijwand.set.mx + wallElementHistory.rechterZijwand.staanderObject.dx/2
                  muur.parentWall = wallElementHistory.rechterZijwand
                  muur.settings.Puuid = muur.parentWall.settings.uuid
                }
                wallElementHistory.rechterZijwand = muur
              } else {
                //linkerzijde
                if(wallElementHistory.linkerZijwand) {
                  muur.childWall = wallElementHistory.linkerZijwand
                  muur.settings.Cuuid = muur.childWall.settings.uuid
                  muur.showWallStaander(this.textureFrame)
                  muur.wallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
                  muur.mapWallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
                  wallElementHistory.linkerZijwand.parentWall = muur
                  wallElementHistory.linkerZijwand.settings.Puuid = wallElementHistory.linkerZijwand.parentWall.settings.uuid
                }
                wallElementHistory.linkerZijwand = muur
              }
            } else {
              //binnenste wanden
              // console.log(i, nStaandersZ, nStaandersX)
              if(i<(this.muren.length-((nStaandersZ) * (nStaandersX)))) {
                //de uiterste parentwall van een rijtje tussenmuren
                wallElementHistory["tussenMuur"+(i-(this.muren.length-((nStaandersZ+1) * (nStaandersX))))] = muur
                console.log(i)

                // make connection with frontWall
                const voorWandIndex = i - (2*(nStaandersZ+1)) - (2*(nStaandersX+1))
                wallElementHistory["voorMuur"+voorWandIndex].tussenChildWall = muur
                wallElementHistory["voorMuur"+voorWandIndex].settings.TCuuid = muur.settings.uuid
                muur.tussenParentWall = wallElementHistory["voorMuur"+voorWandIndex]
                muur.settings.TPuuid = muur.tussenParentWall.settings.uuid 

              } else {
                //dit werkt misschien niet als er 3 muren in de diepte geplaatst worden
                let tussenmuurParent = wallElementHistory["tussenMuur"+((i-(this.muren.length-((nStaandersZ+1) * (nStaandersX))))%nStaandersX)]
                tussenmuurParent.childWall = muur
                tussenmuurParent.settings.Cuuid = tussenmuurParent.childWall.settings.uuid
                muur.parentWall = tussenmuurParent
                muur.settings.Puuid = muur.parentWall.settings.uuid
              }
            }
          }
        }

        set.muur = newMuur
        // console.log("muur", muur)
        this.muurInstances.push(muur)
        if(muur.isSteellook) console.log('steellook CHECK ME',muur) // investigating during TASK 1394 / TASK 1455
        muurGroup.add(newMuur)
        mapMuurGroup.add(newMapMuur)
        modelMuurGroup.add(newModelMuur)
        // console.log(modelMuurGroup)
        muur.settings.position = JSON.parse(JSON.stringify(muur.group.position))
        muur.settings.position.x -= muur.groupOffset.x
        muur.settings.position.z -= muur.groupOffset.z
        wallArray.push(muur.settings)
      }

      for(let i=0; i<this.muurInstances.length; i++) {
        this.muurInstances[i].setSchorenVisibility()
        this.muurInstances[i].updateMuurAfkapping()
      }

      console.log(retourLink)
      if(retourLink) {
        this.muurInstances.forEach(muur => {
          console.log("PUUID:", muur.settings.Puuid)
          console.log("UUID:", muur.settings.uuid)
          console.log("CUUID:", muur.settings.Cuuid)
          if(muur.settings.Cuuid) {
            muur.childWall = this.muurInstances.find(childwall => childwall.settings.uuid === muur.settings.Cuuid)
            console.log("CHILDWALL", muur.childWall)
            console.log("isTussenMuur?",muur)
            if(!muur.settings.tussenMuur) {
              muur.showWallStaander(this.textureFrame)
              muur.wallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
              muur.mapWallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
            } else {
              if(muur.childWall && (muur.wallOn || muur.childWall.wallOn)) {
                muur.showWallStaander(this.textureFrame)
                muur.wallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
                muur.mapWallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
              }
            }
          }
          if(muur.settings.Puuid) {
            muur.parentWall = this.muurInstances.find(parentwall => parentwall.settings.uuid === muur.settings.Puuid)
            console.log("PARENTWALL", muur.parentWall)
          }
          console.log(muur.settings)
          if(muur.settings.TCuuid) {
            muur.tussenChildWall = this.muurInstances.find(tussenchildwall => tussenchildwall.settings.uuid === muur.settings.TCuuid)
          }
          if(muur.settings.TPuuid) {
            muur.tussenParentWall = this.muurInstances.find(tussenparentwall => tussenparentwall.settings.uuid === muur.settings.TPuuid)
          }
        })
      }

      const topgevels = []
      if(this.topgevel) {
        this.topgevel.topgevelArray.forEach((topgevel) => {
          topgevels.push({
            binnenMuur: topgevel.binnenMuur,
            binnenMuurTexture: topgevel.binnenMuurTexture,
            buitenMuur: topgevel.buitenMuur,
            buitenMuurTexture: topgevel.buitenMuurTexture,
            heeftIsolatie: topgevel.heeftIsolatie,
            isolatieId: topgevel.isolatieId,
          })
        })
      }

      // const nStaandersZ = this.staanders.nStaandersZ
      // const nTussenZ = this.staanders.nStaandersX
      // for (let k=this.muurInstances.length - ((nStaandersZ+1) * nTussenZ); k<this.muurInstances.length-nTussenZ; k++) {
      //   console.log(k, this.muurInstances.length, this.muurInstances.length-nTussenZ)
      //   this.muurInstances[k].tussenChild = this.muurInstances[k+nTussenZ]
      //   this.muurInstances[k+nTussenZ].tussenParent = this.muurInstances[k]
      // }


      //save config and set as new
      //store.dispatch('updateConfig')
      //store.dispatch('updateConfigDefault')
      console.log(division)
      division.forEach((div, divisionIndex) => {

        //tussenmuur
        if(divisionIndex < division.length-1) {
          console.log(division[divisionIndex].bergingEnabled, division[divisionIndex+1].bergingEnabled)
          if(division[divisionIndex].bergingEnabled !== division[divisionIndex+1].bergingEnabled) {
            const tussenMuur = wallElementHistory["tussenMuur"+divisionIndex]
            //console.log(division[divisionIndex].bergingEnabled, division[divisionIndex+1].bergingEnabled)
            tussenMuur.addWall({
              binnenMuur: division[divisionIndex].bergingEnabled && !division[divisionIndex+1].bergingEnabled,
              binnenMuurTexture: tussenMuur.settings.binnenMuurTexture, 
              buitenMuur: !division[divisionIndex].bergingEnabled && division[divisionIndex+1].bergingEnabled,
              buitenMuurTexture: tussenMuur.settings.buitenMuurTexture,
              connectionBinnen: tussenMuur.settings.connectionBinnen,
              connectionBuiten: tussenMuur.settings.connectionBuiten
            })
          }
        }

        if(div.bergingEnabled) {

          //voor muur
          const voorMuur = wallElementHistory["voorMuur"+divisionIndex]
          voorMuur.addWall({
            binnenMuur: false,
            binnenMuurTexture: voorMuur.settings.binnenMuurTexture,
            buitenMuur: true,
            buitenMuurTexture: voorMuur.settings.buitenMuurTexture,
            connectionBinnen: voorMuur.settings.connectionBinnen,
            connectionBuiten: voorMuur.settings.connectionBuiten
          })

          //achter muur
          const achterMuur = wallElementHistory["achterMuur"+divisionIndex]
          achterMuur.addWall({
            binnenMuur: false,
            binnenMuurTexture: achterMuur.settings.binnenMuurTexture,
            buitenMuur: true,
            buitenMuurTexture: achterMuur.settings.buitenMuurTexture,
            connectionBinnen: achterMuur.settings.connectionBinnen,
            connectionBuiten: achterMuur.settings.connectionBuiten
          })

          //linker muur
          if(divisionIndex === 0) {
            const linkerMuur = wallElementHistory.linkerZijwand
            linkerMuur.addWall({
              binnenMuur: false,
              binnenMuurTexture: linkerMuur.settings.binnenMuurTexture,
              buitenMuur: true,
              buitenMuurTexture: linkerMuur.settings.buitenMuurTexture,
              connectionBinnen: linkerMuur.settings.connectionBinnen,
              connectionBuiten: linkerMuur.settings.connectionBuiten
            })
          }

          //rechter muur
          if(divisionIndex === division.length-1) {
            const rechterMuur = wallElementHistory.rechterZijwand
            rechterMuur.addWall({
              binnenMuur: false,
              binnenMuurTexture: rechterMuur.settings.binnenMuurTexture,
              buitenMuur: true,
              buitenMuurTexture: rechterMuur.settings.buitenMuurTexture,
              connectionBinnen: rechterMuur.settings.connectionBinnen,
              connectionBuiten: rechterMuur.settings.connectionBuiten
            })
          }
        }
      })

      console.log('set HoekProfielen in muurInstances.forEach')
      this.muurInstances.forEach(muur => {
        this.setHoekProfielen(muur)
      })

      // console.log(wallArray)
      store.dispatch('updateWallArrayConfig', wallArray)
      store.dispatch('updateTopgevelArrayConfig', topgevels)
      store.dispatch('updateConfig')
      store.dispatch('updateIntersectingObjects')

      // turn off dakpannen als dakafwerking is Geen
      this.dakbeschot.setDakpanMeshVisibility(dakAfwerking.name !== 'Geen' && (this.modelType.isKapschuur || this.modelType.isZadeldak))
    })

    const frameGroup = new this.THREE.Group()
    frameGroup.name="FrameGroup"
    frameGroup.add(vloer.group)
    frameGroup.add(ringBalk.group)
    frameGroup.add(staanders.group)
    frameGroup.add(gording.group)
    if(!this.isPlatdak) {
      frameGroup.add(this.topgevel.group)
    }
    if(dakgootSize.enabled) {
      frameGroup.add(this.goot.group)
    }
    frameGroup.add(boeideel.group)
    frameGroup.add(dakbeschot.group)
    frameGroup.add(dakbedekking.group)
    frameGroup.add(trim.group)
    frameGroup.add(muurGroup)
    frameGroup.add(this.verandaMuur.group)
    frameGroup.add(this.lichtstraatGroup)

    const mapGroup = new this.THREE.Group()
    mapGroup.name = "MapGroup"
    mapGroup.add(staanders.mapGroup)
    if(!this.isPlatdak) {
      mapGroup.add(topgevel.mapGroup)
    }
    mapGroup.add(daklijn.mapGroup)
    mapGroup.add(mapMuurGroup)
    mapGroup.add(this.verandaMuur.mapGroup)
    mapGroup.add(this.lichtstraatMapGroup)

    const modelGroup = new this.THREE.Group()
    modelGroup.add(modelMuurGroup)
    modelGroup.add(this.lichtstraatModelGroup)
    modelGroup.name="ModelGroup"

    this.vloer = vloer
    this.ringBalk = ringBalk
    this.staanders = staanders
    this.gording = gording
    this.boeideel = boeideel
    this.dakbeschot = dakbeschot
    this.dakbedekking = dakbedekking
    this.trim = trim
    this.daklijn = daklijn
    this.schoor0 = schoor0
    this.muurGroup = muurGroup
    this.mapMuurGroup = mapMuurGroup
    this.modelMuurGroup = modelMuurGroup
    this.frame = frameGroup
    this.map = mapGroup
    this.model = modelGroup
  }

  update(typeId, modelId, afmetingen, modelSettings, fundering, dakgoot, shouldNotResetWalls) {

    // console.log(afmetingen)
    const x = afmetingen.x
    const y = afmetingen.y
    const z = afmetingen.z
    const division = afmetingen.division
    const ringBalkGrootte = afmetingen.ringBalk
    const extraRingBalk = afmetingen.extraRingBalk
    const ringbalkTussenPaal = afmetingen.ringbalkTussenPaal
    const staanderGrootte = afmetingen.staanderGrootte
    const schoor = afmetingen.schoor
    const aantalMuurVakken = afmetingen.aantalMuurVakken
    const gordingBalk = afmetingen.gordingBalk
    const ringBalkGording = afmetingen.ringBalkGording
    const overstekKlos = JSON.parse(JSON.stringify(afmetingen.overstekKlos))
    const boeideelSize = afmetingen.boeideel
    const trimSize = afmetingen.trim
    const round = afmetingen.round
    const dak = afmetingen.dak
    const dakgootSize = afmetingen.dakgoot
    const op = afmetingen.omlijstingPalen
    const wallConfig = afmetingen.wall
    const veranda = afmetingen.veranda

    const overstekConfig = store.getters.getOverstekConfig
    if (!overstekConfig.voEnabled) overstekKlos.klosVoEnabled = false
    if (!overstekConfig.acEnabled) overstekKlos.klosAcEnabled = false
    if (!overstekConfig.liEnabled) overstekKlos.klosLiEnabled = false
    if (!overstekConfig.reEnabled) overstekKlos.klosReEnabled = false

    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0

    this.typeId = typeId

    this.isPlatdak = typeId === "629a060f2fbe252c87361bdc" || typeId === "629a060f2fbe252c87361be0"
    this.isVeranda = typeId === "629a060f2fbe252c87361be0"
    this.isZadeldak = typeId === "629a060f2fbe252c87361bdd"
    this.isKapschuur = typeId === "629a060f2fbe252c87361bde"
    this.isMonaco = modelId === "62ac739bfa81c784ec4209eb" || modelId === "66961ed934d59d03d3581d84"
    this.isMilaan = modelId === "62ac73a7fa81c784ec4209ef" || modelId === "669621b634d59d03d3581d92"
    this.isStockholm = modelId === "6503faece0cd6b12f5d31b2d" || modelId === "662bb1aa1b012b3f25578d36"
    this.isLissabon = modelId === "6503fb04e0cd6b12f5d31b2e" || modelId === "662bb19c1b012b3f25578d35"
    this.isParijs = modelId === "62ac6eb754a242046ed38e51"
    this.isLagos = modelId === "651d3ff22ec1ec7443e54de9"

    this.config.minWallWidth = modelSettings.minWallWidth,
    this.config.needsSpaceForStaander = modelSettings.needsSpaceForStaander,
    this.config.maxWallWidth = afmetingen.maximaleMuurBreedte
    
    // console.log(afmetingen, boeideelSize)
    // console.log(extraRingBalk)
    
    this.x = x,
    this.y = y,
    this.z = z, 
    this.ringBalkGrootte = ringBalkGrootte
    this.staanderGrootte = staanderGrootte
    this.schoor = schoor
    this.aantalMuurVakken = aantalMuurVakken


    let gy = gordingBalk.y
    let gz = gordingBalk.z
    let ox = gordingBalk.ox
    let oy = gordingBalk.oy
    let oyl = gordingBalk.oyl
    // let kz = overstekKlos.enabled? overstekKlos.zDik :0 // dikte tussen
    // let kzp = overstekKlos.enabled? overstekKlos.zDikPaal :0 // dikte paal
    let kx = overstekKlos.enabled? (overstekKlos.klosVoEnabled? overstekKlos.xBovVo : 0) :0 // lengte bovenkant
    // let kxo = overstekKlos.enabled? overstekKlos.xOndVo :0 // lengte onderkant
    // let kyo = overstekKlos.enabled? overstekKlos.yKopVo :0 // hoogte kopkant
    // let ky = overstekKlos.enabled? overstekKlos.yHooVo :0 // hoogte
    let kl = overstekKlos.enabled? overstekKlos.klosLiEnabled :false
    let kr = overstekKlos.enabled? overstekKlos.klosReEnabled :false
    let kp = {a: overstekKlos.klosVoEnabled&&overstekKlos.klosAcEnabled, v:true, l:overstekKlos.klosLiEnabled, r:overstekKlos.klosReEnabled}
    let gs = gordingBalk.afstandTussenGordingen
    let rbgy = ringBalkGording.y
    let rbgz = ringBalkGording.z
    let rgbp = ringBalkGording.position
    let rby = ringBalkGrootte.dy
    let rbz = ringBalkGrootte.dz
    let dy = boeideelSize.enabled? boeideelSize.y :0 //170
    let dz = boeideelSize.enabled? boeideelSize.z :0 //18 
    let lijstEnabled = boeideelSize.lijstEnabled
    let dsz = boeideelSize.enabled? boeideelSize.lz :0 //18
    let dsy = boeideelSize.enabled? boeideelSize.ly :0 //60
    let lijstPosition = boeideelSize.enabled? boeideelSize.lijstPosition :false
    let dpy = boeideelSize.dpy
    let dpz = boeideelSize.dpz
    let dpok = boeideelSize.dpOffsetKopkant
    let dpog = boeideelSize.dpOffsetGootzijde
    let np = boeideelSize.nokpositie
    let byl = boeideelSize.byl
    let boeideelHorizontaleOffset = boeideelSize.horizontaleOffset
    let douglasPlanken = boeideelSize.douglasPlanken
    let rbtp = ringbalkTussenPaal
    let rbtpy = ringbalkTussenPaal.y
    let rbtpz = ringbalkTussenPaal.z
    let gsh = dak.gordingSchoorHoek
    let gso = dak.gordingSchoorOffset

    this.ox = ox
    this.oy = oy
    this.dak = {}
    this.dak.helling = dak.helling
    this.rbtpy = rbtpy
    this.gy = gy
    this.veranda = veranda

    const modelType = {
      isPlatdak: this.isPlatdak,
      isVeranda: this.isVeranda,
      isZadeldak: this.isZadeldak,
      isKapschuur: this.isKapschuur,
      isMonaco: this.isMonaco,
      isMilaan: this.isMilaan,
      isStockholm: this.isStockholm,
      isLissabon: this.isLissabon,
      isParijs: this.isParijs,
      isLagos: this.isLagos
    }
    this.modelType = modelType
    this.topgevelSpace = (!modelType.isPlatdak?z*0.3*0.3:0)
    this.poer = afmetingen.poer
    this.wallConfig = wallConfig
    this.dy = dy
    this.snijpuntY = (Math.tan(dak.helling*Math.PI/180)*z + 1523 + 2300)/2
    this.snijpuntX = (this.snijpuntY - 2300) / Math.tan(dak.helling*Math.PI/180)
    this.kapschuurConfig = {
      topX: this.snijpuntX,
      achtergevelHoogte: 1523,
      extraBalkjeDikte: 70
    }
    const radians = dak.helling*(Math.PI/180)
    const HalfDepth = this.modelType.isKapschuur ? (z-(y-this.kapschuurConfig.achtergevelHoogte)/Math.tan(radians))/2 : z/2
    const yMax = Math.tan(radians) * (HalfDepth + (dy/Math.sin(radians)) - (byl/Math.tan(radians)) + boeideelHorizontaleOffset) + boeideelSize.position + byl
    const yMin = boeideelSize.position
    const totalWidthVoor = (yMax-yMin+(dpy/(Math.cos(radians))) - (dpy*Math.cos(radians)))/Math.tan(radians) + (Math.cos(radians)*dpog) -(byl/Math.tan(radians))
    const totalWidthAchter = !this.modelType.isLissabon ? (yMax-yMin+(y-this.kapschuurConfig.achtergevelHoogte)+(dpy/(Math.cos(radians))) - (dpy*Math.cos(radians)))/Math.tan(radians) + (Math.cos(radians)*dpog) -(byl/Math.tan(radians)) : dy-staanderGrootte.dx
    const absoluteWidth = this.modelType.isKapschuur 
      ? Math.round((totalWidthVoor+totalWidthAchter)/2)
      : Math.round(totalWidthVoor)
    const totalOverhang = {
      a: veranda?.enabled
        ? veranda.dikte
        : this.modelType.isLissabon ? totalWidthAchter : (!this.isPlatdak?(absoluteWidth-z/2):(xBovAc+dz+((!lijstEnabled || lijstPosition)?0:dsz))),
      v: this.modelType.isLissabon ? Math.round(totalWidthVoor-this.snijpuntX) : (!this.isPlatdak?(absoluteWidth-z/2):(xBovVo+dz+((!lijstEnabled || lijstPosition)?0:dsz))),
      l: ((kl&&this.isPlatdak) ? xBovLi : 0)+dz+((!lijstEnabled || lijstPosition)?0:dsz) +(!this.isPlatdak?dak.overhang+dpok:0),
      r: ((kr&&this.isPlatdak) ? xBovRe : 0)+dz+((!lijstEnabled || lijstPosition)?0:dsz) +(!this.isPlatdak?dak.overhang+dpok:0)
    }
    this.totalOverhang = totalOverhang

    // vloer
    this.vloer.update(x, z)

    // ringblak 
    this.ringBalk.update(x, y, z, ringBalkGrootte.dy, ringBalkGrootte.dz, staanderGrootte.dx, extraRingBalk.enabled, extraRingBalk.gordingVorm, extraRingBalk.z, extraRingBalk.y, extraRingBalk.position, gy, overstekKlos, overstekKlos.enabled, modelType, dak.helling, ox, oy, rbtpy, this.kapschuurConfig)

    // staanders
    this.staanders.update(x, y, z, division, staanderGrootte.dx, staanderGrootte.dz, aantalMuurVakken, afmetingen.maximaleMuurBreedte, totalOverhang, op, dz, kx, kp, boeideelSize.position, this.topgevelSpace, this.poer, modelType, this.kapschuurConfig)

    // Gording
    // console.log(modelType)
    this.gording.update(x, y, z, division, gy, gz, overstekKlos, kp, gs, rbgy, rbgz, rgbp, rby, rbz, gordingBalk.position, this.staanders.staandersX, round, extraRingBalk.enabled, extraRingBalk.enabled&&extraRingBalk.position ? ringBalkGrootte.dz-extraRingBalk.z : 0, modelType, dak.helling, rbtp, rbtpy, rbtpz, ox, oy, oyl, gsh, gso, dak.overhang, staanderGrootte, this.kapschuurConfig, ringBalkGrootte.dy, ringBalkGrootte.dz)
    this.gording.group.position.y = gordingBalk.position //y +  ringBalkGrootte.dy
    this.gording.group.position.x = modelType.isPlatdak ? (overstekKlos.klosVoEnabled?-overstekKlos.xBovVo:0) : 0

    // Topgevel
    this.topgevel.update(modelType, x, y, z, dak.helling, gy, rbgz, division, totalOverhang, staanderGrootte.dx, ox, oy, this.kapschuurConfig)
    this.topgevel.group.position.y = y + ringBalkGrootte.dy
    if(this.isPlatdak) {
      this.topgevel.group.removeFromParent()
      this.topgevel.mapGroup.removeFromParent()
    } else {
      this.frame.add(this.topgevel.group)
      this.map.add(this.topgevel.mapGroup)
    }

    // boeidelen
    // console.log(dak.helling, dak.overhang, boeideelSize.position, dpy, dpz, dpok, dpog, np, this.wallConfig)
    this.boeideel.update(x, y, z, dy, dz, dsz, dsy, gy, overstekKlos, lijstPosition, boeideelSize.enabled, lijstEnabled, kp, op, y-boeideelSize.position, modelType, dak.helling, dak.overhang, boeideelSize.position, dpy, dpz, dpok, dpog, np, byl, douglasPlanken, this.wallConfig, this.kapschuurConfig, staanderGrootte.dx, boeideelHorizontaleOffset)
    this.boeideel.group.position.y = boeideelSize.position//y + dy / 2 + ry + gy - dyo

    // dakbeschot
    let by = dak.beschotDikte
    let dpo = dak.dakpanOverhang
    let dpoa = dak.dakpanOverhangAchter

    this.dakbeschot.update(x, y, z, by, overstekKlos, dak.offset, kp, modelType, dak.helling, dak.overhang, dpo, dpoa, ox, oy, this.kapschuurConfig)
    // turn off dakpannen als dakafwerking is Geen
    this.dakbeschot.setDakpanMeshVisibility(this.dakAfwerking.name !== 'Geen' && (this.modelType.isKapschuur || this.modelType.isZadeldak))
    this.dakbeschot.group.position.y = gordingBalk.position + (this.isPlatdak ? gy :rbtpy) + (by / 2)

    // dakbedekking
    let tz = trimSize.z
    let ty = trimSize.y
    let offset = trimSize.overhang+0.1

    let dby = boeideelSize.enabled ? (boeideelSize.position+dy-(gordingBalk.position+gy+by)) : ((trimSize.position+ty/2-10-gordingBalk.position-gy) - by - 10)
    let dbz = !boeideelSize.enabled&&extraRingBalk.enabled ? (y+ringBalkGrootte.dy > gordingBalk.position+gy+by ? ringBalkGrootte.dz-tz/2 +offset +10 : 10) : (dz+(lijstEnabled?dsz:0))

    this.dakbedekking.update(x, y, z, dz, dby, dbz, dsz, tz, offset, overstekKlos, lijstPosition, lijstEnabled, kp)
    this.dakbedekking.group.position.y = gordingBalk.position + gy + by + 10//y + ry + gy + by + 10
    // console.log(this.dakAfwerking.name)
    this.dakbedekking.group.visible = this.isPlatdak ? this.dakAfwerking.name === 'EPDM' : false
    // console.log(this.isPlatdak, this.dakAfwerking)

    // trim
    if(trimSize.enabled) {
      let trimColor = store.getters.getTextures.filter(color => color.id.toString() === trimSize.color[0].toString())[0]
      this.trim.update(x, y, z, tz, ty, overstekKlos, dz, dsz, (lijstPosition||!lijstEnabled) ? offset - dsz : offset, trimColor, kp)
      this.trim.group.position.y = trimSize.position+ty/2//y + ry + gy + dy - dyo
    }
    this.trim.group.visible = trimSize.enabled ? this.dakAfwerking.name === 'EPDM' : false

    //daklijn
    this.daklijn.update(x, z, totalOverhang, this.topgevelSpace, veranda)

    // veranda muur
    this.verandaMuur.update(modelType, x, z, veranda)

    // lichtstraat manager
    this.lichtstraatManager.update(modelType, x, y, z, gordingBalk.position+gy, staanderGrootte.dx, dak.beschotDikte)

    // muren 
    this.schoor0.update(schoor)

    if(!shouldNotResetWalls) {
      // muren x as
      const nStaandersX = this.staanders.nStaandersX
      const nStaandersZ = this.staanders.nStaandersZ

      //const nXSpacing = (x - (2 + nStaandersX) * staanderGrootte.dx) / ( nStaandersX + 1)

      this.muren = []

      let voorwandXSpacing = staanderGrootte.dx
      let achterwandXSpacing = x - staanderGrootte.dx
      for (let i = 0; i < nStaandersX+1; i++) {
        //voorwand
        this.muren.push({
          mx: division[i].width,
          my: y,
          mz: staanderGrootte.dx,
          x: voorwandXSpacing,
          y: 0,
          z: staanderGrootte.dx / 2,
          rotation: 1.5 * Math.PI
        })
        voorwandXSpacing += division[i].width
        voorwandXSpacing += staanderGrootte.dx

        //achterwand
        this.muren.push({
          mx: division[(nStaandersX)-i].width,
          my: y,
          mz: staanderGrootte.dx,
          x: achterwandXSpacing,
          y: 0,
          z: z - staanderGrootte.dx / 2,
          rotation: 0.5 * Math.PI
        })
        achterwandXSpacing -= division[(nStaandersX)-i].width
        achterwandXSpacing -= staanderGrootte.dx
      }

      // muren z as

      const nZSpacing = (z - (2 + nStaandersZ) * staanderGrootte.dz) / ( nStaandersZ + 1)

      for (let i = 0; i < nStaandersZ+1; i++) {
        this.muren.push({
          mx: nZSpacing,
          my: y,
          mz: staanderGrootte.dz,
          x: 0.5 * staanderGrootte.dz,
          y: 0,
          z: nZSpacing * (i + 1) + (i + 1) * staanderGrootte.dx,
          rotation: Math.PI
        })

        this.muren.push({
          mx: nZSpacing,
          my: y,
          mz: staanderGrootte.dz,
          x: x - 0.5 * staanderGrootte.dz,
          y: 0,
          z: nZSpacing * (i) + (i + 1) * staanderGrootte.dx,
          rotation: 0 * Math.PI
        })
      }

      // tussen muren z as
      // console.log(staanders)
      const nTussenZ = nStaandersX
      let tussenXSpacing = staanderGrootte.dx/2

      for (let i=0; i<nTussenZ; i++) {
        tussenXSpacing += division[i].width + staanderGrootte.dx
        for (let j = 0; j < nStaandersZ+1; j++) {
          this.muren.push({
            mx: nZSpacing,
            my: y,
            mz: staanderGrootte.dz,
            x: tussenXSpacing,
            y: 0,
            z: z - (j+1)*staanderGrootte.dx - j*nZSpacing,
            rotation: Math.PI,
            tussenMuur: true,
          })
        }
      }

      // print muren
      if (this.muren.length > this.muurGroup.children.length) {
        const delta = this.muren.length - this.muurGroup.children.length

        for (let i = 0; i < delta; i++) {
          const set = this.muren[i]

          const hoverLine0 = new HoverBox(
            {
              colorInsideOutline: 0xE3E6E9,
              colorInsideFill: 0xDAA877,
              colorOutsideOutline: 0xB3B6B9,
              colorOutsideFill: 0xDAA877,
              active: { 
                inside: true, 
                outside: true 
              },
              x: set.mx,//-400,
              z: 500, 
              ox: 500, 
              br: 50, 
              cp: 10, 
              t: 20,
              s: (500-this.staanderGrootte.dx)/2,
              d: 0,
              dx: this.staanderGrootte.dx,
            }
          ) //new HoverLine(THREE, set)
          const muur = new Muur(this.THREE, this.textureLoader, null, x, y, z, set, schoor, this.schoor0, hoverLine0, staanderGrootte, this.font, {frameTexture: this.textureFrame, y: y, dx: staanderGrootte.dx, dz: staanderGrootte.dz}, this.wallHover, fundering, null, totalOverhang, this.topgevelSpace, this.poer, this.wallConfig, this.dy, this.textureHoekprofielen, this.config, this.modelType, this.kapschuurConfig, ox, oy, dak.helling, rbtpy, gy, veranda)

          const newMuur = muur.group
          const newMapMuur = muur.mapGroup
          const newModelMuur = muur.modelGroup

          newMuur.rotation.y = set.rotation
          newMuur.position.z = set.x + muur.groupOffset.z
          newMuur.position.x = set.z + muur.groupOffset.x

          newMapMuur.rotation.y = set.rotation
          newMapMuur.position.z = set.x
          newMapMuur.position.x = set.z

          newModelMuur.rotation.y = set.rotation
          newModelMuur.position.z = set.x + muur.groupOffset.z
          newModelMuur.position.x = set.z + muur.groupOffset.x
          // console.log(newModelMuur.rotation.y)

          set.muur = newMuur
          this.muurInstances.push(muur)

          this.muurGroup.add(newMuur)
          this.mapMuurGroup.add(newMapMuur)
          this.modelMuurGroup.add(newModelMuur)
        }
      } else if (this.muren.length < this.muurGroup.children.length) {
        const nGoup = this.muurGroup.children.length
        // console.log('te lang')
        for (let i = this.muren.length; i < nGoup; i++) {
          const muur = this.muurGroup.children[this.muurGroup.children.length - 1]

          console.log(muur)
          /*muur.group.removeFromParent()
          muur.mapGroup.removeFromParent()
          muur.modelGroup.removeFromParent()*/
          
          /*const s0 = muur.children[0].children[0]
          const s1 = muur.children[0].children[1]*/
          /*const hoverLine0 = muur.children[1].children[0]
          const hoverLine1 = muur.children[1].children[1]
          console.log(muur.children[1])
          console.log(hoverLine0)*/
          /*s0.geometry.dispose()
          s0.removeFromParent()

          s1.geometry.dispose()
          s1.removeFromParent()*/
          
          /*hoverLine0.geometry.dispose()
          hoverLine0.removeFromParent()
          hoverLine1.geometry.dispose()
          hoverLine1.removeFromParent()*/
  
          this.muurGroup.children.pop()
          this.mapMuurGroup.children.pop()
          this.modelMuurGroup.children.pop()
          this.muurInstances.pop()
        }
      }
      // console.log(this.muurInstances)
      // console.log(this.muurInstances.length)
      //setTimeout(() => {

      for (let i = 0; i < this.muurInstances.length; i++) {
        this.muurInstances[i].staanderObject = {frameTexture: this.textureFrame, y: y, dx: staanderGrootte.dx, dz: staanderGrootte.dz}
        this.muurInstances[i].childWall = null
        this.muurInstances[i].settings.Cuuid = null
        this.muurInstances[i].parentWall = null
        this.muurInstances[i].settings.Puuid = null
        this.muurInstances[i].tussenChildWall = null
        this.muurInstances[i].settings.TCuuid = null
        this.muurInstances[i].tussenParentWall = null
        this.muurInstances[i].settings.TPuuid = null
        if(this.muurInstances[i].wallStaander) {
          this.muurInstances[i].wallStaander.removeFromParent()
          this.muurInstances[i].wallStaander = null
          this.muurInstances[i].mapWallStaander.removeFromParent()
          this.muurInstances[i].mapWallStaander = null
        }
      }

      let previousWallElement = null
      let previousBackWallElement = null
      let wallElementHistory = {}
      let frontWallIndex = 0
      let backWallIndex = 0
      //alert(this.muren.length + " " + this.muurInstances.length)
      for (let i = 0; i < this.muren.length; i++) {
        const set = this.muren[i]
        const muur = this.muurInstances[i]
        muur.totalOverhang = totalOverhang
        // console.log(this.wallConfig)
        muur.update(y, set, schoor, staanderGrootte, null, this.topgevelSpace, this.poer, this.wallConfig, dy, this.config, this.x, this.z, this.modelType, this.kapschuurConfig, ox, oy, dak.helling, rbtpy, gy, veranda, true)

        muur.group.rotation.y = set.rotation
        muur.group.position.z = set.x + muur.groupOffset.z
        muur.group.position.x = set.z + muur.groupOffset.x
        muur.mapGroup.rotation.y = set.rotation
        muur.mapGroup.position.z = set.x
        muur.mapGroup.position.x = set.z
        muur.modelGroup.rotation.y = set.rotation
        muur.modelGroup.position.z = set.x + muur.groupOffset.z
        muur.modelGroup.position.x = set.z + muur.groupOffset.x
        muur.previousWallWidth = set.mx

        if(i<(this.muren.length-((nStaandersZ+1) * (nStaandersX+2)))) {
          if(i % 2) {
            // achterwand
            wallElementHistory["achterMuur"+(nStaandersX-Math.floor(i/2))] = muur
            if(previousBackWallElement /*&& (division[backWallIndex].bergingEnabled === division[backWallIndex-1].bergingEnabled)*/) {
              previousBackWallElement.childWall = muur
              previousBackWallElement.settings.Cuuid = previousBackWallElement.childWall.settings.uuid
              previousBackWallElement.showWallStaander(this.textureFrame)
              previousBackWallElement.wallStaander.position.x = previousBackWallElement.set.mx + previousBackWallElement.staanderObject.dx/2
              previousBackWallElement.mapWallStaander.position.x = previousBackWallElement.set.mx + previousBackWallElement.staanderObject.dx/2
              muur.parentWall = previousBackWallElement
              muur.settings.Puuid = muur.parentWall.settings.uuid
              //newMuur.parentWall.position.y = 1000
            } 
            previousBackWallElement = muur
            backWallIndex++
            console.log(backWallIndex)
          } else {
            // voorwand
            wallElementHistory["voorMuur"+i/2] = muur
            if(previousWallElement /*&& (division[frontWallIndex].bergingEnabled === division[frontWallIndex-1].bergingEnabled)*/) {
              previousWallElement.childWall = muur
              previousWallElement.settings.Cuuid = previousWallElement.childWall.settings.uuid
              previousWallElement.showWallStaander(this.textureFrame)
              previousWallElement.wallStaander.position.x = previousWallElement.set.mx + previousWallElement.staanderObject.dx/2
              previousWallElement.mapWallStaander.position.x = previousWallElement.set.mx + previousWallElement.staanderObject.dx/2
              muur.parentWall = previousWallElement
              muur.settings.Puuid = muur.parentWall.settings.uuid
              //newMuur.parentWall.position.y = 1000
            } 
            previousWallElement = muur
            frontWallIndex++
            console.log(frontWallIndex)
          }
        } else {
          // zijwanden
          // console.log(i, nStaandersZ+1, nStaandersX+2)
          //muur.group.position.y=(300*i)
          if(i<(this.muren.length-((nStaandersZ+1) * (nStaandersX)))) {
            //buitenste wanden
            if(i % 2) {
              //rechterzijde
              if(wallElementHistory.rechterZijwand) {
                wallElementHistory.rechterZijwand.childWall = muur
                wallElementHistory.rechterZijwand.settings.Cuuid = wallElementHistory.rechterZijwand.childWall.settings.uuid
                wallElementHistory.rechterZijwand.showWallStaander(this.textureFrame)
                wallElementHistory.rechterZijwand.wallStaander.position.x = wallElementHistory.rechterZijwand.set.mx + wallElementHistory.rechterZijwand.staanderObject.dx/2
                wallElementHistory.rechterZijwand.mapWallStaander.position.x = wallElementHistory.rechterZijwand.set.mx + wallElementHistory.rechterZijwand.staanderObject.dx/2
                muur.parentWall = wallElementHistory.rechterZijwand
                muur.settings.Puuid = muur.parentWall.settings.uuid
              }
              wallElementHistory.rechterZijwand = muur
            } else {
              //linkerzijde
              if(wallElementHistory.linkerZijwand) {
                muur.childWall = wallElementHistory.linkerZijwand
                muur.settings.Cuuid = muur.childWall.settings.uuid
                muur.showWallStaander(this.textureFrame)
                muur.wallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
                muur.mapWallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
                wallElementHistory.linkerZijwand.parentWall = muur
                wallElementHistory.linkerZijwand.settings.Puuid = wallElementHistory.linkerZijwand.parentWall.settings.uuid
              }
              wallElementHistory.linkerZijwand = muur
            }
          } else {
            //binnenste wanden
            // console.log("binnenste wanden", i, nStaandersZ, nStaandersX)
            const binnenWandIndex = i-(nStaandersX+1)*2-(nStaandersZ+1)*2
            const linksNaarRechtsIndex = Math.floor(binnenWandIndex/(nStaandersZ+1))
            const achterNaarVoorIndex = binnenWandIndex%(nStaandersZ+1)
            console.log("binnenste wanden coords", binnenWandIndex, linksNaarRechtsIndex, achterNaarVoorIndex, i, nStaandersZ, nStaandersX)
            // if(!((i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))%(nStaandersZ+1))) {

            wallElementHistory[`tussenMuur_${linksNaarRechtsIndex}_${achterNaarVoorIndex}`] = muur
            if(achterNaarVoorIndex === 0) {
              console.log("binnenste wanden parent", i, nStaandersZ, nStaandersX)
              //de uiterste parentwall van een rijtje tussenmuren
              // console.log("gold", "tussenMuur"+(i-(this.muren.length-((nStaandersZ+1) * (nStaandersX)))), i, Math.floor((i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))/nStaandersX))
              // wallElementHistory["tussenMuur"+Math.floor((i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))/(nStaandersZ+1))] = muur
              console.log("retourlinkIndex",i, (this.muren.length-((nStaandersZ) * (nStaandersX))))
              // make connection with frontWall
              const voorWandIndex = i - (2*(nStaandersZ+1)) - (2*(nStaandersX+1)) - (nStaandersZ * Math.floor((i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))/(nStaandersZ+1)))
              console.log("voorwandindex", i, voorWandIndex, wallElementHistory, Math.floor((i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))/(nStaandersZ+1)))
              wallElementHistory["voorMuur"+voorWandIndex].tussenChildWall = muur
              wallElementHistory["voorMuur"+voorWandIndex].settings.TCuuid = muur.settings.uuid
              muur.tussenParentWall = wallElementHistory["voorMuur"+voorWandIndex]
              muur.settings.TPuuid = muur.tussenParentWall.settings.uuid

            } else {
              console.log("retourlinkIndex",i)
              console.log("binnenste wanden overig", i, nStaandersZ, nStaandersX)
              // console.log("gold", "tussenMuur"+(i-(this.muren.length-((nStaandersZ+1) * (nStaandersX)))), i, Math.floor((i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))/nStaandersX))
              //dit werkt misschien niet als er 3 muren in de diepte geplaatst worden
              // let tussenmuurParent = wallElementHistory["tussenMuur"+Math.floor((i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))/(nStaandersZ+1))]
              let tussenmuurParent = wallElementHistory[`tussenMuur_${linksNaarRechtsIndex}_${achterNaarVoorIndex-1}`]
              console.log("tussenmuurParent", tussenmuurParent, `tussenMuur_${linksNaarRechtsIndex}_${achterNaarVoorIndex-1}`)
              tussenmuurParent.childWall = muur
              tussenmuurParent.settings.Cuuid = tussenmuurParent.childWall.settings.uuid
              muur.parentWall = tussenmuurParent
              muur.settings.Puuid = muur.parentWall.settings.uuid
            }
            // if(!((i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))%nStaandersX)) {
            //   console.log("retourlinkIndex",i, 'origin')
            // } else {
            //   console.log("retourlinkIndex",i, 'child')
            // } 
            // console.log("retourlinkIndex",i, ((nStaandersZ+1) * (nStaandersX)), i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))), (i - (this.muren.length-((nStaandersZ+1) * (nStaandersX))))%nStaandersX)
          }
        }
      }
      console.log("binnenste wanden wallElementHistory", wallElementHistory)

      for(let i=0; i<this.muurInstances.length; i++) {
        this.muurInstances[i].setSchorenVisibility()
        this.muurInstances[i].updateMuurAfkapping()
      }

      // for (let k=this.muurInstances.length - ((nStaandersZ+1) * nTussenZ); k<this.muurInstances.length-nTussenZ; k++) {
      //   console.log(k, this.muurInstances.length, this.muurInstances.length-nTussenZ)
      //   //this.muurInstances[k].group.position.y = k*400
      //   //this.muurInstances[k+nTussenZ].group.position.y = k*400
      //   this.muurInstances[k].tussenChild = this.muurInstances[k+nTussenZ]
      //   this.muurInstances[k+nTussenZ].tussenParent = this.muurInstances[k]
      // }

      store.dispatch('updateIntersectingObjects')

      //}, 400);

      // for (let i = 0; i < this.muren.length; i++) {
      //   const set = this.muren[i]

      //   const s0 =  this.schoor0.group.clone()
      //   s0.position.y = y - schoor.sy
      //   s0.position.x = 0
      //   const s1 = s0.clone()
      //   s1.position.x = set.mx
      //   s1.rotation.y = Math.PI
      //   s1.position.z = schoor.sx

      //   const schoorGroup = new this.THREE.Group()
      //   schoorGroup.add(s0)
      //   schoorGroup.add(s1)

      //   const muur = new this.THREE.Group()
      //   muur.add(schoorGroup)

      //   muur.rotation.y = set.rotation
      //   muur.position.z = set.x
      //   muur.position.x = set.z
      //   set.muur = muur

      //   this.muurGroup.children[i] = muur
      // }

      // this.frame.position.x = - z / 2
      // this.frame.position.z = - x / 2


      division.forEach((div, divisionIndex) => {

        //tussenmuur
        if(divisionIndex < division.length-1) {
          // console.log(division[divisionIndex].bergingEnabled, division[divisionIndex+1].bergingEnabled)
          if(division[divisionIndex].bergingEnabled !== division[divisionIndex+1].bergingEnabled) {
            const tussenMuur = wallElementHistory["tussenMuur"+divisionIndex]
            //console.log(division[divisionIndex].bergingEnabled, division[divisionIndex+1].bergingEnabled)
            tussenMuur.addWall({
              binnenMuur: division[divisionIndex].bergingEnabled && !division[divisionIndex+1].bergingEnabled,
              binnenMuurTexture: tussenMuur.settings.binnenMuurTexture, 
              buitenMuur: !division[divisionIndex].bergingEnabled && division[divisionIndex+1].bergingEnabled,
              buitenMuurTexture: tussenMuur.settings.buitenMuurTexture,
              connectionBinnen: tussenMuur.settings.connectionBinnen,
              connectionBuiten: tussenMuur.settings.connectionBuiten
            })

            if(this.isZadeldak || this.isKapschuur) {
              const plusIconName = division[divisionIndex].bergingEnabled && !division[divisionIndex+1].bergingEnabled ? "plusIconTopgevelBinnen" : "plusIconTopgevelBuiten"
              this.topgevel.addTopgevel({
                topgevel: this.topgevel.topgevelArray[divisionIndex+1].group.getObjectByName(plusIconName),
                settings: {
                  topgevelBinnen: division[divisionIndex].bergingEnabled && !division[divisionIndex+1].bergingEnabled,
                  topgevelBuiten: !division[divisionIndex].bergingEnabled && division[divisionIndex+1].bergingEnabled,
                  topgevelBinnenTexture: this.topgevel.topgevelArray[divisionIndex+1].buitenMuurTexture,
                  topgevelBuitenTexture: this.topgevel.topgevelArray[divisionIndex+1].buitenMuurTexture,
                }
              })
            }
          }
        }

        if(div.bergingEnabled) {

          //voor muur
          const voorMuur = wallElementHistory["voorMuur"+divisionIndex]
          voorMuur.addWall({
            binnenMuur: false,
            binnenMuurTexture: voorMuur.settings.binnenMuurTexture,
            buitenMuur: true,
            buitenMuurTexture: voorMuur.settings.buitenMuurTexture,
            connectionBinnen: voorMuur.settings.connectionBinnen,
            connectionBuiten: voorMuur.settings.connectionBuiten
          })

          //achter muur
          const achterMuur = wallElementHistory["achterMuur"+divisionIndex]
          achterMuur.addWall({
            binnenMuur: false,
            binnenMuurTexture: achterMuur.settings.binnenMuurTexture,
            buitenMuur: true,
            buitenMuurTexture: achterMuur.settings.buitenMuurTexture,
            connectionBinnen: achterMuur.settings.connectionBinnen,
            connectionBuiten: achterMuur.settings.connectionBuiten
          })

          //linker muur
          if(divisionIndex === 0) {
            const linkerMuur = wallElementHistory.linkerZijwand
            linkerMuur.addWall({
              binnenMuur: false,
              binnenMuurTexture: linkerMuur.settings.binnenMuurTexture,
              buitenMuur: true,
              buitenMuurTexture: linkerMuur.settings.buitenMuurTexture,
              connectionBinnen: linkerMuur.settings.connectionBinnen,
              connectionBuiten: linkerMuur.settings.connectionBuiten
            })
            this.topgevel.addTopgevel({
              topgevel: this.topgevel.topgevelArray[0].group.getObjectByName("plusIconTopgevelBuiten"),
              settings: {
                topgevelBinnen: false,
                topgevelBuiten: true,
                topgevelBinnenTexture: this.topgevel.topgevelArray[0].buitenMuurTexture,
                topgevelBuitenTexture: this.topgevel.topgevelArray[0].buitenMuurTexture,
              }
            })
          }

          //rechter muur
          if(divisionIndex === division.length-1) {
            const rechterMuur = wallElementHistory.rechterZijwand
            rechterMuur.addWall({
              binnenMuur: false,
              binnenMuurTexture: rechterMuur.settings.binnenMuurTexture,
              buitenMuur: true,
              buitenMuurTexture: rechterMuur.settings.buitenMuurTexture,
              connectionBinnen: rechterMuur.settings.connectionBinnen,
              connectionBuiten: rechterMuur.settings.connectionBuiten
            })
            this.topgevel.addTopgevel({
              topgevel: this.topgevel.topgevelArray[this.topgevel.topgevelArray.length-1].group.getObjectByName("plusIconTopgevelBuiten"),
              settings: {
                topgevelBinnen: false,
                topgevelBuiten: true,
                topgevelBinnenTexture: this.topgevel.topgevelArray[this.topgevel.topgevelArray.length-1].buitenMuurTexture,
                topgevelBuitenTexture: this.topgevel.topgevelArray[this.topgevel.topgevelArray.length-1].buitenMuurTexture,
              }
            })
          }
        }
      })
    } else {
      // update plattegrond groepen voor de maatlijnen, deze moeten namelijk altijd buiten de daklijn zitten, en dit kan wel alsnog per model verschillen
      for (let i = 0; i < this.muren.length; i++) {
        const muur = this.muurInstances[i]
        if (!muur) continue
        muur.update(y, null, schoor, null)
        muur.totalOverhang = totalOverhang
        // muur.update(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true) 
        muur.updatePlattegrond()
      }
    }

    const topgevels = []
    if(this.topgevel) {
      this.topgevel.topgevelArray.forEach((topgevel) => {
        topgevels.push({
          binnenMuur: topgevel.binnenMuur,
          binnenMuurTexture: topgevel.binnenMuurTexture,
          buitenMuur: topgevel.buitenMuur,
          buitenMuurTexture: topgevel.buitenMuurTexture,
        })
      })
    }

    const walls = []
    this.muurInstances.forEach((muur) => {
      muur.settings.rotation = muur.group.rotation.y
      muur.settings.position = muur.group.position.clone()
      muur.settings.position.x -= muur.groupOffset.x
      muur.settings.position.z -= muur.groupOffset.z
      walls.push({rotation: muur.settings.rotation, position: muur.group.position, width: muur.set.mx})
      this.setHoekProfielen(muur)
      // muur.group.removeFromParent()
      // muur.mapGroup.removeFromParent()
      // muur.modelGroup.removeFromParent()
    })
    // this.muurInstances = []

    this.goot.update(x, y, z, dakgootSize, dakgoot, dak.overhang, staanderGrootte.dx, this.staanders, walls, modelType, this.kapschuurConfig, dz)
    if(dakgootSize.enabled) {
      this.frame.add(this.goot.group)
    } else {
      this.goot.group.removeFromParent()
    }

    return {
      walls: this.muurInstances.map((muur) => { return muur.settings }),
      topgevels: topgevels
    }
  }

  insertWall(parentWall, set, y, newWallShouldbeChild, _callback, wallStaanderTexture, noHover, insertedBySteellookPlacement, uuid) {
    this.hasFinishedPlacingNewWall = false
    const hoverLine0 = new HoverBox(
      {
        colorInsideOutline: 0xE3E6E9,
        colorInsideFill: 0xDAA877,
        colorOutsideOutline: 0xB3B6B9,
        colorOutsideFill: 0xDAA877,
        active: { 
          inside: true, 
          outside: true 
        },
        x: set.mx,//-400,
        z: 500, 
        ox: 500, 
        br: 50, 
        cp: 10, 
        t: 20,
        s: (500-this.staanderGrootte.dx)/2,
        d: 0,
        dx: this.staanderGrootte.dx,
      }
    )
    console.log("this.modelType", this.modelType)
    // const hoverIsoMainGroup = this.createHoverIsoMainGroup(2480/*set.mx*/,set.mz); // isolatie knoppen
    const hoverIsoMainGroup = this.createHoverIsoMainGroup();
    const mapIsoMainGroup = this.createMapIsoMainGroup();
    const muur = new Muur(this.THREE, this.textureLoader, null, this.x, y, this.z, set, this.schoor, this.schoor0, hoverLine0, this.staanderGrootte, this.font, {frameTexture: this.textureFrame, y: y, dx: this.staanderGrootte.dx, dz: this.staanderGrootte.dz}, !noHover, (this.muurInstances[0].betonBand ? true : false), null, this.totalOverhang, this.topgevelSpace, this.poer, this.wallConfig, this.dy, this.textureHoekprofielen, this.config, this.modelType, this.kapschuurConfig, this.ox, this.oy, this.dak.helling, this.rbtpy, this.gy, this.veranda, null, hoverIsoMainGroup,mapIsoMainGroup)
    muur.setHover(this.wallHover)
    if(this.selectedWallComponent) {
      this.setWallPlusIcons(this.selectedWallComponent)
    }
    if(parentWall) {
      if(newWallShouldbeChild) {
        if(parentWall.childWall) {
          parentWall.childWall.parentWall = muur
          parentWall.childWall.settings.Puuid = muur.settings.uuid
          muur.childWall = parentWall.childWall
          muur.settings.Cuuid = muur.childWall.settings.uuid
        }
        parentWall.childWall = muur
        parentWall.settings.Cuuid = parentWall.childWall.settings.uuid
        muur.parentWall = parentWall
        muur.settings.Puuid = muur.parentWall.settings.uuid
        /*if(muur.childWall) {
          if(!muur.wallStaander) {muur.showWallStaander(this.textureFrame)}
          muur.wallStaander.position.x = muur.set.mx + muur.staanderObject.dx
          muur.mapWallStaander.position.x = muur.set.mx + muur.staanderObject.dx
        }*/
      } else {
        if(parentWall.parentWall) {
          parentWall.parentWall.childWall = muur
          parentWall.parentWall.settings.Cuuid = parentWall.parentWall.childWall.settings.uuid
          muur.parentWall = parentWall.parentWall
          muur.settings.Puuid = muur.parentWall.settings.uuid
        }
        // console.log(parentWall)
        muur.childWall = parentWall
        muur.settings.Cuuid = muur.childWall.settings.uuid
        parentWall.parentWall = muur
        parentWall.settings.Puuid = parentWall.parentWall.settings.uuid
        muur.showWallStaander(this.textureFrame)
        muur.wallStaander.position.x = set.mx + this.staanderGrootte.dx/2 
        muur.mapWallStaander.position.x = set.mx + this.staanderGrootte.dx/2
      }
    }
    if(uuid) {
      muur.settings.uuid = uuid.uuid || muur.settings.uuid
      muur.settings.Puuid = uuid.Puuid || muur.settings.Puuid
      muur.settings.Cuuid = uuid.Cuuid || muur.settings.Cuuid
    }

    muur.updateMuurAfkapping()

    // console.log(parentWall.parentWall)
    const newMuur = muur.group
    const newMapMuur = muur.mapGroup
    const newModelMuur = muur.modelGroup

    newMuur.rotation.y = set.rotation
    newMuur.position.z = set.x + muur.groupOffset.z
    newMuur.position.x = set.z + muur.groupOffset.x

    newMapMuur.rotation.y = set.rotation
    newMapMuur.position.z = set.x
    newMapMuur.position.x = set.z

    newModelMuur.rotation.y = set.rotation
    newModelMuur.position.z = set.x + muur.groupOffset.z
    newModelMuur.position.x = set.z + muur.groupOffset.x

    this.muurInstances.push(muur)
    this.muren.push(set)

    this.muurGroup.add(newMuur)
    this.mapMuurGroup.add(newMapMuur)
    this.modelMuurGroup.add(newModelMuur)
    //hoverLine0.group.position.z=-2500
    //this.muurGroup.add(hoverLine0.group)
    //const testplaat = new Testplaat(this.THREE, 1000,1000,1000)
    //this.muurGroup.add(testplaat.group)

    if(wallStaanderTexture && muur.wallStaander === null && muur.childWall) {
      muur.showWallStaander(wallStaanderTexture)
      muur.wallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
      muur.mapWallStaander.position.x = muur.set.mx + muur.staanderObject.dx/2
    }





    //needs to insert tussenwanden
    if(!parentWall.alignsXAxis && parentWall.alignedRotation && (newWallShouldbeChild ? muur.childWall : true)) {
      this.insertTussenWanden(parentWall, newWallShouldbeChild, noHover, muur, wallStaanderTexture)
    } 

    if(store.state.models.selectedWallComponent.category === "Schuifwand") {
      const data = this.selectedWallComponent
      if (data) {
        const f = data.facadeOptions 
        const w = data.wallOptions
        this.componentIconPositions = [w.left, w.center, w.right, f.left, f.center, f.right]
        muur.setWallPlusIcons(this.componentIconPositions, this.selectedWallComponent, this.getEmptyChildWalls(muur), this.getEmptyParentWalls(muur))
        muur.setElementPlacing(true)
      }
    }


    if(insertedBySteellookPlacement) {
      if(this.selectedWallComponent) {
        this.setWallPlusIcons(this.selectedWallComponent)
      }
      store.dispatch('updateIntersectingObjects')
    }

    const hoofdthis = this
    if(_callback) {
      var loopje = setInterval(function() {
        if(muur.hasFinishedBuilding) {
          _callback(muur)
          hoofdthis.hasFinishedPlacingNewWall = true
          clearInterval(loopje)
        }
      },50)
    } else {
      this.hasFinishedPlacingNewWall = true
    }

    this.setHoekProfielen(muur)
  } 

  insertSteellook (parentWall, newWallShouldbeChild, set, model, map, modelCode, dimensions, settings) {
    const steellook = new SteellookComponent(this.THREE, this.textureLoader, this.y, set, dimensions, model, map, parentWall.staanderObject, this.font, this.totalOverhang, modelCode, null, this.topgevelSpace, this.poer, this.wallConfig, this.dy, this.textureHoekprofielen)
    steellook.settings.uuid = settings.uuid
    if(newWallShouldbeChild) {
      if(parentWall.childWall) {
        parentWall.childWall.parentWall = steellook
        parentWall.childWall.settings.Puuid = steellook.settings.uuid
        steellook.childWall = parentWall.childWall
        steellook.settings.Cuuid = steellook.childWall.settings.uuid
      }
      parentWall.childWall = steellook
      parentWall.settings.Cuuid = parentWall.childWall.settings.uuid
      steellook.parentWall = parentWall
      steellook.settings.Puuid = steellook.parentWall.settings.uuid
    } else {

      if(parentWall.parentWall) {
        parentWall.parentWall.childWall = steellook
        parentWall.parentWall.settings.Cuuid = parentWall.parentWall.childWall.settings.uuid
        steellook.parentWall = parentWall.parentWall
        steellook.settings.Puuid = steellook.parentWall.settings.uuid
      }
      // console.log(parentWall)
      steellook.childWall = parentWall
      steellook.settings.Cuuid = steellook.childWall.settings.uuid
      parentWall.parentWall = steellook
      parentWall.settings.Puuid = parentWall.parentWall.settings.uuid
      /*steellook.showWallStaander(this.textureFrame)
      // console.log(this.getChildrenWidth(parentWall.childWall))
      steellook.wallStaander.position.x = set.mx + this.staanderGrootte.dx/2 + this.getChildrenWidth(parentWall.childWall)
      steellook.mapWallStaander.position.x = set.mx + this.staanderGrootte.dx/2 + this.getChildrenWidth(parentWall.childWall)*/
    }

    if(steellook.childWall) {
      steellook.showWallStaander(this.textureFrame)
      steellook.wallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
      steellook.mapWallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
    }
    /*if(steellook.parentWall) {
      steellook.parentWall.showWallStaander(this.textureFrame)
      steellook.parentWall.wallStaander.position.x = steellook.parentWall.set.mx + steellook.staanderObject.dx/2
    }*/

    const newSteellook = steellook.group
    const newMapSteellook = steellook.mapGroup
    const newModelSteellook = steellook.modelGroup

    newSteellook.rotation.y = set.rotation
    newSteellook.position.z = set.x
    newSteellook.position.x = set.z

    newMapSteellook.rotation.y = set.rotation
    newMapSteellook.position.z = set.x
    newMapSteellook.position.x = set.z

    newModelSteellook.rotation.y = set.rotation
    newModelSteellook.position.z = set.x
    newModelSteellook.position.x = set.z

    this.muurInstances.push(steellook)
    this.muren.push(set)

    this.muurGroup.add(newSteellook)
    this.mapMuurGroup.add(newMapSteellook)
    this.modelMuurGroup.add(newModelSteellook)

    this.setHoekProfielen(newSteellook)
    //hoverLine0.group.position.z=-2500
    //this.muurGroup.add(hoverLine0.group)
    //const testplaat = new Testplaat(this.THREE, 1000,1000,1000)
    //this.muurGroup.add(testplaat.group)
  }

  insertTussenWanden (parentWall, newWallShouldbeChild, noHover, muur, wallStaanderTexture) {
    // alert('insert tussenwanden')
    console.log('inserted tussenwanden', newWallShouldbeChild, this, wallStaanderTexture)
    let previousTussenWand = null

    //if(parentWall.tussenChildWall || !parentWall.childWall) {console.log(parentWall.tussenChildWall, parentWall.childWall);return}

    for(let i=0; i<this.staanders.nStaandersZ+1; i++) {

      const wallWidth = (this.z - this.staanderGrootte.dx) / (this.staanders.nStaandersZ+1) - this.staanderGrootte.dx
      // console.log(wallWidth)
      const set = {
        mx: wallWidth,
        my: 2300,
        mz: this.staanderGrootte.dx,
        rotation: Math.PI,
        x: parentWall.group.position.z + parentWall.set.mx + this.staanderGrootte.dx/2,//60,
        y: parentWall.set.y,
        z: this.z - (i+1)*this.staanderGrootte.dx - i*wallWidth,
        tussenMuur: true
      }

      const hoverLine0 = new HoverBox(
        {
          colorInsideOutline: 0xE3E6E9,
          colorInsideFill: 0xDAA877,
          colorOutsideOutline: 0xB3B6B9,
          colorOutsideFill: 0xDAA877,
          active: { 
            inside: true, 
            outside: true 
          },
          x: set.mx,//-400,
          z: 500, 
          ox: 500, 
          br: 50, 
          cp: 10, 
          t: 20,
          s: (500-this.staanderGrootte.dx)/2,
          d: 0,
          dx: this.staanderGrootte.dx,
        }
      )
      // const hoverIsoMainGroup = this.createHoverIsoMainGroup(set.mx,set.mz); // isolatie knoppen
      // console.log('insertTussenWanden hoverIsoMainGroup',hoverIsoMainGroup)
      const hoverIsoMainGroup = this.createHoverIsoMainGroup();
      const mapIsoMainGroup = this.createMapIsoMainGroup();
      const tussenMuur = new Muur(this.THREE, this.textureLoader, null, this.x, this.y, this.z, set, this.schoor, this.schoor0, hoverLine0, this.staanderGrootte, this.font, {frameTexture: this.textureFrame, y: this.y, dx: this.staanderGrootte.dx, dz: this.staanderGrootte.dz}, !noHover, (this.muurInstances[0].betonBand ? true : false), null, this.totalOverhang, this.topgevelSpace, this.poer, this.wallConfig, this.dy, this.textureHoekprofielen, this.config, this.modelType, this.kapschuurConfig, this.ox, this.oy, this.dak.helling, this.rbtpy, this.gy, this.veranda, null, hoverIsoMainGroup,mapIsoMainGroup)
      tussenMuur.setHover(this.wallHover)
      if(this.selectedWallComponent) {
        this.setWallPlusIcons(this.selectedWallComponent)
      }

      if(previousTussenWand) {
        previousTussenWand.childWall = tussenMuur
        previousTussenWand.settings.Cuuid = tussenMuur.settings.uuid
        tussenMuur.parentWall = previousTussenWand
        tussenMuur.settings.Puuid = previousTussenWand.settings.uuid
      } else {
        if(muur.childWall) {
          if(muur.childWall && muur.childWall.isLocked && newWallShouldbeChild) {
            //transfer tussenwanden ownership
            muur.tussenChildWall = parentWall.tussenChildWall
            muur.settings.TCuuid = parentWall.settings.TCuuid
            if(muur.tussenChildWall) {
              muur.tussenChildWall.tussenParentWall = muur
              muur.tussenChildWall.settings.TPuuid = muur.settings.uuid
            }

            parentWall.tussenChildWall = tussenMuur
            parentWall.settings.TCuuid = tussenMuur.settings.uuid
            tussenMuur.tussenParentWall = parentWall
            tussenMuur.settings.TPuuid = parentWall.settings.uuid
          } else {
            muur.tussenChildWall = tussenMuur
            muur.settings.TCuuid = tussenMuur.settings.uuid
            tussenMuur.tussenParentWall = muur
            tussenMuur.settings.TPuuid = muur.settings.uuid
          }
        } else {
          parentWall.tussenChildWall = tussenMuur
          parentWall.settings.TCuuid = tussenMuur.settings.uuid
          tussenMuur.tussenParentWall = parentWall
          tussenMuur.settings.TPuuid = parentWall.settings.uuid
        }
      }
      previousTussenWand = tussenMuur

      tussenMuur.updateMuurAfkapping()

      const newTussenMuur = tussenMuur.group
      const newTussenMapMuur = tussenMuur.mapGroup
      const newTussenModelMuur = tussenMuur.modelGroup

      newTussenMuur.rotation.y = set.rotation
      newTussenMuur.position.z = set.x + tussenMuur.groupOffset.z
      newTussenMuur.position.x = set.z + tussenMuur.groupOffset.x

      newTussenMapMuur.rotation.y = set.rotation
      newTussenMapMuur.position.z = set.x
      newTussenMapMuur.position.x = set.z

      newTussenModelMuur.rotation.y = set.rotation
      newTussenModelMuur.position.z = set.x + tussenMuur.groupOffset.z
      newTussenModelMuur.position.x = set.z + tussenMuur.groupOffset.x

      this.muurInstances.push(tussenMuur)
      this.muren.push(set)

      this.muurGroup.add(newTussenMuur)
      this.mapMuurGroup.add(newTussenMapMuur)
      this.modelMuurGroup.add(newTussenModelMuur)
      //hoverLine0.group.position.z=-2500
      //this.muurGroup.add(hoverLine0.group)
      //const testplaat = new Testplaat(this.THREE, 1000,1000,1000)
      //this.muurGroup.add(testplaat.group)

      if(wallStaanderTexture && tussenMuur.wallStaander === null && tussenMuur.childWall) {
        tussenMuur.showWallStaander(wallStaanderTexture)
        tussenMuur.wallStaander.position.x = tussenMuur.set.mx + tussenMuur.staanderObject.dx/2
        tussenMuur.mapWallStaander.position.x = tussenMuur.set.mx + tussenMuur.staanderObject.dx/2
      }
    }
  }

  setWallHover (on) {
    this.wallHover = on
    for (let i = 0; i < this.muurInstances.length; i++) {
      const muur = this.muurInstances[i]
      muur.setHover(on)
    }
    this.topgevel.togglePlusIcons(on)
  }

  wallHoverEnter (data) {
    // console.log(data.wall)
    let muur
    if(data.wall.parent.name === "wall") { //if is map element
      muur = this.muurInstances.find((muur) => muur.group.uuid === data.wall.parent.uuid)
    } else {
      muur = this.muurInstances.find((muur) => muur.group.uuid === data.wall.parent.parent.parent.uuid)
    }
    this.lastEditedWall = muur
    muur.showPreview(data.settings, data.uuid)
  }

  wallHoverLeave (wall) {
    var muur;
    if(wall.parent.name === "hoverLineGroup" || wall.name === 'hoverLineBuiten' || wall.name === 'hoverLineBuiten') {
      muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.parent.parent.uuid)
    } else {
      muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.uuid)
    }
    if(muur !== undefined && muur !== null && muur.hidePreview) {
      muur.hidePreview(wall.name)
    }
  }

  setWallHoverShowInFuture (data) {
    let muur
    // console.log(data)
    if(data.wall.parent.name === "wall") { //if is map element
      muur = this.muurInstances.find((muur) => muur.group.uuid === data.wall.parent.uuid)
    } else {
      if(data.wall.parent.parent?.parent) {
        muur = this.muurInstances.find((muur) => muur.group.uuid === data.wall.parent.parent?.parent?.uuid)
      } else {
        return;
      }
    }
    if(muur) {
      if(data.side === 0) {
        muur.showInFuture.binnen = data.value
      } else if(data.side === 1 && muur.showInFuture) {
        muur.showInFuture.buiten = data.value
      }
    } else {
      //alert("GEEN MUUR GEVONDEN!")
    }
  }

  topgevelHoverEnter (data) {
    this.topgevel.showPreview(data)
  }

  topgevelHoverLeave (hoveredTopgevel) {
    this.topgevel.hidePreview(hoveredTopgevel)
  }

  setTopgevelHoverShowInFuture (data) {
    this.topgevel.setTopgevelHoverShowInFuture(data)
  }

  setElementPlacing (on) {
    this.shouldShowPlusIcons = on
    this.setWallPlusIcons(this.selectedWallComponent)
    for (let i = 0; i < this.muurInstances.length; i++) {
      const muur = this.muurInstances[i]
      muur.setElementPlacing(on)
    }
  }

  addWall (wall, settings) {
    let muur
    let index
    if(wall.parent.name === "wall") { //if is map element
      index = this.muurInstances.findIndex((muur) => muur.group.uuid === wall.parent.uuid)
      muur = this.muurInstances[index]
    } else {
      index = this.muurInstances.findIndex((muur) => muur.group.uuid === wall.parent.parent.parent.uuid)
      muur = this.muurInstances[index]
    }

    const hasRemovedBinnenMuur = settings.binnenMuur && muur.settings.binnenMuur
    const hasRemovedBuitenMuur = settings.buitenMuur && muur.settings.buitenMuur

    muur.addWall(settings)

    console.log("addWall log", JSON.parse(JSON.stringify(settings)), JSON.parse(JSON.stringify(muur.settings)), hasRemovedBinnenMuur, hasRemovedBuitenMuur)
    if(settings.binnenMuurTexture && settings.binnenMuurTexture) {
      if(settings.binnenMuurTexture.open !== settings.buitenMuurTexture.open && !muur.isBorstwering) {
        if(settings.buitenMuur && muur.settings.binnenMuur && !hasRemovedBuitenMuur) {
          settings.buitenMuur = false
          settings.binnenMuur = true
          muur.addWall(settings, true)
        } else if (settings.binnenMuur && muur.settings.buitenMuur && !hasRemovedBinnenMuur) {
          settings.buitenMuur = true
          settings.binnenMuur = false
          muur.addWall(settings, true)
        }
      }
    }

    this.setHoekProfielen(muur)

    if(this.wallConfig.position === "buiten" && wall.name==='hoverLineBuiten' && !settings.buitenMuurTexture.open) {
      this.muurInstances.forEach((muurLoop) => {
        if(!muurLoop.set.tussenMuur && (muur.settings.uuid !== muurLoop.settings.uuid) && !(muurLoop.settings.binnenMuur && muurLoop.settings.binnenMuurTexture.open) && !(muurLoop.settings.buitenMuur && muurLoop.settings.buitenMuurTexture.open)) {
          let newMuurSettings = {
            binnenMuur: muurLoop.settings.binnenMuur,
            binnenMuurTexture: muurLoop.settings.binnenMuurTexture,
            buitenMuur: muurLoop.settings.buitenMuur,
            buitenMuurTexture: settings.buitenMuurTexture,
            connectionBinnen: muurLoop.settings.connectionBinnen,
            connectionBuiten: settings.connectionBuiten,
          }
          muurLoop.updateWall(newMuurSettings, false)
        }
      })
    }

    if(!muur.settings.binnenMuur && !muur.settings.buitenMuur) {
      store.dispatch('deselectWall')
    }

    return {
      index: index,
      settings: muur.settings
    }
  }

  removeWall (wall) {
    //alert("REMOVE")
    let muur
    let isBorstwering = this.muurInstances.find((muur) => muur.group.uuid === wall?.parent?.uuid)?.isBorstwering
    if(wall.name === "topgevelSelectbox") {
      //topgevel
      this.topgevel.removeWall(wall)
    } else if(wall.parent.name != "wallBox" && !isBorstwering) {
      //wand
      muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.uuid)
      muur.removeWall()
    }else {
      //steellook
      if (isBorstwering) {
        muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.uuid)
        muur.set.y = muur.y
        muur.set.my = muur.y
      } else {
        muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.parent.uuid)
      }
      muur.group.removeFromParent()
      muur.mapGroup.removeFromParent()
      muur.modelGroup.removeFromParent()

      // console.log(this.muurInstances.length)
      this.muurInstances.splice(this.muurInstances.indexOf(muur), 1)
      // console.log(this.muurInstances.length)

      const hoofdthis = this
      const callBackfunction = (newMuur) => {

        this.removeTussenChildWalls(newMuur)

        if(muur.tussenChildWall) {
          newMuur.tussenChildWall = muur.tussenChildWall
          newMuur.settings.TCuuid = newMuur.tussenChildWall.settings.uuid
          newMuur.tussenChildWall.tussenParentWall = newMuur
          newMuur.tussenChildWall.settings.TPuuid = newMuur.settings.uuid
        }

        hoofdthis.calcRemoveWall(newMuur)

        const wallArray = []
        for(let k=0; k<hoofdthis.muurInstances.length; k++) {
          hoofdthis.muurInstances[k].settings.position = JSON.parse(JSON.stringify(hoofdthis.muurInstances[k].group.position))
          hoofdthis.muurInstances[k].settings.position.x -= hoofdthis.muurInstances[k].groupOffset.x
          hoofdthis.muurInstances[k].settings.position.z -= hoofdthis.muurInstances[k].groupOffset.z
          wallArray.push(hoofdthis.muurInstances[k].settings)
        }

        store.dispatch('updateWallArrayConfig', wallArray)
        //store.dispatch('updateConfig')
      }
      muur.deselect()
      console.log(muur)
      muur.set.x = muur.group.position.z
      muur.set.z = muur.group.position.x

      this.setHoekProfielen(muur)

      // POTENTIELE FIX VOOR ALS HET STUK HIERONDER NIET GOED WERKT
      // if(muur.parentWall) {
      //   muur.parentWall.childWall = muur.childWall
      //   if(muur.childWall) {
      //     muur.parentWall.settings.Cuuid = muur.childWall.settings.uuid
      //   } else {
      //     muur.parentWall.settings.Cuuid = null
        
      //   }
      // }
      // if(muur.childWall) {
      //   muur.childWall.parentWall = muur.parentWall
      //   if(muur.parentWall) {
      //     muur.childWall.settings.Puuid = muur.parentWall.settings.uuid
      //   } else {
      //     muur.childWall.settings.Puuid = null
      //   }
      // }

      if(muur.parentWall) {
        muur.parentWall.childWall = muur.childWall 
        if(muur.childWall) {
          muur.parentWall.settings.Cuuid = muur.parentWall.childWall.settings.uuid
          muur.childWall.parentWall = muur.parentWall
          muur.childWall.settings.Puuid = muur.childWall.parentWall.settings.uuid
        }
        this.insertWall(muur.parentWall, muur.set, muur.y, true, callBackfunction, this.textureFrame, true, true)
      } else if(muur.childWall) {
        muur.childWall.parentWall = muur.parentWall
        if(muur.parentWall) {
          muur.childWall.settings.Puuid = muur.parentWall.settings.uuid
        }
        this.insertWall(muur.childWall, muur.set, muur.y, false, callBackfunction, this.textureFrame, true, true)
      } else {
        this.insertWall(muur, muur.set, muur.y, false, callBackfunction, this.textureFrame, true, true)
      }
    }
    //this.deselectWall (wall)
    this.setElementPlacing(this.shouldShowPlusIcons)
    //muur.group.parent.remove(muur.group)
    //this.muurInstances.splice([this.muurInstances.findIndex((muur) => muur.group.uuid === wall.parent.uuid)],1)
  }

  async calcRemoveWall (wall) {
    var firstWall;
    // console.log(wall)
    // alert("calcremovewall")

    //var totalWidth = wall.set.mx
    wall.isLocked=false
    //var firstUnlockedParent = this.getFirstUnlockedParent(wall)
    
    firstWall = await this.getFirstUnlockedParent(wall)
    this.overigeRuimte = this.getEmptyChildWalls(firstWall, true)

    // if(!this.dat) {
    //   return
    // }
    // this.overigeRuimte.walls.pop()
    // this.overigeRuimte.totalWidth = 845+845


    let result = this.getEmptyChildWalls(firstWall)
    if(firstWall.childWall && !firstWall.childWall.isLocked){
      console.log(result.walls.length, result.totalWidth)
      //alert(result.walls.length + " > " + Math.ceil((result.totalWidth+wall.staanderObject.dx)/(this.config.maxWallWidth+wall.staanderObject.dx)))
      let amountOfWallsToRemove = result.walls.length - Math.ceil((result.totalWidth+(wall.staanderObject.dx*(result.walls.length)))/(this.config.maxWallWidth+wall.staanderObject.dx))
      console.log("CALC", amountOfWallsToRemove, (result.totalWidth+wall.staanderObject.dx)/(this.config.maxWallWidth+wall.staanderObject.dx), result.totalWidth+(wall.staanderObject.dx*(result.walls.length-1)), this.config.maxWallWidth+wall.staanderObject.dx)
      const wallsToRemove = []
      if(amountOfWallsToRemove > 0) {
        wallsToRemove.push(wall)
        amountOfWallsToRemove--
      }
      let currentLoopWall = wall
      for(let i=0; i<amountOfWallsToRemove; i++) {
        if(amountOfWallsToRemove > 0 && currentLoopWall.childWall) {
          wallsToRemove.push(currentLoopWall.childWall)
        } else {
          currentLoopWall = wall
          if(amountOfWallsToRemove > 0 && currentLoopWall.parentWall) {
            wallsToRemove.push(currentLoopWall.parentWall)
          }
        }
      }
      

      //if(result.walls.length>1 && ((result.walls.length-1) * (this.config.maxWallWidth+wall.staanderObject.dx) > result.totalWidth +wall.staanderObject.dx)){
      for(let i=0; i<wallsToRemove.length; i++) {
        //if there are too many wall than necessary
        wallsToRemove[i].group.removeFromParent()
        wallsToRemove[i].mapGroup.removeFromParent()
        wallsToRemove[i].modelGroup.removeFromParent()

        this.removeTussenChildWalls(wallsToRemove[i])
        if(!wallsToRemove[i].childWall && wallsToRemove[i].parentWall) {
          this.removeTussenChildWalls(wallsToRemove[i].parentWall)
        }

        if(wallsToRemove[i].parentWall) {
          if(wallsToRemove[i].childWall) {
            wallsToRemove[i].parentWall.childWall = wallsToRemove[i].childWall
            wallsToRemove[i].parentWall.settings.Cuuid = wallsToRemove[i].childWall.settings.uuid
            wallsToRemove[i].childWall.parentWall = wallsToRemove[i].parentWall
            wallsToRemove[i].childWall.settings.Puuid = wallsToRemove[i].parentWall.settings.uuid
          } else {
            wallsToRemove[i].parentWall.childWall = null
            wallsToRemove[i].parentWall.settings.Cuuid = null
          }
        } else {
          if(wallsToRemove[i].childWall) {
            wallsToRemove[i].childWall.parentWall = null
            wallsToRemove[i].childWall.settings.Puuid = null
          }
        }
        this.muurInstances.splice(this.muurInstances.indexOf(wallsToRemove[i]), 1)
        this.overigeRuimte.walls.splice(this.overigeRuimte.walls.indexOf(wallsToRemove[i]), 1)
        this.overigeRuimte.totalWidth += wallsToRemove[i].staanderObject.dx
      }

      


      firstWall = await this.getFirstUnlockedParent(wall)
      
      let shouldRenderChildFrame
      let addedWidth = 0
      const currentAxis = wall.alignsXAxis ? 'x' : 'z'
      const leftOrBack = wall.alignedRotation == wall.alignsXAxis
      console.log("this.overigeRuimte", JSON.parse(JSON.stringify(this.overigeRuimte.walls.map((muur) => {return muur.group.position.z}))), this.overigeRuimte.totalWidth)
      setTimeout(() => {
        console.log("this.overigeRuimte", JSON.parse(JSON.stringify(this.overigeRuimte.walls.map((muur) => {return muur.group.position.z}))), this.overigeRuimte.totalWidth)
      }, 5000);
      for(let m=0; m<this.overigeRuimte.walls.length; m++) {
        this.overigeRuimte.walls[m].set.mx = ((this.overigeRuimte.totalWidth)/this.overigeRuimte.walls.length) //+ (this.overigeRuimte.walls[m].wallStaander ? +this.overigeRuimte.walls[m].staanderObject.dx : 0)
        shouldRenderChildFrame = !(this.overigeRuimte.walls[m].settings.binnenMuur && this.overigeRuimte.walls[m].settings.buitenMuur)
        this.overigeRuimte.walls[m].update(this.overigeRuimte.walls[m].y, this.overigeRuimte.walls[m].set, this.overigeRuimte.walls[m].schoor, this.overigeRuimte.walls[m].staanderGrootte, shouldRenderChildFrame)
        this.overigeRuimte.walls[m].group.position[currentAxis] = firstWall.group.position[currentAxis]+ (leftOrBack? addedWidth*-1 : addedWidth) //+ m*(this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
        this.overigeRuimte.walls[m].mapGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
        this.overigeRuimte.walls[m].modelGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
        this.overigeRuimte.walls[m].previousWallWidth = this.overigeRuimte.walls[m].set.mx
        if(this.overigeRuimte.walls[m].wallStaander !== null) {
          this.overigeRuimte.walls[m].wallStaander.position.x = this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx/2
          this.overigeRuimte.walls[m].mapWallStaander.position.x = this.overigeRuimte.walls[m].wallStaander.position.x
        }
        addedWidth += this.overigeRuimte.walls[m].set.mx + firstWall.staanderObject.dx

        // move tussenchildwalls if any are connected
        if(this.overigeRuimte.walls[m].tussenChildWall) {
          this.setTussenMuurPosition(this.overigeRuimte.walls[m].tussenChildWall, currentAxis, this.overigeRuimte.walls[m].group.position[currentAxis]+this.overigeRuimte.walls[m].set.mx+firstWall.staanderObject.dx/2)
        }
      }
    }


    
    const wallArray = []
    for(let k=0; k<this.muurInstances.length; k++) {
      this.muurInstances[k].settings.position = JSON.parse(JSON.stringify(this.muurInstances[k].group.position))
      this.muurInstances[k].settings.position.x -= this.muurInstances[k].groupOffset.x
      this.muurInstances[k].settings.position.z -= this.muurInstances[k].groupOffset.z
      wallArray.push(this.muurInstances[k].settings)
    }
    console.log(wallArray)

    if(!this.isPlatdak && !wall.alignsXAxis && this.goot.dakgoot.front.enabled && (this.x > this.goot.thirdPipeThreshold)) {
      this.updateMiddenGootPosition()
    }

    this.setElementPlacing(this.shouldShowPlusIcons)

    // alert("jaja")
    store.dispatch('updateWallArrayConfig', wallArray)
    store.dispatch('updateConfig')
    store.dispatch('updateIntersectingObjects')

    this.updateGordingenDivision()
  }

  getWallSettings (wall) {
    let muur
    if(wall.name === "topgevelSelectbox") {
      muur = this.topgevel.topgevelArray.find((topgevel) => topgevel.group.uuid === wall.parent.uuid)
    } else {
      muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.uuid)
    }
    //console.log('getWallSettings -> muur',muur)
    return muur?.settings
  }

  updateWall (wall, settings, index, updateEntireWall, onlyUpdatePreview) {
    
    //updateEntireWall is only true if the caller comes from the history functions (if true this updates a lot more settings, thus slower)
    let i

    if (!wall) {
      i = index
    } else {
      i = this.muurInstances.findIndex((muur) => muur.group.uuid === wall.parent.uuid)
    }

    if(updateEntireWall) {
      i = this.muurInstances.findIndex((muur) => muur.settings.uuid === settings.uuid)
      this.muurInstances[i].settings = settings
      this.muurInstances[i].update()
      this.setHoekProfielen(this.muurInstances[i])
    } else {
      // if(this.wallConfig.position === "buiten" && !this.muurInstances[i].set.tussenMuur && settings.buitenMuur) {
      //   //console.log("dingdingding", settings.binnenMuur, settings.buitenMuur, i)
      //   this.muurInstances.forEach((muur) => {
      //     if(!muur.set.tussenMuur) {
      //       let newMuurSettings = {
      //         binnenMuur: muur.settings.binnenMuur,
      //         binnenMuurTexture: muur.settings.binnenMuurTexture,
      //         buitenMuur: muur.settings.buitenMuur,
      //         buitenMuurTexture: settings.buitenMuurTexture,
      //         connectionBinnen: muur.settings.connectionBinnen,
      //         connectionBuiten: settings.connectionBuiten,
      //       }
      //       newMuurSettings.buitenMuurTexture = settings.buitenMuurTexture
      //       newMuurSettings.connectionBuiten = settings.connectionBuiten
      //       console.log(newMuurSettings.binnenMuurTexture.name)
      //       muur.updateWall(newMuurSettings)
      //     }
      //   })
      // } else {
      //   console.error("dingdingding2", settings.binnenMuurTexture.name, settings.buitenMuurTexture.name, i)
      //   // if(this.muurInstances[i].settings.binnenMuur) {
      //   //   settings.binnenMuurTexture = this.muurInstances[i].settings.binnenMuurTexture
      //   // }
      //   // if(this.muurInstances[i].settings.buitenMuur) {
      //   //   settings.binnenMuurTexture = this.muurInstances[i].settings.buitenMuurTexture
      //   // }
      //   this.muurInstances[i].updateWall(settings)
      // }
      
      // hiero, hier gaat het mis
      if(this.wallConfig.position === "buiten" && !this.muurInstances[i].set.tussenMuur && !settings.buitenMuurTexture.open) {
        this.muurInstances.forEach((muur) => {
          if(!muur.set.tussenMuur && !(muur.settings.binnenMuur && muur.settings.binnenMuurTexture.open) && !(muur.settings.buitenMuur && muur.settings.buitenMuurTexture.open)) {
            let newMuurSettings = {
              binnenMuur: muur.settings.binnenMuur,
              binnenMuurTexture: muur.settings.binnenMuurTexture,
              buitenMuur: muur.settings.buitenMuur,
              buitenMuurTexture: settings.buitenMuurTexture,
              connectionBinnen: muur.settings.connectionBinnen,
              connectionBuiten: settings.connectionBuiten,
            }
            muur.updateWall(newMuurSettings, onlyUpdatePreview)
            console.log(settings.buitenMuurTexture.url)
          }
        })
      } 
      console.log("updateWall", settings)
      if(!('binnenMuur' in settings)) {settings.binnenMuur = this.muurInstances[i].settings.binnenMuur}
      if(!('binnenMuurTexture' in settings)) {settings.binnenMuurTexture = this.muurInstances[i].settings.binnenMuurTexture}
      if(!('buitenMuur' in settings)) {settings.buitenMuur = this.muurInstances[i].settings.buitenMuur}
      if(!('buitenMuurTexture' in settings)) {settings.buitenMuurTexture = this.muurInstances[i].settings.buitenMuurTexture}
      if(!('connectionBinnen' in settings)) {settings.connectionBinnen = this.muurInstances[i].settings.connectionBinnen}
      if(!('connectionBuiten' in settings)) {settings.connectionBuiten = this.muurInstances[i].settings.connectionBuiten}
      this.muurInstances[i].updateWall(settings, onlyUpdatePreview)
    }
    

    return {
      index: i,
      settings: this.muurInstances[i].settings
    }
  }

  updateWallWidth (wall, settings, index) {
    let i

    if (!wall) {
      i = index
    } else {
      i = this.muurInstances.findIndex((muur) => muur.group.uuid === wall.parent.uuid)
    }
    i = i = this.muurInstances.findIndex((muur) => muur.settings.uuid === settings.uuid)

    this.muurInstances[i].set.mx = settings.width
    this.muurInstances[i].previousWallWidth = settings.width

    return {
      index: i,
      settings: this.muurInstances[i].settings
    }
  }

  updateWallPosition (wall, settings, index) {
    let i

    if (!wall) {
      i = index
    } else {
      i = this.muurInstances.findIndex((muur) => muur.group.uuid === wall.parent.uuid)
    }
    i = i = this.muurInstances.findIndex((muur) => muur.settings.uuid === settings.uuid)

    settings.position.x += this.muurInstances[i].groupOffset.x
    settings.position.z += this.muurInstances[i].groupOffset.z
    this.muurInstances[i].updatePosition(settings.position)

    return {
      index: i,
      settings: this.muurInstances[i].settings
    }
  }

  selectWall (wall, clickedOnBorstweringAsset) {
    let muur
    console.log(wall)
    if(wall.name === "topgevelSelectbox"){
      this.topgevel.selectTopgevel(wall)
    }else{
      if(wall.parent.name != "wallBox") {
        muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.uuid)
      }else {
        muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.parent.uuid)
      }
      muur.select(clickedOnBorstweringAsset)
    }
  }

  deselectWall (wall) {
    // console.log(wall)
    let muur 
    if(wall.parent.name != "wallBox") {
      muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.uuid)
    }else {
      muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.parent.uuid)
    }
    if(muur) {
      muur.deselect()
    }
  }

  updateWallHoles (wall, holes, model, map, component, settings, Ewall, Elocation, Eside) {
    //variables that start with an 'E' are for the changing of the elements/components
    // console.log(component)
    // console.log(settings)
    let muur
    let location
    let side
    // console.log(Ewall)
    if(Ewall === undefined) {
      muur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.parent.uuid)
      location = wall.metaData.plusIndex
      side = wall.metaData.side
    } else {
      muur = Ewall
      location = Elocation
      side = Eside
    }
    console.log(component, settings)
    muur.updateHoles(holes, model, map, location, side, component, settings)
  }

  addSteelLookComponent (wall, dimensions, model, map, component, settings, modelCode) {
    console.log(wall, dimensions, model, map, component, settings, modelCode)
    console.log("walldata: " + wall.metaData.plusIndex)
    console.log("wallUUID", wall.parent.parent.uuid, wall.parent.parent)

    const mainMuur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.parent.uuid)
    this.muurInstances.forEach((muur) => {console.log("forEach Muur Instance UUID", muur.group.uuid)})
    console.log("forEach Muur Instance UUID -----------------------------------------------------------")
    console.log(mainMuur, wall)

    let posX
    let posZ
    switch(wall.metaData.plusIndex) {
      case 1:
        posX = mainMuur.group.position.z
        posZ = mainMuur.group.position.x
        if(mainMuur.alignsXAxis) {
          if(mainMuur.alignedRotation) {
            // linkerwand
            if(mainMuur.set.tussenMuur) {
              posX = mainMuur.group.position.z
            } else {
              posX = this.staanderGrootte.dz / 2//mainMuur.group.position.z
            }
            posZ = mainMuur.group.position.x
          } else {
            // rechterwand
            posX = this.x - this.staanderGrootte.dz / 2//mainMuur.group.position.z
            posZ = mainMuur.group.position.x 
          }
        }else{
          if(mainMuur.alignedRotation) {
            // voorwand
            posX = mainMuur.group.position.z
            posZ = this.staanderGrootte.dx / 2//mainMuur.group.position.x
          } else {
            // achterwand
            posX = mainMuur.group.position.z
            posZ = this.z - this.staanderGrootte.dx / 2//mainMuur.group.position.x
          }
        }
        break
      case 2:
        //posX = mainMuur.alignsXAxis ? mainMuur.group.position.z : mainMuur.group.position.z + (mainMuur.set.mx - dimensions.x)/2
        //posZ = mainMuur.alignsXAxis ? mainMuur.alignedRotation ? mainMuur.group.position.x-(mainMuur.set.mx - dimensions.x)/2 : mainMuur.group.position.x+(mainMuur.set.mx - dimensions.x)/2 : mainMuur.group.position.x //+ (mainMuur.set.mx - dimensions.x)/2
        if(mainMuur.alignsXAxis) {
          if(mainMuur.alignedRotation) {
            // linkerwand
            if(mainMuur.set.tussenMuur) {
              posX = mainMuur.group.position.z
            } else {
              posX = this.staanderGrootte.dz / 2//mainMuur.group.position.z
            }
            posZ = mainMuur.group.position.x-(mainMuur.set.mx - dimensions.x)/2
          } else {
            // rechterwand
            posX = this.x - this.staanderGrootte.dz / 2//mainMuur.group.position.z
            posZ = mainMuur.group.position.x+(mainMuur.set.mx - dimensions.x)/2
          }
        }else{
          if(mainMuur.alignedRotation) {
            // voorwand
            posX = mainMuur.group.position.z + (mainMuur.set.mx - dimensions.x)/2
            posZ = this.staanderGrootte.dx / 2//mainMuur.group.position.x
          } else {
            // achterwand
            posX = mainMuur.group.position.z - (mainMuur.set.mx - dimensions.x)/2
            posZ = this.z - this.staanderGrootte.dx / 2//mainMuur.group.position.x
          }
        }
        break
      case 3:
        if(mainMuur.alignsXAxis) {
          if(mainMuur.alignedRotation) {
            // linkerwand
            if(mainMuur.set.tussenMuur) {
              posX = mainMuur.group.position.z
            } else {
              posX = this.staanderGrootte.dz / 2//mainMuur.group.position.z
            }
            posZ = mainMuur.group.position.x-(mainMuur.set.mx-dimensions.x)
          } else {
            // rechterwand
            posX = this.x - this.staanderGrootte.dz / 2//mainMuur.group.position.z
            posZ = mainMuur.group.position.x + mainMuur.set.mx - dimensions.x
          }
        }else{
          if(mainMuur.alignedRotation) {
            // voorwand
            posX = mainMuur.group.position.z + mainMuur.set.mx - dimensions.x
            posZ = this.staanderGrootte.dx / 2//mainMuur.group.position.x
          } else {
            // achterwand
            posX = mainMuur.group.position.z - mainMuur.set.mx + dimensions.x
            posZ = this.z - this.staanderGrootte.dx / 2//mainMuur.group.position.x
          }
        }
        //posX = mainMuur.alignsXAxis ? mainMuur.group.position.z : mainMuur.group.position.z + mainMuur.set.mx - dimensions.x
        //posZ = mainMuur.alignsXAxis ? mainMuur.alignedRotation ? mainMuur.group.position.x-(mainMuur.set.mx-dimensions.x) : mainMuur.group.position.x + mainMuur.set.mx - dimensions.x : mainMuur.group.position.x
    }
    console.log(posX, posZ)
    const set = {
      mx: dimensions.x,
      my: dimensions.y - dimensions.yo,
      mz: mainMuur.staanderObject.dx,
      rotation: mainMuur.set.rotation,
      x: /*mainMuur.set.x,*/posX,
      y: mainMuur.set.y,
      z: /*mainMuur.set.z,*/posZ,//this.movingWall.previousWallWidth + this.movingWall.staanderObject.dx + this.getChildrenWidth(this.movingWall.childWall)//2600,
      tussenMuur: mainMuur.set.tussenMuur
    }
    // WARNING: please leave the settings param alone (null) in the line below to avoid uuid errors
    let steellook;

    if (component.category === "Steellook Wand BW") {
      const hoverLine0 = new HoverBox(
        {
          colorInsideOutline: 0xE3E6E9,
          colorInsideFill: 0xDAA877,
          colorOutsideOutline: 0xB3B6B9,
          colorOutsideFill: 0xDAA877,
          active: {
            inside: true,
            outside: true
          },
          x: set.mx,//-400,
          z: 500,
          ox: 500,
          br: 50,
          cp: 10,
          t: 20,
          s: (500 - this.staanderGrootte.dx) / 2,
          d: 0,
          dx: this.staanderGrootte.dx,
        }
      )
      const smallY = this.y - (dimensions.y - dimensions.yo)
      set.my = smallY
      set.y = smallY
      // isolatie knoppen steellook BW
      const hoverIsoMainGroup = this.createHoverIsoMainGroup(); // isolatie knoppen hover
      const mapIsoMainGroup = this.createMapIsoMainGroup(); // isolatie knoppen plattegrond
      steellook = new Muur(this.THREE, this.textureLoader, null, this.x, this.y, this.z, set, this.schoor, this.schoor0, hoverLine0, this.staanderGrootte, this.font, {frameTexture: this.textureFrame, y: this.y, dx: this.staanderGrootte.dx, dz: this.staanderGrootte.dz}, false, (this.muurInstances[0].betonBand ? true : false), null, this.totalOverhang, this.topgevelSpace, this.poer, this.wallConfig, this.dy, this.textureHoekprofielen, this.config, this.modelType, this.kapschuurConfig, this.ox, this.oy, this.dak.helling, this.rbtpy, this.gy, this.veranda, {wall, dimensions, model, map, component, settings, modelCode},hoverIsoMainGroup,mapIsoMainGroup)
      steellook.isSteellook = true
      steellook.isLocked = true
      steellook.setHover(this.wallHover)
    } else {
      steellook = new SteellookComponent(this.THREE, this.textureLoader, this.y, set, dimensions, model, map, mainMuur.staanderObject, this.font, this.totalOverhang, modelCode, null, this.topgevelSpace, this.poer, this.wallConfig, this.dy, this.textureHoekprofielen, settings.hasBetonBand)
    }

    const newMuur = steellook.group
    const newMapMuur = steellook.mapGroup
    const newModelMuur = steellook.modelGroup

    newMuur.rotation.y = set.rotation
    newMuur.position.z = set.x
    newMuur.position.x = set.z

    newMapMuur.rotation.y = set.rotation
    newMapMuur.position.z = set.x
    newMapMuur.position.x = set.z

    newModelMuur.rotation.y = set.rotation
    newModelMuur.position.z = set.x
    newModelMuur.position.x = set.z

    this.muurInstances.push(steellook)
    this.muren.push(set)

    this.muurGroup.add(newMuur)
    this.mapMuurGroup.add(newMapMuur)
    this.modelMuurGroup.add(newModelMuur)


    //needs to insert tussenwanden
    if(!mainMuur.alignsXAxis && mainMuur.alignedRotation) {
      if((mainMuur.childWall || wall.metaData.plusIndex !== 3) && !(mainMuur.set.mx === dimensions.x)) {
        this.insertTussenWanden(steellook, true, true, steellook, this.textureFrame)
      }
    } 

    
    const currentAxis = mainMuur.alignsXAxis ? 'x' : 'z'

    // skip repositioning of other elements if the dimensiosn of the steellook are the same as the main wall
    const shouldSkipPositioning = mainMuur.set.mx === dimensions.x
    
    switch(wall.metaData.plusIndex) {
      case 1:
        //right and front walls
        if((!mainMuur.alignsXAxis&&mainMuur.alignedRotation) || (mainMuur.alignsXAxis&&!mainMuur.alignedRotation)) {
          mainMuur.group.position[currentAxis] += dimensions.x + mainMuur.staanderObject.dx
          mainMuur.mapGroup.position[currentAxis] = mainMuur.group.position[currentAxis]
          mainMuur.modelGroup.position[currentAxis] = mainMuur.group.position[currentAxis]

          mainMuur.set.mx -= (steellook.set.mx + steellook.staanderObject.dx)
          
          steellook.childWall = mainMuur
          steellook.settings.Cuuid = steellook.childWall.settings.uuid
          if(mainMuur.parentWall) {
            mainMuur.parentWall.childWall = steellook
            mainMuur.parentWall.settings.Cuuid = mainMuur.parentWall.childWall.settings.uuid
            steellook.parentWall = mainMuur.parentWall
            steellook.settings.Puuid = steellook.parentWall.settings.uuid
          }
          mainMuur.parentWall = steellook
          mainMuur.settings.Puuid = mainMuur.parentWall.settings.uuid

          // const steellookTussenChildWall = steellook.tussenChildWall
          // steellook.tussenChildWall = mainMuur.tussenChildWall
          // steellook.settings.TCuuid = steellook.tussenChildWall ? steellook.tussenChildWall.settings.uuid : null
          // if(steellook.tussenChildWall) {
          //   steellook.tussenChildWall.tussenParentWall = steellook
          //   steellook.tussenChildWall.settings.TPuuid = steellook.settings.uuid
          // }
          // mainMuur.tussenChildWall = steellookTussenChildWall
          // mainMuur.settings.TCuuid = mainMuur.tussenChildWall ? mainMuur.tussenChildWall.settings.uuid : null
          // if(mainMuur.tussenChildWall) {
          //   mainMuur.tussenChildWall.tussenParentWall = mainMuur
          //   mainMuur.tussenChildWall.settings.TPuuid = mainMuur.settings.uuid
          // }

          // als steellook perfect past
          if(mainMuur.set.mx == -this.staanderGrootte.dx) {
            // set steellook tussen child walls
            steellook.tussenChildWall = mainMuur.tussenChildWall
            steellook.settings.TCuuid = steellook.tussenChildWall ? steellook.tussenChildWall.settings.uuid : null
            if(steellook.tussenChildWall) {
              steellook.tussenChildWall.tussenParentWall = steellook
              steellook.tussenChildWall.settings.TPuuid = steellook.settings.uuid
            }

            this.muurInstances.splice(this.muurInstances.indexOf(mainMuur), 1)
            mainMuur.group.removeFromParent()
            mainMuur.mapGroup.removeFromParent()
            mainMuur.modelGroup.removeFromParent()
            steellook.childWall = mainMuur.childWall
            steellook.settings.Cuuid = mainMuur.settings.Cuuid
            if(mainMuur.childWall) {
              mainMuur.childWall.parentWall = steellook
              mainMuur.childWall.settings.Puuid = steellook.settings.uuid
            }
            // this.removeTussenChildWalls(mainMuur)
          }

          if(steellook.wallStaander === null && steellook.childWall) {
            steellook.showWallStaander(this.textureFrame)
            steellook.wallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
            steellook.mapWallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
          }

          if(!shouldSkipPositioning) {
            if(mainMuur.set.mx < this.config.minWallWidth && mainMuur.childWall) {
              const diff = this.config.minWallWidth - mainMuur.set.mx
              this.overigeRuimte = this.getEmptyChildWalls(mainMuur.childWall)
              console.log(this.overigeRuimte)
              mainMuur.set.mx = this.config.minWallWidth
              mainMuur.update(mainMuur.y, mainMuur.set, mainMuur.schoor, mainMuur.staanderGrootte, true)
              mainMuur.childWall.group.position.z = mainMuur.childWall.group.position.z+diff
              let addedWidth = 0
              for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                this.overigeRuimte.lockedWalls[m].group.position.z = mainMuur.childWall.group.position.z+addedWidth //+ m*(this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
                this.overigeRuimte.lockedWalls[m].mapGroup.position.z = this.overigeRuimte.lockedWalls[m].group.position.z
                this.overigeRuimte.lockedWalls[m].modelGroup.position.z = this.overigeRuimte.lockedWalls[m].group.position.z
                if(this.overigeRuimte.lockedWalls[m].wallStaander !== null) {
                  this.overigeRuimte.lockedWalls[m].wallStaander.position.x = this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx/2
                  this.overigeRuimte.lockedWalls[m].mapWallStaander.position.x = this.overigeRuimte.lockedWalls[m].wallStaander.position.x
                }
                addedWidth += this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx
              }
              this.overigeRuimte.walls[0].set.mx -= diff
              this.overigeRuimte.walls[0].update(this.overigeRuimte.walls[0].y, this.overigeRuimte.walls[0].set, this.overigeRuimte.walls[0].schoor, this.overigeRuimte.walls[0].staanderGrootte, true)
              this.overigeRuimte.walls[0].group.position.z = this.overigeRuimte.walls[0].group.position.z+diff
              this.overigeRuimte.walls[0].mapGroup.position.z = this.overigeRuimte.walls[0].group.position.z
              this.overigeRuimte.walls[0].modelGroup.position.z = this.overigeRuimte.walls[0].group.position.z
            }
          }



        } else {
          // left and backwalls
          mainMuur.group.position[currentAxis] -= dimensions.x + mainMuur.staanderObject.dx
          mainMuur.mapGroup.position[currentAxis] = mainMuur.group.position[currentAxis]
          mainMuur.modelGroup.position[currentAxis] = mainMuur.group.position[currentAxis]

          mainMuur.set.mx -= (steellook.set.mx + steellook.staanderObject.dx)
          
          steellook.childWall = mainMuur
          steellook.settings.Cuuid = steellook.childWall.settings.uuid
          if(mainMuur.parentWall) {
            mainMuur.parentWall.childWall = steellook
            mainMuur.parentWall.settings.Cuuid = mainMuur.parentWall.childWall.settings.uuid
            steellook.parentWall = mainMuur.parentWall
            steellook.settings.Puuid = steellook.parentWall.settings.uuid
          }
          mainMuur.parentWall = steellook
          mainMuur.settings.Puuid = mainMuur.parentWall.settings.uuid

          if(mainMuur.set.mx == -this.staanderGrootte.dx) {
            this.muurInstances.splice(this.muurInstances.indexOf(mainMuur), 1)
            mainMuur.group.removeFromParent()
            mainMuur.mapGroup.removeFromParent()
            mainMuur.modelGroup.removeFromParent()
            steellook.childWall = mainMuur.childWall
            steellook.settings.Cuuid = mainMuur.settings.Cuuid
            if(mainMuur.childWall) {
              mainMuur.childWall.parentWall = steellook
              mainMuur.childWall.settings.Puuid = steellook.settings.uuid
            }
            this.removeTussenChildWalls(mainMuur)
          }

          if(steellook.wallStaander === null && steellook.childWall) {
            steellook.showWallStaander(this.textureFrame)
            steellook.wallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
            steellook.mapWallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
          }

          if(!shouldSkipPositioning) {
            if(mainMuur.set.mx < this.config.minWallWidth && mainMuur.childWall) {
              const diff = this.config.minWallWidth - mainMuur.set.mx
              this.overigeRuimte = this.getEmptyChildWalls(mainMuur.childWall)
              console.log(this.overigeRuimte)
              mainMuur.set.mx = this.config.minWallWidth
              mainMuur.update(mainMuur.y, mainMuur.set, mainMuur.schoor, mainMuur.staanderGrootte, true)
              mainMuur.childWall.group.position[currentAxis] = mainMuur.childWall.group.position[currentAxis]-diff
              let addedWidth = 0
              for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                this.overigeRuimte.lockedWalls[m].group.position[currentAxis] = mainMuur.childWall.group.position[currentAxis]+addedWidth //+ m*(this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
                this.overigeRuimte.lockedWalls[m].mapGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
                this.overigeRuimte.lockedWalls[m].modelGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
                if(this.overigeRuimte.lockedWalls[m].wallStaander !== null) {
                  this.overigeRuimte.lockedWalls[m].wallStaander.position.x = this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx/2
                  this.overigeRuimte.lockedWalls[m].mapWallStaander.position.x = this.overigeRuimte.lockedWalls[m].wallStaander.position.x
                }
                addedWidth += this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx
              }
              this.overigeRuimte.walls[0].set.mx -= diff
              this.overigeRuimte.walls[0].update(this.overigeRuimte.walls[0].y, this.overigeRuimte.walls[0].set, this.overigeRuimte.walls[0].schoor, this.overigeRuimte.walls[0].staanderGrootte, true)
              this.overigeRuimte.walls[0].group.position[currentAxis] = this.overigeRuimte.walls[0].group.position[currentAxis]-diff
              this.overigeRuimte.walls[0].mapGroup.position[currentAxis] = this.overigeRuimte.walls[0].group.position[currentAxis]
              this.overigeRuimte.walls[0].modelGroup.position[currentAxis] = this.overigeRuimte.walls[0].group.position[currentAxis]
            }
          }
        }
        break
      case 2: 
        mainMuur.set.mx = (mainMuur.set.mx - dimensions.x)/2 - mainMuur.staanderObject.dx
        // als steellook perfect past
        if(mainMuur.set.mx < 0) {
          this.muurInstances.splice(this.muurInstances.indexOf(mainMuur), 1)
          mainMuur.group.removeFromParent()
          mainMuur.mapGroup.removeFromParent()
          mainMuur.modelGroup.removeFromParent()
          if(mainMuur.childWall) {
            steellook.childWall = mainMuur.childWall
            steellook.settings.Cuuid = steellook.childWall.settings.uuid
            steellook.childWall.parentWall = steellook
            steellook.childWall.settings.Puuid = steellook.childWall.parentWall.settings.uuid
          } else {
            steellook.childWall = null
            steellook.settings.Cuuid = null
          }
          if(mainMuur.parentWall) {
            steellook.parentWall = mainMuur.parentWall
            steellook.settings.Puuid = steellook.parentWall.settings.uuid
            steellook.parentWall.childWall = steellook
            steellook.parentWall.settings.Cuuid = steellook.settings.uuid
          } else {
            steellook.parentWall = null
            steellook.settings.Puuid = null
          }
          steellook.tussenChildWall = mainMuur.tussenChildWall
          steellook.settings.TCuuid = steellook.tussenChildWall ? steellook.tussenChildWall.settings.uuid : null
          if(steellook.tussenChildWall) {
            steellook.tussenChildWall.tussenParentWall = steellook
            steellook.tussenChildWall.settings.TPuuid = steellook.settings.uuid
          }
          // this.removeTussenChildWalls(mainMuur)
          if(steellook.wallStaander === null && steellook.childWall) {
            steellook.showWallStaander(this.textureFrame)
            steellook.wallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
            steellook.mapWallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
          }
          store.dispatch('updateIntersectingObjects')
        } else {
          mainMuur.update(mainMuur.y, mainMuur.set, mainMuur.schoor, mainMuur.staanderGrootte, true, null, null, this.wallConfig)
          mainMuur.previousWallWidth = mainMuur.set.mx
          const mainMuurPosition = mainMuur.group.position.clone()

          const posX =  mainMuur.alignsXAxis ? 
                          mainMuur.set.x
                          : 
                          mainMuur.alignedRotation ? 
                            steellook.group.position.z + steellook.set.mx + steellook.staanderObject.dx 
                            : 
                            steellook.group.position.z-steellook.set.mx-steellook.staanderObject.dx
          const posZ =  mainMuur.alignsXAxis ? 
                          mainMuur.alignedRotation ? 
                            steellook.group.position.x-steellook.set.mx-mainMuur.staanderObject.dx 
                            : 
                            steellook.group.position.x+steellook.set.mx+mainMuur.staanderObject.dx 
                          : 
                          mainMuur.set.z

          console.log(posX, posZ)
          mainMuur.group.position.set(posZ+mainMuur.groupOffset.x, mainMuur.group.position.y, posX+mainMuur.groupOffset.z)
          mainMuur.mapGroup.position.set(posZ+mainMuur.groupOffset.x, mainMuur.mapGroup.position.y, posX+mainMuur.groupOffset.z)
          mainMuur.modelGroup.position.set(posZ+mainMuur.groupOffset.x, mainMuur.modelGroup.position.y, posX+mainMuur.groupOffset.z)

          // if(!mainMuur.wallStaander) {mainMuur.showWallStaander(this.textureFrame)}
          // mainMuur.wallStaander.position.x = mainMuur.set.mx + steellook.staanderObject.dx/2
          // mainMuur.mapWallStaander.position.x = mainMuur.set.mx + steellook.staanderObject.dx/2

          // steellook.childWall = mainMuur
          // steellook.settings.Cuuid = mainMuur.settings.uuid
          // //if(mainMuur.childWall) {
          //   mainMuur.parentWall = steellook
          //   mainMuur.settings.Puuid = steellook.settings.uuid
            // steellook.childWall = mainMuur.childWall
            // steellook.settings.Cuuid = steellook.childWall.settings.uuid
          //}
          // mainMuur.childWall = steellook
          // mainMuur.settings.Cuuid = mainMuur.childWall.settings.uuid

          console.log(mainMuurPosition)
          const set = {
            mx: mainMuur.set.mx,
            my: 2300,
            mz: mainMuur.staanderObject.dx,
            rotation: mainMuur.set.rotation,
            x: mainMuurPosition.z - mainMuur.groupOffset.z,
            y: mainMuur.set.y,
            z: mainMuurPosition.x - mainMuur.groupOffset.x,
            tussenMuur: mainMuur.set.tussenMuur
          }
          const callBack = (newMuur) => {
            if(newMuur.tussenChildWall) {
              this.setTussenMuurPosition(newMuur.tussenChildWall, 'z', newMuur.group.position.z + newMuur.set.mx + newMuur.staanderObject.dx/2)
            }

            if(mainMuur.parentWall) {
              newMuur.parentWall = mainMuur.parentWall
              newMuur.settings.Puuid = mainMuur.parentWall.settings.uuid
              newMuur.parentWall.childWall = newMuur
              newMuur.parentWall.settings.Cuuid = newMuur.settings.uuid
            }
            //   mainMuur.parentWall.childWall = newMuur
            //   mainMuur.parentWall.settings.Cuuid = newMuur.settings.uuid
            // }
            mainMuur.parentWall = steellook
            mainMuur.settings.Puuid = steellook.settings.uuid
            steellook.childWall = mainMuur
            steellook.settings.Cuuid = steellook.childWall.settings.uuid

            newMuur.childWall = steellook
            newMuur.settings.Cuuid = newMuur.childWall.settings.uuid
            steellook.parentWall = newMuur
            steellook.settings.Puuid = newMuur.settings.uuid

            if(mainMuur.tussenParentWall) {
              newMuur.tussenParentWall = mainMuur.tussenParentWall
              newMuur.settings.Puuid = mainMuur.tussenParentWall.settings.uuid
              newMuur.tussenParentWall.tussenChildWall = newMuur
              newMuur.tussenParentWall.settings.Cuuid = newMuur.settings.uuid
            }

            console.log(mainMuur.mapGroup.position)
            if(steellook.wallStaander === null && steellook.childWall) {
              steellook.showWallStaander(this.textureFrame)
              steellook.wallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
              steellook.mapWallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
            }


            // afronden, staat ook onderaad deze (addSteelLookComponent) functie
            const wallArray = []
            for(let k=0; k<this.muurInstances.length; k++) {
              this.muurInstances[k].settings.position = JSON.parse(JSON.stringify(this.muurInstances[k].group.position))
              this.muurInstances[k].settings.position.x -= this.muurInstances[k].groupOffset.x
              this.muurInstances[k].settings.position.z -= this.muurInstances[k].groupOffset.z
              wallArray.push(this.muurInstances[k].settings)
            }
            store.dispatch('updateWallArrayConfig', wallArray)

            console.log(steellook)
            store.dispatch('updateIntersectingObjects')
            this.setWallPlusIcons(this.selectedWallComponent)
            this.setElementPlacing(this.selectedWallComponent)

            for(let i=0; i<this.muurInstances.length; i++) {
              this.muurInstances[i].previousWallWidth = this.muurInstances[i].set.mx
            }

            if(!this.isPlatdak && !mainMuur.alignsXAxis && this.goot.dakgoot.front.enabled && (this.x > this.goot.thirdPipeThreshold)) {
              this.updateMiddenGootPosition()
            } else {console.log(wall)}

            this.setHoekProfielen(steellook)
            // eind van afronden

          }
          this.insertWall(steellook ,set, 2300, false, callBack, steellook.childWall ? this.textureFrame : null, true, true)
        }
        

        break
      case 3:
        if((!mainMuur.alignsXAxis&&mainMuur.alignedRotation) || (mainMuur.alignsXAxis&&!mainMuur.alignedRotation)) {
          mainMuur.group.position[currentAxis] -= dimensions.x + mainMuur.staanderObject.dx
          mainMuur.mapGroup.position[currentAxis] = mainMuur.group.position[currentAxis]
          mainMuur.modelGroup.position[currentAxis] = mainMuur.group.position[currentAxis]

          mainMuur.set.mx -= (dimensions.x + steellook.staanderObject.dx)

          steellook.parentWall = mainMuur
          steellook.settings.Puuid = steellook.parentWall.settings.uuid
          if(mainMuur.childWall) {
            mainMuur.childWall.parentWall = steellook
            mainMuur.childWall.settings.Puuid = mainMuur.childWall.parentWall.settings.uuid
            steellook.childWall = mainMuur.childWall
            steellook.settings.Cuuid = steellook.childWall.settings.uuid
          }
          mainMuur.childWall = steellook
          mainMuur.settings.Cuuid = mainMuur.childWall.settings.uuid

          const steellookTussenChildWall = steellook.tussenChildWall
          steellook.tussenChildWall = mainMuur.tussenChildWall
          steellook.settings.TCuuid = steellook.tussenChildWall ? steellook.tussenChildWall.settings.uuid : null
          if(steellook.tussenChildWall) {
            steellook.tussenChildWall.tussenParentWall = steellook
            steellook.tussenChildWall.settings.TPuuid = steellook.settings.uuid
          }
          mainMuur.tussenChildWall = steellookTussenChildWall
          mainMuur.settings.TCuuid = mainMuur.tussenChildWall ? mainMuur.tussenChildWall.settings.uuid : null
          if(mainMuur.tussenChildWall) {
            mainMuur.tussenChildWall.tussenParentWall = mainMuur
            mainMuur.tussenChildWall.settings.TPuuid = mainMuur.settings.uuid
          }

          if(mainMuur.set.mx == -this.staanderGrootte.dx) {
            this.muurInstances.splice(this.muurInstances.indexOf(mainMuur), 1)
            mainMuur.group.removeFromParent()
            mainMuur.mapGroup.removeFromParent()
            mainMuur.modelGroup.removeFromParent()
            steellook.parentWall = mainMuur.parentWall
            steellook.settings.Puuid = mainMuur.settings.Puuid
            if(mainMuur.parentWall) {
              mainMuur.parentWall.childWall = steellook
              mainMuur.parentWall.settings.Cuuid = steellook.settings.uuid
            }
            this.removeTussenChildWalls(mainMuur)
          }

          if(steellook.wallStaander === null && steellook.childWall) {
            steellook.showWallStaander(this.textureFrame)
            steellook.wallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
            steellook.mapWallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
          }

          if(!shouldSkipPositioning) {
            if(mainMuur.set.mx < this.config.minWallWidth && mainMuur.parentWall) {
              const diff = this.config.minWallWidth - mainMuur.set.mx
              this.overigeRuimte = this.getEmptyParentWalls(mainMuur.parentWall)
              console.log(this.overigeRuimte)
              mainMuur.set.mx = this.config.minWallWidth
              mainMuur.update(mainMuur.y, mainMuur.set, mainMuur.schoor, mainMuur.staanderGrootte, true)
              let addedWidth = 0
              for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                this.overigeRuimte.lockedWalls[m].group.position[currentAxis] = mainMuur.group.position[currentAxis] + diff - addedWidth- this.overigeRuimte.lockedWalls[m].set.mx - this.overigeRuimte.lockedWalls[m].staanderObject.dx
                console.log(this.overigeRuimte.lockedWalls[m].group.position[currentAxis], mainMuur.group.position[currentAxis], addedWidth, this.overigeRuimte.lockedWalls[m].set.mx)
                this.overigeRuimte.lockedWalls[m].mapGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
                this.overigeRuimte.lockedWalls[m].modelGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
                if(this.overigeRuimte.lockedWalls[m].wallStaander !== null) {
                  this.overigeRuimte.lockedWalls[m].wallStaander.position.x = this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx/2
                  this.overigeRuimte.lockedWalls[m].mapWallStaander.position.x = this.overigeRuimte.lockedWalls[m].wallStaander.position.x
                }
                addedWidth += this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx
              }
              this.overigeRuimte.walls[0].set.mx -= diff
              this.overigeRuimte.walls[0].update(this.overigeRuimte.walls[0].y, this.overigeRuimte.walls[0].set, this.overigeRuimte.walls[0].schoor, this.overigeRuimte.walls[0].staanderGrootte, true)
            }
          }




        } else {
          mainMuur.group.position[currentAxis] += dimensions.x + mainMuur.staanderObject.dx
          mainMuur.mapGroup.position[currentAxis] = mainMuur.group.position[currentAxis]
          mainMuur.modelGroup.position[currentAxis] = mainMuur.group.position[currentAxis]

          mainMuur.set.mx -= (dimensions.x + steellook.staanderObject.dx)

          steellook.parentWall = mainMuur
          steellook.settings.Puuid = steellook.parentWall.settings.uuid
          if(mainMuur.childWall) {
            mainMuur.childWall.parentWall = steellook
            mainMuur.childWall.settings.Puuid = mainMuur.childWall.parentWall.settings.uuid
            steellook.childWall = mainMuur.childWall
            steellook.settings.Cuuid = steellook.childWall.settings.uuid
          }
          mainMuur.childWall = steellook
          mainMuur.settings.Cuuid = mainMuur.childWall.settings.uuid

          if(mainMuur.set.mx == -this.staanderGrootte.dx) {
            this.muurInstances.splice(this.muurInstances.indexOf(mainMuur), 1)
            mainMuur.group.removeFromParent()
            mainMuur.mapGroup.removeFromParent()
            mainMuur.modelGroup.removeFromParent()
            steellook.parentWall = mainMuur.parentWall
            steellook.settings.Puuid = mainMuur.settings.Puuid
            if(mainMuur.parentWall) {
              mainMuur.parentWall.childWall = steellook
              mainMuur.parentWall.settings.Cuuid = steellook.settings.uuid
            }
            this.removeTussenChildWalls(mainMuur)
          }

          if(steellook.wallStaander === null && steellook.childWall) {
            steellook.showWallStaander(this.textureFrame)
            steellook.wallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
            steellook.mapWallStaander.position.x = steellook.set.mx + steellook.staanderObject.dx/2
          }

          if(!shouldSkipPositioning) {
            if(mainMuur.set.mx < this.config.minWallWidth && mainMuur.parentWall) {
              const diff = this.config.minWallWidth - mainMuur.set.mx
              this.overigeRuimte = this.getEmptyParentWalls(mainMuur.parentWall)
              console.log(this.overigeRuimte)
              mainMuur.set.mx = this.config.minWallWidth
              mainMuur.update(mainMuur.y, mainMuur.set, mainMuur.schoor, mainMuur.staanderGrootte, true)
              let addedWidth = 0
              for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                this.overigeRuimte.lockedWalls[m].group.position[currentAxis] = mainMuur.group.position[currentAxis] + diff - addedWidth- this.overigeRuimte.lockedWalls[m].set.mx - this.overigeRuimte.lockedWalls[m].staanderObject.dx
                console.log(this.overigeRuimte.lockedWalls[m].group.position[currentAxis], mainMuur.group.position[currentAxis], addedWidth, this.overigeRuimte.lockedWalls[m].set.mx)
                this.overigeRuimte.lockedWalls[m].mapGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
                this.overigeRuimte.lockedWalls[m].modelGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
                if(this.overigeRuimte.lockedWalls[m].wallStaander !== null) {
                  this.overigeRuimte.lockedWalls[m].wallStaander.position.x = this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx/2
                  this.overigeRuimte.lockedWalls[m].mapWallStaander.position.x = this.overigeRuimte.lockedWalls[m].wallStaander.position.x
                }
                addedWidth += this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx
              }
              this.overigeRuimte.walls[0].set.mx -= diff
              this.overigeRuimte.walls[0].update(this.overigeRuimte.walls[0].y, this.overigeRuimte.walls[0].set, this.overigeRuimte.walls[0].schoor, this.overigeRuimte.walls[0].staanderGrootte, true)
            }
          }
        }
        if(!mainMuur.wallStaander && mainMuur.childWall) {
          mainMuur.showWallStaander(this.textureFrame)
          mainMuur.wallStaander.position.x = mainMuur.set.mx + steellook.staanderObject.dx/2
          mainMuur.mapWallStaander.position.x = mainMuur.set.mx + steellook.staanderObject.dx/2
        }
        
    }
      

    



    if(wall.metaData.plusIndex == 1) {
      this.overigeRuimte = this.getEmptyChildWalls(steellook.childWall, true)
      console.log("overigeRuimteStructuredClone", this.overigeRuimte, steellook.childWall)
      let result = this.overigeRuimte 
      console.log(result)
      if(result.totalWidth +this.staanderGrootte.dx <= (this.config.maxWallWidth*(result.walls.length-1)) && result.walls.length>1){
        if(result.walls.length > 1) {
          //remove extra wall
          console.log(result.walls.length, "remove extra wall")
          
          result.walls[0].group.removeFromParent()
          result.walls[0].mapGroup.removeFromParent()
          result.walls[0].modelGroup.removeFromParent()

          this.removeTussenChildWalls(result.walls[0])

          if(result.walls[0].childWall) {
            console.log("eerste")
            steellook.childWall = result.walls[0].childWall
            steellook.settings.Cuuid = result.walls[0].childWall.settings.uuid
            result.walls[0].childWall.parentWall = steellook
            result.walls[0].childWall.settings.Puuid = steellook.settings.uuid
          } else {
            console.log("tweede")
            steellook.childWall = null
            steellook.settings.Cuuid = null
            if(steellook.wallStaander) {
              steellook.wallStaander.removeFromParent()
              steellook.wallStaander = null
              steellook.mapWallStaander.removeFromParent()
              steellook.mapWallStaander = null
            }
          }

          result.walls[1].group.position.x = result.walls[0].group.position.x
          result.walls[1].group.position.z = result.walls[0].group.position.z

          result.walls[1].previousWallWidth += result.walls[0].previousWallWidth + result.walls[0].staanderObject.dx
          console.log("index: ", this.muurInstances.indexOf(result.walls[0]))
          this.muurInstances.splice(this.muurInstances.indexOf(result.walls[0]), 1)

          this.overigeRuimte.walls = this.getEmptyChildWalls(steellook.childWall).walls
          this.overigeRuimte.totalWidth += steellook.childWall.staanderObject.dx
          this.overigeRuimte.totalWidthStart += steellook.childWall.staanderObject.dx
        }
      }

      let addedWidth = 0

      for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
        this.overigeRuimte.lockedWalls[m].group.position[currentAxis] = steellook.childWall.group.position[currentAxis]+addedWidth //+ m*(this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
        this.overigeRuimte.lockedWalls[m].mapGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
        this.overigeRuimte.lockedWalls[m].modelGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
        if(this.overigeRuimte.lockedWalls[m].wallStaander !== null) {
          this.overigeRuimte.lockedWalls[m].wallStaander.position.x = this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx/2
          this.overigeRuimte.lockedWalls[m].mapWallStaander.position.x = this.overigeRuimte.lockedWalls[m].wallStaander.position.x
        }
        if((!mainMuur.alignsXAxis&&mainMuur.alignedRotation) || (mainMuur.alignsXAxis&&!mainMuur.alignedRotation)) {
          addedWidth += this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx
        } else {
          addedWidth -= (this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx)
        }

        // move tussenchildwalls if any are connected
        if(this.overigeRuimte.lockedWalls[m].tussenChildWall) {
          this.setTussenMuurPosition(this.overigeRuimte.lockedWalls[m].tussenChildWall, currentAxis, this.overigeRuimte.lockedWalls[m].group.position[currentAxis]+this.overigeRuimte.lockedWalls[m].set.mx+this.overigeRuimte.lockedWalls[m].staanderObject.dx/2)
        }
      }

      for(let m=0; m<this.overigeRuimte.walls.length; m++) {
        this.overigeRuimte.walls[m].set.mx = ((this.overigeRuimte.totalWidth)/this.overigeRuimte.walls.length) //+ (this.overigeRuimte.walls[m].wallStaander ? +this.overigeRuimte.walls[m].staanderObject.dx : 0)
        this.overigeRuimte.walls[m].update(this.overigeRuimte.walls[m].y, this.overigeRuimte.walls[m].set, this.overigeRuimte.walls[m].schoor, this.overigeRuimte.walls[m].staanderGrootte)
        this.overigeRuimte.walls[m].group.position[currentAxis] = steellook.childWall.group.position[currentAxis]+addedWidth //+ m*(this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
        this.overigeRuimte.walls[m].mapGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
        this.overigeRuimte.walls[m].modelGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
        if(this.overigeRuimte.walls[m].wallStaander !== null) {
          this.overigeRuimte.walls[m].wallStaander.position.x = this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx/2
          this.overigeRuimte.walls[m].mapWallStaander.position.x = this.overigeRuimte.walls[m].wallStaander.position.x
        }
        if((!mainMuur.alignsXAxis&&mainMuur.alignedRotation) || (mainMuur.alignsXAxis&&!mainMuur.alignedRotation)) {
          addedWidth += this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx
        } else {
          addedWidth -= (this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
        }

        // move tussenchildwalls if any are connected
        if(this.overigeRuimte.walls[m].tussenChildWall) {
          this.setTussenMuurPosition(this.overigeRuimte.walls[m].tussenChildWall, currentAxis, this.overigeRuimte.walls[m].group.position[currentAxis]+this.overigeRuimte.walls[m].set.mx+this.overigeRuimte.walls[m].staanderObject.dx/2)
        }
      }
    }

    else if(wall.metaData.plusIndex == 3) {
      this.overigeRuimte = this.getEmptyParentWalls(steellook.parentWall, true)
      console.log("overigeRuimteStructuredClone", this.overigeRuimte, steellook.parentWall)
      // if(!steellook.childWall) {
      //   alert("OMHOOG")
      //   steellook.parentWall.group.position.y=1500
      //   return
      // }
      let result = this.overigeRuimte 
      if(result.totalWidth +this.staanderGrootte.dx <= (this.config.maxWallWidth*(result.walls.length-1)) && result.walls.length>1){
        if(result.walls.length > 1) {
          //remove extra wall
          console.log(result.walls.length, "remove extra wall")
                  
          result.walls[0].group.removeFromParent()
          result.walls[0].mapGroup.removeFromParent()
          result.walls[0].modelGroup.removeFromParent()

          this.removeTussenChildWalls(result.walls[0])

          if(result.walls[0].parentWall) {
            console.log("eerste")
            steellook.parentWall = result.walls[0].parentWall
            steellook.settings.Puuid = result.walls[0].parentWall.settings.uuid
            steellook.parentWall.childWall = steellook
            steellook.parentWall.settings.Cuuid = steellook.settings.uuid
          } else {
            console.log("tweede")
            steellook.parentWall = null
            steellook.settings.Puuid = null
          }
          // result.walls[0].previousWallWidth += result.walls[1].previousWallWidth + result.walls[0].staanderObject.dx
          console.log("index: ", this.muurInstances.indexOf(result.walls[0]))
          this.muurInstances.splice(this.muurInstances.indexOf(result.walls[0]), 1)

          this.overigeRuimte.walls = this.getEmptyParentWalls(steellook.parentWall).walls
          this.overigeRuimte.totalWidth += steellook.parentWall.staanderObject.dx
          this.overigeRuimte.totalWidthStart += steellook.parentWall.staanderObject.dx
        }
      }

      let addedWidth = 0
      // if(!this.dit) {return}
      for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
        if((!mainMuur.alignsXAxis&&mainMuur.alignedRotation) || (mainMuur.alignsXAxis&&!mainMuur.alignedRotation)) {
          this.overigeRuimte.lockedWalls[m].group.position[currentAxis] = steellook.parentWall.group.position[currentAxis]-addedWidth //+ m*(this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
        } else {
          this.overigeRuimte.lockedWalls[m].group.position[currentAxis] = steellook.parentWall.group.position[currentAxis]+addedWidth
        }
        this.overigeRuimte.lockedWalls[m].mapGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
        this.overigeRuimte.lockedWalls[m].modelGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
        if(this.overigeRuimte.lockedWalls[m].wallStaander !== null) {
          this.overigeRuimte.lockedWalls[m].wallStaander.position.x = this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx/2
          this.overigeRuimte.lockedWalls[m].mapWallStaander.position.x = this.overigeRuimte.lockedWalls[m].wallStaander.position.x
        }
        addedWidth += this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx

        // move tussenchildwalls if any are connected
        if(this.overigeRuimte.lockedWalls[m].tussenChildWall) {
          this.setTussenMuurPosition(this.overigeRuimte.lockedWalls[m].tussenChildWall, currentAxis, this.overigeRuimte.lockedWalls[m].group.position[currentAxis]+this.overigeRuimte.lockedWalls[m].set.mx+this.overigeRuimte.lockedWalls[m].staanderObject.dx/2)
        }
      }

      for(let m=0; m<this.overigeRuimte.walls.length; m++) {
        this.overigeRuimte.walls[m].set.mx = ((this.overigeRuimte.totalWidth)/this.overigeRuimte.walls.length) //+ (this.overigeRuimte.walls[m].wallStaander ? +this.overigeRuimte.walls[m].staanderObject.dx : 0)
        this.overigeRuimte.walls[m].update(this.overigeRuimte.walls[m].y, this.overigeRuimte.walls[m].set, this.overigeRuimte.walls[m].schoor, this.overigeRuimte.walls[m].staanderGrootte)
        if((!mainMuur.alignsXAxis&&mainMuur.alignedRotation) || (mainMuur.alignsXAxis&&!mainMuur.alignedRotation)) {
          this.overigeRuimte.walls[m].group.position[currentAxis] = steellook.group.position[currentAxis]-this.overigeRuimte.walls[m].set.mx-this.overigeRuimte.walls[m].staanderObject.dx-addedWidth //+ m*(this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
        } else {
          this.overigeRuimte.walls[m].group.position[currentAxis] = steellook.group.position[currentAxis]+this.overigeRuimte.walls[m].set.mx+this.overigeRuimte.walls[m].staanderObject.dx+addedWidth
        }
        this.overigeRuimte.walls[m].mapGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
        this.overigeRuimte.walls[m].modelGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
        if(this.overigeRuimte.walls[m].wallStaander !== null) {
          this.overigeRuimte.walls[m].wallStaander.position.x = this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx/2
          this.overigeRuimte.walls[m].mapWallStaander.position.x = this.overigeRuimte.walls[m].wallStaander.position.x
        }
        addedWidth += this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx

        // move tussenchildwalls if any are connected
        if(this.overigeRuimte.walls[m].tussenChildWall) {
          this.setTussenMuurPosition(this.overigeRuimte.walls[m].tussenChildWall, currentAxis, this.overigeRuimte.walls[m].group.position[currentAxis]+this.overigeRuimte.walls[m].set.mx+this.overigeRuimte.walls[m].staanderObject.dx/2)
        }
      }
      
      // if((!mainMuur.alignsXAxis&&mainMuur.alignedRotation) || (mainMuur.alignsXAxis&&!mainMuur.alignedRotation)) {
      //   //front and right wall
      //   for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
      //     this.overigeRuimte.lockedWalls[m].group.position[currentAxis] = steellook.group.position[currentAxis] - addedWidth - this.overigeRuimte.lockedWalls[m].set.mx - this.overigeRuimte.lockedWalls[m].staanderObject.dx
      //     this.overigeRuimte.lockedWalls[m].mapGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
      //     this.overigeRuimte.lockedWalls[m].modelGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
      //     if(this.overigeRuimte.lockedWalls[m].wallStaander !== null) {
      //       this.overigeRuimte.lockedWalls[m].wallStaander.position.x = this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx/2
      //       this.overigeRuimte.lockedWalls[m].mapWallStaander.position.x = this.overigeRuimte.lockedWalls[m].wallStaander.position.x
      //     }
      //     addedWidth += this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx
      //   }

      //   for(let m=0; m<this.overigeRuimte.walls.length; m++) {
      //     this.overigeRuimte.walls[m].set.mx = this.overigeRuimte.totalWidth/this.overigeRuimte.walls.length
      //     this.overigeRuimte.walls[m].update(this.overigeRuimte.walls[m].y, this.overigeRuimte.walls[m].set, this.overigeRuimte.walls[m].schoor, this.overigeRuimte.walls[m].staanderGrootte, true)
      //     this.overigeRuimte.walls[m].group.position[currentAxis] = steellook.group.position[currentAxis] - addedWidth - this.overigeRuimte.walls[m].set.mx - this.overigeRuimte.walls[m].staanderObject.dx
      //     this.overigeRuimte.walls[m].mapGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
      //     this.overigeRuimte.walls[m].modelGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
      //     if(this.overigeRuimte.walls[m].wallStaander !== null) {
      //       this.overigeRuimte.walls[m].wallStaander.position.x = this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx/2
      //       this.overigeRuimte.walls[m].mapWallStaander.position.x = this.overigeRuimte.walls[m].wallStaander.position.x
      //     }
      //     addedWidth += this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx
      //   }


      // }else {
      //   //left and back walls
      //   for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
      //     this.overigeRuimte.lockedWalls[m].group.position[currentAxis] = steellook.group.position[currentAxis] - addedWidth + this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx
      //     this.overigeRuimte.lockedWalls[m].mapGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
      //     this.overigeRuimte.lockedWalls[m].modelGroup.position[currentAxis] = this.overigeRuimte.lockedWalls[m].group.position[currentAxis]
      //     if(this.overigeRuimte.lockedWalls[m].wallStaander !== null) {
      //       this.overigeRuimte.lockedWalls[m].wallStaander.position.x = this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx/2
      //       this.overigeRuimte.lockedWalls[m].mapWallStaander.position.x = this.overigeRuimte.lockedWalls[m].wallStaander.position.x
      //     }
      //     addedWidth += this.overigeRuimte.lockedWalls[m].set.mx + this.overigeRuimte.lockedWalls[m].staanderObject.dx
      //   }

      //   for(let m=0; m<this.overigeRuimte.walls.length; m++) {
      //     this.overigeRuimte.walls[m].set.mx = this.overigeRuimte.totalWidth/this.overigeRuimte.walls.length
      //     this.overigeRuimte.walls[m].update(this.overigeRuimte.walls[m].y, this.overigeRuimte.walls[m].set, this.overigeRuimte.walls[m].schoor, this.overigeRuimte.walls[m].staanderGrootte, true)
      //     this.overigeRuimte.walls[m].group.position[currentAxis] = steellook.group.position[currentAxis] - addedWidth + this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx
      //     this.overigeRuimte.walls[m].mapGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
      //     this.overigeRuimte.walls[m].modelGroup.position[currentAxis] = this.overigeRuimte.walls[m].group.position[currentAxis]
      //     if(this.overigeRuimte.walls[m].wallStaander !== null) {
      //       this.overigeRuimte.walls[m].wallStaander.position.x = this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx/2
      //       this.overigeRuimte.walls[m].mapWallStaander.position.x = this.overigeRuimte.walls[m].wallStaander.position.x
      //     }
      //     addedWidth += this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx
      //   }
      // }


    }

    if(wall.metaData.plusIndex !== 2) {
      const wallArray = []
      for(let k=0; k<this.muurInstances.length; k++) {
        this.muurInstances[k].settings.position = JSON.parse(JSON.stringify(this.muurInstances[k].group.position))
        this.muurInstances[k].settings.position.x -= this.muurInstances[k].groupOffset.x
        this.muurInstances[k].settings.position.z -= this.muurInstances[k].groupOffset.z
        wallArray.push(this.muurInstances[k].settings)
      }
      store.dispatch('updateWallArrayConfig', wallArray)

      console.log(steellook)
      store.dispatch('updateIntersectingObjects')
      this.setWallPlusIcons(this.selectedWallComponent)
      if (this.selectedWallComponent.category !== 'Steellook Wand BW') {
        this.setElementPlacing(this.selectedWallComponent)
      }

      for(let i=0; i<this.muurInstances.length; i++) {
        this.muurInstances[i].previousWallWidth = this.muurInstances[i].set.mx
      }

      if(!this.isPlatdak && !mainMuur.alignsXAxis && this.goot.dakgoot.front.enabled && (this.x > this.goot.thirdPipeThreshold)) {
        this.updateMiddenGootPosition()
      } else {console.log(wall)}

      this.setHoekProfielen(steellook)
    }
    this.updateGordingenDivision()
  }

  addSchuifwandComponent (wall, settings) {
    console.log("addSchuifwandComponent", wall, settings)
    const mainMuur = this.muurInstances.find((muur) => muur.group.uuid === wall.parent.parent.uuid)
    console.log("addSchuifwandComponent", mainMuur)
    mainMuur.isSlidingWall = true
    mainMuur.isLocked = true
    mainMuur.schuifwandRichting = store.getters.getSchuifwandRichting
    mainMuur.schuifwandKleur = store.getters.getSchuifwandKleur
    mainMuur.schuifwandPreference = store.getters.getSchuifwandPreference
    mainMuur.update()
    store.dispatch('updateIntersectingObjects')
  }

  updateTextureFrame(texture) {
    this.textureFrame = texture
    console.log(this.textureFrame)
    this.ringBalk.updateTexture(texture)
    this.staanders.updateTexture(texture)
    this.gording.updateTexture(texture)
    this.boeideel.updateFrameTexture(texture)

    const textures = store.getters.getTextures
    const selectedFrameColor = store.getters.getSelectedFrameColor
    const filteredTexture = textures.filter(texture2 => texture2.textureType === "62e3e09a990280c3716422dd").filter(texture2 => texture2.color === selectedFrameColor.colorId)

    this.dakbeschot.updateTexture(filteredTexture[0].url)
    this.schoor0.updateTexture(texture)
    for(let i=0; i<this.muurInstances.length; i++) {
      this.muurInstances[i].updateWallStaanderTexture(this.textureFrame)
    }
    this.lichtstraatManager.updateFrameTexture(texture)
  }

  updateTextureBoeidelen(texture) {
    this.textureBoeidelen = texture
    this.boeideel.updateTexture(texture)
  }

  updateTextureHoekprofielen(texture) {
    this.textureHoekprofielen = texture
    this.muurInstances.forEach((muur) => {
      muur.updateHoekprofielTexture(texture)
    })

    this.boeideel.updateSluitlatTexture(texture)
  }

  updateTrimColor(color) {
    this.trim.updateColor(color)
  }

  toggleEPDM (state) {
    this.dakbedekking.group.visible = state
    this.trim.group.visible = state
  }

  setDakpanMeshVisibility (visibility) {
    this.dakbeschot.setDakpanMeshVisibility(visibility)
  }

  updateDakafwerkingTexture(color) {
    this.dakAfwerking = color
    this.dakbeschot.updateDakafwerkingTexture(color)
  }

  selectElement (obj) {
    const muur = this.muurInstances.find((muur) => muur.group.uuid === obj.parent.parent.uuid)
    muur.selectElement(obj)
  }

  deselectElement (obj) {
    const muur = this.muurInstances.find((muur) => muur.group.uuid === obj.parent.parent.uuid)
    muur.deselectElement(obj)
  }

  removeElement (obj) {
    const muur = this.muurInstances.find((muur) => muur.group.uuid === obj.parent.parent.uuid)
    muur.removeElement(obj)
  }

  changeLockMode (obj) {
    // console.log(obj)
    obj.changeLockMode()
  }

  setMovingBeam (beam) {
    //console.log(beam)
    const muur = this.muurInstances.find((muur) => muur.group.uuid === beam.parent.parent.uuid)
    var movingSide = beam.name==="arrowRight" ? 0 : 1 
    // console.log(muur.alignsXAxis)
    this.movingWall = muur
    this.movingWall.movingSide = movingSide
    this.movingWall.isLeftLocked = beam.name==="arrowLeft"
    this.movingWall.movingWallBeginValue = this.movingWall.set.mx
    this.movingWall.movingWallBeginPosition = this.movingWall.alignsXAxis ? this.movingWall.group.position.x : this.movingWall.group.position.z
    // console.log(this.movingWall.movingWallBeginPosition)
    // console.log(muur)
    //this.overigeRuimte = (this.movingWall.alignedRotation && !this.movingWall.alignsXAxis && (this.movingWall.movingSide === 0 ? this.movingWall.parentWall : this.movingWall.childWall)) ? (this.movingWall.movingSide === 0 ? this.getEmptyParentWalls(this.movingWall.parentWall) : this.getEmptyChildWalls(this.movingWall.childWall)) : {lockedWalls: [], walls: [], totalWidth: 0, shouldInsertNewWall: false}
    this.overigeRuimte = (this.movingWall.movingSide === 0 ? this.getEmptyParentWalls(this.movingWall.parentWall) : this.getEmptyChildWalls(this.movingWall.childWall))
    // console.log(this.overigeRuimte)
    // console.log((this.movingWall.alignedRotation && !this.movingWall.alignsXAxis && (this.movingWall.movingSide === 0 ? this.movingWall.parentWall : this.movingWall.childWall)))
    // console.log(this.movingWall.parentWall)
    this.childWallIsLocked = this.movingWall.childWall ? this.movingWall.childWall.isLocked : false
    // console.log(this.childWallIsLocked)
    this.muurInstancesPrevious = []
    for(let y=0; y<this.muurInstances.length; y++) {
      this.muurInstancesPrevious.push({
        uuid: this.muurInstances[y].group.uuid,
        width: this.muurInstances[y].set.mx,
        position: JSON.parse(JSON.stringify(this.muurInstances[y].group.position)),
      })
    }
  }

  async removeMovingBeam (changedByNumberInput) {
    this.hadToInsertNewWall = false
    console.log(changedByNumberInput)
    //alert((this.movingWall.childWall !== null && (this.movingWall.childWall.childWall.hasFinishedBuilding)))
    if(changedByNumberInput || (this.movingWall.movingSide === 0 ? (this.movingWall.parentWall != null && this.getFirstNonSteellookWall(this.movingWall)) : (this.movingWall.childWall !== null && this.getFirstNonSteellookWall(this.movingWall)))) {
    //if(this.movingWall.isUpdating === false) {

      //this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte)

      /*if(this.movingWall.movingSide === 1 && this.movingWall.childWall === null) {
        const set = {
          mx: this.movingWall.previousWallWidth-this.movingWall.set.mx-120,
          my: 2300,
          mz: 120,
          rotation: 3.141592653589793,
          x: 60,
          y: 0,
          z: 0,//2600,
        }
        this.insertWall(this.movingWall, set, 2300, this.movingWall.movingSide === 1)
        //store.dispatch('updateIntersectingObjects')
        this.movingWall.childWall.group.position.x = this.movingWall.previousWallWidth-(this.movingWall.set.x + this.movingWall.set.mx) + 60
        this.movingWall.childWall.previousWallWidth = this.movingWall.childWall.set.mx
      } */

      if(!this.hasDoneOverkill) {
        // console.log(this.movingWall.childWall !== null && this.movingWall.childWall.hasFinishedBuilding)
        const hoofdthis = this
        if(!this.hasFinishedPlacingNewWall) {
          setTimeout(() => {
            hoofdthis.removeMovingBeam(changedByNumberInput)
          }, 250);
          return
        }
        this.movingBeamPositionArray.unshift(this.previousPlanePosition)
        this.hasDoneOverkill = true
        // console.log("PWW PRE-START")
        this.setUpdateMovingBeam(function() {
          hoofdthis.removeMovingBeam(changedByNumberInput)
        }, true)
      } else {

        this.hasDoneOverkill = false
      //setTimeout(() => {
        /*if(this.movingWall.movingSide === 1) {
          this.movingWall.childWall.previousWallWidth = this.movingWall.childWall.set.mx
        }
        if(this.movingWall.movingSide === 0) {
          this.movingWall.parentWall.previousWallWidth = this.movingWall.parentWall.set.mx
        }*/
        // console.log("PWW START")
        var currentElement = this.movingWall
        while(currentElement != undefined) {
          currentElement.previousWallWidth = currentElement.set.mx
          currentElement = currentElement.parentWall
          // console.log("PWW")
        }
        currentElement = this.movingWall.childWall
        while(currentElement != undefined) {
          currentElement.previousWallWidth = currentElement.set.mx
          currentElement = currentElement.childWall
          // console.log("PWW")
        }
        store.dispatch('updateIntersectingObjects')
        this.movingWall.previousWallWidth = this.movingWall.set.mx
        console.log("----------SAVE----------")
        


        //if(this.movingWall.alignedRotation && !this.movingWall.alignsXAxis) {
          /*if(this.movingWall.movingSide === 1 && this.movingWall.childWall.childWall) {
            this.movingWall.childWall.childWall.previousWallWidth = this.movingWall.childWall.childWall.set.mx
          }*/
          this.isTooBig = false
        //}
        
        // check which walls changed in width
        const wallArray = []
        for(let k=0; k<this.muurInstances.length; k++) {
          const oldWallConfig = this.muurInstancesPrevious.find(muur => muur.uuid === this.muurInstances[k].group.uuid)
          if(oldWallConfig) {
            console.log("check gelijk")
            console.log("check", oldWallConfig.position, JSON.parse(JSON.stringify(this.muurInstances[k].group.position)))
            console.log("check ff", oldWallConfig.width, this.muurInstances[k].set.mx)
            if(oldWallConfig.width != this.muurInstances[k].set.mx || oldWallConfig.position.z != JSON.parse(JSON.stringify(this.muurInstances[k].group.position)).z || oldWallConfig.position.x != JSON.parse(JSON.stringify(this.muurInstances[k].group.position)).x) {
              this.muurInstances[k].settings.width = this.muurInstances[k].set.mx
              this.muurInstances[k].settings.position = this.muurInstances[k].group.position.clone()
              this.muurInstances[k].settings.position.x -= this.muurInstances[k].groupOffset.x
              this.muurInstances[k].settings.position.z -= this.muurInstances[k].groupOffset.z
              console.log("niet gelijk!")
              store.dispatch('setUpdateWallConfig', {index: this.muurInstances.findIndex((muur) => muur.group.uuid === oldWallConfig.uuid), settings: this.muurInstances[k].settings})
            }
          } else {
            // add new wall to config.muren
            this.muurInstances[k].settings.width = this.muurInstances[k].set.mx
            this.muurInstances[k].settings.position = this.muurInstances[k].group.position.clone()
            this.muurInstances[k].settings.position.x -= this.muurInstances[k].groupOffset.x
            this.muurInstances[k].settings.position.z -= this.muurInstances[k].groupOffset.z
            //store.dispatch('insertWallInconfigArray', this.muurInstances[k].settings)
          }
          this.muurInstances[k].settings.position = JSON.parse(JSON.stringify(this.muurInstances[k].group.position))
          this.muurInstances[k].settings.position.x -= this.muurInstances[k].groupOffset.x
          this.muurInstances[k].settings.position.z -= this.muurInstances[k].groupOffset.z
          wallArray.push(this.muurInstances[k].settings)
        }
        store.dispatch('updateWallArrayConfig', wallArray)
        store.dispatch('updateConfig')
  
        this.movingWall = null

        this.updateGordingenDivision()
      //}, 1500);
      }

    } else {
      
      setTimeout(() => {
        this.removeMovingBeam(changedByNumberInput)
      }, 500);
    }
  }

  updateMovingBeam(planePosition) {
    if(this.movingWall) {
      this.movingBeamPositionArray.unshift(planePosition)
      this.setUpdateMovingBeam()
    }
  }

  async setUpdateMovingBeam(_callback, isCallback) {
    // console.log(_callback)
      //console.log(this.movingWall.isUpdating)
      if(!this.movingWall.isUpdating || _callback) {
        const planePosition = this.movingBeamPositionArray[0]
        // console.log(planePosition)
        this.previousPlanePosition = planePosition
        this.movingBeamPositionArray = []
        this.movingWall.isUpdating = true
        // var olddate = Date.now()
        let shouldRenderChildFrame;


        const axis = this.movingWall.alignsXAxis ? 'x' : 'z'

        if(this.movingWall.movingSide === 0) {
          //MOVING LEFT SIDE

          //insert a wall if the selectedwall has no parentwall and if there is enough space
          /*if(this.movingWall.parentWall === null && (this.movingWall.set.mx >= (2*this.config.minWallWidth + this.movingWall.staanderObject.dx))) {
            const set = {
              mx: this.config.minWallWidth,
              my: 2300,
              mz: this.movingWall.staanderObject.dx,
              rotation: this.movingWall.set.rotation,
              x: this.movingWall.set.x,//this.movingWall.set.x,//60,
              y: this.movingWall.set.y,
              z: this.movingWall.set.z,//2600,//this.movingWall.previousWallWidth + this.movingWall.staanderObject.dx + this.getChildrenWidth(this.movingWall.childWall)//2600,
              tussenMuur: this.movingWall.set.tussenMuur
            }
            const hoofdthis = this
            this.childWallIsLocked = false
            this.hadToInsertNewWall = true
            this.insertWall(this.movingWall ,set, 2300, this.movingWall.movingSide === 1, () => {
              //this.updateMovingBeam(this.previousPlanePosition)
              this.overigeRuimte.walls.push(this.movingWall.parentWall)
              this.overigeRuimte.totalWidth -= this.movingWall.staanderObject.dx
              this.overigeRuimte.totalWidthStart -= this.movingWall.staanderObject.dx
              this.movingWall.parentWall.previousWallWidth = -this.movingWall.staanderObject.dx
              this.updateMovingBeam(this.previousPlanePosition)
            }, hoofdthis.textureFrame)
          }*/

          if((!this.movingWall.alignsXAxis&&this.movingWall.alignedRotation) || (this.movingWall.alignsXAxis&&!this.movingWall.alignedRotation)) {
            let addition
            //calculate the difference in mouse position
            const addition2 = Math.round(this.movingStartPlanePosition[axis] - planePosition[axis])
            //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
            let addition3
            if(this.movingWall.isSlidingWall) {
              const min = store.state.cms.schuifwandWidthRanges[0].min
              const max = this.config.maxWallWidth //store.state.cms.schuifwandWidthRanges[store.state.cms.schuifwandWidthRanges.length-1].max
              addition3 = (this.movingWall.previousWallWidth + addition2 > max) ? (max - this.movingWall.movingWallBeginValue) : (this.movingWall.movingWallBeginValue + addition2) < (min) ? (min - this.movingWall.movingWallBeginValue) : addition2
            } else {
              addition3 = (this.movingWall.previousWallWidth + addition2 > this.config.maxWallWidth) ? (this.config.maxWallWidth - this.movingWall.movingWallBeginValue) : (this.movingWall.movingWallBeginValue + addition2) < (this.config.minWallWidth) ? (this.config.minWallWidth - this.movingWall.movingWallBeginValue) : addition2
            }
            //adds minimum and maximum boundaries for parent wall
            const addition4 = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidthStart-addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth - this.overigeRuimte.walls[0].previousWallWidth  + this.config.minWallWidth))-(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : (addition3 > 0 ? 0 : addition3)
            addition = this.overigeRuimte.walls.length == 0 ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + (this.config.needsSpaceForStaander ? this.movingWall.staanderObject.dx : 0)) ? 0 : addition4 : addition4
            this.movingWall.group.position[axis] = this.movingWall.movingWallBeginPosition - addition
            this.movingWall.mapGroup.position[axis] = this.movingWall.movingWallBeginPosition - addition
            this.movingWall.modelGroup.position[axis] = this.movingWall.movingWallBeginPosition - addition
            this.movingWall.set.mx = this.movingWall.previousWallWidth + addition
            
            shouldRenderChildFrame = !(this.movingWall.settings.binnenMuur && this.movingWall.settings.buitenMuur)
            await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, shouldRenderChildFrame)
            if(this.movingWall.wallStaander !== null) {
              this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
              this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
            }

            this.overigeRuimte.totalWidth = this.overigeRuimte.totalWidthStart-addition

            // // move tussenchildwalls if any are connected
            // if(this.movingWall.tussenChildWall) {
            //   this.setTussenMuurPosition(this.movingWall.tussenChildWall, axis, this.movingWall.group.position[axis]+this.movingWall.set.mx+this.movingWall.staanderObject.dx/2)
            // }

            let result = this.overigeRuimte
            if(!this.movingWall.isLocked) {
              result.walls.splice(result.walls.indexOf(this.movingWall), 1)
              result.totalWidth -= this.movingWall.set.mx
            }
            if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
              if(!this.isTooBig) {
                this.isTooBig = true
                //generate extra wall
                const set = {
                  mx: 0,
                  my: 2300,
                  mz: this.movingWall.staanderObject.dx,
                  rotation: this.movingWall.set.rotation,
                  x: this.movingWall.group.position.z - this.movingWall.groupOffset.z,
                  y: this.movingWall.set.y,
                  z: this.movingWall.group.position.x - this.movingWall.groupOffset.x,
                  tussenMuur: this.movingWall.set.tussenMuur
                }
                let parentOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                this.insertWall(parentOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, () => {
                  if(parentOfInsertedWall.parentWall) {
                    if(parentOfInsertedWall.parentWall.parentWall) {
                      parentOfInsertedWall.parentWall.showWallStaander(this.textureFrame)
                    }
                  }
                  if(parentOfInsertedWall.parentWall) {
                    parentOfInsertedWall.showWallStaander(this.textureFrame)
                  }
                  this.updateMovingBeam(this.previousPlanePosition)
                  const newWallIndex = this.overigeRuimte.walls.indexOf(parentOfInsertedWall)+1
                  this.overigeRuimte.walls.splice(newWallIndex, 0, parentOfInsertedWall.parentWall)
                  this.overigeRuimte.totalWidth -= this.staanderGrootte.dx
                  this.overigeRuimte.totalWidthStart -= this.staanderGrootte.dx
                  if(_callback) {
                    _callback()
                  }
                }, this.textureFrame, this.movingWall.isSlidingWall, true)
              }
            } else if(result.totalWidth +this.staanderGrootte.dx <= (this.config.maxWallWidth*(result.walls.length-1)) && result.walls.length>1){
              if(this.isTooBig || result.walls.length > 1) {
                //remove extra wall
                result.walls[0].parentWall.group.removeFromParent()
                result.walls[0].parentWall.mapGroup.removeFromParent()
                result.walls[0].parentWall.modelGroup.removeFromParent()

                this.removeTussenChildWalls(result.walls[0].parentWall)

                if(result.walls[0].parentWall.parentWall) {
                  result.walls[0].parentWall = result.walls[0].parentWall.parentWall
                  result.walls[0].settings.Puuid = result.walls[0].parentWall.settings.uuid
                  result.walls[0].parentWall.childWall = result.walls[0]
                  result.walls[0].parentWall.settings.Cuuid = result.walls[0].parentWall.childWall.settings.uuid
                } else {
                  result.walls[0].parentWall = null
                  result.walls[0].settings.Puuid = null
                }
                result.walls[0].previousWallWidth += result.walls[1].previousWallWidth + result.walls[0].staanderObject.dx
                this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                this.overigeRuimte.walls = this.getEmptyParentWalls(this.movingWall).walls
                this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx
                this.overigeRuimte.totalWidthStart += this.movingWall.staanderObject.dx

                


                this.isTooBig = false
              }
            }

            let addedWidth = 0

            if(!this.overigeRuimte.shouldInsertNewWall) {
              for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                let wall = this.overigeRuimte.lockedWalls[m]
                if(wall) {
                  wall.group.position[axis] = this.movingWall.group.position[axis] - addedWidth - wall.set.mx - this.movingWall.staanderObject.dx
                  wall.mapGroup.position[axis] = wall.group.position[axis]
                  wall.modelGroup.position[axis] = wall.group.position[axis]

                  addedWidth += wall.set.mx + this.movingWall.staanderObject.dx

                  // move tussenchildwalls if any are connected
                  if(wall.tussenChildWall) {
                    this.setTussenMuurPosition(wall.tussenChildWall, axis, this.movingWall.group.position[axis]+wall.set.mx+this.movingWall.staanderObject.dx/2 - addedWidth)
                  }
                }
              }
            }

            for(let m=0; m<this.overigeRuimte.walls.length; m++) {
              let wall = this.overigeRuimte.walls[m]
              if(wall) {
                wall.set.mx = this.overigeRuimte.totalWidth/this.overigeRuimte.walls.length
                shouldRenderChildFrame = !(wall.settings.binnenMuur && wall.settings.buitenMuur)
                wall.update(wall.y, wall.set, wall.schoor, wall.staanderGrootte, shouldRenderChildFrame)
                wall.group.position[axis] = this.movingWall.group.position[axis] - addedWidth - wall.set.mx - this.movingWall.staanderObject.dx//this.movingWall.staanderObject.dx +addedWidth + this.getfirstLockedParentLocationAndWidth(this.movingWall.parentWall)
                wall.mapGroup.position[axis] = wall.group.position[axis]
                wall.modelGroup.position[axis] = wall.group.position[axis]
                if(wall.wallStaander !== null) {
                  wall.wallStaander.position.x = wall.set.mx + wall.staanderObject.dx/2
                  wall.mapWallStaander.position.x = wall.wallStaander.position.x
                }

                // move tussenchildwalls if any are connected
                if(wall.tussenChildWall) {
                  this.setTussenMuurPosition(wall.tussenChildWall, axis, this.movingWall.group.position[axis]-this.movingWall.staanderObject.dx/2 - addedWidth)
                }

                addedWidth += wall.set.mx + this.movingWall.staanderObject.dx

                
              }
            }



          } else {
            let addition
            // console.log(this.overigeRuimte)
            //calculate the difference in mouse position
            const addition2 = Math.round(this.movingStartPlanePosition[axis] - planePosition[axis])
            
            // snapping
            let addition2_1
            if (!this.movingWall.alignsXAxis && !this.movingWall.alignedRotation && store.state.config.snappingEnabled) {
              const frontWalls = this.muurInstances.filter(muur => !muur.alignsXAxis && muur.alignedRotation)
              const frontStaanderLocations = frontWalls.map(muur => muur.group.position.z + muur.set.mx + muur.staanderObject.dx/2).sort((a, b) => a - b)

              const currentFuturePosition = this.movingWall.movingWallBeginPosition - addition2 + this.movingWall.staanderObject.dx/2
              const closestFrontStaanderPosition = frontStaanderLocations.reduce((prev, curr) => Math.abs(curr - currentFuturePosition) < Math.abs(prev - currentFuturePosition) ? curr : prev)
              const closestFrontStaanderPositionIndex = frontStaanderLocations.indexOf(closestFrontStaanderPosition)
              console.log("currentFuturePosition", currentFuturePosition, this.movingWall.movingWallBeginPosition)
              let leftBorder = closestFrontStaanderPosition + Math.min(this.achtermuurSnapRange, (closestFrontStaanderPositionIndex === frontStaanderLocations.length-1
                ? this.achtermuurSnapRange
                : frontStaanderLocations[closestFrontStaanderPositionIndex+1] - closestFrontStaanderPosition
              ))
              let rightBorder = closestFrontStaanderPosition - Math.min(this.achtermuurSnapRange, (closestFrontStaanderPositionIndex === 0 
                ? this.achtermuurSnapRange 
                : closestFrontStaanderPosition - frontStaanderLocations[closestFrontStaanderPositionIndex-1]
              ))

              if(currentFuturePosition < leftBorder && currentFuturePosition > rightBorder) {
                addition2_1 = this.movingWall.movingWallBeginPosition - closestFrontStaanderPosition + this.movingWall.staanderObject.dx/2
              } else {
                addition2_1 = addition2
              }
            } else {
              addition2_1 = addition2
            }

            //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
            let addition3
            if(this.movingWall.isSlidingWall) {
              const min = store.state.cms.schuifwandWidthRanges[0].min
              const max = this.config.maxWallWidth //store.state.cms.schuifwandWidthRanges[store.state.cms.schuifwandWidthRanges.length-1].max
              addition3 = (this.movingWall.previousWallWidth - addition2_1 > max) ? (this.movingWall.movingWallBeginValue - max) : (this.movingWall.movingWallBeginValue - addition2_1) < (min) ? (this.movingWall.movingWallBeginValue - min) : addition2_1
            } else {
              addition3 = (this.movingWall.previousWallWidth - addition2_1 > this.config.maxWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.maxWallWidth) : (this.movingWall.movingWallBeginValue - addition2_1) < (this.config.minWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.minWallWidth) : addition2_1
            }
            //adds minimum and maximum boundaries for parent wall
            const addition4 = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidthStart+addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.config.minWallWidth - this.overigeRuimte.walls[0].previousWallWidth)+(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : (addition3 < 0 ? 0 : addition3)
            addition = this.overigeRuimte.walls.length == 0 ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + (this.config.needsSpaceForStaander ? this.movingWall.staanderObject.dx : 0)) ? 0 : addition4 : addition4
            this.movingWall.group.position[axis] = this.movingWall.movingWallBeginPosition - addition
            this.movingWall.mapGroup.position[axis] = this.movingWall.movingWallBeginPosition - addition
            this.movingWall.modelGroup.position[axis] = this.movingWall.movingWallBeginPosition - addition
            this.movingWall.set.mx = this.movingWall.movingWallBeginValue - addition
            
            await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, true)
            if(this.movingWall.wallStaander !== null) {
              this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
              this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
            }

            this.overigeRuimte.totalWidth = this.overigeRuimte.totalWidthStart+addition
            
            let result = this.overigeRuimte
            // console.log(result)
            // console.log("DATTAAA", result.totalWidth, this.movingWall.previousWallWidth)
            if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
              if(!this.isTooBig) {
                // console.log("add extra wall")
                // console.log(result.totalWidth, result.walls.length)
                this.isTooBig = true
                //generate extra wall
                const set = {
                  mx: 0,
                  my: 2300,
                  mz: this.movingWall.staanderObject.dx,
                  rotation: this.movingWall.set.rotation,
                  x: this.movingWall.group.position.z - this.movingWall.groupOffset.z,
                  y: this.movingWall.set.y,
                  z: this.movingWall.group.position.x - this.movingWall.groupOffset.x,
                  tussenMuur: this.movingWall.set.tussenMuur
                }
                let childOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                this.insertWall(childOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, () => {
                  if(childOfInsertedWall.childWall) {
                    if(childOfInsertedWall.parentWall.parentWall) {
                      childOfInsertedWall.parentWall.showWallStaander(this.textureFrame)
                    }
                  }
                  if(childOfInsertedWall.parentWall) {
                    childOfInsertedWall.showWallStaander(this.textureFrame)
                  }
                  this.updateMovingBeam(this.previousPlanePosition)
                  const newWallIndex = this.overigeRuimte.walls.indexOf(childOfInsertedWall)+1
                  // console.log(newWallIndex)
                  this.overigeRuimte.walls.splice(newWallIndex, 0, childOfInsertedWall.parentWall)
                  this.overigeRuimte.totalWidth -= this.staanderGrootte.dx
                  this.overigeRuimte.totalWidthStart -= this.staanderGrootte.dx
                  if(_callback) {
                    _callback()
                  }
                }, this.textureFrame, this.movingWall.isSlidingWall, true)
              }
            } else if(result.totalWidth +this.staanderGrootte.dx <= (this.config.maxWallWidth*(result.walls.length-1)) && result.walls.length>1){
              if(this.isTooBig || result.walls.length > 1) {
                //remove extra wall
                // console.log(result.walls.length, "remove extra wall")
                
                result.walls[0].parentWall.group.removeFromParent()
                result.walls[0].parentWall.mapGroup.removeFromParent()
                result.walls[0].parentWall.modelGroup.removeFromParent()

                if(result.walls[0].parentWall.parentWall) {
                  // console.log("eerste")
                  result.walls[0].parentWall = result.walls[0].parentWall.parentWall
                  result.walls[0].settings.Puuid = result.walls[0].parentWall.settings.uuid
                  result.walls[0].parentWall.childWall = result.walls[0]
                  result.walls[0].parentWall.settings.Cuuid = result.walls[0].parentWall.childWall.settings.uuid
                } else {
                  // console.log("tweede")
                  result.walls[0].parentWall = null
                  result.walls[0].settings.Puuid = null
                  result.walls[0].wallStaander.removeFromParent()
                  result.walls[0].wallStaander = null
                  result.walls[0].mapWallStaander.removeFromParent()
                  result.walls[0].mapWallStaander = null
                }
                result.walls[0].previousWallWidth += result.walls[1].previousWallWidth + result.walls[0].staanderObject.dx
                // console.log("index: ", this.muurInstances.indexOf(result.walls[1]))
                this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                this.overigeRuimte.walls = this.getEmptyParentWalls(this.movingWall).walls
                this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx
                this.overigeRuimte.totalWidthStart += this.movingWall.staanderObject.dx

                


                this.isTooBig = false
              }
            }

            let addedWidth = 0

            if(!this.overigeRuimte.shouldInsertNewWall) {
              for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                let wall = this.overigeRuimte.lockedWalls[m]
                if(wall) {
                  wall.group.position[axis] = this.movingWall.group.position[axis] - addedWidth + wall.set.mx + this.movingWall.staanderObject.dx
                  wall.mapGroup.position[axis] = wall.group.position[axis]
                  wall.modelGroup.position[axis] = wall.group.position[axis]
                  addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
                }
              }
            }

            for(let m=0; m<this.overigeRuimte.walls.length; m++) {
              let wall = this.overigeRuimte.walls[m]
              if(wall) {
                wall.set.mx = ((this.overigeRuimte.totalWidth)/this.overigeRuimte.walls.length)
                shouldRenderChildFrame = !(wall.settings.binnenMuur && wall.settings.buitenMuur)
                wall.update(wall.y, wall.set, wall.schoor, wall.staanderGrootte, shouldRenderChildFrame)
                wall.group.position[axis] = this.movingWall.group.position[axis] + addedWidth + wall.set.mx + this.movingWall.staanderObject.dx
                wall.mapGroup.position[axis] = wall.group.position[axis]
                wall.modelGroup.position[axis] = wall.group.position[axis]
                if(wall.wallStaander !== null) {
                  wall.wallStaander.position.x = wall.set.mx + wall.staanderObject.dx/2
                  wall.mapWallStaander.position.x = wall.wallStaander.position.x
                }
                addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
              }
            }


            /*if(this.movingWall.alignsXAxis) {
              if(this.movingWall.alignedRotation) {
                //towards negative z
                // console.log("1")

                let addition
                // console.log(this.overigeRuimte)
                //calculate the difference in mouse position
                const addition2 = Math.round(this.movingStartPlanePosition.x - planePosition.x)
                //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
                const addition3 = (this.movingWall.previousWallWidth - addition2 > this.config.maxWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.maxWallWidth) : (this.movingWall.movingWallBeginValue - addition2) < (this.config.minWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.minWallWidth) : addition2
                //adds minimum and maximum boundaries for parent wall
                addition = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidth+addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.config.minWallWidth - this.overigeRuimte.walls[0].previousWallWidth)+(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : addition3 
                
                this.movingWall.group.position.x = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.mapGroup.position.x = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.modelGroup.position.x = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.set.mx = this.movingWall.movingWallBeginValue - addition
                
                await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, true)
                if(this.movingWall.wallStaander !== null) {
                  this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                }

                

                
                let result = this.getEmptyParentWalls(this.movingWall)
                console.log(result)
                console.log("DATTAAA", result.totalWidth, this.movingWall.previousWallWidth)
                if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
                  if(!this.isTooBig) {
                    console.log("add extra wall")
                    this.isTooBig = true
                    //generate extra wall
                    const set = {
                      mx: 0,
                      my: 2300,
                      mz: this.movingWall.staanderObject.dx,
                      rotation: this.movingWall.set.rotation,
                      x: this.movingWall.set.x,//60,
                      y: this.movingWall.set.y,
                      z: this.movingWall.set.z,//2600,
                      tussenMuur: this.movingWall.set.tussenMuur
                    }
                    const hoofdthis = this
                    let childOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                    this.insertWall(childOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, function() {
                      if(childOfInsertedWall.childWall) {
                        if(childOfInsertedWall.parentWall.parentWall) {
                          childOfInsertedWall.parentWall.showWallStaander(hoofdthis.textureFrame)
                        }
                      }
                      if(childOfInsertedWall.parentWall) {
                        childOfInsertedWall.showWallStaander(hoofdthis.textureFrame)
                      }
                      hoofdthis.updateMovingBeam(hoofdthis.previousPlanePosition)
                      hoofdthis.overigeRuimte.walls.push(childOfInsertedWall.parentWall)
                      hoofdthis.overigeRuimte.totalWidth -= hoofdthis.movingWall.staanderObject.dx
                      if(_callback) {
                        _callback()
                      }
                    })
                  }
                } else if(result.totalWidth +this.movingWall.staanderObject.dx< this.config.maxWallWidth && result.walls.length>1){
                  if(this.isTooBig || result.walls.length > 1) {
                    //remove extra wall
                    console.log(result.walls.length, "remove extra wall")
                    
                    result.walls[0].parentWall.group.removeFromParent()
                    result.walls[0].parentWall.mapGroup.removeFromParent()
                    result.walls[0].parentWall.modelGroup.removeFromParent()

                    if(result.walls[0].parentWall.parentWall) {
                      console.log("eerste")
                      result.walls[0].parentWall = result.walls[0].parentWall.parentWall
                      result.walls[0].settings.Puuid = result.walls[0].parentWall.settings.uuid
                      result.walls[0].parentWall.childWall = result.walls[0]
                      result.walls[0].parentWall.settings.Cuuid = result.walls[0].parentWall.childWall.settings.uuid
                    } else {
                      console.log("tweede")
                      result.walls[0].parentWall = null
                      result.walls[0].settings.Puuid = null
                      result.walls[0].wallStaander.removeFromParent()
                      result.walls[0].wallStaander = null
                      result.walls[0].mapWallStaander.removeFromParent()
                      result.walls[0].mapWallStaander = null
                    }
                    result.walls[0].previousWallWidth += result.walls[0].previousWallWidth + result.walls[0].staanderObject.dx
                    console.log("index: ", this.muurInstances.indexOf(result.walls[1]))
                    this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                    this.overigeRuimte.walls = this.getEmptyParentWalls(this.movingWall).walls
                    this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx

                    


                    this.isTooBig = false
                  }
                }

                let addedWidth = 0

                if(!this.overigeRuimte.shouldInsertNewWall) {
                  for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                    this.overigeRuimte.lockedWalls[m].group.position.x = this.movingWall.group.position.x + addedWidth + this.overigeRuimte.lockedWalls[m].set.mx + this.movingWall.staanderObject.dx
                    this.overigeRuimte.lockedWalls[m].mapGroup.position.x = this.overigeRuimte.lockedWalls[m].group.position.x
                    this.overigeRuimte.lockedWalls[m].modelGroup.position.x = this.overigeRuimte.lockedWalls[m].group.position.x
                    addedWidth += this.overigeRuimte.lockedWalls[m].set.mx + this.movingWall.staanderObject.dx
                  }
                }

                for(let m=0; m<this.overigeRuimte.walls.length; m++) {
                  // console.log(this.overigeRuimte.walls[m2])
                  this.overigeRuimte.walls[m].set.mx = ((this.overigeRuimte.totalWidth+addition)/this.overigeRuimte.walls.length)
                  shouldRenderChildFrame = !(this.overigeRuimte.walls[m].settings.binnenMuur && this.overigeRuimte.walls[m].settings.buitenMuur)
                  this.overigeRuimte.walls[m].update(this.overigeRuimte.walls[m].y, this.overigeRuimte.walls[m].set, this.overigeRuimte.walls[m].schoor, this.overigeRuimte.walls[m].staanderGrootte, shouldRenderChildFrame)
                  this.overigeRuimte.walls[m].group.position.x = this.movingWall.group.position.x + addedWidth + this.overigeRuimte.walls[m].set.mx + this.movingWall.staanderObject.dx
                  this.overigeRuimte.walls[m].mapGroup.position.x = this.overigeRuimte.walls[m].group.position.x
                  this.overigeRuimte.walls[m].modelGroup.position.x = this.overigeRuimte.walls[m].group.position.x
                  if(this.overigeRuimte.walls[m].wallStaander !== null) {
                    this.overigeRuimte.walls[m].wallStaander.position.x = this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx/2
                    this.overigeRuimte.walls[m].mapWallStaander.position.x = this.overigeRuimte.walls[m].wallStaander.position.x
                  }
                  addedWidth += this.overigeRuimte.walls[m].set.mx + this.movingWall.staanderObject.dx
                }


              } else {
                //towards positive z
                // console.log("2")

                let addition
                //calculate the difference in mouse position
                const addition2 = Math.round(this.movingStartPlanePosition.x - planePosition.x)
                //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
                const addition3 = (this.movingWall.previousWallWidth + addition2 > this.config.maxWallWidth) ? (this.config.maxWallWidth - this.movingWall.movingWallBeginValue) : (this.movingWall.movingWallBeginValue + addition2) < (this.config.minWallWidth) ? (this.config.minWallWidth - this.movingWall.movingWallBeginValue) : addition2
                //adds minimum and maximum boundaries for parent wall
                console.log("check: ", ((this.overigeRuimte.totalWidth-addition3)/this.overigeRuimte.walls.length), this.overigeRuimte.walls.length)
                const addition4 = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidthStart-addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth - this.overigeRuimte.walls[0].previousWallWidth  + this.config.minWallWidth))-(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : addition3
                addition = this.overigeRuimte.walls.length == 0 ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + this.movingWall.staanderObject.dx) ? 0 : addition4 : addition4
                this.movingWall.group.position.x = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.mapGroup.position.x = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.modelGroup.position.x = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.set.mx = this.movingWall.previousWallWidth + addition
                
                shouldRenderChildFrame = !(this.movingWall.settings.binnenMuur && this.movingWall.settings.buitenMuur)
                await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, shouldRenderChildFrame)
                if(this.movingWall.wallStaander !== null) {
                  this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                }

                this.overigeRuimte.totalWidth = this.overigeRuimte.totalWidthStart-addition

                let result = this.overigeRuimte
                if(!this.movingWall.isLocked) {
                  result.walls.splice(result.walls.indexOf(this.movingWall), 1)
                  result.totalWidth -= this.movingWall.set.mx
                }
                if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
                  if(!this.isTooBig) {
                    console.log("add extra wall")
                    this.isTooBig = true
                    //generate extra wall
                    const set = {
                      mx: 0,
                      my: 2300,
                      mz: this.movingWall.staanderObject.dx,
                      rotation: this.movingWall.set.rotation,
                      x: this.movingWall.set.x,//60,
                      y: this.movingWall.set.y,
                      z: this.movingWall.set.z,//2600,
                      tussenMuur: this.movingWall.set.tussenMuur
                    }
                    let parentOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                    this.insertWall(parentOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, () => {
                      if(parentOfInsertedWall.parentWall) {
                        if(parentOfInsertedWall.parentWall.parentWall) {
                          parentOfInsertedWall.parentWall.showWallStaander(this.textureFrame)
                        }
                      }
                      if(parentOfInsertedWall.parentWall) {
                        parentOfInsertedWall.showWallStaander(this.textureFrame)
                      }
                      this.updateMovingBeam(this.previousPlanePosition)
                      this.overigeRuimte.walls.push(parentOfInsertedWall.parentWall)
                      this.overigeRuimte.totalWidth -= this.movingWall.staanderObject.dx
                      this.overigeRuimte.totalWidthStart -= this.movingWall.staanderObject.dx
                      if(_callback) {
                        _callback()
                      }
                    })
                  }
                } else if(result.totalWidth +this.movingWall.staanderObject.dx< this.config.maxWallWidth && result.walls.length>1){
                  if(this.isTooBig || result.walls.length > 1) {
                    //remove extra wall
                    console.log(result.walls.length, "remove extra wall")
                    
                    result.walls[0].parentWall.group.removeFromParent()
                    result.walls[0].parentWall.mapGroup.removeFromParent()
                    result.walls[0].parentWall.modelGroup.removeFromParent()

                    if(result.walls[0].parentWall.parentWall) {
                      console.log("eerste")
                      result.walls[0].parentWall = result.walls[0].parentWall.parentWall
                      result.walls[0].settings.Puuid = result.walls[0].parentWall.settings.uuid
                      result.walls[0].parentWall.childWall = result.walls[0]
                      result.walls[0].parentWall.settings.Cuuid = result.walls[0].parentWall.childWall.settings.uuid
                    } else {
                      console.log("tweede")
                      result.walls[0].parentWall = null
                      result.walls[0].settings.Puuid = null
                    }
                    result.walls[0].previousWallWidth += result.walls[0].previousWallWidth + result.walls[0].staanderObject.dx
                    console.log("index: ", this.muurInstances.indexOf(result.walls[1]))
                    this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                    this.overigeRuimte.walls = this.getEmptyParentWalls(this.movingWall).walls
                    this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx
                    this.overigeRuimte.totalWidthStart += this.movingWall.staanderObject.dx

                    


                    this.isTooBig = false
                  }
                }

                let addedWidth = 0

                if(!this.overigeRuimte.shouldInsertNewWall) {
                  for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                    let wall = this.overigeRuimte.lockedWalls[m]
                    if(wall) {
                      wall.group.position.x = this.movingWall.group.position.x - addedWidth - wall.set.mx - this.movingWall.staanderObject.dx
                      wall.mapGroup.position.x = wall.group.position.x
                      wall.modelGroup.position.x = wall.group.position.x
                      addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
                    }
                  }
                }

                for(let m=0; m<this.overigeRuimte.walls.length; m++) {
                  let wall = this.overigeRuimte.walls[m]
                  if(wall) {
                    wall.set.mx = this.overigeRuimte.totalWidth/this.overigeRuimte.walls.length
                    shouldRenderChildFrame = !(wall.settings.binnenMuur && wall.settings.buitenMuur)
                    wall.update(wall.y, wall.set, wall.schoor, wall.staanderGrootte, shouldRenderChildFrame)
                    wall.group.position.x = this.movingWall.group.position.x - addedWidth - wall.set.mx - this.movingWall.staanderObject.dx//this.movingWall.staanderObject.dx +addedWidth + this.getfirstLockedParentLocationAndWidth(this.movingWall.parentWall)
                    wall.mapGroup.position.x = wall.group.position.x
                    wall.modelGroup.position.x = wall.group.position.x
                    if(wall.wallStaander !== null) {
                      wall.wallStaander.position.x = wall.set.mx + wall.staanderObject.dx/2
                      wall.mapWallStaander.position.x = wall.wallStaander.position.x
                    }
                    addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
                  }
                }
              }
            } else {
              if(this.movingWall.alignedRotation) {
                //towards negative x
                // console.log("3")

                let addition
                //calculate the difference in mouse position
                const addition2 = Math.round(this.movingStartPlanePosition.z - planePosition.z)
                //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
                const addition3 = (this.movingWall.previousWallWidth + addition2 > this.config.maxWallWidth) ? (this.config.maxWallWidth - this.movingWall.movingWallBeginValue) : (this.movingWall.movingWallBeginValue + addition2) < (this.config.minWallWidth) ? (this.config.minWallWidth - this.movingWall.movingWallBeginValue) : addition2
                //adds minimum and maximum boundaries for parent wall
                console.log("check: ", ((this.overigeRuimte.totalWidth-addition3)/this.overigeRuimte.walls.length), this.overigeRuimte.walls.length)
                const addition4 = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidthStart-addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth - this.overigeRuimte.walls[0].previousWallWidth  + this.config.minWallWidth))-(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : addition3
                addition = this.overigeRuimte.walls.length == 0 ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + this.movingWall.staanderObject.dx) ? 0 : addition4 : addition4

                this.movingWall.group.position.z = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.mapGroup.position.z = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.modelGroup.position.z = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.set.mx = this.movingWall.previousWallWidth + addition
                
                shouldRenderChildFrame = !(this.movingWall.settings.binnenMuur && this.movingWall.settings.buitenMuur)
                await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, shouldRenderChildFrame)
                if(this.movingWall.wallStaander !== null) {
                  this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                }

                this.overigeRuimte.totalWidth = this.overigeRuimte.totalWidthStart-addition

                let result = this.overigeRuimte
                if(!this.movingWall.isLocked) {
                  result.walls.splice(result.walls.indexOf(this.movingWall), 1)
                  result.totalWidth -= this.movingWall.set.mx
                }
                if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
                  if(!this.isTooBig) {
                    console.log("add extra wall")
                    this.isTooBig = true
                    //generate extra wall
                    const set = {
                      mx: 0,
                      my: 2300,
                      mz: this.movingWall.staanderObject.dx,
                      rotation: this.movingWall.set.rotation,
                      x: this.movingWall.set.x,//60,
                      y: this.movingWall.set.y,
                      z: this.movingWall.set.z,//2600,
                      tussenMuur: this.movingWall.set.tussenMuur
                    }
                    let parentOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                    this.insertWall(parentOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, () => {
                      if(parentOfInsertedWall.parentWall) {
                        if(parentOfInsertedWall.parentWall.parentWall) {
                          parentOfInsertedWall.parentWall.showWallStaander(this.textureFrame)
                        }
                      }
                      if(parentOfInsertedWall.parentWall) {
                        parentOfInsertedWall.showWallStaander(this.textureFrame)
                      }
                      this.updateMovingBeam(this.previousPlanePosition)
                      this.overigeRuimte.walls.push(parentOfInsertedWall.parentWall)
                      this.overigeRuimte.totalWidth -= this.movingWall.staanderObject.dx
                      this.overigeRuimte.totalWidthStart -= this.movingWall.staanderObject.dx
                      if(_callback) {
                        _callback()
                      }
                    })
                  }
                } else if(result.totalWidth +this.movingWall.staanderObject.dx< this.config.maxWallWidth && result.walls.length>1){
                  if(this.isTooBig || result.walls.length > 1) {
                    //remove extra wall
                    console.log(result.walls.length, "remove extra wall")
                    
                    result.walls[0].parentWall.group.removeFromParent()
                    result.walls[0].parentWall.mapGroup.removeFromParent()
                    result.walls[0].parentWall.modelGroup.removeFromParent()

                    if(result.walls[0].parentWall.parentWall) {
                      console.log("eerste")
                      result.walls[0].parentWall = result.walls[0].parentWall.parentWall
                      result.walls[0].settings.Puuid = result.walls[0].parentWall.settings.uuid
                      result.walls[0].parentWall.childWall = result.walls[0]
                      result.walls[0].parentWall.settings.Cuuid = result.walls[0].parentWall.childWall.settings.uuid
                    } else {
                      console.log("tweede")
                      result.walls[0].parentWall = null
                      result.walls[0].settings.Puuid = null
                    }
                    result.walls[0].previousWallWidth += result.walls[0].previousWallWidth + result.walls[0].staanderObject.dx
                    console.log("index: ", this.muurInstances.indexOf(result.walls[1]))
                    this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                    this.overigeRuimte.walls = this.getEmptyParentWalls(this.movingWall).walls
                    this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx
                    this.overigeRuimte.totalWidthStart += this.movingWall.staanderObject.dx

                    


                    this.isTooBig = false
                  }
                }

                let addedWidth = 0

                if(!this.overigeRuimte.shouldInsertNewWall) {
                  for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                    let wall = this.overigeRuimte.lockedWalls[m]
                    if(wall) {
                      wall.group.position.z = this.movingWall.group.position.z - addedWidth - wall.set.mx - this.movingWall.staanderObject.dx
                      wall.mapGroup.position.z = wall.group.position.z
                      wall.modelGroup.position.z = wall.group.position.z
                      addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
                    }
                  }
                }

                for(var m=0; m<this.overigeRuimte.walls.length; m++) {
                  let wall = this.overigeRuimte.walls[m]
                  if(wall) {
                    wall.set.mx = this.overigeRuimte.totalWidth/this.overigeRuimte.walls.length
                    shouldRenderChildFrame = !(wall.settings.binnenMuur && wall.settings.buitenMuur)
                    wall.update(wall.y, wall.set, wall.schoor, wall.staanderGrootte, shouldRenderChildFrame)
                    wall.group.position.z = this.movingWall.group.position.z - addedWidth - wall.set.mx - this.movingWall.staanderObject.dx//this.movingWall.staanderObject.dx +addedWidth + this.getfirstLockedParentLocationAndWidth(this.movingWall.parentWall)
                    wall.mapGroup.position.z = wall.group.position.z
                    wall.modelGroup.position.z = wall.group.position.z
                    if(wall.wallStaander !== null) {
                      wall.wallStaander.position.x = wall.set.mx + wall.staanderObject.dx/2
                      wall.mapWallStaander.position.x = wall.wallStaander.position.x
                    }
                    addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
                  }
                }
              } else {
                //towards positive x
                console.log("4")

                let addition
                //calculate the difference in mouse position
                const addition2 = Math.round(this.movingStartPlanePosition.z - planePosition.z)
                //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
                const addition3 = (this.movingWall.movingWallBeginValue - addition2 > this.config.maxWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.maxWallWidth) : (this.movingWall.movingWallBeginValue - addition2) < (this.config.minWallWidth) ? (this.movingWall.movingWallBeginValue-this.config.minWallWidth) : addition2
                //adds minimum and maximum boundaries for parent wall
                let addition4
                if(!this.movingWall.parentWall.isSteellook) {
                  addition4 = this.movingWall.parentWall !== null ? ((this.movingWall.previousWallWidth+this.movingWall.parentWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition3)) < this.config.minWallWidth ? (addition3 + (this.config.minWallWidth - ((this.movingWall.previousWallWidth+this.movingWall.parentWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition3)))) : addition3 : addition3
                } else {
                  addition4 = this.movingWall.parentWall.parentWall !== null ? ((this.movingWall.previousWallWidth+this.movingWall.parentWall.parentWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition3)) < this.config.minWallWidth ? (addition3 + (this.config.minWallWidth - ((this.movingWall.previousWallWidth+this.movingWall.parentWall.parentWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition3)))) : addition3 : addition3
                }
                //don't make any changes when the currently selected wall is too small to fit another wall    (2* minimumWallWidth + width of a staander)
                addition = this.movingWall.parentWall == undefined ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + this.movingWall.staanderObject.dx) ? 0 : addition4 : addition4

                
                this.movingWall.group.position.z = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.mapGroup.position.z = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.modelGroup.position.z = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.set.mx = this.movingWall.movingWallBeginValue - addition


                await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, shouldRenderFrame)


                if(this.movingWall.parentWall !== null && this.movingWall.parentWall.hasFinishedBuilding && !this.movingWall.parentWall.isSteellook) {
                  this.movingWall.parentWall.set.mx = (this.movingWall.previousWallWidth+this.movingWall.parentWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition)//-this.movingWall.set.mx-this.movingWall.staanderObject.dx//(this.movingWall.movingWallBeginValue - addition) - this.movingWall.staanderObject.dx
                  this.movingWall.set.mx) + this.movingWall.staanderObject.dx*1 + this.getParentsWidth(this.movingWall.parentWall)
                  if(this.movingWall.parentWall.wallStaander !== null) {
                    this.movingWall.parentWall.wallStaander.position.x = this.movingWall.parentWall.set.mx + this.movingWall.parentWall.staanderObject.dx/2
                    this.movingWall.parentWall.mapWallStaander.position.x = this.movingWall.parentWall.set.mx + this.movingWall.parentWall.staanderObject.dx/2
                  }
                  if(this.movingWall.wallStaander !== null) {
                    this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                    this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  }
                  shouldRenderChildFrame = !(this.movingWall.parentWall.settings.binnenMuur && this.movingWall.parentWall.settings.buitenMuur)
                  this.movingWall.parentWall.update(this.movingWall.parentWall.y, this.movingWall.parentWall.set, this.movingWall.parentWall.schoor, this.movingWall.parentWall.staanderGrootte, shouldRenderChildFrame)
                
                
                } else if(this.movingWall.parentWall !== null && this.movingWall.parentWall.isSteellook) {
                  this.movingWall.parentWall.parentWall.set.mx = (this.movingWall.previousWallWidth+this.movingWall.parentWall.parentWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition)//-this.movingWall.set.mx-this.movingWall.staanderObject.dx//(this.movingWall.movingWallBeginValue - addition) - this.movingWall.staanderObject.dx
                  
                  this.movingWall.parentWall.group.position.z = this.movingWall.rootPosition - this.getParentsWidth(this.movingWall.parentWall)
                  this.movingWall.parentWall.mapGroup.position.z = this.movingWall.parentWall.group.position.z
                  this.movingWall.parentWall.modelGroup.position.z = this.movingWall.parentWall.group.position.z

                  if(this.movingWall.parentWall.parentWall.wallStaander !== null) {
                    this.movingWall.parentWall.parentWall.wallStaander.position.x = this.movingWall.parentWall.parentWall.set.mx + this.movingWall.parentWall.parentWall.staanderObject.dx/2
                    this.movingWall.parentWall.parentWall.mapWallStaander.position.x = this.movingWall.parentWall.parentWall.wallStaander.position.x
                  }
                  if(this.movingWall.wallStaander !== null) {
                    this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                    this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  }
                  shouldRenderChildFrame = !(this.movingWall.parentWall.parentWall.settings.binnenMuur && this.movingWall.parentWall.parentWall.settings.buitenMuur)
                  this.movingWall.parentWall.parentWall.update(this.movingWall.parentWall.parentWall.y, this.movingWall.parentWall.parentWall.set, this.movingWall.parentWall.parentWall.schoor, this.movingWall.parentWall.parentWall.staanderGrootte, shouldRenderChildFrame)
                }
              }
            }*/
          }

        






          

  
        } else if(this.movingWall.movingSide === 1){
          //MOVING RIGHT SIDE

          //insert a wall if the selectedwall has no childWall and if there is enough space
          /*if(this.movingWall.childWall === null && (this.movingWall.set.mx >= (2*this.config.minWallWidth + this.movingWall.staanderObject.dx))) {
            const set = {
              mx: this.config.minWallWidth,
              my: 2300,
              mz: this.movingWall.staanderObject.dx,
              rotation: this.movingWall.set.rotation,
              x: this.movingWall.set.x,//60,
              y: this.movingWall.set.y,
              z: this.movingWall.set.z,//2600,
              tussenMuur: this.movingWall.set.tussenMuur
            }
            const hoofdthis = this
            this.childWallIsLocked = false
            this.hadToInsertNewWall = true
            if(hoofdthis.movingWall.wallStaander === null && hoofdthis.movingWall.childWall) {
              hoofdthis.movingWall.showWallStaander(hoofdthis.textureFrame)
            }
            console.log("inserted extra wall")
            this.insertWall(this.movingWall ,set, 2300, this.movingWall.movingSide === 1, () => {
              //this.updateMovingBeam(this.previousPlanePosition)
              this.overigeRuimte.walls.push(this.movingWall.childWall)
              this.overigeRuimte.totalWidth -= this.movingWall.staanderObject.dx
              this.overigeRuimte.totalWidthStart -= this.movingWall.staanderObject.dx
              this.movingWall.childWall.previousWallWidth = -this.movingWall.staanderObject.dx//this.config.minWallWidth
              //this.overigeRuimte = this.getEmptyChildWalls(this.movingWall.childWall)
              this.updateMovingBeam(this.previousPlanePosition)
            }, hoofdthis.textureFrame)
          } */

          if((!this.movingWall.alignsXAxis&&this.movingWall.alignedRotation) || (this.movingWall.alignsXAxis&&!this.movingWall.alignedRotation)) {
            let addition
            // console.log(this.overigeRuimte)
            //calculate the difference in mouse position
            const addition2 = Math.round(this.movingStartPlanePosition[axis] - planePosition[axis])
            //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
            let addition3
            if(this.movingWall.isSlidingWall) {
              const min = store.state.cms.schuifwandWidthRanges[0].min
              const max = this.config.maxWallWidth //store.state.cms.schuifwandWidthRanges[store.state.cms.schuifwandWidthRanges.length-1].max
              addition3 = (this.movingWall.previousWallWidth - addition2 > max) ? (this.movingWall.movingWallBeginValue - max) : (this.movingWall.movingWallBeginValue - addition2) < (min) ? (this.movingWall.movingWallBeginValue - min) : addition2
            } else {
              addition3 = (this.movingWall.previousWallWidth - addition2 > this.config.maxWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.maxWallWidth) : (this.movingWall.movingWallBeginValue - addition2) < (this.config.minWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.minWallWidth) : addition2
            }
            //adds minimum and maximum boundaries for child wall
            const addition4 = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidthStart+addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth + this.overigeRuimte.walls[0].previousWallWidth  - this.config.minWallWidth))+(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : (addition3 < 0 ? 0 : addition3) //this.movingWall.childwall !== null ? ((this.movingWall.previousWallWidth+this.movingWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition3)) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth + this.movingWall.childWall.previousWallWidth - this.config.minWallWidth)) : addition3 : addition3
            addition = this.overigeRuimte.walls.length == 0 ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + (this.config.needsSpaceForStaander ? this.movingWall.staanderObject.dx : 0)) ? 0 : addition4 : addition4
            this.movingWall.set.mx = this.movingWall.previousWallWidth - addition
            await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, true)
            if(this.movingWall.wallStaander !== null) {
              this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
              this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
            }
            this.overigeRuimte.totalWidth = this.overigeRuimte.totalWidthStart+addition

            // move tussenchildwalls if any are connected
            if(this.movingWall.tussenChildWall  && (!this.movingWall.alignsXAxis&&this.movingWall.alignedRotation)) {
              this.setTussenMuurPosition(this.movingWall.tussenChildWall, axis, this.movingWall.group.position[axis]+this.movingWall.set.mx+this.movingWall.staanderObject.dx/2)
            }
            
            let result = this.overigeRuimte//this.getEmptyChildWalls(this.movingWall)
            if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
              if(!this.isTooBig) {
                this.isTooBig = true
                //generate extra wall
                const set = {
                  mx: 0,
                  my: 2300,
                  mz: this.movingWall.staanderObject.dx,
                  rotation: this.movingWall.set.rotation,
                  x: this.movingWall.group.position.z - this.movingWall.groupOffset.z,
                  y: this.movingWall.set.y,
                  z: this.movingWall.group.position.x - this.movingWall.groupOffset.x,
                  tussenMuur: this.movingWall.set.tussenMuur
                }
                let parentOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                this.insertWall(parentOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, () => {
                  if(parentOfInsertedWall.childWall) {
                    if(parentOfInsertedWall.childWall.childWall) {
                      parentOfInsertedWall.childWall.showWallStaander(this.textureFrame)
                    }
                  }
                  if(parentOfInsertedWall.childWall) {
                    parentOfInsertedWall.showWallStaander(this.textureFrame)
                  }
                  this.updateMovingBeam(this.previousPlanePosition)
                  const newWallIndex = this.overigeRuimte.walls.indexOf(parentOfInsertedWall)+1
                  this.overigeRuimte.walls.splice(newWallIndex, 0, parentOfInsertedWall.childWall)
                  this.overigeRuimte.totalWidth -= this.staanderGrootte.dx
                  this.overigeRuimte.totalWidthStart -= this.staanderGrootte.dx

                  if(!parentOfInsertedWall.tussenChildWall && parentOfInsertedWall.childWall && (!this.movingWall.alignsXAxis&&this.movingWall.alignedRotation)) {
                    this.insertTussenWanden(parentOfInsertedWall, true, false, parentOfInsertedWall.childWall, this.textureFrame)
                  } 

                  if(_callback) {
                    _callback()
                  }
                }, this.textureFrame, this.movingWall.isSlidingWall, true)
              }
            } else if(result.totalWidth +this.staanderGrootte.dx <= (this.config.maxWallWidth*(result.walls.length-1)) && result.walls.length>1){
              if(this.isTooBig || result.walls.length > 1) {
                //remove extra wall
                result.walls[0].group.removeFromParent()
                result.walls[0].mapGroup.removeFromParent()
                result.walls[0].modelGroup.removeFromParent()

                this.removeTussenChildWalls(result.walls[0])

                // if(result.walls[0].childWall.childWall) {
                //   result.walls[0].childWall = result.walls[0].childWall.childWall
                //   result.walls[0].settings.Cuuid = result.walls[0].childWall.settings.uuid
                //   result.walls[0].childWall.parentWall = result.walls[0]
                //   result.walls[0].childWall.settings.Puuid = result.walls[0].childWall.parentWall.settings.uuid
                // } else {
                //   result.walls[0].childWall = null
                //   result.walls[0].settings.Cuuid = null
                //   result.walls[0].wallStaander.removeFromParent()
                //   result.walls[0].wallStaander = null
                //   result.walls[0].mapWallStaander.removeFromParent()
                //   result.walls[0].mapWallStaander = null
                // }
                // result.walls[0].previousWallWidth += result.walls[1].previousWallWidth + result.walls[0].staanderObject.dx
                // this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                result.walls[0].parentWall.childWall = result.walls[1]
                result.walls[0].parentWall.settings.Cuuid = result.walls[1].settings.uuid
                result.walls[1].parentWall = result.walls[0].parentWall
                result.walls[1].settings.Puuid = result.walls[0].parentWall.settings.uuid



                result.walls[1].previousWallWidth += result.walls[0].previousWallWidth + result.walls[1].staanderObject.dx
                this.muurInstances.splice(this.muurInstances.indexOf(result.walls[0]), 1)

                this.overigeRuimte.walls = this.getEmptyChildWalls(this.movingWall.childWall).walls
                this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx
                this.overigeRuimte.totalWidthStart += this.movingWall.staanderObject.dx

                


                this.isTooBig = false
              }
            }

            let addedWidth = 0

            if(!this.overigeRuimte.shouldInsertNewWall) {
              for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                let wall = this.overigeRuimte.lockedWalls[m]
                if(wall) {
                  wall.group.position[axis] = this.movingWall.set.mx+this.movingWall.group.position[axis] + this.movingWall.staanderObject.dx + addedWidth
                  wall.mapGroup.position[axis] = wall.group.position[axis]
                  wall.modelGroup.position[axis] = wall.group.position[axis]

                  addedWidth += wall.set.mx + this.movingWall.staanderObject.dx

                  // move tussenchildwalls if any are connected
                  if(wall.tussenChildWall) {
                    this.setTussenMuurPosition(wall.tussenChildWall, axis, this.movingWall.group.position[axis]+this.movingWall.set.mx+this.movingWall.staanderObject.dx/2 + addedWidth)
                  }
                }
              }
            }

            for(let m=0; m<this.overigeRuimte.walls.length; m++) {
              let wall = this.overigeRuimte.walls[m]
              if(wall) {
                wall.set.mx = this.overigeRuimte.totalWidth/this.overigeRuimte.walls.length//+ (wall.wallStaander ? +wall.staanderObject.dx : 0)
                shouldRenderChildFrame = !(wall.settings.binnenMuur && wall.settings.buitenMuur)
                wall.update(wall.y, wall.set, wall.schoor, wall.staanderGrootte, shouldRenderChildFrame)
                wall.group.position[axis] = this.movingWall.set.mx+this.movingWall.group.position[axis]+addedWidth+this.movingWall.staanderObject.dx //+ m*(wall.set.mx + wall.staanderObject.dx)
                wall.mapGroup.position[axis] = wall.group.position[axis]
                wall.modelGroup.position[axis] = wall.group.position[axis]
                if(wall.wallStaander !== null) {
                  wall.wallStaander.position.x = wall.set.mx + wall.staanderObject.dx/2
                  wall.mapWallStaander.position.x = wall.wallStaander.position.x
                }
                addedWidth += wall.set.mx + this.movingWall.staanderObject.dx

                // move tussenchildwalls if any are connected
                if(wall.tussenChildWall) {
                  this.setTussenMuurPosition(wall.tussenChildWall, axis, this.movingWall.group.position[axis]+this.movingWall.set.mx+this.movingWall.staanderObject.dx/2 + addedWidth)
                }
                
              }
            }




          } else {
            let addition
            // console.log(this.overigeRuimte)
            //calculate the difference in mouse position
            const addition2 = Math.round(this.movingStartPlanePosition[axis] - planePosition[axis])

            // snapping
            let addition2_1
            if (!this.movingWall.alignsXAxis && !this.movingWall.alignedRotation && store.state.config.snappingEnabled) {
              const frontWalls = this.muurInstances.filter(muur => !muur.alignsXAxis && muur.alignedRotation)
              const frontStaanderLocations = frontWalls.map(muur => muur.group.position.z + muur.set.mx + muur.staanderObject.dx/2).sort((a, b) => a - b)

              const currentFuturePosition = this.movingWall.movingWallBeginPosition - this.movingWall.previousWallWidth - addition2 - this.movingWall.staanderObject.dx/2
              const closestFrontStaanderPosition = frontStaanderLocations.reduce((prev, curr) => Math.abs(curr - currentFuturePosition) < Math.abs(prev - currentFuturePosition) ? curr : prev)
              const closestFrontStaanderPositionIndex = frontStaanderLocations.indexOf(closestFrontStaanderPosition)
              console.log("currentFuturePosition", currentFuturePosition, this.movingWall.movingWallBeginPosition, this.movingWall.previousWallWidth)
              let leftBorder = closestFrontStaanderPosition + Math.min(this.achtermuurSnapRange, (closestFrontStaanderPositionIndex === frontStaanderLocations.length-1
                ? this.achtermuurSnapRange
                : frontStaanderLocations[closestFrontStaanderPositionIndex+1] - closestFrontStaanderPosition
              ))
              let rightBorder = closestFrontStaanderPosition - Math.min(this.achtermuurSnapRange, (closestFrontStaanderPositionIndex === 0 
                ? this.achtermuurSnapRange 
                : closestFrontStaanderPosition - frontStaanderLocations[closestFrontStaanderPositionIndex-1]
              ))

              if(currentFuturePosition < leftBorder && currentFuturePosition > rightBorder) {
                addition2_1 = this.movingWall.movingWallBeginPosition - this.movingWall.previousWallWidth - closestFrontStaanderPosition - this.movingWall.staanderObject.dx/2
              } else {
                addition2_1 = addition2
              }
            } else {
              addition2_1 = addition2
            }

            //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
            let addition3
            if(this.movingWall.isSlidingWall) {
              const min = store.state.cms.schuifwandWidthRanges[0].min
              const max = this.config.maxWallWidth //store.state.cms.schuifwandWidthRanges[store.state.cms.schuifwandWidthRanges.length-1].max
              addition3 = (this.movingWall.previousWallWidth + addition2_1 > max) ? (max - this.movingWall.movingWallBeginValue) : (this.movingWall.movingWallBeginValue + addition2_1) < (min) ? (min - this.movingWall.movingWallBeginValue) : addition2_1
            } else {
              addition3 = (this.movingWall.previousWallWidth + addition2_1 > this.config.maxWallWidth) ? (this.config.maxWallWidth - this.movingWall.movingWallBeginValue) : (this.movingWall.movingWallBeginValue + addition2_1) < (this.config.minWallWidth) ? (this.config.minWallWidth - this.movingWall.movingWallBeginValue) : addition2_1
            }
            //adds minimum and maximum boundaries for child wall
            // console.log(this.movingWall.previousWallWidth, this.overigeRuimte.walls[0] ? this.overigeRuimte.walls[0].previousWallWidth : 'nog niet')
            const addition4 = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidthStart-addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.overigeRuimte.walls[0].previousWallWidth - this.config.minWallWidth)-(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : (addition3 > 0 ? 0 : addition3)
            addition = this.overigeRuimte.walls.length == 0 ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + (this.config.needsSpaceForStaander ? this.movingWall.staanderObject.dx : 0)) ? 0 : addition4 : addition4
            this.movingWall.set.mx = this.movingWall.previousWallWidth + addition
            await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, true)
            if(this.movingWall.wallStaander !== null) {
              this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
              this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
            }
            this.overigeRuimte.totalWidth = this.overigeRuimte.totalWidthStart-addition
            
            let result = this.overigeRuimte
            // console.log(result)
            // console.log("DATTAAA", result.totalWidth, this.movingWall.previousWallWidth)
            if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
              if(!this.isTooBig) {
                // console.log("add extra wall")
                this.isTooBig = true
                //generate extra wall
                const set = {
                  mx: 0,
                  my: 2300,
                  mz: this.movingWall.staanderObject.dx,
                  rotation: this.movingWall.set.rotation,
                  x: this.movingWall.group.position.z - this.movingWall.groupOffset.z,
                  y: this.movingWall.set.y,
                  z: this.movingWall.group.position.x - this.movingWall.groupOffset.x,
                  tussenMuur: this.movingWall.set.tussenMuur
                }
                let parentOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                this.insertWall(parentOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, () => {
                  if(parentOfInsertedWall.childWall) {
                    if(parentOfInsertedWall.childWall.childWall) {
                      parentOfInsertedWall.childWall.showWallStaander(this.textureFrame)
                    }
                  }
                  if(parentOfInsertedWall.childWall) {
                    parentOfInsertedWall.showWallStaander(this.textureFrame)
                  }
                  this.updateMovingBeam(this.previousPlanePosition)
                  // this.overigeRuimte.walls.push(parentOfInsertedWall.childWall)
                  const newWallIndex = this.overigeRuimte.walls.indexOf(parentOfInsertedWall)+1
                  this.overigeRuimte.walls.splice(newWallIndex, 0, parentOfInsertedWall.childWall)
                  this.overigeRuimte.totalWidth -= this.staanderGrootte.dx
                  this.overigeRuimte.totalWidthStart -= this.staanderGrootte.dx
                  if(_callback) {
                    _callback()
                  }
                }, this.textureFrame, this.movingWall.isSlidingWall, true)
              }
            } else if(result.totalWidth +this.staanderGrootte.dx <= (this.config.maxWallWidth*(result.walls.length-1)) && result.walls.length>1){
              if(this.isTooBig || result.walls.length > 1) {
                //remove extra wall
                // console.log(result.walls.length, "remove extra wall")
                
                result.walls[0].childWall.group.removeFromParent()
                result.walls[0].childWall.mapGroup.removeFromParent()
                result.walls[0].childWall.modelGroup.removeFromParent()

                if(result.walls[0].childWall.childWall) {
                  // console.log("eerste")
                  result.walls[0].childWall = result.walls[0].childWall.childWall
                  result.walls[0].settings.Cuuid = result.walls[0].childWall.settings.uuid
                  result.walls[0].childWall.parentWall = result.walls[0]
                  result.walls[0].childWall.settings.Puuid = result.walls[0].childWall.parentWall.settings.uuid
                } else {
                  // console.log("tweede")
                  result.walls[0].childWall = null
                  result.walls[0].settings.Cuuid = null
                  result.walls[0].wallStaander.removeFromParent()
                  result.walls[0].wallStaander = null
                  result.walls[0].mapWallStaander.removeFromParent()
                  result.walls[0].mapWallStaander = null
                }
                result.walls[0].previousWallWidth += result.walls[1].previousWallWidth + result.walls[0].staanderObject.dx
                // console.log("index: ", this.muurInstances.indexOf(result.walls[1]))
                this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                this.overigeRuimte.walls = this.getEmptyChildWalls(this.movingWall).walls
                this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx
                this.overigeRuimte.totalWidthStart += this.movingWall.staanderObject.dx
                


                this.isTooBig = false
              }
            }

            let addedWidth = 0

            if(!this.overigeRuimte.shouldInsertNewWall) {
              for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                let wall = this.overigeRuimte.lockedWalls[m]
                if(wall) {
                  wall.group.position[axis] = this.movingWall.group.position[axis]-this.movingWall.set.mx-addedWidth-this.movingWall.staanderObject.dx
                  wall.mapGroup.position[axis] = wall.group.position[axis]
                  wall.modelGroup.position[axis] = wall.group.position[axis]
                  addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
                }
              }
            }

            for(let m=0; m<this.overigeRuimte.walls.length; m++) {
              let wall = this.overigeRuimte.walls[m]
              if(wall) {
                wall.set.mx = (this.overigeRuimte.totalWidth/this.overigeRuimte.walls.length) //+ (wall.wallStaander ? +wall.staanderObject.dx : 0)
                shouldRenderChildFrame = !(wall.settings.binnenMuur && wall.settings.buitenMuur)
                wall.update(wall.y, wall.set, wall.schoor, wall.staanderGrootte, shouldRenderChildFrame)
                wall.group.position[axis] = this.movingWall.group.position[axis]-this.movingWall.set.mx-addedWidth-this.movingWall.staanderObject.dx //+ m*(wall.set.mx + wall.staanderObject.dx)
                wall.mapGroup.position[axis] = wall.group.position[axis]
                wall.modelGroup.position[axis] = wall.group.position[axis]
                if(wall.wallStaander !== null) {
                  wall.wallStaander.position.x = wall.set.mx + wall.staanderObject.dx/2
                  wall.mapWallStaander.position.x = wall.wallStaander.position.x
                }
                addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
              }
            }
          }

            /*if(this.movingWall.alignsXAxis) {
              if(this.movingWall.alignedRotation) {
                //towards negative z
                // console.log("1")

                let addition
                // console.log(this.overigeRuimte)
                //calculate the difference in mouse position
                const addition2 = Math.round(this.movingStartPlanePosition.x - planePosition.x)
                //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
                const addition3 = (this.movingWall.previousWallWidth + addition2 > this.config.maxWallWidth) ? (this.config.maxWallWidth - this.movingWall.movingWallBeginValue) : (this.movingWall.movingWallBeginValue + addition2) < (this.config.minWallWidth) ? (this.config.minWallWidth - this.movingWall.movingWallBeginValue) : addition2
                //adds minimum and maximum boundaries for child wall
                addition = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidth-addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.overigeRuimte.walls[0].previousWallWidth - this.config.minWallWidth)+(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : addition3
                this.movingWall.set.mx = this.movingWall.previousWallWidth + addition
                await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, true)
                if(this.movingWall.wallStaander !== null) {
                  this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                }
                

                
                let result = this.getEmptyChildWalls(this.movingWall)
                console.log(result)
                console.log("DATTAAA", result.totalWidth, this.movingWall.previousWallWidth)
                if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
                  if(!this.isTooBig) {
                    console.log("add extra wall")
                    this.isTooBig = true
                    //generate extra wall
                    const set = {
                      mx: 0,
                      my: 2300,
                      mz: this.movingWall.staanderObject.dx,
                      rotation: this.movingWall.set.rotation,
                      x: this.movingWall.set.x,//60,
                      y: this.movingWall.set.y,
                      z: this.movingWall.set.z,//2600,
                      tussenMuur: this.movingWall.set.tussenMuur
                    }
                    const hoofdthis = this
                    let parentOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                    this.insertWall(parentOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, function() {
                      if(parentOfInsertedWall.childWall) {
                        if(parentOfInsertedWall.childWall.childWall) {
                          parentOfInsertedWall.childWall.showWallStaander(hoofdthis.textureFrame)
                        }
                      }
                      if(parentOfInsertedWall.childWall) {
                        parentOfInsertedWall.showWallStaander(hoofdthis.textureFrame)
                      }
                      hoofdthis.updateMovingBeam(hoofdthis.previousPlanePosition)
                      hoofdthis.overigeRuimte.walls.push(parentOfInsertedWall.childWall)
                      hoofdthis.overigeRuimte.totalWidth -= hoofdthis.movingWall.staanderObject.dx
                      if(_callback) {
                        _callback()
                      }
                    })
                  }
                } else if(result.totalWidth +this.movingWall.staanderObject.dx< this.config.maxWallWidth && result.walls.length>1){
                  if(this.isTooBig || result.walls.length > 1) {
                    //remove extra wall
                    console.log(result.walls.length, "remove extra wall")
                    
                    result.walls[0].childWall.group.removeFromParent()
                    result.walls[0].childWall.mapGroup.removeFromParent()
                    result.walls[0].childWall.modelGroup.removeFromParent()

                    if(result.walls[0].childWall.childWall) {
                      console.log("eerste")
                      result.walls[0].childWall = result.walls[0].childWall.childWall
                      result.walls[0].settings.Cuuid = result.walls[0].childWall.settings.uuid
                      result.walls[0].childWall.parentWall = result.walls[0]
                      result.walls[0].childWall.settings.Puuid = result.walls[0].childWall.parentWall.settings.uuid
                    } else {
                      console.log("tweede")
                      result.walls[0].childWall = null
                      result.walls[0].settings.Cuuid = null
                      result.walls[0].wallStaander.removeFromParent()
                      result.walls[0].wallStaander = null
                      result.walls[0].mapWallStaander.removeFromParent()
                      result.walls[0].mapWallStaander = null
                    }
                    result.walls[0].previousWallWidth += result.walls[0].previousWallWidth + result.walls[0].staanderObject.dx
                    console.log("index: ", this.muurInstances.indexOf(result.walls[1]))
                    this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                    this.overigeRuimte.walls = this.getEmptyChildWalls(this.movingWall).walls
                    this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx

                    


                    this.isTooBig = false
                  }
                }

                let addedWidth = 0
                console.log(this.overigeRuimte.walls.length, this.overigeRuimte.lockedWalls.length)
                if(!this.overigeRuimte.shouldInsertNewWall) {
                  for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                    this.overigeRuimte.lockedWalls[m].group.position.x = this.movingWall.group.position.x-this.movingWall.set.mx-addedWidth-this.movingWall.staanderObject.dx
                    this.overigeRuimte.lockedWalls[m].mapGroup.position.x = this.overigeRuimte.lockedWalls[m].group.position.x
                    this.overigeRuimte.lockedWalls[m].modelGroup.position.x = this.overigeRuimte.lockedWalls[m].group.position.x
                    addedWidth += this.overigeRuimte.lockedWalls[m].set.mx + this.movingWall.staanderObject.dx
                  }
                }

                for(let m=0; m<this.overigeRuimte.walls.length; m++) {
                  this.overigeRuimte.walls[m].set.mx = ((this.overigeRuimte.totalWidth-addition)/this.overigeRuimte.walls.length) //+ (this.overigeRuimte.walls[m].wallStaander ? +this.overigeRuimte.walls[m].staanderObject.dx : 0)
                  shouldRenderChildFrame = !(this.overigeRuimte.walls[m].settings.binnenMuur && this.overigeRuimte.walls[m].settings.buitenMuur)
                  this.overigeRuimte.walls[m].update(this.overigeRuimte.walls[m].y, this.overigeRuimte.walls[m].set, this.overigeRuimte.walls[m].schoor, this.overigeRuimte.walls[m].staanderGrootte, shouldRenderChildFrame)
                  this.overigeRuimte.walls[m].group.position.x = this.movingWall.group.position.x-this.movingWall.set.mx-addedWidth-this.movingWall.staanderObject.dx //+ m*(this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx)
                  this.overigeRuimte.walls[m].mapGroup.position.x = this.overigeRuimte.walls[m].group.position.x
                  this.overigeRuimte.walls[m].modelGroup.position.x = this.overigeRuimte.walls[m].group.position.x
                  if(this.overigeRuimte.walls[m].wallStaander !== null) {
                    this.overigeRuimte.walls[m].wallStaander.position.x = this.overigeRuimte.walls[m].set.mx + this.overigeRuimte.walls[m].staanderObject.dx/2
                    this.overigeRuimte.walls[m].mapWallStaander.position.x = this.overigeRuimte.walls[m].wallStaander.position.x
                  }
                  addedWidth += this.overigeRuimte.walls[m].set.mx + this.movingWall.staanderObject.dx
                }

              } else {
                //towards positive z
                // console.log("2")

                let addition
                //calculate the difference in mouse position
                const addition2 = Math.round(this.movingStartPlanePosition.x - planePosition.x)
                //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
                const addition3 = (this.movingWall.movingWallBeginValue - addition2 > this.config.maxWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.maxWallWidth) : (this.movingWall.movingWallBeginValue - addition2) < (this.config.minWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.minWallWidth) : addition2
                //adds minimum and maximum boundaries for child wall
                let addition4
                if(!this.movingWall.childWall.isSteellook) {
                  addition4 = this.movingWall.childWall !== null ? ((this.movingWall.previousWallWidth+this.movingWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition3)) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth + this.movingWall.childWall.previousWallWidth - this.config.minWallWidth)) : addition3 : addition3
                } else {
                  addition4 = this.movingWall.childWall.childWall !== null ? ((this.movingWall.previousWallWidth+this.movingWall.childWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition3)) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth + this.movingWall.childWall.childWall.previousWallWidth - this.config.minWallWidth)) : addition3 : addition3
                }
                //don't make any changes when the currently selected wall is too small to fit another wall    (2* minimumWallWidth + width of a staander)
                addition = this.movingWall.childWall == undefined ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + this.movingWall.staanderObject.dx) ? 0 : addition4 : addition4

                this.movingWall.set.mx = this.movingWall.movingWallBeginValue - addition


                if(this.movingWall.wallStaander !== null) {
                  this.movingWall.wallStaander.position.x = this.movingWall.movingWallBeginValue - addition + (this.movingWall.staanderObject.dx/2)
                  this.movingWall.mapWallStaander.position.x = this.movingWall.movingWallBeginValue - addition + (this.movingWall.staanderObject.dx/2)
                }
                
      
                await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, shouldRenderFrame)
      
                if(this.movingWall.childWall !== null && this.movingWall.childWall.hasFinishedBuilding && !this.movingWall.childWall.isSteellook) {
                  this.movingWall.childWall.set.mx = (this.movingWall.previousWallWidth+this.movingWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition)//-120
                  this.movingWall.childWall.group.position.x = this.movingWall.set.mx + this.getParentsWidth(this.movingWall) + this.movingWall.staanderObject.dx*2
                  this.movingWall.childWall.mapGroup.position.x = this.movingWall.childWall.group.position.x
                  this.movingWall.childWall.modelGroup.position.x = this.movingWall.childWall.group.position.x
                  if(this.movingWall.childWall.wallStaander !== null) {
                    this.movingWall.childWall.wallStaander.position.x = this.movingWall.childWall.set.mx + this.movingWall.childWall.staanderObject.dx/2
                    this.movingWall.childWall.mapWallStaander.position.x = this.movingWall.childWall.set.mx + this.movingWall.childWall.staanderObject.dx/2
                  }
                  shouldRenderChildFrame = !(this.movingWall.childWall.settings.binnenMuur && this.movingWall.childWall.settings.buitenMuur)
                  this.movingWall.childWall.update(this.movingWall.childWall.y, this.movingWall.childWall.set, this.movingWall.childWall.schoor, this.movingWall.childWall.staanderGrootte, shouldRenderChildFrame)
                
                
                } else if (this.movingWall.childWall !== null && this.movingWall.childWall.isSteellook) {
                  this.movingWall.childWall.childWall.set.mx = (this.movingWall.previousWallWidth+this.movingWall.childWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition)//-120
                  
                  this.movingWall.childWall.group.position.x = this.movingWall.set.mx + this.getParentsWidth(this.movingWall) + this.movingWall.staanderObject.dx*2
                  this.movingWall.childWall.mapGroup.position.x = this.movingWall.childWall.group.position.x
                  this.movingWall.childWall.modelGroup.position.x = this.movingWall.childWall.group.position.x

                  this.movingWall.childWall.childWall.group.position.x = this.movingWall.set.mx + this.movingWall.childWall.set.mx + this.getParentsWidth(this.movingWall) + this.movingWall.staanderObject.dx*3
                  this.movingWall.childWall.childWall.mapGroup.position.x = this.movingWall.childWall.childWall.group.position.x
                  this.movingWall.childWall.childWall.modelGroup.position.x = this.movingWall.childWall.childWall.group.position.x

                  if(this.movingWall.childWall.childWall.wallStaander !== null) {
                    this.movingWall.childWall.childWall.wallStaander.position.x = this.movingWall.childWall.childWall.set.mx + this.movingWall.childWall.childWall.staanderObject.dx/2
                    this.movingWall.childWall.childWall.mapWallStaander.position.x = this.movingWall.childWall.childWall.wallStaander.position.x
                  }
                  shouldRenderChildFrame = !(this.movingWall.childWall.childWall.settings.binnenMuur && this.movingWall.childWall.childWall.settings.buitenMuur)
                  this.movingWall.childWall.childWall.update(this.movingWall.childWall.childWall.y, this.movingWall.childWall.childWall.set, this.movingWall.childWall.childWall.schoor, this.movingWall.childWall.childWall.staanderGrootte, shouldRenderChildFrame)
                }
              }
            } else {
              if(this.movingWall.alignedRotation) {
                //towards negative x
                // console.log("3")

                let addition
                // console.log(this.overigeRuimte)
                //calculate the difference in mouse position
                const addition2 = Math.round(this.movingStartPlanePosition.z - planePosition.z)
                //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
                const addition3 = (this.movingWall.previousWallWidth - addition2 > this.config.maxWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.maxWallWidth) : (this.movingWall.movingWallBeginValue - addition2) < (this.config.minWallWidth) ? (this.movingWall.movingWallBeginValue - this.config.minWallWidth) : addition2
                //adds minimum and maximum boundaries for child wall
                const addition4 = this.overigeRuimte.walls.length !== 0 ? ((this.overigeRuimte.totalWidthStart+addition3)/this.overigeRuimte.walls.length) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth + this.overigeRuimte.walls[0].previousWallWidth  - this.config.minWallWidth))+(this.isTooBig ? this.movingWall.staanderObject.dx : 0) : addition3 : addition3 //this.movingWall.childwall !== null ? ((this.movingWall.previousWallWidth+this.movingWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue - addition3)) < this.config.minWallWidth ? (this.movingWall.previousWallWidth - (this.movingWall.previousWallWidth + this.movingWall.childWall.previousWallWidth - this.config.minWallWidth)) : addition3 : addition3
                addition = this.overigeRuimte.walls.length == 0 ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + this.movingWall.staanderObject.dx) ? 0 : addition4 : addition4
                console.log("MOVE RIGHT", this.movingWall.previousWallWidth, addition)
                this.movingWall.set.mx = this.movingWall.previousWallWidth - addition
                await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, true)
                if(this.movingWall.wallStaander !== null) {
                  this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                }
                this.overigeRuimte.totalWidth = this.overigeRuimte.totalWidthStart+addition
                
                let result = this.overigeRuimte//this.getEmptyChildWalls(this.movingWall)
                console.log(result)
                console.log("DATTAAA", result.totalWidth, this.movingWall.previousWallWidth)
                if(result.totalWidth > result.walls.length * this.config.maxWallWidth) {
                  if(!this.isTooBig) {
                    console.log("add extra wall")
                    this.isTooBig = true
                    //generate extra wall
                    const set = {
                      mx: 0,
                      my: 2300,
                      mz: this.movingWall.staanderObject.dx,
                      rotation: this.movingWall.set.rotation,
                      x: this.movingWall.set.x,//60,
                      y: this.movingWall.set.y,
                      z: this.movingWall.set.z,//2600,
                      tussenMuur: this.movingWall.set.tussenMuur
                    }
                    let parentOfInsertedWall = result.walls[0] ? result.walls[0] : this.movingWall
                    this.insertWall(parentOfInsertedWall, set, 2300, this.movingWall.movingSide === 1, () => {
                      if(parentOfInsertedWall.childWall) {
                        if(parentOfInsertedWall.childWall.childWall) {
                          parentOfInsertedWall.childWall.showWallStaander(this.textureFrame)
                        }
                      }
                      if(parentOfInsertedWall.childWall) {
                        parentOfInsertedWall.showWallStaander(this.textureFrame)
                      }
                      this.updateMovingBeam(this.previousPlanePosition)
                      //this.overigeRuimte.walls.push(parentOfInsertedWall.childWall)
                      const newWallIndex = this.overigeRuimte.walls.indexOf(parentOfInsertedWall)
                      console.log(newWallIndex)
                      this.overigeRuimte.walls.splice(newWallIndex, 0, parentOfInsertedWall.childWall)
                      this.overigeRuimte.totalWidth -= this.movingWall.staanderObject.dx
                      this.overigeRuimte.totalWidthStart -= this.movingWall.staanderObject.dx
                      if(_callback) {
                        _callback()
                      }
                    })
                  }
                } else if(result.totalWidth +this.movingWall.staanderObject.dx< this.config.maxWallWidth && result.walls.length>1){
                  if(this.isTooBig || result.walls.length > 1) {
                    //remove extra wall
                    console.log(result.walls.length, "remove extra wall")
                    
                    result.walls[0].childWall.group.removeFromParent()
                    result.walls[0].childWall.mapGroup.removeFromParent()
                    result.walls[0].childWall.modelGroup.removeFromParent()

                    if(result.walls[0].childWall.childWall) {
                      console.log("eerste")
                      result.walls[0].childWall = result.walls[0].childWall.childWall
                      result.walls[0].settings.Cuuid = result.walls[0].childWall.settings.uuid
                      result.walls[0].childWall.parentWall = result.walls[0]
                      result.walls[0].childWall.settings.Puuid = result.walls[0].childWall.parentWall.settings.uuid
                    } else {
                      console.log("tweede")
                      result.walls[0].childWall = null
                      result.walls[0].settings.Cuuid = null
                      result.walls[0].wallStaander.removeFromParent()
                      result.walls[0].wallStaander = null
                      result.walls[0].mapWallStaander.removeFromParent()
                      result.walls[0].mapWallStaander = null
                    }
                    result.walls[0].previousWallWidth += result.walls[0].previousWallWidth + result.walls[0].staanderObject.dx
                    console.log("index: ", this.muurInstances.indexOf(result.walls[1]))
                    this.muurInstances.splice(this.muurInstances.indexOf(result.walls[1]), 1)

                    this.overigeRuimte.walls = this.getEmptyChildWalls(this.movingWall.childWall).walls
                    this.overigeRuimte.totalWidth += this.movingWall.staanderObject.dx
                    this.overigeRuimte.totalWidthStart += this.movingWall.staanderObject.dx

                    


                    this.isTooBig = false
                  }
                }

                let addedWidth = 0

                if(!this.overigeRuimte.shouldInsertNewWall) {
                  for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
                    let wall = this.overigeRuimte.lockedWalls[m]
                    if(wall) {
                      wall.group.position.z = this.movingWall.set.mx+this.movingWall.group.position.z + this.movingWall.staanderObject.dx + addedWidth
                      wall.mapGroup.position.z = wall.group.position.z
                      wall.modelGroup.position.z = wall.group.position.z
                      addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
                    }
                  }
                }

                for(let m=0; m<this.overigeRuimte.walls.length; m++) {
                  let wall = this.overigeRuimte.walls[m]
                  if(wall) {
                    wall.set.mx = this.overigeRuimte.totalWidth/this.overigeRuimte.walls.length//+ (wall.wallStaander ? +wall.staanderObject.dx : 0)
                    shouldRenderChildFrame = !(wall.settings.binnenMuur && wall.settings.buitenMuur)
                    wall.update(wall.y, wall.set, wall.schoor, wall.staanderGrootte, shouldRenderChildFrame)
                    wall.group.position.z = this.movingWall.set.mx+this.movingWall.group.position.z+addedWidth+this.movingWall.staanderObject.dx //+ m*(wall.set.mx + wall.staanderObject.dx)
                    wall.mapGroup.position.z = wall.group.position.z
                    wall.modelGroup.position.z = wall.group.position.z
                    if(wall.wallStaander !== null) {
                      wall.wallStaander.position.x = wall.set.mx + wall.staanderObject.dx/2
                      wall.mapWallStaander.position.x = wall.wallStaander.position.x
                    }
                    addedWidth += wall.set.mx + this.movingWall.staanderObject.dx
                  }
                }
                
              } else {
                //towards positive x
                // console.log("4")

                let addition
                //calculate the difference in mouse position
                const addition2 = Math.round(this.movingStartPlanePosition.z - planePosition.z)
                //adds minimum and maximum boundaries for each individual wall.    e.g. maximum value=3480, minumum value=500
                const addition3 = (this.movingWall.movingWallBeginValue + addition2 > this.config.maxWallWidth) ? (this.config.maxWallWidth - this.movingWall.movingWallBeginValue) : (this.movingWall.movingWallBeginValue + addition2) < (this.config.minWallWidth) ? (this.config.minWallWidth - this.movingWall.movingWallBeginValue) : addition2
                //adds minimum and maximum boundaries for child wall
                let addition4
                if(!this.movingWall.childWall.isSteellook) {
                  addition4 = this.movingWall.childWall !== null ? ((this.movingWall.previousWallWidth+this.movingWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue + addition3)) < this.config.minWallWidth ? ((this.movingWall.previousWallWidth + this.movingWall.childWall.previousWallWidth - this.config.minWallWidth)-this.movingWall.previousWallWidth) : addition3 : addition3
                } else {
                  addition4 = this.movingWall.childWall.childWall !== null ? ((this.movingWall.previousWallWidth+this.movingWall.childWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue + addition3)) < this.config.minWallWidth ? ((this.movingWall.previousWallWidth + this.movingWall.childWall.childWall.previousWallWidth - this.config.minWallWidth)-this.movingWall.previousWallWidth) : addition3 : addition3
                }
                //don't make any changes when the currently selected wall is too small to fit another wall    (2* minimumWallWidth + width of a staander)
                addition = addition4//this.movingWall.childwall == undefined ? this.movingWall.previousWallWidth < (2*this.config.minWallWidth + this.movingWall.staanderObject.dx) ? 0 : addition4 : addition4

                
                //this.movingWall.group.position.z = this.movingWall.movingWallBeginPosition - addition
                this.movingWall.set.mx = this.movingWall.movingWallBeginValue + addition


                await this.movingWall.update(this.movingWall.y, this.movingWall.set, this.movingWall.schoor, this.movingWall.staanderGrootte, shouldRenderFrame)


                if(this.movingWall.childWall !== null && this.movingWall.childWall.hasFinishedBuilding && !this.movingWall.childWall.isSteellook) {
                  this.movingWall.childWall.set.mx = (this.movingWall.previousWallWidth+this.movingWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue + addition)//-this.movingWall.set.mx-this.movingWall.staanderObject.dx//(this.movingWall.movingWallBeginValue - addition) - this.movingWall.staanderObject.dx
                  this.movingWall.childWall.group.position.z = this.getChildrenWidth(this.movingWall) + this.movingWall.childWall.rootPosition - this.getParentsWidth(this.movingWall.childWall) - this.movingWall.childWall.set.mx - this.movingWall.staanderObject.dx//this.movingWall.staanderObject.dx*2 //(this.movingWall.previousWallWidth+this.movingWall.parentWall.previousWallWidth)-(this.movingWall.set.mx) + this.movingWall.staanderObject.dx*1 + this.getParentsWidth(this.movingWall.parentWall)
                  this.movingWall.childWall.mapGroup.position.z = this.movingWall.childWall.group.position.z
                  this.movingWall.childWall.modelGroup.position.z = this.movingWall.childWall.group.position.z
                  if(this.movingWall.childWall.wallStaander !== null) {
                    this.movingWall.childWall.wallStaander.position.x = this.movingWall.childWall.set.mx + this.movingWall.childWall.staanderObject.dx/2
                    this.movingWall.childWall.mapWallStaander.position.x = this.movingWall.childWall.set.mx + this.movingWall.childWall.staanderObject.dx/2
                  }
                  if(this.movingWall.wallStaander !== null) {
                    this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                    this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  }
                  shouldRenderChildFrame = !(this.movingWall.childWall.settings.binnenMuur && this.movingWall.childWall.settings.buitenMuur)
                  this.movingWall.childWall.update(this.movingWall.childWall.y, this.movingWall.childWall.set, this.movingWall.childWall.schoor, this.movingWall.childWall.staanderGrootte, shouldRenderChildFrame)
              
              
                } else if(this.movingWall.childWall !== null && this.movingWall.childWall.isSteellook) {
                  this.movingWall.childWall.childWall.set.mx = (this.movingWall.previousWallWidth+this.movingWall.childWall.childWall.previousWallWidth)-(this.movingWall.movingWallBeginValue + addition)
                  
                  this.movingWall.childWall.group.position.z = this.movingWall.rootPosition - this.getParentsWidth(this.movingWall.childWall)
                  this.movingWall.childWall.mapGroup.position.z = this.movingWall.childWall.group.position.z
                  this.movingWall.childWall.modelGroup.position.z = this.movingWall.childWall.group.position.z

                  this.movingWall.childWall.childWall.group.position.z = this.movingWall.rootPosition - this.getParentsWidth(this.movingWall.childWall.childWall)
                  this.movingWall.childWall.childWall.mapGroup.position.z = this.movingWall.childWall.childWall.group.position.z
                  this.movingWall.childWall.childWall.modelGroup.position.z = this.movingWall.childWall.childWall.group.position.z
                  
                  if(this.movingWall.childWall.childWall.wallStaander !== null) {
                    this.movingWall.childWall.childWall.wallStaander.position.x = this.movingWall.childWall.childWall.set.mx + this.movingWall.childWall.childWall.staanderObject.dx/2
                    this.movingWall.childWall.childWall.mapWallStaander.position.x = this.movingWall.childWall.childWall.wallStaander.position.x
                  }
                  if(this.movingWall.wallStaander !== null) {
                    this.movingWall.wallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                    this.movingWall.mapWallStaander.position.x = this.movingWall.set.mx + this.movingWall.staanderObject.dx/2
                  }
                  shouldRenderChildFrame = !(this.movingWall.childWall.childWall.settings.binnenMuur && this.movingWall.childWall.childWall.settings.buitenMuur)
                  this.movingWall.childWall.childWall.update(this.movingWall.childWall.childWall.y, this.movingWall.childWall.childWall.set, this.movingWall.childWall.childWall.schoor, this.movingWall.childWall.childWall.staanderGrootte, shouldRenderChildFrame)
                }
              }
            }*/

        }

        for(let m=0; m<this.overigeRuimte.lockedWalls.length; m++) {
          if(!this.overigeRuimte.lockedWalls[m].isSteellook) {
            this.overigeRuimte.lockedWalls[m].updateOutsideWallTextureAlignment()
          }
        }
        for(let m=0; m<this.overigeRuimte.walls.length; m++) {
          if(!this.overigeRuimte.walls[m].isSteellook) {
            this.overigeRuimte.walls[m].updateOutsideWallTextureAlignment()
          }
        }

        // console.log('length: ' + (Date.now()-olddate))
        //setTimeout(() => {//DEZE REGEL
          // console.log(this.movingWall)
          this.movingWall.isUpdating = false
          
          if(!this.isPlatdak && !this.movingWall.alignsXAxis && this.goot.dakgoot.front.enabled && (this.x > this.goot.thirdPipeThreshold)) {
            this.updateMiddenGootPosition()
          }
          //alert("weer uit")
          // console.log("weer uit")
          if(this.movingBeamPositionArray.length !== 0) {
            this.setUpdateMovingBeam()
          }
        //}, 1);//DEZE REGEL

        // update selectedWallWidth in store
        store.commit('setSelectedWallWidth', this.movingWall.set.mx)

        // console.log("PWW OF DEZE " + this.isTooBig)
        // console.log(_callback, !this.isTooBig, isCallback)
        if(_callback && (!this.isTooBig || isCallback)) {
          //alert("callback")
          _callback()
        }

        
        


    



    }
  }

  setWallWidth(data) {
    /*let addition = data.wall.set.mx - data.width
    console.log(addition)
    
    if(data.wall.alignsXAxis) {
      if(!data.wall.isLeftLocked) {
        data.wall.group.position.x = data.wall.group.position.x - addition
      }
    } else {
      if(!data.wall.isLeftLocked) {
        data.wall.group.position.z = data.wall.group.position.z + addition
      }
    }
    data.wall.set.mx = data.width



    data.wall.update(data.wall.y, data.wall.set, data.wall.schoor, data.wall.staanderGrootte)*/
    this.movingWall = toRaw(data.wall)
    this.movingWall.movingSide = data.side
    this.movingWall.movingWallBeginValue = this.movingWall.set.mx
    this.movingWall.movingWallBeginPosition = this.movingWall.alignsXAxis ? this.movingWall.group.position.x : this.movingWall.group.position.z
    this.overigeRuimte = (this.movingWall.movingSide === 0 ? this.getEmptyParentWalls(this.movingWall.parentWall) : this.getEmptyChildWalls(this.movingWall.childWall))
    this.childWallIsLocked = this.movingWall.childWall ? this.movingWall.childWall.isLocked : false

    this.muurInstancesPrevious = []
    for(let y=0; y<this.muurInstances.length; y++) {
      this.muurInstancesPrevious.push({
        uuid: this.muurInstances[y].group.uuid,
        width: this.muurInstances[y].set.mx,
        position: JSON.parse(JSON.stringify(this.muurInstances[y].group.position)),
      })
    }

    let planePosition

    this.movingStartPlanePosition = {x:0, y:0, z:0}
    if((this.movingWall.alignsXAxis && !this.movingWall.alignedRotation) ? data.side == 0 : data.side == 1 ) {
      planePosition = {x:this.movingWall.set.mx-data.width, y:0, z:this.movingWall.set.mx-data.width}
      // console.log("side1")
    } else if((this.movingWall.alignsXAxis && !this.movingWall.alignedRotation) ? data.side == 1 : data.side == 0) {
      planePosition = {x:data.width-this.movingWall.set.mx, y:0, z:data.width-this.movingWall.set.mx}
      // console.log("side0")
    }
    if(this.movingWall.alignedRotation && !this.movingWall.alignsXAxis) {
      planePosition = {x:(planePosition.x)*-1, y:0, z:(planePosition.z)*-1}
    }

    /*if(Math.max(planePosition.x,planePosition.z) > 0 && !this.overigeRuimte.walls.length) {
      planePosition.x = 0
      planePosition.z = 0
    }*/
    
    // console.log(planePosition)
    this.movingBeamPositionArray.unshift(planePosition)

    const hoofdthis = this
    this.setUpdateMovingBeam(function() {
      hoofdthis.removeMovingBeam(true) 
    })

    /*planePosition = {x:0, y:0, z:0}
    this.movingBeamPositionArray.unshift(planePosition)
    this.setUpdateMovingBeam()*/

    /*setTimeout(() => {
      this.removeMovingBeam()
    }, 1000);*/
    


  }

  updateMiddenGootPosition () {
    const frontWalls = this.muurInstances.filter(aWall => !aWall.alignsXAxis && aWall.alignedRotation)
    const frontWallPositions = []
    frontWalls.forEach(fWall => {
      frontWallPositions.push(fWall.group.position.z + fWall.set.mx + fWall.staanderObject.dx/2)
    })

    const backWalls = this.muurInstances.filter(aWall => !aWall.alignsXAxis && !aWall.alignedRotation)
    const backWallPositions = []
    backWalls.forEach(bWall => {
      backWallPositions.push(bWall.group.position.z - bWall.set.mx - bWall.staanderObject.dx/2)
    })

    this.goot.setMiddenGootPosition({
      frontWallPositions,
      backWallPositions
    })
  }

  getChildrenWidth(el) {
    // console.log("kindergrootte")
    var totalWidth = 0;
    var currentElement = el
    if(currentElement == null) {
      return totalWidth
    }
    return loopje()
    
    function loopje() {
      if(currentElement.childWall != null) {
        totalWidth += currentElement.childWall.set.mx
        totalWidth += currentElement.staanderObject.dx
        currentElement = currentElement.childWall
        // console.log("dingetje")
        return loopje()
      } else {
        // console.log("dingetje2")
        return totalWidth
      }
    }
  }

  getParentsWidth(el) {
    // console.log(el)
    // console.log("oudergrootte")
    var totalWidth = 0;
    var currentElement = el
    if(currentElement === null) {
      return totalWidth
    }
    return loopje()
    
    function loopje() {
      if(currentElement.parentWall != null) {
        totalWidth += currentElement.parentWall.set.mx
        totalWidth += currentElement.staanderObject.dx
        currentElement = currentElement.parentWall
        // console.log("dingetje")
        return loopje()
      } else {
        // console.log("dingetje2")
        return totalWidth
      }
    }
  }

  getEmptyChildWalls(el, calledFromSteellook) {
    var currentElement = el
    var currentElementP = el ? el.parentWall : null
    var hasEncounteredOpenWall = false
    var previousElementLockState = false

    var result = {
      lockedWalls: [],
      walls: [],
      totalWidth: 0,
      totalWidthStart: 0,
      shouldInsertNewWall: true
    }
    if(currentElement == null) {
      return result
    }
    loopjeChild()
    loopjeParent()
    result.totalWidthStart = result.totalWidth
    return result

    function loopjeChild() {
      if(currentElement != undefined){

        if(currentElement.isLocked && !hasEncounteredOpenWall) {
          result.lockedWalls.push(currentElement)
        }
        // console.log(currentElement.isLocked, hasEncounteredOpenWall, previousElementLockState)
        if(!currentElement.isLocked && (!hasEncounteredOpenWall || !previousElementLockState)) {
          result.walls.push(currentElement)
          result.totalWidth += currentElement.set.mx
          //result.totalWidth -= currentElement.staanderObject.dx
          result.shouldInsertNewWall = false
          hasEncounteredOpenWall = true
        } else {
          //result.totalWidth -= currentElement.set.mx 
          //result.totalWidth -= currentElement.staanderObject.dx
        }

        // if(calledFromSteellook) {
        //   if(!previousElementLockState) {
        //     previousElementLockState = currentElement.isLocked
        //   }
        // } else {
        //   previousElementLockState = currentElement.isLocked
        // }
        calledFromSteellook&&previousElementLockState||(previousElementLockState=currentElement.isLocked)
        

        currentElement = currentElement.childWall
        loopjeChild()
      } else {
        return result
      }
    }

    function loopjeParent() {
      if(currentElementP != undefined){
        // console.log(currentElementP)
        //result.totalWidth -= currentElementP.set.mx 
        //result.totalWidth -= currentElementP.staanderObject.dx
        currentElementP = currentElementP.parentWall
        return loopjeParent()
      } else {
        return result
      }
    }
  }

  getEmptyParentWalls(el, calledFromSteellook) {
    var currentElement = el
    var currentElementP = el ? el.childWall : null
    var hasEncounteredOpenWall = false
    var previousElementLockState = false

    var result = {
      lockedWalls: [],
      walls: [],
      totalWidth: 0,
      totalWidthStart: 0,
      shouldInsertNewWall: true
    }
    if(currentElement == null) {
      return result
    }
    loopjeParent()
    loopjeChild()
    result.totalWidthStart = result.totalWidth
    return result

    function loopjeParent() {
      if(currentElement != undefined){

        if(currentElement.isLocked && !hasEncounteredOpenWall) {
          result.lockedWalls.push(currentElement)
        }
  
        if(!currentElement.isLocked && (!hasEncounteredOpenWall || !previousElementLockState)) {
          result.walls.push(currentElement)
          result.totalWidth += currentElement.set.mx
          //result.totalWidth -= currentElement.staanderObject.dx
          result.shouldInsertNewWall = false
          hasEncounteredOpenWall = true
        } else {
          //result.totalWidth -= currentElement.set.mx 
          //result.totalWidth -= currentElement.staanderObject.dx
        }
        //console.log("result.totalWidthP", result.totalWidth, currentElement.set.mx)
  
        // if(!previousElementLockState) {
        //   previousElementLockState = currentElement.isLocked
        // }
        calledFromSteellook&&previousElementLockState||(previousElementLockState=currentElement.isLocked)
  
        currentElement = currentElement.parentWall
        loopjeParent()
      } else {
        return result
      }
    }

    function loopjeChild() {
      if(currentElementP != undefined){
        // console.log(currentElementP)
        //result.totalWidth -= currentElementP.set.mx 
        //result.totalWidth -= currentElementP.staanderObject.dx
        currentElementP = currentElementP.childWall
        return loopjeChild()
      } else {
        return result
      }
    }
  }

  getfirstLockedParentLocationAndWidth(el) {
    let currentElement = el
    while(currentElement && !currentElement.isLocked) {
      currentElement=currentElement.parentWall
    }
    if(currentElement) {
      // console.log("beginbreedte: " + (currentElement.group.position.z + currentElement.set.mx))
      return (currentElement.group.position.z + currentElement.set.mx)
    } else {
      // console.log("beginbreedte: " + 0)
      return 0
    }
  }

  getFirstUnlockedParent(el) {
    console.log("el", el)
    var currentElement = el
    if(currentElement === null) {
      return currentElement
    }
    while(currentElement.parentWall && !currentElement.parentWall.isLocked) {
      currentElement = currentElement.parentWall
    }
    return currentElement
    // return loopje()
    // function loopje() {
    //   if(currentElement.parentWall && !currentElement.parentWall.isLocked) {
    //     currentElement = currentElement.parentWall
    //     return loopje()
    //   } else {
    //     return currentElement
    //   }
    // }
  }

  getCombinedWallWidth(el) {
    const parentWidth = this.getParentsWidth(el)
    const childWidth = this.getChildrenWidth(el)
    // console.log("cWidth: " + childWidth)
    // console.log("pWidth: " + parentWidth)
    // console.log("aWidth: " + (parentWidth+childWidth+el.set.mx))
    return (parentWidth+childWidth+el.set.mx)
  }

  getFirstNonSteellookWall(el) {
    let currentElement = el
    while(currentElement.isSteellook) {
      if(this.movingWall.movingSide === 0) {
        currentElement = currentElement.parentWall
      } else {
        currentElement = currentElement.childWall
      }
    }
    if(currentElement) {
      return currentElement.hasFinishedBuilding
    } else {
      return true
    }
  }

  getTussenChildWalls(tussenChildWall) {
    // recieves a wall and returns said wall together with all the child walls of that wall
    var currentElement = tussenChildWall
    var result = [tussenChildWall]
    if(currentElement == null) {
      return result
    }
    while(currentElement.childWall) {
      result.push(currentElement.childWall)
      currentElement = currentElement.childWall
    }
    return result
  }

  setTussenMuurPosition(tussenMuur, axis, position) {
    let currentElement = tussenMuur
    while(currentElement) {
      currentElement.group.position[axis] = position
      currentElement.mapGroup.position[axis] = position
      currentElement.modelGroup.position[axis] = position
      currentElement = currentElement.childWall
    }
  }

  setWallPlusIcons(data) {
    if(data == null) {
      return 
    }
    const f = data.facadeOptions 
    const w = data.wallOptions
    this.componentIconPositions = [w.left, w.center, w.right, f.left, f.center, f.right]
    for (let i = 0; i < this.muurInstances.length; i++) {
      this.muurInstances[i].setWallPlusIcons(this.componentIconPositions, this.selectedWallComponent, this.getEmptyChildWalls(this.muurInstances[i]), this.getEmptyParentWalls(this.muurInstances[i]))
    }
  }

  setFoundation(value) {
    for(let i = 0; i < this.muurInstances.length; i++) {
      this.muurInstances[i].fundering = value;
      // console.log('hey', this.muurInstances[i].fundering)

      if(this.muurInstances[i].isSteellook || this.muurInstances[i].settings.binnenMuur || this.muurInstances[i].settings.buitenMuur) {
        this.muurInstances[i].betonBand.betonBandShow();

        if(value) {
          this.muurInstances[i].betonBand.betonBandShow();
        } else {
          this.muurInstances[i].betonBand.betonBandHide();
        }
      }

      if(value) {
        if(this.muurInstances[i].betonPoer) { 
          this.muurInstances[i].betonPoer.betonPoerShow();
        }
      } else {
        if(this.muurInstances[i].betonPoer) { 
          this.muurInstances[i].betonPoer.betonPoerHide()
        }
      }
    }

    for(let j = 0; j < this.staanders.arrayBetonPoeren.length; j++) {
      this.staanders.arrayBetonPoeren[j].visible = value;

    }
  }

  resetWalls() {
    return new Promise((resolve) => {


      new Promise((resolve2) => {

        let amountOfSteellooks = 0

        function callBackFunction() {
          amountOfSteellooks--
          if(amountOfSteellooks === 0) {
            resolve2()
          }
        }

        for(let i = 0; i < this.muurInstances.length; i++) {
          if(this.muurInstances[i].isSteellook) {
            amountOfSteellooks++
          }
        }
        
        for(let i = this.muurInstances.length-1; i > 0; i--) {
          if(this.muurInstances[i].isSteellook) {
            const muur = this.muurInstances[i]
            muur.group.removeFromParent()
            muur.mapGroup.removeFromParent()
            muur.modelGroup.removeFromParent()

            this.muurInstances.splice(this.muurInstances.indexOf(muur), 1)

            muur.deselect()
            console.log(muur)
            muur.set.x = muur.group.position.z
            muur.set.z = muur.group.position.x

            if(muur.parentWall) {
              muur.parentWall.childWall = muur.childWall 
              if(muur.childWall) {
                muur.parentWall.settings.Cuuid = muur.parentWall.childWall.settings.uuid
                muur.childWall.parentWall = muur.parentWall
                muur.childWall.settings.Puuid = muur.childWall.parentWall.settings.uuid
              }
              this.insertWall(muur.parentWall, muur.set, muur.dimensions.y, true, callBackFunction, this.textureFrame, true, true)
            } else if(muur.childWall) {
              muur.childWall.parentWall = muur.parentWall
              if(muur.parentWall) {
                muur.childWall.settings.Puuid = muur.parentWall.settings.uuid
              }
              this.insertWall(muur.childWall, muur.set, muur.dimensions.y, false, callBackFunction, this.textureFrame, true, true)
            } else {
              this.insertWall(muur, muur.set, muur.dimensions.y, false, callBackFunction, this.textureFrame, true, true)
            }
          }
        }

        if(amountOfSteellooks === 0) {
          resolve2()
        }
      }).then(() => {
        for(let i = 0; i < this.muurInstances.length; i++) {
          console.log(this.muurInstances[i].isSteellook)
          this.muurInstances[i].removeWall(true)
        }
        const wallArray = []
        for(let k=0; k<this.muurInstances.length; k++) {
          this.muurInstances[k].settings.position = JSON.parse(JSON.stringify(this.muurInstances[k].group.position))
          this.muurInstances[k].settings.position.x -= this.muurInstances[k].groupOffset.x
          this.muurInstances[k].settings.position.z -= this.muurInstances[k].groupOffset.z
          wallArray.push(this.muurInstances[k].settings)
        }
        store.dispatch('updateWallArrayConfig', wallArray)
        resolve()
      })
    })
  }

  resetTopgevels() {
    for(let i = 0; i < this.topgevel.topgevelArray.length; i++) {
      this.topgevel.removeWall(this.topgevel.topgevelArray[i].group.children[0])
    }
  }

  setHoekProfielen(wall) {
    
    if(wall.set.tussenMuur) {
      return
    }
    //get all walls where alignsXAxis and alignedRotation are the same as the supplied wall
    const walls = this.muurInstances.filter((w) => {
      return w.alignsXAxis === wall.alignsXAxis && w.alignedRotation === wall.alignedRotation && !w.set.tussenMuur
    })
    // sort the walls by their position
    walls.sort((a, b) => {
      if(wall.alignsXAxis) {
        if(wall.alignedRotation) {
          return b.group.position.x - a.group.position.x
        } else {
          return a.group.position.x - b.group.position.x
        }
      } else {
        if(wall.alignedRotation) {
          return a.group.position.z - b.group.position.z
        } else {
          return b.group.position.z - a.group.position.z
        }
      }
    })
    // get the index of the supplied wall

    

    let sortedWalls = []
    let firstWall
    // console.log('setHoekProfielen sortWalls()',walls)
    function sortWalls() {
      firstWall = walls.find(w => !sortedWalls.includes(w))
      // console.log('SORTWALLS firstWall=',firstWall)
      if(firstWall === undefined) {return}
      while(firstWall.parentWall) {
        firstWall = firstWall.parentWall
      }
      sortedWalls.push(firstWall)
      let count = 0; // stop looping please // check added during TASK 1394 / TASK 1455
      while(firstWall.childWall && count < 10) {      
        // console.log('2nd while firstWall.childWall',firstWall.childWall)
        firstWall = firstWall.childWall
        sortedWalls.push(firstWall)
        count++;
      }
      if(sortedWalls.length < walls.length) {
        // console.log('sortedWalls, walls',sortedWalls, walls)
        sortWalls()
      }
    }
    sortWalls()

    // const neightbouringWalls = []
    // const index = sortedWalls.indexOf(wall)
    // if(index !== 0) {
    //   neightbouringWalls.push(sortedWalls[index-1])
    // }
    // neightbouringWalls.push(wall)
    // if(index !== sortedWalls.length-1) {
    //   neightbouringWalls.push(sortedWalls[index+1])
    // }

    // console.log(neightbouringWalls, wall.group.position.z)

    // let positionString = ''
    // sortedWalls.forEach((wall) => {
    //   positionString += wall.group.position.z + ', '
    // })
    // console.log(positionString)

    sortedWalls.forEach((wall) => {

      //wall.group.position.y = ((i+1)*500)

      let shouldRight = false 
      let shouldLeft = false
      let shouldRightBoeideel = false 
      let shouldLeftBoeideel = false

      function muurIsOpen(muur) {
        return muur.settings.binnenMuur == muur.settings.binnenMuurTexture?.open || muur.settings.buitenMuur == muur.settings.buitenMuurTexture?.open
      }

      if(this.wallConfig.position === "buiten") {

        const index = sortedWalls.indexOf(wall)

        if(wall === undefined) {return}

        //first wall
        if(index === 0) {
          if(wall.wallOn && !wall.isSteellook && !muurIsOpen(wall)) {
            shouldLeft = true
          }
          shouldLeftBoeideel = true
        }

        //last wall
        if(index === sortedWalls.length-1) {
          if(wall.wallOn && !wall.isSteellook && !muurIsOpen(wall)) {
            shouldRight = true
          }
          shouldRightBoeideel = true
        }

        if(wall.wallOn && !wall.isSteellook && !muurIsOpen(wall) && (sortedWalls[index-1] && (!sortedWalls[index-1].wallOn || muurIsOpen(sortedWalls[index-1])) && !sortedWalls[index-1].isSteellook)) {
          shouldLeft = true
        }

        if(wall.wallOn && !wall.isSteellook && !muurIsOpen(wall) && (sortedWalls[index+1] && (!sortedWalls[index+1].wallOn || muurIsOpen(sortedWalls[index+1])) && !sortedWalls[index+1].isSteellook)) {
          shouldRight = true
        }

        console.log(wall)
      }

      wall.updateHoekProfiel({shouldRight, shouldLeft, shouldRightBoeideel, shouldLeftBoeideel})
    })
  }

  removeTussenChildWalls(wall) {
    // alert("removeTussenChildWalls")
    let currentElement = wall.tussenChildWall
    while(currentElement) {
      currentElement.group.removeFromParent()
      currentElement.mapGroup.removeFromParent()
      currentElement.modelGroup.removeFromParent()
      this.muurInstances.splice(this.muurInstances.indexOf(currentElement), 1)
      if(currentElement.tussenParentWall) {
        delete currentElement.tussenParentWall.tussenChildWall
      }
      currentElement = currentElement.childWall
    }
  }

  updateGordingenDivision () {
    const sortedBackWalls = this.muurInstances.filter(wall => !wall.alignsXAxis && !wall.alignedRotation).sort((a, b) => a.group.position.z - b.group.position.z)
    const wallDivision = sortedBackWalls.map(wall => {return {
      width: wall.set.mx,
      begingEnabled: false
    }})

    this.gording.update(
      this.gording.x,
      this.gording.y,
      this.gording.z,
      // this.gording.division,
      wallDivision,
      this.gording.gy,
      this.gording.gz,
      this.gording.overstekKlos,
      this.gording.kp,
      this.gording.ttOverspanning,
      this.gording.rbgy,
      this.gording.rbgz,
      this.gording.rbgp,
      this.gording.rby,
      this.gording.rbz,
      this.gording.verticalePositie,
      this.gording.staanders,
      this.gording.round,
      this.gording.erOn,
      this.gording.erOffset,
      this.gording.modelType,
      this.gording.graden,
      this.gording.rbtp,
      this.gording.rbtpy,
      this.gording.rbtpz,
      this.gording.ox,
      this.gording.oy,
      this.gording.oyl,
      this.gording.gsh,
      this.gording.gso,
      this.gording.overhang,
      this.gording.staanderGrootte,
      this.gording.kapschuurConfig,
      this.gording.dy,
      this.gording.dz
    )
    this.topgevel.update(
      this.topgevel.modelType, 
      this.topgevel.x, 
      this.topgevel.y, 
      this.topgevel.z, 
      this.topgevel.degrees, 
      this.topgevel.gy, 
      this.topgevel.rbgz, 
      wallDivision, 
      this.topgevel.totalOverhang, 
      this.topgevel.dx, 
      this.topgevel.ox, 
      this.topgevel.oy, 
      this.topgevel.kapschuurConfig
    )
  }

  getIsoIcon(THREE, textureloader, viewName, heeftIsolatie) {
    const meshGroup = new THREE.Group();

    const previewsServer = import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER;
    const anisotropy = 16;
    const encoding = THREE.sRGBEncoding;
    const vector = new THREE.Vector3( 0, Math.PI, 0); // flip the graphic around

    if(viewName !== 'Hover' && viewName !== 'Map') throw new Error('viewName must be Hover or Map');
    const planeSize = viewName === 'Hover' ? 350 : 300;
    let meshGroupName;
    let meshName;
    let textureName;
    if(heeftIsolatie) {
      meshGroupName = `isolatie${viewName}ButtonGroupActive`;
      meshName = `isolatie${viewName}ButtonActive`
      textureName = '/textures/ui/Wandisolatie_active.png';
    } else {
      meshGroupName = `isolatie${viewName}ButtonGroupInactive`;
      meshName = `isolatie${viewName}ButtonInactive`
      textureName = '/textures/ui/Wandisolatie_inactive.png';
    }

    meshGroup.name = meshGroupName //'isolatieHoverButtonGroupActive'
    const graphic = textureloader.load(`${previewsServer}${textureName}`);
    graphic.anisotropy = anisotropy;
    graphic.encoding = encoding;
    
    const spriteMaterial = new THREE.MeshBasicMaterial( { map: graphic, side: THREE.DoubleSide, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );
    const spriteGeometry = new THREE.BufferGeometry()
    spriteGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(planeSize, planeSize)), 3))
    // spriteGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(300, 1200)), 3)) // Wandisolatie_active_hover.png
    spriteGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    spriteGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
    const spriteMesh = new THREE.Mesh( spriteGeometry, spriteMaterial );
    spriteMesh.name = meshName // 'isolatieHoverButtonActive'
    spriteMesh.rotation.setFromVector3(vector);

    meshGroup.add(spriteMesh);

    return meshGroup;
  }

  createHoverIsoMainGroup(retourHeeftIsolatie=false) {
    const mainGroup = new this.THREE.Group();
    mainGroup.name = 'isolatieHoverButtonMainGroup';
    const hoverIsoActiveGroup = this.getIsoIcon(this.THREE, this.textureLoader, 'Hover', true);
    const hoverIsoInactiveGroup = this.getIsoIcon(this.THREE, this.textureLoader, 'Hover', false);
    hoverIsoActiveGroup.visible = retourHeeftIsolatie ? true : false; // name = 'isolatieHoverButtonGroupActive'
    hoverIsoInactiveGroup.visible = retourHeeftIsolatie ? false : true; // name = 'isolatieHoverButtonGroupInactive'
    mainGroup.add(hoverIsoActiveGroup);
    mainGroup.add(hoverIsoInactiveGroup);

    // lower the position of one button to help the raycaster hit the other button first
    if(retourHeeftIsolatie) { 
      // hide the OFF button
      hoverIsoInactiveGroup.position.set(0, -1, 0);
    } else {
      // hide the ON button
      hoverIsoActiveGroup.position.set(0, -1, 0);
    }
    return mainGroup;
  }

  createMapIsoMainGroup(retourHeeftIsolatie=false) {
    const mainGroup = new this.THREE.Group();
    mainGroup.name = 'isolatieMapButtonMainGroup';
    const mapIsoActiveGroup = this.getIsoIcon(this.THREE, this.textureLoader, 'Map', true);
    const mapIsoInactiveGroup = this.getIsoIcon(this.THREE, this.textureLoader, 'Map', false);
    mapIsoActiveGroup.visible = retourHeeftIsolatie ? true : false; // name = 'isolatieMapButtonGroupActive'
    mapIsoInactiveGroup.visible = retourHeeftIsolatie ? false : true; // name = 'isolatieMapButtonGroupInactive'
    mainGroup.add(mapIsoActiveGroup);
    mainGroup.add(mapIsoInactiveGroup);

    // lower the position of one button to help the raycaster hit the other button first
    if(retourHeeftIsolatie) { 
      // hide the OFF button
      mapIsoInactiveGroup.position.set(0, -1, 0);
    } else {
      // hide the ON button
      mapIsoActiveGroup.position.set(0, -1, 0);
    }
    return mainGroup;
  }
}