import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'

export default class Trim {
  constructor (THREE, textureLoader, colorTrim, cubeLoader, x, y, z, tz, ty, overstekKlos, dz, dsz, offset, color) {
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.colorTrim = colorTrim
    this.x = x
    this.y = y
    this.z = z
    this.tz = tz
    this.ty = ty
    this.dz = dz
    this.dsz = dsz
    this.offset = offset
    this.color = color
    this.group = null
    this.bouw(THREE, textureLoader, colorTrim, cubeLoader, x, y, z, tz, ty, overstekKlos, dz, dsz, offset, color)
  }

  bouw (THREE, textureLoader, colorTrim, cubeLoader, x, y, z, tz, ty, overstekKlos, dz, dsz, offset, color) {
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0

    // z as
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(z + (xBovVo + xBovAc) + (2*dz) + (2*dsz) - (2*tz) + (2*offset), ty, tz)), 3))
    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry.setIndex(boxBuffer.indexBuffer());
    geometry.groups = boxBuffer.groupBuffer()

    let textureCube = cubeLoader.load([ 
      // require('@/assets/test/posx.jpg'), 
      // require('@/assets/test/negx.jpg'), 
      // require('@/assets/test/posy.jpg'), 
      // require('@/assets/test/negy.jpg'), 
      // require('@/assets/test/posz.jpg'), 
      // require('@/assets/test/negz.jpg') 
    ]);
    textureCube.encoding = THREE.sRGBEncoding;

    let trimTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${colorTrim}`)
    const material = new this.THREE.MeshStandardMaterial({ 
        map: trimTexture, 
        // color: new this.THREE.Color(0xDFDFDF), 
        roughness: 0.1,  // Adjust between 0-1
        metalness: 0.2   // Adjust between 0-1})
      })

    const trim0 = new THREE.Mesh( geometry, material );
    trim0.position.z = - (xBovLi) - dz - dsz + (tz / 2) - offset
    trim0.position.x = z / 2 + xBovAc / 2 - xBovVo / 2
    trim0.castShadow = true; 
    trim0.receiveShadow = true;
    trim0.frustumCulled = false

    const trim1 = trim0.clone()
    trim1.position.z = x + (xBovRe) + dz + dsz - (tz / 2) + offset

    // x as
    const geometry1 = new THREE.BufferGeometry()
    geometry1.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(x + (xBovLi + xBovRe) + (2*dz) + (2*dsz) + (2*offset), ty, tz)), 3))
    geometry1.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry1.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry1.setIndex(boxBuffer.indexBuffer());
    geometry1.groups = boxBuffer.groupBuffer()

    const trim2 = new THREE.Mesh( geometry1, material );
    trim2.rotation.y = 0.5 * Math.PI
    trim2.position.z = x / 2 - xBovLi / 2 + xBovRe / 2
    trim2.position.x = z + (xBovAc) + dz + dsz - (tz / 2) + offset
    trim2.castShadow = true; 
    trim2.receiveShadow = true;
    trim2.frustumCulled = false

    const trim3 = trim2.clone()
    trim3.position.x = - (xBovVo) - dz - dsz + (tz / 2) - offset

    // group
    const group = new this.THREE.Group()
    group.add(trim0)
    group.add(trim1)
    group.add(trim2)
    group.add(trim3)

    this.group = group
  }

  update (x, y, z, tz, ty, overstekKlos, dz, dsz, offset, color, kp) {
    this.x = x
    this.y = y 
    this.z = z 
    this.tz = tz
    this.ty = ty
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    // let kx = this.kx = xBovVo
    this.dz = dz
    this.dsz = dsz
    this.offset = offset
    this.color = color
    this.kp = kp

    const trim0 = this.group.children[0]
    const trim1 = this.group.children[1]
    const trim2 = this.group.children[2]
    const trim3 = this.group.children[3]

    trim0.geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(z + (xBovVo + xBovAc) + (2*dz) + (2*dsz) - (2*tz) + (2*offset), ty, tz))
    trim0.geometry.attributes.position.needsUpdate = true

    trim2.geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(x + (xBovLi + xBovRe) + (2*dz) + (2*dsz) + (2*offset), ty, tz))
    trim2.geometry.attributes.position.needsUpdate = true

    // trim0.position.z = - (xBovLi) - dz - dsz + (tz / 2) - offset
    trim0.position.z = - (xBovLi) - dz - dsz + (tz / 2) - offset
    trim0.position.x = z / 2 + xBovAc / 2 - xBovVo / 2

    trim1.position.z = x + (xBovRe) + dz + dsz - (tz / 2) + offset
    trim1.position.x = z / 2 + xBovAc / 2 - xBovVo / 2

    trim2.position.z = x / 2 - xBovLi / 2 + xBovRe / 2
    trim2.position.x = z + (xBovAc) + dz + dsz - (tz / 2) + offset

    trim3.position.z = x / 2 - xBovLi / 2 + xBovRe / 2
    trim3.position.x = - (xBovVo) - dz - dsz + (tz / 2) - offset

    this.updateColor(color)
  }

  updateColor (color) {
    this.colorTrim = color ? color : this.colorTrim
    let texture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.colorTrim.url ? this.colorTrim.url : this.colorTrim}`)
    const material = new this.THREE.MeshStandardMaterial({ 
        map: texture, 
        // color: new this.THREE.Color(0xDFDFDF), 
        roughness: 0.1,  // Adjust between 0-1
        metalness: 0.2   // Adjust between 0-1})
    })
    const trim0 = this.group.children[0]
    const trim1 = this.group.children[1]
    const trim2 = this.group.children[2]
    const trim3 = this.group.children[3]

    trim0.material = material
    trim1.material = material
    trim2.material = material
    trim3.material = material

    // this.group.children[0].material.color = new this.THREE.Color(color.hexCode)
  }
}