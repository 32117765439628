import * as graphql from '@/functions/graphql.js'
import * as errorHandeling from '@/functions/errorHandeling.js'

const state = {
  inWallComponents: [],
  steellookComponents: [],
  slidingWallComponents: [],
  selectedWallComponent: {},
  selectedWallComponentSettings: {
    "color": {
        "name": "Onbehandeld",
        "hexCode": "#DAA877",
        "code": "S5100367"
    },
    "option": {
        "name": "0",
        "subCode": "a"
    }
  },
  currentComponent: {},
  selectedSteellookComponent: null,
  selectedSteellookComponentSettings: {},
  options: [],
  insulation: [],
  selectedInsulation: '',
  modelTypes: null,
  availableTypes: null,
  externalTypes: null,
}

const mutations = {

  setSelectedWallComponent (state, data) {
    state.selectedWallComponent = data.component
    state.selectedWallComponentSettings = data.settings || {}
    // console.log('setSelectedWallComponent log', data.component, data.settings)
  },

  setSelectedSteellookComponent(state, data) {
    state.selectedSteellookComponent = data.component
    state.selectedSteellookComponentSettings = data.settings
  },

  setComponents (state, data) {
    state.inWallComponents = data
    state.selectedWallComponent = data[0]
    
    const component = data[0].component
    const colorIndex = data[0].colorIndex
    const optionIndex = data[0].optionIndex
    const variantIndex = data[0].variantIndex // feature 1394
    const glasTypeIndex = data[0].glasTypeIndex // see above
    const settings = {
      color: colorIndex ? component.colors[colorIndex] : null,
      option: optionIndex ? component.options[optionIndex] : null,
      variant: variantIndex ? component.variants[variantIndex] : null, // new
      glasType: glasTypeIndex ? component.glasTypes[glasTypeIndex] : null, // new      
    }
    state.selectedWallComponentSettings = settings || {}
  },

  setGlassVariantsMain (state, data) {
    state.glassAndVariantsData = data
  },

  setSteellookComponents (state, data) {
    state.steellookComponents = data
    state.selectedWallComponent = data[0]

    const component = data[0].component
    const colorIndex = data[0].colorIndex
    const optionIndex = data[0].optionIndex
    const variantIndex = data[0].variantIndex // feature 1394
    const glasTypeIndex = data[0].glasTypeIndex // see above
    const settings = {
      color: colorIndex ? component.colors[colorIndex] : null,
      option: optionIndex ? component.options[optionIndex] : null,
      variant: variantIndex ? component.variants[variantIndex] : null, // new
      glasType: glasTypeIndex ? component.glasTypes[glasTypeIndex] : null, // new      
    }
    state.selectedWallComponentSettings = settings || {}
  },

  setSlidingWallComponents (state, data) {
    state.slidingWallComponents = data
    state.selectedWallComponent = data[0]

    const component = data[0].component
    const colorIndex = data[0].colorIndex
    const optionIndex = data[0].optionIndex
    const variantIndex = data[0].variantIndex // feature 1394
    const glasTypeIndex = data[0].glasTypeIndex // see above
    const settings = {
      color: colorIndex ? component.colors[colorIndex] : null,
      option: optionIndex ? component.options[optionIndex] : null,
      variant: variantIndex ? component.variants[variantIndex] : null, // new
      glasType: glasTypeIndex ? component.glasTypes[glasTypeIndex] : null, // new
    }
    state.selectedWallComponentSettings = settings || {}
  },

  setComponent (state, data) {
    state.currentComponent = data
  },

  setSteellookComponent(state, component) {
    state.steellookComponents = [component];
  },

  setOptions (state, components) {
    state.options = components
  },

  setInsulation (state, insulation) {
    state.insulation = insulation
    console.log(insulation)
  },

  setAvailableModels (state, data) {
    // console.log(data)
    state.modelTypes = data

    const uniqueTypes = []

    for (let i = 0; i < state.modelTypes.length; i++) {
      const foundType = uniqueTypes.find((t) => t.type.name === state.modelTypes[i].type.name)

      if (!foundType) {
        uniqueTypes.push(state.modelTypes[i])
      }
    }

    const sortedUniqueTypes = uniqueTypes.sort((a, b) => {
      return a.type.index - b.type.index
    })

    state.availableTypes = sortedUniqueTypes
  },

  setExternalTypes (state, data) {
    state.externalTypes = data
    console.log(state.externalTypes)
  },

  setSelectedInsulation (state, insulation) {
    state.selectedInsulation = insulation
  }
} 

const actions = {
  // async fetchSteellookComp(context) {
  //   const graphqlQuery = {
  //     query: `
  //       {
  //         getComponent(code: "S5100248") {
  //           code
  //           category
  //           name
  //           shortName
  //           size
  //           trueOnline
  //           options {
  //             name
  //             subCode
  //           }
  //           colors {
  //             name
  //             hexCode
  //             code
  //           }
  //           dimensions {
  //             x
  //             y
  //             yo
  //           }
  //         }
  //       }
  //     `,
  //   };

  //   const response = await graphql.fetchGraphQL(context, graphqlQuery);

  //   if (response.errors) {
  //     errorHandeling.throwError(context, response, "Failed to fetch S5100248.");
  //   }
  //   context.commit('setSteellookComponent', response.data.getComponent);
  // },

  // changeSelectedSteellookComponent(context, component) {
  //   context.commit('setSelectedSteellookComponent', component)
  // },


  changeSelectedWallComponent (context, data) {
    console.log('changeSelectedWallComponent',data)
    context.commit('setSelectedWallComponent', data)
    context.commit('setSelectedWallComponentInFrame', data.component)
    context.commit('setWallPlusIcons', data.component)
  },

  async getComponents (context, { categories, trueOnline}) {
    // console.log('Calling getComponents action inside store-models');
    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getComponents(categories: ${JSON.stringify(categories)}, trueOnline: "${trueOnline}") {
            code
            category
            glasTypes {
              name
              acronym
              code
              componentName
            }
            name
            shortName
            variantShortName
            size
            trueOnline
            options {
              name
              subCode
            }
            colors {
              name
              hexCode
              code
              componentName
            }
            variants {
              name
              code
              componentName
            }            
            dimensions {
              x
              y
              z
              xo
              yo
              zo
            }
            margins {
              xPos1
              xPos2
              xNeg1
              xNeg2
              yPos1
              yPos2
              yNeg1
              yNeg2
              zPos1
              zPos2
              zNeg1
              zNeg2
            }
            wallOptions {
              left
              center
              right
            }
            facadeOptions {
              left
              center
              right
            }
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch components.')
    }

    // console.log(`GraphQL response: ${JSON.stringify(response.data.getComponents)}`);
    // response from database is save here
    context.commit('setComponents', response.data.getComponents)

    // console.log(`response.getComponents${JSON.stringify(response.getComponents)}`)
    // console.log(`response.getComponents[0]${JSON.stringify(response.getComponents[0])}`)

    context.commit('setSelectedWallComponentInFrame', response.data.getComponents[0])
    context.commit('setWallPlusIcons', response.data.getComponents[0])
  },

  async getGlassVariantsMain (context, { categories, trueOnline}) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getGlassVariantsMain(categories: ${JSON.stringify(categories)}, trueOnline: "${trueOnline}") {
            basicDoors {
              glassVariants {
                code
                variants {
                  name
                  code
                  componentName
                }
              }
              variantColors {
                code
                colors {
                  name
                  hexCode
                  code
                  componentName
                }
              }
            }
            basicWindows {
              glassVariants {
                code
                variants {
                  name
                  code
                  componentName
                }
              }
              variantColors {
                code
                colors {
                  name
                  hexCode
                  code
                  componentName
                }
              }              
            }
            steellookDoors {
              glassVariants {
                code
                variants {
                  name
                  code
                  componentName
                }
              }
              variantColors {
                code
                colors {
                  name
                  hexCode
                  code
                  componentName
                }
              }
            }
            steellookWindows {
              glassVariants {
                code
                variants {
                  name
                  code
                  componentName
                }
              }
              variantColors {
                code
                colors {
                  name
                  hexCode
                  code
                  componentName
                }
              }
            }
            steellookWindowsBW {
              glassVariants {
                code
                variants {
                  name
                  code
                  componentName
                }
              }
              variantColors {
                code
                colors {
                  name
                  hexCode
                  code
                  componentName
                }
              }
            }
            steellook {
              glassVariants {
                code
                variants {
                  name
                  code
                  componentName
                }
              }
              variantColors {
                code
                colors {
                  name
                  hexCode
                  code
                  componentName
                }
              }
            }        
            steellookWandBW {
              glassVariants {
                code
                variants {
                  name
                  code
                  componentName
                }
              }
              variantColors {
                code
                colors {
                  name
                  hexCode
                  code
                  componentName
                }
              }
            }
            slidingwalls {
              glassVariants {
                code
                variants {
                  name
                  code
                  componentName
                }
              }
              variantColors {
                code
                colors {
                  name
                  hexCode
                  code
                  componentName
                }
              }
            }    
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch components.')
    }

    // console.log('getGlassVariantsMain', response.data.getGlassVariantsMain)    

    context.commit('setGlassVariantsMain', response.data.getGlassVariantsMain)
  },

  async getSteellookComponents (context, { categories, trueOnline}) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getComponents(categories: ${JSON.stringify(categories)}, trueOnline: "${trueOnline}") {
            code
            category
            glasTypes {
              name
              acronym
              code
              componentName
            }
            name
            shortName
            variantShortName
            size
            trueOnline
            options {
              name
              subCode
            }
            colors {
              name
              hexCode
              code
              componentName
            }
            variants {
              name
              code
              componentName
            }            
            dimensions {
              x
              y
              z
              xo
              yo
              zo
            }
            margins {
              xPos1
              xPos2
              xNeg1
              xNeg2
              yPos1
              yPos2
              yNeg1
              yNeg2
              zPos1
              zPos2
              zNeg1
              zNeg2
            }
            wallOptions {
              left
              center
              right
            }
            facadeOptions {
              left
              center
              right
            }
            keyWords
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch components.')
    }

    context.commit('setSteellookComponents', response.data.getComponents)
    context.commit('setSelectedWallComponentInFrame', response.data.getComponents[0])
    context.commit('setWallPlusIcons', response.data.getComponents[0])
  },

  async getSlidingWallComponents (context, { categories, trueOnline}) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getComponents(categories: ${JSON.stringify(categories)}, trueOnline: "${trueOnline}") {
            id
            code
            category
            glasTypes {
              name
              acronym
              code
              componentName
            }
            name
            shortName
            size
            trueOnline
            options {
              name
              subCode
            }
            colors {
              name
              hexCode
              code
              componentName
            }
            dimensions {
              x
              y
              z
              xo
              yo
              zo
            }
            margins {
              xPos1
              xPos2
              xNeg1
              xNeg2
              yPos1
              yPos2
              yNeg1
              yNeg2
              zPos1
              zPos2
              zNeg1
              zNeg2
            }
            wallOptions {
              left
              center
              right
            }
            facadeOptions {
              left
              center
              right
            },
            keyWords
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch components.')
    }

    context.commit('setSlidingWallComponents', response.data.getComponents)
    context.commit('setSelectedWallComponentInFrame', response.data.getComponents[0])
    context.commit('setWallPlusIcons', response.data.getComponents[0])
  },

  async getComponent (context, id) {
    context.commit('setComponent', {})

    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getComponent(id: "${id}") {
            id
            code
            combinedCode
            category
            name
            shortName
            size
            trueOnline
            keyWords
            options {
              name
              subCode
            }
            colors {
              name
              hexCode
              code
            }
            dimensions {
              x
              y
              z
              xo
              yo
              zo
            }
            margins {
              xPos1
              xPos2
              xNeg1
              xNeg2
              yPos1
              yPos2
              yNeg1
              yNeg2
              zPos1
              zPos2
              zNeg1
              zNeg2
            }
            wallOptions {
              left
              center
              right
            }
            facadeOptions {
              left
              center
              right
            }
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch component.')
    }

    context.commit('setComponent', response.data.getComponent)
  },

  async updateComponent (context, c) {

    // format options
    let options = c.options.map((x) => {
      return `{
        name: "${x.name}", 
        subCode: "${x.subCode}"
      }`
    })

    // format colors
    let colors = c.colors.map((x) => {
      return `{
        name: "${x.name}", 
        hexCode: "${x.hexCode}", 
        code: "${x.code}"
      }`
    })

    // format variants
    let variants = c.variants.map((x) => {
      return `{
        name: "${x.name}", 
        code: "${x.code}"
      }`
    })

    // format glass types
    let glasTypes = c.glasTypes.map((x) => {
      return `{
        name: "${x.name}", 
        acronym: "${x.acronym}"
        code: "${x.code}"
      }`
    })

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          updateComponent(componentData: {
            id: "${c.id}",
            code: "${c.code}",
            combinedCode: "${c.combinedCode}",
            category: "${c.category}",
            glasTypes: [${glasTypes}],
            name: "${c.name}",
            shortName: "${c.shortName}",
            variantShortName: "${c.variantShortName}",
            size: "${c.size ? c.size : ''}",
            trueOnline: ${c.trueOnline},
            options: [${options}],
            colors: [${colors}],
            variants: [${variants}],
            dimensions: {
              x: ${c.dimensions.x},
              y: ${c.dimensions.y},
              z: ${c.dimensions.z},
              xo: ${c.dimensions.xo},
              yo: ${c.dimensions.yo},
              zo: ${c.dimensions.zo},
            },
            margins: {
              xPos1: ${c.margins.xPos1},
              xPos2: ${c.margins.xPos2},
              xNeg1: ${c.margins.xNeg1},
              xNeg2: ${c.margins.xNeg2},
              yPos1: ${c.margins.yPos1},
              yPos2: ${c.margins.yPos2},
              yNeg1: ${c.margins.yNeg1},
              yNeg2: ${c.margins.yNeg2},
              zPos1: ${c.margins.zPos1},
              zPos2: ${c.margins.zPos2},
              zNeg1: ${c.margins.zNeg1},
              zNeg2: ${c.margins.zNeg2},
            }
            keyWords: ${JSON.stringify(c.keyWords)},
            wallOptions: {
              left: ${c.wallOptions.left},
              center: ${c.wallOptions.center},
              right: ${c.wallOptions.right},
            }
            facadeOptions: {
              left: ${c.facadeOptions.left},
              center: ${c.facadeOptions.center},
              right: ${c.facadeOptions.right},
            }
          }) {
            id
            code
            category
            glasTypes {
              name
              acronym
              code
            }
            name
            shortName
            variantShortName
            size
            trueOnline
            options {
              name
              subCode
              code
            }
            colors {
              name
              hexCode
            }
            variants {
              name
              code
            }            
            dimensions {
              x
              y
              z
              xo
              yo
              zo
            }
            margins {
              xPos1
              xPos2
              xNeg1
              xNeg2
              yPos1
              yPos2
              yNeg1
              yNeg2
              zPos1
              zPos2
              zNeg1
              zNeg2
            }
            keyWords
            wallOptions {
              left
              center
              right
            }
            facadeOptions {
              left
              center
              right
            }
          }
        }
      `
    }
    console.log("test",graphqlQuery)
    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to update component.')
    }

    context.commit('updateCMSComponents', c)
    // context.commit('setComponents', [])
    // await context.dispatch('updateDWTables', 'Component successfully updated.')

    /* -------------------------------------------------------------------------- */
    /*     als we de DriveWorkstables willen updaten, dit stukje code weer aanzetten     */
    /* -------------------------------------------------------------------------- */
    // await context.dispatch('updateDWTables', {
    //   succesText: 'Components succesvol geupdate.',
    //   tableData: response.data.updateModel,
    //   menuName: "updateCMSComponents"
    // })
    /* -------------------------------------------------------------------------- */
    /*     als we de DriveWorkstables willen updaten, dit stukje code weer aanzetten     */
    /* -------------------------------------------------------------------------- */
  },

  async createComponent (context, c) {

    // format options
    let options = c.options.map((x) => {
      return `{
        name: "${x.name}", 
        subCode: "${x.subCode}"
      }`
    })

    // format colors
    let colors = c.colors.map((x) => {
      return `{
        name: "${x.name}", 
        hexCode: "${x.hexCode}", 
        code: "${x.code}"
      }`
    })

    let variants = c.variants.map((x) => {
      return `{
        name: "${x.name}", 
        code: "${x.code}"
      }`
    })

    // format glass types
    let glasTypes = c.glasTypes.map((x) => {
      return `{
        name: "${x.name}", 
        acronym: "${x.acronym}"
        code: "${x.code}"        
      }`
    })

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          createComponent(componentData: {
            id: "${c.id}",
            code: "${c.code}",
            combinedCode: "${c.combinedCode}",
            category: "${c.category}",
            glasTypes: [${glasTypes}],
            name: "${c.name}",
            shortName: "${c.shortName}",
            variantShortName: "${c.variantShortName}",
            size: "${c.size ? c.size : ''}",
            trueOnline: ${c.trueOnline},
            options: [${options}],
            colors: [${colors}],
            variants: [${variants}],
            dimensions: {
              x: ${c.dimensions.x},
              y: ${c.dimensions.y},
              z: ${c.dimensions.z},
              xo: ${c.dimensions.xo},
              yo: ${c.dimensions.yo},
              zo: ${c.dimensions.zo},
            },
            margins: {
              xPos1: ${c.margins.xPos1},
              xPos2: ${c.margins.xPos2},
              xNeg1: ${c.margins.xNeg1},
              xNeg2: ${c.margins.xNeg2},
              yPos1: ${c.margins.yPos1},
              yPos2: ${c.margins.yPos2},
              yNeg1: ${c.margins.yNeg1},
              yNeg2: ${c.margins.yNeg2},
              zPos1: ${c.margins.zPos1},
              zPos2: ${c.margins.zPos2},
              zNeg1: ${c.margins.zNeg1},
              zNeg2: ${c.margins.zNeg2},
            }
            keyWords: ${JSON.stringify(c.keyWords)},
            wallOptions: {
              left: ${c.wallOptions.left},
              center: ${c.wallOptions.center},
              right: ${c.wallOptions.right},
            }
            facadeOptions: {
              left: ${c.facadeOptions.left},
              center: ${c.facadeOptions.center},
              right: ${c.facadeOptions.right},
            }
          }) {
            id
            code
            combinedCode
            category
            glasTypes {
              name
              acronym
              code
            }
            name
            shortName
            variantShortName
            size
            trueOnline
            options {
              name
              subCode
              code
            }
            colors {
              name
              hexCode
            }
            variants {
              name
              code
            }            
            dimensions {
              x
              y
              z
              xo
              yo
              zo
            }
            keyWords
            wallOptions {
              left
              center
              right
            }
            facadeOptions {
              left
              center
              right
            }
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to create component.')
    }

    context.commit('addCMSComponent', response.data.createComponent)
    // context.commit('setComponents', [])
    context.dispatch('showSucces', 'Component successfully created.')
  },

  async deleteComponent (context, id) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          deleteComponent(id: "${id}")
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to delete component.')
    }

    context.commit('removeCMSComponent', id)
    context.dispatch('showSucces', 'Component successfully deleted.')
  },

  async getOptions (context) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getOptions {
            code
            combinedCode
            category
            
            name
            shortName
            size
            trueOnline
            colors {
              name
              hexCode
              code
              componentName
            }
            dimensions {
              x
              y
              z
              xo
              yo
              zo
            }
            margins {
              xPos1
              xPos2
              xNeg1
              xNeg2
              yPos1
              yPos2
              yNeg1
              yNeg2
              zPos1
              zPos2
              zNeg1
              zNeg2
            }
            keyWords
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch components.')
    }

    context.commit('setOptions', response.data.getOptions)
  },

  async getInsulation (context) {
    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getInsulation {
            id
            code
            shortName
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch insulation.')
    }

    context.commit('setInsulation', response.data.getInsulation)
  },

  async getAvailableModels (context) {
    if (!context.state.modelTypes) {
      // config graphQL data
      const textureGraphqlObject = `{
        id
        name
        url
        textureType
        plankType
        color
        locations
        scalingX
        scalingY
        emissive
        emissiveColor
        metalness
        order
      }`
      const graphqlQuery = {
        query: `
          {
            getTypes {
              id
              name
              urls {
                dev
                tes
                pro
              }
              index
            }

            getBergingTypes {
              id
              name
              order
            }

            getMenuInfoboxSettings {
              id
              name
              title
              header
              urlEnding
            }

            getMenuUISettings {
              id
              showProductDetails
              showPrice
              extraDetails {
                id
                text
              }
              extraQuestions {
                id
                text
                options {
                  id
                  text
                }
              }
            }

            getAvailableModels {
              id
              name
              type {
                id
                name
                urls {
                  dev
                  tes
                  pro
                }
                index
              }
              infobox {
                id
              }
              infoboxIsOnline
              category {
                id
                name
              }
              online
              maatwerk {
                enabled
                width {
                  min
                  max
                }
                depth {
                  min
                  max
                }
              }
              standaardmatenDisabled
              wall {
                minWallWidth
                needsSpaceForStaander
              }
              roofFinishes
              wallFinishes {
                wandBuiten
                wandBinnen
                tussenWandLinks
                tussenWandRechts
                topgevelBuiten
                topgevelBinnen
                topgevelLinks
                topgevelRechts
              }
              overstekKlosRound
              availableOptions {
                fundering
                dakgoot
                lichtstraten
                ledverlichting
              }
              defaultSizes {
                diepte
                hoogtePaal
                maximaleMuurBreedte
                staanderDikte
                poer {
                  z
                  y
                }
                schoor {
                  enabled
                  sz
                  sy
                  sx
                  sox
                  wallMinWidth
                }
                ringBalk {
                  y
                  z
                }
                extraRingBalk {
                  enabled
                  gordingVorm
                  y
                  z
                  position
                }
                ringbalkTussenPaal {
                  enabled
                  y
                  z
                }
                gordingBalk {
                  y
                  z
                  afstandTussenGordingen
                  position
                  ox
                  oy
                  oyl
                }
                ringBalkGording {
                  y
                  z
                  position
                }
                overstekKlos {
                  enabled
                  z
                  zp
                  xb
                  xo
                  y
                  yk
                  position{
                    left
                    right
                  }
                  zDik
                  zDikPaal
                  klosVoEnabled
                  klosVoEditable
                  xBovVo
                  xOndVo
                  yHooVo
                  yKopVo
                  klosAcEnabled
                  klosAcEditable
                  xBovAc
                  xOndAc
                  yHooAc
                  yKopAc
                  klosLiEnabled
                  klosLiEditable
                  xBovLi
                  xOndLi
                  yHooLi
                  yKopLi
                  klosReEnabled
                  klosReEditable
                  xBovRe
                  xOndRe
                  yHooRe
                  yKopRe
                }
                boeideel {
                  enabled
                  y
                  z
                  position
                  lijstEnabled
                  ly
                  lz
                  lijstPosition
                  dpy
                  dpz
                  dpOffsetKopkant
                  dpOffsetGootzijde
                  nokpositie
                  byl
                  douglasPlanken,
                  horizontaleOffset
                }
                trim {
                  enabled
                  y
                  z
                  overhang
                  color
                  position
                }
                dak {
                  offset
                  helling
                  overhang
                  beschotDikte
                  dakpanOverhang
                  dakpanOverhangAchter
                  gordingSchoorHoek
                  gordingSchoorOffset
                }
                dakgoot {
                  enabled
                  y
                  offset
                  twoPipesWidth
                  threePipesWidth
                }
                wall {
                  position
                  wz
                  height {
                    position
                    value
                  }
                }
                omlijstingPalen
                veranda {
                  enabled
                  hoogte
                  dikte
                  extraBreedte
                  material {
                    name
                    width
                    height
                    color
                    diffusemap ${textureGraphqlObject}
                    normalmap ${textureGraphqlObject}
                    aomap ${textureGraphqlObject}
                    metalnessmap ${textureGraphqlObject}
                    roughnessmap ${textureGraphqlObject}
                  }
                }
                breedtes {
                  breedte
                  bergingType
                  artikelNummer
                  wallDivision {
                    width
                    bergingEnabled
                  }
                  enabled
                }
              }
            }
          }
        `
      }

      // sending request to server
      const response = await graphql.fetchGraphQL(context, graphqlQuery)

      // if error
      if (response.errors) {
        errorHandeling.throwError(context, response, 'Failed to fetch model info.')
      }

      const externalTypes = []
      // console.log(response.data.getTypes)
      for(let i=0; i<response.data.getTypes.length; i++) {
        const t = response.data.getTypes[i].urls
        // console.log(t)
        if(t.dev || t.tes || t.pro) {
          externalTypes.push(response.data.getTypes[i])
        }
      }
      context.commit('setExternalTypes', externalTypes)

      const infoboxes = response.data.getMenuInfoboxSettings;

      const availableModels = response.data.getAvailableModels;
      const mappedModels = []
      for (let i = 0; i < availableModels.length; i++) {
        const element = availableModels[i];
        const match = infoboxes.find(box => box.id === element.infobox.id)
        if (match && element.infoboxIsOnline) element.infobox = match;
        mappedModels.push(element)
      }

      // context.commit('setAvailableModels', response.data.getAvailableModels)
      context.commit('setAvailableModels', mappedModels)
      const startModel = state.modelTypes.find((t) => t.name === context.rootState.config.config.model)
      console.log(state.modelTypes, context.rootState.config.config.model, startModel)
      context.commit('setAfmetingen', startModel)
      context.commit('setBergingTypes', response.data.getBergingTypes)
      // context.commit('setType', startModel)
    }
  },
}

const getters = {
  getInWallComponents (state) {
    return state.inWallComponents
  },

  getGlassAndVariantsData () {
    return state.glassAndVariantsData
  },

  getSteellookComponents (state) {
    return state.steellookComponents
  },

  getSlidingWallComponents (state) {
    return state.slidingWallComponents
  },

  getSelectedSteellookComponent (state) {
    return state.selectedSteellookComponent
  },
 
  getSelectedSteellookComponentSettings (state) {
    return state.selectedSteellookComponentSettings
  },

  getSelectedWallComponent (state) {
    return state.selectedWallComponent
  },
 
  getSelectedWallComponentSettings (state) {
    return state.selectedWallComponentSettings
  },

  getCurrentComponent (state) {
    return state.currentComponent
  },

  getOptions (state) {
    return state.options
  },

  getModelTypes (state) {
    return state.modelTypes
  },

  getAvailableTypes (state) {
    // console.log(state.availableTypes)
    return state.availableTypes
  },

  getExternalTypes (state) {
    // console.log(state.externalTypes)
    return state.externalTypes
  },

  getInsulation (state) {
    return state.insulation
  },

  getSelectedInsulation (state) {
    return state.selectedInsulation
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}