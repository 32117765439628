function positionBuffer (x, z, w) {
  // a: L height
  // b: outer width
  // c: inner width
  // d: L depth

  return [
    // top
    //breedte   0   diepte 
    0, 0, 0, 
    w, 0, w, 
    z, 0, 0,  
    
    z, 0, 0,
    w, 0, w,
    z-w, 0, w,

    z, 0, 0,
    z-w, 0, w,
    z,0,x,

    z,0,x,
    z-w, 0, w,
    z-w, 0, x-w,

    z,0,x,
    z-w, 0, x-w,
    0,0,x,

    0,0,x,
    z-w, 0, x-w,
    w, 0, x-w,

    0,0,x,
    w, 0, x-w,
    0,0,0,
    
    0,0,0,
    w, 0, x-w,
    w,0,w,

    w,0,w,
    z-w, 0, x-w,
    z-w, 0, w,

    w,0,w,
    w, 0, x-w,
    z-w, 0, x-w,
  ]
}

// defines position of the textures
function uvBuffer () { 
  return [
    // top
    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,

    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,

    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,

    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,

    1, 0,
    0, 1,
    1, 1,

    1, 0,
    0, 0,
    0, 1,
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  return [
    // top
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
  ]
}

function groupBuffer () {
  return [
    {
      start: 0,
      count: 24,
      materialIndex: 0
    },
    {
      start: 24,
      count: 6,
      materialIndex: 1
    }
  ]
}

export { positionBuffer, uvBuffer, normalBuffer, groupBuffer }