// defines position of the vertices

// breedte-1, this.modelType.isPlatdak ? gy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, gKLength, gKLengthO, ky, kyo
//gording lengte (3000), gording hoogte (145), gording diepte (65), klos lengte boven (), klos lengte onder (), klos hoogte boven (), klos hoogte onder()

function positionBufferGording (z, gy, gz, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc) {
  // const a = gy // gording hoogte
  // const b = z + (2 * kx) // goring lengte + (2 * klost lengte boven)
  // const c = gy - kyo // gording hoogte - klost hoogte onder
  // const d = z + (2 * kx) - (kx - kxo) // gording lengte + (2 * klost lengte boven) - (klos lengte boven - klos lengte onder)
  // const e = kx - kxo // klost lengte boven - klos lengte onder
  // const f = gz // gording diepte
  // const g = gy-ky // gording hoogte - klos hoogte

  const a = gy
  const b = z + xBovVo + xBovAc
  const c = gy - yKopVo
  const d = b - (xBovAc - xOndAc)
  const e = xBovVo - xOndVo
  const f = gz
  const g = gy-yHooVo
  const h = gy - yKopAc
  const i = gy-yHooAc
  

  return [ 
          //voorkant
          0, c, 0, 0, a, 0, b, a, 0, // 1
          d, i, 0, e, g, 0, 0, c, 0, // 2
          b, a, 0, b, h, 0, d, i, 0, // 3
          d, i, 0, 0, c, 0, b, a, 0, // 4
          e, g, 0, d, i, 0, d, 0, 0, // 5
          e, 0, 0, e, g, 0, d, 0, 0, // 6

          //achterkant
          b, a, f, 0, a, f, 0, c, f, 
          0, c, f, e, g, f, d, i, f, 
          d, i, f, b, h, f, b, a, f, 
          b, a, f, 0, c, f, d, i, f, 
          d, 0, f, d, i, f, e, g, f,
          d, 0, f, e, g, f, e, 0, f, 
          
          //rechterkant shuin
          0, c, 0, e, g, 0, 0, c, f, 
          e, g, 0, e, g, f, 0, c, f, 

          //onderkant
          e, 0, 0, d, 0, 0, e, 0, f, 
          d, 0, 0, d, 0, f, e, 0, f, 

          //linkerkant schuin
          d, i, 0, b, h, 0, d, i, f, 
          b, h, 0, b, h, f, d, i, f,
          
          //linkerkant
          b, h, 0, b, a, 0, b, h, f, 
          b, a, 0, b, a, f, b, h, f,
          
          //bovenkant
          b, a, 0, 0, a, 0, b, a, f, 
          0, a, 0, 0, a, f, b, a, f, 
          
          //rechterkant
          0, a, 0, 0, c, 0, 0, a, f, 
          0, c, 0, 0, c, f, 0, a, f,

          //rechterkant onder
          e, g, 0, e, 0, 0, e, g, f, 
          e, 0, 0, e, 0, f, e, g, f,

          //linkerkant onder
          d, 0, 0, d, i, 0, d, 0, f, 
          d, i, 0, d, i, f, d, 0, f,
        ]
        //[ 0, c, 0, 0, a, 0, b, a, 0, d, 0, 0, e, 0, 0, 0, c, 0, b, a, 0, b, c, 0, d, 0, 0, d, 0, 0, 0, c, 0, b, a, 0, b, a, f, 0, a, f, 0, c, f, 0, c, f, e, 0, f, d, 0, f, d, 0, f, b, c, f, b, a, f, b, a, f, 0, c, f, d, 0, f, 0, c, 0, e, 0, 0, 0, c, f, e, 0, 0, e, 0, f, 0, c, f, e, 0, 0, d, 0, 0, e, 0, f, d, 0, 0, d, 0, f, e, 0, f, d, 0, 0, b, c, 0, d, 0, f, b, c, 0, b, c, f, d, 0, f, b, c, 0, b, a, 0, b, c, f, b, a, 0, b, a, f, b, c, f, b, a, 0, 0, a, 0, b, a, f, 0, a, 0, 0, a, f, b, a, f, 0, a, 0, 0, c, 0, 0, a, f, 0, c, 0, 0, c, f, 0, a, f ]
}

function positionBufferKlos (gy, kx, kxo, kyo, gz) {
  const a = gy
  const b = kx
  const c = kx - kxo
  const d = gy - kyo
  const e = gz

  return [ 0, d, 0, 0, a, 0, b, a, 0, b, 0, 0, c, 0, 0, 0, d, 0, 0, d, 0, b, a, 0, b, 0, 0, b, a, e, 0, a, e, 0, d, e, 0, d, e, c, 0, e, b, 0, e, b, 0, e, b, a, e, 0, d, e, 0, d, 0, c, 0, 0, 0, d, e, c, 0, 0, c, 0, e, 0, d, e, c, 0, 0, b, 0, 0, c, 0, e, b, 0, 0, b, 0, e, c, 0, e, b, 0, 0, b, a, 0, b, 0, e, b, a, 0, b, a, e, b, 0, e, b, a, 0, 0, a, 0, b, a, e, 0, a, 0, 0, a, e, b, a, e, 0, a, 0, 0, d, 0, 0, a, e, 0, d, 0, 0, d, e, 0, a, e ]
}

// defines position of the textures
function uvBufferGording (z, gy, gz, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc) { 
  // const a = gy
  // const b = z + (2 * kx)
  // const c = gy - kyo
  // const d = z + (2 * kx) - (kx - kxo)
  // const e = kx - kxo
  // const f = gz
  // const g = gy-ky

  const a = gy
  const b = z + xBovVo + xBovAc
  const c = gy - yKopVo
  const d = b - (xBovAc - xOndAc)
  const e = xBovVo - xOndVo
  const f = gz
  const g = gy-yHooVo
  const h = gy - yKopAc
  const i = gy-yHooAc
  

  return [ 
    //voorkant
    0, c, 0, a, b, a, 
    d, i, e, g, 0, c,
    b, a, b, h, d, i,
    d, i, 0, c, b, a,
    e, g, d, i, d, 0,
    e, 0, e, g, d, 0,


    //achterkant
    b, a, 0, a, 0, c, 
    0, c, e, 0, d, 0, 
    d, 0, b, h, b, a, 
    b, a, 0, c, d, 0,
    d, 0, d, i, e, g,
    d, 0, e, g, e, 0,

    //rechterkant schuin
    0, 1, e, 1, 0, 1 - f, 
    e, 1, e, 1 - f, 0, 1 - f,

    //onderkant
    e, 1, d, 1, e, 1 - f, 
    d, 1, d, 1 - f, e, 1 - f,
    
    //linkerkant schuin
    d, 1, b, 1, d, 1 - f, 
    b, 1, b, 1 - f, d, 1 - f,

    //linkerkant
    c, 1, a, 1, c, 1 - f, 
    a, 1, a, 1 - f, c, 1 - f, 
    
    //bovenkant
    b, 1, 0, 1, b, 1 - f, 
    0, 1, 0, 1 - f, b, 1 - f, 
    
    //rechterkant
    a, 1, c, 1, a, 1 - f, 
    c, 1, c, 1 - f, a, 1 - f,

    //rechterkant onder
    a, 1, c, 1, a, 1 - f, 
    c, 1, c, 1 - f, a, 1 - f,

    //linkerkant onder
    c, 1, a, 1, c, 1 - f, 
    a, 1, a, 1 - f, c, 1 - f,

    //0, 0, c, b, a, b, c, d, 0, d, 0, 0, c, b, a, b, a, 0, a, 0, c, 0, c, e, 0, d, 0, d, 0, b, c, b, a, b, a, 0, c, d, 0, 0, 1, e, 1, 0, 1 - f, e, 1, e, 1 - f, 0, 1 - f, e, 1, d, 1, e, 1 - f, d, 1, d, 1 - f, e, 1 - f, d, 1, b, 1, d, 1 - f, b, 1, b, 1 - f, d, 1 - f, c, 1, a, 1, c, 1 - f, a, 1, a, 1 - f, c, 1 - f, b, 1, 0, 1, b, 1 - f, 0, 1, 0, 1 - f, b, 1 - f, a, 1, c, 1, a, 1 - f, c, 1, c, 1 - f, a, 1 - f ]
  ]
    //return [ 0, c, 0, a, b, a, d, 0, e, 0, 0, c, b, a, b, c, d, 0, d, 0, 0, c, b, a, b, a, 0, a, 0, c, 0, c, e, 0, d, 0, d, 0, b, c, b, a, b, a, 0, c, d, 0, 0, 1, e, 1, 0, 1 - f, e, 1, e, 1 - f, 0, 1 - f, e, 1, d, 1, e, 1 - f, d, 1, d, 1 - f, e, 1 - f, d, 1, b, 1, d, 1 - f, b, 1, b, 1 - f, d, 1 - f, c, 1, a, 1, c, 1 - f, a, 1, a, 1 - f, c, 1 - f, b, 1, 0, 1, b, 1 - f, 0, 1, 0, 1 - f, b, 1 - f, a, 1, c, 1, a, 1 - f, c, 1, c, 1 - f, a, 1 - f ]
}

function uvBufferKlos (gy, kx, kxo, kyo, gz) {
  const a = gy
  const b = kx
  const c = kx - kxo
  const d = gy - kyo
  const e = gz

  return [ 0, d, 0, a, b, a, b, 0, c, 0, 0, d, 0, d, b, a, b, 0, b, a, 0, a, 0, d, 0, d, c, 0, b, 0, b, 0, b, a, 0, d, 0, 1, c, 1, 0, 1 - e, c, 1, c, 1 - e, 0, 1 - e, c, 1, b, 1, c, 1 - e, b, 1, b, 1 - e, c, 1 - e, 0, 1, a, 1, 0, 1 - e, a, 1, a, 1 - e, 0, 1 - e, b, 1, 0, 1, b, 1 - e, 0, 1, 0, 1 - e, b, 1 - e, a, 1, d, 1, a, 1 - e, d, 1, d, 1 - e, a, 1 - e ]
}

// defines orientation of the faces (texture inside or outside)
function normalBufferGording () {
  return [ 
    0, 0, -1, 0, 0, -1, 0, 0, -1,
    0, 0, -1, 0, 0, -1, 0, 0, -1,
    0, 0, -1, 0, 0, -1, 0, 0, -1,
    0, 0, -1, 0, 0, -1, 0, 0, -1,
    0, 0, -1, 0, 0, -1, 0, 0, -1,
    0, 0, -1, 0, 0, -1, 0, 0, -1,

    0, 0, 1, 0, 0, 1, 0, 0, 1,
    0, 0, 1, 0, 0, 1, 0, 0, 1,
    0, 0, 1, 0, 0, 1, 0, 0, 1,
    0, 0, 1, 0, 0, 1, 0, 0, 1,
    0, 0, 1, 0, 0, 1, 0, 0, 1,
    0, 0, 1, 0, 0, 1, 0, 0, 1,

    -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0,
    -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0,

    0, -1, 0, 0, -1, 0, 0, -1, 0, 
    0, -1, 0, 0, -1, 0, 0, -1, 0,

    0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0,
    0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0,

    1, 0, 0, 1, 0, 0, 1, 0, 0, 
    1, 0, 0, 1, 0, 0, 1, 0, 0, 
    
    0, 1, 0, 0, 1, 0, 0, 1, 0, 
    0, 1, 0, 0, 1, 0, 0, 1, 0, 
    
    -1, 0, 0, -1, 0, 0, -1, 0, 0, 
    -1, 0, 0, -1, 0, 0, -1, 0, 0,

    -1, 0, 0, -1, 0, 0, -1, 0, 0, 
    -1, 0, 0, -1, 0, 0, -1, 0, 0,

    1, 0, 0, 1, 0, 0, 1, 0, 0, 
    1, 0, 0, 1, 0, 0, 1, 0, 0,
    //0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0]
  ]
    //return [ 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 0.2982749938964844, -0.954479992389679, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0]
}

function normalBufferKlos () {
  return [ 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, -0.2982749938964844, -0.954479992389679, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0 ]
}

export { positionBufferGording, positionBufferKlos, uvBufferGording, uvBufferKlos, normalBufferGording, normalBufferKlos }