class History {

  constructor (config, maxItems) {
    this.maxItems = maxItems
    this.default = config
    this.current = config
    this.backList = []
    this.forwardList = []
    //this.init(config)
  }

  init (config) {

    const { current, back, forward } = this.get()

    this.current = current ? JSON.parse(current) : config
    this.backList = back ? JSON.parse(back) : []
    this.forwardList = forward ? JSON.parse(forward) : []

  }

  push (config) {
    
    this.backList.push(this.clone(this.current))

    if (this.backList.length > this.maxItems) {
      this.backList.shift()
    }

    this.current = this.clone(config)
    this.forwardList = []
    this.set()

  }

  back () {

    this.forwardList.push(this.clone(this.current))
    this.current = this.backList.pop()
    this.set()

    return this.clone(this.current)
  }

  forward () {

    this.backList.push(this.clone(this.current))
    this.current = this.forwardList.pop()
    this.set()

    return this.clone(this.current)
  }

  reset (newConfig) {

    if (newConfig) {
      this.current = this.clone(newConfig)
    } else {
      this.current = this.clone(this.default)
    }

    this.backList = []
    this.forwardList = []
    this.set()

  }

  getCurrent () {
    return this.clone(this.current)
  }

  set () {
    localStorage.setItem('currentConfig', JSON.stringify(this.current))
    localStorage.setItem('previousConfigs', JSON.stringify(this.backList))
    localStorage.setItem('undoneConfigs', JSON.stringify(this.forwardList))
  }

  get () {
    return {
      current: localStorage.getItem('currentConfig'),
      back: localStorage.getItem('previousConfigs'),
      forward: localStorage.getItem('undoneConfigs'),
    }
  }

  clone (config) {
    return JSON.parse(JSON.stringify(config))
  }
}

export { History }