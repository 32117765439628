import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'

export default class SelectElement {
  constructor (THREE, x, y, z, rotation) {
    this.THREE = THREE
    this.group = null
    this.bouw(THREE, x, y, z, rotation)
  }

  bouw(THREE, x, y, z, rotation) {

    // geometry
    // console.log(rotation)
    // console.log(rotation % Math.PI === 0)
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(rotation % Math.PI === 0 ? x : z, y, rotation % Math.PI === 0 ? z : x)), 3))
    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry.setIndex(boxBuffer.indexBuffer());
    geometry.groups = boxBuffer.groupBuffer()

    // texture
    const white = new this.THREE.MeshBasicMaterial({ 
      color: 0xffffff,
      side: THREE.DoubleSide,
      //transparent: true,
      opacity: 1,
      //depthWrite: false,
    });
    /*const transparent = new this.THREE.MeshBasicMaterial({ 
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.0,
      depthWrite: false,
    });*/
    // eslint-disable-next-line no-unused-vars
    const gradient = new THREE.ShaderMaterial({
      uniforms: {
        color1: {
          value: new THREE.Color("#ffffff")
        },
        color2: {
          value: new THREE.Color("#ffffff")
        }
      },
      vertexShader: `
        varying vec2 vUv;
    
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color1;
        uniform vec3 color2;
      
        varying vec2 vUv;
        
        void main() {
          // y < 0 = transparent, > 1 = opaque
          float alpha = smoothstep(0.1, 0.0, vUv.y);

          // y < 1 = color1, > 2 = color2
          float colorMix = smoothstep(1.0, 2.0, vUv.y);
          
          gl_FragColor = vec4(mix(color1, color2, colorMix), alpha);
        }
      `,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
    });

    let materialArray1;
    if(rotation % Math.PI == 0) {
      materialArray1 = [
        white,
        white,
        white,
        white,
        gradient,
        gradient,
      ]
    } else {
      materialArray1 = [
        gradient,
        gradient,
        white,
        white,
        white,
        white,
      ]
    }

    let materialArray2;
    if(rotation % Math.PI == 0) {
      materialArray2 = [
        white,
        white,
        gradient,
        gradient,
        white,
        white,
      ]
    } else {
      materialArray2 = [
        gradient,
        gradient,
        white,
        white,
        white,
        white,
      ]
    }

    // mesh
    const hoverLine0 = new THREE.Mesh(geometry, materialArray1)

    const hoverLine2 = hoverLine0.clone()
    hoverLine2.rotation.z = Math.PI
    //hoverLine2.position.y = set.my - 200


    const geometry1 = new THREE.BufferGeometry()
    if(rotation % Math.PI/2 == 0) {
      geometry1.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(rotation % Math.PI === 0 ? y : x, z, rotation % Math.PI === 0 ? x : y)), 3))
    } else {
      geometry1.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(z, x, y)), 3))
    }
    geometry1.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry1.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry1.setIndex(boxBuffer.indexBuffer());
    geometry1.groups = boxBuffer.groupBuffer()

    const hoverLine1 = new THREE.Mesh(geometry1, materialArray2)

    if(rotation % Math.PI/2 == 0) {
      hoverLine1.rotation.x = 0.5 * Math.PI
    } else {
      hoverLine1.rotation.x = 0.5 * Math.PI
    }
    //hoverLine1.position.y = y / 2 - 100
    //hoverLine1.position.x = x / 2 - 100
    
    const hoverLine3 = hoverLine1.clone()
    if(rotation % Math.PI/2 == 0) {
      hoverLine3.rotation.y = 1.5 * Math.PI
    } else {
      hoverLine3.rotation.x = 1.5 * Math.PI
    }
    //hoverLine3.position.x = - x / 2 + 100

    if(rotation % Math.PI/2 == 0) {
      hoverLine1.rotation.y = 0.5 * Math.PI
    }

    /*const cubeGeometry = new THREE.BoxGeometry( x, y, z );
    const cubeMaterial = new THREE.MeshBasicMaterial( {color: 0x00ff00, wireframe: true} );
    const cube = new THREE.Mesh( cubeGeometry, cubeMaterial );
    hoverLine0.visible = false
    hoverLine1.visible = false
    hoverLine2.visible = false
    hoverLine3.visible = false*/

    const group = new this.THREE.Group()
    group.add(hoverLine0)
    group.add(hoverLine1)
    group.add(hoverLine2)
    group.add(hoverLine3)
    //group.add(cube)

    this.group = group
  }
}