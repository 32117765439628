function positionBuffer (a, b, c, d) {
  // a: L height
  // b: outer width
  // c: inner width
  // d: L depth

  return [
    // top
    c, a, d,
    b, a, 0,
    0, a, 0,

    0, a, 0,
    d, a, d,
    c, a, d,

    // front
    c, 0, d,
    c, a, d,
    d, a, d,

    d, a, d,
    d, 0, d,
    c, 0, d,
  ]
}

// defines position of the textures
function uvBuffer (b, c, d) { 
  return [
    // top
    (c / b), 0,
    1, 1,
    0, 1,

    0, 1,
    (d / b), 0,
    (c/ b), 0,

    // front
    1, 0,
    1, 1,
    0, 1,

    0, 1,
    0, 0,
    1, 0
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  return [
    // top
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    // front
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
  ]
}

// group the vertices belonging to each of the 6 sides, allows for 6 different textures one for each side.
function groupBuffer () {
  return [
    {
      start: 0,
      count: 6,
      materialIndex: 0
    },
    {
      start: 6,
      count: 6,
      materialIndex: 1
    },
  ]
}

export { positionBuffer, uvBuffer, normalBuffer, groupBuffer }