import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

export default class Vloer {
  constructor (THREE, textureLoader, x, z, spacing) {
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.x = x
    this.z = z
    this.spacing = spacing
    this.group = null
    this.font = null
    this.bouw(THREE, textureLoader, x, z, spacing)
  }

  bouw (THREE, textureLoader, x, z, spacing) {
    this.group = new THREE.Group()

    const Geometry = new THREE.BoxGeometry(z + (spacing * 2), x + (spacing * 2), 50) // 100 100

    const textureColor = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}vloer/vloer.jpg`)
    const textureColorSideX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}vloer/vloer.jpg`)
    const textureColorSideZ = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}vloer/vloer.jpg`)
    //const textureAmientOcclusion = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}vloer/ao.jpg`)
    // const textureHeight = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}vloer/height.jpg`)
    //const textureNormal = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}vloer/normal.jpg`)
    //const textureRoughness = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}vloer/roughness.jpg`)

    const tx = (z + (spacing * 2)) * 0.0005
    const tz = (x + (spacing * 2)) * 0.0005
    const tr = Math.PI * 0.5

    const sideZTexture = textureColorSideZ
    this.textureSettings(sideZTexture, tz, 0.05, tr)

    const sideXTexture = textureColorSideX
    this.textureSettings(sideXTexture, tx, 0.05, Math.PI*2)

    this.textureSettings(textureColor, tz, tx, tr)
    //this.textureSettings(textureAmientOcclusion, tz, tx, tr)
    // this.textureSettings(textureHeight, tz, tx, tr)
    //this.textureSettings(textureNormal, tz, tx, tr)
    //this.textureSettings(textureRoughness, tz, tx, tr)

    const material = new THREE.MeshPhysicalMaterial({ 
      map: textureColor,
      //aoMap: textureAmientOcclusion,
      // displacementMap: textureHeight,
      //normalMap: textureNormal,
      //roughnessMap: textureRoughness,
      //aoMapIntensity: 1,
      //displacementScale: 100, 
      //metalness: 0,
    })

    const sideZMaterial = new THREE.MeshPhysicalMaterial({ 
      map: sideZTexture,
    })

    const sideXMaterial = new THREE.MeshPhysicalMaterial({ 
      map: sideXTexture,
    })
    // console.log(sideXMaterial)
    const vloer = new THREE.Mesh(Geometry, [sideZMaterial, sideZMaterial, sideXMaterial, sideXMaterial, material, material])
    vloer.rotation.x = 1.5 * Math.PI
    vloer.position.x = z / 2 
    vloer.position.z = x / 2 
    vloer.position.y = -75

    vloer.geometry.setAttribute('uv2', new THREE.BufferAttribute(vloer.geometry.attributes.uv.array, 2))

    vloer.receiveShadow = true;
    vloer.castShadow = true;

    this.group.add(vloer)



    const textloader = new FontLoader()
    textloader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/fonts/Calibri_Regular.json`, (font) => {
      // textloader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {      
      const textMaterial = new THREE.MeshBasicMaterial( {color: 0xE3E6E9, side: THREE.FrontSide} );

      const box0 = new THREE.Box3()
      const vector0 = new THREE.Vector3()
      this.box = box0
      this.vector = vector0

      const textSize = 400

      const textFront = new THREE.Mesh(new TextGeometry("VOORZIJDE", {
        font: font,
        size: textSize,
        height: 1,
      }), textMaterial)
      box0.setFromObject(textFront)
      box0.getSize(vector0)
      textFront.position.set(-vector0.y-900, -45, x/2-vector0.x/2)
      textFront.rotation.x = 270*Math.PI/180
      textFront.rotation.z = 270*Math.PI/180
      // console.log(vector0.y)

      const textLeft = new THREE.Mesh(new TextGeometry("LINKERZIJDE", {
        font: font,
        size: textSize,
        height: 1,
      }), textMaterial)
      box0.setFromObject(textLeft)
      box0.getSize(vector0)
      textLeft.position.set(z-(z/2-vector0.x/2), -45, -vector0.y-900)
      textLeft.rotation.x = 270*Math.PI/180
      textLeft.rotation.z = 180*Math.PI/180

      const textBack = new THREE.Mesh(new TextGeometry("ACHTERZIJDE", {
        font: font,
        size: textSize,
        height: 1,
      }), textMaterial)
      box0.setFromObject(textBack)
      box0.getSize(vector0)
      textBack.position.set(vector0.y+900 + z, -45, x/2+vector0.x/2)
      textBack.rotation.x = 270*Math.PI/180
      textBack.rotation.z = 90*Math.PI/180

      const textRight = new THREE.Mesh(new TextGeometry("RECHTERZIJDE", {
        font: font,
        size: textSize,
        height: 1,
      }), textMaterial)
      box0.setFromObject(textRight)
      box0.getSize(vector0)
      textRight.position.set((z/2-vector0.x/2), -45, x+vector0.y+900)
      textRight.rotation.x = 270*Math.PI/180
      textRight.rotation.z = 0*Math.PI/180

      this.group.add(textFront)
      this.group.add(textLeft)
      this.group.add(textBack)
      this.group.add(textRight)
    })
  }

  update (x, z) {
    this.x = x
    this.z = z

    const Geometry = new this.THREE.BoxGeometry(z + (this.spacing * 2), x + (this.spacing * 2), 50) // 100 100

    const vloer = this.group.children[0]
    vloer.geometry.dispose()
    vloer.geometry = Geometry
    vloer.geometry.setAttribute('uv2', new this.THREE.BufferAttribute(vloer.geometry.attributes.uv.array, 2))

    const tx = (z + (this.spacing * 2)) * 0.0005
    const tz = (x + (this.spacing * 2)) * 0.0005
    const tr = Math.PI * 0.5

    this.textureSettings(vloer.material[0].map, tz, 0.125, tr)
    // vloer.material[0].map.needsUpdate = true
    vloer.material[0].needsUpdate = true
    this.textureSettings(vloer.material[1].map, tz, 0.125, tr)
    // vloer.material[1].map.needsUpdate = true
    vloer.material[1].needsUpdate = true

    this.textureSettings(vloer.material[2].map, tx, 0.125, Math.PI*2)
    // vloer.material[2].map.needsUpdate = true
    vloer.material[2].needsUpdate = true
    this.textureSettings(vloer.material[3].map, tx, 0.125, Math.PI*2)
    // vloer.material[3].map.needsUpdate = true
    vloer.material[3].needsUpdate = true

    this.textureSettings(vloer.material[4].map, tz, tx, tr)
    // vloer.material[4].map.needsUpdate = true
    vloer.material[4].needsUpdate = true
    this.textureSettings(vloer.material[5].map, tz, tx, tr)
    // vloer.material[5].map.needsUpdate = true
    vloer.material[5].needsUpdate = true

    /*this.textureSettings(vloer.material.aoMap, tz, tx, tr)
    vloer.material.aoMap.needsUpdate = true

    this.textureSettings(vloer.material.displacementMap, tz, tx, tr)
    vloer.material.displacementMap.needsUpdate = true

    this.textureSettings(vloer.material.normalMap, tz, tx, tr)
    vloer.material.normalMap.needsUpdate = true

    this.textureSettings(vloer.material.roughnessMap, tz, tx, tr)
    vloer.material.roughnessMap.needsUpdate = true*/
  

    vloer.rotation.x = 1.5 * Math.PI
    vloer.position.x = z / 2 
    vloer.position.z = x / 2 
    vloer.position.y = -75

    const textFront = this.group.children[1]
    const textLeft = this.group.children[2]
    const textBack = this.group.children[3]
    const textRight = this.group.children[4]

    this.box.setFromObject(textFront)
    this.box.getSize(this.vector)
    textFront.position.set(-this.vector.x-900, -50, x/2-this.vector.z/2)      //y x      y->x    x->z
    // console.log(x,z)
    // console.log(this.vector)
    // console.log(-this.vector.y)

    this.box.setFromObject(textLeft)
    this.box.getSize(this.vector)
    textLeft.position.set(z-(z/2-this.vector.x/2), -50, -this.vector.z-900)
    // console.log(this.vector)

    this.box.setFromObject(textBack)
    this.box.getSize(this.vector)
    textBack.position.set(this.vector.x+900 + z, -50, x/2+this.vector.z/2)

    this.box.setFromObject(textRight)
    this.box.getSize(this.vector)
    textRight.position.set((z/2-this.vector.x/2), -50, x+this.vector.z+900)


  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    // console.log(texture)
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    // texture.offset.set(Math.random(), 0)
  }
}