/* eslint-disable no-unused-vars */
import { Vector3, Group, EllipseCurve, Shape, MeshBasicMaterial, Mesh, ShapeGeometry, Color, ShaderMaterial } from 'three'

// volledige box vorm met border-radius
// profiel.pushCurve(br, br, br, br, 0, Math.PI * 1.5, true, - Math.PI * 0.5, cp)
// profiel.pushCurve(br, z - br, br, br, Math.PI * 1.5, Math.PI * 1, true, - Math.PI * 0.5, cp)
// profiel.pushCurve(x - br, z - br, br, br, Math.PI * 1, Math.PI * 0.5, true, - Math.PI * 0.5, cp)
// profiel.pushCurve(x - br, br, br, br, Math.PI * 0.5, Math.PI * 0, true, - Math.PI * 0.5, cp)

function createEllipseCurve (aX, aY, xRadius, yRadius, aStartAngle, aEndAngle, aClockwise, aRotation, nPoints) {

  const curve = new EllipseCurve(aX, aY, xRadius, yRadius, aStartAngle, aEndAngle, aClockwise, aRotation)
  const curvePoints2D = curve.getPoints(nPoints)

  return curvePoints2D.map((p) => {
    return new Vector3(p.x, p.y, 0)
  })
}

function shapeToGeomtery(outline, holes) {
  const shape = new Shape(outline)

  if (holes) {
    shape.holes = [new Shape(holes)]
  }

  return new ShapeGeometry(shape)
}

class VectorList {
  constructor () {
    this.vectors = []
  }

  push (x, y, z) {
    this.vectors.push(new Vector3(x, y, z))
  }

  pushCurve(aX, aY, xRadius, yRadius, aStartAngle, aEndAngle, aClockwise, aRotation, nPoints) {
    this.vectors.push(...createEllipseCurve(aX, aY, xRadius, yRadius, aStartAngle, aEndAngle, aClockwise, aRotation, nPoints))
  }
}

function createGeometries ({ x, z, ox, br, cp, t, s, d, cornerType, dx }) {

  let cot   // center offset top
  let cob   // center offset bottom
  let sm    // maximum afschuining
  let so    // binnenlijn hoek offset door afschuining
  let hd    // halve delta d / 2

  hd = d / 2

  if (ox < (x / 2 - br * 2)) {
    cot = x - ox
    cob = ox
  } else {
    cot = x / 2
    cob = x / 2
  }

  so = t / Math.tan(((90 - 22.5) / 180) * Math.PI)

  const minimumWidth = (s + so + t)*2

  sm = s - (x < minimumWidth ? (minimumWidth-x)/2 : 0)//Math.max(Math.min(s, Math.min(z, x) / 2), 0)

  z = dx+sm+sm

  // deel 1 ouside line
  const profiel1 = new VectorList()

  if (cornerType === 'BORDERRADIUS') {
    // met border-radius
    profiel1.pushCurve(br, br, br, br, 0, Math.PI * 1.5, true, - Math.PI * 0.5, cp)
    profiel1.pushCurve(br, z - br, br, br, Math.PI * 1.5, Math.PI * 1, true, - Math.PI * 0.5, cp)
  } else {
    // met afschuining
    profiel1.push(sm, 0, 0)
    profiel1.push(0, sm, 0)
    profiel1.push(0, z - sm, 0)
    profiel1.push(sm, z, 0)
  }

  profiel1.push(cot - hd, z, 0)

  if (ox < (x / 2 - br * 2)) {
    profiel1.pushCurve(x - ox - hd - (br - hd), (br - hd) + z / 2 + hd, (br - hd), (br - hd), Math.PI * 0.5, Math.PI * 0, true, - Math.PI * 0.5, cp)
    profiel1.pushCurve(ox + (br + hd) - hd, z / 2 - (br + hd) + hd, (br + hd), (br + hd), Math.PI * 0, Math.PI * 0.5, false, Math.PI * 0.5, cp)
  } 

  profiel1.push(cob - hd, 0, 0)

  // deel 1 inside line
  const profiel2 = new VectorList()

  if (cornerType === 'BORDERRADIUS') {
    // met border-radius
    profiel2.pushCurve(br, br, br - t, br - t, 0, Math.PI * 1.5, true, - Math.PI * 0.5, cp)
    profiel2.pushCurve(br, z - br, br - t, br - t, Math.PI * 1.5, Math.PI * 1, true, - Math.PI * 0.5, cp)
  } else {
    // met afschuining
    profiel2.push(Math.min(Math.max(sm + so, t), x / 2 - t), t, 0)
    profiel2.push(t, Math.min(Math.max(sm + so, t), z / 2), 0)
    profiel2.push(t, Math.max(Math.max(z - sm - so, t), z / 2), 0)
    profiel2.push(Math.min(Math.max(sm + so, t), x / 2 - t), z - t, 0)
  }

  profiel2.push(cot - t - hd, z - t, 0)

  if (ox < ((x) / 2 - br * 2)) {
    profiel2.pushCurve(x - ox - (br - hd) - hd, (br - hd) + z / 2 + hd, (br - hd) - t, (br - hd) - t, Math.PI * 0.5, Math.PI * 0, true, - Math.PI * 0.5, cp)
    profiel2.pushCurve(ox + (br + hd) - hd, z / 2 - (br + hd) + hd, br + t + hd, br + t + hd, Math.PI * 0, Math.PI * 0.5, false, Math.PI * 0.5, cp)
  }

  profiel2.push(cob - t - hd, t, 0)

  // deel 2 outside line
  const profiel3 = new VectorList()

  profiel3.push(cot + hd, z, 0)

  if (cornerType === 'BORDERRADIUS') {
    // met border-radius
    profiel3.pushCurve(x - br, z - br, br, br, Math.PI * 1, Math.PI * 0.5, true, - Math.PI * 0.5, cp)
    profiel3.pushCurve(x - br, br, br, br, Math.PI * 0.5, Math.PI * 0, true, - Math.PI * 0.5, cp)
  } else {
    // met afschuining
    profiel3.push(x - sm, z, 0)
    profiel3.push(x, z - sm, 0)
    profiel3.push(x, sm, 0)
    profiel3.push(x - sm, 0, 0)
  }

  profiel3.push(cob + hd, 0, 0)

  if (ox < (x / 2 - br * 2)) {
    profiel3.pushCurve(ox + (br - hd) + hd, z / 2 - (br - hd) - hd, (br - hd), (br - hd), Math.PI * 1.5, Math.PI * 1, true, - Math.PI * 0.5, cp)
    profiel3.pushCurve(x - ox - (br + hd) + hd, (br + hd) + z / 2 - hd, (br + hd), (br + hd), Math.PI * 1, Math.PI * 1.5, false, Math.PI * 0.5, cp)
  }

  // deel 3 inside line
  const profiel4 = new VectorList()

  profiel4.push(cot + t + hd, z - t, 0)

  if (cornerType === 'BORDERRADIUS') {
    // met border-radius
    profiel4.pushCurve(x - br, z - br, br - t, br - t, Math.PI * 1, Math.PI * 0.5, true, - Math.PI * 0.5, cp)
    profiel4.pushCurve(x - br, br, br - t, br - t, Math.PI * 0.5, Math.PI * 0, true, - Math.PI * 0.5, cp)
  } else {
    // met afschuining
    profiel4.push(x - sm - so, z - t, 0)
    profiel4.push(x - t, Math.max(Math.max(z - sm - so, t), z / 2), 0)
    profiel4.push(x - t, Math.min(Math.max(sm + so, t), z / 2), 0)
    profiel4.push(x - sm - so, t, x / 2 - t, t, 0)
  }

  profiel4.push(cob + t + hd, t, 0)

  if (ox < (x / 2 - br * 2)) {
    profiel4.pushCurve(ox + (br - hd) + hd, z / 2 - (br - hd) - hd, (br - hd) - t, (br - hd) - t, Math.PI * 1.5, Math.PI * 1, true, - Math.PI * 0.5, cp)
    profiel4.pushCurve(x - ox - (br + hd) + hd, (br + hd) + z / 2 - hd, (br + hd) + t, (br + hd) + t, Math.PI * 1, Math.PI * 1.5, false, Math.PI * 0.5, cp)
  }

  return [
    shapeToGeomtery(profiel1.vectors, profiel2.vectors), 
    shapeToGeomtery(profiel2.vectors),
    shapeToGeomtery(profiel3.vectors, profiel4.vectors),
    shapeToGeomtery(profiel4.vectors)
  ]
}

function shaderMaterial(hex) {
  const c = new Color(hex)
  const fragmentShader = `void main() {gl_FragColor = vec4(vec3(${c.r},${c.g},${c.b}),1.0);}`
  const material = new ShaderMaterial({fragmentShader})
  return material
}

function updateColor(component, color, transparent, opacity) {
  if(!color){return}
  component.material = shaderMaterial(color)
  component.material.transparent = transparent
  component.material.opacity = opacity
  component.material.needsUpdate = true
}

export default class HoverBox {
  constructor ({ colorInsideOutline, colorInsideFill, colorOutsideOutline, colorOutsideFill, ...settings}) {
    this.components = []
    this.colorInsideOutline = colorInsideOutline || 0x444242
    this.colorInsideFill = colorInsideFill || 0xff0000
    this.colorOutsideOutline = colorOutsideOutline || 0xa8a6a6
    this.colorOutsideFill = colorOutsideFill || 0xff0000
    this.visible = true
    this.active = {
      inside: settings.active ? settings.active.inside || false : false,
      outside: settings.active ? settings.active.outside || false : false
    }
    this.hover = { inside: false, outside: false }
    this.group = null
    this.settings = settings
    this.init(settings)
  }

  init ({ x, z, ox, br, cp, t, s, d, cornerType, dx }) {
    // x - breedte
    // z - diepte
    // ox - overlap breedte
    // br - hoek radius
    // cp - radius precisie
    // t - dikte van de rand

    // console.time('generating hoverbox')
    
    const group = new Group()
  
    const geometries = createGeometries({ x, z, ox, br, cp, t, s, d, cornerType, dx })

    // deel 1
    
    const component1 = new Mesh(geometries[0], shaderMaterial(this.colorInsideOutline))
    const component2 = new Mesh(geometries[1], shaderMaterial(this.colorInsideFill))
    
    component1.rotation.x = -0.5 * Math.PI
    component2.rotation.x = -0.5 * Math.PI

    component1.name = 'hoverLineBinnen'
    component2.name = 'hoverLineBinnen'

    if (!this.active.inside) {
      component2.visible = false
    }

    this.components[0] = component1
    this.components[1] = component2

    group.add(component1, component2)

    // deel 2
    const component3 = new Mesh(geometries[2], shaderMaterial(this.colorOutsideOutline))
    const component4 = new Mesh(geometries[3], shaderMaterial(this.colorOutsideFill))

    component3.rotation.x = -0.5 * Math.PI
    component4.rotation.x = -0.5 * Math.PI

    component3.name = 'hoverLineBuiten'
    component4.name = 'hoverLineBuiten'

    if (!this.active.outside) {
      component4.visible = false
    }

    this.components[2] = component3
    this.components[3] = component4

    group.add(component3, component4)

    // console.timeEnd('generating hoverbox')

    this.components[1].visible = false
    this.components[3].visible = false

    group.name = "hoverLineGroup"
    this.group = group
    this.group.position.y = -50 +1 //to prevent z-fighting
    this.group.position.z = this.settings.z/2
  }

  updateSize (x, dx) {
    this.settings.x = x
    this.settings.dx = dx
    // console.time('update hoverbox')

    const geometries = createGeometries({ 
      x, 
      z: this.settings.z, 
      ox: this.settings.ox, 
      br: this.settings.br, 
      cp: this.settings.cp, 
      t: this.settings.t,
      s: this.settings.s,
      d: this.settings.d,
      cornerType: this.settings.cornerType, 
      dx: this.settings.dx
    })

    for (let i = 0; i < this.components.length; i++) {
      this.components[i].geometry = geometries[i]
    }

    // console.timeEnd('update hoverbox')
  }

  setHover ({ hover, colorInsideFill, colorOutsideFill }) {
    // console.time('update hover')
    if (hover.inside !== undefined) {
      if (hover.inside) {
        this.components[1].visible = true

        updateColor(this.components[1], colorInsideFill, true, 1)
      } else if (!hover.inside) {
        this.components[1].visible = false
      } /*else if (!hover.inside && this.active.inside) {
        updateColor(this.components[1], colorInsideFill, false, 1)
      }*/
      this.hover.inside = hover.inside
    }

    if (hover.outside !== undefined) {
      if (hover.outside) {
        this.components[3].visible = true

        updateColor(this.components[3], colorOutsideFill, true, 1)
      } else if (!hover.outside) {
        this.components[3].visible = false
      } /*else if (!hover.outside && this.active.outside) {
        updateColor(this.components[3], colorOutsideFill, false, 1)
      }*/
      this.hover.outside = hover.outside
    }

    if(colorOutsideFill) {
      this.colorOutsideFill = colorOutsideFill
    }
    if(colorInsideFill) {
      this.colorInsideFill = colorInsideFill
    }

    // console.timeEnd('update hover')
  }

  setActive ({ active, colorInsideFill, colorOutsideFill }) {
    // console.time('update active')

    if (active.inside) {
      updateColor(this.components[1], colorInsideFill, false, 1)
      this.components[1].visible = true
    } else {
      this.components[1].visible = false
    }

    if (active.outside) {
      updateColor(this.components[3], colorOutsideFill, false, 1)
      this.components[3].visible = true
    } else {
      this.components[3].visible = false
    }

    this.active = active
    this.colorOutsideFill = colorOutsideFill
    this.colorInsideFill = colorInsideFill

    // console.timeEnd('update active')
  }

  setVisability (isVisible) {
    // console.time('update visablity')

    this.visible = isVisible
    this.group.visible = isVisible

    // console.timeEnd('update visablity')
  }

  destroy () {
    // console.time('destroy')
    for (let i = 0; i < this.components.length; i++) {

      this.components[i].geometry.dispose()
      this.components[i].material.dispose()

    }
    // console.timeEnd('destroy')
  }
}
