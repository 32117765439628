import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
import * as gordingBuffer from '@/three/buffer-geometries/gordingBuffer.js'
import * as schuineGording from '@/three/buffer-geometries/schuineGording.js'

export default class RingBalk {
  constructor (THREE, textureLoader, x, y, z, dy, dz, staanderDikte, erOn, erGording, erz, ery, erp, kz, kzp, kx, kxo, kyo, ky, gy, overstekKlos, oKEnabled, modelType, dakhelling, ox, oy, rbtpy, kapschuurConfig, texture) {
    this.THREE = THREE
    this.x = x
    this.y = y
    this.z = z
    this.dy = dy
    this.dz = dz
    this.staanderDikte = staanderDikte
    this.erOn = erOn
    this.erGording = erGording
    this.erz = erz
    this.ery = ery
    this.erp = erp
    this.kz = kz
    this.kzp = kzp
    this.kx = kx
    this.kxo = kxo
    this.kyo = kyo
    this.ky = ky
    this.gy = gy
    this.overstekKlos = overstekKlos
    this.oKEnabled = oKEnabled
    this.modelType = modelType
    this.dakhelling = dakhelling
    this.ox = ox
    this.oy = oy
    this.rbtpy = rbtpy
    this.kapschuurConfig = kapschuurConfig
    this.texture = texture
    this.group = new THREE.Group()
    this.textureLoader = textureLoader
    this.bouw(this.THREE, textureLoader, this.x, this.y, this.z, this.dy, this.dz, this.staanderDikte, this.erOn, this.erGording, this.erz, this.ery, this.erp, kz, kzp, kx, kxo, kyo, ky, gy, overstekKlos, oKEnabled, dakhelling, ox, oy, rbtpy, modelType, texture)
  }

  bouw (THREE, textureLoader, x, y, z, dy, dz, staanderDikte, erOn, erGording, erz, ery, erp, kz, kzp, kx, kxo, kyo, ky, gy, overstekKlos, oKEnabled, dakhelling, ox, oy, rbtpy, modelType, texture) {
    console.log(this.modelType)

    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let xOndVo = this.xOndVo = klosVoEnabled ? overstekKlos.xOndVo : 0
    let yHooVo = this.yHooVo = klosVoEnabled ? overstekKlos.yHooVo : 0
    let yKopVo = this.yKopVo = klosVoEnabled ? overstekKlos.yKopVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let xOndAc = this.xOndAc = klosAcEnabled ? overstekKlos.xOndAc : 0
    let yHooAc = this.yHooAc = klosAcEnabled ? overstekKlos.yHooAc : 0
    let yKopAc = this.yKopAc = klosAcEnabled ? overstekKlos.yKopAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let xOndLi = this.xOndLi = klosLiEnabled ? overstekKlos.xOndLi : 0
    let yHooLi = this.yHooLi = klosLiEnabled ? overstekKlos.yHooLi : 0
    let yKopLi = this.yKopLi = klosLiEnabled ? overstekKlos.yKopLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    let xOndRe = this.xOndRe = klosReEnabled ? overstekKlos.xOndRe : 0
    let yHooRe = this.yHooRe = klosReEnabled ? overstekKlos.yHooRe : 0
    let yKopRe = this.yKopRe = klosReEnabled ? overstekKlos.yKopRe : 0

    console.log("BOUW", x, y, z, dy, dz, staanderDikte, erOn, erz, ery, erp, kz, kzp, kx, kxo, kyo, ky, oKEnabled, dakhelling, ox, oy, rbtpy, modelType, texture)

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z
    const ringBalkBreedteKapschuur = breedte+ox+((rbtpy+oy)/Math.tan(dakhelling*(Math.PI/180)))

    // balken op de z as (loodrecht op de x as)
    const geometryZ = new THREE.BufferGeometry()
    if(this.modelType.isKapschuur) {
      geometryZ.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(ringBalkBreedteKapschuur*Math.sin(dakhelling*(Math.PI/180)), dy, dz, 90-dakhelling)), 3))
      geometryZ.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(ringBalkBreedteKapschuur*Math.sin(dakhelling*(Math.PI/180)), dy, dz, 90-dakhelling)), 2))
      geometryZ.setIndex(schuineGording.indexBuffer());
      geometryZ.computeVertexNormals()
      geometryZ.translate(0, 0, -dz/2)
      geometryZ.rotateX(Math.PI)
    } else {
      if(this.modelType.isPlatdak ) {
        geometryZ.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(this.buffer(breedte, dy, dz)), 3 ) );
        geometryZ.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(this.uvBuffer(breedte, dy, dz)), 2));
        geometryZ.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(this.normalBuffer()), 3));
      } else {
        geometryZ.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(this.buffer(breedte-((staanderDikte-dz)*2), dy, dz)), 3 ) );
        geometryZ.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(this.uvBuffer(breedte-((staanderDikte-dz)*2), dy, dz)), 2));
        geometryZ.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(this.normalBuffer()), 3));
      }
      geometryZ.groups = this.groupBuffer()
    }

    // textures
    const textureZijkant = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureZijkant,  breedte/2320/breedte,  dy/145/dy, Math.PI)
    const materialZijkant = new THREE.MeshStandardMaterial({ map: textureZijkant, metalness: 0, roughness: 1 })

    const textureBoven = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureBoven,  breedte/2320/breedte,  dy/145/dy, 2*Math.PI, 0.7)
    const materialBoven = new THREE.MeshStandardMaterial({ map: textureBoven, metalness: 0, roughness: 1 })

    const textureOnder = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureOnder,  breedte/2320/breedte,  dy/145/dy, 2*Math.PI, 0.4)
    const materialOnder = new THREE.MeshStandardMaterial({ map: textureOnder, metalness: 0, roughness: 1 })

    const textureKop = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureKop,  dz/2320/dz/2,  dy/145/dy/2, Math.PI)
    const materialKop = new THREE.MeshStandardMaterial({ map: textureKop, metalness: 0, roughness: 1 })

    const textureKapschuur = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureKapschuur,  1,  1, Math.PI)
    const materialKapschuur = new THREE.MeshStandardMaterial({ map: textureKapschuur, metalness: 0, roughness: 1 })

    const materialR = new THREE.MeshStandardMaterial({ color: 0xff0000, metalness: 0, roughness: 1 })
    const materialG = new THREE.MeshStandardMaterial({ color: 0x00ff00, metalness: 0, roughness: 1 })
    const materialB = new THREE.MeshStandardMaterial({ color: 0x0000ff, metalness: 0, roughness: 1 })
    console.log(materialZijkant, materialKop, materialBoven, materialOnder, materialB, materialG, materialR)
    // const materialZ = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
    let balk0
    if(this.modelType.isKapschuur) {
      balk0 = new THREE.Mesh( geometryZ, materialKapschuur)
      balk0.rotation.z = (90-dakhelling)*Math.PI/180
    } else {
      balk0 = new THREE.Mesh( geometryZ, [
        materialZijkant,  //zijkant
        materialZijkant,  //zijkant
        materialKop,  //kopkant
        materialBoven,  //bovenkant
        materialKop,  //kopkant
        materialOnder   //onderkant
      ]);
    }
    if(this.modelType.isPlatdak ) {
      balk0.position.x = 0
      balk0.position.z = 0
    } else {
      balk0.position.x = staanderDikte-dz
      balk0.position.z = 0
    }
    if(this.modelType.isKapschuur) {
      balk0.position.x = ringBalkBreedteKapschuur
    }
    balk0.castShadow = true;
    balk0.receiveShadow = true;
    balk0.frustumCulled = false
    console.log(balk0)
    const balk1 = balk0.clone()
    if(this.modelType.isPlatdak ) {
      balk1.position.x = 0
      balk1.position.z = x - dz
    } else {
      balk1.position.z = x - staanderDikte
      balk1.position.z = x - dz
    }

    // balken op de x as (loodrecht op de z as)
    const geometryXVoor = new THREE.BufferGeometry()
    if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
      geometryXVoor.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(x, dy, dz+1, kx, kxo, kx, kxo, ky, kyo, ky, kyo)), 3));
      geometryXVoor.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(x, dy, dz+1, kx, kxo, kx, kxo, ky, kyo, ky, kyo)), 2));
      geometryXVoor.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3));
    } else {
      if(this.modelType.isPlatdak ) {
        geometryXVoor.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(this.buffer(x, dy, dz)), 3 ));
        geometryXVoor.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(this.uvBuffer(x, dy, dz)), 2));
        geometryXVoor.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(this.normalBuffer()), 3));
      } else{
        geometryXVoor.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(this.buffer(x, dy, dz)), 3 ));
        geometryXVoor.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(this.uvBuffer(x, dy, dz)), 2));
        geometryXVoor.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(this.normalBuffer()), 3));
      }
      geometryXVoor.groups = this.groupBuffer()
    }

    const geometryXAchter = new THREE.BufferGeometry()
    if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
      geometryXAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(x, dy, dz+1, kx, kxo, kx, kxo, ky, kyo, ky, kyo)), 3));
      geometryXAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(x, dy, dz+1, kx, kxo, kx, kxo, ky, kyo, ky, ky)), 2));
      geometryXAchter.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3));
    } else if(this.modelType.isKapschuur && !oKEnabled) {
      geometryXAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(x, dy, dz)), 3));
      geometryXAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer(x, dy, dz)), 2));
      geometryXAchter.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3));
      geometryXAchter.translate(x/2, -dy/2, dz/2)
      geometryXAchter.setIndex(boxBuffer.indexBuffer());
      geometryXAchter.groups = boxBuffer.groupBuffer()
    } else {
      if(this.modelType.isPlatdak ) {
        geometryXAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(this.buffer(x, dy, dz)), 3 ));
        geometryXAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(this.uvBuffer(x, dy, dz)), 2));
        geometryXAchter.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(this.normalBuffer()), 3));
      } else{
        geometryXAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(this.buffer(x-((staanderDikte-dz)*2), dy, dz)), 3 ));
        geometryXAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(this.uvBuffer(x-((staanderDikte-dz)*2), dy, dz)), 2));
        geometryXAchter.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(this.normalBuffer()), 3));
      }
      geometryXAchter.groups = this.groupBuffer()
    }

    const textureZijkant1 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureZijkant1,  x/2320/x,  dy/145/dy, Math.PI)
    const materialZijkant1 = new THREE.MeshStandardMaterial({ map: textureZijkant1, metalness: 0, roughness: 1 })

    const textureBoven1 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureBoven1,  x/2320/x,  dy/145/dy, 2*Math.PI, 0.7)
    const materialBoven1 = new THREE.MeshStandardMaterial({ map: textureBoven1, metalness: 0, roughness: 1 })

    const textureOnder1 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureOnder1,  x/2320/x,  dy/145/dy, 2*Math.PI, 0.4)
    const materialOnder1 = new THREE.MeshStandardMaterial({ map: textureOnder1, metalness: 0, roughness: 1 })

    const textureKop1 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureKop1,  dz/2320/dz/2,  dy/145/dy/2, Math.PI)
    const materialKop1 = new THREE.MeshStandardMaterial({ map: textureKop1, metalness: 0, roughness: 1 })

    const textureOverstekKlos = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureOverstekKlos, (x+2*kx) / 5440000 , dy / 16000)
    const materialOverstekKlos = new THREE.MeshStandardMaterial({ map: textureOverstekKlos, metalness: 0, roughness: 1 })

    const textureLissabon = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureLissabon,  x/2320,  dy/145, Math.PI)
    const materialLissabon = new THREE.MeshStandardMaterial({ map: textureLissabon, metalness: 0, roughness: 1 })

    const textureLissabonKop = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureLissabonKop,  dz/2320,  dy/145, Math.PI)
    const materialLissabonKop = new THREE.MeshStandardMaterial({ map: textureLissabonKop, metalness: 0, roughness: 1 })

    let balk2
    if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
      balk2 = new THREE.Mesh( geometryXVoor, materialOverstekKlos)
    } else {
      balk2 = new THREE.Mesh( geometryXVoor, [
        materialZijkant1,  //zijkant
        materialZijkant1,  //zijkant
        materialKop1,  //kopkant
        materialBoven1,  //bovenkant
        materialKop1,  //kopkant
        materialOnder1   //onderkant
      ]);
    }
    balk2.position.z = 0
    balk2.position.x = 0
    balk2.castShadow = true;
    balk2.receiveShadow = true;
    balk2.frustumCulled = false
    if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
      balk2.rotation.y = 0.5 * Math.PI
      balk2.position.z = x + kx
      balk2.position.x = -1
    } else {
      balk2.rotation.x = Math.PI
      balk2.rotation.y = 0.5 * Math.PI
    }
    balk2.position.y = !((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) ? dy : 0

    let balk3
    if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
      balk3 = new THREE.Mesh( geometryXAchter, materialOverstekKlos)
    } else if(this.modelType.isKapschuur && !oKEnabled) {
      balk3 = new THREE.Mesh( geometryXAchter, [
        materialLissabonKop,
        materialLissabonKop,
        materialLissabon,
        materialLissabon,
        materialLissabon,
        materialLissabon
      ])
    } else {
      balk3 = new THREE.Mesh( geometryXAchter, [
        materialZijkant1,  //zijkant
        materialZijkant1,  //zijkant
        materialKop1,  //kopkant
        materialBoven1,  //bovenkant
        materialKop1,  //kopkant
        materialOnder1   //onderkant
      ]);
    }
    balk3.position.z = staanderDikte-dz
    balk3.position.x = staanderDikte-dz
    balk3.castShadow = true;
    balk3.receiveShadow = true;
    balk3.frustumCulled = false
    if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
      balk3.rotation.y = 0.5 * Math.PI
      balk3.position.z = x + kx
      balk3.position.x = -1
    } else {
      balk3.rotation.x = Math.PI
      balk3.rotation.y = 0.5 * Math.PI
    }
    if(this.modelType.isPlatdak ) {
      balk3.position.z = 0
      balk3.position.x = breedte - dz
    } else {
      balk3.position.x = breedte - staanderDikte
    }
    balk3.position.y = !((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) ? dy : 0


    let eBalk0, eBalk1, eBalk2, eBalk3
    if(erOn) {
      if (erGording) {
        const eGeometryZ = new THREE.BufferGeometry()
        eGeometryZ.setAttribute('position', new THREE.BufferAttribute(new Float32Array(gordingBuffer.positionBufferGording(breedte, gy, erz, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 3))
        eGeometryZ.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(breedte, gy, erz, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 2))
        eGeometryZ.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3))
        // textures
        const loadedTextureZ = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
        this.textureSettings(loadedTextureZ,  breedte / 2330 / breedte , gy / 145 / gy)
        const eMaterialZ = new THREE.MeshStandardMaterial({ map: loadedTextureZ, metalness: 0, roughness: 1 })
        eBalk0 = new THREE.Mesh( eGeometryZ, eMaterialZ);
        eBalk0.castShadow = true;
        eBalk0.receiveShadow = true;
        eBalk0.position.y= dy+ery-gy
        eBalk0.position.x= -xBovVo
        eBalk0.position.z = (erp ? (dz-erz/2) : erz/2) - erz/2 - 0.5
        eBalk1 = eBalk0.clone()
        eBalk1.position.z = (erp ? (x - dz + erz/2) : (x-erz/2)) - erz/2 + 0.5

        // minder ovor klossen compensatie
        const erOnderMinder = eBalk0.position.z
        const klosMinderLinks = klosLiEnabled ? erOnderMinder : 0.5
        const klosMinderRechts = klosReEnabled ? erOnderMinder : 0.5

        const eGeometryX = new THREE.BufferGeometry()
        eGeometryX.setAttribute('position', new THREE.BufferAttribute(new Float32Array(gordingBuffer.positionBufferGording(x, gy, erz, xBovRe, xOndRe-klosMinderRechts, xBovLi, xOndLi-klosMinderLinks, yHooRe, yKopRe, yHooLi, yKopLi)), 3))
        eGeometryX.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(x, gy, erz, xBovRe, xOndRe-klosMinderRechts, xBovLi, xOndLi-klosMinderLinks, yHooRe, yKopRe, yHooLi, yKopLi)), 2))
        eGeometryX.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3))
        // textures
        const loadedTextureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
        this.textureSettings(loadedTextureX,  breedte / 2330 / breedte , gy / 145 / gy)
        const eMaterialX = new THREE.MeshStandardMaterial({ map: loadedTextureX, metalness: 0, roughness: 1 })
        eBalk2 = new THREE.Mesh( eGeometryX, eMaterialX);
        eBalk2.castShadow = true;
        eBalk2.receiveShadow = true;
        eBalk2.rotation.y=Math.PI/2
        eBalk2.position.y= dy+ery-gy
        eBalk2.position.x= (erp? dz - erz/2 : erz/2) - erz/2 - 0.5
        eBalk2.position.z = x+xBovRe
        eBalk3 = eBalk2.clone()
        eBalk3.position.x = (breedte - (erp?dz - (erz/2) : erz/2)) - erz/2 + 0.5
      } else {
        const eGeometryZ = new THREE.BufferGeometry()
        eGeometryZ.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(breedte + xBovVo + xBovAc - (erp ? 0 : (2 * erz)), ery, erz)), 3))
        eGeometryZ.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        eGeometryZ.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        eGeometryZ.setIndex(boxBuffer.indexBuffer());
        eGeometryZ.groups = boxBuffer.groupBuffer()
        // textures
        const eTextureZ = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
        this.textureSettings(eTextureZ,  breedte/2320,  erz/145, Math.PI)
        const eTextureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
        this.textureSettings(eTextureX,  ery/2320,  erz/145,  0.5*Math.PI)
        const eMaterialX = new THREE.MeshStandardMaterial({ map: eTextureX, metalness: 0, roughness: 1 })
        const eMaterialZ = new THREE.MeshStandardMaterial({ map: eTextureZ, metalness: 0, roughness: 1 })
        eBalk0 = new THREE.Mesh( eGeometryZ, [
          eMaterialX,
          eMaterialX,
          eMaterialZ,
          eMaterialZ,
          eMaterialZ,
          eMaterialZ,
        ]);
        eBalk0.castShadow = true;
        eBalk0.receiveShadow = true;
        eBalk0.position.y= dy + ery/2
        eBalk0.position.x= breedte/2 -xBovVo/2 + xBovAc/2
        eBalk0.position.z = erp ? (dz-erz/2) : erz/2
        eBalk1 = eBalk0.clone()
        eBalk1.position.z = erp ? (x - dz + erz/2) : (x-erz/2)




        const eGeometryX = new THREE.BufferGeometry()
        eGeometryX.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(x+xBovLi+xBovRe, ery, erz)), 3))
        eGeometryX.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
        eGeometryX.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        eGeometryX.setIndex(boxBuffer.indexBuffer());
        eGeometryX.groups = boxBuffer.groupBuffer()

        // textures
        const eTextureZ2 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
        this.textureSettings(eTextureZ2,  x/2320,  1, Math.PI)

        const eTextureX2 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
        this.textureSettings(eTextureX2,  ery/2320,  erz/145,  0.5*Math.PI)
        const eMaterialX2 = new THREE.MeshStandardMaterial({ map: eTextureX2, metalness: 0, roughness: 1 })

        const eMaterialZ2 = new THREE.MeshStandardMaterial({ map: eTextureZ2, metalness: 0, roughness: 1 })

        // const materialZ = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
        eBalk2 = new THREE.Mesh( eGeometryX, [
          eMaterialX2,
          eMaterialX2,
          eMaterialZ2,
          eMaterialZ2,
          eMaterialZ2,
          eMaterialZ2,
        ]);

        eBalk2.castShadow = true;
        eBalk2.receiveShadow = true;

        eBalk2.rotation.y=Math.PI/2
        eBalk2.position.y= dy + ery/2
        eBalk2.position.x= erp? dz - erz/2 : erz/2
        eBalk2.position.z = x/2-xBovLi/2+xBovRe/2

        eBalk3 = eBalk2.clone()
        eBalk3.position.x = breedte - (erp?dz - (erz/2) : erz/2)
      }


    }

    // groeperen van de balken op de z en x as
    this.group.add(balk0) // Linkerzijde
    this.group.add(balk1) // Rechterzijde
    this.group.add(balk2) // Voorzijde
    this.group.add(balk3) // Achterzijde

    if(erOn) {
      this.group.add(eBalk0,eBalk1,eBalk2,eBalk3)
    }

    if(this.modelType.isKapschuur) {
      balk3.position.y = -(y - this.kapschuurConfig.achtergevelHoogte)
      balk3.position.x = z - dz

      if(this.modelType.isStockholm) {
        const geometryKlos = new THREE.BufferGeometry()
        geometryKlos.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(ky, kx, kxo, kyo, dz)), 3))
        geometryKlos.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(ky, kx, kxo, kyo, dz)), 2))
        geometryKlos.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))
        const klos = new THREE.Mesh( geometryKlos, materialZijkant1)
        klos.castShadow = true;
        klos.receiveShadow = true;
        klos.name = "klos"
        klos.rotation.y = -Math.PI/2
        klos.position.x = breedte
        klos.position.z = -kx

        const klos2 = klos.clone()
        klos2.rotation.y = Math.PI/2
        klos2.position.z = x + kx
        klos2.position.x = breedte-dz
        this.group.add(klos, klos2)
      }
    }
  }

  update (x, y, z, dy, dz, staanderDikte, erOn, erGording, erz, ery, erp, gy, overstekKlos, oKEnabled, modelType, dakhelling, ox, oy, rbtpy, kapschuurConfig) {
    this.x = x,
      this.y = y,
      this.z = z,
      this.dy = dy
    this.dz = dz
    this.staanderDikte = staanderDikte
    this.erOn = erOn
    this.erGording = erGording
    this.erz = erz
    this.ery = ery
    this.erp = erp
    let kz = this.kz = overstekKlos.zDik
    let kzp = this.kzp = overstekKlos.zDikPaal
    let kx = this.kx = overstekKlos.klosVoEnabled ? overstekKlos.xBovVo : 0
    let kxo = this.kxo = overstekKlos.klosVoEnabled ? overstekKlos.xOndVo : 0
    let kyo = this.kyo = overstekKlos.klosVoEnabled ? overstekKlos.yKopVo : 0
    let ky = this.ky = overstekKlos.klosVoEnabled ? overstekKlos.yHooVo : 0
    this.gy = gy
    this.overstekKlos = overstekKlos
    this.oKEnabled = oKEnabled
    this.modelType = modelType
    this.dakhelling = dakhelling
    this.ox = ox
    this.oy = oy
    this.rbtpy = rbtpy
    this.kapschuurConfig = kapschuurConfig

    while(this.group.children.length > 0){
      this.group.remove(this.group.children[0]);
    }
    this.bouw(this.THREE, this.textureLoader, this.x, this.y, this.z, this.dy, this.dz, this.staanderDikte, this.erOn, this.erGording, this.erz, this.ery, this.erp, kz, kzp, kx, kxo, kyo, ky, gy, overstekKlos, oKEnabled, dakhelling, ox, oy, rbtpy, modelType, this.texture)


    // const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z
    // const ringBalkBreedteKapschuur = breedte+ox+((rbtpy+oy)/Math.tan(dakhelling*(Math.PI/180)))

    // // balken op de x as (loodrecht op de z as)
    // if(this.modelType.isKapschuur) {
    //   this.group.children[0].geometry.attributes.position.array = new Float32Array(schuineGording.positionBuffer(ringBalkBreedteKapschuur*Math.sin(dakhelling*(Math.PI/180)), dy, dz, 90-dakhelling))
    //   this.group.children[0].geometry.attributes.uv.array = new Float32Array(schuineGording.uvBuffer(ringBalkBreedteKapschuur*Math.sin(dakhelling*(Math.PI/180)), dy, dz, 90-dakhelling))
    //   this.group.children[0].geometry.attributes.position.needsUpdate = true
    //   this.group.children[0].geometry.attributes.uv.needsUpdate = true
    //   this.group.children[0].geometry.computeVertexNormals()
    //   this.group.children[0].geometry.setIndex(schuineGording.indexBuffer());
    //   this.group.children[0].geometry.translate(0, 0, -dz/2)
    //   this.group.children[0].geometry.rotateX(Math.PI)

    //   this.group.children[0].rotation.z = (90-dakhelling)*Math.PI/180
    //   this.group.children[0].position.x = ringBalkBreedteKapschuur
    //   this.group.children[1].position.x = ringBalkBreedteKapschuur
    // } else {
    //   this.group.children[0].geometry.attributes.position.array = new Float32Array(this.buffer(breedte-((staanderDikte-dz)*2), dy, dz))
    //   this.group.children[0].geometry.attributes.uv.array = new Float32Array(this.uvBuffer(breedte-((staanderDikte-dz)*2), dy, dz))
    //   this.group.children[0].geometry.attributes.position.needsUpdate = true
    //   this.group.children[0].geometry.attributes.uv.needsUpdate = true

    //   this.textureSettings(this.group.children[0].material[0].map,  breedte/2320/breedte,  dy/145/dy, Math.PI)
    //   this.textureSettings(this.group.children[0].material[3].map,  breedte/2320/breedte,  dy/145/dy, 2*Math.PI, 0.7)
    //   this.textureSettings(this.group.children[0].material[5].map,  breedte/2320/breedte,  dy/145/dy, 2*Math.PI, 0.4)
    //   this.textureSettings(this.group.children[0].material[2].map,  dz/2320/dz/2,  dy/145/dy/2, Math.PI)
    //   this.group.children[0].material[0].needsUpdate = true;
    //   this.group.children[0].material[3].needsUpdate = true;
    //   this.group.children[0].material[5].needsUpdate = true;
    //   this.group.children[0].material[2].needsUpdate = true;

    //   this.group.children[0].position.z = staanderDikte-dz
    //   this.group.children[0].position.x = staanderDikte-dz

    //   this.group.children[1].position.x = staanderDikte-dz
    //   this.group.children[1].position.z = x - staanderDikte
    // }






    // // balken op de x as (loodrecht op de z as)
    // if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
    //   console.log(x, dy, dz, kx, kxo, ky, kyo)
    //   this.group.children[2].geometry.attributes.position.array = new Float32Array(gordingBuffer.positionBufferGording(x, dy, dz+1, kx, kxo, ky, kyo));
    //   this.group.children[2].geometry.attributes.uv.array = new Float32Array(gordingBuffer.uvBufferGording(x, dy, dz, kx, kxo, ky, kyo));
    //   this.group.children[2].geometry.attributes.normal.array = new Float32Array(gordingBuffer.normalBufferGording());
    // } else {
    //   this.group.children[2].geometry.attributes.position.array = new Float32Array(this.buffer(x-((staanderDikte-dz)*2), dy, dz))
    //   this.group.children[2].geometry.attributes.uv.array = new Float32Array(this.uvBuffer(x-((staanderDikte-dz)*2), dy, dz))
    //   this.group.children[2].geometry.attributes.normal.array = new Float32Array(this.normalBuffer())
    //   this.group.children[2].geometry.groups = this.groupBuffer()
    // }
    // this.group.children[2].geometry.attributes.position.needsUpdate = true
    // this.group.children[2].geometry.attributes.uv.needsUpdate = true
    // this.group.children[2].geometry.attributes.normal.needsUpdate = true
    // //this.textureSettings(this.group.children[2].material.map,  x/2320/x,  dy/145/dy, Math.PI)
    // if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
    //   this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`, (newTexture) => {
    //     if(Array.isArray(this.group.children[2].material)) {
    //       this.group.children[2].material.forEach((material) => {
    //         material.map.dispose()
    //       })
    //       this.group.children[3].material.forEach((material) => {
    //         material.map.dispose()
    //       })
    //     } else if(this.group.children[2].material.map) {
    //       this.group.children[2].material.map.dispose()
    //       this.group.children[3].material.map.dispose()
    //     }
    //     this.group.children[2].material = new this.THREE.MeshStandardMaterial({ map: newTexture.clone(), metalness: 0, roughness: 1 })
    //     this.textureSettings(this.group.children[2].material.map, (this.x+2*this.kx) / 2320 / (this.x+2*this.kx) , this.dy/145/this.dy)
    //     this.group.children[2].material.needsUpdate = true;
    //     this.group.children[3].material = new this.THREE.MeshStandardMaterial({ map: newTexture.clone(), metalness: 0, roughness: 1 })
    //     this.textureSettings(this.group.children[3].material.map, (this.x+2*this.kx) / 2320 / (this.x+2*this.kx) , this.dy/145/this.dy)
    //     this.group.children[3].material.needsUpdate = true;
    //   })
    // } else {
    //   this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`, (newTexture) => {
    //     for(let i = 2; i < 4; i++) {
    //       this.group.children[i].material = []
    //       if(this.group.children[i].material[0]) {
    //         this.group.children[i].material[0].map.dispose()
    //       } 
    //       this.group.children[i].material[0] = new this.THREE.MeshStandardMaterial({ map: newTexture.clone(), metalness: 0, roughness: 1 })
    //       this.group.children[i].material[1] = this.group.children[i].material[0]
    //       if(this.group.children[i].material[3]) {
    //         this.group.children[i].material[3].map.dispose()
    //       } 
    //       this.group.children[i].material[3] = new this.THREE.MeshStandardMaterial({ map: newTexture.clone(), metalness: 0, roughness: 1 })
    //       if(this.group.children[i].material[5]) {
    //         this.group.children[i].material[5].map.dispose()
    //       } 
    //       this.group.children[i].material[5] = new this.THREE.MeshStandardMaterial({ map: newTexture.clone(), metalness: 0, roughness: 1 })
    //       if(this.group.children[i].material[2]) { 
    //         this.group.children[i].material[2].map.dispose()
    //       }
    //       this.group.children[i].material[2] = new this.THREE.MeshStandardMaterial({ map: newTexture.clone(), metalness: 0, roughness: 1 })
    //       this.group.children[i].material[4] = this.group.children[2].material[2]
    //       this.textureSettings(this.group.children[i].material[0].map,  x/2320/x,  dy/145/dy, Math.PI)
    //       this.textureSettings(this.group.children[i].material[3].map,  x/2320/x,  dy/145/dy, 2*Math.PI, 0.7)
    //       this.textureSettings(this.group.children[i].material[5].map,  x/2320/x,  dy/145/dy, 2*Math.PI, 0.4)
    //       this.textureSettings(this.group.children[i].material[2].map,  dz/2320/dz/2,  dy/145/dy/2, Math.PI)
    //       this.group.children[i].material[0].needsUpdate = true;
    //       this.group.children[i].material[3].needsUpdate = true;
    //       this.group.children[i].material[5].needsUpdate = true;
    //       this.group.children[i].material[2].needsUpdate = true;
    //     }
    //   })
    // }


    // if((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) {
    //   this.group.children[2].rotation.y = 0.5 * Math.PI
    //   this.group.children[2].rotation.x = 0
    //   this.group.children[2].position.z = x + kx
    //   this.group.children[2].position.x = -1
    //   this.group.children[3].rotation.y = 0.5 * Math.PI
    //   this.group.children[3].rotation.x = 0
    //   this.group.children[3].position.z = x + kx
    //   this.group.children[3].position.x = breedte - dz +1
    // } else {
    //   this.group.children[2].rotation.x = Math.PI
    //   this.group.children[2].rotation.y = 0.5 * Math.PI
    //   this.group.children[2].position.z = staanderDikte-dz
    //   this.group.children[2].position.x = staanderDikte-dz
    //   this.group.children[3].rotation.x = Math.PI
    //   this.group.children[3].rotation.y = 0.5 * Math.PI
    //   this.group.children[3].position.z = staanderDikte-dz
    //   this.group.children[3].position.x = breedte - staanderDikte
    // }
    // this.group.children[2].position.y = !((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) ? dy : 0
    // this.group.children[3].position.y = !((this.modelType.isZadeldak || this.modelType.isKapschuur) && oKEnabled) ? dy : 0

    // console.log(this.group.children)
    // if(this.group.children[4]) {
    //   while(this.group.children[4]) {
    //     this.group.children[4].geometry.dispose()
    //     if(Array.isArray(this.group.children[4].material)) {
    //       this.group.children[4].material.forEach(material => {
    //         material.dispose()
    //       })
    //     } else {
    //       this.group.children[4].material.dispose()
    //     }
    //     this.group.children[4].removeFromParent()
    //   }
    // }

    // if(erOn) {
    //   let eBalk0, eBalk1, eBalk2, eBalk3
    //   const eGeometryZ = new this.THREE.BufferGeometry()
    //   eGeometryZ.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(breedte+kx*2-(erp?0:(2*erz)), ery, erz)), 3))
    //   eGeometryZ.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    //   eGeometryZ.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    //   eGeometryZ.setIndex(boxBuffer.indexBuffer());
    //   eGeometryZ.groups = boxBuffer.groupBuffer()

    //   // textures
    //   const eTextureZ = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    //   this.textureSettings(eTextureZ,  breedte/2320,  erz/145, Math.PI)

    //   const eTextureX = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    //   this.textureSettings(eTextureX,  ery/2320,  erz/145,  0.5*Math.PI)
    //   const eMaterialX = new this.THREE.MeshStandardMaterial({ map: eTextureX, metalness: 0, roughness: 1 })

    //   const eMaterialZ = new this.THREE.MeshStandardMaterial({ map: eTextureZ, metalness: 0, roughness: 1 })

    //   // const materialZ = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
    //   eBalk0 = new this.THREE.Mesh( eGeometryZ, [
    //     eMaterialX,
    //     eMaterialX,
    //     eMaterialZ,
    //     eMaterialZ,
    //     eMaterialZ,
    //     eMaterialZ, 
    //   ]);

    //   eBalk0.castShadow = true; 
    //   eBalk0.receiveShadow = true;

    //   eBalk0.position.y= dy + ery/2
    //   eBalk0.position.x= breedte/2
    //   eBalk0.position.z = erp? (dz-erz/2) : erz/2

    //   eBalk1 = eBalk0.clone()
    //   eBalk1.position.z = x - (erp? dz - (erz/2) : erz/2)





    //   const eGeometryX = new this.THREE.BufferGeometry()
    //   eGeometryX.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(x+kx*2, ery, erz)), 3))
    //   eGeometryX.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    //   eGeometryX.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    //   eGeometryX.setIndex(boxBuffer.indexBuffer());
    //   eGeometryX.groups = boxBuffer.groupBuffer()

    //   // textures
    //   const eTextureZ2 = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    //   this.textureSettings(eTextureZ2,  x/2320,  1, Math.PI)

    //   const eTextureX2 = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    //   this.textureSettings(eTextureX2,  ery/2320,  erz/145,  0.5*Math.PI)
    //   const eMaterialX2 = new this.THREE.MeshStandardMaterial({ map: eTextureX2, metalness: 0, roughness: 1 })

    //   const eMaterialZ2 = new this.THREE.MeshStandardMaterial({ map: eTextureZ2, metalness: 0, roughness: 1 })

    //   // const materialZ = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
    //   eBalk2 = new this.THREE.Mesh( eGeometryX, [
    //     eMaterialX2,
    //     eMaterialX2,
    //     eMaterialZ2,
    //     eMaterialZ2,
    //     eMaterialZ2,
    //     eMaterialZ2, 
    //   ]);

    //   eBalk2.castShadow = true; 
    //   eBalk2.receiveShadow = true;

    //   eBalk2.rotation.y=Math.PI/2
    //   eBalk2.position.y= dy + ery/2
    //   eBalk2.position.x= erp? dz - erz/2 : erz/2
    //   eBalk2.position.z = x/2

    //   eBalk3 = eBalk2.clone()
    //   eBalk3.position.x = breedte - (erp?dz - (erz/2) : erz/2)

    //   this.group.add(eBalk0, eBalk1, eBalk2, eBalk3)
    // }

    // if(this.modelType.isKapschuur) {
    //   this.group.children[3].position.y = -(2300 - this.kapschuurConfig.achtergevelHoogte)
    //   this.group.children[3].position.x = z - staanderDikte

    //   const geometryKlos = new this.THREE.BufferGeometry()
    //   geometryKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(ky, kx, kxo, kyo, dz)), 3))
    //   geometryKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(ky, kx, kxo, kyo, dz)), 2))
    //   geometryKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))

    //   const textureZijkant1 = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    //   this.textureSettings(textureZijkant1,  x/2320/x,  dy/145/dy, Math.PI)
    //   const materialZijkant1 = new this.THREE.MeshStandardMaterial({ map: textureZijkant1, metalness: 0, roughness: 1 })

    //   const klos = new this.THREE.Mesh( geometryKlos, materialZijkant1)
    //   klos.rotation.y = -Math.PI/2
    //   klos.position.x = breedte
    //   klos.position.z = -kx

    //   const klos2 = klos.clone()
    //   klos2.rotation.y = Math.PI/2
    //   klos2.position.z = x + kx
    //   klos2.position.x = breedte-dz
    //   this.group.add(klos, klos2)
    // }
  }

  updateTexture (texture) {
    console.log("group", this.group.children)
    this.texture = texture
    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (newTexture) => {
      if(Array.isArray(this.group.children[0].material)) {
        this.group.children[0].material[0].map.dispose()
        this.group.children[0].material[3].map.dispose()
        this.group.children[0].material[5].map.dispose()
        this.group.children[0].material[2].map.dispose()
        this.group.children[0].material[0].map = newTexture.clone()
        this.group.children[0].material[3].map = newTexture.clone()
        this.group.children[0].material[5].map = newTexture.clone()
        this.group.children[0].material[2].map = newTexture.clone()
        this.textureSettings(this.group.children[0].material[0].map,  this.z/2320/this.z,  this.dy/145/this.dy, Math.PI)
        this.textureSettings(this.group.children[0].material[3].map,  this.z/2320/this.z,  this.dy/145/this.dy, 2*Math.PI, 0.7)
        this.textureSettings(this.group.children[0].material[5].map,  this.z/2320/this.z,  this.dy/145/this.dy, 2*Math.PI, 0.4)
        this.textureSettings(this.group.children[0].material[2].map,  this.dz/2320/this.dz/2,  this.dy/145/this.dy/2, Math.PI)
        this.group.children[0].material[0].needsUpdate = true;
        this.group.children[0].material[3].needsUpdate = true;
        this.group.children[0].material[5].needsUpdate = true;
        this.group.children[0].material[2].needsUpdate = true;
      } else {
        this.group.children[0].material.map.dispose()
        this.group.children[0].material.map = newTexture.clone()
        this.textureSettings(this.group.children[0].material.map,  1,  1, Math.PI)
        this.group.children[0].material.needsUpdate = true;
      }

      if(this.erOn) {
        const eTextureZ = newTexture.clone()
        const eTextureX = newTexture.clone()
        this.textureSettings(eTextureZ,  this.z/2320,  1, Math.PI)
        this.textureSettings(eTextureX,  this.ery/2320,  this.erz/145,  0.5*Math.PI)
        const eTextureZGording = newTexture.clone()
        this.textureSettings(eTextureZGording,  this.z / 2330 / this.z , this.gy / 145 / this.gy)

        let forEachIndex = 0
        if (this.erGording) {
          this.group.children[4].material.map.dispose()
          this.group.children[4].material.map = eTextureZGording
          this.group.children[4].material.map.needsUpdate = true;
          this.group.children[4].material.needsUpdate = true;
        } else {
          this.group.children[4].material.forEach(material => {
            material.map.dispose()
            if (forEachIndex < 2) {
              material.map = eTextureX
            } else {
              material.map = eTextureZ
            }
            material.map.needsUpdate = true;
            material.needsUpdate = true;
            forEachIndex++
          })
        }
      }

      if(this.modelType.isKapschuur) {
        this.group.getObjectByName("klos").material.map.dispose()
        this.group.getObjectByName("klos").material.map = newTexture.clone()
        this.textureSettings(this.group.getObjectByName("klos").material.map,  this.x/2320/this.x,  this.dy/145/this.dy, Math.PI)
        this.group.getObjectByName("klos").material.needsUpdate = true;
      }
    })


    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (newTexture) => {
      if((this.modelType.isZadeldak || this.modelType.isKapschuur) && this.oKEnabled) {
        this.group.children[2].material.map.dispose()
        this.group.children[2].material.map = newTexture.clone()
        this.textureSettings(this.group.children[2].material.map, (this.x+2*this.kx) / 2320 / (this.x+2*this.kx) , this.dy/145/this.dy)
        this.group.children[2].material.needsUpdate = true;

        this.group.children[3].material.map.dispose()
        this.group.children[3].material.map = newTexture.clone()
        this.textureSettings(this.group.children[3].material.map, (this.x+2*this.kx) / 2320 / (this.x+2*this.kx) , this.dy/145/this.dy)
        this.group.children[3].material.needsUpdate = true;
      } else {
        // loop 2 times
        for(let i = 2; i < 4; i++) {
          if(i === 3 && this.modelType.isKapschuur && !this.oKEnabled) {
            this.group.children[i].material[0].map.dispose()
            this.group.children[i].material[1].map.dispose()
            this.group.children[i].material[3].map.dispose()
            this.group.children[i].material[5].map.dispose()
            this.group.children[i].material[2].map.dispose()
            this.group.children[i].material[4].map.dispose()
            this.group.children[i].material[0].map = newTexture.clone()
            this.group.children[i].material[1].map = this.group.children[i].material[0].map
            this.group.children[i].material[2].map = newTexture.clone()
            this.group.children[i].material[3].map = this.group.children[i].material[2].map
            this.group.children[i].material[4].map = this.group.children[i].material[2].map
            this.group.children[i].material[5].map = this.group.children[i].material[2].map
            this.textureSettings(this.group.children[i].material[0].map,  this.dz/2320,  this.dy/145, Math.PI)
            this.textureSettings(this.group.children[i].material[2].map,  this.x/2320,  this.dy/145, 2*Math.PI, 0.7)
            this.group.children[i].material[0].needsUpdate = true;
            this.group.children[i].material[1].needsUpdate = true;
          } else {
            this.group.children[i].material[0].map.dispose()
            this.group.children[i].material[1].map.dispose()
            this.group.children[i].material[3].map.dispose()
            this.group.children[i].material[5].map.dispose()
            this.group.children[i].material[2].map.dispose()
            this.group.children[i].material[4].map.dispose()
            this.group.children[i].material[0].map = newTexture.clone()
            this.group.children[i].material[1].map = this.group.children[i].material[0].map
            this.group.children[i].material[3].map = newTexture.clone()
            this.group.children[i].material[5].map = newTexture.clone()
            this.group.children[i].material[2].map = newTexture.clone()
            this.group.children[i].material[4].map = this.group.children[i].material[2].map
            this.textureSettings(this.group.children[i].material[0].map,  this.x/2320/this.x,  this.dy/145/this.dy, Math.PI)
            this.textureSettings(this.group.children[i].material[3].map,  this.x/2320/this.x,  this.dy/145/this.dy, 2*Math.PI, 0.7)
            this.textureSettings(this.group.children[i].material[5].map,  this.x/2320/this.x,  this.dy/145/this.dy, 2*Math.PI, 0.4)
            this.textureSettings(this.group.children[i].material[2].map,  this.dz/2320/this.dz/2,  this.dy/145/this.dy/2, Math.PI)
            this.group.children[i].material[0].needsUpdate = true;
            this.group.children[i].material[3].needsUpdate = true;
            this.group.children[i].material[5].needsUpdate = true;
            this.group.children[i].material[2].needsUpdate = true;
          }
        }
      }

      if(this.erOn) {
        const eTextureZ2 = newTexture.clone()
        const eTextureX2 = newTexture.clone()
        this.textureSettings(eTextureZ2,  this.x/2320,  1, Math.PI)
        this.textureSettings(eTextureX2,  this.ery/2320,  this.erz/145,  0.5*Math.PI)
        const eTextureZGording2 = newTexture.clone()
        this.textureSettings(eTextureZGording2,  this.x / 2330 / this.x , this.gy / 145 / this.gy)

        let forEachIndex = 0
        if (this.erGording) {
          this.group.children[6].material.map.dispose()
          this.group.children[6].material.map = eTextureZGording2
          this.group.children[6].material.map.needsUpdate = true;
          this.group.children[6].material.needsUpdate = true;
        } else {
          this.group.children[6].material.forEach(material => {
            material.map.dispose()
            if (forEachIndex < 2) {
              material.map = eTextureX2
            } else {
              material.map = eTextureZ2
            }
            material.map.needsUpdate = true;
            material.needsUpdate = true;
            forEachIndex++
          })
        }
      }
    })
  }

  buffer (z, dy, dz) {
    return [
      z, 0, 0,
      0, 0, 0,
      0, dy / 2, 0,

      z - dz, dy / 2, 0,
      z, dy / 2, 0,
      z, 0, 0,

      dz, dy, 0,
      z - dz, dy, 0,
      z - dz, dy / 2, 0,

      z, 0, 0,
      0, dy / 2, 0,
      dz, dy / 2, 0,

      dz, dy, 0,
      z - dz, dy / 2, 0,
      z, 0, 0,

      z, 0, 0,
      dz, dy / 2, 0,
      dz, dy, 0,

      0, dy / 2, dz,
      0, 0, dz,
      z, 0, dz,

      z, 0, dz,
      z, dy / 2, dz,
      z - dz, dy / 2, dz,

      z - dz, dy / 2, dz,
      z - dz, dy, dz,
      dz, dy, dz,

      dz, dy / 2, dz,
      0, dy / 2, dz,
      z, 0, dz,

      z, 0, dz,
      z - dz, dy / 2, dz,
      dz, dy, dz,

      dz, dy, dz,
      dz, dy / 2, dz,
      z, 0, dz,

      z, 0, 0,
      z, dy / 2, 0,
      z, 0, dz,

      z, dy / 2, 0,
      z, dy / 2, dz,
      z, 0, dz,

      z, dy / 2, 0,
      z - dz, dy / 2, 0,
      z, dy / 2, dz,

      z - dz, dy / 2, 0,
      z - dz, dy / 2, dz,
      z, dy / 2, dz,

      z - dz, dy / 2, 0,
      z - dz, dy, 0,
      z - dz, dy / 2, dz,

      z - dz, dy, 0,
      z - dz, dy, dz,
      z - dz, dy / 2, dz,

      z - dz, dy, 0,
      dz, dy, 0,
      z - dz, dy, dz,

      dz, dy, 0,
      dz, dy, dz,
      z - dz, dy, dz,

      dz, dy, 0,
      dz, dy / 2, 0,
      dz, dy, dz,

      dz, dy / 2, 0,
      dz, dy / 2, dz,
      dz, dy, dz,

      dz, dy / 2, 0,
      0, dy / 2, 0,
      dz, dy / 2, dz,

      0, dy / 2, 0,
      0, dy / 2, dz,
      dz, dy / 2, dz,

      0, dy / 2, 0,
      0, 0, 0,
      0, dy / 2, dz,

      0, 0, 0,
      0, 0, dz,
      0, dy / 2, dz,

      0, 0, 0,
      z, 0, 0,
      0, 0, dz,

      z, 0, 0,
      z, 0, dz,
      0, 0, dz
    ]
  }

  uvBuffer (z, dy, dz) {
    return [ z, 0, 0, 0, 0, dy / 2, z - dz, dy / 2, z, dy / 2, z, 0, dz, dy, z - dz, dy, z - dz, dy / 2, z, 0, 0, dy / 2, dz, dy / 2, dz, dy, z - dz, dy / 2, z, 0, z, 0, dz, dy / 2, dz, dy, 0, dy / 2, 0, 0, z, 0, z, 0, z, dy / 2, z - dz, dy / 2, z - dz, dy / 2, z - dz, dy, dz, dy, dz, dy / 2, 0, dy / 2, z, 0, z, 0, z - dz, dy / 2, dz, dy, dz, dy, dz, dy / 2, z, 0, 0, 1, dy / 2, 1, 0, 1 - dz, dy / 2, 1, dy / 2, 1 - dz, 0, 1 - dz, z, 1, z - dz, 1, z, 1 - dz, z - dz, 1, z - dz, 1 - dz, z, 1 - dz, dy / 2, 1, dy, 1, dy / 2, 1 - dz, dy, 1, dy, 1 - dz, dy / 2, 1 - dz, z - dz, 1, dz, 1, z - dz, 1 - dz, dz, 1, dz, 1 - dz, z - dz, 1 - dz, dy, 1, dy / 2, 1, dy, 1 - dz, dy / 2, 1, dy / 2, 1 - dz, dy, 1 - dz, dz, 1, 0, 1, dz, 1 - dz, 0, 1, 0, 1 - dz, dz, 1 - dz, dy / 2, 1, 0, 1, dy / 2, 1 - dz, 0, 1, 0, 1 - dz, dy / 2, 1 - dz, 0, 1, z, 1, 0, 1 - dz, z, 1, z, 1 - dz, 0, 1 - dz ]
  }

  normalBuffer () {
    return [ 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0 ]
  }

  groupBuffer () {
    return [
      {
        start: 0,
        count: 18,
        materialIndex: 0
      },
      {
        start: 18,
        count: 18,
        materialIndex: 1
      },
      {
        start: 36,
        count: 18,
        materialIndex: 2
      },
      {
        start: 54,
        count: 6,
        materialIndex: 3
      },
      {
        start: 60,
        count: 18,
        materialIndex: 4
      },
      {
        start: 78,
        count: 6,
        materialIndex: 5
      }
    ]
  }

  textureSettings(texture, repeatX, repeatY, rotation, offsetX, offsetY) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    texture.needsUpdate = true;
    texture.offset.set(offsetX !== undefined ? offsetX : 0, offsetY !== undefined ? offsetY : 0)
  }
}