import * as errorHandeling from '@/functions/errorHandeling.js'

// format for a standard graphQL fetch request 
async function fetchGraphQL (context, graphqlQuery, token) {
  let response
  let headers

  if (token) {
    headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  } else {
    headers = {
      'Content-Type': 'application/json'
    }
  }

  try {
    response = await fetch(import.meta.env.VITE_VUE_APP_GRAPHQL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(graphqlQuery)
    })
  } catch (error) {
    response = {
      errors: [
        error
      ]
    }

    errorHandeling.throwError(context,response, 'Failed to make connection with the server.')
  }

  const responseData = await response.json()

  return responseData
}

// Function to turn an object in to a string without setting property names bewteen quotes "", this is neccesary if the graphQL input is dynamic in size
function printObject (object) {
  let output = ''

  for (var property in object) {

    if (typeof object[property] === 'object' && !Array.isArray(object[property])) {

      output += property + ': {' + printObject(object[property]) + '},'

    } else if (typeof object[property] === 'object' && Array.isArray(object[property])) {

      output += property + ': ['

      for (let i = 0; i < object[property].length; i++) {

        if (typeof object[property][i] === 'object' && !Array.isArray(object[property][i])) { 

          output += '{' + printObject(object[property][i]) + '},'

        } else {

          output += object[property][i]

        }
      }

      output += ']'

    } else {

      output += property + ':' + JSON.stringify(object[property]) + ','

    }
  }

  return output
}

export { fetchGraphQL, printObject }