import { client, clean } from "./graphqlClient"
import store from '../store';

const saveModelConfigQuery = `
mutation SaveModelConfig($config: ModelConfig) {
  saveModelConfig(config: $config)
}
`

const getModelConfigQuery = `
query GetModelConfig($id: String) {
  getModelConfig(id: $id) {
    type
    typeId
    model
    modelId
    fundering
    afmetingen {
      breedte
      diepte
    }
    frameKleur {
      name
      url
      previewURL
      id
      colorId
    }
    boeideelKleur {
      name
      url
      previewURL
      id
      colorId
    }
    hoekprofielKleur {
      name
      url
      previewURL
      id
      colorId
    }
    trimColor {
      name
      url
      textureType
      plankType
      color
      locations
      scalingX
      scalingY
      emissive
      emissiveColor
      metalness
      order
    }
    selectedDakAfwerking {
      id
      name
      previewURL
      url
      color
      textureType
      plankType
      locations
      scalingX
      scalingY
      emissive
      emissiveColor
      metalness
      order
    }
    muren {
      binnenMuur
      binnenMuurTexture {
        name
        url
        previewURL
        hex
        id
        plankType
        open
      }
      buitenMuur
      buitenMuurTexture {
        name
        url
        previewURL
        hex
        id
        plankType
        open
      }
      connectionBinnen
      connectionBuiten
      modelArray {
        lastCode
        code
        color {
          code
          componentName
          hexCode
          name
        }
        option {
          name
          subCode
        }
        side
      }
      width
      rotation
      position {
        x
        y
        z
      }
      tussenMuur
      uuid
      Puuid
      Cuuid
      TPuuid
      TCuuid
      isSteellook
      isSlidingWall
      schuifwandKleur {
        code
        componentName
        hexCode
        name
      }
      schuifwandRichting
      schuifwandPreference
      modelCode
      hasBetonBand
      isBorstwering
      heeftIsolatie
      isolatieId
    }
    topgevels {
      heeftIsolatie
      isolatieId
      binnenMuur
      binnenMuurTexture {
        name
        url
        previewURL
        hex
        id
        plankType
        open
        isolatieId
      }
      buitenMuur
      buitenMuurTexture {
        name
        url
        previewURL
        hex
        id
        plankType
        open
        isolatieId
      }
    }
    dakgoot {
      front {
        enabled
        left
        right
      }
      back {
        enabled
        left
        right
      }
    }
    lichtstraten {
      code
      positionFront
      positionLeft
    }
    opties {
      code
      aantal
    }
    insulation
    overstek {
      voEnabled
      acEnabled
      liEnabled
      reEnabled
    }
  }
}
`

async function saveModelConfig(config) {
  // filter options where aantal > 0
  config.opties = config.opties.filter(o => o.aantal > 0)
  // clean temp settings in options
  for (let i = 0; i < config.opties.length; i++) {
    const option = config.opties[i];
    if (option.editedColors) delete option.editedColors;
    if (option.category) delete option.category;
  }

  console.log("SAVE MODEL CONFIG", config)
  console.log(saveModelConfigQuery, { config })
  const newConfig = JSON.parse(JSON.stringify(config))
  console.log("newConfig", newConfig)
  const breedte = config.afmetingen.breedte
  const diepte = config.afmetingen.diepte
  newConfig.afmetingen = {breedte, diepte}
  delete newConfig.roofFinishes
  delete newConfig.wallFinishes
  delete newConfig.wall
  delete newConfig.maatwerk
  delete newConfig.standaardmatenDisabled
  // delete unwanted properties in topgevels array
  const topgevelArray = newConfig.topgevels;
  for (let i = 0; i < topgevelArray.length; i++) {
    const item = topgevelArray[i];
    delete item.group;
    delete item.mapGroup;    
    delete item.mapTopIsoMainGroup;
    delete item.hoverTopIsoMainGroup;
  }

  // set overstek config
  newConfig.overstek = store.getters.getOverstekConfig

  console.log(newConfig)
  const result = await client.mutation(saveModelConfigQuery, { config: newConfig }).toPromise()
  console.log(result)
  return {
    data: result.data.saveModelConfig,
    error: result.error,
  }
}

async function getModelConfig(id) {
  const result = await client.query(getModelConfigQuery, { id }).toPromise()
  return {
    data: clean(result.data.getModelConfig),
    error: result.error,
  }
}

export { saveModelConfig, getModelConfig }