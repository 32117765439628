const cs = 7

// defines position of the vertices
function positionBuffer (width, height, depth) {
  const a = -width / 2
  const b = -height / 2
  const c = -depth / 2

  const d = width / 2
  const e = height / 2
  const f = depth / 2

  const g = b + cs
  const h = e - cs

  const i = c + cs
  const j = f - cs

  const k = cs
  const l = -cs

  return [
    // Front
    d, h, f,  d, e, j,  a, e, j, // 1
    a, h, f,  a, e, j,  d, h, f,
    d, k, f,  d, h, f,  a, h, f, // 2
    a, k, f,  a, h, f,  d, k, f,
    d, 0, j,  d, k, f,  a, k, f, // 3
    a, 0, j,  a, k, f,  d, 0, j,
    a, l, f,  a, 0, j,  d, 0, j, // 4
    d, l, f,  d, 0, j,  a, l, f,
    a, g, f,  a, l, f,  d, l, f, // 5
    d, g, f,  d, l, f,  a, g, f,
    a, b, j,  a, g, f,  d, g, f, // 6
    d, b, j,  d, g, f,  a, b, j,


    // Bottom
    a, b, i,  a, b, j,  d, b, j,
    d, b, i,  d, b, j,  a, b, i,


    // Back
    a, h, c,  a, e, i,  d, e, i, // 1
    d, h, c,  d, e, i,  a, h, c,
    a, k, c,  a, h, c,  d, h, c, // 2
    d, k, c,  d, h, c,  a, k, c,
    a, 0, i,  a, k, c,  d, k, c, // 3
    d, 0, i,  d, k, c,  a, 0, i,
    d, l, c,  d, 0, i,  a, 0, i, // 4 
    a, l, c,  a, 0, i,  d, l, c,
    d, g, c,  d, l, c,  a, l, c, // 5
    a, g, c,  a, l, c,  d, g, c,
    d, b, i,  d, g, c,  a, g, c, // 6
    a, b, i,  a, g, c,  d, b, i,


    // Top
    a, e, j,  a, e, i,  d, e, i,
    d, e, j,  d, e, i,  a, e, j,


    // Left
    a, e, i,  a, e, j,  a, h, f, // 1
    a, h, c,  a, h, f,  a, e, i,
    a, h, c,  a, h, f,  a, k, f, // 2
    a, k, c,  a, k, f,  a, h, c,
    a, k, c,  a, k, f,  a, 0, j, // 3
    a, 0, i,  a, 0, j,  a, k, c,
    a, 0, i,  a, 0, j,  a, l, f, // 4
    a, l, c,  a, l, f,  a, 0, i,
    a, l, c,  a, l, f,  a, g, f, // 5
    a, g, c,  a, g, f,  a, l, c,
    a, g, c,  a, g, f,  a, b, j, // 6
    a, b, i,  a, b, j,  a, g, c,

    
    // Right
    d, e, j,  d, e, i,  d, h, c, // 1
    d, h, f,  d, h, c,  d, e, j,
    d, h, f,  d, h, c,  d, k, c, // 2
    d, k, f,  d, k, c,  d, h, f,
    d, k, f,  d, k, c,  d, 0, i, // 3
    d, 0, j,  d, 0, i,  d, k, f,
    d, 0, j,  d, 0, i,  d, l, c, // 4
    d, l, f,  d, l, c,  d, 0, j,
    d, l, f,  d, l, c,  d, g, c, // 5
    d, g, f,  d, g, c,  d, l, f,
    d, g, f,  d, g, c,  d, b, i, // 6
    d, b, j,  d, b, i,  d, g, f,
  ]
}


// defines position of the textures
function uvBuffer (width, height, depth) { 

  const y = height 
  const z = depth 



  const a = 0
  const b = 1
  const c = Math.sqrt((cs * cs + cs * cs) / 2) / y
  const d = 0.5 - c * 2

  // Front / Back
  const e = c + d
  const f = e + c
  const g = f + c
  const h = g + d
  const i = h + c

  // Top / Bottom
  const j = (z / y) * 1 - c * 2

  // Left / Right
  const k = 0.2
  const k2 = 0.1
  const k3 = 0.7
  const l = cs / depth * 0.2
  const m = k - l
  const n = c * k2
  const o = e * k2
  const p = f * k2
  const q = f * k2 + k3
  const r = g * k2 + k3
  const s = h * k2 + k3
  const t = i * k2 + k3

  return [
    // Front
    a, h, 0,  a, i, 0,  b, i, 0,
    b, h, 0,  b, i, 0,  a, h, 0,
    a, g, 0,  a, h, 0,  b, h, 0,
    b, g, 0,  b, h, 0,  a, g, 0,
    a, f, 0,  a, g, 0,  b, g, 0,
    b, f, 0,  b, g, 0,  a, f, 0,
    a, e, 0,  a, f, 0,  b, f, 0,
    b, e, 0,  b, f, 0,  a, e, 0,
    a, c, 0,  a, e, 0,  b, e, 0,
    b, c, 0,  b, e, 0,  a, c, 0,
    a, 0, 0,  a, c, 0,  b, c, 0,
    b, 0, 0,  b, c, 0,  a, 0, 0,

    // Bottom
    a, 0, 0,  a, j, 0,  b, j, 0,
    b, 0, 0,  b, j, 0,  a, 0, 0,

    // Back
    a, h, 0,  a, i, 0,  b, i, 0,
    b, h, 0,  b, i, 0,  a, h, 0,
    a, g, 0,  a, h, 0,  b, h, 0,
    b, g, 0,  b, h, 0,  a, g, 0,
    a, f, 0,  a, g, 0,  b, g, 0,
    b, f, 0,  b, g, 0,  a, f, 0,
    a, e, 0,  a, f, 0,  b, f, 0,
    b, e, 0,  b, f, 0,  a, e, 0,
    a, c, 0,  a, e, 0,  b, e, 0,
    b, c, 0,  b, e, 0,  a, c, 0,
    a, 0, 0,  a, c, 0,  b, c, 0,
    b, 0, 0,  b, c, 0,  a, 0, 0,

    // Top
    a, 0, 0,  a, j, 0,  b, j, 0,
    b, 0, 0,  b, j, 0,  a, 0, 0,

    // Left
    0, l, 0,  0, m, 0,  n, k, 0,
    n, 0, 0,  n, k, 0,  0, l, 0,
    n, 0 ,0,  n, k, 0,  o, k, 0,
    o, 0 ,0,  o, k, 0,  n, 0, 0,
    o, 0, 0,  o, k, 0,  p, m, 0,
    p, l, 0,  p, m, 0,  o, 0, 0,
    q, l, 0,  q, m, 0,  r, k, 0,
    r, 0, 0,  r, k, 0,  q, l, 0,
    r, 0 ,0,  r, k, 0,  s, k, 0,
    s, 0 ,0,  s, k, 0,  r, 0, 0,
    s, 0, 0,  s, k, 0,  t, m, 0,
    t, l, 0,  t, m, 0,  s, 0, 0,

    // Right
    0, l, 0,  0, m, 0,  n, k, 0,
    n, 0, 0,  n, k, 0,  0, l, 0,
    n, 0 ,0,  n, k, 0,  o, k, 0,
    o, 0 ,0,  o, k, 0,  n, 0, 0,
    o, 0, 0,  o, k, 0,  p, m, 0,
    p, l, 0,  p, m, 0,  o, 0, 0,
    q, l, 0,  q, m, 0,  r, k, 0,
    r, 0, 0,  r, k, 0,  q, l, 0,
    r, 0 ,0,  r, k, 0,  s, k, 0,
    s, 0 ,0,  s, k, 0,  r, 0, 0,
    s, 0, 0,  s, k, 0,  t, m, 0,
    t, l, 0,  t, m, 0,  s, 0, 0,
  ]
}

// group the vertices belonging to each of the 6 sides, allows for 6 different textures one for each side.
function groupBuffer () {
  return [
    {
      start: 0,
      count: 36,
      materialIndex: 0
    },
    {
      start: 36,
      count: 6,
      materialIndex: 1
    },
    {
      start: 42,
      count: 36,
      materialIndex: 2
    },
    {
      start: 78,
      count: 6,
      materialIndex: 3
    },
    {
      start: 84,
      count: 36,
      materialIndex: 4
    },
    {
      start: 120,
      count: 36,
      materialIndex: 5
    }
  ]
}

export { positionBuffer, uvBuffer, groupBuffer }