// Aanmaken van een nieuwe class genaamd Betonpoer
export default class Betonpoer {
  constructor(THREE, betonpoerConfig, fundering) {
    this.THREE = THREE,
    this.lengte = betonpoerConfig.z,
    this.breedte = betonpoerConfig.z,  
    this.hoogte = betonpoerConfig.y,
    this.kleurBetonPoer = { color: 0x242323 },
    this.fundering = fundering
    this.betonPoer = null,
    // console.log(betonpoerConfig);
    this.buildBetonPoer(this.THREE, this.breedte, this.lengte, this.hoogte, this.kleurBetonPoer, fundering)
  }

  // Opbouw van de geometrie van een betonpoer  
  buildBetonPoer(THREE, lengte, breedte, hoogte, kleurBetonPoer, fundering) {
    const geometrie = new THREE.BoxGeometry(lengte, breedte, hoogte);
    const material = new THREE.MeshStandardMaterial(kleurBetonPoer);
    const Poer = new THREE.Mesh(geometrie, material);
    Poer.visible = fundering
    Poer.castShadow = true; 
    Poer.receiveShadow = true;
    this.betonPoer = Poer;
  }

  updateBetonPoer(betonpoerConfig) {
    console.log(betonpoerConfig)
    this.lengte = betonpoerConfig.z,
    this.breedte = betonpoerConfig.z,
    this.hoogte = betonpoerConfig.y,
    console.log(new this.THREE.BoxGeometry(this.lengte, this.breedte, this.hoogte).attributes.position.array)
    this.betonPoer.geometry.attributes.position.array = new this.THREE.BoxGeometry(this.lengte, this.breedte, this.hoogte).attributes.position.array
    this.betonPoer.geometry.attributes.position.needsUpdate = true
    this.betonPoer.geometry.attributes.uv.array = new this.THREE.BoxGeometry(this.lengte, this.breedte, this.hoogte).attributes.uv.array
    this.betonPoer.geometry.attributes.uv.needsUpdate = true
  }

  // Functie om een betonPoer zichtbaar te maken
  betonPoerShow() {
    this.betonPoer.visible = true
  } 

  // Functie om een betonPoer niet zichtbaar te maken
  betonPoerHide() {
    this.betonPoer.visible = false
  }
}