import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'

export default class Betonband {
  constructor(THREE, breedte) {
    this.THREE = THREE, 
    this.lengte = 60,
    this.breedte = breedte,
    this.hoogte = 200,
    this.kleurBetonBand = { color: 0x242323 },
    this.betonBand = null,
    this.buildBetonBand(this.THREE, this.breedte, this.hoogte, this.lengte, this.kleurBetonBand);
  }

  buildBetonBand(THREE, breedte, hoogte, lengte, kleurBetonBand) {

    const geometrie = new THREE.BufferGeometry()
    geometrie.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(breedte, hoogte, lengte)), 3))
    geometrie.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
    geometrie.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
    geometrie.setIndex(boxBuffer.indexBuffer());
    geometrie.groups = boxBuffer.groupBuffer()

    const material = new THREE.MeshStandardMaterial(kleurBetonBand);
    const betBand = new THREE.Mesh(geometrie, material);
    betBand.visible = false; // Hide betonbanden
    betBand.castShadow = true; 
    betBand.receiveShadow = true;
    this.betonBand = betBand;
    this.betonBand.position.set(breedte / 2, -hoogte / 2, 0)
    // console.log(breedte / 2, -hoogte / 2, 0)
    
  }
  
  update(breedte) {
    this.breedte = breedte;
    const geometrie = new this.THREE.BufferGeometry()
    geometrie.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.breedte, this.hoogte, this.lengte)), 3))
    geometrie.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
    geometrie.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
    geometrie.setIndex(boxBuffer.indexBuffer());
    geometrie.groups = boxBuffer.groupBuffer()
    this.betonBand.geometry = geometrie;
    this.betonBand.position.set(this.breedte / 2, -this.hoogte / 2, 0)
  }

  // Functie om een betonband zichtbaar te maken
  betonBandShow() {
    this.betonBand.visible = true
  }

  // Functie om een betonband niet zichtbaar te maken
  betonBandHide() {
    this.betonBand.visible = false
  }


}