import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
import * as planeBuffer from '@/three/buffer-geometries/plane.js'
import * as triangleBuffer from '@/three/buffer-geometries/triangleBuffer.js'
import * as planeBorderBuffer from '@/three/buffer-geometries/planeBorder.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import Betonpoer from './betonPoer.js'

export default class Staanders {
  constructor (THREE, scene, textureLoader, x, y, z, division, dx, dz, texture, fundering, maximaleMuurBreedte, totalOverhang, op, bz, kx, kp, bPos, topgevelSpace, poer, modelType, kapschuurConfig) {
    this.THREE = THREE
    this.scene = scene
    this.x = x
    this.y = y
    this.z = z
    this.division = division
    this.dx = dx // staanderbreedte
    this.dz = dz // staanderdikte/breedte
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.texture = texture
    this.fundering = fundering
    this.group = null
    this.mapGroup = null
    this.staander0 = null
    this.mapStaander0 = null
    this.nStaandersX
    this.nStaandersZ
    this.staandersX
    this.staandersZ
    this.maxOverspanning = maximaleMuurBreedte // 3480
    this.totalOverhang = totalOverhang
    this.op = op
    this.bz = bz
    this.kx = kx
    this.kp = kp
    this.bPos = bPos
    this.topgevelSpace = topgevelSpace
    this.poer = poer
    this.modelType = modelType
    this.kapschuurConfig = kapschuurConfig
    this.helper = null
    this.box = null
    this.vector = null
    // betonpoer in constuctor function gezet
    this.Betonpoer = null
    this.staanderInfoArray = []
    this.bouw(THREE, textureLoader, x, y, z, division, dx, dz, texture, fundering, totalOverhang, op, bz, kx, kp, bPos, poer)
  }

  bouw (THREE, textureLoader, x, y, z, division, dx, dz, texture, fundering, totalOverhang, op, bz, kx, kp, bPos, poer) {
    console.log("RETOURBUG:", THREE, textureLoader, x, y, z, division, dx, dz, texture, fundering, totalOverhang, op, bz, kx, kp, bPos, poer)
    // console.log(x)
    // console.log(y)
    // console.log(z)
    // console.log(dx)
    // console.log(dz)
    
    // const geometry = new THREE.BoxGeometry( dx, y, dz + 50 );

    // console.log(geometry)

    // const textureZ = textureLoader.load(`http://localhost:8080/textures/${texture}`)
    // textureZ.repeat.x = 0.0043
    // textureZ.repeat.y = 0.0043
    // textureZ.wrapS = THREE.RepeatWrapping
    // textureZ.wrapT = THREE.RepeatWrapping
    // textureZ.encoding = THREE.sRGBEncoding

    // const material = new THREE.MeshStandardMaterial({ map: textureZ })

    // // const material = new this.THREE.MeshStandardMaterial( { color: 0xff0000 } );
    // material.wireframe = true
    // const staander = new this.THREE.Mesh( geometry, material );
    // console.log(staander)
    // staander.position.x = 500

    // geometry
    const staanderVoorGeometry = new THREE.BufferGeometry()
    staanderVoorGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(dx, y, dz)), 3))
    staanderVoorGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer(dx, y, dz)), 2))
    staanderVoorGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    staanderVoorGeometry.setIndex(boxBuffer.indexBuffer());
    staanderVoorGeometry.groups = boxBuffer.groupBuffer()

    const staanderAchterGeometry = new THREE.BufferGeometry()
    staanderAchterGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(dx, (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y), dz)), 3))
    staanderAchterGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer(dx, (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y), dz)), 2))
    staanderAchterGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    staanderAchterGeometry.setIndex(boxBuffer.indexBuffer());
    staanderAchterGeometry.groups = boxBuffer.groupBuffer()

    // texture
    const textureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureX,  y / 2320,  dz / 145,  0.5 * Math.PI)
    const materialX = new THREE.MeshStandardMaterial({ map: textureX, metalness: 0, roughness: 1})

    const textureY = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureY, dx / 2320,  dz / 145, 0.5 * Math.PI)
    const materialY = new THREE.MeshStandardMaterial({ map: textureY, metalness: 0, roughness: 1})

    const textureZ = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureZ,  y / 2320,  dz / 145,  1.5 * Math.PI, 0.7)
    const materialZ = new THREE.MeshStandardMaterial({ map: textureZ, metalness: 0, roughness: 1})    

    const textureXAchter = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureXAchter,  (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y) / 2320,  dz / 145,  0.5 * Math.PI)
    const materialXAchter = new THREE.MeshStandardMaterial({ map: textureXAchter, metalness: 0, roughness: 1})

    const textureZAchter = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureZAchter,  (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y) / 2320,  dz / 145,  1.5 * Math.PI, 0.7)
    const materialZAchter = new THREE.MeshStandardMaterial({ map: textureZAchter, metalness: 0, roughness: 1})

    // mesh
    const staanderVoor0 = new THREE.Mesh( staanderVoorGeometry, [
      materialX,
      materialX,
      materialY,
      materialY,
      materialZ,
      materialZ,
    ]);
    staanderVoor0.castShadow = true; 
    staanderVoor0.receiveShadow = true;
    staanderVoor0.position.x = dx / 2
    staanderVoor0.position.y = y / 2
    staanderVoor0.position.z = dz / 2

    const staanderAchter0 = new THREE.Mesh( staanderAchterGeometry, [
      materialXAchter,
      materialXAchter,
      materialY,
      materialY,
      materialZAchter,
      materialZAchter,
    ]);
    staanderAchter0.castShadow = true; 
    staanderAchter0.receiveShadow = true;
    staanderAchter0.position.x = dx / 2
    staanderAchter0.position.y = (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y) / 2
    staanderAchter0.position.z = dz / 2

    const geometry2 = new THREE.BufferGeometry()
    geometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBorderBuffer.positionBuffer(dx, dz, 7)), 3))
    geometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBorderBuffer.uvBuffer()), 2))
    geometry2.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBorderBuffer.normalBuffer()), 3))
    geometry2.groups = planeBorderBuffer.groupBuffer()
    geometry2.translate( -dx / 2, 0, -dz / 2 );

    const mapTexture = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(mapTexture,  dx/2320,  dz/145,  0.5 * Math.PI)
    const mapMaterial = new THREE.MeshStandardMaterial({ map: mapTexture })
    const mapMaterialOutline = this.shaderMaterial('#000000')

    const mapStaander0 = new THREE.Mesh( geometry2, [mapMaterialOutline, mapMaterial] );
    mapStaander0.position.x = dx / 2
    mapStaander0.position.y = y / 2
    mapStaander0.position.z = dz / 2

    this.betonPoer = new Betonpoer(this.THREE, poer, fundering);

    this.staanderInfoArray.push({mesh: staanderVoor0, x:dx, y:y, z:dz})


    this.staanderVoor0 = staanderVoor0
    this.staanderAchter0 = staanderAchter0
    this.mapStaander0 = mapStaander0

    // const helperGeomtery = new THREE.BoxGeometry(5000, 50, 50)
    // const helperMaterial = new THREE.MeshStandardMaterial( {color: 0x00ff00} );
    // helperMaterial.wireFrame = true
    // const helper = new THREE.Mesh( helperGeomtery, helperMaterial );

    // this.helper = helper
    
    // this.scene.add(this.helper)


    // corners
    //
    // 3   2
    //
    // 0   1
    //
    // numberd around the clock from the absolute zero point

    const staanderVoor1 = staanderVoor0.clone()
    const staanderAchter1 = staanderAchter0.clone()

    this.staanderInfoArray.push({mesh: staanderVoor1, x:dx, y:y, z:dz},{mesh: staanderAchter0, x:dx, y:(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y), z:dz},{mesh: staanderAchter1, x:dx, y:(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y), z:dz})

    staanderVoor1.position.z = x - (dz / 2)
    staanderAchter0.position.z = staanderVoor1.position.z
    staanderAchter0.position.x = z - (dx / 2)
    staanderAchter1.position.x = staanderAchter0.position.x

    const mapStaander1 = mapStaander0.clone()
    const mapStaander2 = mapStaander0.clone()
    const mapStaander3 = mapStaander0.clone()

    mapStaander1.position.z = x - (dz / 2)
    mapStaander2.position.z = mapStaander1.position.z
    mapStaander2.position.x = z - (dx / 2)
    mapStaander3.position.x = mapStaander2.position.x

    const omlijstingGroup = new THREE.Group()
    if(op) {
      if(kp.l&&kp.v) {
        //back left
        let omlijsting1 = this.meshCreation (THREE, textureLoader, bPos, dz+kx, bz, texture)
        omlijsting1.rotation.x=Math.PI*0.5
        omlijsting1.rotation.y=Math.PI*0.5
        omlijsting1.position.y=bPos/2
        omlijsting1.position.x=z+bz/2
        omlijsting1.position.z=dz-((dz+kx)/2)

        let omlijsting2 = this.meshCreation (THREE, textureLoader, y, kx, bz, texture)
        omlijsting2.rotation.x=Math.PI*0.5
        omlijsting2.rotation.y=Math.PI*0.5
        omlijsting2.position.y=y/2
        omlijsting2.position.x=z-dz+bz/2
        omlijsting2.position.z=-kx/2

        let omlijsting3 = this.meshCreation (THREE, textureLoader, bPos, dz+bz, bz, texture)
        omlijsting3.rotation.z=Math.PI*0.5
        omlijsting3.position.y=bPos/2
        omlijsting3.position.x=z+bz-(dz+bz)/2
        omlijsting3.position.z=-kx-bz/2

        //front right
        let omlijsting4 = this.meshCreation (THREE, textureLoader, bPos, dz+kx, bz, texture)
        omlijsting4.rotation.z=Math.PI*0.5
        omlijsting4.position.y=bPos/2
        omlijsting4.position.x=-kx+(dz+kx)/2
        omlijsting4.position.z=x+bz/2

        let omlijsting5 = this.meshCreation (THREE, textureLoader, y, kx, bz, texture)
        omlijsting5.rotation.z=Math.PI*0.5
        omlijsting5.position.y=y/2
        omlijsting5.position.x=-kx/2
        omlijsting5.position.z=x-dz+bz/2

        let omlijsting6 = this.meshCreation (THREE, textureLoader, bPos, dz+bz, bz, texture)
        omlijsting6.rotation.z=Math.PI*0.5
        omlijsting6.rotation.y=Math.PI*0.5
        omlijsting6.position.y=bPos/2
        omlijsting6.position.x=-kx-bz/2
        omlijsting6.position.z=x+bz-(dz+bz)/2

        //add to group
        omlijstingGroup.add(omlijsting1, omlijsting2, omlijsting3, omlijsting4, omlijsting5, omlijsting6)
      }else if(kp.r&&kp.v) {
        //back right
        let omlijsting1 = this.meshCreation (THREE, textureLoader, bPos, dz+kx, bz, texture)
        omlijsting1.rotation.x=Math.PI*0.5
        omlijsting1.rotation.y=Math.PI*0.5
        omlijsting1.position.y=bPos/2
        omlijsting1.position.x=z+bz/2
        omlijsting1.position.z=x-dz+((dz+kx)/2)

        let omlijsting2 = this.meshCreation (THREE, textureLoader, y, kx, bz, texture)
        omlijsting2.rotation.x=Math.PI*0.5
        omlijsting2.rotation.y=Math.PI*0.5
        omlijsting2.position.y=y/2
        omlijsting2.position.x=z-dz+bz/2
        omlijsting2.position.z=x+kx/2

        let omlijsting3 = this.meshCreation (THREE, textureLoader, bPos, dz+bz, bz, texture)
        omlijsting3.rotation.z=Math.PI*0.5
        omlijsting3.position.y=bPos/2
        omlijsting3.position.x=z+bz-(dz+bz)/2
        omlijsting3.position.z=x+kx+bz/2

        //front right
        let omlijsting4 = this.meshCreation (THREE, textureLoader, bPos, dz+kx, bz, texture)
        omlijsting4.rotation.z=Math.PI*0.5
        omlijsting4.position.y=bPos/2
        omlijsting4.position.x=-kx+(dz+kx)/2
        omlijsting4.position.z=-bz/2

        let omlijsting5 = this.meshCreation (THREE, textureLoader, y, kx, bz, texture)
        omlijsting5.rotation.z=Math.PI*0.5
        omlijsting5.position.y=y/2
        omlijsting5.position.x=-kx/2
        omlijsting5.position.z=dz-bz/2

        let omlijsting6 = this.meshCreation (THREE, textureLoader, bPos, dz+bz, bz, texture)
        omlijsting6.rotation.z=Math.PI*0.5
        omlijsting6.rotation.y=Math.PI*0.5
        omlijsting6.position.y=bPos/2
        omlijsting6.position.x=-kx-bz/2
        omlijsting6.position.z=-bz+(dz+bz)/2

        //add to group
        omlijstingGroup.add(omlijsting1, omlijsting2, omlijsting3, omlijsting4, omlijsting5, omlijsting6)
      }
    }


    // console.log(mapStaander0);


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const hoekBetonPoerVisual1 = this.betonPoer.betonPoer.clone()
    hoekBetonPoerVisual1.position.y = -y/2 + (-poer.y/2);
    hoekBetonPoerVisual1.rotation.x = Math.PI / 2;
    // hoekBetonPoerVisual1.position.x = this.dx / 2; 
    // hoekBetonPoerVisual1.position.z = this.dz / 2; 

    const hoekBetonPoerVisual2 = this.betonPoer.betonPoer.clone()
    // hoekBetonPoerVisual2.position === staander1.position;
    hoekBetonPoerVisual2.position.y = -y/2 + (-poer.y/2); // -300
    hoekBetonPoerVisual2.rotation.x = Math.PI / 2;
    // hoekBetonPoerVisual2.position.x = this.dx / 2; 
    // hoekBetonPoerVisual2.position.z = this.dz / 2;

    const hoekBetonPoerVisual3 = this.betonPoer.betonPoer.clone()
    // hoekBetonPoerVisual3.position === staander2.position;
    hoekBetonPoerVisual3.position.y = -y/2 + (-poer.y/2); // -300
    hoekBetonPoerVisual3.rotation.x = Math.PI / 2;
    // hoekBetonPoerVisual3.position.x = this.dx / 2; 
    // hoekBetonPoerVisual3.position.z = this.dz / 2;

    const hoekBetonPoerVisual4 = this.betonPoer.betonPoer.clone()
    // hoekBetonPoerVisual4.position === staander3.position;
    hoekBetonPoerVisual4.position.y = -y/2 + (-poer.y/2); // -300
    hoekBetonPoerVisual4.rotation.x = Math.PI / 2;
    // hoekBetonPoerVisual4.position.x = this.dx / 2; 
    // hoekBetonPoerVisual4.position.z = this.dz / 2;
    
    this.arrayBetonPoeren = [hoekBetonPoerVisual1, hoekBetonPoerVisual2, hoekBetonPoerVisual3, hoekBetonPoerVisual4]

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // in between corners
    // maixmum <-- this.maxOverspanning mm --> gap in between staanders

      // n x as
      const nStaandersX = division.length-1 //? division.length-1 : Math.ceil(x / this.maxOverspanning) - 1
      this.nStaandersX = nStaandersX

      const groupX0 = new this.THREE.Group()
      groupX0.position.z = dx 
      
      const mapGroupX0 = new this.THREE.Group()
      mapGroupX0.position.z = dx

      const groupX1 = new this.THREE.Group()
      groupX1.position.z = x - dx
      groupX1.position.x = z - dz

      const mapGroupX1 = new this.THREE.Group()
      mapGroupX1.position.z = x - dx
      mapGroupX1.position.x = z - dz

      if (nStaandersX > 0) {

        // staanders x
        //
        // 3  <-- x1   2
        //
        // 0  x0 -->  1

        //const overspanning = (x - ((nStaandersX + 2) * dz)) / ( nStaandersX + 1)
        let overspanningVoor = - dx / 2;
        for (let i = 0; i < nStaandersX; i++) {
          overspanningVoor += division[i].width
          overspanningVoor += dx
          //only if connecting walls bergingenabled are not the same
          if(division[i].bergingEnabled !== division[i+1].bergingEnabled) {
            console.log("breedte", division[i].width)
            
            const staander = staanderVoor0.clone()
            const BetonPoerVisualLoopX = this.betonPoer.betonPoer.clone(); 
            staander.position.z = overspanningVoor
            BetonPoerVisualLoopX.position.y = -y/2 + (-poer.y/2); 
            staander.add(BetonPoerVisualLoopX)
            groupX0.add(staander);
            this.staanderInfoArray.push({mesh: staander, x:dx, y:y, z:dz})
            this.arrayBetonPoeren.push(BetonPoerVisualLoopX)
    
            BetonPoerVisualLoopX.rotation.x = Math.PI / 2;
            BetonPoerVisualLoopX.position.y = -y/2 + (-poer.y/2);

            const mapStaander = mapStaander0.clone()
            mapStaander.position.z = staander.position.z
            mapGroupX0.add(mapStaander)
          }
        }


        // achterstevore for-loop genereert staanders achterzijde
        let overspanningAchter = - dx / 2;
        for (let i = nStaandersX; i > 0; i--) {
          console.log("breedte", division[i].width)
          overspanningAchter += division[i].width
          overspanningAchter += dx
          //only if connecting walls bergingenabled are not the same
          if(division[i].bergingEnabled !== division[i-1].bergingEnabled) {
            const staander = staanderVoor0.clone()
            const BetonPoerVisualLoopX = this.betonPoer.betonPoer.clone(); 
            staander.position.z = (overspanningAchter)*-1
            BetonPoerVisualLoopX.position.y = -y/2 + (-poer.y/2); 
            staander.add(BetonPoerVisualLoopX)
            groupX1.add(staander);
            this.staanderInfoArray.push({mesh: staander, x:dx, y:y, z:dz})
            this.arrayBetonPoeren.push(BetonPoerVisualLoopX)
    
            BetonPoerVisualLoopX.rotation.x = Math.PI / 2;
            BetonPoerVisualLoopX.position.y = -y/2 + (-poer.y/2);

            const mapStaander = mapStaander0.clone()
            mapStaander.position.z = staander.position.z
            mapGroupX1.add(mapStaander)
          }
        }
      }

      // const BetonPoerVisualLoopFrontLeft = this.betonPoer.betonPoer.clone();
      // const BetonPoerVisualLoopFrontRight = this.betonPoer.betonPoer.clone();
      // n z as
      const nStaandersZ = Math.ceil((z-this.dz) / (this.maxOverspanning+this.dz)) - 1
      this.nStaandersZ = nStaandersZ

      const groupZ0 = new this.THREE.Group()
      groupZ0.position.x = dz

      const groupZ1 = new this.THREE.Group()
      groupZ1.position.z = x - dx
      groupZ1.position.x = z - dz

      const mapGroupZ0 = new this.THREE.Group()
      mapGroupZ0.position.x = dz

      const mapGroupZ1 = new this.THREE.Group()
      mapGroupZ1.position.z = x - dx
      mapGroupZ1.position.x = z - dz

      if (nStaandersZ > 0) {

        // staanders z
        //
        // 3    2
        //      ^
        // z1   z0
        // v 
        // 0    1

        //const overspanning = (z - ((nStaandersZ + 2) * dx)) / ( nStaandersZ + 1)
      

        /*for (let i = 0; i < nStaandersZ; i++) {
          
          const staander = staander0.clone()
          staander.position.x = overspanning * (i + 1) + (i * dz) + dz / 2
          groupZ0.add(staander)
          this.staanderInfoArray.push({mesh: staander, x:dx, y:y, z:dz})
          const mapStaander = mapStaander0.clone()
          staander.position.x = overspanning * (i + 1) + (i * dz) + dz / 2
          mapGroupZ0(mapStaander)

        }

        for (let i = 0; i < nStaandersZ; i++) {

          const staander = staander0.clone()
          staander.position.x = (overspanning * (i + 1) + (i * dz)) * -1 - dz / 2
          groupZ1.add(staander)
          this.staanderInfoArray.push({mesh: staander, x:dx, y:y, z:dz})
          const mapStaander = mapStaander0.clone()
          staander.position.x = (overspanning * (i + 1) + (i * dz)) * -1 - dz / 2
          mapGroupZ1.add(mapStaander)

        }*/
      }

    // group
    const groupCorners = new this.THREE.Group()
    groupCorners.add(staanderVoor0, staanderVoor1, staanderAchter0, staanderAchter1)
    

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Betonpoeren koppelen aan de staanders op de hoeken
    staanderVoor0.add(this.arrayBetonPoeren[0]);
    staanderVoor1.add(this.arrayBetonPoeren[1]);
    this.arrayBetonPoeren[2].position.y = -(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y)/2 + (-poer.y/2);
    this.arrayBetonPoeren[3].position.y = -(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y)/2 + (-poer.y/2);
    staanderAchter0.add(this.arrayBetonPoeren[2]);
    staanderAchter1.add(this.arrayBetonPoeren[3]);
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const group = new this.THREE.Group()
    group.add(groupCorners, groupX0, groupX1, groupZ0, groupZ1, omlijstingGroup) 
    
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Betonpoeren koppelen aan de twee staanders in het midden aan de voorzijde
    // const BetonPoerVisualLoopFrontLeft = this.betonPoer.betonPoer.clone();
    // groupZ0.add(BetonPoerVisualLoopFrontLeft);
    // BetonPoerVisualLoopFrontLeft.position.z = this.maxOverspanning - 927.5;
    // BetonPoerVisualLoopFrontLeft.position.x = dx / 2
    // BetonPoerVisualLoopFrontLeft.position.y = -300
    // BetonPoerVisualLoopFrontLeft.rotation.x = Math.PI / 2;

    // const BetonPoerVisualLoopFrontRight = this.betonPoer.betonPoer.clone();
    // groupZ1.add(BetonPoerVisualLoopFrontRight)
    // BetonPoerVisualLoopFrontRight.position.z = 5322.5;
    // BetonPoerVisualLoopFrontRight.position.x = -(dx / 2);
    // BetonPoerVisualLoopFrontRight.position.y = -300
    // BetonPoerVisualLoopFrontRight.rotation.x = Math.PI / 2;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const mapGroupCorners = new this.THREE.Group()
    mapGroupCorners.add(mapStaander0, mapStaander1, mapStaander2, mapStaander3)

    const mapgroup = new this.THREE.Group()
    mapgroup.add(mapGroupCorners, mapGroupX0, mapGroupX1, mapGroupZ0, mapGroupZ1)

    /*const geometry = new THREE.BoxGeometry( 1000, 1000, 1000 );
    const material = new THREE.MeshStandardMaterial( {color: 0x00ff00} );
    const cube = new THREE.Mesh( geometry, material );
    cube.position.set(0,0,0)
    mapgroup.add( cube );*/
    this.group = group
    // console.log(this.group)
    this.mapGroup = mapgroup
    this.staandersX = groupX1
    this.staandersZ = groupZ1

    //maatlijnen:
    const betweenLines = 200
    const betweenTextAndLine = 20
    const lineWidth = 20
    const alignLineWidth = 10
    const arrowLength = 200
    const arrowWidth = 120

    const arrowGeometry = new THREE.BufferGeometry()
    arrowGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(triangleBuffer.positionBuffer(arrowLength, arrowWidth)), 3))
    arrowGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(triangleBuffer.uvBuffer()), 2))
    arrowGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(triangleBuffer.normalBuffer()), 3))
    const triangleMaterial = this.shaderMaterial('#505051');
    
    const lineGeometry = new THREE.BufferGeometry()
    lineGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(x-arrowLength*2, 20)), 3))
    lineGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    lineGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))



    const arrow0 = new THREE.Mesh( arrowGeometry, triangleMaterial );
    const arrow1 = arrow0.clone()
    const arrow2 = arrow0.clone()
    const arrow3 = arrow0.clone()
    const line0 = new THREE.Mesh( lineGeometry, triangleMaterial );
    // console.log(triangleMaterial)
    const line1 = new THREE.Mesh(new THREE.BufferGeometry().copy(lineGeometry), triangleMaterial);
    line1.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(z-arrowLength*2, lineWidth))
    line1.geometry.attributes.position.needsUpdate = true

    arrow0.rotation.y = 180*Math.PI/180
    arrow0.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1.5+arrowWidth/2,0,arrowLength)
    arrow1.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1.5-arrowWidth/2,0,x-arrowLength)
    line0.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1, 0, arrowLength)
    line1.position.set(z-arrowLength, 0, -totalOverhang.l-betweenLines*2-lineWidth*2 -this.topgevelSpace)
    line1.rotation.y = 270*Math.PI/180

    arrow2.rotation.y = 90*Math.PI/180
    arrow2.position.set(z-arrowLength,0,-totalOverhang.l-betweenLines*2-lineWidth*1.5+arrowWidth/2 -this.topgevelSpace)
    arrow3.rotation.y = 270*Math.PI/180
    arrow3.position.set(arrowLength,0,-totalOverhang.l-betweenLines*2-lineWidth*1.5-arrowWidth/2 -this.topgevelSpace)

    const alignLineGeometry0 = new THREE.BufferGeometry()
    alignLineGeometry0.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(totalOverhang.a+betweenLines*2+lineWidth*1.5+arrowWidth/2, alignLineWidth)), 3))
    alignLineGeometry0.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    alignLineGeometry0.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))

    const alignLineGeometry1 = new THREE.BufferGeometry()
    alignLineGeometry1.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(totalOverhang.l+betweenLines*2+lineWidth*1.5+arrowWidth/2 +this.topgevelSpace, alignLineWidth)), 3))
    alignLineGeometry1.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    alignLineGeometry1.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))

    const alignLine0 = new THREE.Mesh( alignLineGeometry0, triangleMaterial );
    const alignLine1 = alignLine0.clone()
    const alignLine2 = new THREE.Mesh( alignLineGeometry1, triangleMaterial );
    const alignLine3 = alignLine2.clone()

    console.log(totalOverhang)

    alignLine0.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1.5+arrowWidth/2,0,-alignLineWidth)
    alignLine0.rotation.y = 270*Math.PI/180
    alignLine1.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1.5+arrowWidth/2,0,x)
    alignLine1.rotation.y = 270*Math.PI/180
    alignLine2.position.set(z,0,-totalOverhang.l-betweenLines*2-lineWidth*1.5-arrowWidth/2 -this.topgevelSpace)
    alignLine3.position.set(-alignLineWidth,0,-totalOverhang.l-betweenLines*2-lineWidth*1.5-arrowWidth/2 -this.topgevelSpace)




    const totalWidth = x
    const textloader = new FontLoader()
    textloader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/fonts/Calibri_Regular.json`, (font) => {
    // textloader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {      
      const textGeometry = new TextGeometry(totalWidth.toString(), {
        font: font,
        size: 125,
        height: 1,
      })
      this.font = font

      const text0 = new THREE.Mesh(textGeometry, triangleMaterial)
      text0.name = 'text0'

      const box0 = new THREE.Box3().setFromObject( text0 );
      const vector0 = new THREE.Vector3()
      box0.getSize(vector0)
      this.box = box0
      this.vector = vector0

      text0.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*2+betweenTextAndLine,0,(x-vector0.x)/2)
      text0.rotation.x = 270*Math.PI/180
      text0.rotation.z = 270*Math.PI/180



      
      const totalDepth = z
      const text1 = new THREE.Mesh(new TextGeometry(totalDepth.toString(), {
        font: font,
        size: 125,
        height: 1,
      }), triangleMaterial)
      text1.name = 'text1'
      box0.setFromObject(text1)
      box0.getSize(vector0)
      text1.position.set((z-vector0.x)/2,0, -totalOverhang.l-betweenLines*2-lineWidth*2-betweenTextAndLine -this.topgevelSpace)
      text1.rotation.x = 270*Math.PI/180




      this.mapGroup.add(text0)
      this.mapGroup.add(text1)
    })
      
    arrow0.name = 'arrow0'
    arrow1.name = 'arrow1'
    line0.name = 'line0'
    arrow2.name = 'arrow2'
    arrow3.name = 'arrow3'
    line1.name = 'line1'
    alignLine0.name = 'alignLine0'
    alignLine1.name = 'alignLine1'
    alignLine2.name = 'alignLine2'
    alignLine3.name = 'alignLine3'

    const maatlijnGroup = new this.THREE.Group()
    maatlijnGroup.name = "maatlijnGroup"
    maatlijnGroup.add(arrow0, arrow1, line0, arrow2, arrow3, line1, alignLine0, alignLine1, alignLine2, alignLine3)
    //console.log(maatlijnGroup.getObjectByName('line0'))
    this.mapGroup.add(maatlijnGroup)

  }

  update (x, y, z, division, dx, dz, aantalMuurVakken, maximaleMuurBreedte, totalOverhang, op, bz, kx, kp, bPos, topgevelSpace, poer, modelType, kapschuurConfig) {
    this.x = x,
    this.y = y,
    this.z = z, 
    this.division = division
    this.dx = dx
    this.dz = dz
    this.aantalMuurVakken = aantalMuurVakken
    this.maxOverspanning = maximaleMuurBreedte
    this.totalOverhang = totalOverhang
    this.op = op
    this.bz = bz
    this.kx = kx
    this.kp = kp
    this.bPos = bPos
    this.topgevelSpace = topgevelSpace
    this.poer = poer
    this.modelType = modelType
    this.kapschuurConfig = kapschuurConfig

    console.log("staanders: ", this.nStaandersZ, this.nStaandersX)
    // updating corners
    const groupCorners = this.group.children[0]

    //update betonpoer size and position
    this.betonPoer.updateBetonPoer(poer)
    this.arrayBetonPoeren.forEach((poerMesh, i) => {
      if(i < this.arrayBetonPoeren.length/2) {
        poerMesh.position.y = -y/2 + (-poer.y/2);
      } else {
        poerMesh.position.y = -(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y)/2 + (-poer.y/2);
      }
    })

    for (let i = 0; i < groupCorners.children.length; i++) {
      groupCorners.children[i].geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(dx, (i>1?(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y):y), dz))
      groupCorners.children[i].geometry.attributes.position.needsUpdate = true

      this.textureSettings(groupCorners.children[i].material[0].map, (i>1?(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y):y) / 2320, dz * 0.0075)
      groupCorners.children[i].material[0].map.needsUpdate = true
      groupCorners.children[i].material[0].needsUpdate = true

      this.textureSettings(groupCorners.children[i].material[2].map, (i>1?(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y):y) / 2320, dx * 0.0075)
      groupCorners.children[i].material[2].map.needsUpdate = true
      groupCorners.children[i].material[2].needsUpdate = true

      this.staanderInfoArray.filter(s => s.mesh.id==groupCorners.children[i].id)[0].x=dx
      this.staanderInfoArray.filter(s => s.mesh.id==groupCorners.children[i].id)[0].y=(i>1?(this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y):y)
      this.staanderInfoArray.filter(s => s.mesh.id==groupCorners.children[i].id)[0].z=dz
    }

    groupCorners.children[0].position.set(dx / 2, y / 2, dz / 2)
    groupCorners.children[1].position.x = dx / 2 

    groupCorners.children[1].position.z = x - (dz / 2)
    groupCorners.children[2].position.z = groupCorners.children[1].position.z
    groupCorners.children[2].position.x = z - (dx / 2)
    groupCorners.children[2].position.y = (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y)/2
    groupCorners.children[3].position.x = groupCorners.children[2].position.x
    groupCorners.children[3].position.z = groupCorners.children[0].position.z
    groupCorners.children[3].position.y = (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:y)/2

    // updating mapcorners
    const mapGroupCorners = this.mapGroup.children[0]

    for (let i = 0; i < mapGroupCorners.children.length; i++) {
      mapGroupCorners.children[i].geometry.attributes.position.array = new Float32Array(planeBorderBuffer.positionBuffer(dx, dz, 7))
      mapGroupCorners.children[i].geometry.attributes.position.needsUpdate = true

      this.textureSettings(mapGroupCorners.children[i].material[1].map, dx/2320,  dz/145,  0.5 * Math.PI)
      mapGroupCorners.children[i].material[1].map.needsUpdate = true
      mapGroupCorners.children[i].material[1].needsUpdate = true
      mapGroupCorners.children[i].geometry.translate( -dx / 2, 0, -dz / 2 );
    }
    mapGroupCorners.children[0].position.set(dx / 2, y / 2, dz / 2)
    mapGroupCorners.children[1].position.x = dx / 2

    mapGroupCorners.children[1].position.z = x - (dz / 2)
    mapGroupCorners.children[2].position.z = mapGroupCorners.children[1].position.z
    mapGroupCorners.children[2].position.x = z - (dx / 2)
    mapGroupCorners.children[3].position.x = mapGroupCorners.children[2].position.x
    mapGroupCorners.children[3].position.z = mapGroupCorners.children[0].position.z


    // updating in between corners
    const groupX0 = this.group.children[1]
    const mapGroupX0 = this.mapGroup.children[1]
    const groupX1 = this.group.children[2]
    const mapGroupX1 = this.mapGroup.children[2]

    const groupZ0 = this.group.children[3]
    const groupZ1 = this.group.children[4]
    const mapGroupZ0 = this.mapGroup.children[3]
    const mapGroupZ1 = this.mapGroup.children[4]

    groupX0.position.z = dx
    groupX1.position.z = x - dx
    groupX1.position.x = z - dz
    mapGroupX0.position.z = dx
    mapGroupX1.position.z = x - dx
    mapGroupX1.position.x = z - dz

    groupZ0.position.x = dz
    groupZ1.position.x = z - dz
    groupZ1.position.z = x - dx
    mapGroupZ0.position.x = dz
    mapGroupZ1.position.x = z - dz
    mapGroupZ1.position.z = x - dx

    const nStaandersX = division.length-1
    //er moet alleen een paal komen tussen een berging en geen berging
    let nStaandersXAchter = 0
    for(let i = 0; i < this.division.length-1; i++) {
      if(division[i].bergingEnabled !== division[i+1].bergingEnabled) {
        nStaandersXAchter++
      }
    }

    let nStaandersXVoor = 0
    for(let i = 0; i < this.division.length-1; i++) {
      if(division[i].bergingEnabled !== division[i+1].bergingEnabled) {
        nStaandersXVoor++
      }
    }
    const nStaandersZ = Math.ceil((z-this.dz) / (this.maxOverspanning+this.dz)) - 1
    //const overspanningZ = (z - ((nStaandersZ + 2) * dx)) / ( nStaandersZ + 1)

    //check achterkant
    if (nStaandersXAchter !== groupX1.children.length) {
      // amount changed, recalculate amount

      // dispose or clone ?
      if (nStaandersXAchter > groupX1.children.length) {
        // clone
        for (let i = groupX1.children.length; i < nStaandersXAchter; i++) {

          const staanderX1 = this.staander0.clone()
          groupX1.add(staanderX1)

          this.staanderInfoArray.push({mesh: staanderX1, x:dx, y:y, z:dz})

          const mapStaanderX1 = this.mapStaander0.clone()
          mapGroupX1.add(mapStaanderX1)

        }

      } else if (nStaandersXAchter < groupX1.children.length) {
        // dispose
        //console.log("strange: ", groupX0)
        for (let i = nStaandersXAchter; i < groupX1.children.length; i++) {

          this.staanderInfoArray.splice(this.staanderInfoArray.findIndex(s => s.mesh.id==groupX1.children[0].id), 1)

          groupX1.children[0].geometry.dispose()
          groupX1.children[0].removeFromParent()
          
          mapGroupX1.children[0].geometry.dispose()
          mapGroupX1.children[0].removeFromParent()

        }
      }

    } 

    //check voorkant
    if (nStaandersXVoor !== groupX0.children.length) {
      // amount changed, recalculate amount

      // dispose or clone ?
      if (nStaandersXVoor > groupX0.children.length) {
        // clone
        for (let i = groupX0.children.length; i < nStaandersXVoor; i++) {

          const staanderX0 = this.staander0.clone()
          groupX0.add(staanderX0)

          this.staanderInfoArray.push({mesh: staanderX0, x:dx, y:y, z:dz})

          const mapStaanderX0 = this.mapStaander0.clone()
          mapGroupX0.add(mapStaanderX0)

        }

      } else if (nStaandersXVoor < groupX0.children.length) {
        // dispose
        //console.log("strange: ", groupX0)
        for (let i = nStaandersXVoor; i < groupX0.children.length; i++) {

          this.staanderInfoArray.splice(this.staanderInfoArray.findIndex(s => s.mesh.id==groupX0.children[0].id), 1)

          groupX0.children[0].geometry.dispose()
          groupX0.children[0].removeFromParent()
          
          mapGroupX0.children[0].geometry.dispose()
          mapGroupX0.children[0].removeFromParent()

        }
      }

    } 

    //set position
    let overspanningAchter = - dx / 2;
    let staanderIndexAchter = 0
    
    for (let i = nStaandersX; i > 0; i--) {
      overspanningAchter += division[i].width
      overspanningAchter += dx

      if(division[i].bergingEnabled !== division[i-1].bergingEnabled) {

        groupX1.children[staanderIndexAchter].position.z = (overspanningAchter)*-1
        groupX1.children[staanderIndexAchter].position.x = dx / 2

        this.staanderInfoArray.filter(s => s.mesh.id==groupX1.children[staanderIndexAchter].id)[0].x=dx
        this.staanderInfoArray.filter(s => s.mesh.id==groupX1.children[staanderIndexAchter].id)[0].y=y
        this.staanderInfoArray.filter(s => s.mesh.id==groupX1.children[staanderIndexAchter].id)[0].z=dz
        
        mapGroupX1.children[staanderIndexAchter].position.z = (overspanningAchter)*-1
        mapGroupX1.children[staanderIndexAchter].position.x = dx / 2

        staanderIndexAchter++

      }

    }

    let overspanningVoor = - dx / 2;
    let staanderIndexVoor = 0

    for (let i = 0; i < nStaandersX; i++) {

      overspanningVoor += division[i].width
      overspanningVoor += dx

      if(division[i].bergingEnabled !== division[i+1].bergingEnabled) {

        groupX0.children[staanderIndexVoor].position.z = overspanningVoor
        groupX0.children[staanderIndexVoor].position.x = dx / 2

        this.staanderInfoArray.filter(s => s.mesh.id==groupX0.children[staanderIndexVoor].id)[0].x=dx
        this.staanderInfoArray.filter(s => s.mesh.id==groupX0.children[staanderIndexVoor].id)[0].y=y
        this.staanderInfoArray.filter(s => s.mesh.id==groupX0.children[staanderIndexVoor].id)[0].z=dz
        
        mapGroupX0.children[staanderIndexVoor].position.z = overspanningVoor
        mapGroupX0.children[staanderIndexVoor].position.x = dx / 2

        staanderIndexVoor++

      }

    }

    /*if (nStaandersZ !== this.nStaandersZ) {
      // amount changed, recalculate amount

      // dispose or clone ?
      if (nStaandersZ > this.nStaandersZ) {
        // clone

        if (groupZ0.children.length === 0) {

          for (let i = 0; i < nStaandersZ; i++) {
            const staanderZ0 = this.staander0.clone()
            staanderZ0.position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
            groupZ0.add(staanderZ0)

            const staanderZ1 = this.staander0.clone()
            staanderZ1.position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
            groupZ1.add(staanderZ1)

            this.staanderInfoArray.push({mesh: staanderZ0, x:dx, y:y, z:dz})
            this.staanderInfoArray.push({mesh: staanderZ1, x:dx, y:y, z:dz})


            const mapStaanderZ0 = this.mapStaander0.clone()
            mapStaanderZ0.position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
            mapGroupZ0.add(mapStaanderZ0)

            const mapStaanderZ1 = this.mapStaander0.clone()
            mapStaanderZ1.position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
            mapGroupZ1.add(mapStaanderZ1)
          }

        } else {
          for (let i = 0; i < this.nStaandersZ; i++) {
            groupZ0.children[i].position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
            groupZ0.children[i].position.z = dz / 2 
  
            groupZ1.children[i].position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2

            this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].x=dx
            this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].y=y
            this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].z=dz

            this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].x=dx
            this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].y=y
            this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].z=dz


            mapGroupZ0.children[i].position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
            mapGroupZ0.children[i].position.z = dz / 2 
  
            mapGroupZ1.children[i].position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
          }
          
          for (let i = this.nStaandersZ; i < nStaandersZ; i++) {
  
            const staanderZ0 = groupZ0.children[0].clone()
            staanderZ0.position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
            groupZ0.add(staanderZ0)
  
            const staanderZ1 =  groupZ0.children[0].clone()
            staanderZ1.position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
            groupZ1.add(staanderZ1)

            this.staanderInfoArray.push({mesh: staanderZ0, x:dx, y:y, z:dz})
            this.staanderInfoArray.push({mesh: staanderZ1, x:dx, y:y, z:dz})


            const mapStaanderZ0 = mapGroupZ0.children[0].clone()
            mapStaanderZ0.position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
            mapGroupZ0.add(mapStaanderZ0)
  
            const mapStaanderZ1 =  mapGroupZ0.children[0].clone()
            mapStaanderZ1.position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
            mapGroupZ1.add(mapStaanderZ1)
  
          }
        }

      } else if (nStaandersZ < this.nStaandersZ) {
        // dispose

        for (let i = nStaandersZ; i < this.nStaandersZ; i++) {

          this.staanderInfoArray.splice(this.staanderInfoArray.findIndex(s => s.mesh.id==groupZ0.children[0].id), 1)
          this.staanderInfoArray.splice(this.staanderInfoArray.findIndex(s => s.mesh.id==groupZ1.children[0].id), 1)

          groupZ0.children[i].geometry.dispose()
          groupZ0.children[i].removeFromParent()

          groupZ1.children[i].geometry.dispose()
          groupZ1.children[i].removeFromParent()


          mapGroupZ0.children[i].geometry.dispose()
          mapGroupZ0.children[i].removeFromParent()

          mapGroupZ1.children[i].geometry.dispose()
          mapGroupZ1.children[i].removeFromParent()

        }

        for (let i = 0; i < nStaandersZ; i++) {
          groupZ0.children[i].position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
          groupZ0.children[i].position.z = dz / 2 

          groupZ1.children[i].position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
          groupZ1.children[i].position.z = dz / 2

          this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].x=dx
          this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].y=y
          this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].z=dz

          this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].x=dx
          this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].y=y
          this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].z=dz


          mapGroupZ0.children[i].position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
          mapGroupZ0.children[i].position.z = dz / 2 

          mapGroupZ1.children[i].position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
          mapGroupZ1.children[i].position.z = dz / 2
        }
      }

    } else {
      // amount is the same only update position and size of the staander

      for (let i = 0; i < nStaandersZ; i++) {
        console.log(groupZ0, i)
        // aantal overspanningen + aantal palen + half een paal om te centreren
        groupZ0.children[i].position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
        groupZ0.children[i].position.z = dz / 2 
        
        groupZ1.children[i].position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
        groupZ1.children[i].position.z = dz / 2

        this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].x=dx
        this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].y=y
        this.staanderInfoArray.filter(s => s.mesh.id==groupZ0.children[i].id)[0].z=dz

        this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].x=dx
        this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].y=y
        this.staanderInfoArray.filter(s => s.mesh.id==groupZ1.children[i].id)[0].z=dz


        mapGroupZ0.children[i].position.x = overspanningZ * (i + 1) + (i * dz) + dz / 2
        mapGroupZ0.children[i].position.z = dz / 2 
        
        mapGroupZ1.children[i].position.x = (overspanningZ * (i + 1) + (i * dz)) * -1 - dz / 2
        mapGroupZ1.children[i].position.z = dz / 2

      }
    }*/

    // update this for next change
    this.nStaandersX = nStaandersX
    this.nStaandersZ = nStaandersZ
    this.staandersX = groupX1
    this.staandersZ = groupZ1
    
    // helper
    // this.helper.position.x = 2500
    // this.helper.position.z = (overspanning * (0 + 1) + (0 * dx) + 25) + dx
    const omlijstingGroup = this.group.children[5]
    while(this.group.children[5].children[0]) {
      this.group.children[5].children[0].geometry.dispose();
      this.group.children[5].children[0].material.forEach(mat => {
        mat.dispose();
      });
      this.group.children[5].children[0].removeFromParent();
    }
    if(op) {
      if(kp.l&&kp.v) {
        //back left
        let omlijsting1 = this.meshCreation (this.THREE, this.textureLoader, bPos, dz+kx, bz, this.texture)
        omlijsting1.rotation.x=Math.PI*0.5
        omlijsting1.rotation.y=Math.PI*0.5
        omlijsting1.position.y=bPos/2
        omlijsting1.position.x=z+bz/2
        omlijsting1.position.z=dz-((dz+kx)/2)

        let omlijsting2 = this.meshCreation (this.THREE, this.textureLoader, y, kx, bz, this.texture)
        omlijsting2.rotation.x=Math.PI*0.5
        omlijsting2.rotation.y=Math.PI*0.5
        omlijsting2.position.y=y/2
        omlijsting2.position.x=z-dz+bz/2
        omlijsting2.position.z=-kx/2

        let omlijsting3 = this.meshCreation (this.THREE, this.textureLoader, bPos, dz+bz, bz, this.texture)
        omlijsting3.rotation.z=Math.PI*0.5
        omlijsting3.position.y=bPos/2
        omlijsting3.position.x=z+bz-(dz+bz)/2
        omlijsting3.position.z=-kx-bz/2

        //front right
        let omlijsting4 = this.meshCreation (this.THREE, this.textureLoader, bPos, dz+kx, bz, this.texture)
        omlijsting4.rotation.z=Math.PI*0.5
        omlijsting4.position.y=bPos/2
        omlijsting4.position.x=-kx+(dz+kx)/2
        omlijsting4.position.z=x+bz/2

        let omlijsting5 = this.meshCreation (this.THREE, this.textureLoader, y, kx, bz, this.texture)
        omlijsting5.rotation.z=Math.PI*0.5
        omlijsting5.position.y=y/2
        omlijsting5.position.x=-kx/2
        omlijsting5.position.z=x-dz+bz/2

        let omlijsting6 = this.meshCreation (this.THREE, this.textureLoader, bPos, dz+bz, bz, this.texture)
        omlijsting6.rotation.z=Math.PI*0.5
        omlijsting6.rotation.y=Math.PI*0.5
        omlijsting6.position.y=bPos/2
        omlijsting6.position.x=-kx-bz/2
        omlijsting6.position.z=x+bz-(dz+bz)/2

        //add to group
        omlijstingGroup.add(omlijsting1, omlijsting2, omlijsting3, omlijsting4, omlijsting5, omlijsting6)
      }else if(kp.r&&kp.v) {
        //back right
        let omlijsting1 = this.meshCreation (this.THREE, this.textureLoader, bPos, dz+kx, bz, this.texture)
        omlijsting1.rotation.x=Math.PI*0.5
        omlijsting1.rotation.y=Math.PI*0.5
        omlijsting1.position.y=bPos/2
        omlijsting1.position.x=z+bz/2
        omlijsting1.position.z=x-dz+((dz+kx)/2)

        let omlijsting2 = this.meshCreation (this.THREE, this.textureLoader, y, kx, bz, this.texture)
        omlijsting2.rotation.x=Math.PI*0.5
        omlijsting2.rotation.y=Math.PI*0.5
        omlijsting2.position.y=y/2
        omlijsting2.position.x=z-dz+bz/2
        omlijsting2.position.z=x+kx/2

        let omlijsting3 = this.meshCreation (this.THREE, this.textureLoader, bPos, dz+bz, bz, this.texture)
        omlijsting3.rotation.z=Math.PI*0.5
        omlijsting3.position.y=bPos/2
        omlijsting3.position.x=z+bz-(dz+bz)/2
        omlijsting3.position.z=x+kx+bz/2

        //front right
        let omlijsting4 = this.meshCreation (this.THREE, this.textureLoader, bPos, dz+kx, bz, this.texture)
        omlijsting4.rotation.z=Math.PI*0.5
        omlijsting4.position.y=bPos/2
        omlijsting4.position.x=-kx+(dz+kx)/2
        omlijsting4.position.z=-bz/2

        let omlijsting5 = this.meshCreation (this.THREE, this.textureLoader, y, kx, bz, this.texture)
        omlijsting5.rotation.z=Math.PI*0.5
        omlijsting5.position.y=y/2
        omlijsting5.position.x=-kx/2
        omlijsting5.position.z=dz-bz/2

        let omlijsting6 = this.meshCreation (this.THREE, this.textureLoader, bPos, dz+bz, bz, this.texture)
        omlijsting6.rotation.z=Math.PI*0.5
        omlijsting6.rotation.y=Math.PI*0.5
        omlijsting6.position.y=bPos/2
        omlijsting6.position.x=-kx-bz/2
        omlijsting6.position.z=-bz+(dz+bz)/2

        //add to group
        omlijstingGroup.add(omlijsting1, omlijsting2, omlijsting3, omlijsting4, omlijsting5, omlijsting6)
      }
    }






    //maatlijnen:
    const betweenLines = 200
    const betweenTextAndLine = 20
    const lineWidth = 20
    const alignLineWidth = 10
    const arrowLength = 200
    const arrowWidth = 120

    const maatlijnGroup = this.mapGroup.getObjectByName('maatlijnGroup')
    const arrow0 = maatlijnGroup.getObjectByName('arrow0')
    const arrow1 = maatlijnGroup.getObjectByName('arrow1')
    const line0 = maatlijnGroup.getObjectByName('line0')
    const arrow2 = maatlijnGroup.getObjectByName('arrow2')
    const arrow3 = maatlijnGroup.getObjectByName('arrow3')
    const line1 = maatlijnGroup.getObjectByName('line1')
    const alignLine0 = maatlijnGroup.getObjectByName('alignLine0')
    const alignLine1 = maatlijnGroup.getObjectByName('alignLine1')
    const alignLine2 = maatlijnGroup.getObjectByName('alignLine2')
    const alignLine3 = maatlijnGroup.getObjectByName('alignLine3')
    const text0 = this.mapGroup.getObjectByName('text0')
    const text1 = this.mapGroup.getObjectByName('text1')

    const box0 = this.box
    const textSizes = this.vector

    line0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(x-arrowLength*2, lineWidth))
    line0.geometry.attributes.position.needsUpdate = true
    line1.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(z-arrowLength*2, lineWidth))
    line1.geometry.attributes.position.needsUpdate = true
    alignLine0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(totalOverhang.a+betweenLines*2+lineWidth*1.5+arrowWidth/2, alignLineWidth))
    alignLine0.geometry.attributes.position.needsUpdate = true
    alignLine2.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(totalOverhang.l+betweenLines*2+lineWidth*1.5+arrowWidth/2 +this.topgevelSpace, alignLineWidth))
    alignLine2.geometry.attributes.position.needsUpdate = true

    arrow0.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1.5+arrowWidth/2,0,arrowLength)
    arrow1.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1.5-arrowWidth/2,0,x-arrowLength)
    line0.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1, 0, arrowLength)
    line1.position.set(z-arrowLength, 0, -totalOverhang.l-betweenLines*2-lineWidth*2 -this.topgevelSpace)
    arrow2.position.set(z-arrowLength,0,-totalOverhang.l-betweenLines*2-lineWidth*1.5+arrowWidth/2 -this.topgevelSpace)
    arrow3.position.set(arrowLength,0,-totalOverhang.l-betweenLines*2-lineWidth*1.5-arrowWidth/2 -this.topgevelSpace)
    alignLine0.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1.5+arrowWidth/2,0,-alignLineWidth)
    alignLine1.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*1.5+arrowWidth/2,0,x)
    alignLine2.position.set(z,0,-totalOverhang.l-betweenLines*2-lineWidth*1.5-arrowWidth/2 -this.topgevelSpace)
    alignLine3.position.set(-alignLineWidth,0,-totalOverhang.l-betweenLines*2-lineWidth*1.5-arrowWidth/2 -this.topgevelSpace)

    const totalWidth = x
    text0.geometry = new TextGeometry(totalWidth.toString(), {
      font: this.font,
      size: 125,
      height: 1,
    })
    box0.setFromObject(text0)
    box0.getSize(textSizes)
    text0.position.set(z+totalOverhang.a+betweenLines*2+lineWidth*2+betweenTextAndLine,0,(x-textSizes.z)/2)

    const totalDepth = z
    text1.geometry = new TextGeometry(totalDepth.toString(), {
      font: this.font,
      size: 125,
      height: 1,
    })
    box0.setFromObject(text1)
    box0.getSize(textSizes)
    text1.position.set((z-textSizes.x)/2,0, -totalOverhang.l-betweenLines*2-lineWidth*2-betweenTextAndLine -this.topgevelSpace)

    // knop om betonpoer aan en uit te zetten
    console.log("staanders: ", this)
  }

  updateTexture (texture) {
    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (newTexture) => {
      const textureX = this.group.children[0].children[0].material[0]
      textureX.map.dispose()
      textureX.map = newTexture.clone()
      this.textureSettings(textureX.map,  this.y / 2320,  this.dz / 145,  0.5 * Math.PI)
      textureX.map.needsUpdate = true;
      textureX.needsUpdate = true;

      const textureY = this.group.children[0].children[0].material[2]
      textureY.map.dispose()
      textureY.map = newTexture.clone()
      this.textureSettings(textureY.map,  this.dx / 2320,  this.dz / 145, 0.5 * Math.PI)
      textureY.map.needsUpdate = true;
      textureY.needsUpdate = true;

      const textureZ = this.group.children[0].children[0].material[4]
      textureZ.map.dispose()
      textureZ.map = newTexture.clone()
      this.textureSettings(textureZ.map,  this.y / 2320,  this.dz / 145,  1.5 * Math.PI, 0.7)
      textureZ.map.needsUpdate = true;
      textureZ.needsUpdate = true;
      let op = this.op

      const textureXAchter = this.group.children[0].children[2].material[0]
      textureXAchter.map.dispose()
      textureXAchter.map = newTexture.clone()
      this.textureSettings(textureXAchter.map,  this.y / 2320,  this.dz / 145,  0.5 * Math.PI)
      textureXAchter.map.needsUpdate = true;
      textureXAchter.needsUpdate = true;

      const textureZAchter = this.group.children[0].children[2].material[4]
      textureZAchter.map.dispose()
      textureZAchter.map = newTexture.clone()
      this.textureSettings(textureZAchter.map, (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:this.y) / 2320,  this.dz / 145,  0.5 * Math.PI)
      textureZAchter.map.needsUpdate = true;
      textureZAchter.needsUpdate = true;

      const mapTextureZ = this.mapGroup.children[0].children[0].material[1]
      console.log(mapTextureZ)
      mapTextureZ.map.dispose()
      mapTextureZ.map = newTexture.clone()
      this.textureSettings(mapTextureZ.map, (this.modelType.isKapschuur?this.kapschuurConfig.achtergevelHoogte:this.y) / 2320,  this.dz / 145,  1.5 * Math.PI, 0.7)
      mapTextureZ.map.needsUpdate = true;
      mapTextureZ.needsUpdate = true;

      if(op) {
        let omlijstingGroup = this.group.children[5]
        let bPos = this.bPos
        let dz = this.dz
        let kx = this.kx
        let bz = this.bz
        let y = this.y
        let kp = this.kp
        if(kp.l&&kp.v) {
          this.meshTextureUpdate (omlijstingGroup.children[0], this.THREE, bPos, dz+kx, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[1], this.THREE, y, kx, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[2], this.THREE, bPos, dz+bz, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[3], this.THREE, bPos, dz+kx, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[4], this.THREE, y, kx, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[5], this.THREE, bPos, dz+bz, bz, newTexture)
        }else if(kp.r&&kp.v) {
          this.meshTextureUpdate (omlijstingGroup.children[0], this.THREE, bPos, dz+kx, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[1], this.THREE, y, kx, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[2], this.THREE, bPos, dz+bz, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[3], this.THREE, bPos, dz+kx, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[4], this.THREE, y, kx, bz, newTexture)
          this.meshTextureUpdate (omlijstingGroup.children[5], this.THREE, bPos, dz+bz, bz, newTexture)
        }
      }
    })
    this.texture = texture
  }

  textureSettings(texture, repeatX, repeatY, rotation, offsetX, offsetY) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    texture.offset.set(offsetX !== undefined ? offsetX : 0, offsetY !== undefined ? offsetY : 0)
  }

  shaderMaterial(hex) {
    const c = new this.THREE.Color(hex)
    const fragmentShader = `void main() {gl_FragColor = vec4(vec3(${c.r},${c.g},${c.b}),1.0);}`
    const material = new this.THREE.ShaderMaterial({fragmentShader})
    return material
  }

  meshCreation (THREE, textureLoader, x, y, z, texture) {
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(x, y, z)), 3))
    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry.setIndex(boxBuffer.indexBuffer());
    geometry.groups = boxBuffer.groupBuffer()

    // texture
    const textureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureX,  x / 2320,  y * 0.0075)
    const materialX = new THREE.MeshStandardMaterial({ map: textureX, metalness: 0, roughness: 1 })
    //materialX.side = THREE.BackSide

    const textureY = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureY,  x / 2320,  z * 0.0075,  0 * Math.PI)
    const materialY = new THREE.MeshStandardMaterial({ map: textureY, metalness: 0, roughness: 1 })
    //materialY.side = THREE.BackSide

    const textureZ = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureZ,  z / 2320,  y * 0.0075,  0 * Math.PI)
    const materialZ = new THREE.MeshStandardMaterial({ map: textureZ, metalness: 0, roughness: 1 })
    //materialZ.side = THREE.BackSide

    // mesh
    const plank = new THREE.Mesh( geometry, [
      materialZ,
      materialZ,
      materialY,
      materialY,
      materialX,
      materialX
    ]);
    plank.castShadow = true; 
    plank.receiveShadow = true;
    return plank
  }

  meshTextureUpdate(object, THREE, x, y, z, map) {
    const mapX = map.clone()
    this.textureSettings(mapX, x / 2320, y * 0.0075, 2*Math.PI)
    const materialX = new THREE.MeshStandardMaterial({ map: mapX, metalness: 0, roughness: 1 })
    materialX.map.needsUpdate = true
    materialX.needsUpdate = true

    const mapY = map.clone()
    this.textureSettings(mapY, x / 2320, z * 0.0075, Math.PI)
    const materialY = new THREE.MeshStandardMaterial({ map: mapY, metalness: 0, roughness: 1 })
    materialY.map.needsUpdate = true
    materialY.needsUpdate = true

    const mapZ = map.clone()
    this.textureSettings(mapZ, z / 2320, y * 0.0075, 2*Math.PI)
    const materialZ = new THREE.MeshStandardMaterial({ map: mapZ, metalness: 0, roughness: 1 })
    materialZ.map.needsUpdate = true
    materialZ.needsUpdate = true

    console.log(materialZ, materialY, materialX)
    console.log(x,z,y)

    // mesh
    object.material = [
      materialZ,
      materialZ,
      materialY,
      materialY,
      materialX,
      materialX
    ];
  }
}