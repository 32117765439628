import { merge } from 'lodash';

export default class SessionTracker {
  constructor(tenantCode, token) {
    this.tenantCode = tenantCode;
    this.token = token;
    this.socket = null;
    this.data = {};
    this.connectionId = null;
    this.createdCollections = {};
  }

  connect(tenantCode, token, isReconnect = false, callback) {

    // const protocol = window.location.protocol==='https'?'wss':'ws';
    const webSocketURL = import.meta.env.VITE_VUE_APP_CMS_SERVER//`${protocol}://localhost:4000/websocket`;
    const ws = new WebSocket(webSocketURL); // 'ws://localhost:4000/websocket'
    this.socket = ws;

    // Event listener for successful connection
    ws.onopen = () => {
      console.log('Connected to WebSocket server');

      if(isReconnect) {
        ws.send(JSON.stringify({
          op: 'reconnect',
          connectionId: this.connectionId,
          configuratorCode: "MG3"
        }));
      }
      
      // auth with token
      ws.send(JSON.stringify({
        op: 'auth',
        args: [tenantCode, token]
      }));

      // callback if provided
      if(callback) callback();
    };

    // Event listener for receiving messages from the server
    ws.onmessage = (data) => {
      console.log('Received message from server:', data.data);
      const message = JSON.parse(data.data);
      switch (message.content) {
        case 'connectionId':
          if(!this.connectionId) {
            this.connectionId = message.connectionId
          }
          break;
        case 'connectionIdForce':
          this.connectionId = message.connectionId
          break;
        default:
          console.log('Unknown message type');
      }
    };

    // Event listener for WebSocket connection close
    ws.onclose = () => {
      console.log('Connection to WebSocket server closed');
    };

    // Event listener for WebSocket connection error
    ws.onerror = (error) => {
      console.error('Error in WebSocket connection:', error);
      // settimout to reconnect
      setTimeout(() => {
        console.log("this.data", this.data)
        this.connect(tenantCode, token, true, () => {
          // loop through all collections and update
          for(const [key, value] of Object.entries(this.data)) {
            this.updateSessionData(key, value);  
          }
        });
      }, 5000);
    };
  }

  updateSessionData(collectionName, data) {
    if(!this.data[collectionName]) this.data[collectionName] = {};
    try {
      // merge data so that all collectiondata is also "stored" clientside as a backup
      this.data[collectionName] = merge(this.data[collectionName], data);
      this.socket.send(JSON.stringify({
        op: 'collectionUpdate',
        configuratorCode: "MG3",
        collectionName,
        data
      }));
      this.createdCollections[collectionName] = true;
    } catch {
      setTimeout(() => {
        this.updateSessionData(collectionName, data);
      }, 1000);
    }
  }

  authorizeToken(token) {
    if(!this.socket || !this.createdCollections['configuratorSession']) {
      setTimeout(() => {
        this.authorizeToken(token);
      }, 1000);
      return;
    }
    this.socket.send(JSON.stringify({
      op: 'authCollection',
      collection: 'users',
      filter: {
        token: token
      },
      configuratorCode: "MG3",
      collectionName: 'configuratorSession'
    }));
  }
}