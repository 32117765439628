let succesTimer

const state = {
  error: {
    showError: false,
    message: '',
  },
  succes: {
    showSucces: false,
    message: '',
  },
}

const mutations = {

  setError (state, payload) {
    state.error = payload
  },

  setSucces (state, payload) {
    state.succes = payload
  },
}

const actions = {
  showError (context, message) {
    context.commit('setError', {showError: true, message: message})

    // Vue.$gtag.exception({
    //   'description': message,        
    //   'fatal': false
    // })

    context.dispatch('setLoadingModal', {open: false, message: ''})
  },

  dismissError (context) {
    context.commit('setError', {showError: false, message: ''})
  },

  showSucces (context, message) {
    context.commit('setSucces', {showSucces: true, message: message})

    succesTimer = setTimeout(() => context.commit('setSucces', {showSucces: false, message: ''}), 4000)
  },

  dismissSucces (context) {
    context.commit('setSucces', {showSucces: false, message: ''})
    clearTimeout(succesTimer)
  },
}

const getters = {
  getError(state) {
    return state.error
  },

  getSucces(state) {
    return state.succes
  },
}

export default {
  state,
  mutations,
  actions,
  getters
}