// defines position of the vertices
function positionBuffer (breedte, hoogte, dikte, degrees) {
  const radians = degrees*(Math.PI/180)
  const x = breedte / 2
  const y = hoogte
  const h = y+(x)*Math.tan(radians)
  const z = dikte / 2

  console.log(y, h)
  // console.log(x,y,z)
  //return [x, y, z, x, y, -z, x, -y, z, x, -y, -z, -x, y, -z, -x, y, z, -x, -y, -z, -x, -y, z, -x, y, -z, x, y, -z, -x, y, z, x, y, z, -x, -y, z, x, -y, z, -x, -y, -z, x, -y, -z, -x, y, z, x, y, z, -x, -y, z, x, -y, z, x, y, -z, -x, y, -z, x, -y, -z, -x, -y, -z]
  //return [x, -y, z, -x, -y, z, -x, -y, -z,        -x, -y, -z, x, -y, -z, x, -y, z,]
  return [
    //front
    0, h, -z,
    x, y, -z,
    x, 0, -z,
    -x, 0, -z,
    0, h, -z,
    x, 0, -z,
    0, h, -z,
    -x, 0, -z,
    -x, y, -z,

    //back
    x, 0, z,
    x, y, z,
    0, h, z,
    x, 0, z,
    0, h, z,
    -x, 0, z,
    -x, 0, z,
    0, h, z,
    -x, y, z,

    //right
    -x, 0, -z,
    -x, 0, z,
    -x, y, -z,
    -x, 0, z,
    -x, y, z,
    -x, y, -z,

    //left
    x, y, -z,
    x, 0, z,
    x, 0, -z,
    x, y, -z,
    x, y, z,
    x, 0, z,

    //top
    x, y, z,
    x, y, -z,
    0, h, -z,
    x, y, z,
    0, h, -z,
    0, h, z,

    -x, y, -z,
    -x, y, z,
    0, h, -z,
    -x, y, z,
    0, h, z,
    0, h, -z,  

    //bottom
    x, 0, -z,
    -x, 0, z,
    -x, 0, -z,
    
    x, 0, -z,
    x, 0, z,
    -x, 0, z
  ]
}

// defines position of the textures
function uvBuffer (breedte, hoogte, dikte, degrees) { 
  //return [0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0]
  //return [0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1,0]
  const radians = degrees*(Math.PI/180)
  const x = breedte / 2
  const y = hoogte
  const h = y+(145/2)*Math.tan(radians)
  const z = dikte / 2
  console.log(radians, x, y, h, z)
  console.log(1-(1/(2330/(h-y))))
  return [
    0.5*(breedte/145), h/2330,
    1, (h/2330)-(1/(2330/(h-y))),
    breedte/145, 0, 

    0, 0,
    0.5*(breedte/145), h/2330, 
    breedte/145, 0, 

    0.5*(breedte/145), h/2330,
    0, 0,
    0, (h/2330)-(1/(2330/(h-y))),



    breedte/145, 0.5, 
    breedte/145, (((h/2330)-(1/(2330/(h-y))) )+0.5), 
    0.5*(breedte/145), ((h/2330 )+0.5), 

    breedte/145, 0.5, 
    0.5*(breedte/145), ((h/2330 )+0.5),
    0, 0.5,

    0, 0.5,
    0.5*(breedte/145), ((h/2330 )+0.5), 
    0, (((h/2330)-(1/(2330/(h-y))) )+0.5),



    y/145, 0,
    y/145, dikte/2330,
    0, 0,

    y/145, 0,
    0, 0,
    0, dikte/2330,



    0, ((dikte/2330 )+0.5),
    y/145, 0.5,
    y/145, ((dikte/2330 )+0.5),

    0, ((dikte/2330 )+0.5),
    0, 0.5, 
    y/145, 0.5,



    0, dikte/145, 
    0, 0, 
    breedte/2330/2, 0,

    0, dikte/145,
    breedte/2330/2, 0,
    breedte/2330/2, dikte/145,   
    
    breedte/2330, 0,
    breedte/2330, dikte/145,
    breedte/2330/2, 0,

    breedte/2330, dikte/145,
    breedte/2330/2, dikte/145,
    breedte/2330/2, 0,




    0, ((dikte/2330 )+0.25),
    y/145, 0.25,
    y/145, ((dikte/2330 )+0.25),

    0, ((dikte/2330 )+0.25),
    0, 0.25,
    y/145, 0.25,
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  //return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
  //return [0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0,]
  //return [0,0,1]
  //return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
  return [
    0, 0, -1,
    0, 0, -1,
    0, 0, -1,
    0, 0, -1,
    0, 0, -1,
    0, 0, -1,
    0, 0, -1,
    0, 0, -1,
    0, 0, -1,

    0, 0, 1,
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,

    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,

    0, -1, 0,
    0, -1, 0,
    0, -1, 0,
    0, -1, 0,
    0, -1, 0,
    0, -1, 0,
  ]
}

export { positionBuffer, uvBuffer, normalBuffer }