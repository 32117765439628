import SessionTracker from "../services/SessionTracker"
import { UAParser } from 'ua-parser-js'
import { getGPUTier } from 'detect-gpu'

const state = {
  sessionTracker: new SessionTracker(),
}

const actions = {
  async connectToWebSocket() {
    state.sessionTracker.connect("tw")
    // state.sessionTracker.updateSessionData("configuratorSession", {})

    let parser = new UAParser();
    let uaInfo = parser.getResult();
    const deviceInfo = uaInfo
    deviceInfo.resolution = `${window.screen.width}x${window.screen.height}`
    deviceInfo.language = navigator.language
    deviceInfo.gpu = await getGPUTier();
    const deviceInfoString = JSON.stringify(deviceInfo)
    state.sessionTracker.updateSessionData("configuratorSession", {deviceInfo: deviceInfoString})

    setInterval(() => {
      state.sessionTracker.updateSessionData("configuratorSession", {})
    }, 10000)
  },

  setSessionTrackerToken(context, token) {
    state.sessionTracker.authorizeToken(token)
  },

  updateSessionData(context, { collectionName, data }) {
    console.log("updateSessionData", collectionName, data)
    state.sessionTracker.updateSessionData(collectionName, data)
  }
}

const getters = {
  sessionTracker: state => state.sessionTracker
}

export default {
  state,
  actions,
  getters
}