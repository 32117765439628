import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
// import {CSG} from 'three-csg-ts';
import { v4 as uuid } from 'uuid'
import store from '@/store'

export default class Goot {
  constructor (THREE, x, y, z, dakgootSize, dakgoot, overhang, dx, staanders, walls, modelType, kapschuurConfig, bz) {
    this.THREE = THREE
    this.x = x
    this.y = y
    this.z = z
    this.dakgootSize = dakgootSize
    this.dakgoot = dakgoot
    // this.dakPlaatBreedteVoor = dakPlaatBreedteVoor
    this.overhang = overhang
    this.dx = dx
    this.staanders = staanders
    this.walls = walls
    this.modelType = modelType
    this.kapschuurConfig = kapschuurConfig
    this.bz = bz
    this.bouw(THREE, x, y, z, dakgootSize, dakgoot, overhang, dx, staanders, walls, bz)
  }

  bouw (THREE, x, y, z, dakgootSize, dakgoot, overhang, dx, staanders, walls, bz) {
    // console.log(`THREE, x, y, z, overhang, dx, dakgoot, staanders, walls`)
    // console.log(THREE, x, y, z, overhang, dx, dakgoot, staanders, walls)
    this.group = new THREE.Group()

    const frontHeight = dakgootSize.y
    const backHeight = (this.modelType.isKapschuur?dakgootSize.y-(y-this.kapschuurConfig.achtergevelHoogte):dakgootSize.y)    
    // const needs3rdPipe = x > dakgootSize.threePipesWidth //14496
    // let dakPlaatBreedteVoor = store.state.config.dakPlaatSize.dakPlaatBreedteVoor
    let dakPlaatBreedteVoor = store.state.config.dakPlaatSize.dakPlaatBreedteVoor
    // const needs3rdPipe = this.dakPlaatBreedteVoor > dakgootSize.threePipesWidth
    // console.log(`bouw = dakPlaatBreedteVoor, dakgootSize.threePipesWidth`)
    // console.log(dakPlaatBreedteVoor, dakgootSize.threePipesWidth)

    // Then in your code where you need it:
    const needs3rdPipe = dakPlaatBreedteVoor > dakgootSize.threePipesWidth
    this.thirdPipeThreshold = dakgootSize.threePipesWidth //14496

    const pijpwidth = 70

    const pijpBoven = (-pijpwidth/2) -dakgootSize.offset -1
    const pijpMidden = (-pijpwidth/2) -(dakgootSize.offset/2) -1
    const pijpOnder = (-pijpwidth/2) -1

    const pijpBovenAchter = (-pijpwidth/2) -dakgootSize.offset -1 
    const pijpMiddenAchter = (-pijpwidth/2) -(dakgootSize.offset/2) -1 -(this.modelType.isLissabon ? bz/2 : 0)
    const pijpOnderAchter = (-pijpwidth/2) -1 -(this.modelType.isLissabon ? bz : 0)

    const bend = 40 // degrees of bend
    const bendLength = Math.tan(bend * Math.PI / 180) * dakgootSize.offset +pijpwidth

    // voor
    const curve0Voor = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( pijpBoven, frontHeight - 49.285, 0 ),
      new THREE.Vector3( pijpBoven, frontHeight-90, 0 ),
      new THREE.Vector3( pijpBoven, frontHeight-100, 0 ),
    );
    const curve1Voor = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( pijpBoven, frontHeight-100, 0 ),
      new THREE.Vector3( pijpBoven, frontHeight-100-bendLength*0.25, 0 ),
      new THREE.Vector3( pijpMidden, frontHeight-100-bendLength/2, 0 ),
    );
    const curve2Voor = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( pijpMidden, frontHeight-100-bendLength/2, 0 ),
      new THREE.Vector3( pijpOnder, frontHeight-100-bendLength*0.75, 0 ),
      new THREE.Vector3( pijpOnder, frontHeight-100-bendLength, 0 ),
    );
    const curve3Voor = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( pijpOnder, frontHeight-100-bendLength, 0 ),
      new THREE.Vector3( pijpOnder, (frontHeight-100-bendLength)/2, 0 ),
      new THREE.Vector3( pijpOnder, 0, 0 )
    );

    const points0Voor = curve0Voor.getPoints( 2 )
    points0Voor.pop()
    const curve11Voor = curve1Voor.getPoints( 5 )
    curve11Voor.unshift()
    const points1Voor = points0Voor.concat(curve11Voor)
    points1Voor.pop()
    const points2Voor = points1Voor.concat(curve2Voor.getPoints( 5 ))
    points2Voor.pop()
    const points3Voor = points2Voor.concat(curve3Voor.getPoints( 2 ));

    const splineVoor = new THREE.CatmullRomCurve3( points3Voor );
    const tubeGeometryVoor = new THREE.TubeGeometry( splineVoor, 500, 70/2, 128, false );


    // achter
    const curve0Achter = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( pijpBovenAchter, backHeight - 49.285, 0 ),
      new THREE.Vector3( pijpBovenAchter, backHeight-90, 0 ),
      new THREE.Vector3( pijpBovenAchter, backHeight-100, 0 ),
    );
    const curve1Achter = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( pijpBovenAchter, backHeight-100, 0 ),
      new THREE.Vector3( pijpBovenAchter, backHeight-100-bendLength*0.25, 0 ),
      new THREE.Vector3( pijpMiddenAchter, backHeight-100-bendLength/2, 0 ),
    );
    const curve2Achter = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( pijpMiddenAchter, backHeight-100-bendLength/2, 0 ),
      new THREE.Vector3( pijpOnderAchter, backHeight-100-bendLength*0.75, 0 ),
      new THREE.Vector3( pijpOnderAchter, backHeight-100-bendLength, 0 ),
    );
    const curve3Achter = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( pijpOnderAchter, backHeight-100-bendLength, 0 ),
      new THREE.Vector3( pijpOnderAchter, (backHeight-100-bendLength)/2, 0 ),
      new THREE.Vector3( pijpOnderAchter, 0, 0 )
    );
    const points0Achter = curve0Achter.getPoints( 2 )
    points0Achter.pop()
    const curve11Achter = curve1Achter.getPoints( 5 )
    curve11Achter.unshift()
    const points1Achter = points0Achter.concat(curve11Achter)
    points1Achter.pop()
    const points2Achter = points1Achter.concat(curve2Achter.getPoints( 5 ))
    points2Achter.pop()
    const points3Achter = points2Achter.concat(curve3Achter.getPoints( 2 ));

    const splineAchter = new THREE.CatmullRomCurve3( points3Achter );
    const tubeGeometryAchter = new THREE.TubeGeometry( splineAchter, 500, 70/2, 128, false );

      
    const cubeLoader = new THREE.CubeTextureLoader()
    let textureCube = cubeLoader.load([ 
      // require('@/assets/test/posx.jpg'), 
      // require('@/assets/test/negx.jpg'), 
      // require('@/assets/test/posy.jpg'), 
      // require('@/assets/test/negy.jpg'), 
      // require('@/assets/test/posz.jpg'), 
      // require('@/assets/test/negz.jpg') 
    ]);
    textureCube.encoding = THREE.sRGBEncoding;

    let materialM = new THREE.MeshStandardMaterial({
      metalness: 0.9,
      roughness: 0.15,
      envMapIntensity: 1.0,
      side: THREE.DoubleSide
    })
    materialM.envMap = textureCube
    this.material = materialM

    const pijpVoor = new THREE.Mesh( tubeGeometryVoor, materialM );
    pijpVoor.castShadow = true;
    pijpVoor.receiveShadow = true;

    const pijpAchter = new THREE.Mesh( tubeGeometryAchter, materialM );
    pijpAchter.castShadow = true;
    pijpAchter.receiveShadow = true;

    const gltfLoader = new GLTFLoader()
    gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/models/dakGootGeometry.glb`, (goot) => {
      const gootMesh = goot.scene.children[0].children[0]
      gootMesh.scale.multiplyScalar(1000)
      gootMesh.material = materialM
      console.log(gootMesh)
      gootMesh.castShadow = true;
      gootMesh.receiveShadow = true;
      gootMesh.rotation.x = Math.PI/2

      this.goot = gootMesh

      gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/models/dakGootKopplaat.glb`, (kopplaat) => {
        const gootKopplaat = kopplaat.scene.children[0]
        console.log(gootKopplaat)
        gootKopplaat.scale.multiplyScalar(1000)
        //gootKopplaat.position.y = y + 100
        //gootKopplaat.position.x = -50
        gootKopplaat.position.z= 0
        gootKopplaat.material = materialM
        gootKopplaat.castShadow = true;
        gootKopplaat.receiveShadow = true;

        this.kopplaat = gootKopplaat
  
        const gootKopplaat2 = gootKopplaat.clone()
        gootKopplaat2.position.z = x + (overhang*2)-1
  
        const gootGroup = new THREE.Group()

        const gootMeshForGroup = gootMesh.clone()
        gootMeshForGroup.name = "gootMesh"
        gootMeshForGroup.scale.y = x+(overhang*2)
        
        gootGroup.add(gootMeshForGroup, gootKopplaat, gootKopplaat2)
      

        if(dakgoot.front.enabled) {
          const gootVoor = gootGroup.clone()
          gootVoor.name = "gootVoor"
          gootVoor.position.y = frontHeight
          gootVoor.position.x = pijpBoven
          gootVoor.position.z = -overhang
          this.group.add(gootVoor)
        }
        if(dakgoot.back.enabled) {
          const gootAchter = gootGroup.clone()
          gootAchter.name = "gootAchter"
          gootAchter.rotation.y = Math.PI
          gootAchter.position.y = backHeight
          gootAchter.position.x = z+Math.abs(pijpBoven)
          gootAchter.position.z = x+overhang
          this.group.add(gootAchter)
        }




        gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/models/dakGootFunnel.glb`, (model) => {
          const funnel = model.scene.children[0]
          funnel.scale.multiplyScalar(1000)
          funnel.material = materialM
          funnel.castShadow = true;
          funnel.receiveShadow = true;
          this.funnel = funnel

          const cylinderGeometry = new THREE.CylinderGeometry( 70/2, 70/2, 500, 32 );
          const gootGat = new THREE.Mesh( cylinderGeometry, materialM );
          this.gootGat = gootGat

      
          if(dakgoot.front.enabled) {
            if(dakgoot.front.left) { 
              const pijpVoorLinks = pijpVoor.clone()
              pijpVoorLinks.name = "pijpVoorLinks"
              pijpVoorLinks.position.z = dx/2
              const funnelVoorLinks = funnel.clone()
              funnelVoorLinks.name = "funnelVoorLinks"
              funnelVoorLinks.position.z = dx/2
              funnelVoorLinks.position.y = frontHeight
              funnelVoorLinks.position.x = pijpBoven
              this.group.add(pijpVoorLinks, funnelVoorLinks)

              let goot = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
              goot.removeFromParent()
              this.group.add(gootGat)
              gootGat.position.z = dx/2+overhang
              gootGat.updateMatrix()
              goot.updateMatrix()
              let goot2 = goot//CSG.subtract(goot, gootGat)
              goot2.name = "gootMesh"
              gootGat.removeFromParent()
              this.group.getObjectByName("gootVoor").add(goot2)
            }
            if(dakgoot.front.right) { 
              const pijpVoorRechts = pijpVoor.clone()
              pijpVoorRechts.name = "pijpVoorRechts"
              pijpVoorRechts.position.z = x-dx/2
              const funnelVoorRechts = funnel.clone()
              funnelVoorRechts.name = "funnelVoorRechts"
              funnelVoorRechts.position.z = x-dx/2
              funnelVoorRechts.position.y = frontHeight
              funnelVoorRechts.position.x = pijpBoven
              this.group.add(pijpVoorRechts, funnelVoorRechts)

              let goot = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
              goot.removeFromParent()
              this.group.add(gootGat)
              gootGat.position.z = x+overhang-dx/2 
              gootGat.updateMatrix()
              goot.updateMatrix()
              let goot2 = goot//CSG.subtract(goot, gootGat)
              goot2.name = "gootMesh"
              gootGat.removeFromParent()
              this.group.getObjectByName("gootVoor").add(goot2)
            }
            if(needs3rdPipe) {
              const frontWalls = walls.filter(wall => wall.rotation === Math.PI*1.5)
              const frontStaanderPositions = []
              frontWalls.forEach(wall => {
                frontStaanderPositions.push(wall.position.z + wall.width + dx/2)
              })
              const voorPosition = frontStaanderPositions.reduce((prev, curr) => {
                return (Math.abs(curr - x/2) < Math.abs(prev - x/2) ? curr : prev);
              });
              
              const pijpVoorMidden = pijpVoor.clone()
              pijpVoorMidden.name = 'pijpVoorMidden'
              pijpVoorMidden.position.z = voorPosition
              const funnelVoorMidden = funnel.clone()
              funnelVoorMidden.name = 'funnelVoorMidden'
              funnelVoorMidden.position.z = voorPosition
              funnelVoorMidden.position.y = frontHeight
              funnelVoorMidden.position.x = pijpBoven
              this.group.add(pijpVoorMidden, funnelVoorMidden)

              let gootVoor = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
              gootVoor.removeFromParent()
              this.group.add(gootGat)
              gootGat.position.z = voorPosition+overhang
              gootGat.updateMatrix()
              gootVoor.updateMatrix()
              let gootVoor2 = gootVoor//CSG.subtract(gootVoor, gootGat)
              gootVoor2.name = "gootMesh"
              gootGat.removeFromParent()
              this.group.getObjectByName("gootVoor").add(gootVoor2)
            }
          }
          if(dakgoot.back.enabled) {
            if(dakgoot.back.left) {
              const pijpAchterLinks = pijpAchter.clone()
              pijpAchterLinks.name = "pijpAchterLinks"
              pijpAchterLinks.rotation.y = Math.PI
              pijpAchterLinks.position.z = x-dx/2
              pijpAchterLinks.position.x = z
              const funnelAchterLinks = funnel.clone()
              funnelAchterLinks.name = "funnelAchterLinks"
              funnelAchterLinks.position.z = x-dx/2
              funnelAchterLinks.position.y = backHeight
              funnelAchterLinks.position.x = z+Math.abs(pijpBoven)
              this.group.add(pijpAchterLinks, funnelAchterLinks)

              let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
              goot.removeFromParent()
              this.group.add(gootGat)
              gootGat.position.z = dx/2+overhang
              gootGat.updateMatrix()
              goot.updateMatrix()
              let goot2 = goot//CSG.subtract(goot, gootGat)
              goot2.name = "gootMesh"
              gootGat.removeFromParent()
              this.group.getObjectByName("gootAchter").add(goot2)
            }
            if(dakgoot.back.right) {
              const pijpAchterRechts = pijpAchter.clone()
              pijpAchterRechts.name = "pijpAchterRechts"
              pijpAchterRechts.rotation.y = Math.PI
              pijpAchterRechts.position.z = dx/2
              pijpAchterRechts.position.x = z
              const funnelAchterRechts = funnel.clone()
              funnelAchterRechts.name = "funnelAchterRechts"
              funnelAchterRechts.position.z = dx/2
              funnelAchterRechts.position.y = backHeight
              funnelAchterRechts.position.x = z+Math.abs(pijpBoven)
              this.group.add(pijpAchterRechts, funnelAchterRechts)

              let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
              goot.removeFromParent()
              this.group.add(gootGat)
              gootGat.position.z = x-dx/2+overhang
              gootGat.updateMatrix()
              goot.updateMatrix()
              let goot2 = goot//CSG.subtract(goot, gootGat)
              goot2.name = "gootMesh"
              gootGat.removeFromParent()
              this.group.getObjectByName("gootAchter").add(goot2)
            }
            if(needs3rdPipe) {
              const backWalls = walls.filter(wall => wall.rotation === Math.PI*0.5)
              const backStaanderPositions = []
              backWalls.forEach(wall => {
                backStaanderPositions.push(wall.position.z - wall.width - dx/2)
              })
              const achterPosition = backStaanderPositions.reduce((prev, curr) => {
                return (Math.abs(curr - x/2) < Math.abs(prev - x/2) ? curr : prev);
              });

              const pijpAchterMidden = pijpAchter.clone()
              pijpAchterMidden.name = 'pijpAchterMidden'
              pijpAchterMidden.rotation.y = Math.PI
              pijpAchterMidden.position.z = achterPosition
              pijpAchterMidden.position.x = z
              const funnelAchterMidden = funnel.clone()
              funnelAchterMidden.name = 'funnelAchterMidden'
              funnelAchterMidden.position.z = achterPosition
              funnelAchterMidden.position.y = backHeight
              funnelAchterMidden.position.x = z+Math.abs(pijpBoven)
              this.group.add(pijpAchterMidden, funnelAchterMidden)

              let gootAchter = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
              gootAchter.removeFromParent()
              this.group.add(gootGat)
              gootGat.position.z = achterPosition+overhang
              gootGat.updateMatrix()
              gootAchter.updateMatrix()
              let gootAchter2 = gootAchter//CSG.subtract(gootAchter, gootGat)
              gootAchter2.name = "gootMesh"
              gootGat.removeFromParent()
              this.group.getObjectByName("gootAchter").add(gootAchter2)
            //   const targetedStaander = staanders.staandersX.children[Math.floor(staanders.staandersX.children.length/2)]
            //   console.log(targetedStaander)
    
            //   const pijpAchterMidden = pijpVoor.clone()
            //   pijpAchterMidden.rotation.y = Math.PI
            //   pijpAchterMidden.position.z = Math.abs(targetedStaander.position.z)+dx
            //   pijpAchterMidden.position.x = z
            //   const funnelAchterMidden = funnel.clone()
            //   funnelAchterMidden.position.z = Math.abs(targetedStaander.position.z)+dx
            //   funnelAchterMidden.position.y = frontHeight
            //   funnelAchterMidden.position.x = z+Math.abs(pijpBoven)
            //   this.group.add(pijpAchterMidden, funnelAchterMidden)

            //   let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
            //   goot.removeFromParent()
            //   this.group.add(gootGat)
            //   gootGat.position.z = x-Math.abs(targetedStaander.position.z)-dx+overhang
            //   gootGat.updateMatrix()
            //   goot.updateMatrix()
            //   let goot2 = CSG.subtract(goot, gootGat)
            //   goot2.name = "gootMesh"
            //   gootGat.removeFromParent()
            //   this.group.getObjectByName("gootAchter").add(goot2)
            }
          }
        })

      })
    })

    
  }

  update(x, y, z, dakgootSize, dakgoot, overhang, dx, staanders, walls, modelType, kapschuurConfig, bz) {
    this.x = x
    this.y = y
    this.z = z
    this.dakgootSize = dakgootSize
    this.dakgoot = dakgoot
    // this.dakPlaatBreedteVoor = dakPlaatBreedteVoor
    this.overhang = overhang
    this.dx = dx
    this.staanders = staanders
    this.walls = walls
    this.modelType = modelType
    this.kapschuurConfig = kapschuurConfig
    this.bz = bz
    this.thirdPipeThreshold = dakgootSize.threePipesWidth

    while(this.group.children[0]) {
      this.group.children[0].removeFromParent()
    }
    console.log(dakgootSize)

    const frontHeight = dakgootSize.y
    const backHeight = (this.modelType.isKapschuur?dakgootSize.y-(y-this.kapschuurConfig.achtergevelHoogte):dakgootSize.y)
    // const needs3rdPipe = x > this.thirdPipeThreshold && this.thirdPipeThreshold //14496
    let dakPlaatBreedteVoor = store.state.config.dakPlaatSize.dakPlaatBreedteVoor // variable gemaakt t.b.v. het ophalen van de breedte van de dakplaat uit de store
    // console.log(`update = ${{dakPlaatBreedteVoor}} `)
    // console.log(dakPlaatBreedteVoor)
    
    const needs3rdPipe = dakPlaatBreedteVoor > this.thirdPipeThreshold && this.thirdPipeThreshold // dakPlaatBreedteVoor i.p.v. staanderbreedte
    
    // console.log(`dakPlaatBreedteVoor, this.thirdPipeThreshold`)
    // console.log(needs3rdPipe)
    // console.log(dakPlaatBreedteVoor, this.thirdPipeThreshold)
    const pijpwidth = 70
    const pijpBoven = (-pijpwidth/2) -dakgootSize.offset -1
    const pijpMidden = (-pijpwidth/2) -(dakgootSize.offset/2) -1
    const pijpOnder = (-pijpwidth/2) -1

    const pijpBovenAchter = (-pijpwidth/2) -dakgootSize.offset -1 
    const pijpMiddenAchter = (-pijpwidth/2) -(dakgootSize.offset/2) -1 -(this.modelType.isLissabon ? bz/2 : 0)
    const pijpOnderAchter = (-pijpwidth/2) -1 -(this.modelType.isLissabon ? bz : 0)

    const bend = 40 // degrees of bend
    const bendLength = Math.tan(bend * Math.PI / 180) * dakgootSize.offset +pijpwidth

    const curve0Voor = new this.THREE.QuadraticBezierCurve3(
      new this.THREE.Vector3( pijpBoven, frontHeight - 49.285, 0 ),
      new this.THREE.Vector3( pijpBoven, frontHeight-90, 0 ),
      new this.THREE.Vector3( pijpBoven, frontHeight-100, 0 ),
    );
    const curve1Voor = new this.THREE.QuadraticBezierCurve3(
      new this.THREE.Vector3( pijpBoven, frontHeight-100, 0 ),
      new this.THREE.Vector3( pijpBoven, frontHeight-100-bendLength*0.25, 0 ),
      new this.THREE.Vector3( pijpMidden, frontHeight-100-bendLength/2, 0 ),
    );
    const curve2Voor = new this.THREE.QuadraticBezierCurve3(
      new this.THREE.Vector3( pijpMidden, frontHeight-100-bendLength/2, 0 ),
      new this.THREE.Vector3( pijpOnder, frontHeight-100-bendLength*0.75, 0 ),
      new this.THREE.Vector3( pijpOnder, frontHeight-100-bendLength, 0 ),
    );
    const curve3Voor = new this.THREE.QuadraticBezierCurve3(
      new this.THREE.Vector3( pijpOnder, frontHeight-100-bendLength, 0 ),
      new this.THREE.Vector3( pijpOnder, (frontHeight-100-bendLength)/2, 0 ),
      new this.THREE.Vector3( pijpOnder, 0, 0 )
    );

    const points0Voor = curve0Voor.getPoints( 2 )
    points0Voor.pop()
    const curve11Voor = curve1Voor.getPoints( 5 )
    curve11Voor.unshift()
    const points1Voor = points0Voor.concat(curve11Voor)
    points1Voor.pop()
    const points2Voor = points1Voor.concat(curve2Voor.getPoints( 5 ))
    points2Voor.pop()
    const points3Voor = points2Voor.concat(curve3Voor.getPoints( 2 ));

    const splineVoor = new this.THREE.CatmullRomCurve3( points3Voor );
    const tubeGeometryVoor = new this.THREE.TubeGeometry( splineVoor, 500, 70/2, 128, false );

    const curve0Achter = new this.THREE.QuadraticBezierCurve3(
      new this.THREE.Vector3( pijpBovenAchter, backHeight - 49.285, 0 ),
      new this.THREE.Vector3( pijpBovenAchter, backHeight-90, 0 ),
      new this.THREE.Vector3( pijpBovenAchter, backHeight-100, 0 ),
    );
    const curve1Achter = new this.THREE.QuadraticBezierCurve3(
      new this.THREE.Vector3( pijpBovenAchter, backHeight-100, 0 ),
      new this.THREE.Vector3( pijpBovenAchter, backHeight-100-bendLength*0.25, 0 ),
      new this.THREE.Vector3( pijpMiddenAchter, backHeight-100-bendLength/2, 0 ),
    );
    const curve2Achter = new this.THREE.QuadraticBezierCurve3(
      new this.THREE.Vector3( pijpMiddenAchter, backHeight-100-bendLength/2, 0 ),
      new this.THREE.Vector3( pijpOnderAchter, backHeight-100-bendLength*0.75, 0 ),
      new this.THREE.Vector3( pijpOnderAchter, backHeight-100-bendLength, 0 ),
    );
    const curve3Achter = new this.THREE.QuadraticBezierCurve3(
      new this.THREE.Vector3( pijpOnderAchter, backHeight-100-bendLength, 0 ),
      new this.THREE.Vector3( pijpOnderAchter, (backHeight-100-bendLength)/2, 0 ),
      new this.THREE.Vector3( pijpOnderAchter, 0, 0 )
    );

    const points0Achter = curve0Achter.getPoints( 2 )
    points0Achter.pop()
    const curve11Achter = curve1Achter.getPoints( 5 )
    curve11Achter.unshift()
    const points1Achter = points0Achter.concat(curve11Achter)
    points1Achter.pop()
    const points2Achter = points1Achter.concat(curve2Achter.getPoints( 5 ))
    points2Achter.pop()
    const points3Achter = points2Achter.concat(curve3Achter.getPoints( 2 ));

    const splineAchter = new this.THREE.CatmullRomCurve3( points3Achter );
    const tubeGeometryAchter = new this.THREE.TubeGeometry( splineAchter, 500, 70/2, 128, false );

      
    const materialM = this.material

    const pijpVoor = new this.THREE.Mesh( tubeGeometryVoor, materialM );
    pijpVoor.castShadow = true;
    pijpVoor.receiveShadow = true;

    const pijpAchter = new this.THREE.Mesh( tubeGeometryAchter, materialM );
    pijpAchter.castShadow = true;
    pijpAchter.receiveShadow = true;

    const funnel = this.funnel
    const gootGat = this.gootGat
    
    const gootMeshForGroup = this.goot.clone()
    gootMeshForGroup.name = "gootMesh"
    gootMeshForGroup.scale.y = x+(overhang*2)
    
    const gootKopplaat = this.kopplaat

    const gootKopplaat2 = gootKopplaat.clone()
    gootKopplaat2.position.z = x + (overhang*2)-1

    const gootGroup = new this.THREE.Group()
    gootGroup.add(gootMeshForGroup, gootKopplaat, gootKopplaat2)
  

    if(dakgoot.front.enabled) {
      const gootVoor = gootGroup.clone()
      gootVoor.name = "gootVoor"
      gootVoor.position.y = frontHeight 
      gootVoor.position.x = pijpBoven
      gootVoor.position.z = -overhang
      this.group.add(gootVoor)
    }
    if(dakgoot.back.enabled) {
      const gootAchter = gootGroup.clone()
      gootAchter.name = "gootAchter"
      gootAchter.rotation.y = Math.PI
      gootAchter.position.y = backHeight
      gootAchter.position.x = z+Math.abs(pijpBoven)
      gootAchter.position.z = x+overhang
      this.group.add(gootAchter)
    }

    if(dakgoot.front.enabled) {
      if(dakgoot.front.left) { 
        const pijpVoorLinks = pijpVoor.clone()
        pijpVoorLinks.name = "pijpVoorLinks"
        pijpVoorLinks.position.z = dx/2
        const funnelVoorLinks = funnel.clone()
        funnelVoorLinks.name = "funnelVoorLinks"
        funnelVoorLinks.position.z = dx/2
        funnelVoorLinks.position.y = frontHeight
        funnelVoorLinks.position.x = pijpBoven
        this.group.add(pijpVoorLinks, funnelVoorLinks)

        let goot = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
        goot.removeFromParent()
        this.group.add(gootGat)
        gootGat.position.z = dx/2+overhang
        gootGat.updateMatrix()
        goot.updateMatrix()
        let goot2 = goot//CSG.subtract(goot, gootGat)
        goot2.name = "gootMesh"
        gootGat.removeFromParent()
        this.group.getObjectByName("gootVoor").add(goot2)
      }
      if(dakgoot.front.right) { 
        const pijpVoorRechts = pijpVoor.clone()
        pijpVoorRechts.name = "pijpVoorRechts"
        pijpVoorRechts.position.z = x-dx/2
        const funnelVoorRechts = funnel.clone()
        funnelVoorRechts.name = "funnelVoorRechts"
        funnelVoorRechts.position.z = x-dx/2
        funnelVoorRechts.position.y = frontHeight
        funnelVoorRechts.position.x = pijpBoven
        this.group.add(pijpVoorRechts, funnelVoorRechts)

        let goot = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
        goot.removeFromParent()
        this.group.add(gootGat)
        gootGat.position.z = x+overhang-dx/2 
        gootGat.updateMatrix()
        goot.updateMatrix()
        let goot2 = goot//CSG.subtract(goot, gootGat)
        goot2.name = "gootMesh"
        gootGat.removeFromParent()
        this.group.getObjectByName("gootVoor").add(goot2)
      }
      if(needs3rdPipe) {
        const frontWalls = walls.filter(wall => wall.rotation === Math.PI*1.5)
        const frontStaanderPositions = []
        frontWalls.forEach(wall => {
          frontStaanderPositions.push(wall.position.z + wall.width + dx/2)
        })
        const voorPosition = frontStaanderPositions.reduce((prev, curr) => {
          return (Math.abs(curr - x/2) < Math.abs(prev - x/2) ? curr : prev);
        });
        
        const pijpVoorMidden = pijpVoor.clone()
        pijpVoorMidden.name = 'pijpVoorMidden'
        pijpVoorMidden.position.z = voorPosition
        const funnelVoorMidden = funnel.clone()
        funnelVoorMidden.name = 'funnelVoorMidden'
        funnelVoorMidden.position.z = voorPosition
        funnelVoorMidden.position.y = frontHeight
        funnelVoorMidden.position.x = pijpBoven
        this.group.add(pijpVoorMidden, funnelVoorMidden)

        let gootVoor = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
        gootVoor.removeFromParent()
        this.group.add(gootGat)
        gootGat.position.z = voorPosition+overhang
        gootGat.updateMatrix()
        gootVoor.updateMatrix()
        let gootVoor2 = gootVoor//CSG.subtract(gootVoor, gootGat)
        gootVoor2.name = "gootMesh"
        gootGat.removeFromParent()
        this.group.getObjectByName("gootVoor").add(gootVoor2)
      }
    }
    if(dakgoot.back.enabled) {
      if(dakgoot.back.left) {
        const pijpAchterLinks = pijpAchter.clone()
        pijpAchterLinks.name = "pijpAchterLinks"
        pijpAchterLinks.rotation.y = Math.PI
        pijpAchterLinks.position.z = x-dx/2
        pijpAchterLinks.position.x = z
        const funnelAchterLinks = funnel.clone()
        funnelAchterLinks.name = "funnelAchterLinks"
        funnelAchterLinks.position.z = x-dx/2
        funnelAchterLinks.position.y = backHeight
        funnelAchterLinks.position.x = z+Math.abs(pijpBoven)
        this.group.add(pijpAchterLinks, funnelAchterLinks)

        let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
        goot.removeFromParent()
        this.group.add(gootGat)
        gootGat.position.z = dx/2+overhang
        gootGat.updateMatrix()
        goot.updateMatrix()
        let goot2 = goot//CSG.subtract(goot, gootGat)
        goot2.name = "gootMesh"
        gootGat.removeFromParent()
        this.group.getObjectByName("gootAchter").add(goot2)
      }
      if(dakgoot.back.right) {
        const pijpAchterRechts = pijpAchter.clone()
        pijpAchterRechts.name = "pijpAchterRechts"
        pijpAchterRechts.rotation.y = Math.PI
        pijpAchterRechts.position.z = dx/2
        pijpAchterRechts.position.x = z
        const funnelAchterRechts = funnel.clone()
        funnelAchterRechts.name = "funnelAchterRechts"
        funnelAchterRechts.position.z = dx/2
        funnelAchterRechts.position.y = backHeight
        funnelAchterRechts.position.x = z+Math.abs(pijpBoven)
        this.group.add(pijpAchterRechts, funnelAchterRechts)

        let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
        goot.removeFromParent()
        this.group.add(gootGat)
        gootGat.position.z = x-dx/2+overhang
        gootGat.updateMatrix()
        goot.updateMatrix()
        let goot2 = goot//CSG.subtract(goot, gootGat)
        goot2.name = "gootMesh"
        gootGat.removeFromParent()
        this.group.getObjectByName("gootAchter").add(goot2)
      }
      if(needs3rdPipe) {
        const backWalls = walls.filter(wall => wall.rotation === Math.PI*0.5)
        const backStaanderPositions = []
        backWalls.forEach(wall => {
          backStaanderPositions.push(wall.position.z - wall.width - dx/2)
        })
        const achterPosition = backStaanderPositions.reduce((prev, curr) => {
          return (Math.abs(curr - x/2) < Math.abs(prev - x/2) ? curr : prev);
        });

        const pijpAchterMidden = pijpAchter.clone()
        pijpAchterMidden.name = 'pijpAchterMidden'
        pijpAchterMidden.rotation.y = Math.PI
        pijpAchterMidden.position.z = achterPosition
        pijpAchterMidden.position.x = z
        console.error(pijpAchterMidden.position)
        const funnelAchterMidden = funnel.clone()
        funnelAchterMidden.name = 'funnelAchterMidden'
        funnelAchterMidden.position.z = achterPosition
        funnelAchterMidden.position.y = backHeight
        funnelAchterMidden.position.x = z+Math.abs(pijpBoven)
        this.group.add(pijpAchterMidden, funnelAchterMidden)

        let gootAchter = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
        gootAchter.removeFromParent()
        this.group.add(gootGat)
        gootGat.position.z = achterPosition+overhang
        gootGat.updateMatrix()
        gootAchter.updateMatrix()
        let gootAchter2 = gootAchter//CSG.subtract(gootAchter, gootGat)
        gootAchter2.name = "gootMesh"
        gootGat.removeFromParent()
        this.group.getObjectByName("gootAchter").add(gootAchter2)
      //   const targetedStaander = staanders.staandersX.children[Math.floor(staanders.staandersX.children.length/2)]
      //   console.log(targetedStaander)

      //   const pijpAchterMidden = pijpAchter.clone()
      //   pijpAchterMidden.rotation.y = Math.PI
      //   pijpAchterMidden.position.z = Math.abs(targetedStaander.position.z)+dx
      //   pijpAchterMidden.position.x = z
      //   const funnelAchterMidden = funnel.clone()
      //   funnelAchterMidden.position.z = Math.abs(targetedStaander.position.z)+dx
      //   funnelAchterMidden.position.y = backHeight
      //   funnelAchterMidden.position.x = z+Math.abs(pijpBoven)
      //   this.group.add(pijpAchterMidden, funnelAchterMidden)

      //   let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
      //   goot.removeFromParent()
      //   this.group.add(gootGat)
      //   gootGat.position.z = x-Math.abs(targetedStaander.position.z)-dx+overhang
      //   gootGat.updateMatrix()
      //   goot.updateMatrix()
      //   let goot2 = CSG.subtract(goot, gootGat)
      //   goot2.name = "gootMesh"
      //   gootGat.removeFromParent()
      //   this.group.getObjectByName("gootAchter").add(goot2)
      }
    }
  }

  setGoot(data) {
    console.log(data)
    this.dakgoot = data
    this.update(this.x, this.y, this.z, this.dakgootSize, this.dakgoot, this.overhang, this.dx, this.staanders, this.walls, this.modelType, this.kapschuurConfig, this.bz)
  }

  setMiddenGootPosition({frontWallPositions, backWallPositions}) {
    const voorPosition = frontWallPositions.reduce((prev, curr) => {
      return (Math.abs(curr - this.x/2) < Math.abs(prev - this.x/2) ? curr : prev);
    });

    this.group.getObjectByName("pijpVoorMidden").position.z = voorPosition
    this.group.getObjectByName("funnelVoorMidden").position.z = voorPosition

    const achterPosition = backWallPositions.reduce((prev, curr) => {
      return (Math.abs(curr - this.x/2) < Math.abs(prev - this.x/2) ? curr : prev);
    });

    this.group.getObjectByName("pijpAchterMidden").position.z = achterPosition
    this.group.getObjectByName("funnelAchterMidden").position.z = achterPosition

    const code = uuid()
    this.uuid = code
    const time = Date.now()
    this.lastUpdateTime = time

    setTimeout((time, code) => {
      console.log(Date.now() - this.lastUpdateTime, code, this.uuid)
      if(Date.now() - this.lastUpdateTime < 900 || code !== this.uuid){return}

      this.group.getObjectByName("gootVoor").removeFromParent()

      const needs3rdPipe = this.dakPlaatBreedteVoor > this.thirdPipeThreshold && this.thirdPipeThreshold // aangepast naar dakPlaatBreedteVoor i.p.v. staanderbreedte

      //const funnel = this.funnel
      const gootGat = this.gootGat
      
      const gootMeshForGroup = this.goot.clone()
      gootMeshForGroup.name = "gootMesh"
      gootMeshForGroup.scale.y = this.x+(this.overhang*2)
      
      const gootKopplaat = this.kopplaat

      const gootKopplaat2 = gootKopplaat.clone()
      gootKopplaat2.position.z = this.x + (this.overhang*2)-1

      const gootGroup = new this.THREE.Group()
      gootGroup.add(gootMeshForGroup, gootKopplaat, gootKopplaat2)
    

      if(this.dakgoot.front.enabled) {
        const gootVoor = gootGroup.clone()
        gootVoor.name = "gootVoor"
        gootVoor.position.y = this.y 
        gootVoor.position.x = -35-50-50
        gootVoor.position.z = -this.overhang
        this.group.add(gootVoor)

        if(this.dakgoot.front.left) { 
          let goot = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
          goot.removeFromParent()
          this.group.add(gootGat)
          gootGat.position.z = this.dx/2+this.overhang
          gootGat.updateMatrix()
          goot.updateMatrix()
          let goot2 = goot//CSG.subtract(goot, gootGat)
          goot2.name = "gootMesh"
          gootGat.removeFromParent()
          this.group.getObjectByName("gootVoor").add(goot2)
        }
        if(this.dakgoot.front.right) { 
          let goot = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
          console.log(this.group.getObjectByName("gootVoor"))
          goot.removeFromParent()
          this.group.add(gootGat)
          gootGat.position.z = this.x+this.overhang-this.dx/2 
          gootGat.updateMatrix()
          goot.updateMatrix()
          let goot2 = goot//CSG.subtract(goot, gootGat)
          goot2.name = "gootMesh"
          gootGat.removeFromParent()
          this.group.getObjectByName("gootVoor").add(goot2)
        }
        if(needs3rdPipe) {        
          let goot = this.group.getObjectByName("gootVoor").getObjectByName("gootMesh")
          goot.removeFromParent()
          this.group.add(gootGat)
          gootGat.position.z = voorPosition+this.overhang
          gootGat.updateMatrix()
          goot.updateMatrix()
          let goot2 = goot//CSG.subtract(goot, gootGat)
          goot2.name = "gootMesh"
          gootGat.removeFromParent()
          this.group.getObjectByName("gootVoor").add(goot2)
        }
      }

      if(this.dakgoot.back.enabled) {
        const gootAchter = gootGroup.clone()
        gootAchter.name = "gootAchter"
        gootAchter.position.y = this.y 
        gootAchter.position.x = -35-50-50
        gootAchter.position.z = -this.overhang
        this.group.add(gootAchter)

        if(this.dakgoot.back.left) { 
          let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
          goot.removeFromParent()
          this.group.add(gootGat)
          gootGat.position.z = this.dx/2+this.overhang
          gootGat.updateMatrix()
          goot.updateMatrix()
          let goot2 = goot//CSG.subtract(goot, gootGat)
          goot2.name = "gootMesh"
          gootGat.removeFromParent()
          this.group.getObjectByName("gootAchter").add(goot2)
        }
        if(this.dakgoot.back.right) { 
          let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
          console.log(this.group.getObjectByName("gootAchter"))
          goot.removeFromParent()
          this.group.add(gootGat)
          gootGat.position.z = this.x+this.overhang-this.dx/2 
          gootGat.updateMatrix()
          goot.updateMatrix()
          let goot2 = goot//CSG.subtract(goot, gootGat)
          goot2.name = "gootMesh"
          gootGat.removeFromParent()
          this.group.getObjectByName("gootAchter").add(goot2)
        }
        if(needs3rdPipe) {        
          let goot = this.group.getObjectByName("gootAchter").getObjectByName("gootMesh")
          goot.removeFromParent()
          this.group.add(gootGat)
          gootGat.position.z = achterPosition+this.overhang
          gootGat.updateMatrix()
          goot.updateMatrix()
          let goot2 = goot//CSG.subtract(goot, gootGat)
          goot2.name = "gootMesh"
          gootGat.removeFromParent()
          this.group.getObjectByName("gootAchter").add(goot2)
        }
      }
    }, 1000, time, code)
    

  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
  }
}