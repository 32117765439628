import * as graphql from '@/functions/graphql.js'
import * as errorHandeling from '@/functions/errorHandeling.js'
import router from '../router/index'

const state = {
  lastInvite: {},
  userAccount: {},
}

const mutations = {
  setInvite (state, invite) {
    state.lastInvite = invite
  },

  setLogin (state, data) {
    state.userAccount = data
  }
} 

const actions = {
  async sendInvite (context, { email, admin }) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          sendInvite(inviteData: {email: "${email}", admin: ${admin}}) {
            email
            inviteToken
            status
            superUser
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to send invite.')
    }

    context.commit('setInvite', response.data.sendInvite)
  },

  async createAccount (context, { email, token, password }) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          createAccount(accountData: {email: "${email}", token: "${token}", password: "${password}"})
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to create account.')
    }

    context.dispatch('showSucces', 'Account successfully created.')
    router.push('/account/login')
  },

  async login (context, { email, password }) {

    // config graphQL data
    const graphqlQuery = {
      query: `
        mutation {
          login(loginData: {email: "${email}", password: "${password}"}) {
            userId
            token
            superUser
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to login.')
    }

    // saving login token in localstate
    const expirationTime = 86400000 // 24h
    const expirationDate = new Date().getTime() + expirationTime

    localStorage.setItem('loginToken', response.data.login.token)
    localStorage.setItem('userId', response.data.login.userId)
    localStorage.setItem('tokenExpiration', expirationDate)
    localStorage.setItem('superUser', response.data.login.superUser)

    context.commit('setLogin', response.data.login)
    context.dispatch('showSucces', 'Successfully loged in.')
    router.push('/cms')
  },
  

  async autoLogin (context) {

    // get data from local storage
    const loginToken = localStorage.getItem('loginToken')
    const userId = localStorage.getItem('userId')
    const tokenExpiration = localStorage.getItem('tokenExpiration')
    const superUser = Boolean(localStorage.getItem('superUser'))

    const expiresIn = +tokenExpiration - new Date().getTime()

    if (expiresIn < 300000) { // < 5 min
      return
    } else {
      context.commit('setLogin', {
        userId: userId,
        token: loginToken,
        superUser: superUser,
      })
    }
  },

  async logout (context) {

    localStorage.removeItem('loginToken')
    localStorage.removeItem('userId')
    localStorage.removeItem('tokenExpiration')
    localStorage.removeItem('superUser')

    context.commit('setLogin', {
      userId: null,
      token: null,
      superUser: null,
    })
  },
}

const getters = {
  getLastInvite (state) {
    return state.lastInvite
  },

  getUserAccount (state) {
    return state.userAccount
  },

  isAuth (state) {
    if (state.userAccount) {
      return !!state.userAccount.token
    } else {
      return false
    }
  },

  isSuperUser (state) {
    if (state.userAccount) {
      return state.userAccount.superUser
    } else {
      return false
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}