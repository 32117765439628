// defines position of the vertices
function positionBuffer (sz, sy, sx, sox) {
  const a = sz
  const b = sy
  const c = sox
  const d = sz - sox
  const e = sx 

  return [ a, b, 0, 0, 0, 0, 0, c, 0, 0, c, 0, d, b, 0, a, b, 0, 0, c, e, 0, 0, e, a, b, e, a, b, e, d, b, e, 0, c, e, a, b, 0, d, b, 0, a, b, e, d, b, 0, d, b, e, a, b, e, d, b, 0, 0, c, 0, d, b, e, 0, c, 0, 0, c, e, d, b, e, 0, c, 0, 0, 0, 0, 0, c, e, 0, 0, 0, 0, 0, e, 0, c, e, 0, 0, 0, a, b, 0, 0, 0, e, a, b, 0, a, b, e, 0, 0, e ]
}

// defines position of the textures
function uvBuffer (sz, sy, sx, sox) { 
  const a = sz
  const b = sy
  const c = sox
  const d = sz - sox
  const e = sx 

  return [a, b, 0, 0, 0, c, 0, c, d, b, a, b, 0, c, 0, 0, a, b, a, b, d, b, 0, c, a, 1, d, 1, a, 1 - e, d, 1, d, 1 - e, a, 1 - e, b, 1, c, 1, b, 1 - e, c, 1, c, 1 - e, b, 1 - e, c, 1, 0, 1, c, 1 - e, 0, 1, 0, 1 - e, c, 1 - e, 0, 1, b, 1, 0, 1 - e, b, 1, b, 1 - e, 0, 1 - e]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  return [ 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, -0.8320503234863281, 0.5547001957893372, 0, -0.8320503234863281, 0.5547001957893372, 0, -0.8320503234863281, 0.5547001957893372, 0, -0.8320503234863281, 0.5547001957893372, 0, -0.8320503234863281, 0.5547001957893372, 0, -0.8320503234863281, 0.5547001957893372, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0.7808688282966614, -0.6246950626373291, 0, 0.7808688282966614, -0.6246950626373291, 0, 0.7808688282966614, -0.6246950626373291, 0, 0.7808688282966614, -0.6246950626373291, 0, 0.7808688282966614, -0.6246950626373291, 0, 0.7808688282966614, -0.6246950626373291, 0 ]
}

// group the vertices belonging to each of the 6 sides, allows for 6 different textures one for each side.
function groupBuffer () {
  return [
    {
      start: 0,
      count: 6,
      materialIndex: 0
    },
    {
      start: 6,
      count: 6,
      materialIndex: 1
    },
    {
      start: 12,
      count: 6,
      materialIndex: 2
    },
    {
      start: 18,
      count: 6,
      materialIndex: 3
    },
    {
      start: 24,
      count: 6,
      materialIndex: 4
    },
    {
      start: 30,
      count: 6,
      materialIndex: 5
    }
  ]
}

export { positionBuffer, uvBuffer, normalBuffer, groupBuffer }