// left side is vertical, and bottom side is horizontal  

// defines position of the verticesdakafwerking =
function positionBuffer (totaleBreedte, plankHoogte, dikte, degrees, ox, oy, oyl) {
  const radians = degrees * (Math.PI/180);
  const r1 = (90 - degrees) * (Math.PI/180);

  console.log(ox, oy, oyl)

  const x = totaleBreedte + ox
  const z = dikte / 2
  const h = (totaleBreedte+ox)*Math.tan(radians) 
  const i = plankHoogte/(Math.sin(r1)) 
  const j = plankHoogte*Math.sin(radians)
  const k = Math.sqrt((plankHoogte**2)-(j**2))
  const l = (i-oyl)/Math.tan(radians)


  console.log(x, z, h, i, j, k)

  //alert("h: " + h + " i: " + i + " j: " + j + " k: " + k)

  // h   ->   0
  // h-i   ->   -i
  // 0   ->   -h
  // -k   ->   -h-k

  // x   ->   0
  // 0   ->   -x
  // j   ->   -x+j


  return [
    //front
    0, 0, -z, 
    -x, -h-oyl, -z,
    -x, -h, -z, 

    0, 0, -z, 
    -x+l, -h-oyl, -z,
    -x, -h-oyl, -z,
    
    0, 0, -z, 
    0, -i, -z,
    -x+l, -h-oyl, -z,


    //back
    0, 0, z, 
    -x, -h, z,
    -x, -h-oyl, z,

    0, 0, z, 
    -x, -h-oyl, z,
    -x+l, -h-oyl, z,
    
    
    0, 0, z, 
    -x+l, -h-oyl, z,
    0, -i, z,


    //top
    0, 0, -z,
    -x, -h, -z,
    0, 0, z,

    -x, -h, -z,
    -x, -h, z,
    0, 0, z,


    //right
    -x, -h, -z,
    -x, -h-oyl, -z,
    -x, -h, z,

    -x, -h-oyl, -z,
    -x, -h-oyl, z,
    -x, -h, z,


    //bottom
    0, -i, -z,
    -x+l, -h-oyl, z,
    -x+l, -h-oyl, -z,
    
    0, -i, -z,
    0, -i, z,
    -x+l, -h-oyl, z,


    //bottom2
    -x, -h-oyl, -z,
    -x+l, -h-oyl, -z,
    -x, -h-oyl, z,
    
    -x+l, -h-oyl, -z,
    -x+l, -h-oyl, z,
    -x, -h-oyl, z,
    
    
    //left
    0, 0, -z,
    0, -i, z,
    0, -i, -z,

    0, 0, -z,
    0, 0, z,
    0, -i, z,
    
    
    
   
    /*0, -i, -z, 
    -x, -h, -z, 
    -x, -h, z, 
    -x+j, -h-k, -z, // j/0   -k/-y
    -x+j, -h-k, z, // j/0   -k/-y
    -x, -h, -z, 
    0, 0, -z,
    -x, -h, z, 
    0, 0, z,
    -x+j, -h-k, z, /// j/0   -k/-y
    0, -i, z, 
    -x+j, -h-k, -z, // j/0   -k/-y
    0, -i, -z, 
    -x, -h, z, 
    0, 0, z,
    -x+j, -h-k, z, // j/0   -k/-y
    0, -i, z, 
    0, 0, -z,
    -x, -h, -z, 
    0, -i, -z, 
    -x+j, -h-k, -z // j/0   -k/-y*/
  ]
}

// defines position of the textures
function uvBuffer (totaleBreedte, plankHoogte, dikte, degrees, ox, oy, oyl, maxTextureWith, maxTextureHeight) { 
  //return [0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0]
  //return [0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0]
  const radians = degrees * (Math.PI/180);
  const r1 = (90 - degrees) * (Math.PI/180);

  const x = totaleBreedte + ox
  const z = dikte / 2
  const h = totaleBreedte*Math.tan(radians)
  const i = plankHoogte/(Math.sin(r1))
  const j = plankHoogte*Math.sin(radians)
  const k = Math.sqrt((plankHoogte**2)-(j**2))
  const l = (i-oyl)/Math.tan(radians)

  const tw = maxTextureWith || 2330
  const th = maxTextureHeight || 145

  console.log(x, z, h, i, j, k, l, tw, th)
  return [
    //front
    0, i/145,
    Math.sqrt((x**2) + ((h+oyl)**2))/tw, (Math.sin(radians)*l)/th,
    Math.sqrt((x**2) + (h**2))/tw, i/145,

    0, i/145,
    Math.sqrt(((x-l)**2) + ((h+oyl)**2))/tw, 0,
    Math.sqrt((x**2) + ((h+oyl)**2))/tw, (Math.sin(radians)*l)/th, 

    0, i/145,
    Math.sqrt((i**2) - (plankHoogte**2))/tw, 0,
    Math.sqrt(((x-l)**2) + ((h+oyl)**2))/tw, 0,


    //back
    0, i/145,
    Math.sqrt((x**2) + (h**2))/tw, i/145,
    Math.sqrt((x**2) + ((h+oyl)**2))/tw, (Math.sin(radians)*l)/th,
    

    0, i/145,
    Math.sqrt((x**2) + ((h+oyl)**2))/tw, (Math.sin(radians)*l)/th,
    Math.sqrt(((x-l)**2) + ((h+oyl)**2))/tw, 0,
    

    0, i/145,
    Math.sqrt(((x-l)**2) + ((h+oyl)**2))/tw, 0,
    Math.sqrt((i**2) - (plankHoogte**2))/tw, 0,
    

    //top
    Math.sqrt((x**2) + (h**2))/tw, dikte/th,
    0, dikte/th,
    Math.sqrt((x**2) + (h**2))/tw, 0,

    0, dikte/th,
    0, 0,
    Math.sqrt((x**2) + (h**2))/tw, 0,

    
    //right
    0, oyl/th,
    0, 0,
    dikte/tw, oyl/th,

    0, 0,
    dikte/tw, 0,
    dikte/tw, oyl/th,


    //bottom
    Math.sqrt(((x-l)**2) + ((h+oyl)**2))/tw, 0,
    0, dikte/th,
    0, 0,

    Math.sqrt(((x-l)**2) + ((h+oyl)**2))/tw, 0,
    Math.sqrt(((x-l)**2) + ((h+oyl)**2))/tw, dikte/th,
    0, dikte/th,


    //bottom2
    dikte/tw +0.3, 0,
    dikte/tw +0.3, l/th,
    0.3, 0,

    dikte/tw +0.3, l/th,
    0.3, l/th,
    0.3, 0,


    //left
    i/tw, 0,
    0, dikte/th,
    0, 0,

    i/tw, 0,
    i/tw, dikte/th,
    0, dikte/th,




    0, 0, -z,
    0, -i, z,
    0, -i, -z,

    0, 0, -z,
    0, 0, z,
    0, -i, z,







    

    /*0, i/th, 
    dikte/tw, i/th, 
    0, 0, 
    dikte/tw, 0,

    0.2, plankHoogte/th, 
    ((dikte/tw )+0.2), plankHoogte/th,
    0.2, 0, 
    ((dikte/tw )+0.2), 0,

    0.7, dikte/th, 
    ((Math.sqrt((totaleBreedte**2)+(h**2))/tw )+0.7), dikte/th, 
    0.7, 0, 
    ((Math.sqrt((totaleBreedte**2)+(h**2))/tw )+0.7), 0,

    0.3, dikte/th, 
    ((Math.sqrt(((totaleBreedte-j)**2)+((h-i+k)**2))/tw )+0.3), dikte/th, 
    0.3, 0, 
    ((Math.sqrt(((totaleBreedte-j)**2)+((h-i+k)**2))/tw )+0.3), 0,

    0.5, plankHoogte/th,
    ((Math.sqrt((totaleBreedte**2)+(h**2))/tw )+0.5), plankHoogte/th,
    0.5, 0, 
    ((Math.sqrt(((totaleBreedte-j)**2)+((h-i+k)**2))/tw )+0.5), 0,

    Math.sqrt((totaleBreedte**2)+(h**2))/tw, plankHoogte/th,
    0, plankHoogte/th,
    Math.sqrt(((totaleBreedte-j)**2)+((h-i+k)**2))/tw, 0, 
    0, 0,*/
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  //return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
  //return [0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0,]
  //return [0,0,1]
  return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
}

// group the vertices belonging to each of the 6 sides, allows for 6 different textures one for each side.
function groupBuffer () {
  return [
    {
      start: 0,
      count: 6,
      materialIndex: 0
    },
    {
      start: 6,
      count: 6,
      materialIndex: 1
    },
    {
      start: 12,
      count: 6,
      materialIndex: 2
    },
    {
      start: 18,
      count: 6,
      materialIndex: 3
    },
    {
      start: 24,
      count: 6,
      materialIndex: 4
    },
    {
      start: 30,
      count: 6,
      materialIndex: 5
    }
  ]
}

export { positionBuffer, uvBuffer, normalBuffer, groupBuffer }