import * as THREE from 'three'

// de oud method om het eerst naar een THREE.Color te converteren werkt niet goed
// dit komt omdat de kleur aangepast word door de colorSpace
function hexToRgbObject(cssColor) {
  // Remove the "#" if present
  cssColor = cssColor.replace("#", "");

  // Parse the hexadecimal values
  const hexR = cssColor.substring(0, 2);
  const hexG = cssColor.substring(2, 4);
  const hexB = cssColor.substring(4, 6);

  // Convert hexadecimal to decimal
  const r = parseInt(hexR, 16) / 255;
  const g = parseInt(hexG, 16) / 255;
  const b = parseInt(hexB, 16) / 255;

  // Return the RGB object
  return { r, g, b };
}

export default function shaderMaterial(hex) {
  const c = hexToRgbObject(hex)//new THREE.Color(hex)
  const fragmentShader = `void main() {gl_FragColor = vec4(vec3(${c.r},${c.g},${c.b}),1.0);}`
  const material = new THREE.ShaderMaterial({fragmentShader})
  return material
}