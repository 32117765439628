import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
import * as planeBorderBuffer from '@/three/buffer-geometries/planeBorder.js'
import {CSG} from 'three-csg-ts';
import * as planeBuffer from '@/three/buffer-geometries/plane.js'
import * as triangleBuffer from '@/three/buffer-geometries/triangleBuffer.js'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'
import SelectElement from './selectElement.js'
import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader.js'
import store from '../../../../store';
import betonBand from './betonBand.js';
import Betonpoer from '../betonPoer.js';
import { v4 as uuid } from 'uuid';
import SelectFrame from './selectFrame.js'
import { SUBTRACTION, Brush, Evaluator } from 'three-bvh-csg';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
// const previewsServer = import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER;

export default class Muur {
  constructor(THREE, textureLoader, settings, x, y, z, set, schoor, schoor0, hoverLine0, staanderGrootte, font, staanderObject, hover, showFundering, models, totalOverhang, topgevelSpace, poer, wallConfig, dy, textureHoekprofielen, config, modelType, kapschuurConfig, ox, oy, graden, rbtpy, gy, veranda, borstweringComponent,hoverIsoMainGroup,mapIsoMainGroup) {
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.set = set
    this.schoor = schoor
    this.schoor0 = schoor0
    this.schoorGroup = null
    this.hoverLine0 = hoverLine0
    this.hoverLineGroup = null
    this.hoverIsoMainGroup = hoverIsoMainGroup
    this.mapIsoMainGroup = mapIsoMainGroup;

    this.staanderGrootte = staanderGrootte
    this.showFundering = showFundering
    this.gaten = [
      // {x: 1365, y: 2218, xo: null, yo: 2168 - 2218},
      // {x: 300, y: 500, xo: 1000, yo: 500},
      // {x: 100, y: 300, xo: 1500, yo: 0},
    ]
    this.binnenMuurTexture = null
    this.settings = settings || {
      binnenMuur: false,
      binnenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
      buitenMuur: false,
      buitenMuurTexture: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
      connectionBinnen: "horizontal",
      connectionBuiten: "horizontal",
      modelArray: []
    }
    // if(this.settings.binnenMuurTexture.id == "6622509ad0774fabfa45b6f9") {
    //   this.settings.binnenMuurTexture.open = true
    // } else {
    //   this.settings.binnenMuurTexture.open = false
    // }
    // if(this.settings.buitenMuurTexture.id == "6622509ad0774fabfa45b6f9") {
    //   this.settings.buitenMuurTexture.open = true
    // } else {
    //   this.settings.buitenMuurTexture.open = false
    // }
    // console.log("SET", set)
    this.settings.width = this.set.mx
    this.settings.position = {x: this.set.z, y: this.set.y, z: this.set.x}
    if(!this.settings.uuid){this.settings.uuid = uuid()}
    this.settings.isSteellook = false
    this.settings.rotation = set.rotation
    this.settings.tussenMuur = set.tussenMuur
    this.isSteellook = false
    this.isSlidingWall = this.settings.isSlidingWall || false
    if(this.isSlidingWall) {
      this.schuifwandKleur = this.settings.schuifwandKleur
      this.schuifwandRichting = this.settings.schuifwandRichting
      this.schuifwandPreference = this.settings.schuifwandPreference
    } else {
      this.schuifwandKleur = null
      this.schuifwandRichting = null
      this.schuifwandPreference = 0
    }
    this.wallOn = this.settings.binnenMuur || this.settings.buitenMuur || false
    this.x = x
    this.y = y
    this.z = z
    this.wz = wallConfig.wz
    this.fx = 40
    this.fz = 60
    this.fvs = 700
    this.fhs = 700
    this.totalOverhang = totalOverhang
    this.topgevelSpace = (set.rotation % Math.PI === 0)?topgevelSpace:0
    this.poer = poer
    this.wallConfig = wallConfig
    this.dy = dy
    this.textureHoekprofielen = textureHoekprofielen
    this.modelType = modelType
    this.kapschuurConfig = kapschuurConfig
    this.ox = ox
    this.oy = oy
    this.graden = graden
    this.rbtpy = rbtpy
    this.gy = gy
    this.veranda = veranda
    this.borstweringComponent = borstweringComponent
    this.spantV = null
    this.spantH = null
    this.group = null
    this.mapGroup = null
    this.mapHoverGroup = null
    this.modelGroup = null
    this.plusIconGroup = null
    this.plusIconGroupDisabled = []
    this.arrowGroup = null
    this.hover = hover
    this.selected = false
    this.box = null
    this.vector = null
    this.font = font
    this.wallButtonIndex = [
      {canFitAtLocation: false, enabled: false, hole: null, side: null, model: null, mapModel: null, modelBox: null, selected: false, component: null, settings: null}, 
      {canFitAtLocation: false, enabled: false, hole: null, side: null, model: null, mapModel: null, modelBox: null, selected: false, component: null, settings: null}, 
      {canFitAtLocation: false, enabled: false, hole: null, side: null, model: null, mapModel: null, modelBox: null, selected: false, component: null, settings: null},
      {canFitAtLocation: false},
      {canFitAtLocation: false},
      {canFitAtLocation: false}]
    this.elementSelected = false
    this.shouldShowPlusIcons = false
    this.mapHoverElements = {binnenmuurMap: null, buitenmuurMap: null}
    this.showInFuture = {binnen: false, buiten: false}
    this.movingWallBeginValue = null
    this.movingWallBeginPosition = null
    this.isUpdating = false
    this.movingSide = null
    this.isLeftLocked = true
    this.staanderObject = staanderObject
    this.wallStaander = null
    this.wallStaanderInfo = null
    this.mapWallStaander = null
    this.previousWallWidth = set.mx
    this.alignsXAxis = (set.rotation % Math.PI === 0)  //if wall is rotated vertical or horizontal       | or _    as seen from above
    this.alignedRotation = this.alignsXAxis ? (this.set.rotation % (Math.PI*2)/Math.PI) : ((this.set.rotation-Math.PI/2) % (Math.PI*2)/Math.PI)//if wall is rotated 180 degrees on its y-axis
    this.childWall = null
    this.parentWall = null
    this.tussenChildWall = null
    this.tussenParentWall = null
    this.config = {
      minWallWidth: config.minWallWidth,
      needsSpaceForStaander: config.needsSpaceForStaander,
      maxWallWidth: config.maxWallWidth
    }
    this.rootPosition = this.set.x
    this.isLocked = false

    this.lockIcon = null
    this.betonBand = null
    this.fundering = showFundering
    this.regelPakketTexture = '62e12a5fd15ec84582a1058e.jpeg';

    this.groupOffset = {
      x: 0,
      z: 0
    }

    this.transparentMaterial = new THREE.MeshBasicMaterial({transparent: true, opacity: 0.0})

    this.textureCube = new THREE.CubeTextureLoader().load([ 
      // require('@/assets/test/posx.jpg'), 
      // require('@/assets/test/negx.jpg'), 
      // require('@/assets/test/posy.jpg'), 
      // require('@/assets/test/negy.jpg'), 
      // require('@/assets/test/posz.jpg'), 
      // require('@/assets/test/negz.jpg') 
    ]);
    this.textureCube.encoding = THREE.sRGBEncoding;

    if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
      if(this.alignsXAxis) {
        if(this.alignedRotation) {
          // linkerwand
          this.groupOffset.z = this.fz/2 - this.staanderGrootte.dx/2
        } else {
          // rechterwand
          this.groupOffset.z = this.staanderGrootte.dx/2 - this.fz/2
        }
      }else{
        if(this.alignedRotation) {
          // voorwand
          this.groupOffset.x = this.fz/2 - this.staanderGrootte.dx/2
        } else {
          // achterwand
          this.groupOffset.x = this.staanderGrootte.dx/2 - this.fz/2
        }
      }
    }
    this.settings.position.x -= this.groupOffset.x
    this.settings.position.z -= this.groupOffset.z

    this.hoekProfiel = {
      left: null,
      right: null
    }

    this.hoekProfielConfig = {
      shouldRight: false, 
      shouldLeft: false, 
      shouldRightBoeideel: false, 
      shouldLeftBoeideel: false
    }

    this.lastExecutionTime = 0;

    if(this.modelType.isKapschuur && !this.alignsXAxis && !this.alignedRotation) {
      this.y = this.kapschuurConfig.achtergevelHoogte
      this.staanderObject.y = this.kapschuurConfig.achtergevelHoogte
      this.set.my = this.kapschuurConfig.achtergevelHoogte
    }

    this.bouw(THREE, textureLoader, schoor, schoor0, hoverLine0, staanderGrootte, this.gaten, font, hover, models, this.settings)
    // console.log(hover)
  }

  
  bouw(THREE, textureLoader, schoor, schoor0, hoverLine0, staanderGrootte, gaten, font, hover, models, settings) {
    this.isUpdating = true
    this.group = new this.THREE.Group()
    this.group.name = 'wall'

    this.group.position.z = this.set.x + this.groupOffset.z
    this.group.position.x = this.set.z + this.groupOffset.x

    this.regelPakketTextureLoaded = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)

    // if(this.settings.binnenMuurTexture.id == "6622509ad0774fabfa45b6f9") {
    //   this.settings.binnenMuurTexture.open = true
    // } else {
    //   this.settings.binnenMuurTexture.open = false
    // }
    // if(this.settings.buitenMuurTexture.id == "6622509ad0774fabfa45b6f9") {
    //   this.settings.buitenMuurTexture.open = true
    // } else {
    //   this.settings.buitenMuurTexture.open = false
    // }

    //console.log(font)
    // console.log(set)
    // buitenmuur 
    const binnenGeometry = new THREE.BufferGeometry()
    const wallHeightBinnen =  settings.binnenMuurTexture.open ? this.set.my / 4 : this.set.my
    binnenGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.set.mx, wallHeightBinnen, this.settings.binnenMuurTexture.open ? 5 : this.wz)), 3))
    binnenGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
    binnenGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
    binnenGeometry.setIndex(boxBuffer.indexBuffer());
    binnenGeometry.groups = boxBuffer.groupBuffer()

    const buitenGeometry = new THREE.BufferGeometry()
    const wallWidth = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !this.settings.buitenMuurTexture.open) ? this.set.mx + 2*this.staanderGrootte.dx : this.set.mx
    var preHeight = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.my+16 : this.set.my
    const wallHeightBuiten = settings.buitenMuurTexture.open ? preHeight / 4 : preHeight
    buitenGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(wallWidth, wallHeightBuiten, this.settings.buitenMuurTexture.open ? 5 : this.wz)), 3))
    buitenGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
    buitenGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
    buitenGeometry.setIndex(boxBuffer.indexBuffer());
    buitenGeometry.groups = boxBuffer.groupBuffer()

    const binnennMuurTexture = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.settings.binnenMuurTexture.url}`)
    
    if(this.settings.binnenMuurTexture.open) {
      this.textureSettings(binnennMuurTexture, 1, 1)
    } else {
      this.textureSettings(binnennMuurTexture, this.set.mx * 0.000351, wallHeightBinnen * 0.0004331)
    }

    const binnenMuurMaterial = new this.THREE.MeshStandardMaterial({
      map: binnennMuurTexture,
      opacity: this.settings.binnenMuur ? 1 : 0.75,
      transparent: this.settings.binnenMuurTexture.open,
      //side: THREE.BackSide,
      // depthWrite: false,
    });

    const buitenMuurTexture = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.settings.buitenMuurTexture.url}`)

    if(this.settings.buitenMuurTexture.open) {
      this.textureSettings(buitenMuurTexture, 1, 1)
    } else {
      this.textureSettings(buitenMuurTexture, wallWidth * 0.000351, wallHeightBuiten * 0.0004331)
    }

    if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
      let distanceFromWallsOrigin
      if(this.alignsXAxis) {
        if(this.alignedRotation) {
          distanceFromWallsOrigin = this.z - this.group.position.x
        } else {
          distanceFromWallsOrigin = this.group.position.x
        }
      } else {
        if(this.alignedRotation) {
          distanceFromWallsOrigin = this.group.position.z
        } else {
          distanceFromWallsOrigin = this.x - this.group.position.z
        }
      }
      buitenMuurTexture.offset.x = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
    }

    const buitenMuurMaterial = new this.THREE.MeshStandardMaterial({
      map: buitenMuurTexture,
      opacity: this.settings.buitenMuur ? 1 : 0.75,
      transparent: this.settings.buitenMuurTexture.open,
      //side: THREE.BackSide,
      // depthWrite: false,
    });
    let buitenMuur = new Brush(buitenGeometry, buitenMuurMaterial);
    buitenMuur.name = 'buitenMuur'
    buitenMuur.position.x = this.set.mx / 2
    buitenMuur.position.y = settings.buitenMuurTexture.open ? wallHeightBuiten : wallHeightBuiten / 2
    buitenMuur.position.z = this.wz / 2 + this.fz / 2 //+ (this.wallConfig.position === "buiten" && this.settings.buitenMuurTexture.open) ? this.groupOffset[this.alignsXAxis ? 'z' : 'x'] : 0
    buitenMuur.visible = this.settings.buitenMuur

    buitenMuur.castShadow = true; 
    buitenMuur.receiveShadow = true;

    //binnen muur
    let binnenMuur = new Brush(binnenGeometry, binnenMuurMaterial);
    binnenMuur.name = 'binnenMuur'
    binnenMuur.position.x = this.set.mx / 2
    binnenMuur.position.y = settings.binnenMuurTexture.open ? wallHeightBinnen : wallHeightBinnen / 2
    binnenMuur.position.z = -this.wz / 2 - this.fz / 2 //+ (this.wallConfig.position === "buiten" && this.settings.buitenMuurTexture.open) ? this.groupOffset[this.alignsXAxis ? 'z' : 'x'] : 0
    binnenMuur.visible = this.settings.binnenMuur

    binnenMuur.castShadow = true; 
    binnenMuur.receiveShadow = true;

    this.binnenMuurTexture = binnenMuurMaterial

    // gaten
    const gatenGroup = new this.THREE.Group()

    for (let i = 0; i < gaten.length; i++) {

      const set = gaten[i]

      const gatGeomtery = new THREE.BoxGeometry(set.x, set.y+1, 1000)
      const gat = new Brush(gatGeomtery, binnenMuurMaterial)
      gat.position.x = set.xo ? (set.xo + set.x / 2) : (this.set.mx / 2)
      gat.position.y = set.yo + set.y / 2 -0.5
      gat.visible = false

      binnenMuur.updateMatrix()
      buitenMuur.updateMatrix()
      gat.updateMatrix()

      binnenMuur = this.csgSubtract(binnenMuur, gat)//CSG.subtract(binnenMuur, gat)
      buitenMuur = this.csgSubtract(buitenMuur, gat)//CSG.subtract(buitenMuur, gat)

      gat.geometry.dispose()
    }

    binnenMuur.visible = this.settings.binnenMuur
    buitenMuur.visible = this.settings.buitenMuur

    // frame

    const frameGroup = new this.THREE.Group()
    frameGroup.name = "frameGroup"
    frameGroup.visible = this.wallOn

    if (gaten.length === 0) {

      const geometryfh = new THREE.BufferGeometry()
      geometryfh.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.set.mx, this.fx, this.fz)), 3))
      geometryfh.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      geometryfh.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      geometryfh.setIndex(boxBuffer.indexBuffer())
      geometryfh.groups = boxBuffer.groupBuffer()

      const fhTextureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
      this.textureSettings(fhTextureX, this.set.mx * 0.0005, this.fz * 0.0125)
      // this.textureSettings(fhTextureX, 1, 0.5)

      const fhMaterialX = new this.THREE.MeshStandardMaterial({
        map: fhTextureX,
        opacity: this.wallOn ? 1 : 0.75,
        transparent: !this.wallOn,
        //side: THREE.BackSide,
      });

      const fhTextureY = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
      this.textureSettings(fhTextureY, this.set.mx * 0.0005, this.fx * 0.0125)

      const fhMaterialY = new this.THREE.MeshStandardMaterial({
        opacity: this.wallOn ? 1 : 0.75,
        transparent: !this.wallOn,
        map: fhTextureY,
        //side: THREE.BackSide,
      });

      const frameH0 = new Brush(geometryfh, [
        fhMaterialY,
        fhMaterialY,
        fhMaterialX,
        fhMaterialX,
        fhMaterialY,
        fhMaterialY
      ]);
      frameH0.castShadow = true; 
      frameH0.receiveShadow = true;
      frameH0.position.x = this.set.mx/2
      frameH0.position.y = this.fx / 2

      const frameH1 = frameH0.clone()
      frameH1.position.y = this.set.my - this.fx / 2

      const geometryfv = new THREE.BufferGeometry()
      geometryfv.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.fx, this.set.my - 2 * this.fx, this.fz)), 3))
      geometryfv.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      geometryfv.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      geometryfv.setIndex(boxBuffer.indexBuffer())
      geometryfv.groups = boxBuffer.groupBuffer()

      const fvTextureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
      this.textureSettings(fvTextureX, this.set.my * 0.0005, this.fx * 0.0125, 0.5 * Math.PI)
      // this.textureSettings(fhTextureX, 1, 0.5)

      const fvMaterialX = new this.THREE.MeshStandardMaterial({
        opacity: this.wallOn ? 1 : 0.75,
        transparent: !this.wallOn,
        map: fvTextureX,
        //side: THREE.BackSide,
      });

      const fvTextureY = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
      this.textureSettings(fvTextureY, this.set.my * 0.0005, this.fz * 0.0125, 0.5 * Math.PI)

      const fvMaterialY = new this.THREE.MeshStandardMaterial({
        opacity: this.wallOn ? 1 : 0.75,
        transparent: !this.wallOn,
        map: fvTextureY,
        //side: THREE.BackSide,
      });

      const frameV0 = new Brush(geometryfv, [
        fvMaterialY,
        fvMaterialY,
        fvMaterialX,
        fvMaterialX,
        fvMaterialY,
        fvMaterialY
      ]);

      frameV0.castShadow = true; 
      frameV0.receiveShadow = true;

      frameV0.position.y = this.set.my / 2
      frameV0.position.x = this.fx / 2

      const frameV1 = frameV0.clone()
      frameV1.position.x = this.set.mx - this.fx / 2

      // berekende spanten verticaal
      const svGroup = new this.THREE.Group()

      const nsv = Math.ceil(this.set.mx / this.fvs)//Math.ceil((set.mx-this.fx) / (this.fvs+this.fx))
      // console.log(this.fvs)
      // console.log(nsv)
      const svSpacing = (this.set.mx - ((2 + nsv) * this.fx)) / (nsv + 1)//(set.mx-this.fx)/nsv

      for (let i = 0; i < nsv; i++) {

        const s = frameV0.clone()
        // console.log(svSpacing)
        s.position.x = this.fx / 2 + svSpacing * (i + 1) + this.fx * i

        svGroup.add(s)

      }

      this.spantV = frameV0

      // berekende spanten horizontaal
      const shGroup = new this.THREE.Group()

      const geometrysh = new THREE.BufferGeometry()
      geometrysh.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.set.mx - 2 * this.fx, this.fx, this.fz)), 3))
      geometrysh.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      geometrysh.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      geometrysh.setIndex(boxBuffer.indexBuffer())
      geometrysh.groups = boxBuffer.groupBuffer()

      const sh = new Brush(geometrysh, [
        fhMaterialY,
        fhMaterialY,
        fhMaterialX,
        fhMaterialX,
        fhMaterialY,
        fhMaterialY
      ]);

      sh.castShadow = true; 
      sh.receiveShadow = true;

      const nsh = 3 //Math.ceil(set.my / this.fhs)
      const hvSpacing = (this.set.my - ((2 + nsh) * this.fx)) / (nsh + 1)

      for (let i = 0; i < nsh; i++) {

        const s = sh.clone()

        s.position.y = (this.fx + this.fx / 2) + hvSpacing * (i + 1) + this.fx * i
        s.position.x = this.set.mx/2

        shGroup.add(s)
      }

      this.spantH = sh

      svGroup.name = "svGroup"
      shGroup.name = "shGroup"

      svGroup.visible =
        (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'vertical') : false) ||
        (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'vertical') : false)
      shGroup.visible =
        (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'horizontal') : false) ||
        (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'horizontal') : false)

      // frame groeperen
      svGroup.add(frameH0)
      svGroup.add(frameH1)
      svGroup.add(frameV0)
      svGroup.add(frameV1)

      shGroup.add(frameH0.clone())
      shGroup.add(frameH1.clone())
      shGroup.add(frameV0.clone())
      shGroup.add(frameV1.clone())
      frameGroup.add(svGroup)
      frameGroup.add(shGroup)

      frameGroup.position.x = 0//set.mx / 2

    } else {

      // bereken frame posities
      let frames = []

      for (let i = 0; i < (gaten.length + 1); i++) {

        let set, gx, fx, fo

        if (i === 0) {
          set = gaten[i]

          gx = set.xo ? set.xo : ((this.set.mx - set.x) / 2)
          fx = gx
          fo = 0

        } else if (i === gaten.length) {

          set = gaten[i - 1]

          gx = set.xo ? (this.set.mx - (set.xo + set.x)) : ((this.set.mx - set.x) / 2)
          fx = gx
          fo = frames[i - 1].fo + frames[i - 1].fx + set.x

        } else {
          set = gaten[i]

          gx = set.xo ? (set.xo + set.x / 2) : (this.set.mx / 2)
          fx = gx - (i > 0 ? frames[i].fx : 0)
          fo = (i > 0 ? frames[i].fx : 0)
        }

        frames.push({
          fx: fx,
          fo: fo
        })
      }

      for (let i = 0; i < frames.length; i++) {

        let frameSet = frames[i]

        const geometryfh = new THREE.BufferGeometry()
        geometryfh.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(frameSet.fx, this.fx, this.fz)), 3))
        geometryfh.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometryfh.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometryfh.setIndex(boxBuffer.indexBuffer())
        geometryfh.groups = boxBuffer.groupBuffer()

        const fhTextureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fhTextureX, frameSet.fx * 0.0005, this.fz * 0.0125)
        // this.textureSettings(fhTextureX, 1, 0.5)

        const fhMaterialX = new this.THREE.MeshStandardMaterial({
          map: fhTextureX,
          opacity: this.wallOn ? 1 : 0.75,
          transparent: !this.wallOn,
          //side: THREE.BackSide,
        });

        const fhTextureY = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fhTextureY, frameSet.fx * 0.0005, this.fx * 0.0125)

        const fhMaterialY = new this.THREE.MeshStandardMaterial({
          opacity: this.wallOn ? 1 : 0.75,
          transparent: !this.wallOn,
          map: fhTextureY,
          //side: THREE.BackSide,
        });

        const frameH0 = new Brush(geometryfh, [
          fhMaterialY,
          fhMaterialY,
          fhMaterialX,
          fhMaterialX,
          fhMaterialY,
          fhMaterialY
        ]);

        frameH0.castShadow = true; 
        frameH0.receiveShadow = true;

        frameH0.position.y = this.fx / 2

        const frameH1 = frameH0.clone()
        frameH1.position.y = this.set.my - this.fx / 2

        const geometryfv = new THREE.BufferGeometry()
        geometryfv.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.fx, this.set.my - 2 * this.fx, this.fz)), 3))
        geometryfv.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometryfv.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometryfv.setIndex(boxBuffer.indexBuffer())
        geometryfv.groups = boxBuffer.groupBuffer()

        const fvTextureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fvTextureX, this.set.my * 0.0005, this.fx * 0.0125, 0.5 * Math.PI)
        // this.textureSettings(fhTextureX, 1, 0.5)

        const fvMaterialX = new this.THREE.MeshStandardMaterial({
          opacity: this.wallOn ? 1 : 0.75,
          transparent: !this.wallOn,
          map: fvTextureX,
          //side: THREE.BackSide,
        });

        const fvTextureY = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fvTextureY, this.set.my * 0.0005, this.fz * 0.0125, 0.5 * Math.PI)

        const fvMaterialY = new this.THREE.MeshStandardMaterial({
          opacity: this.wallOn ? 1 : 0.75,
          transparent: !this.wallOn,
          map: fvTextureY,
          //side: THREE.BackSide,
        });

        const frameV0 = new Brush(geometryfv, [
          fvMaterialY,
          fvMaterialY,
          fvMaterialX,
          fvMaterialX,
          fvMaterialY,
          fvMaterialY
        ]);

        frameV0.castShadow = true; 
        frameV0.receiveShadow = true;

        frameV0.position.y = this.set.my / 2
        frameV0.position.x = frameSet.fx / 2 - this.fx / 2

        const frameV1 = frameV0.clone()
        frameV1.position.x = -frameSet.fx / 2 + this.fx / 2

        // berekende spanten verticaal
        const svGroup = new this.THREE.Group()

        const nsv = Math.ceil(frameSet.fx / this.fvs)
        const svSpacing = (frameSet.fx - ((2 + nsv) * this.fx)) / (nsv + 1)

        for (let i = 0; i < nsv; i++) {

          const s = frameV0.clone()

          s.position.x = (-frameSet.fx / 2 + this.fx / 2 + this.fx) + svSpacing * (i + 1) + this.fx * i

          svGroup.add(s)

        }

        this.spantV = frameV0

        // berekende spanten horizontaal
        const shGroup = new this.THREE.Group()

        const geometrysh = new THREE.BufferGeometry()
        geometrysh.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(frameSet.fx - 2 * this.fx, this.fx, this.fz)), 3))
        geometrysh.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometrysh.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometrysh.setIndex(boxBuffer.indexBuffer())
        geometrysh.groups = boxBuffer.groupBuffer()

        const sh = new Brush(geometrysh, [
          fhMaterialY,
          fhMaterialY,
          fhMaterialX,
          fhMaterialX,
          fhMaterialY,
          fhMaterialY
        ]);

        sh.castShadow = true; 
        sh.receiveShadow = true;

        const nsh = 3 //Math.ceil(set.my / this.fhs)
        const hvSpacing = (this.set.my - ((2 + nsh) * this.fx)) / (nsh + 1)

        for (let i = 0; i < nsh; i++) {

          const s = sh.clone()

          s.position.y = (this.fx + this.fx / 2) + hvSpacing * (i + 1) + this.fx * i

          shGroup.add(s)
        }

        this.spantH = sh

        svGroup.visible =
          (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'vertical') : false) ||
          (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'vertical') : false)
        shGroup.visible =
          (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'horizontal') : false) ||
          (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'horizontal') : false)

        // frame groeperen
        frameGroup.name="FrameGroup"
        frameGroup.add(frameH0)
        frameGroup.add(frameH1)
        frameGroup.add(frameV0)
        frameGroup.add(frameV1)
        frameGroup.add(svGroup)
        frameGroup.add(shGroup)

        frameGroup.position.x = frameSet.fo + frameSet.fx / 2
      }
    }

    // schoren
    const s0 = schoor0.group.clone()
    s0.position.y = this.set.my - schoor.sy
    s0.position.z = this.staanderGrootte.dz / 2 - schoor.sx
    const s1 = s0.clone()
    s1.position.x = this.set.mx
    s1.rotation.y = Math.PI
    s1.position.z = this.staanderGrootte.dz / 2

    s0.visible = !this.set.tussenMuur && (this.settings.buitenMuur ? (!!this.settings.buitenMuurTexture.open) : true) && (this.settings.binnenMuur ? (!!this.settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
    s1.visible = !this.set.tussenMuur && (this.settings.buitenMuur ? (!!this.settings.buitenMuurTexture.open) : true) && (this.settings.binnenMuur ? (!!this.settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)

    const schoorGroup = new this.THREE.Group()
    schoorGroup.add(s0)
    schoorGroup.add(s1)
    // schoorGroup.position.z = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) ? this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - schoor.sx)/2  : 0
    if (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) {
      if(!this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
        schoorGroup.position.z = this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2
      } else {
        schoorGroup.position.z = - (this.staanderGrootte.dx - this.schoor.sx)/2
      }
    } else {
      schoorGroup.position.z = 0
    }
    this.schoorGroup = schoorGroup
    this.schoorGroup.visible = this.set.mx >= this.schoor.wallMinWidth && !this.isSlidingWall && !this.isBorstwering

    this.group.add(this.schoorGroup)

    this.hoverLine0.group.visible = true //this.hover
    var binnenMuurKleur = this.settings.binnenMuurTexture.hex
    var buitenMuurKleur = this.settings.buitenMuurTexture.hex
    // console.log(this.settings.binnenMuur, this.settings.buitenMuur, binnenMuurKleur, buitenMuurKleur)
    this.hoverLine0.setHover({hover: {inside: this.settings.binnenMuur, outside: this.settings.buitenMuur}, colorInsideFill: binnenMuurKleur, colorOutsideFill: buitenMuurKleur})

    const selectFrame = new SelectFrame(THREE, this.set)
    this.selectFrame = selectFrame
    selectFrame.group.position.x = this.set.mx / 2
    selectFrame.group.position.y = 100
    selectFrame.group.visible = this.selected

    //plus icons for the doors and windows:
    const plusIconMap = new THREE.TextureLoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/plusicon.png`);
    plusIconMap.anisotropy = 0;
    //plusIconMap.magFilter = THREE.NearestFilter;
    //plusIconMap.minFilter = THREE.NearestFilter;
    plusIconMap.anisotropy = 16
    plusIconMap.encoding = THREE.sRGBEncoding;
    const spriteMaterial = new THREE.MeshBasicMaterial( { map: plusIconMap, side: THREE.DoubleSide, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );

    const plusIconMapLeft = new THREE.TextureLoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/plusiconleft.png`);
    plusIconMapLeft.anisotropy = 0;
    //plusIconMapLeft.magFilter = THREE.NearestFilter;
    //plusIconMapLeft.minFilter = THREE.NearestFilter;
    plusIconMapLeft.anisotropy = 16
    plusIconMapLeft.encoding = THREE.sRGBEncoding;
    const spriteMaterialLeft = new THREE.MeshBasicMaterial( { map: plusIconMapLeft, side: THREE.DoubleSide, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );

    const plusIconMapRight = new THREE.TextureLoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/plusiconright.png`);
    plusIconMapRight.anisotropy = 0;
    //plusIconMapRight.magFilter = THREE.NearestFilter;
    //plusIconMapRight.minFilter = THREE.NearestFilter;
    plusIconMapRight.anisotropy = 16
    plusIconMapRight.encoding = THREE.sRGBEncoding;
    const spriteMaterialRight = new THREE.MeshBasicMaterial( { map: plusIconMapRight, side: THREE.DoubleSide, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );

    const plusIconGroup = new this.THREE.Group()
    plusIconGroup.name = "plusIconGroup"
    this.plusIconGroup = plusIconGroup

    const spriteGeometry = new THREE.BufferGeometry()
    spriteGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(400, 400)), 3))
    spriteGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    spriteGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
    const plusIcon1 = new this.THREE.Mesh( spriteGeometry, spriteMaterial );
    plusIcon1.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
    plusIcon1.name = "plusIcon"
    const plusIcon2 = plusIcon1.clone()
    const plusIcon3 = plusIcon1.clone()
    const plusIcon4 = plusIcon1.clone()
    const plusIcon5 = plusIcon1.clone()
    const plusIcon6 = plusIcon1.clone()
    //facade buttons
    const plusIcon7 = new this.THREE.Mesh( spriteGeometry, spriteMaterialRight );
    const plusIcon8 = new this.THREE.Mesh( spriteGeometry, spriteMaterial );
    const plusIcon9 = new this.THREE.Mesh( spriteGeometry, spriteMaterialLeft );
    plusIcon7.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
    plusIcon8.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
    plusIcon9.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
    plusIcon7.name = "plusIcon"
    plusIcon8.name = "plusIcon"
    plusIcon9.name = "plusIcon"
    plusIcon7.isSteelLookButton = true
    plusIcon8.isSteelLookButton = true
    plusIcon9.isSteelLookButton = true

    plusIcon1.position.set((this.set.mx-1200)/4,1250,this.set.mz/2)
    plusIcon2.position.set((this.set.mx-1200)/4,1250,-this.set.mz/2)
    plusIcon3.position.set(this.set.mx/2-200,this.set.my/2+200,this.set.mz/2)
    plusIcon4.position.set(this.set.mx/2-200,this.set.my/2+200,-this.set.mz/2)
    plusIcon5.position.set(this.set.mx-(this.set.mx-1200)/4-400,1250,this.set.mz/2)
    plusIcon6.position.set(this.set.mx-(this.set.mx-1200)/4-400,1250,-this.set.mz/2)
    //facade buttons
    plusIcon7.position.set(0,1250,0)
    plusIcon8.position.set(this.set.mx/2-200,this.set.my/2+200,0)
    plusIcon9.position.set(this.set.mx-400,1250,0)


    plusIcon1.metaData = {plusIndex: 0, side: 0, facade: false}
    plusIcon2.metaData = {plusIndex: 0, side: 1, facade: false}
    plusIcon3.metaData = {plusIndex: 1, side: 0, facade: false}
    plusIcon4.metaData = {plusIndex: 1, side: 1, facade: false}
    plusIcon5.metaData = {plusIndex: 2, side: 0, facade: false}
    plusIcon6.metaData = {plusIndex: 2, side: 1, facade: false}
    //facade buttons
    plusIcon7.metaData = {plusIndex: 1, side: 0, facade: true}
    plusIcon8.metaData = {plusIndex: 2, side: 0, facade: true}
    plusIcon9.metaData = {plusIndex: 3, side: 0, facade: true}

    /*plusIcon1.visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled
    plusIcon2.visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled
    plusIcon3.visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled
    plusIcon4.visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled
    plusIcon5.visible = this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled
    plusIcon6.visible = this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled*/
    //plusIconGroup.add( plusIcon );.rotation.x = Math.PI/2

    this.plusIconGroupDisabled.push( plusIcon1 );
    this.plusIconGroupDisabled.push( plusIcon2 );
    this.plusIconGroupDisabled.push( plusIcon3 );
    this.plusIconGroupDisabled.push( plusIcon4 );
    this.plusIconGroupDisabled.push( plusIcon5 );
    this.plusIconGroupDisabled.push( plusIcon6 );
    //facade buttons
    this.plusIconGroupDisabled.push( plusIcon7 );
    this.plusIconGroupDisabled.push( plusIcon8 );
    this.plusIconGroupDisabled.push( plusIcon9 );

    const arrowRightMap = new THREE.TextureLoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/arrow_left.png`);
    arrowRightMap.anisotropy = 0;
    //arrowRightMap.magFilter = THREE.NearestFilter;
    //arrowRightMap.minFilter = THREE.NearestFilter;
    arrowRightMap.encoding = THREE.sRGBEncoding;
    const arrowRightMaterial = new THREE.MeshBasicMaterial( { map: arrowRightMap, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );
    const arrowRightGeometry = new THREE.BufferGeometry()
    arrowRightGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(400, 250)), 3))//300,300
    arrowRightGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    arrowRightGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
    const arrowRight = new this.THREE.Mesh( arrowRightGeometry, arrowRightMaterial );
    arrowRight.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
    arrowRight.name = "arrowRight"

    const arrowLeftMap = new THREE.TextureLoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/arrow_right.png`);
    arrowLeftMap.anisotropy = 0;
    //arrowLeftMap.magFilter = THREE.NearestFilter;
    //arrowLeftMap.minFilter = THREE.NearestFilter;
    arrowLeftMap.encoding = THREE.sRGBEncoding;
    const arrowLeftMaterial = new THREE.MeshBasicMaterial( { map: arrowLeftMap, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );
    const arrowLeftGeometry = new THREE.BufferGeometry()
    arrowLeftGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(400, 250)), 3))//300,300
    arrowLeftGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    arrowLeftGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
    const arrowLeft = new this.THREE.Mesh( arrowRightGeometry, arrowLeftMaterial );
    arrowLeft.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
    arrowLeft.name = "arrowLeft"

    arrowLeft.position.set(this.set.mx-250,this.set.mx<500?this.set.my/2+200+250:this.set.my/2+200,this.set.mz/2+11)
    arrowRight.position.set(0,this.set.mx<500?this.set.my/2+200-250:this.set.my/2+200,this.set.mz/2+11)

    // pijltjes voor binnenkant
    const arrowLeftBinnen = new this.THREE.Mesh( arrowRightGeometry, arrowLeftMaterial );
    arrowLeftBinnen.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2));
    arrowLeftBinnen.name = "arrowLeft"
    arrowLeftBinnen.position.set(this.set.mx-250,this.set.mx<500?this.set.my/2-200+250:this.set.my/2-200,-this.set.mz/2-11)
    const arrowRightBinnen = new this.THREE.Mesh(arrowLeftGeometry, arrowRightMaterial);
    arrowRightBinnen.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2));
    arrowRightBinnen.name = "arrowRight"
    arrowRightBinnen.position.set(0,this.set.mx<500?this.set.my/2-200+250:this.set.my/2-200,-this.set.mz/2-11)

    const arrowGroup = new this.THREE.Group()
    arrowGroup.name = "arrowGroup"
    this.arrowGroup = arrowGroup
    this.arrowGroup.add(arrowRight, arrowLeft, arrowLeftBinnen, arrowRightBinnen)
    this.arrowGroup.visible = this.selected
    // console.log(this.arrowGroup)

    const modelBoxGroup = new this.THREE.Group()
    modelBoxGroup.name = "modelBoxGroup"

    this.hoverLineGroup = new this.THREE.Group()
    this.hoverLineGroup.name = "hoverLineGroup"

    //const modelsGroup = new this.THREE.Group()

    this.group.add(this.hoverLineGroup)
    // console.log(this.hoverLineGroup)
    this.group.add(buitenMuur)
    this.group.add(binnenMuur)
    this.group.add(frameGroup)
    this.group.add(selectFrame.group)
    this.group.add(gatenGroup)
    //muur.add(modelsGroup)
    this.group.add(plusIconGroup)
    this.group.add(modelBoxGroup)
    //muur.add(this.arrowGroup)


    // console.log(Date.now())

    // console.log(hoverLine0.group.children[0])
    const hoverBinnen = this.hoverLine0.group.children[0].id
    const hoverBuiten = this.hoverLine0.group.children[2].id
    //plattegrond:
    const triangleMaterial = this.shaderMaterial('#505051')

    // console.log("LINKT", hoverBinnen, hoverBuiten, this.hoverLine0.group.children)

    const mapMuur = new this.THREE.Group()
    const buitenmuurMapGeometry = new THREE.BufferGeometry()
    buitenmuurMapGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(planeBorderBuffer.positionBuffer(53.5, this.set.mx, 7)), 3))
    buitenmuurMapGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer()), 2))
    buitenmuurMapGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(planeBuffer.normalBuffer()), 3))
    buitenmuurMapGeometry.groups = planeBorderBuffer.groupBuffer()
    //const buitenmuurMapMaterial = new THREE.MeshStandardMaterial( {color: 0x00ff00} );
    //const binnenmuurMapMaterial = new THREE.MeshStandardMaterial( {color: 0xff0000} );
    const transparentMaterial = new this.THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.0,
      depthWrite: false,
    });
    const buitenmuurMap = new THREE.Mesh(buitenmuurMapGeometry, [triangleMaterial, transparentMaterial]);
    buitenmuurMap.name = 'buitenmuurMap'
    buitenmuurMap.metaData = {}
    buitenmuurMap.metaData.hoverID = hoverBuiten

    buitenmuurMap.position.y = 100
    buitenmuurMap.position.z = -3.5
    buitenmuurMap.renderOrder = 5

    const binnenmuurMap = buitenmuurMap.clone()
    binnenmuurMap.material = [triangleMaterial, transparentMaterial]
    binnenmuurMap.name = 'binnenmuurMap'
    binnenmuurMap.metaData = {}
    binnenmuurMap.metaData.hoverID = hoverBinnen
    binnenmuurMap.position.z = -50

    buitenmuurMap.frustumCulled = false;
    binnenmuurMap.frustumCulled = false;

    this.mapHoverElements.buitenmuurMap = buitenmuurMap
    this.mapHoverElements.binnenmuurMap = binnenmuurMap

    if(this.settings.binnenMuur) {
      var binnenmuurMapMaterial = (this.settings.binnenMuur && this.settings.binnenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(binnenMuurKleur)
      this.mapHoverElements.binnenmuurMap.material[1] = binnenmuurMapMaterial
    }
    if(this.settings.buitenMuur) {
      var buitenmuurMapMaterial = (this.settings.buitenMuur && this.settings.buitenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(buitenMuurKleur)
      this.mapHoverElements.buitenmuurMap.material[1] = buitenmuurMapMaterial
    }

    this.mapGroup = mapMuur
    this.modelGroup = new this.THREE.Group()
    this.modelGroup.name = "modelGroup"

    const betweenLines = 200
    const betweenTextAndLine = 20
    const lineWidth = 20
    const alignLineWidth = 10
    const arrowLength = 200
    const arrowWidth = 120

    const arrowGeometry = new THREE.BufferGeometry()
    arrowGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(triangleBuffer.positionBuffer(arrowLength, arrowWidth)), 3))
    arrowGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(triangleBuffer.uvBuffer()), 2))
    arrowGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(triangleBuffer.normalBuffer()), 3))

    const lineGeometry = new THREE.BufferGeometry()
    lineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(lineWidth, this.set.mx - (2 * arrowLength))), 3))
    lineGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer()), 2))
    lineGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(planeBuffer.normalBuffer()), 3))

    const overhang = this.settings.tussenMuur ? 0 : (this.alignsXAxis ? (this.alignedRotation ? this.totalOverhang.l : this.totalOverhang.r) : (this.alignedRotation ? this.totalOverhang.v : this.totalOverhang.a))

    const arrow0 = new THREE.Mesh(arrowGeometry, triangleMaterial);
    const arrow1 = arrow0.clone()
    const line0 = new THREE.Mesh(lineGeometry, triangleMaterial);
    arrow0.rotation.y = 270 * Math.PI / 180
    arrow0.position.set(arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines-arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    arrow1.rotation.y = 90 * Math.PI / 180
    arrow1.position.set(this.set.mx - arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    line0.position.set(arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines +this.topgevelSpace)
    line0.name = 'line0'

    const alignLineGeometry = new THREE.BufferGeometry()
    alignLineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace, alignLineWidth)), 3))
    alignLineGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer()), 2))
    alignLineGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(planeBuffer.normalBuffer()), 3))

    const alignLine0 = new THREE.Mesh(alignLineGeometry, triangleMaterial);
    const alignLine1 = alignLine0.clone()

    alignLine0.position.set(-alignLineWidth, 0, this.set.mz / 2)
    alignLine0.rotation.y = 0 * Math.PI / 180
    alignLine0.renderOrder = -10
    alignLine1.position.set(this.set.mx, 0, this.set.mz / 2)
    alignLine1.rotation.y = 0 * Math.PI / 180
    alignLine1.renderOrder = -10


    const totalWidth = (Math.round(this.set.mx*2)/2).toString().replaceAll('.', ',')
    
    // console.log(font)
    const textGeometry = new TextGeometry(totalWidth, {
      font: font,
      size: 125,
      height: 1,
    })
    this.font = font

    const text0 = new THREE.Mesh(textGeometry, triangleMaterial)

    const box0 = new THREE.Box3().setFromObject(text0);
    const vector0 = new THREE.Vector3()
    box0.getSize(vector0)
    this.box = box0
    this.vector = vector0

    if (this.set.rotation * 180 / Math.PI === 270) {
      // console.log(vector0)
      text0.position.set((this.set.mx - vector0.x) / 2, 0, this.staanderGrootte.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine+vector0.y)
      text0.rotation.x = 270 * Math.PI / 180
      text0.rotation.z = 0 * Math.PI / 180
      //alert('gedraaid')
    } else {
      text0.rotation.x = 270 * Math.PI / 180
      text0.rotation.z = 180 * Math.PI / 180
      text0.position.set((this.set.mx + vector0.x) / 2, 0, this.staanderGrootte.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine +this.topgevelSpace)
    }
    const visible = this.settings.tussenMuur?this.wallOn:true
    line0.visible=visible
    arrow0.visible=visible
    arrow1.visible=visible
    alignLine0.visible=visible
    alignLine1.visible=visible
    text0.visible=visible

    // const mapIsoActiveGroup = this.getMapIsoIcon(true);
    // const mapIsoInactiveGroup = this.getMapIsoIcon(false);
    // mapIsoActiveGroup.visible = false; // name = 'isolatieMapButtonGroupActive'
    // mapIsoInactiveGroup.visible = true; // name = 'isolatieMapButtonGroupInactive'
    // const mapIsoMainGroup = new this.THREE.Group();
    // mapIsoMainGroup.name = 'isolatieMapButtonMainGroup';
    // mapIsoMainGroup.add(mapIsoActiveGroup);
    // mapIsoMainGroup.add(mapIsoInactiveGroup);
    // mapIsoMainGroup.position.set(this.set.mx*0.45, 0, this.set.mz / 2);
    // lower the position of the green button to help the raycaster hit the grey button first
    // this is necessary because the raycaster can still hit invisible objects
    // mapIsoActiveGroup.position.set(0, -1, 0);
    // console.log('this.mapIsoMainGroup',this.mapIsoMainGroup);
    // temp fix for missing map group
    if(!this.mapIsoMainGroup) {console.log('No map group provided!'); this.mapIsoMainGroup = new THREE.Group(); this.mapIsoMainGroup.name = 'EMPTY';}
    this.mapGroup.add(line0, arrow0, arrow1, alignLine0, alignLine1, text0, this.mapHoverElements.buitenmuurMap, this.mapHoverElements.binnenmuurMap, this.mapIsoMainGroup);
    // console.log(Date.now())

    // setTimeout(() => {
      // this.setHover(hover)
      // console.log(hover)
    // }, 1);
    
    this.hasFinishedBuilding = true
    
    // console.log(this.set)
    // console.log(Date.now())
    // console.log('------------------------------')

    this.isUpdating = false

    const svgLoader = new SVGLoader();
    svgLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/lock-open.svg`, (data) => {
      const paths = data.paths;
      const group = new THREE.Group();

      for ( let i = 0; i < paths.length; i ++ ) {

        const path = paths[ i ];

        const material = new THREE.MeshBasicMaterial( {
          color: this.isLocked ? 0xff0000 : 0xD4F028,
          side: THREE.DoubleSide,
        } );

        const shapes = SVGLoader.createShapes( path );

        for ( let j = 0; j < shapes.length; j ++ ) {

          const shape = shapes[ j ];
          const geometry = new THREE.ShapeGeometry( shape );
          const mesh = new THREE.Mesh( geometry, material );
          group.add( mesh );

        }

      }
      group.scale.multiplyScalar(25)
      group.rotation.x = Math.PI
      var box1 = new this.THREE.Box3().setFromObject(group);
      var height = box1.max.y - box1.min.y
      var width = box1.max.x - box1.min.x
      // console.log("antoon: " + width + " " + height)
      group.position.set((this.set.mx-width-200)/2,height+300,this.set.mz/2)
      this.lockIcon = group
      this.lockIcon.visible = false
      this.group.add(this.lockIcon)
    })

    this.betonBand = new betonBand(this.THREE, this.set.mx);
    this.betonBand.betonBand.visible = this.fundering && (this.settings.buitenMuur ? !this.settings.buitenMuurTexture.open : false || this.settings.binnenMuur ? !this.settings.binnenMuurTexture.open : false)
    this.group.add(this.betonBand.betonBand);




    // console.log(models)
    if(models) {
      let modelsLength = 0
      models.forEach(model=>{if(model){modelsLength++}})
      // console.log(modelsLength)
      if(modelsLength) {
        for(let i=0; i<models.length; i++) {
          if(models[i]) {
            // console.log('muur.js models[i]',models[i])
            // this.updateHoles([models[i].component.dimensions], models[i].model, models[i].map, i, models[i].side, models[i].component, {color: models[i].color, option: models[i].option})
            this.updateHoles([models[i].component.dimensions], models[i].model, models[i].map, i, models[i].side, models[i].component, 
              {lastCode: models[i].lastCode, color: models[i].color, option: models[i].option}) // TASK-1568
          }
        }
      }
    }

    if (this.borstweringComponent) {
      this.isBorstwering = true
      this.settings.isBorstwering = true
      this.modelCode = this.borstweringComponent.modelCode
      this.settings.modelCode = this.borstweringComponent.modelCode
      // this.dimensions = this.borstweringComponent.dimensions
      this.borstweringAssetHitboxGeometry = new THREE.BoxGeometry(this.borstweringComponent.dimensions.x, this.borstweringComponent.dimensions.y - this.borstweringComponent.dimensions.yo, 100)
      this.borstweringAssetHitboxMaterial = new THREE.MeshBasicMaterial({color: 0x00ff00, transparent: true, opacity: 0.0})
      this.borstweringAssetHitbox = new THREE.Mesh(this.borstweringAssetHitboxGeometry, this.borstweringAssetHitboxMaterial)
      this.borstweringAssetHitbox.name = "borstweringAssetHitbox"
      this.borstweringAssetHitbox.visible = false
      this.group.add(this.borstweringAssetHitbox)
      this.modelGroup.add(this.borstweringComponent.model)
      this.mapGroup.add(this.borstweringComponent.map)
      this.selectFrameBorstweringAsset = new SelectFrame(THREE, this.set, this.y-this.set.y)
      this.selectFrameBorstweringAsset.group.position.x = this.set.mx / 2
      this.selectFrameBorstweringAsset.group.position.y = 100 + this.set.y
      this.selectFrameBorstweringAsset.group.visible = this.selected && this.selectedBorstweringAsset
      this.group.add(this.selectFrameBorstweringAsset.group)
    }

    this.changeLockMode(this.settings.binnenMuur || this.settings.buitenMuur)

    // if(this.isSlidingWall) {
      this.update()
    // }
    this.updateMuurScalingForHitbox()
    this.updateNiceOffset()
  }

  update(y2, set2, schoor2, staanderGrootte2, shouldRenderFrame2, topgevelSpace, poer, wallConfig, dy, config, x, z, modelType, kapschuurConfig, ox, oy, graden, rbtpy, gy, veranda, immediatelyUpdateMap, _callback) {

    this.wallOn = (this.settings.binnenMuur || this.settings.buitenMuur || this.isSlidingWall)

    //set frame and subframe (horizontal or vertical group) visibility
    const frame = this.group.children[4]
    frame.visible = frame.visible || this.settings.binnenMuur || this.settings.buitenMuur
    frame.children[0].visible =
      (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'vertical') : false) ||
      (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'vertical') : false)
    frame.children[1].visible =
      (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'horizontal') : false) ||
      (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'horizontal') : false)

    if(this.fundering && (this.settings.buitenMuur ? !this.settings.buitenMuurTexture.open : false || this.settings.binnenMuur ? !this.settings.binnenMuurTexture.open : false)&& !this.isSlidingWall) {
      this.betonBand.betonBandShow()
    } else {
      this.betonBand.betonBandHide()
    }

    if(this.wallOn) {
      var binnenMuurKleur = this.settings.binnenMuurTexture.hex
      var buitenMuurKleur = this.settings.buitenMuurTexture.hex
      var binnenmuurMapMaterial = (this.settings.binnenMuur && this.settings.binnenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(binnenMuurKleur)
      var buitenmuurMapMaterial = (this.settings.buitenMuur && this.settings.buitenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(buitenMuurKleur)
      if (this.settings.binnenMuur) {
        //this.hoverLine0.group.children[0].material[1] = binnenmuurMapMaterial
        this.hoverLine0.setHover({hover: {inside: true}, colorInsideFill: binnenMuurKleur})
        this.mapHoverElements.binnenmuurMap.material[1] = binnenmuurMapMaterial
      }
      if (this.settings.buitenMuur) {
        //this.hoverLine0.group.children[1].material[1] = buitenmuurMapMaterial
        this.hoverLine0.setHover({hover: {outside: true}, colorOutsideFill: buitenMuurKleur})
        this.mapHoverElements.buitenmuurMap.material[1] = buitenmuurMapMaterial
      }
    } else {
      const transparentMaterial = new this.THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: this.THREE.DoubleSide,
        transparent: true,
        opacity: 0.0,
        depthWrite: false,
      });
      // console.log(this.hoverLine0.group.children[0])
      //this.hoverLine0.group.children[0].material[1] = transparentMaterial
      this.hoverLine0.setHover({hover: {inside: false}})
      this.mapHoverElements.binnenmuurMap.material[1] = transparentMaterial
      //this.hoverLine0.group.children[1].material[1] = transparentMaterial
      this.hoverLine0.setHover({hover: {outside: false}})
      this.mapHoverElements.buitenmuurMap.material[1] = transparentMaterial
    }

    // console.log("CurrentSettings: ", this.settings.binnenMuur)
    const y = y2 || this.y
    const set = set2 || this.set
    const schoor = schoor2 || this.schoor
    const staanderGrootte = staanderGrootte2 || this.staanderGrootte
    const shouldRenderFrame = shouldRenderFrame2 ? shouldRenderFrame2 || (this.settings.binnenMuur || this.settings.buitenMuur) : (this.settings.binnenMuur || this.settings.buitenMuur)
    // console.log("shouldRenderFrame", shouldRenderFrame, shouldRenderFrame2, this.settings.binnenMuur, this.settings.buitenMuur)
    //if(shouldRenderFrame && y2==undefined) {this.group.position.y=2000}
    this.alignsXAxis = (set.rotation % Math.PI === 0)  //if wall is rotated vertical or horizontal       | or _    as seen from above
    this.alignedRotation = this.alignsXAxis ? (set.rotation % (Math.PI*2)/Math.PI) : ((set.rotation-Math.PI/2) % (Math.PI*2)/Math.PI)
    this.rootPosition = set.x
    this.settings.rotation = set.rotation
    this.settings.tussenMuur = set.tussenMuur
    // console.log("rootPosition", this.rootPosition)
    this.set = set
    this.y = y
    this.schoor = schoor
    this.staanderGrootte = staanderGrootte

    if(topgevelSpace !== null && topgevelSpace !== undefined) {this.topgevelSpace = (this.set.rotation % Math.PI === 0)?topgevelSpace:0}
    if(poer !== null && poer !== undefined) {this.poer = poer}
    if(wallConfig !== null && wallConfig !== undefined) {
      this.wallConfig = wallConfig
      this.wz = wallConfig.wz
    }
    if(dy !== null && dy !== undefined) {this.dy = dy}
    if(config !== null && config !== undefined) {this.config = config}
    if(x !== null && x !== undefined) {this.x = x}
    if(z !== null && z !== undefined) {this.z = z}

    if(modelType !== null && modelType !== undefined) {this.modelType = modelType}
    if(kapschuurConfig !== null && kapschuurConfig !== undefined) {this.kapschuurConfig = kapschuurConfig}
    if(ox !== null && ox !== undefined) {this.ox = ox}
    if(oy !== null && oy !== undefined) {this.oy = oy}
    if(graden !== null && graden !== undefined) {this.graden = graden}
    if(rbtpy !== null && rbtpy !== undefined) {this.rbtpy = rbtpy}
    if(gy !== null && gy !== undefined) {this.gy = gy}
    if(veranda !== null && veranda !== undefined) {this.veranda = veranda}

    // if(this.staanderGrootte.y) this.staanderObject.y = this.staanderGrootte.y; console.log(this.staanderGrootte.y)
    if(this.staanderGrootte.dx) this.staanderObject.dx = this.staanderGrootte.dx
    if(this.staanderGrootte.dz) this.staanderObject.dz = this.staanderGrootte.dz

    if(this.modelType.isKapschuur && !this.alignsXAxis && !this.alignedRotation) {
      this.y = this.kapschuurConfig.achtergevelHoogte
      this.staanderObject.y = this.kapschuurConfig.achtergevelHoogte
      this.staanderGrootte.y = this.kapschuurConfig.achtergevelHoogte
      this.set.my = this.kapschuurConfig.achtergevelHoogte
    }

    this.groupOffset.x = 0
    this.groupOffset.z = 0

    if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
      if(this.alignsXAxis) {
        if(this.alignedRotation) {
          // linkerwand
          this.groupOffset.z = this.fz/2 - this.staanderGrootte.dx/2
        } else {
          // rechterwand
          this.groupOffset.z = this.staanderGrootte.dx/2 - this.fz/2
        }
      }else{
        if(this.alignedRotation) {
          // voorwand
          this.groupOffset.x = this.fz/2 - this.staanderGrootte.dx/2
        } else {
          // achterwand
          this.groupOffset.x = this.staanderGrootte.dx/2 - this.fz/2
        }
      }
    }

    const minimumWidth = (((500-this.staanderGrootte.dx)/2) + (20 / Math.tan(((90 - 22.5) / 180) * Math.PI))+ 20)*2
    this.hoverLine0.group.position.z = this.set.mx < minimumWidth ? (250 -  (minimumWidth-this.set.mx)/2) : 250
    
    let buitenMuur = this.group.children[2]
    let binnenMuur = this.group.children[3]
    let newBuitenMuur, newBinnenMuur

    const binnenGeometry = new this.THREE.BufferGeometry()
    const wallHeightBinnen =  this.settings.binnenMuurTexture.open && !this.isSlidingWall ? this.set.my / 4 : this.set.my
    binnenGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.set.mx, wallHeightBinnen, this.settings.binnenMuurTexture.open ? 5 : this.wz)), 3))
    binnenGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
    binnenGeometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
    binnenGeometry.setIndex(boxBuffer.indexBuffer());
    binnenGeometry.groups = boxBuffer.groupBuffer()

    const buitenGeometry = new this.THREE.BufferGeometry()
    const wallWidth = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !this.settings.buitenMuurTexture.open) ? this.set.mx + 2*this.staanderGrootte.dx : this.set.mx
    var preHeight = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.my+16 : this.set.my
    const wallHeightBuiten = this.settings.buitenMuurTexture.open && !this.isSlidingWall ? preHeight / 4 : preHeight
    buitenGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(wallWidth, wallHeightBuiten, this.settings.buitenMuurTexture.open ? 5 : this.wz)), 3))
    buitenGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
    buitenGeometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
    buitenGeometry.setIndex(boxBuffer.indexBuffer());
    buitenGeometry.groups = boxBuffer.groupBuffer()

    const newBuitenMuurMaterial = Array.isArray(buitenMuur.material) ? buitenMuur.material[0].clone() : buitenMuur.material.clone()
    if(this.settings.buitenMuurTexture.open) {
      this.textureSettings(newBuitenMuurMaterial.map, 1, 1)
    } else {
      this.textureSettings(newBuitenMuurMaterial.map, wallWidth * 0.000351, wallHeightBuiten * 0.0004331)
    }

    if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
      let distanceFromWallsOrigin
      if(this.alignsXAxis) {
        if(this.alignedRotation) {
          distanceFromWallsOrigin = this.z - this.group.position.x
        } else {
          distanceFromWallsOrigin = this.group.position.x
        }
      } else {
        if(this.alignedRotation) {
          distanceFromWallsOrigin = this.group.position.z
        } else {
          distanceFromWallsOrigin = this.x - this.group.position.z
        }
      }
      if(this.group.position.z === 30 && !this.group.parentWall && !this.group.childWall) {
        // console.log("antwoord", distanceFromWallsOrigin, this.staanderGrootte.dx, this.z, this.group.position.x)
      }
      newBuitenMuurMaterial.map.offset.x = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
      newBuitenMuurMaterial.map.needsUpdate = true
      newBuitenMuurMaterial.needsUpdate = true
      this.offsetX = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
      this.offsetX2 = newBuitenMuurMaterial.map.offset
    }
    newBuitenMuurMaterial.transparent = false
    newBuitenMuurMaterial.opacity = 1
    newBuitenMuurMaterial.map.needsUpdate = true
    newBuitenMuurMaterial.needsUpdate = true

    const newBinnenMuurMaterial = Array.isArray(binnenMuur.material) ? binnenMuur.material[0].clone() : binnenMuur.material.clone()
    newBinnenMuurMaterial.transparent = false
    newBinnenMuurMaterial.opacity = 1
    if(this.settings.binnenMuurTexture.open) {
      this.textureSettings(newBinnenMuurMaterial.map, 1, 1)
    } else {
      this.textureSettings(newBinnenMuurMaterial.map, this.set.mx * 0.000351, this.set.my * 0.0004331)
    }
    newBinnenMuurMaterial.map.needsUpdate = true
    newBinnenMuurMaterial.needsUpdate = true

    newBuitenMuur = new Brush(buitenGeometry, newBuitenMuurMaterial);
    newBinnenMuur = new Brush(binnenGeometry, newBinnenMuurMaterial);

    newBuitenMuur.castShadow = true; 
    newBuitenMuur.receiveShadow = true;
    newBinnenMuur.castShadow = true; 
    newBinnenMuur.receiveShadow = true;

    newBuitenMuur.position.x = this.set.mx / 2
    newBuitenMuur.position.y = this.settings.buitenMuurTexture.open && !this.isSlidingWall ? wallHeightBuiten : wallHeightBuiten / 2
    newBuitenMuur.position.z = this.wz / 2 + this.fz / 2 //+ (this.wallConfig.position === "buiten" && this.settings.buitenMuurTexture.open) ? this.groupOffset[this.alignsXAxis ? 'z' : 'x'] : 0

    newBinnenMuur.position.x = this.set.mx / 2
    newBinnenMuur.position.y = this.settings.binnenMuurTexture.open && !this.isSlidingWall ? wallHeightBinnen : wallHeightBinnen / 2
    newBinnenMuur.position.z = -this.wz / 2 - this.fz / 2 //+ (this.wallConfig.position === "buiten" && this.settings.buitenMuurTexture.open) ? this.groupOffset[this.alignsXAxis ? 'z' : 'x'] : 0

    for (let i = 0; i < this.group.children[6].children.length; i++) {

      this.group.children[6].children[this.group.children[6].children.length - 1].geometry.dispose()
      this.group.children[6].children[this.group.children[6].children.length - 1].removeFromParent()

    }

    // const gatMaterial = new this.THREE.MeshStandardMaterial();

    // gatMaterial.side = this.THREE.DoubleSide
    // gatMaterial.color.setHex(0xff0000).convertSRGBToLinear()


    let amountOfEnabledElements = 0
    let totalHoleLength = 0
    let enabledElementsArray = []
    for(var i=0; i<this.wallButtonIndex.length; i++) {
      if(this.wallButtonIndex[i].hole != null) {
        totalHoleLength += this.wallButtonIndex[i].hole.x 
      }
      // console.log(this.wallButtonIndex[i])
      if(this.wallButtonIndex[i].enabled) {
        amountOfEnabledElements++
        enabledElementsArray.push(i)
      }
    }

    this.modelGroup.children.forEach((model) => {
      model.removeFromParent()
    })

    this.group.children[8].children.forEach((model) => {
      model.removeFromParent()
    })


    //empty frame group:
    this.group.children[4].children[0].children = []
    this.group.children[4].children[1].children = []

    let modelArray = []

    

    for (let i = 0; i<enabledElementsArray.length; i++) {
      const set = this.wallButtonIndex[enabledElementsArray[i]].hole
      let modelPositionX
      

      if(amountOfEnabledElements === 1) {
        switch(enabledElementsArray[i]) {
          case 0:
            modelPositionX = (this.set.mx-set.x)/4
            break
          case 1:
            modelPositionX = (this.set.mx-set.x)/2
            break
          case 2:
            modelPositionX = (this.set.mx-set.x)/4 *3
            break
        }
      } else if(amountOfEnabledElements === 2) {
        switch(enabledElementsArray[i]) {
          case 0:
            modelPositionX = (this.set.mx-totalHoleLength)/4 + (i* (totalHoleLength-set.x))
            break
          case 1:
            modelPositionX = (this.set.mx-totalHoleLength)/4 + (i* (totalHoleLength-set.x))
            break
          case 2:
            modelPositionX = (this.set.mx-totalHoleLength)/4 + (i* (totalHoleLength-set.x + (this.set.mx-totalHoleLength)/2))
            break
        }
      }



      const gatGeomtery = new this.THREE.BoxGeometry(set.x, set.y - set.yo +1, 1000)

      // console.log(set.x, set.y - set.yo, this.wz * 2 + this.fz)
      const gatBuiten = new Brush(gatGeomtery, newBuitenMuurMaterial)
      gatBuiten.position.x = modelPositionX + set.x/2//set.xo ? (set.xo + set.x / 2) : (this.set.mx / 2)
      gatBuiten.position.y = set.yo + ((set.y - set.yo) / 2) -0.5
      gatBuiten.visible = true
      const gatBinnen = new Brush(gatGeomtery, newBinnenMuurMaterial)
      gatBinnen.position.x = modelPositionX + set.x/2//set.xo ? (set.xo + set.x / 2) : (this.set.mx / 2)
      gatBinnen.position.y = set.yo + ((set.y - set.yo) / 2) -0.5
      gatBinnen.visible = true

      //this.group.children[6].add(gat)

      


      this.wallButtonIndex[enabledElementsArray[i]].model.position.x = modelPositionX + (this.wallButtonIndex[enabledElementsArray[i]].side * set.x)//(this.set.mx - set.x) / 2
      this.wallButtonIndex[enabledElementsArray[i]].model.position.y = set.yo
      this.wallButtonIndex[enabledElementsArray[i]].model.position.z = set.zo
      this.wallButtonIndex[enabledElementsArray[i]].model.rotation.y = 0.5 * Math.PI + (this.wallButtonIndex[enabledElementsArray[i]].side * Math.PI)

      //margin_x = distance between left side of frame, and left side of hole
      //margin_y = distance between bottom of the frame and bottom of the hole
      modelArray.push({
        margin_x: modelPositionX,
        margin_y: set.yo,
        width: set.x,
        height: set.y - set.yo,
      })

      //console.log(model.children[0].children[1].children[0].children[0].children[0].material)
      //model.encoding = this.THREE.sRGBEncoding
      //model.name = "3DModel"
      /*console.log(model)
      try {
      model.children[0].children[1].children[0].children[0].children[0].material.map.encoding = this.THREE.sRGBEncoding;
      model.children[0].children[1].children[1].children[0].children[0].material.map.encoding = this.THREE.sRGBEncoding;
      model.children[0].children[1].children[2].children[0].children[0].material.map.encoding = this.THREE.sRGBEncoding;
      } catch {
        console.log('jammer man')
      }*/
      this.modelGroup.add(this.wallButtonIndex[enabledElementsArray[i]].model)
      this.mapGroup.add(this.wallButtonIndex[enabledElementsArray[i]].mapModel)
      //this.group.children[7].add(model)
      //console.log(model)
      //this.modelGroup.add(gat)

      var box1 = new this.THREE.Box3().setFromObject(this.wallButtonIndex[enabledElementsArray[i]].model);
      
      this.wallButtonIndex[enabledElementsArray[i]].modelBox = new SelectElement(this.THREE, set.x+20, (box1.max.y - box1.min.y)+20, this.staanderObject.dx+10, this.set.rotation).group
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.position.x = modelPositionX + set.x/2//(this.set.mx - set.x) / 2 + (box1.max.x - box1.min.x)/2
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.position.y = set.yo + (box1.max.y - box1.min.y) / 2
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.rotation.y = this.set.rotation //0.5 * Math.PI
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.visible = this.wallButtonIndex[enabledElementsArray[i]].selected
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.name = "ModelBox"
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.metaData = {}
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.metaData.ModelID = this.wallButtonIndex[enabledElementsArray[i]].model.id
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.metaData.ModelPosition = enabledElementsArray[i]
      this.wallButtonIndex[enabledElementsArray[i]].modelBox.metaData.Side = this.wallButtonIndex[enabledElementsArray[i]].side

      this.group.children[8].add(this.wallButtonIndex[enabledElementsArray[i]].modelBox)

      newBinnenMuur.updateMatrix()
      newBuitenMuur.updateMatrix()
      gatBuiten.updateMatrix()
      gatBinnen.updateMatrix()

      newBinnenMuur = this.csgSubtract(newBinnenMuur, gatBinnen)//CSG.subtract(newBinnenMuur, gat)
      newBuitenMuur = this.csgSubtract(newBuitenMuur, gatBuiten)//CSG.subtract(newBuitenMuur, gat)

      newBinnenMuur.castShadow = true; 
      newBinnenMuur.receiveShadow = true;
      newBuitenMuur.castShadow = true; 
      newBuitenMuur.receiveShadow = true;

      /*const hoverBinnen = this.hoverLine0.group.children[0].id
      const hoverBuiten = this.hoverLine0.group.children[1].id
      this.mapGroup.children[0].metaData.hoverID = hoverBuiten
      this.mapGroup.children[1].metaData.hoverID = hoverBinnen*/

      gatBuiten.geometry.dispose()
      gatBinnen.geometry.dispose()


      
      this.wallButtonIndex[enabledElementsArray[i]].mapModel.rotation.y = 0.5 * Math.PI + (this.wallButtonIndex[enabledElementsArray[i]].side * Math.PI)
      var box = new this.THREE.Box3().setFromObject(this.wallButtonIndex[enabledElementsArray[i]].mapModel);
      this.wallButtonIndex[enabledElementsArray[i]].mapModel.position.x = modelPositionX + (this.wallButtonIndex[enabledElementsArray[i]].side * (box.max.z-box.min.z)) + (set.x-Math.max((box.max.x-box.min.x),(box.max.z-box.min.z)))/2
      this.wallButtonIndex[enabledElementsArray[i]].mapModel.position.y = 2000//set.yo
      this.wallButtonIndex[enabledElementsArray[i]].mapModel.renderOrder = 20
      
      //this.wallButtonIndex[locationIndex].mapModel.name = "mapModel"
      //this.mapGroup.add(this.wallButtonIndex[locationIndex].mapModel)




      if (shouldRenderFrame !== false || shouldRenderFrame == false) {
        //frame elements around the door/window:
        const geometryfh = new this.THREE.BufferGeometry()
        geometryfh.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(set.x, this.fx, this.fz)), 3))
        geometryfh.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometryfh.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometryfh.setIndex(boxBuffer.indexBuffer())
        geometryfh.groups = boxBuffer.groupBuffer()

        const fhTextureX = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fhTextureX, this.set.x, this.fx * 0.0125, 0.5 * Math.PI)
        // this.textureSettings(fhTextureX, 1, 0.5)

        const fhMaterialX = new this.THREE.MeshStandardMaterial({
          map: fhTextureX,
          //side: this.THREE.BackSide,
        });

        const fhTextureY = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fhTextureY, this.set.x * 0.0005, this.fx * 0.0125, 0.5 * Math.PI)

        const fhMaterialY = new this.THREE.MeshStandardMaterial({
          map: fhTextureY,
          //side: this.THREE.BackSide,
        });

        const frameH0 = new Brush(geometryfh, [
          fhMaterialY,
          fhMaterialY,
          fhMaterialX,
          fhMaterialX,
          fhMaterialY,
          fhMaterialY
        ]);

        frameH0.castShadow = true; 
        frameH0.receiveShadow = true;

        frameH0.position.y = set.yo - this.fx/2
        frameH0.position.x = modelPositionX + set.x/2
        const frameH1 = frameH0.clone()
        frameH1.position.y = set.yo + this.fx/2 + (set.y - set.yo)
        // console.log(set.y)
        // console.log(this.group.children[4])
        const frameArray = this.group.children[4]
        //const group1 = new this.THREE.Group()
        //const group2 = group1.clone()
        if(set.yo > this.fx*2){frameArray.children[0].add(frameH0)}
        frameArray.children[0].add(frameH1)


        //horizontal beams:
        if(set.yo > this.fx*2){frameArray.children[1].add(frameH0.clone())}
        frameArray.children[1].add(frameH1.clone())




        // console.log(this.fx, this.set.my - 2 * this.fx, this.fz)
        const geometryfv = new this.THREE.BufferGeometry()
        geometryfv.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.fx, this.set.my - 2 * this.fx, this.fz)), 3))
        geometryfv.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometryfv.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometryfv.setIndex(boxBuffer.indexBuffer())
        geometryfv.groups = boxBuffer.groupBuffer()

        const fvTextureX = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fvTextureX, this.set.my * 0.0005, this.fx * 0.0125, 0.5 * Math.PI)
        // this.textureSettings(fhTextureX, 1, 0.5)

        const fvMaterialX = new this.THREE.MeshStandardMaterial({
          map: fvTextureX,
          //side: this.THREE.BackSide,
        });

        const fvTextureY = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fvTextureY, this.set.my * 0.0005, this.fz * 0.0125, 0.5 * Math.PI)

        const fvMaterialY = new this.THREE.MeshStandardMaterial({
          map: fvTextureY,
          //side: this.THREE.BackSide,
        });

        const frameV0 = new Brush(geometryfv, [
          fvMaterialY,
          fvMaterialY,
          fvMaterialX,
          fvMaterialX,
          fvMaterialY,
          fvMaterialY
        ]);

        frameV0.castShadow = true; 
        frameV0.receiveShadow = true;

        frameV0.position.y = this.set.my / 2
        frameV0.position.x = modelPositionX - this.fx/2
        const frameV1 = frameV0.clone()
        frameV1.position.y = this.set.my/2
        frameV0.position.x = modelPositionX + set.x + this.fx/2

        frameArray.children[0].add(frameV0)
        frameArray.children[0].add(frameV1)

        
        
        
        //horizontal beams:
        // console.log(set)
        const geometryfv1 = new this.THREE.BufferGeometry()
        geometryfv1.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.fx, set.y - (set.yo>this.fx ? set.yo : 0) + (set.yo>this.fx ? 2 : 1)*this.fx, this.fz)), 3))
        geometryfv1.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometryfv1.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometryfv1.setIndex(boxBuffer.indexBuffer())
        geometryfv1.groups = boxBuffer.groupBuffer()

        const fvTextureXV = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fvTextureXV, this.set.my * 0.0005, this.fx * 0.0125, 0.5 * Math.PI)
        const fvMaterialXV = new this.THREE.MeshStandardMaterial({map: fvTextureXV/*, side: this.THREE.BackSide*/});

        const fvTextureYV = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fvTextureYV, this.set.my * 0.0005, this.fz * 0.0125, 0.5 * Math.PI)
        const fvMaterialYV = new this.THREE.MeshStandardMaterial({map: fvTextureYV/*, side: this.THREE.BackSide*/});

        const frameV0V = new Brush(geometryfv1, [
          fvMaterialYV,
          fvMaterialYV,
          fvMaterialXV,
          fvMaterialXV,
          fvMaterialYV,
          fvMaterialYV
        ]);

        frameV0V.castShadow = true; 
        frameV0V.receiveShadow = true;

        frameV0V.position.y = (set.yo>this.fx ? set.yo + (set.y - set.yo)/2 : (set.y + 1*this.fx)/2) //set.yo + (set.y - set.yo)/2
        frameV0V.position.x = modelPositionX - this.fx/2
        const frameV1V = frameV0V.clone()
        frameV0V.position.x = modelPositionX + set.x + this.fx/2

        frameArray.children[1].add(frameV0V)
        frameArray.children[1].add(frameV1V)






        // console.log(frameArray)
        //frameArray.children[4].add(group1)
        //frameArray.children[4].add(group2)
      
      

      }


    }


    /*const hoverBinnen = this.hoverLine0.group.children[0].id
    const hoverBuiten = this.hoverLine0.group.children[1].id
    this.mapGroup.children[0].metaData.hoverID = hoverBuiten
    this.mapGroup.children[1].metaData.hoverID = hoverBinnen*/

    this.group.add(newBuitenMuur)
    this.group.add(newBinnenMuur)

    this.buitenMuur = newBuitenMuur
    this.binnenMuur = newBinnenMuur

    this.group.chidren = this.group.children.splice(2, 1);
    this.group.chidren = this.group.children.splice(2, 0, newBuitenMuur)

    this.group.chidren = this.group.children.splice(3, 1);
    this.group.chidren = this.group.children.splice(3, 0, newBinnenMuur)

    this.group.chidren = this.group.children.pop()
    this.group.chidren = this.group.children.pop()

    newBinnenMuur.visible = this.settings ? this.settings.binnenMuur : false
    newBuitenMuur.visible = this.settings ? this.settings.buitenMuur : false

    /*this.mapGroup.children[0].metaData.hoverID = newBuitenMuur.id
    this.mapGroup.children[1].metaData.hoverID = newBuitenMuur.id*/

    this.settings.isSlidingWall = this.isSlidingWall
    this.settings.schuifwandKleur = this.schuifwandKleur
    this.settings.schuifwandRichting = this.schuifwandRichting
    this.settings.schuifwandPreference = this.schuifwandPreference
    if(!this.isSlidingWall) {

      this.modelGroup.children = this.modelGroup.children.filter(child => child.name !== "glaswandModel")
      this.mapGroup.children = this.mapGroup.children.filter(child => child.name !== "glasplaatMap")

      if (shouldRenderFrame !== false || shouldRenderFrame == false) {
        // bereken frame posities
        // let frames = []
        // console.log(this.gaten)
        /*for (let i = 0; i < (this.gaten.length + 1); i++) {

          let set, gx, fx, fo

          if (i === 0) {
            set = this.gaten[i]

            gx = set.xo ? set.xo : ((this.set.mx - set.x) / 2)
            fx = gx
            fo = 0

          } else if (i === this.gaten.length) {

            set = this.gaten[i - 1]

            gx = set.xo ? (this.set.mx - (set.xo + set.x)) : ((this.set.mx - set.x) / 2)
            fx = gx
            fo = frames[i - 1].fo + frames[i - 1].fx + set.x

          } else {
            set = this.gaten[i]

            gx = set.xo ? (set.xo + set.x / 2) : (this.set.mx / 2)
            fx = gx - (i > 0 ? frames[i].fx : 0)
            fo = (i > 0 ? frames[i].fx : 0)
          }

          frames.push({
            fx: fx,
            fo: fo
          })
        }*/
        // console.log(frames)

        //console.log(this.set)
        const geometryfh = new this.THREE.BufferGeometry()
        geometryfh.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.set.mx, this.fx, this.fz)), 3))
        geometryfh.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometryfh.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometryfh.setIndex(boxBuffer.indexBuffer())
        geometryfh.groups = boxBuffer.groupBuffer()

        const fhTextureX = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fhTextureX, this.set.mx * 0.0005, this.fz * 0.0125)
        // this.textureSettings(fhTextureX, 1, 0.5)

        const fhMaterialX = new this.THREE.MeshStandardMaterial({
          map: fhTextureX,
          //side: this.THREE.BackSide,
        });

        const fhTextureY = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fhTextureY, this.set.mx * 0.0005, this.fx * 0.0125)

        const fhMaterialY = new this.THREE.MeshStandardMaterial({
          map: fhTextureY,
          //side: this.THREE.BackSide,
        });

        var frameH0 = new Brush(geometryfh, [
          fhMaterialY,
          fhMaterialY,
          fhMaterialX,
          fhMaterialX,
          fhMaterialY,
          fhMaterialY
        ]);

        frameH0.castShadow = true; 
        frameH0.receiveShadow = true;

        frameH0.position.x = this.set.mx / 2
        frameH0.position.y = this.fx / 2

        var frameH1 = frameH0.clone()
        frameH1.position.y = this.set.my - this.fx / 2

        const geometryfv = new this.THREE.BufferGeometry()
        geometryfv.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.fx, this.set.my - 2 * this.fx, this.fz)), 3))
        geometryfv.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometryfv.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometryfv.setIndex(boxBuffer.indexBuffer())
        geometryfv.groups = boxBuffer.groupBuffer()

        const fvTextureX = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fvTextureX, this.set.my * 0.0005, this.fx * 0.0125, 0.5 * Math.PI)
        // this.textureSettings(fhTextureX, 1, 0.5)

        const fvMaterialX = new this.THREE.MeshStandardMaterial({
          map: fvTextureX,
          //side: this.THREE.BackSide,
        });

        const fvTextureY = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        this.textureSettings(fvTextureY, this.set.my * 0.0005, this.fz * 0.0125, 0.5 * Math.PI)

        const fvMaterialY = new this.THREE.MeshStandardMaterial({
          map: fvTextureY,
          //side: this.THREE.BackSide,
        });

        var frameV0 = new Brush(geometryfv, [
          fvMaterialY,
          fvMaterialY,
          fvMaterialX,
          fvMaterialX,
          fvMaterialY,
          fvMaterialY
        ]);

        frameV0.castShadow = true; 
        frameV0.receiveShadow = true;

        frameV0.position.y = this.set.my / 2
        frameV0.position.x = this.fx / 2 

        var frameV1 = frameV0.clone()
        frameV1.position.x = this.set.mx - this.fx / 2


        var frameH02 = frameH0.clone()
        var frameH12 = frameH1.clone()
        var frameV02 = frameV0.clone()
        var frameV12 = frameV1.clone()

        const gatTussenTexture = this.regelPakketTextureLoaded.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.regelPakketTexture}`)
        const gatTussenMaterial = new this.THREE.MeshStandardMaterial({map: gatTussenTexture})

        //intersection with models:
        for(let i=0; i<modelArray.length; i++) {
          const gatGeomtery = new this.THREE.BoxGeometry(modelArray[i].width, modelArray[i].height +1, 1000)
          const gat = new Brush(gatGeomtery, gatTussenMaterial)
          gat.position.x = modelArray[i].margin_x + modelArray[i].width/2
          gat.position.y = modelArray[i].margin_y + modelArray[i].height/2 -0.5
          gat.visible = true
          //this.group.children[4].children[0].add(gat)

          frameH0.updateMatrix()
          frameH1.updateMatrix()
          frameV0.updateMatrix()
          frameV1.updateMatrix()
          gat.updateMatrix()
          frameH0 = this.csgSubtract(frameH0, gat)//CSG.subtract(frameH0, gat)
          frameH1 = this.csgSubtract(frameH1, gat)//CSG.subtract(frameH1, gat)
          frameV0 = this.csgSubtract(frameV0, gat)//CSG.subtract(frameV0, gat)
          frameV1 = this.csgSubtract(frameV1, gat)//CSG.subtract(frameV1, gat)

          gat.geometry.dispose()
        }

        for(let i=0; i<modelArray.length; i++) {
          const gatGeomtery = new this.THREE.BoxGeometry(modelArray[i].width + this.fx*2, modelArray[i].height +1, 1000)
          const gat = new Brush(gatGeomtery, gatTussenMaterial)
          gat.position.x = modelArray[i].margin_x + modelArray[i].width/2
          gat.position.y = modelArray[i].margin_y + modelArray[i].height/2 -0.5
          gat.visible = true
          //this.group.children[4].children[0].add(gat)

          frameH02.updateMatrix()
          frameH12.updateMatrix()
          frameV02.updateMatrix()
          frameV12.updateMatrix()
          gat.updateMatrix()
          frameH02 = this.csgSubtract(frameH02, gat)//CSG.subtract(frameH02, gat)
          frameH12 = this.csgSubtract(frameH12, gat)//CSG.subtract(frameH12, gat)
          frameV02 = this.csgSubtract(frameV02, gat)//CSG.subtract(frameV02, gat)
          frameV12 = this.csgSubtract(frameV12, gat)//CSG.subtract(frameV12, gat)

          gat.geometry.dispose()
        }
        
        this.group.children[4].children[0].add(frameH0)
        this.group.children[4].children[0].add(frameH1)
        this.group.children[4].children[0].add(frameV0)
        this.group.children[4].children[0].add(frameV1)

        this.group.children[4].children[1].add(frameH02)
        this.group.children[4].children[1].add(frameH12)
        this.group.children[4].children[1].add(frameV02)
        this.group.children[4].children[1].add(frameV12)

        // console.log(this.group.children[4].children[0])
        //this.group.children[4].children[4] = []

        // console.log(modelArray)
        // console.log(modelArray.length)
        //check space between models
        for(let i=0; i<(modelArray.length+1); i++) {
          // console.log(set)
          const distance = (i == modelArray.length ? this.set.mx : modelArray[i].margin_x) - (i==0 ? 0 : modelArray[i-1].margin_x+modelArray[i-1].width)
          // console.log(distance)
          if(distance > 700) {
            const amountOfExtraBeams = Math.ceil(distance/700)
            const distanceBetweenExtraBeams = distance/amountOfExtraBeams
            for(let j=0; j<(amountOfExtraBeams-1); j++) {
              const extraVerticalFrame = frameV0.clone()
              extraVerticalFrame.position.x = (i == 0 ? 0 : modelArray[i-1].margin_x + modelArray[i-1].width) + distanceBetweenExtraBeams*(j+1)
              // console.log((i == 0 ? 0 : modelArray[i-1].margin_x + modelArray[i-1].width) + distanceBetweenExtraBeams*(j+1))
              this.group.children[4].children[0].add(extraVerticalFrame)
            }
          }
        }
        //check space a model takes up
        for(let i=0; i<modelArray.length; i++) {
          if(modelArray[i].width > 700) {
            const amountOfExtraBeams = Math.ceil(modelArray[i].width/700)
            // console.log(modelArray[i].width)
            // console.log(modelArray[i].width/700)
            const distanceBetweenExtraBeams = modelArray[i].width/amountOfExtraBeams
            for(let j=0; j<(amountOfExtraBeams-1); j++) {
              var extraVerticalFrame = frameV0.clone()
              extraVerticalFrame.position.x = modelArray[i].margin_x + distanceBetweenExtraBeams*(j+1)
              // console.log(distanceBetweenExtraBeams*(j+1))
              // console.log(modelArray[i].margin_x + distanceBetweenExtraBeams*(j+1))

              
              //gat
              const gatGeomtery = new this.THREE.BoxGeometry(modelArray[i].width, modelArray[i].height+2*this.fx +1, 1000)
              const gat = new Brush(gatGeomtery, gatTussenMaterial)
              gat.position.x = modelArray[i].margin_x + modelArray[i].width/2
              gat.position.y = modelArray[i].margin_y + modelArray[i].height/2 -0.5
              gat.visible = true
              //this.group.children[4].children[0].add(gat)

              extraVerticalFrame.updateMatrix()
              gat.updateMatrix()
              extraVerticalFrame = this.csgSubtract(extraVerticalFrame, gat)//CSG.subtract(extraVerticalFrame, gat)

              gat.geometry.dispose()

              this.group.children[4].children[0].add(extraVerticalFrame)
            }
          }
        }

        //horizontal beams
        const geometrysh = new this.THREE.BufferGeometry()
        geometrysh.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.set.mx - 2 * this.fx, this.fx, this.fz)), 3))
        geometrysh.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        geometrysh.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        geometrysh.setIndex(boxBuffer.indexBuffer())
        geometrysh.groups = boxBuffer.groupBuffer()

        const sh = new Brush(geometrysh, [
          fhMaterialY,
          fhMaterialY,
          fhMaterialX,
          fhMaterialX,
          fhMaterialY,
          fhMaterialY
        ]);

        sh.castShadow = true; 
        sh.receiveShadow = true;

        const nsh = Math.ceil(set.my / this.fhs)-1
        const hvSpacing = (this.set.my - ((2 + nsh) * this.fx)) / (nsh + 1)

        for (let i = 0; i < nsh; i++) {

          var s = sh.clone()

          s.position.y = (this.fx + this.fx / 2) + hvSpacing * (i + 1) + this.fx * i
          s.position.x = this.set.mx/2

          for(let i=0; i<modelArray.length; i++) {
            const gatGeomtery = new this.THREE.BoxGeometry(modelArray[i].width+2*this.fx, modelArray[i].height+2*this.fx +1, 1000)
            const gat = new Brush(gatGeomtery, gatTussenMaterial)
            gat.position.x = modelArray[i].margin_x + modelArray[i].width/2
            gat.position.y = modelArray[i].margin_y + modelArray[i].height/2 -0.5
            gat.visible = true
            //this.group.children[4].children[0].add(gat)
      
            s.updateMatrix()
            gat.updateMatrix()
            s = this.csgSubtract(s, gat)//CSG.subtract(s, gat)
      
            gat.geometry.dispose()
          }

          this.group.children[4].children[1].add(s)
        }
      }

    } else {

      const allSlidingWalls = store.state.cms.schuifwandTypes
      let suitableSlidingWalls = []
      allSlidingWalls.forEach((type) => {
        if(type.minimaleBreedte <= this.set.mx && type.maximaleBreedte >= this.set.mx) {
          if(!suitableSlidingWalls.find(w => w.glasplaatAantal === type.glasplaatAantal && w.glasplaatBreedte === type.glasplaatBreedte)) {
            suitableSlidingWalls.push(type)
          }
        }
      })
      const schuifwandPreference = this.schuifwandPreference//store.state.config.schuifwandPreference
      let suitableSlidingWall
      if(suitableSlidingWalls.length === 1) {
        suitableSlidingWall = suitableSlidingWalls[0]
      } else if(suitableSlidingWalls[schuifwandPreference]) {
        suitableSlidingWall = suitableSlidingWalls[schuifwandPreference]
      } else {
        suitableSlidingWall = suitableSlidingWalls[suitableSlidingWalls.length-1]
      }
      
      // find component
      let slidingWallComponent = null
      for(let i=0; i<suitableSlidingWall.artikelnummers.length; i++) {
        const artikelNummer = suitableSlidingWall.artikelnummers[i]
        for(let j=0; j<store.state.models.slidingWallComponents.length; j++) {
          const hasMatch = store.state.models.slidingWallComponents[j].id === artikelNummer
          if(hasMatch) {
            slidingWallComponent = store.state.models.slidingWallComponents[j]
            break
          }
        }
        if(slidingWallComponent) {
          break
        }
      }

      // definitive component code with the correct color
      const slidingWallCode = slidingWallComponent ? slidingWallComponent.colors.find(c => c.name === this.schuifwandKleur?.name)?.code : null
      this.settings.modelCode = slidingWallCode
      this.modelCode = slidingWallCode

      // console.log("slidingWallCode", slidingWallComponent.size, slidingWallCode, this)

      // const schuifWand = allSlidingWalls.find(wall => 
      //   wall.glasplaatAantal === suitableSlidingWall.glasplaatAantal && 
      //   wall.glasplaatBreedte === suitableSlidingWall.glasplaatBreedte
      // ).colors

      const glasplaatDikte = suitableSlidingWall?.glasplaatDikte || 10
      const glasplaatAantal = suitableSlidingWall?.glasplaatAantal || 3
      const glasplaatBreedte = suitableSlidingWall?.glasplaatBreedte || 980
      const glasplaatRails = suitableSlidingWall?.glasplaatAantal || 3//3
      const glaspaneelHartAfstand = suitableSlidingWall?.glaspaneelHartAfstand || 22
      const zDepthStart = (glasplaatRails*-glaspaneelHartAfstand+glaspaneelHartAfstand)/2
      const staanderBreedte = suitableSlidingWall?.glasprofielStaanderBreedte || 35
      const staanderDikte = suitableSlidingWall?.glasprofielStaanderDikte || 22
      const liggerBreedte = suitableSlidingWall?.glasprofielLiggerBreedte || 47
      const liggerDikte = suitableSlidingWall?.glasprofielLiggerDikte || 13
      // console.log("schuifwandKleur", schuifwandKleur)
      const schuifwandKleur = this.schuifwandKleur?.hexCode//store.state.config.schuifwandKleur.hexCode
      const schuifwandRichting = this.schuifwandRichting//store.state.config.schuifwandRichting

      // empty glasplaat meshes
      this.modelGroup.children = this.modelGroup.children.filter(child => child.name !== "glaswandModel")
      this.mapGroup.children = this.mapGroup.children.filter(child => child.name !== "glasplaatMap")
      // let mapIndex = 0
      // while(this.mapGroup.children[mapIndex]) {
      //   if(this.mapGroup.children[mapIndex].name === "glasplaatMap") {
      //     this.mapGroup.children[mapIndex].geometry.dispose()
      //     this.mapGroup.children[mapIndex].material[0].dispose()
      //     this.mapGroup.children[mapIndex].material[1].dispose()
      //     this.mapGroup.children[mapIndex].removeFromParent()
      //   } else {
      //     mapIndex++
      //   }
      // }

      const railOnderDikte = 2

      // create glasprofiel staanders
      const glasprofielStaanderGeometry = new this.THREE.BoxGeometry(staanderBreedte, this.set.my+50-railOnderDikte, staanderDikte)
      const glasprofielMaterial = new this.THREE.MeshStandardMaterial({
        color: schuifwandKleur,
      })
      const glasprofielStaanderLinks = new this.THREE.Mesh(glasprofielStaanderGeometry, glasprofielMaterial)
      glasprofielStaanderLinks.name = "glaswandModel"
      glasprofielStaanderLinks.castShadow = true
      glasprofielStaanderLinks.receiveShadow = true
      glasprofielStaanderLinks.position.y = this.set.my / 2 - 50/2 + railOnderDikte/2
      const glasprofielStaanderRechts = glasprofielStaanderLinks.clone()
      if(schuifwandRichting === "links") {
        glasprofielStaanderLinks.position.x = staanderBreedte/2
        glasprofielStaanderRechts.position.x = this.set.mx - staanderBreedte/2
      } else {
        glasprofielStaanderLinks.position.x = this.set.mx - staanderBreedte/2
        glasprofielStaanderRechts.position.x = staanderBreedte/2
      }
      glasprofielStaanderLinks.position.z = zDepthStart + glaspaneelHartAfstand*(glasplaatRails % glasplaatAantal)
      glasprofielStaanderRechts.position.z = zDepthStart + glaspaneelHartAfstand*(glasplaatRails % glasplaatAantal) + glaspaneelHartAfstand*(glasplaatAantal-1)
      this.modelGroup.add(glasprofielStaanderLinks, glasprofielStaanderRechts)

      // create glasplaat mesh
      const glasplaatGeometry = new this.THREE.BoxGeometry(glasplaatBreedte, this.set.my+50-liggerBreedte-75-railOnderDikte, glasplaatDikte)
      const glasplaatMaterial = new this.THREE.MeshStandardMaterial({
        color: "#808080", 
        transparent: true, 
        opacity: 0.15, 
        depthTest: true, 
        depthWrite: false, 
        // side: this.THREE.DoubleSide
      })
      const glasplaat = new this.THREE.Mesh(glasplaatGeometry, glasplaatMaterial)
      glasplaat.name = "glaswandModel"
      glasplaat.castShadow = true
      glasplaat.receiveShadow = true
      glasplaat.position.y = (this.set.my+50-liggerBreedte-75-railOnderDikte)/2 + railOnderDikte + liggerBreedte - 50

      // create glasprofiel ligger mesh
      const glasprofielLiggerGeometry = new this.THREE.BoxGeometry(glasplaatBreedte, liggerBreedte, liggerDikte)
      const glasprofielLigger = new this.THREE.Mesh(glasprofielLiggerGeometry, glasprofielMaterial)
      glasprofielLigger.name = "glaswandModel"
      glasprofielLigger.castShadow = true
      glasprofielLigger.receiveShadow = true
      glasprofielLigger.position.y = liggerBreedte/2 - 50 + railOnderDikte

      //create glasplaat map mesh
      const glasplaatMapGeometry = new this.THREE.BufferGeometry()
      glasplaatMapGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(planeBorderBuffer.positionBuffer(glaspaneelHartAfstand, glasplaatBreedte, glaspaneelHartAfstand/4)), 3))
      glasplaatMapGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer()), 2))
      glasplaatMapGeometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.normalBuffer()), 3))
      glasplaatMapGeometry.groups = planeBorderBuffer.groupBuffer()
      const glasplaatMapInnerMaterial = new this.THREE.MeshBasicMaterial({
        color: "#000000",
      })
      const glasplaatMapOuterMaterial = new this.THREE.MeshBasicMaterial({
        color: "#ffffff",
      })
      const glasplaatMap = new this.THREE.Mesh(glasplaatMapGeometry, [
        glasplaatMapInnerMaterial,
        glasplaatMapOuterMaterial
      ])
      glasplaatMap.name = "glasplaatMap"

      // create glasplaat instances
      for(let i=0; i<glasplaatAantal; i++) {
        const glasplaatInstance = glasplaat.clone()
        if(schuifwandRichting === "links") {
          glasplaatInstance.position.x = glasplaatBreedte/2 + i*((this.set.mx-glasplaatBreedte)/(glasplaatAantal-1))
        } else {
          glasplaatInstance.position.x = this.set.mx - glasplaatBreedte/2 - i*((this.set.mx-glasplaatBreedte)/(glasplaatAantal-1))
        }
        glasplaatInstance.position.z = zDepthStart + glaspaneelHartAfstand*((glasplaatRails % glasplaatAantal) + i)
        // console.log("glasplaatInstance.position.z", glasplaatInstance.position.z, zDepthStart, (glasplaatRails % glasplaatAantal))
        const glasprofielLiggerInstance = glasprofielLigger.clone()
        glasprofielLiggerInstance.position.x = glasplaatInstance.position.x
        glasprofielLiggerInstance.position.z = glasplaatInstance.position.z
        this.modelGroup.add(glasplaatInstance, glasprofielLiggerInstance)

        // create glasplaat map instances
        const glasplaatMapInstance = glasplaatMap.clone()
        glasplaatMapInstance.position.x = glasplaatInstance.position.x - glasplaatBreedte/2
        glasplaatMapInstance.position.z = glasplaatInstance.position.z - glaspaneelHartAfstand/2
        this.mapGroup.add(glasplaatMapInstance)
      }

      // handvat
      const handvatGeometry = new this.THREE.CylinderGeometry(30, 30, 5, 32)
      const handvatMaterial = new this.THREE.MeshStandardMaterial({
        metalness: 0.6,
        roughness: 0.1,
        envMapIntensity: 1
      })
      handvatMaterial.envMap = this.textureCube
      // handvatMaterial.color = new this.THREE.Color("#BEBEBE")
      handvatMaterial.color = new this.THREE.Color("#121212")
      const handvat = new this.THREE.Mesh(handvatGeometry, handvatMaterial)
      handvat.name = "glaswandModel"
      handvat.rotation.x = Math.PI/2
      if(schuifwandRichting === "links") {
        handvat.position.x = this.set.mx - (suitableSlidingWall ? suitableSlidingWall.handvatHorizontalePositionering : 200)
      } else {
        handvat.position.x = suitableSlidingWall ? suitableSlidingWall.handvatHorizontalePositionering : 200
      }
      handvat.position.y = suitableSlidingWall ? suitableSlidingWall.handvatVerticalePositionering : 1100
      handvat.position.z = zDepthStart + glaspaneelHartAfstand*((glasplaatRails % glasplaatAantal) + glasplaatAantal-1) + glasplaatDikte/2 + 5/2
      this.modelGroup.add(handvat)

      // rails onder
      const railsOnderGeometry = new this.THREE.BoxGeometry(this.set.mx, railOnderDikte, glasplaatAantal*glaspaneelHartAfstand+4)
      const railsOnder = new this.THREE.Mesh(railsOnderGeometry, glasprofielMaterial)
      railsOnder.name = "glaswandModel"
      railsOnder.castShadow = true
      railsOnder.receiveShadow = true
      railsOnder.position.y = railOnderDikte/2 - 50
      railsOnder.position.x = this.set.mx/2
      this.modelGroup.add(railsOnder)

      // rails boven
      const railsBovenGeometry = new this.THREE.BoxGeometry(this.set.mx, 75, glasplaatAantal*glaspaneelHartAfstand+4)
      const railsBoven = new this.THREE.Mesh(railsBovenGeometry, glasprofielMaterial)
      railsBoven.name = "glaswandModel"
      railsBoven.castShadow = true
      railsBoven.receiveShadow = true
      railsBoven.position.y = this.set.my - 75/2
      railsBoven.position.x = this.set.mx/2
      this.modelGroup.add(railsBoven)

        

      // const gltfLoader = new GLTFLoader()
      // // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}/S5100396.glb`, (glb) => {
      // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}/S5101403.glb`, (glb) => {  
      //   // const model = glb.scene.children[0].children[1].children[1].children[0].children[0]
      //   // const modelMaterial = model.material
      //   glb.scene.scale.multiplyScalar(1000)
      //   glb.scene.rotation.y = Math.PI/2

      //   glb.scene.traverse(n => {
      //     if(n.isMesh) {
      //       n.castShadow = true
      //       n.receiveShadow = true
      //       if(n.material) {
      //         if(Array.isArray(n.material)) {
      //           n.material.forEach((a) => {
      //             a.metalness = 0;
      //             console.log(a)
      //             if(a.material.map) {
      //               a.material.map.anisotropy = 16
      //               a.material.map.encoding = this.THREE.sRGBEncoding
      //               a.material.map.flipY = false;
      //             }
      //           })
      //         } else {
      //           n.material.metalness = 0;
      //           console.log(n)
      //           if(n.material.map){
      //             n.material.map.anisotropy = 16
      //             n.material.map.encoding = this.THREE.sRGBEncoding
      //             n.material.map.flipY = false;
      //           }
      //         }
      //       }
      //     }
      //   })

      //   // glasplaat.material = modelMaterial
      //   // glasplaatMaterial.color = modelMaterial.color
      //   // console.log("glb", modelMaterial, glasplaatMaterial)
      //   this.modelGroup.add(glb.scene)
      //   console.log("glb", glb.scene, /*model,*/ glasplaat)
      //   console.log("group", this.group, this.modelGroup)
      // })
    }

    //plusicons:
    this.plusIconGroupDisabled[0].position.set((this.set.mx-1200)/4,1250,this.set.mz/2)
    this.plusIconGroupDisabled[1].position.set((this.set.mx-1200)/4,1250,-this.set.mz/2)
    this.plusIconGroupDisabled[2].position.set(this.set.mx/2-200,this.set.my/2+200,this.set.mz/2)
    this.plusIconGroupDisabled[3].position.set(this.set.mx/2-200,this.set.my/2+200,-this.set.mz/2)
    this.plusIconGroupDisabled[4].position.set(this.set.mx-(this.set.mx-1200)/4-400,1250,this.set.mz/2)
    this.plusIconGroupDisabled[5].position.set(this.set.mx-(this.set.mx-1200)/4-400,1250,-this.set.mz/2)
    this.plusIconGroupDisabled[6].position.set(0,1250,0)
    this.plusIconGroupDisabled[7].position.set(this.set.mx/2-200,this.set.my/2+200,0)
    this.plusIconGroupDisabled[8].position.set(this.set.mx-400,1250,0)

    for(const plusIcon of this.plusIconGroupDisabled) {
      plusIcon.visible = (this.settings.buitenMuur ? !this.settings.buitenMuurTexture.open : true) && (this.settings.binnenMuur ? !this.settings.binnenMuurTexture.open : true)
    }

    // schoren
    const s0 = this.group.children[0].children[0]
    const s1 = this.group.children[0].children[1]

    s0.position.y = this.set.my - schoor.sy
    s0.position.z = this.staanderGrootte.dz / 2 - schoor.sx
    s1.position.y = this.set.my - schoor.sy
    s1.position.x = this.set.mx
    s1.rotation.y = Math.PI
    s1.position.z = this.staanderGrootte.dz / 2

    s0.visible = !this.set.tussenMuur && (this.settings.buitenMuur ? (!!this.settings.buitenMuurTexture.open) : true) && (this.settings.binnenMuur ? (!!this.settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
    s1.visible = !this.set.tussenMuur && (this.settings.buitenMuur ? (!!this.settings.buitenMuurTexture.open) : true) && (this.settings.binnenMuur ? (!!this.settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)
    // console.log("this.schoor.wallMinWidth", s0.visible, s1.visible, this.schoor.wallMinWidth)
    this.schoorGroup.visible = this.set.mx >= this.schoor.wallMinWidth && !this.isSlidingWall && !this.isBorstwering
    // this.schoorGroup.position.z = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) ? this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2  : 0
    if (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) {
      if(!this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
        this.schoorGroup.position.z = this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2
      } else {
        this.schoorGroup.position.z = - (this.staanderGrootte.dx - this.schoor.sx)/2
      }
    } else {
      this.schoorGroup.position.z = 0
    }

    this.group.children[0].position.z = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - schoor.sx)/2  : 0

    this.hoverLine0.updateSize(this.set.mx, this.staanderGrootte.dx)
    this.selectFrame.update(this.set)

    this.hoverLine0.group.visible = true

    this.selectFrame.group.position.x = this.set.mx / 2
    this.selectFrame.group.position.y = 100

    //plattegrond
    if(immediatelyUpdateMap) {
      this.updatePlattegrond()
    } else {
      this.updatePlattegrondWithDelay(immediatelyUpdateMap)
    }
    // const betweenLines = 200
    // // const betweenTextAndLine = 20
    // const lineWidth = 20
    // const alignLineWidth = 10
    // const arrowLength = 200
    // const arrowWidth = 120
    // const overhang = this.settings.tussenMuur ? 0 : (this.alignsXAxis ? (this.alignedRotation ? this.totalOverhang.l : this.totalOverhang.r) : (this.alignedRotation ? this.totalOverhang.v : this.totalOverhang.a))
    
    // const line0 = this.mapGroup.children[0]
    // const arrow0 = this.mapGroup.children[1]
    // const arrow1 = this.mapGroup.children[2]
    // const alignLine0 = this.mapGroup.children[3]
    // const alignLine1 = this.mapGroup.children[4]
    // const text0 = this.mapGroup.children[5]

    // // const box0 = this.box
    // // const textSizes = this.vector
    
    // this.mapHoverElements.buitenmuurMap.geometry.attributes.position.array = new Float32Array(planeBorderBuffer.positionBuffer(53.5, this.set.mx, 7))
    // this.mapHoverElements.buitenmuurMap.geometry.attributes.position.needsUpdate = true
    // this.mapHoverElements.binnenmuurMap.geometry.attributes.position.array = new Float32Array(planeBorderBuffer.positionBuffer(53.5, this.set.mx, 7))
    // this.mapHoverElements.binnenmuurMap.geometry.attributes.position.needsUpdate = true
    // // console.log(20, set.mx - (2 * 150))
    // // console.log(line0)
    // line0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(lineWidth, this.set.mx-2*arrowLength))
    // line0.geometry.attributes.position.needsUpdate = true
    // alignLine0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace, alignLineWidth))
    // alignLine0.geometry.attributes.position.needsUpdate = true
    // arrow0.rotation.y = 270 * Math.PI / 180
    // arrow0.position.set(arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines-arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    // arrow1.rotation.y = 90 * Math.PI / 180
    // arrow1.position.set(this.set.mx - arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    // line0.position.set(arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines +this.topgevelSpace)
    // alignLine0.position.set(-alignLineWidth, 0, this.set.mz / 2)
    // alignLine0.rotation.y = 0 * Math.PI / 180
    // alignLine1.position.set(this.set.mx, 0, this.set.mz / 2)
    // alignLine1.rotation.y = 0 * Math.PI / 180
    // const totalWidth = (Math.round(this.set.mx*2)/2).toString().replaceAll('.', ',')
    // text0.geometry = new TextGeometry(totalWidth, {
    //   font: this.font,
    //   size: 125,
    //   height: 1,
    // })
    // box0.setFromObject(text0)
    // box0.getSize(textSizes)
    // // console.log(textSizes)
    // if (this.set.rotation * 180 / Math.PI === 270) {
    //   text0.position.set((this.set.mx - this.vectorMHighest(textSizes, 1)) / 2, 0, this.staanderGrootte.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine+this.vectorMHighest(textSizes, 2))
    //   text0.rotation.x = 270 * Math.PI / 180
    //   text0.rotation.z = 0 * Math.PI / 180
    //   //alert('gedraaid')
    // } else {
    //   text0.rotation.x = 270 * Math.PI / 180
    //   text0.rotation.z = 180 * Math.PI / 180
    //   text0.position.set((this.set.mx + this.vectorMHighest(textSizes, 1)) / 2, 0, this.staanderGrootte.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine +this.topgevelSpace)
    // }
    // const visible = this.settings.tussenMuur?this.wallOn:true
    // line0.visible=visible
    // arrow0.visible=visible
    // arrow1.visible=visible
    // alignLine0.visible=visible
    // alignLine1.visible=visible
    // text0.visible=visible

    this.arrowGroup.children[0].position.set(0,this.set.mx<500?this.set.my/2+200+250:this.set.my/2+200,this.set.mz/2+11)
    this.arrowGroup.children[1].position.set(this.set.mx-250,this.set.mx<500?this.set.my/2+200-250:this.set.my/2+200,this.set.mz/2+11)
    this.arrowGroup.children[2].position.set(this.set.mx-250,this.set.mx<500?this.set.my/2-200+250:this.set.my/2-200,-this.set.mz/2-11)
    this.arrowGroup.children[3].position.set(0,this.set.mx<500?this.set.my/2-200+250:this.set.my/2-200,-this.set.mz/2-11)

    if(this.lockIcon) {
      var box2 = new this.THREE.Box3().setFromObject(this.lockIcon);
      var height = box2.max.y - box2.min.y
      var width = Math.max(box2.max.z - box2.min.z, box2.max.x - box2.min.x)
      this.lockIcon.position.set((this.set.mx-width-200)/2,height+300,this.set.mz/2)
    }
    
    //this.isUpdating = false
    this.betonBand.update(this.set.mx);

    if(this.childWall && (!this.set.tussenMuur && (this.wallOn || this.childWall.wallOn))) {
      this.showWallStaander(this.staanderObject.frameTexture)
    } else if(this.wallStaander && ((!this.childWall) || (this.set.tussenMuur && (!this.wallOn && !this.childWall.wallOn)))) {
      this.wallStaander.removeFromParent()
      this.mapWallStaander.removeFromParent()
      this.wallStaander = null
      this.wallStaanderInfo = null
      this.mapWallStaander = null
    }

    if(this.wallStaander) {
      this.wallStaander.position.x = this.set.mx + this.staanderObject.dx/2
      this.mapWallStaander.position.x = this.set.mx + this.staanderObject.dx/2
      if(poer) {
        this.betonPoer.updateBetonPoer(poer)
        this.betonPoer.betonPoer.position.y = -poer.y/2;
      }
      this.wallStaander.position.z = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.fz/2 - this.staanderGrootte.dx/2 : 0
      //alert(this.wallStaander.position.z)
    }

    if(this.hoekProfiel.right) {
      this.hoekProfiel.right.position.x = this.set.mx + this.staanderObject.dx
    }

    if(this.hoekProfiel.left) {
      this.hoekProfiel.left.position.x = -this.staanderObject.dx
    }

    this.settings.width = this.set.mx

    if(store.state.models.selectedWallComponent.category === "Schuifwand") {
      const min = store.state.cms.schuifwandWidthRanges[0].min
      const max = store.state.cms.schuifwandWidthRanges[store.state.cms.schuifwandWidthRanges.length-1].max
      this.wallButtonIndex[4].canFitAtLocation = this.set.mx >= min && this.set.mx <= max
      this.setElementPlacing(this.shouldShowPlusIcons)
    }

    if (this.isBorstwering && this.borstweringComponent) {
      this.modelGroup.add(this.borstweringComponent.model)
      this.borstweringComponent.model.rotation.y = 0.5 * Math.PI
      this.borstweringComponent.model.position.x = this.borstweringComponent.dimensions.xo
      this.borstweringComponent.model.position.y = this.borstweringComponent.dimensions.yo
      this.borstweringComponent.model.position.z = this.borstweringComponent.dimensions.zo
      this.borstweringComponent.map.rotation.y = 0.5 * Math.PI
      this.borstweringComponent.map.position.y = 1000
      this.borstweringComponent.map.renderOrder = 100
      this.borstweringAssetHitbox.position.set(
        this.borstweringComponent.dimensions.x/2,
        this.borstweringComponent.dimensions.yo + (this.borstweringComponent.dimensions.y - this.borstweringComponent.dimensions.yo) / 2,
        0
      )
      this.selectFrameBorstweringAsset.group.position.x = this.set.mx / 2
      this.selectFrameBorstweringAsset.group.position.y = 100 + this.set.y
      this.selectFrameBorstweringAsset.group.visible = this.selected && this.selectedBorstweringAsset
    }

    if(this.isSlidingWall || this.isBorstwering) {
      this.setElementPlacing(false)
    }

    this.setHover(this.hover)

    // isolatie knoppen
    // console.log('this.hover',this.hover,'this.settings',this.settings);
    if(this.settings.binnenMuur || this.settings.buitenMuur) {
      if (this.group.children[1] !== null && this.group.children[1].children.indexOf(c => c.name === "isolatieHoverButtonMainGroup") > -1) {
        this.group.children[1].add(this.hoverIsoMainGroup);
      }
    }
    if(!this.settings.binnenMuur && !this.settings.buitenMuur) {
      this.hoverIsoMainGroup?.removeFromParent()
    }
    // graphic size = 350, therefore shift x by 175 to get it centered;
    // set y to -45 to prevent graphic from looking as though it's floating;
    // z + 500 is an arbitrary choice to push the graphic away from other buttons;
    this.hoverIsoMainGroup.position.set((this.set.mx*0.5)+175, /*0*/-45, this.set.mz+500)

    this.updateMuurAfkapping()
    this.updateMuurScalingForHitbox()
    this.updateNiceOffset()
    setTimeout(() => {
      this.updateMuurAfkapping(() => {
        if(_callback) {
          _callback()
        }
      })
    }, 1);
  }

  updatePlattegrondWithDelay() {

    // Set the last execution time to the current time
    this.lastExecutionTime = Date.now();

    // Set a timer for 1 second
    setTimeout(() => {
      if (Date.now() - this.lastExecutionTime < 1000) return
      this.lastExecutionTime = Date.now();
      this.updatePlattegrond()
    }, 1000);
  }

  updatePlattegrond() {
    const betweenLines = 200
    const betweenTextAndLine = 20
    const lineWidth = 20
    const alignLineWidth = 10
    const arrowLength = 200
    const arrowWidth = 120
    const overhang = this.settings.tussenMuur ? 0 : (this.alignsXAxis ? (this.alignedRotation ? this.totalOverhang.l : this.totalOverhang.r) : (this.alignedRotation ? this.totalOverhang.v : this.totalOverhang.a))
    
    const line0 = this.mapGroup.children[0]
    const arrow0 = this.mapGroup.children[1]
    const arrow1 = this.mapGroup.children[2]
    const alignLine0 = this.mapGroup.children[3]
    const alignLine1 = this.mapGroup.children[4]
    const text0 = this.mapGroup.children[5]
    // console.log('this.mapGroup',this.mapGroup);

    const box0 = this.box
    const textSizes = this.vector
    
    this.mapHoverElements.buitenmuurMap.visible = !this.isSlidingWall
    this.mapHoverElements.binnenmuurMap.visible = !this.isSlidingWall
    this.mapHoverElements.buitenmuurMap.geometry.attributes.position.array = new Float32Array(planeBorderBuffer.positionBuffer(53.5, this.set.mx, 7))
    this.mapHoverElements.buitenmuurMap.geometry.attributes.position.needsUpdate = true
    this.mapHoverElements.binnenmuurMap.geometry.attributes.position.array = new Float32Array(planeBorderBuffer.positionBuffer(53.5, this.set.mx, 7))
    this.mapHoverElements.binnenmuurMap.geometry.attributes.position.needsUpdate = true
    // console.log(20, set.mx - (2 * 150))
    // console.log(line0)
    line0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(lineWidth, this.set.mx-2*arrowLength))
    line0.geometry.attributes.position.needsUpdate = true
    alignLine0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace, alignLineWidth))
    alignLine0.geometry.attributes.position.needsUpdate = true
    arrow0.rotation.y = 270 * Math.PI / 180
    arrow0.position.set(arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines-arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    arrow1.rotation.y = 90 * Math.PI / 180
    arrow1.position.set(this.set.mx - arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    line0.position.set(arrowLength, 0, this.staanderGrootte.dx/2+overhang+betweenLines +this.topgevelSpace)
    alignLine0.position.set(-alignLineWidth, 0, this.set.mz / 2)
    alignLine0.rotation.y = 0 * Math.PI / 180
    alignLine1.position.set(this.set.mx, 0, this.set.mz / 2)
    alignLine1.rotation.y = 0 * Math.PI / 180
    const totalWidth = (Math.round(this.set.mx*2)/2).toString().replaceAll('.', ',')
    text0.geometry = new TextGeometry(totalWidth, {
      font: this.font,
      size: 125,
      height: 1,
    })
    box0.setFromObject(text0)
    box0.getSize(textSizes)
    // console.log(textSizes)
    if (this.set.rotation * 180 / Math.PI === 270) {
      text0.position.set((this.set.mx - this.vectorMHighest(textSizes, 1)) / 2, 0, this.staanderGrootte.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine+this.vectorMHighest(textSizes, 2))
      text0.rotation.x = 270 * Math.PI / 180
      text0.rotation.z = 0 * Math.PI / 180
      //alert('gedraaid')
    } else {
      text0.rotation.x = 270 * Math.PI / 180
      text0.rotation.z = 180 * Math.PI / 180
      text0.position.set((this.set.mx + this.vectorMHighest(textSizes, 1)) / 2, 0, this.staanderGrootte.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine +this.topgevelSpace)
    }
    const visible = this.settings.tussenMuur?this.wallOn:true
    line0.visible=visible
    arrow0.visible=visible
    arrow1.visible=visible
    alignLine0.visible=visible
    alignLine1.visible=visible
    text0.visible=visible
    // console.log('isoButtonGroup', isoButtonGroup)
    // isoButtonGroup.visible = visible;
    if(this.settings.binnenMuur || this.settings.buitenMuur) {
      this.mapGroup.add(this.mapIsoMainGroup);
    }
    if(!this.settings.binnenMuur && !this.settings.buitenMuur) {
      this.mapIsoMainGroup?.removeFromParent()
    }
    this.mapIsoMainGroup.position.set((this.set.mx*0.5)+150, 200, (this.set.mz))
  }

  selfUpdate() {
    this.update(this.y, this.set, this.schoor, this.staanderGrootte, this.showFundering, this.topgevelSpace, this.poer, this.wallConfig, this.dy, this.config, this.x, this.z, this.modelType, this.kapschuurConfig, this.ox, this.oy, this.graden, this.rbtpy, this.gy, this.veranda)
  }

  updatePosition(pos) {
    // console.log("pos", pos)
    this.group.position = pos
    this.mapGroup.position = pos
    this.modelGroup.position = pos
  }

  updateOutsideWallTextureAlignment() {
    if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
      let distanceFromWallsOrigin
      if(this.alignsXAxis) {
        if(this.alignedRotation) {
          distanceFromWallsOrigin = this.z - this.group.position.x
        } else {
          distanceFromWallsOrigin = this.group.position.x
        }
      } else {
        if(this.alignedRotation) {
          distanceFromWallsOrigin = this.group.position.z
        } else {
          distanceFromWallsOrigin = this.x - this.group.position.z
        }
      }
      this.group.children[2].material.map.offset.x = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
      this.group.children[2].material.map.needsUpdate = true
      this.group.children[2].material.needsUpdate = true
    }
  }

  async updateHoekProfiel({shouldRight, shouldLeft, shouldRightBoeideel, shouldLeftBoeideel}) {

    this.hoekProfielConfig = {shouldRight, shouldLeft, shouldRightBoeideel, shouldLeftBoeideel}

    const generateHoekProfiel = (height, posY) => {
      const hoekProfielWidth = 63
      const hokeProfielThickness = this.wallConfig.wz+1 //23

      const shape = new this.THREE.Shape();
      shape.moveTo(0, 0);
      shape.lineTo(0, -(hoekProfielWidth - hokeProfielThickness));
      shape.lineTo(hokeProfielThickness, -(hoekProfielWidth - hokeProfielThickness));
      shape.lineTo(hokeProfielThickness, hokeProfielThickness);
      shape.lineTo(-(hoekProfielWidth - hokeProfielThickness), hokeProfielThickness);
      shape.lineTo(-(hoekProfielWidth - hokeProfielThickness), 0);
      shape.lineTo(0, 0);

      const extrudeSettings = {
        steps: 1,
        depth: height,
        bevelEnabled: false,
      };
      const geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
      geometry.groups = [
        {start: 0, count: 30, materialIndex: 0},
        {start: 30, count: 6, materialIndex: 1},
        {start: 36, count: 6, materialIndex: 2},
        {start: 42, count: 138, materialIndex: 0},
      ]
      // console.log(geometry)

      return new Promise((resolve) => {

        const tex = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.textureHoekprofielen}`, () => {
          this.textureSettings(tex,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
          const texFrontLeft = tex.clone()
          this.textureSettings(texFrontLeft,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI*1.5)
          texFrontLeft.needsUpdate = true
          texFrontLeft.offset.set(posY/2330, 0)
          const texFrontRight = tex.clone()
          this.textureSettings(texFrontRight,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
          texFrontRight.needsUpdate = true
          texFrontRight.offset.set(posY/2330, 0)
          // console.log("offset", posY/2330)
          const material = new this.THREE.MeshStandardMaterial({map: tex});
          const front_left = new this.THREE.MeshStandardMaterial({map: texFrontLeft});
          const front_right = new this.THREE.MeshStandardMaterial({map: texFrontRight});
          const mesh = new this.THREE.Mesh(geometry, [material, front_left, front_right]);
        
          mesh.castShadow = true;
          mesh.receiveShadow = true;

          resolve(mesh)
        })

      })
    }
    

    const removeRight = () => {
      if(this.hoekProfiel.right) {
        // console.log("remove right")
        this.hoekProfiel.right.removeFromParent()
        this.hoekProfiel.right.geometry.dispose()
        this.hoekProfiel.right.material.forEach((material) => {
          // material.map.dispose()
          material.dispose()
        })
        this.hoekProfiel.right = null
      }
    }
    removeRight()

    if(shouldRight && !this.hoekProfiel.right) {
      const hoekProfielRechts = await generateHoekProfiel(shouldRightBoeideel ? this.y + this.dy + 16 : this.y + 16, 0)
      hoekProfielRechts.position.x = this.set.mx + this.staanderObject.dx
      hoekProfielRechts.position.z = this.fz/2
      hoekProfielRechts.rotation.x = -Math.PI/2
      hoekProfielRechts.rotation.z = Math.PI*1.5
      removeRight()
      if(this.hoekProfielConfig.shouldRight && !this.hoekProfiel.right) {
        this.hoekProfiel.right = hoekProfielRechts
        this.group.add(hoekProfielRechts);
      }
    } else if(shouldRightBoeideel && !shouldRight && !this.hoekProfiel.right) {
      const hoekProfielRechts = await generateHoekProfiel(this.dy + 16 +1, this.y -1)
      hoekProfielRechts.position.x = this.set.mx + this.staanderObject.dx
      hoekProfielRechts.position.z = this.fz/2
      hoekProfielRechts.position.y = this.y -1
      hoekProfielRechts.rotation.x = -Math.PI/2
      hoekProfielRechts.rotation.z = Math.PI*1.5
      removeRight()
      if(this.hoekProfielConfig.shouldRightBoeideel && !this.hoekProfielConfig.shouldRight && !this.hoekProfiel.right) {
        this.hoekProfiel.right = hoekProfielRechts
        this.group.add(hoekProfielRechts);
      }
    } 

    const removeLeft = () => {
      if(this.hoekProfiel.left) {
        this.hoekProfiel.left.removeFromParent()
        this.hoekProfiel.left.geometry.dispose()
        this.hoekProfiel.left.material.forEach((material) => {
          // material.map.dispose()
          material.dispose()
        })
        this.hoekProfiel.left = null
      }
    }
    removeLeft()

    if(shouldLeft && !this.hoekProfiel.left) {
      const hoekProfielLinks = await generateHoekProfiel(shouldLeftBoeideel ? this.y + this.dy + 16 : this.y + 16, 0)
      hoekProfielLinks.position.x = -this.staanderObject.dx
      hoekProfielLinks.position.z = this.fz/2
      hoekProfielLinks.rotation.x = -Math.PI/2
      hoekProfielLinks.rotation.z = Math.PI*1
      removeLeft()
      if(this.hoekProfielConfig.shouldLeft && !this.hoekProfiel.left) {
        this.hoekProfiel.left = hoekProfielLinks
        this.group.add(hoekProfielLinks);
      }
    } else if(shouldLeftBoeideel && !shouldLeft && !this.hoekProfiel.left) {
      const hoekProfielLinks = await generateHoekProfiel(this.dy+16 +1, this.y -1)
      hoekProfielLinks.position.x = -this.staanderObject.dx
      hoekProfielLinks.position.z = this.fz/2
      hoekProfielLinks.position.y = this.y -1
      hoekProfielLinks.rotation.x = -Math.PI/2
      hoekProfielLinks.rotation.z = Math.PI*1
      removeLeft()
      if(this.hoekProfielConfig.shouldLeftBoeideel && !this.hoekProfielConfig.shouldLeft && !this.hoekProfiel.left) {
        this.hoekProfiel.left = hoekProfielLinks
        this.group.add(hoekProfielLinks);
      }
    }
    
  }

  updateHoekprofielTexture(texture) {
    this.textureHoekprofielen = texture

    const shouldLeft = this.hoekProfielConfig.shouldLeft
    const shouldLeftBoeideel = this.hoekProfielConfig.shouldLeftBoeideel
    const shouldRight = this.hoekProfielConfig.shouldRight
    const shouldRightBoeideel = this.hoekProfielConfig.shouldRightBoeideel

    const hoekProfielWidth = 63

    const tex = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.textureHoekprofielen}`, () => {
      if(this.hoekProfiel.left) {

        const posY = shouldLeft ? 0 : (shouldLeftBoeideel ? (this.y -1) : 0)

        const tex0 = tex.clone()
        this.textureSettings(tex0,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
        tex0.needsUpdate = true
        
        const texFrontLeft = tex.clone()
        this.textureSettings(texFrontLeft,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI*1.5)
        texFrontLeft.needsUpdate = true
        texFrontLeft.offset.set(posY/2330, 0)

        const texFrontRight = tex.clone()
        this.textureSettings(texFrontRight,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
        texFrontRight.needsUpdate = true
        texFrontRight.offset.set(posY/2330, 0)

        this.hoekProfiel.left.material[0].map = tex
        this.hoekProfiel.left.material[1].map = texFrontLeft
        this.hoekProfiel.left.material[2].map = texFrontRight

        this.hoekProfiel.left.material[0].map.needsUpdate = true
        this.hoekProfiel.left.material[1].map.needsUpdate = true
        this.hoekProfiel.left.material[2].map.needsUpdate = true

        this.hoekProfiel.left.material[0].needsUpdate = true
        this.hoekProfiel.left.material[1].needsUpdate = true
        this.hoekProfiel.left.material[2].needsUpdate = true
      }

      if(this.hoekProfiel.right) {

        const posY = shouldRight ? 0 : (shouldRightBoeideel ? (this.y -1) : 0)

        const tex1 = tex.clone()
        this.textureSettings(tex1,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
        tex1.needsUpdate = true
        
        const texFrontLeft1 = tex.clone()
        this.textureSettings(texFrontLeft1,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI*1.5)
        texFrontLeft1.needsUpdate = true
        texFrontLeft1.offset.set(posY/2330, 0)

        const texFrontRight1 = tex.clone()
        this.textureSettings(texFrontRight1,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
        texFrontRight1.needsUpdate = true
        texFrontRight1.offset.set(posY/2330, 0)

        this.hoekProfiel.right.material[0].map = tex1
        this.hoekProfiel.right.material[1].map = texFrontLeft1
        this.hoekProfiel.right.material[2].map = texFrontRight1

        this.hoekProfiel.right.material[0].map.needsUpdate = true
        this.hoekProfiel.right.material[1].map.needsUpdate = true
        this.hoekProfiel.right.material[2].map.needsUpdate = true

        this.hoekProfiel.right.material[0].needsUpdate = true
        this.hoekProfiel.right.material[1].needsUpdate = true
        this.hoekProfiel.right.material[2].needsUpdate = true
      }


    })
  }

  csgSubtract(a, b, copyMaterial) {

    const previousAScale = a.scale.clone()
    a.scale.set(1,1,1)

    // prepare a
    const aClone = a.clone()
    // const aPosition = a.position.clone()
    // aClone.position.set(0,0,0)
    aClone.updateMatrixWorld()

    // prepare b
    b.updateMatrixWorld()

    // aClone.name = "ToRemove"
    // this.group.add(aClone)
    

    const evaluator = new Evaluator();
    let result
    try {
      result = evaluator.evaluate( aClone, b, SUBTRACTION );
    } catch(e) {
      console.log(e)
      return a
    }

    // result.position.copy(aPosition)
    result.name = a.name
    result.visible = a.visible
    result.parent = a.parent
    result.geometry.computeBoundingBox()
    result.geometry.computeBoundingSphere()
    result.scale.set(previousAScale.x, previousAScale.y, previousAScale.z)
    // result.material = a.material
    if(!Array.isArray(a.material)) {
      // result.geometry.groups = a.geometry.groups
    }
    if(copyMaterial) {
      result.material = a.material
    }
    return result
  }

  updateMuurAfkapping(_callback) {
    if(this.alignsXAxis && this.modelType.isKapschuur) {
      const buitenMuur = this.group.children[2]
      const binnenMuur = this.group.children[3]

      const g_cutCube = new this.THREE.BoxGeometry(4000, 2000, 1000);
      g_cutCube.translate(0, 1000, 0);
      const m_cutCube = new this.THREE.MeshBasicMaterial({color: "#FF00DC", /*opacity: 0.0, transparent: true*/});
      const cutCube = new Brush(g_cutCube, m_cutCube) //new Brush(g_cutCube, m_cutCube);
      cutCube.name = "cutCube"

      const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : this.z
      const ringBalkBreedteKapschuur = breedte+this.ox+((this.rbtpy+this.oy)/Math.tan(this.graden*(Math.PI/180)))
      const posX = ringBalkBreedteKapschuur-(this.gy/Math.sin(this.graden * (Math.PI/180))) - (this.modelType.isStockholm ? (this.kapschuurConfig.extraBalkjeDikte/Math.sin(this.graden * (Math.PI/180))) : 0)
      // console.log("iets", (this.modelType.isStockholm ? (this.kapschuurConfig.extraBalkjeDikte/Math.sin(this.graden * (Math.PI/180))) : 0))
      cutCube.position.y = this.y
      if(this.alignedRotation) {
        cutCube.position.x = this.group.position.x-posX//this.group.position.x
        cutCube.rotation.z = this.graden * (Math.PI/180)
      } else {
        cutCube.position.x = posX-this.group.position.x
        cutCube.rotation.z = -this.graden * (Math.PI/180)
      }
      cutCube.position.y = this.y

      // if(this.group.getObjectByName("cutCube"))this.group.getObjectByName("cutCube").removeFromParent()
      // this.group.add(cutCube)

      // const buitenMuurClone = buitenMuur.clone()
      // const buitenMuurPosition = buitenMuur.position.clone()
      // buitenMuurClone.position.set(0,0,0)
      // buitenMuurClone.updateMatrixWorld()
      // // const gat = new Brush(new this.THREE.BoxGeometry(500, 500, 500), new this.THREE.MeshBasicMaterial({color: "#FF00DC"}))
      // // gat.position.set(0,0,0)
      // // gat.updateMatrixWorld()

      // cutCube.position.y = cutCube.position.y/2
      // cutCube.position.x = -this.set.mx/2+cutCube.position.x
      // cutCube.updateMatrixWorld()

      // const evaluator = new Evaluator();

      // const result = evaluator.evaluate( buitenMuurClone, cutCube, SUBTRACTION );
      // result.position.copy(buitenMuurPosition)
      // result.name = buitenMuur.name
      // result.material = buitenMuur.material
      // result.visible = buitenMuur.visible
      // this.group.children[2] = result
      // result.parent = this.group
      // buitenMuur.removeFromParent()

      // console.log("csg", buitenMuur, binnenMuur)

      // cutCube.position.y = cutCube.position.y/2
      // cutCube.position.x = -this.set.mx/2+cutCube.position.x

      this.group.children[2] = this.csgSubtract(buitenMuur, cutCube)
      buitenMuur.removeFromParent()

      this.group.children[3] = this.csgSubtract(binnenMuur, cutCube)
      binnenMuur.removeFromParent()

      // buitenMuur.removeFromParent()
      // result.name = buitenMuur.name


      // console.log(Brush, Evaluator, SUBTRACTION, buitenMuur, binnenMuur)

      // if(this.group.getObjectByName("cutCube"))this.group.getObjectByName("cutCube").removeFromParent()
      // this.group.add(cutCube)
      // console.log(cutCube, this.group.position.x, this.settings.uuid)

      // buitenMuur.geometry = CSG.subtract(buitenMuur, cutCube).geometry
      // binnenMuur.geometry = CSG.subtract(binnenMuur, cutCube).geometry

      const verticaalRegelpakket = this.group.children[4].children[0].children
      const horizontaalRegelpakket = this.group.children[4].children[1].children
      for(let i=0; i<verticaalRegelpakket.length; i++) {
        const toCut = verticaalRegelpakket[i]
        verticaalRegelpakket[i] = this.csgSubtract(toCut, cutCube)
        toCut.removeFromParent()
      }
      for(let i=0; i<horizontaalRegelpakket.length; i++) {
        const toCut = horizontaalRegelpakket[i]
        horizontaalRegelpakket[i] = this.csgSubtract(toCut, cutCube)
        toCut.removeFromParent()
      }

      if(this.wallStaander && this.wallStaander.children[0] && this.childWall) {
        
        const geometry1 = new this.THREE.BufferGeometry()
        geometry1.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(this.staanderObject.dx, this.staanderObject.y, this.staanderObject.dz)), 3))
        geometry1.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
        geometry1.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        geometry1.setIndex(boxBuffer.indexBuffer());
        geometry1.groups = boxBuffer.groupBuffer()

        this.wallStaander.children[0].geometry = geometry1
        this.wallStaander.children[0].position.y = this.y/2

        cutCube.position.x = cutCube.position.x - this.wallStaander.position.x
        // cutCube.updateMatrix()

        // console.log(this.wallStaander.position, this.wallStaander.children[0].position, cutCube.position)
        this.wallStaander.children[0] = this.csgSubtract(this.wallStaander.children[0], cutCube, true)

        const box = new this.THREE.Box3().setFromObject(this.wallStaander.children[0]);
        
        // offset zou nog netter kunnen
        this.wallStaander.children[0].material[0].map.repeat.set(box.max.y / 2320, this.staanderObject.dz / 145)
        this.wallStaander.children[0].material[0].map.offset.set((this.y-box.max.y)/2/ 2320, 0)
        this.wallStaander.children[0].material[0].map.needsUpdate = true
        this.wallStaander.children[0].material[0].needsUpdate = true
        this.wallStaander.children[0].material[2].map.repeat.set(box.max.y / 2320, this.staanderObject.dz / 145)
        this.wallStaander.children[0].material[2].map.offset.set((this.y-box.max.y)/2/ 2320, 0)
        this.wallStaander.children[0].material[2].map.needsUpdate = true
        this.wallStaander.children[0].material[2].needsUpdate = true
        this.wallStaander.children[0].material[4].map.repeat.set(box.max.y / 2320, this.staanderObject.dz / 145)
        this.wallStaander.children[0].material[4].map.offset.set((this.y-box.max.y)/2/ 2320, 0)
        this.wallStaander.children[0].material[4].map.needsUpdate = true
        this.wallStaander.children[0].material[4].needsUpdate = true
      }


      // for(let i=0; i<this.selectFrame.group.children.length-1; i++) {
      //   cutCube.updateMatrixWorld()
      //   cutCube.position.set(0,0,0)
      //   this.selectFrame.group.children[i].updateMatrixWorld()
      //   this.selectFrame.group.children[i].geometry = evaluator.evaluate( this.selectFrame.group.children[i], cutCube, SUBTRACTION).geometry
      //   this.selectFrame.group.children[i].position.set(0,0,0)
      // }

      if(this.alignedRotation) {
        cutCube.position.x = this.set.mx/2+this.staanderGrootte.dx-this.set.mx+(this.group.position.x-posX)-25// geen idee waarom -25
      } else {
        cutCube.position.x = this.set.mx/2-this.staanderGrootte.dx-this.set.mx+(posX-this.group.position.x)+25// geen idee waarom +25
      }

      while(this.selectFrame.group.children.length > 0){
        this.selectFrame.group.remove(this.selectFrame.group.children[0]);
      }

      const geometry = new this.THREE.BufferGeometry()
      geometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(this.set.mx, 200, this.set.mz)), 3))
      geometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
      geometry.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
      geometry.setIndex(boxBuffer.indexBuffer());
      geometry.groups = boxBuffer.groupBuffer()

      // texture
      const transparent = new this.THREE.MeshBasicMaterial({ 
        color: 0xffffff,
        side: this.THREE.DoubleSide,
        transparent: true,
        opacity: 0.0,
        depthWrite: false,
      });
      // eslint-disable-next-line no-unused-vars
      const gradient = new this.THREE.ShaderMaterial({
        uniforms: {
          color1: {
            value: new this.THREE.Color("#ffffff")
          },
          color2: {
            value: new this.THREE.Color("#ffffff")
          }
        },
        vertexShader: `
          varying vec2 vUv;
      
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
          }
        `,
        fragmentShader: `
          uniform vec3 color1;
          uniform vec3 color2;
        
          varying vec2 vUv;
          
          void main() {
            // y < 0 = transparent, > 1 = opaque
            float alpha = smoothstep(0.5, 0.0, vUv.y);

            // y < 1 = color1, > 2 = color2
            float colorMix = smoothstep(1.0, 2.0, vUv.y);
            
            gl_FragColor = vec4(mix(color1, color2, colorMix), alpha);
          }
        `,
        transparent: true,
        side: this.THREE.DoubleSide,
        depthWrite: false,
      });

      // mesh
      const hoverLine0 = new Brush(geometry, [
        transparent,
        transparent,
        transparent,
        transparent,
        gradient,
        gradient,
      ])

      const hoverLine2 = hoverLine0.clone()
      hoverLine2.rotation.z = Math.PI
      hoverLine2.position.y = this.set.my - 200

      const geometry1 = new this.THREE.BufferGeometry()
      geometry1.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(this.set.my, 200, this.set.mz)), 3))
      geometry1.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
      geometry1.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
      geometry1.setIndex(boxBuffer.indexBuffer());
      geometry1.groups = boxBuffer.groupBuffer()

      const hoverLine1 = new Brush(geometry1, [
        transparent,
        transparent,
        transparent,
        transparent,
        gradient,
        gradient,
      ])
      hoverLine1.rotation.z = 0.5 * Math.PI
      hoverLine1.position.y = this.set.my / 2 - 100
      hoverLine1.position.x = this.set.mx / 2 - 100

      const hoverLine3 = hoverLine1.clone()
      hoverLine3.rotation.z = 1.5 * Math.PI
      hoverLine3.position.x = - this.set.mx / 2 + 100

      hoverLine0.updateMatrixWorld()
      hoverLine1.updateMatrixWorld()
      hoverLine2.updateMatrixWorld()
      hoverLine3.updateMatrixWorld()

      cutCube.updateMatrixWorld()
      const cutHoverLine0 = this.csgSubtract(hoverLine0, cutCube)
      const cutHoverLine1 = this.csgSubtract(hoverLine1, cutCube)
      const cutHoverLine2 = this.csgSubtract(hoverLine2, cutCube)
      const cutHoverLine3 = this.csgSubtract(hoverLine3, cutCube)

      cutHoverLine0.position.x = 0
      cutHoverLine0.position.y = 0
      cutHoverLine0.position.z = 0

      cutHoverLine1.position.x = 0
      cutHoverLine1.position.y = 0
      cutHoverLine1.position.z = 0

      cutHoverLine2.position.x = 0
      cutHoverLine2.position.y = 0
      cutHoverLine2.position.z = 0

      cutHoverLine3.position.x = 0
      cutHoverLine3.position.y = 0
      cutHoverLine3.position.z = 0

      this.selectFrame.group.add(cutHoverLine0)
      this.selectFrame.group.add(cutHoverLine1)
      this.selectFrame.group.add(cutHoverLine2)
      this.selectFrame.group.add(cutHoverLine3)
      // if(this.selectFrame.group.getObjectByName("cutCube")) {
      //   this.selectFrame.group.getObjectByName("cutCube").removeFromParent()
      // }
      // this.selectFrame.group.add(cutCube)
      // TOT DIT STUK WERKT BIJNA

      // hoverLine1.rotation.z = 0.5 * Math.PI
      // hoverLine1.position.y = this.set.my / 2 - 100
      // hoverLine1.position.x = this.set.mx / 2 - 100
      
      // const hoverLine3 = hoverLine1.clone()
      // hoverLine3.rotation.z = 1.5 * Math.PI
      // hoverLine3.position.x = - this.set.mx / 2 + 100

      // this.selectFrame.group.add(hoverLine0)
      // this.selectFrame.group.add(hoverLine1)
      // this.selectFrame.group.add(hoverLine2)
      // this.selectFrame.group.add(hoverLine3)



      // const evaluator = new Evaluator();
      // const newMeshes = []
      // for(let i=0; i<this.selectFrame.group.children.length; i++) {
      //   cutCube.position.set(0,0,0)
      //   cutCube.updateMatrixWorld()
      //   this.selectFrame.group.children[i].position.set(0,0,0)
      //   this.selectFrame.group.children[i].updateMatrixWorld()
      //   newMeshes.push(evaluator.evaluate( this.selectFrame.group.children[i], cutCube, SUBTRACTION))
      // }
      // while(this.selectFrame.group.children.length > 0){
      //   this.selectFrame.group.remove(this.selectFrame.group.children[0]);
      // }
      // for(let i=0; i<newMeshes.length; i++) {
      //   newMeshes[i].position.set(0,0,0)
      //   this.selectFrame.group.add(newMeshes[i])
      // }

      this.hastDisabledPaalCutting = true
      if(this.wallStaander && this.wallStaander.children[0] && this.childWall && !this.hastDisabledPaalCutting) {
        
        const geometry1 = new this.THREE.BufferGeometry()
        geometry1.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(this.staanderObject.dx, this.staanderObject.y, this.staanderObject.dz)), 3))
        geometry1.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
        geometry1.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        geometry1.setIndex(boxBuffer.indexBuffer());
        geometry1.groups = boxBuffer.groupBuffer()

        this.wallStaander.children[0].geometry = geometry1

        cutCube.position.x = cutCube.position.x - this.wallStaander.position.x
        cutCube.updateMatrix()

        this.wallStaander.updateMatrix()
        this.wallStaander.children[0].updateMatrix()
        this.wallStaander.children[0].geometry = CSG.subtract(this.wallStaander.children[0], cutCube).geometry

        const box = new this.THREE.Box3().setFromObject(this.wallStaander.children[0]);
        
        // offset zou nog netter kunnen
        this.wallStaander.children[0].material[0].map.repeat.set(box.max.y / 2320, this.staanderObject.dz / 145)
        this.wallStaander.children[0].material[0].map.offset.set((this.y-box.max.y)/2/ 2320, 0)
        this.wallStaander.children[0].material[0].map.needsUpdate = true
        this.wallStaander.children[0].material[0].needsUpdate = true
        this.wallStaander.children[0].material[2].map.repeat.set(box.max.y / 2320, this.staanderObject.dz / 145)
        this.wallStaander.children[0].material[2].map.offset.set((this.y-box.max.y)/2/ 2320, 0)
        this.wallStaander.children[0].material[2].map.needsUpdate = true
        this.wallStaander.children[0].material[2].needsUpdate = true
        this.wallStaander.children[0].material[4].map.repeat.set(box.max.y / 2320, this.staanderObject.dz / 145)
        this.wallStaander.children[0].material[4].map.offset.set((this.y-box.max.y)/2/ 2320, 0)
        this.wallStaander.children[0].material[4].map.needsUpdate = true
        this.wallStaander.children[0].material[4].needsUpdate = true
      }

      // while(this.group.getObjectByName("cutCube")) {
      //   this.group.getObjectByName("cutCube").removeFromParent()
      // }
      // cutCube.position.copy(cutCubePosition)
      // this.group.add(cutCube)

      // console.log(cutCube.position.x, this.parentWall, this.childWall, posX)
      // if(this.group.getObjectByName("cutCube")) this.group.getObjectByName("cutCube").removeFromParent()
      // this.group.add(cutCube)
    }

    if(_callback) {
      _callback()
    }
  }

  setHover(on) {
    //if (!this.wallOn) {
      this.hover = on
      // console.log("setHover", on)
      //this.hoverLine0.group.children[0].visible = on
      //this.hoverLine0.group.children[1].visible = on
      //this.hoverLine0.group.visible = on
      //this.mapGroup.children[0].visible = on
      //this.mapGroup.children[1].visible = on
      // console.log(this.hoverLine0)
      if(on && !this.isSlidingWall) {
        //alert("hover on")
        this.group.children[1].add(this.hoverLine0.group)
        // show hover buttons:
        if(this.settings.binnenMuur || this.settings.buitenMuur){
          this.group.children[1].add(this.hoverIsoMainGroup);
        }
        //this.mapGroup.add(this.mapHoverElements.buitenmuurMap)
        //this.mapGroup.add(this.mapHoverElements.binnenmuurMap)
      } else {
        this.hoverLine0.group.removeFromParent()
        // hide hover buttons:
        this.hoverIsoMainGroup?.removeFromParent()
        /*if(this.mapGroup.getObjectByName("binnenmuurMap") !== undefined) {
          this.mapGroup.getObjectByName("binnenmuurMap").removeFromParent()
        }
        if(this.mapGroup.getObjectByName("buitenmuurMap") !== undefined) {
          this.mapGroup.getObjectByName("buitenmuurMap").removeFromParent()
        }*/
      }

      // open wand vakken uit/aan zetten
      const binnenMuur = this.group.children[3]
      const buitenMuur = this.group.children[2]
      if ((this.settings.binnenMuur && this.settings.binnenMuurTexture.open) || (this.settings.buitenMuur && this.settings.buitenMuurTexture.open)) {
        if (on) {
          binnenMuur.visible = this.settings.binnenMuur && this.settings.binnenMuurTexture
          buitenMuur.visible = this.settings.buitenMuur && this.settings.buitenMuurTexture
        } else {
          binnenMuur.visible = !(this.settings.binnenMuur && this.settings.binnenMuurTexture) || (this.settings.buitenMuur && this.settings.buitenMuurTexture)
          buitenMuur.visible = !(this.settings.buitenMuur && this.settings.buitenMuurTexture) || (this.settings.binnenMuur && this.settings.binnenMuurTexture)
        }
      }
    //}
    if(this.lockIcon && (this.settings.buitenMuur || this.settings.binnenMuur)) {this.lockIcon.visible=false}

  }

  setWallPlusIcons(data, component, emptyChildWalls, emptyParentWalls) {

    // fix 25/04/2024 altijd ruimte nodig voor staander anders gaat wanden systeem fout
    this.config.needsSpaceForStaander = true

    for (let i = 0; i < data.length; i++) {
      this.wallButtonIndex[i].canFitAtLocation = data[i]
    }
    if(component && (component.category === "Steellook" || component.category === "Steellook Wand BW")) {
      this.wallButtonIndex[3].canFitAtLocation = this.wallButtonIndex[3].canFitAtLocation ? (emptyChildWalls.totalWidth) === component.dimensions.x ? true :
      this.childWall?.isLocked && (this.set.mx < component.dimensions.x+this.config.minWallWidth+(this.config.needsSpaceForStaander ? this.staanderObject.dx : 0)) ? false : (emptyChildWalls.totalWidth) >= (component.dimensions.x+this.config.minWallWidth+(this.config.needsSpaceForStaander ? this.staanderObject.dx : 0)) : false
      this.wallButtonIndex[4].canFitAtLocation = this.wallButtonIndex[4].canFitAtLocation ? this.set.mx >= (component.dimensions.x+(2*(this.config.minWallWidth+(this.config.needsSpaceForStaander ? this.staanderObject.dx : 0)))) : false
      this.wallButtonIndex[5].canFitAtLocation = this.wallButtonIndex[5].canFitAtLocation ? (emptyParentWalls.totalWidth) === component.dimensions.x ? true :
      this.parentWall?.isLocked && (this.set.mx < component.dimensions.x+this.config.minWallWidth+(this.config.needsSpaceForStaander ? this.staanderObject.dx : 0)) ? false : (emptyParentWalls.totalWidth) >= (component.dimensions.x+this.config.minWallWidth+(this.config.needsSpaceForStaander ? this.staanderObject.dx : 0)) : false
    } else if(component && (component.category === "Schuifwand")) {
      if(store.state.cms.schuifwandWidthRanges.length) {
        const min = store.state.cms.schuifwandWidthRanges[0].min
        const max = store.state.cms.schuifwandWidthRanges[store.state.cms.schuifwandWidthRanges.length-1].max
        this.wallButtonIndex[3].canFitAtLocation = false
        this.wallButtonIndex[4].canFitAtLocation = this.set.mx >= min && this.set.mx <= max
        this.wallButtonIndex[5].canFitAtLocation = false
      }
    }
  }

  setElementPlacing(bool) {
    this.shouldShowPlusIcons = bool
    // shouldHideBecauseOfKapschuur is nu ook voor veranda's
    const shouldHideBecauseOfKapschuur = (this.modelType.isKapschuur || this.veranda?.enabled) && !this.alignsXAxis && !this.alignedRotation
    const shouldHideLeftBecauseOfKapschuur = this.modelType.isKapschuur && this.alignsXAxis && this.alignedRotation
    const shouldHideRightBecauseOfKapschuur = this.modelType.isKapschuur && this.alignsXAxis && !this.alignedRotation
    // console.log(shouldHideBecauseOfKapschuur, shouldHideLeftBecauseOfKapschuur, shouldHideRightBecauseOfKapschuur)

    // isPlacingShuifwand
    if(bool && !this.isSlidingWall &&!this.isBorstwering && !((this.settings.binnenMuur && this.settings.binnenMuurTexture.open) || (this.settings.buitenMuur && this.settings.buitenMuurTexture.open))) {
      // if (this.wallOn) console.log("muur setElementPlacing", "aan")
      if(!shouldHideBecauseOfKapschuur && !shouldHideLeftBecauseOfKapschuur && this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && this.wallButtonIndex[0].canFitAtLocation) {
        this.plusIconGroup.add(this.plusIconGroupDisabled[0])
      } else {
        this.plusIconGroupDisabled[0].removeFromParent()
      }
      if(!shouldHideBecauseOfKapschuur && !shouldHideLeftBecauseOfKapschuur && this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && this.set.tussenMuur && this.wallButtonIndex[0].canFitAtLocation) {
        this.plusIconGroup.add(this.plusIconGroupDisabled[1])
      } else {
        this.plusIconGroupDisabled[1].removeFromParent()
      }
      if(!shouldHideBecauseOfKapschuur && this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled && this.wallButtonIndex[1].canFitAtLocation){
        this.plusIconGroup.add(this.plusIconGroupDisabled[2])
      } else {
        this.plusIconGroupDisabled[2].removeFromParent()
      }
      if(!shouldHideBecauseOfKapschuur && this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled && this.set.tussenMuur && this.wallButtonIndex[1].canFitAtLocation){
        this.plusIconGroup.add(this.plusIconGroupDisabled[3])
      } else {
        this.plusIconGroupDisabled[3].removeFromParent()
      }
      if(!shouldHideBecauseOfKapschuur && !shouldHideRightBecauseOfKapschuur && this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled && this.wallButtonIndex[2].canFitAtLocation){
        this.plusIconGroup.add(this.plusIconGroupDisabled[4])
      } else {
        this.plusIconGroupDisabled[4].removeFromParent()
      }
      if(!shouldHideBecauseOfKapschuur && !shouldHideRightBecauseOfKapschuur && this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled && this.set.tussenMuur && this.wallButtonIndex[2].canFitAtLocation){
        this.plusIconGroup.add(this.plusIconGroupDisabled[5])
      } else {
        this.plusIconGroupDisabled[5].removeFromParent()
      }
      if(!shouldHideBecauseOfKapschuur && !shouldHideLeftBecauseOfKapschuur && !this.wallOn && this.wallButtonIndex[3].canFitAtLocation){
        this.plusIconGroup.add(this.plusIconGroupDisabled[6])
      } else {
        this.plusIconGroupDisabled[6].removeFromParent()
      }
      if(!shouldHideBecauseOfKapschuur && !this.wallOn && this.wallButtonIndex[4].canFitAtLocation){
        this.plusIconGroup.add(this.plusIconGroupDisabled[7])
      } else {
        this.plusIconGroupDisabled[7].removeFromParent()
      }
      if(!shouldHideBecauseOfKapschuur && !shouldHideRightBecauseOfKapschuur && !this.wallOn && this.wallButtonIndex[5].canFitAtLocation){
        this.plusIconGroup.add(this.plusIconGroupDisabled[8])
      } else {
        this.plusIconGroupDisabled[8].removeFromParent()
      }


    } else {
      this.plusIconGroup.children = []
    }
  }
  

  showPreview(settings, uuid) {
    // steellooks met een borstwering mogen geen open wandvak hebben
    if (this.isBorstwering && ((settings.binnenMuur && settings.binnenMuurTexture.open) || (settings.buitenMuur && settings.buitenMuurTexture.open))) return

    if (this.veranda?.enabled && !this.alignsXAxis && !this.alignedRotation) {
      settings.buitenMuur = false // voor veranda
    }
    // console.log("showPreview", settings, uuid)
    // console.log("buitenmuurpreview", this.group.children[2])
    if(settings.binnenMuur ? !(this.showInFuture.binnen === uuid) : (settings.buitenMuur ? !(this.showInFuture.buiten === uuid) : true)) {return}
    // console.log(settings)
    // console.log(settings.binnenMuur ? !this.settings.binnenMuur : settings.buitenMuur ? !this.settings.buitenMuur : false)
    // console.log(!this.settings.binnenMuur)
    if ( /*!this.wallOn*/ (!this.wallOn || this.wallOn) && settings.binnenMuur ? !this.settings.binnenMuur : settings.buitenMuur ? !this.settings.buitenMuur : false) {

      const s0 = this.group.children[0].children[0]
      const s1 = this.group.children[0].children[1]

      s0.visible = !this.set.tussenMuur && (settings.buitenMuur ? (!!settings.buitenMuurTexture.open) : true) && (settings.binnenMuur ? (!!settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
      s1.visible = !this.set.tussenMuur && (settings.buitenMuur ? (!!settings.buitenMuurTexture.open) : true) && (settings.binnenMuur ? (!!settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)

      // this.hover = false
      //this.hoverLine0.group.visible = false

      // settings.binnenMuurTexture.open = settings.binnenMuurTexture.id == "6622509ad0774fabfa45b6f9"
      // settings.buitenMuurTexture.open = settings.buitenMuurTexture.id == "6622509ad0774fabfa45b6f9"

      const buitenMuur = this.group.children[2]
      const binnenMuur = this.group.children[3]

      const frame = this.group.children[4]
      // console.log(settings.binnenMuurTexture)
      // if (settings.binnenMuur/*this.binnenMuurTexture !== settings.binnenMuurTexture || settings.binnenMuur || !this.undefined*/) {
      //
      //   this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.binnenMuurTexture.url}`, (newTexture) => {
      //     if(Array.isArray(binnenMuur.material)) {
      //       for(const texture of binnenMuur.material) {
      //         if (texture.map) {
      //           // texture.map.dispose()
      //         }
      //         texture.map = newTexture
      //         if (this.settings.binnenMuurTexture.open) {
      //           this.textureSettings(texture.map, 1, 1)
      //         } else {
      //           this.textureSettings(texture.map, this.set.mx * 0.000351, this.set.my * 0.0004331)
      //         }
      //         texture.transparent = true //settings.binnenMuurTexture.open
      //         texture.opacity = 0.75
      //         texture.map.needsUpdate = true;
      //         texture.needsUpdate = true;
      //       }
      //     } else {
      //       const texture = binnenMuur.material
      //       if(texture.map) {
      //         // texture.map.dispose()
      //       }
      //       texture.map = newTexture
      //       if (this.settings.binnenMuurTexture.open) {
      //         this.textureSettings(texture.map, 1, 1)
      //       } else {
      //         this.textureSettings(texture.map, this.set.mx * 0.000351, this.set.my * 0.0004331)
      //       }
      //       texture.transparent = true //settings.binnenMuurTexture.open
      //       texture.opacity = 0.75
      //       texture.map.needsUpdate = true;
      //       texture.needsUpdate = true;
      //     }
      //
      //     this.binnenMuurTexture = settings.binnenMuurTexture
      //   })
      // }
      if (settings.binnenMuur) {
        if(Array.isArray(binnenMuur.material)) {
          for(const material of binnenMuur.material) {
            material.transparent = true //settings.binnenMuurTexture.open
            material.opacity = 0.75
            if (material.map) material.map.needsUpdate = true;
            material.needsUpdate = true;
          }
        } else {
          binnenMuur.material.transparent = true //settings.binnenMuurTexture.open
          binnenMuur.material.opacity = 0.75
          if (binnenMuur.material.map) binnenMuur.material.map.needsUpdate = true;
          binnenMuur.material.needsUpdate = true;
        }
      }

      // if (settings.buitenMuur/*this.buitenMuurTexture !== settings.buitenMuurTexture || settings.buitenMuur || !this.undefined*/) {
      //   // console.log(settings.buitenMuurTexture)
      //   this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.buitenMuurTexture.url}`, (newTexture) => {
      //     const wallWidth = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.mx + 2*this.staanderGrootte.dx : this.set.mx
      //     const wallHeight = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.my+16 : this.set.my
      //     if(Array.isArray(buitenMuur.material)) {
      //       for(const texture of buitenMuur.material) {
      //         if (texture.map) {
      //           // texture.map.dispose()
      //         }
      //         // texture.map.dispose()
      //         texture.map = newTexture
      //         if (this.settings.buitenMuurTexture.open) {
      //           this.textureSettings(texture.map, 1, 1)
      //         } else {
      //           this.textureSettings(texture.map, wallWidth * 0.000351, wallHeight * 0.0004331)
      //         }
      //         texture.transparent = true //settings.buitenMuurTexture.open
      //         texture.opacity = 0.75
      //         texture.map.needsUpdate = true;
      //         texture.needsUpdate = true;
      //       }
      //     } else {
      //       const texture = buitenMuur.material
      //       if(texture.map) {
      //         // texture.map.dispose()
      //       }
      //       texture.map = newTexture
      //       if (this.settings.buitenMuurTexture.open) {
      //         this.textureSettings(texture.map, 1, 1)
      //       } else {
      //         this.textureSettings(texture.map, wallWidth * 0.000351, wallHeight * 0.0004331)
      //       }
      //       texture.transparent = true //settings.buitenMuurTexture.open
      //       texture.opacity = 0.75
      //       texture.map.needsUpdate = true;
      //       texture.needsUpdate = true;
      //     }
      //
      //     this.buitenMuurTexture = settings.buitenMuurTexture
      //   })
      // }
      if (settings.buitenMuur) {
        if(Array.isArray(buitenMuur.material)) {
          for(const material of buitenMuur.material) {
            material.transparent = true //settings.binnenMuurTexture.open
            material.opacity = 0.75
            if (material.map) material.map.needsUpdate = true;
            material.needsUpdate = true;
          }
        } else {
          buitenMuur.material.transparent = true //settings.binnenMuurTexture.open
          buitenMuur.material.opacity = 0.75
          if (buitenMuur.material.map) buitenMuur.material.map.needsUpdate = true;
          buitenMuur.material.needsUpdate = true;
        }
      }

      // console.log('staat dit er??')
      // console.log(binnenMuur.visible)
      // console.log(settings.binnenMuur)
      binnenMuur.visible = binnenMuur.visible || settings.binnenMuur
      buitenMuur.visible = buitenMuur.visible || settings.buitenMuur
      frame.visible = settings.binnenMuur || settings.buitenMuur
      frame.children[0].visible =
        (this.settings.binnenMuur || settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'vertical') : false) ||
        (this.settings.buitenMuur || settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'vertical') : false)
      frame.children[1].visible =
        (this.settings.binnenMuur || settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'horizontal') : false) ||
        (this.settings.buitenMuur || settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'horizontal') : false)

      for (let i = 0; i < frame.children.length; i++) {
        if(!frame.children[i].children[0]) {
          return
        }
        frame.children[i].children[0].material[0].transparent = true
        frame.children[i].children[0].material[0].opacity = 0.75
        frame.children[i].children[0].material[0].needsUpdate = true

        frame.children[i].children[0].material[2].transparent = true
        frame.children[i].children[0].material[2].opacity = 0.75
        frame.children[i].children[0].material[2].needsUpdate = true

        frame.children[i].children[2].material[0].transparent = true
        frame.children[i].children[2].material[0].opacity = 0.75
        frame.children[i].children[2].material[0].needsUpdate = true

        frame.children[i].children[2].material[2].transparent = true
        frame.children[i].children[2].material[2].opacity = 0.75
        frame.children[i].children[2].material[2].needsUpdate = true
      }

      if (settings.buitenMuur) {
        this.group.children[3].scale.set(1, 1, 1)
      }
      if (settings.binnenMuur) {
        this.group.children[3].scale.set(1, 1, 1)
      }

      //const redMaterial = new this.THREE.MeshStandardMaterial( {color: 0xff0000, side: this.THREE.FrontSide} );
      //const greenMaterial = new this.THREE.MeshStandardMaterial( {color: 0x00ff00, side: this.THREE.FrontSide} );
      var binnenMuurKleur = settings.binnenMuurTexture.hex
      var buitenMuurKleur = settings.buitenMuurTexture.hex
      // console.log(binnenMuurTextureType + "   " + buitenMuurTextureType)
      // console.log(binnenMuurKleur + buitenMuurKleur)
      // console.log(settings.binnenMuurTexture, settings.buitenMuurTexture)
      const binnenMuurMaterial = (this.settings.binnenMuur && this.settings.binnenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(binnenMuurKleur)
      const buitenMuurMaterial = (this.settings.buitenMuur && this.settings.buitenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(buitenMuurKleur)
      // console.log(settings)
      if (settings.binnenMuur) {
        //this.hoverLine0.group.children[0].material[1] = binnenMuurMaterial
        this.hoverLine0.setHover({hover: {inside: true}, colorInsideFill: binnenMuurKleur})
        //this.mapGroup.children[1].material[1] = binnenMuurMaterial
        this.mapHoverElements.binnenmuurMap.material[1] = binnenMuurMaterial
      } else if (settings.buitenMuur) {
        //this.hoverLine0.group.children[1].material[1] = buitenMuurMaterial
        this.hoverLine0.setHover({hover: {outside: true}, colorOutsideFill: buitenMuurKleur})
        //this.mapGroup.children[0].material[1] = buitenMuurMaterial
        this.mapHoverElements.buitenmuurMap.material[1] = buitenMuurMaterial
      }
    } else {
      /*const transparentMaterial = new this.THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: this.THREE.DoubleSide,
        transparent: true,
        opacity: 0.0,
        depthWrite: false,
      });*/
      // console.log(this.hoverLine0.group.children[0])
      /*if (settings.binnenMuur) {
        //this.hoverLine0.group.children[0].material[1] = transparentMaterial
        this.hoverLine0.setHover({hover: {inside: false}})
      } else if (settings.buitenMuur) {
        //this.hoverLine0.group.children[1].material[1] = transparentMaterial
        this.hoverLine0.setHover({hover: {outside: false}})
      }*/

    }
  }

  hidePreview(hoverlineName) {
    // console.log("hide preview")
    if(hoverlineName === 'hoverLineBinnen') {
      this.showInFuture.binnen = false
    }
    if(hoverlineName === 'hoverLineBuiten') {
      this.showInFuture.buiten = false
    }
    
    // console.log('hide preview')
    // console.log(this.settings)
    // console.log(!this.wallOn)
    // console.log(this.settings.binnenMuur)
    // console.log(this.settings.buitenMuur)
    if (!this.wallOn || hoverlineName === 'hoverLineBinnen' ? !this.settings.binnenMuur : hoverlineName === 'hoverLineBuiten' ? !this.settings.buitenMuur : false) {
      // console.log('dit moet je zien!')
      const buitenMuur = this.group.children[2]
      const binnenMuur = this.group.children[3]

      const frame = this.group.children[4]

      if (hoverlineName === 'hoverLineBinnen') {
        binnenMuur.visible = false
      }
      if (hoverlineName === 'hoverLineBuiten') {
        buitenMuur.visible = false
      }
      frame.visible = binnenMuur.visible || buitenMuur.visible



      const s0 = this.group.children[0].children[0]
      const s1 = this.group.children[0].children[1]

      s0.visible = !this.set.tussenMuur && (this.settings.buitenMuur ? (!!this.settings.buitenMuurTexture.open) : true) && (this.settings.binnenMuur ? (!!this.settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
      s1.visible = !this.set.tussenMuur && (this.settings.buitenMuur ? (!!this.settings.buitenMuurTexture.open) : true) && (this.settings.binnenMuur ? (!!this.settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)
      this.schoorGroup.visible = this.set.mx >= this.schoor.wallMinWidth && !this.isSlidingWall && !this.isBorstwering
      // this.schoorGroup.position.z = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) ? this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2  : 0
      if (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) {
        if(!this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
          this.schoorGroup.position.z = this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2
        } else {
          this.schoorGroup.position.z = - (this.staanderGrootte.dx - this.schoor.sx)/2
        }
      } else {
        this.schoorGroup.position.z = 0
      }

      // this.hover = true
      this.hoverLine0.group.visible = true
      const transparentMaterial = new this.THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: this.THREE.DoubleSide,
        transparent: true,
        opacity: 0.0,
        depthWrite: false,
      });
      // console.log(this.hoverLine0.group.children[0])
      if (hoverlineName === 'hoverLineBinnen') {
        this.hoverLine0.setHover({hover: {inside: false}})
        //this.hoverLine0.group.children[0].material[1] = transparentMaterial
        this.mapHoverElements.binnenmuurMap.material[1] = transparentMaterial
      } else if (hoverlineName === 'hoverLineBuiten') {
        this.hoverLine0.setHover({hover: {outside: false}})
        //this.hoverLine0.group.children[1].material[1] = transparentMaterial
        this.mapHoverElements.buitenmuurMap.material[1] = transparentMaterial
      }
    } else {
      // console.log(this.hoverLine0.group.children[0])
      var binnenMuurKleur = this.settings.binnenMuurTexture.hex
      var buitenMuurKleur = this.settings.buitenMuurTexture.hex
      if (hoverlineName === 'hoverLineBinnen') {
        this.hoverLine0.setHover({hover: {inside: true}, colorInsideFill: binnenMuurKleur, colorOutsideFill: buitenMuurKleur})
      } else if (hoverlineName === 'hoverLineBuiten') {
        this.hoverLine0.setHover({hover: {outside: true}, colorInsideFill: binnenMuurKleur, colorOutsideFill: buitenMuurKleur})
      }
    }
    const frame = this.group.children[4]
    frame.children[0].visible =
      (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'vertical') : false) ||
      (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'vertical') : false)
    frame.children[1].visible =
      (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'horizontal') : false) ||
      (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'horizontal') : false)
    for (let i = 0; i < frame.children.length; i++) {
      if(!frame.children[i].children[0]) {
        return
      }
      frame.children[i].children[0].material[0].transparent = false
      frame.children[i].children[0].material[0].opacity = 1
      frame.children[i].children[0].material[0].needsUpdate = true

      frame.children[i].children[0].material[2].transparent = false
      frame.children[i].children[0].material[2].opacity = 1
      frame.children[i].children[0].material[2].needsUpdate = true

      frame.children[i].children[2].material[0].transparent = false
      frame.children[i].children[2].material[0].opacity = 1
      frame.children[i].children[2].material[0].needsUpdate = true

      frame.children[i].children[2].material[2].transparent = false
      frame.children[i].children[2].material[2].opacity = 1
      frame.children[i].children[2].material[2].needsUpdate = true
    }

    this.updateMuurScalingForHitbox()
  }

  // getMapIsoIcon(heeftIsolatie) {
  //   // const graphic = heeftIsolatie ? 'Wandisolatie_AAN.svg' : 'Wandisolatie_UIT.svg'
  //   const graphicName = heeftIsolatie ? 'Wandisolatie_active.png' : 'Wandisolatie_inactive.png'
  //   const graphicMap = new this.THREE.TextureLoader().load(`${previewsServer}/textures/ui/${graphicName}`);
  //   // graphicMap.anisotropy = 0;
  //   graphicMap.anisotropy = 16;
  //   graphicMap.encoding = this.THREE.sRGBEncoding;
  //   const spriteMaterial = new this.THREE.MeshBasicMaterial( { map: graphicMap, side: this.THREE.DoubleSide, transparent: true, alphaTest: 0.5/*, depthWrite: false*/} );
  //   const spriteGeometry = new this.THREE.BufferGeometry()
  //   spriteGeometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(300, 300)), 3))
  //   spriteGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
  //   spriteGeometry.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
  //   const spriteMesh = new this.THREE.Mesh( spriteGeometry, spriteMaterial );
  //   spriteMesh.name = heeftIsolatie ? 'isolatieMapButtonActive' : 'isolatieMapButtonInactive'; 
  //   const meshGroup = new this.THREE.Group();
  //   // meshGroup.name = 'isolatieButtonGroup'
  //   meshGroup.name = heeftIsolatie ? 'isolatieMapButtonGroupActive' : 'isolatieMapButtonGroupInactive';
  //   meshGroup.add(spriteMesh)
  //   return meshGroup;
    // // console.log('positionObject',positionObject)
    // meshGroup.position.set(positionObject.x,positionObject.y,positionObject.z);

    // const loader = new SVGLoader();
    // const upperThis = this;
    // loader.load(`${previewsServer}/icons/${graphic}`, function (data) {
    //   // assume the SVG has paths
    //   const paths = data.paths;

    //   for ( let i = 0; i < paths.length; i ++ ) { 
    //     const path = paths[i];
    //     const material = new upperThis.THREE.MeshBasicMaterial({
    //       color: path.color,//0x00ff00,
    //       side: upperThis.THREE.DoubleSide,
    //       depthWrite: false
    //     });

    //     const shapes = path.toShapes(true); // convert path
    //     for ( let j = 0; j < shapes.length; j ++ ) {
    //       const shape = shapes[j];
    //       const geometry = new upperThis.THREE.ExtrudeGeometry(shape, {
    //         depth: 1, 
    //       });
    //       geometry.rotateX(90);
    //       const newMesh = new upperThis.THREE.Mesh(geometry, material);

    //       newMesh.name = 'buttonMeshPart'
    //       newMesh.visible = isVisible;
    //       meshGroup.add(newMesh);
    //     }
    //   }
    // });
    // console.log('meshGroup',meshGroup);
    // return meshGroup;
  // }

  addWall(settings) {
    // steellooks met een borstwering mogen geen open wandvak hebben
    if (this.isBorstwering && (((settings.binnenMuur?!this.settings.binnenMuur:this.settings.binnenMuur) && settings.binnenMuurTexture.open) || ((settings.buitenMuur?!this.settings.buitenMuur:this.settings.buitenMuur) && settings.buitenMuurTexture.open))) return

    if (this.veranda?.enabled && !this.alignsXAxis && !this.alignedRotation) {
      settings.buitenMuur = false
    }
    this.showInFuture.binnen = false
    this.showInFuture.buiten = false

    if (settings.binnenMuur) {
      this.settings.binnenMuur = !this.settings.binnenMuur
      this.settings.binnenMuurTexture = settings.binnenMuurTexture
      this.settings.connectionBinnen = settings.connectionBinnen
    }
    if (settings.buitenMuur) {
      this.settings.buitenMuur = !this.settings.buitenMuur
      this.settings.buitenMuurTexture = settings.buitenMuurTexture
      this.settings.connectionBuiten = settings.connectionBuiten
    }

    this.changeLockMode(this.settings.binnenMuur || this.settings.buitenMuur)
    this.update()
    if(!this.wallOn) {
      this.deselect()
      this.removeWall()
    }
    // isolatie knoppen - add to hoverLineGroup
    // console.log('this.hoverIsoMainGroup',this.hoverIsoMainGroup)
    this.group.children[1].add(this.hoverIsoMainGroup);
    this.mapGroup.add(this.mapIsoMainGroup);

    // TPLA-223 fix staanders
    if(this.settings.tussenMuur) {
      if(this.wallOn) {
        if(this.wallStaander == null && this.childWall != null) {
          this.showWallStaander(this.staanderObject.frameTexture)
          this.wallStaander.position.x = this.set.mx + this.staanderObject.dx/2
          this.mapWallStaander.position.x = this.set.mx + this.staanderObject.dx/2
        }
        if(this.parentWall) {
          this.parentWall.showWallStaander(this.staanderObject.frameTexture)
          this.parentWall.wallStaander.position.x = this.parentWall.set.mx + this.parentWall.staanderObject.dx/2
          this.parentWall.mapWallStaander.position.x = this.parentWall.set.mx + this.parentWall.staanderObject.dx/2
        }
      } else {
        if(this.childWall && !this.childWall.wallOn && this.wallStaander) {
          this.wallStaander.removeFromParent()
          this.mapWallStaander.removeFromParent()
          this.wallStaander = null
          this.wallStaanderInfo = null
          this.mapWallStaander = null
        }
        if(this.parentWall && !this.parentWall.wallOn && this.parentWall.wallStaander) {
          this.parentWall.wallStaander.removeFromParent()
          this.parentWall.mapWallStaander.removeFromParent()
          this.parentWall.wallStaander = null
          this.wallStaanderInfo = null
          this.parentWall.mapWallStaander = null
        }
      }
    }

    if (!this.undefined) return // always return without causing an error
    /* 
      even niks hier onder toevoegen - besproken met Job
    */

    // console.log("addwall muur.js", settings)
    if ( /*!this.wallOn &&*/ (settings.binnenMuur || settings.buitenMuur) && settings.binnenMuur ? !this.settings.binnenMuur : settings.buitenMuur ? !this.settings.buitenMuur : false) {
      // console.log("addwall muur.js if", settings)
      // settings.binnenMuurTexture = {name: 'Behandeld', url: '62d6621dd973225dbe295ee9.jpeg', previewURL: '62d6621dd973225dbe295ee9.jpeg', hex: '#B87854', id: '62ea9272d4ac06cce974be8d', plankType: '62d13023a04bd6a5191aeede'},
      // settings.buitenMuurTexture = {name: 'Behandeld', url: '62d6621dd973225dbe295ee9.jpeg', previewURL: '62d6621dd973225dbe295ee9.jpeg', hex: '#B87854', id: '62ea9272d4ac06cce974be8d', plankType: '62d13023a04bd6a5191aeede'},
      // settings.connectionBinnen = "horizontal",
      // settings.connectionBuiten = "horizontal",

      //this.settings = settings
      this.settings.binnenMuur = settings.binnenMuur
      this.settings.binnenMuurTexture = settings.binnenMuurTexture
      // this.settings.binnenMuurTexture.open = false//settings.binnenMuurTexture.id == "6622509ad0774fabfa45b6f9"
      // settings.binnenMuurTexture.open = false//settings.binnenMuurTexture.id == "6622509ad0774fabfa45b6f9"
      this.settings.buitenMuur = settings.buitenMuur
      this.settings.buitenMuurTexture = settings.buitenMuurTexture
      // this.settings.buitenMuurTexture.open = false//settings.buitenMuurTexture.id == "6622509ad0774fabfa45b6f9"
      // settings.buitenMuurTexture.open = false//settings.buitenMuurTexture.id == "6622509ad0774fabfa45b6f9"
      this.settings.connectionBinnen = settings.connectionBinnen
      this.settings.connectionBuiten = settings.connectionBuiten

      
      this.wallOn = true
      

      this.betonBand.betonBand.visible = this.fundering && (this.settings.buitenMuur ? !this.settings.buitenMuurTexture.open : false || this.settings.binnenMuur ? !this.settings.binnenMuurTexture.open : false)

      // if(this.fundering) {
      //   this.betonBand.betonBandShow()
       
      // }

      const s0 = this.group.children[0].children[0]
      const s1 = this.group.children[0].children[1]

      s0.visible = !this.set.tussenMuur && (this.settings.buitenMuur ? (!!this.settings.buitenMuurTexture.open) : true) && (this.settings.binnenMuur ? (!!this.settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
      s1.visible = !this.set.tussenMuur && (this.settings.buitenMuur ? (!!this.settings.buitenMuurTexture.open) : true) && (this.settings.binnenMuur ? (!!this.settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)

      // this.hover = false
      this.hoverLine0.group.visible = true //false

      var binnenMuurKleur = settings.binnenMuurTexture.hex
      var buitenMuurKleur = settings.buitenMuurTexture.hex
      var binnenmuurMapMaterial = (this.settings.binnenMuur && this.settings.binnenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(binnenMuurKleur)
      var buitenmuurMapMaterial = (this.settings.buitenMuur && this.settings.buitenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(buitenMuurKleur)

      if (settings.binnenMuur) {
        this.hoverLine0.setHover({hover: {inside: true}, colorInsideFill: binnenMuurKleur})
        //this.hoverLine0.group.getObjectByName('hoverLineBinnen').material[1] = binnenmuurMapMaterial
        this.mapHoverElements.binnenmuurMap.material[1] = binnenmuurMapMaterial
      }
      if (settings.buitenMuur) {
        this.hoverLine0.setHover({hover: {outside: true}, colorOutsideFill: buitenMuurKleur})
        //this.hoverLine0.group.getObjectByName('hoverLineBuiten').material[1] = buitenmuurMapMaterial
        this.mapHoverElements.buitenmuurMap.material[1] = buitenmuurMapMaterial
      }

      const buitenMuur = this.group.children[2]
      const binnenMuur = this.group.children[3]
      const frame = this.group.children[4]

      if (this.settings.binnenMuurTexture !== settings.binnenMuurTexture || this.settings.binnenMuur !== settings.binnenMuur || !this.undefined) {

        // create new mesh geometry (werkt niet voor kapschuur)
        // const binnenGeometry = new this.THREE.BufferGeometry()
        // const wallHeightBinnen = settings.binnenMuurTexture.open && !this.isSlidingWall ? this.set.my / 4 : this.set.my
        // binnenGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.set.mx, wallHeightBinnen, this.settings.binnenMuurTexture.open ? 5 : this.wz)), 3))
        // binnenGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        // binnenGeometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        // binnenGeometry.setIndex(boxBuffer.indexBuffer());
        // binnenGeometry.groups = boxBuffer.groupBuffer()
        // binnenMuur.geometry = binnenGeometry
        // binnenMuur.position.y = settings.binnenMuurTexture.open && !this.isSlidingWall ? wallHeightBinnen : wallHeightBinnen / 2

        this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.binnenMuurTexture.url}`, (newTexture) => {
          const texture = binnenMuur.material
          const applyNewTexture = (material) => {
            if (material.map) {
              // material.map.dispose()
            }
            material.map = newTexture
            if(this.settings.binnenMuurTexture.open) {
              this.textureSettings(material.map, 1, 1)
            } else {
              this.textureSettings(material.map, this.set.mx * 0.000351, this.set.my * 0.0004331)
            }
            material.transparent = settings.binnenMuurTexture.open
            material.opacity = settings.binnenMuurTexture.open ? 0.75 : 1
            material.map.needsUpdate = true;
            material.needsUpdate = true;
          }
          if (Array.isArray(texture)) {
            for (const material of texture) {
              applyNewTexture(material)
            }
          } else {
            applyNewTexture(texture)
          }

          // this.group.children[3].material = texture

          this.binnenMuurTexture = settings.binnenMuurTexture
        })
      } else {

        if(Array.isArray(binnenMuur.material)) {
          for(const material of binnenMuur.material) {
            material.transparent = settings.binnenMuurTexture.open
            material.opacity = settings.binnenMuurTexture.open ? 0.75 : 1
            material.needsUpdate = true;
          }
        } else {
          binnenMuur.material.transparent = settings.binnenMuurTexture.open
          binnenMuur.material.opacity = settings.binnenMuurTexture.open ? 0.75 : 1
          binnenMuur.material.needsUpdate = true;
        }

      }

      if (this.settings.buitenMuurTexture !== settings.buitenMuurTexture || this.settings.buitenMuur !== settings.buitenMuur || !this.undefined) {

        // create new mesh geometry (werkt niet ovor kapschuur)
        // const buitenGeometry = new this.THREE.BufferGeometry()
        // const wallWidth = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !settings.buitenMuurTexture.open) ? this.set.mx + 2*this.staanderGrootte.dx : this.set.mx
        // var preHeight = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.my+16 : this.set.my
        // const wallHeightBuiten = settings.buitenMuurTexture.open && !this.isSlidingWall ? preHeight / 4 : preHeight
        // buitenGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(wallWidth, wallHeightBuiten, this.settings.buitenMuurTexture.open ? 5 : this.wz)), 3))
        // buitenGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
        // buitenGeometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
        // buitenGeometry.setIndex(boxBuffer.indexBuffer());
        // buitenGeometry.groups = boxBuffer.groupBuffer()
        // buitenMuur.geometry = buitenGeometry
        // buitenMuur.position.y = settings.buitenMuurTexture.open && !this.isSlidingWall ? wallHeightBuiten : wallHeightBuiten / 2

        this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.buitenMuurTexture.url}`, (newTexture) => {
          const texture = buitenMuur.material
          const applyNewTexture = (material) => {
            const wallWidth = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.mx + 2*this.staanderGrootte.dx : this.set.mx
            const wallHeight = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.my+16 : this.set.my
            if (material.map) {
              material.map.dispose()
            }
            material.map = newTexture
            if(this.settings.buitenMuurTexture.open) {
              this.textureSettings(material.map, 1, 1)
            } else {
              this.textureSettings(material.map, wallWidth * 0.000351, wallHeight * 0.0004331)
            }
            material.transparent = settings.buitenMuurTexture.open
            material.opacity = settings.buitenMuurTexture.open ? 0.75 : 1
            if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
              let distanceFromWallsOrigin
              if(this.alignsXAxis) {
                if(this.alignedRotation) {
                  distanceFromWallsOrigin = this.z - this.group.position.x
                } else {
                  distanceFromWallsOrigin = this.group.position.x
                }
              } else {
                if(this.alignedRotation) {
                  distanceFromWallsOrigin = this.group.position.z
                } else {
                  distanceFromWallsOrigin = this.x - this.group.position.z
                }
              }
              material.map.offset.x = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
              material.map.needsUpdate = true
              material.needsUpdate = true
              this.offsetX = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
              this.offsetX2 = material.map.offset
            }
            material.map.needsUpdate = true;
            material.needsUpdate = true;
          }
          if (Array.isArray(texture)) {
            for (const material of texture) {
              applyNewTexture(material)
            }
          } else {
            applyNewTexture(texture)
          }

          // this.group.children[2].material = texture

          this.buitenMuurTexture = settings.buitenMuurTexture
        })

      } else {

        if(Array.isArray(buitenMuur.material)) {
          for(const material of buitenMuur.material) {
            material.transparent = settings.buitenMuurTexture.open
            material.opacity = settings.buitenMuurTexture.open ? 0.75 : 1
            material.needsUpdate = true;
          }
        } else {
          buitenMuur.material.transparent = settings.buitenMuurTexture.open
          buitenMuur.material.opacity = settings.buitenMuurTexture.open ? 0.75 : 1
          buitenMuur.material.needsUpdate = true;
        }

      }

      for (let i = 0; i < frame.children.length; i++) {
        if(!frame.children[i].children[0]) {
          return
        }
        // console.log("frame", frame.children[i])
        frame.children[i].children[0].material[0].transparent = false
        frame.children[i].children[0].material[0].opacity = 1
        frame.children[i].children[0].material[0].needsUpdate = true

        frame.children[i].children[0].material[2].transparent = false
        frame.children[i].children[0].material[2].opacity = 1
        frame.children[i].children[0].material[2].needsUpdate = true

        frame.children[i].children[2].material[0].transparent = false
        frame.children[i].children[2].material[0].opacity = 1
        frame.children[i].children[2].material[0].needsUpdate = true

        frame.children[i].children[2].material[2].transparent = false
        frame.children[i].children[2].material[2].opacity = 1
        frame.children[i].children[2].material[2].needsUpdate = true
      }

      binnenMuur.visible = settings.binnenMuur || binnenMuur.visible
      buitenMuur.visible = settings.buitenMuur || buitenMuur.visible
      frame.visible = frame.visible || settings.binnenMuur || settings.buitenMuur
      this.settings.binnenMuur = settings.binnenMuur || binnenMuur.visible
      this.settings.buitenMuur = settings.buitenMuur || buitenMuur.visible
      frame.children[0].visible =
        (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'vertical') : false) ||
        (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'vertical') : false)
      frame.children[1].visible =
        (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'horizontal') : false) ||
        (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'horizontal') : false)
      // console.log("frame visible addwall", this.settings.binnenMuur, this.settings.buitenMuur, this.settings.binnenMuurTexture.open, this.settings.buitenMuurTexture.open)
      // console.log("frame visible addwall result", frame.visible, frame.children[0].visible, frame.children[1].visible)

      if(this.lockIcon) {
        //this.lockIcon.visible = true
      }
    } else {
      // console.log("addwall muur.js else", settings)
      const buitenMuur = this.group.children[2]
      const binnenMuur = this.group.children[3]
      const frame = this.group.children[4]
      if (settings.binnenMuur) {
        this.settings.binnenMuur = false
        binnenMuur.visible = false
      } 
      if (settings.buitenMuur) {
        this.settings.buitenMuur = false
        buitenMuur.visible = false
      }
      frame.visible = this.settings.binnenMuur || this.settings.buitenMuur //|| settings.binnenMuur || settings.buitenMuur
      this.wallOn = this.settings.binnenMuur || this.settings.buitenMuur || binnenMuur.visible || buitenMuur.visible

      frame.children[0].visible =
        (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'vertical') : false) ||
        (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'vertical') : false)
      frame.children[1].visible =
        (this.settings.binnenMuur ? (this.settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'horizontal') : false) ||
        (this.settings.buitenMuur ? (this.settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'horizontal') : false)

      //hoverlijnen updaten
      const transparentMaterial = new this.THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: this.THREE.DoubleSide,
        transparent: true,
        opacity: 0.0,
        depthWrite: false,
      });
      // console.log(this.hoverLine0.group.children[0])
      if (settings.binnenMuur) {
        //this.hoverLine0.group.children[0].material[1] = transparentMaterial
        this.hoverLine0.setHover({hover: {inside: false}})
        this.mapHoverElements.binnenmuurMap.material[1] = transparentMaterial
      } else if (settings.buitenMuur) {
        //this.hoverLine0.group.children[1].material[1] = transparentMaterial
        this.hoverLine0.setHover({hover: {outside: false}})
        this.mapHoverElements.buitenmuurMap.material[1] = transparentMaterial
      }
    }
    /*if(this.shouldShowPlusIcons) {
      this.plusIconGroup.children[0].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled
      this.plusIconGroup.children[1].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && (this.set.tussenMuur==true ? true : false)
      this.plusIconGroup.children[2].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled
      this.plusIconGroup.children[3].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled && (this.set.tussenMuur==true ? true : false)
      this.plusIconGroup.children[4].visible = this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled
      this.plusIconGroup.children[5].visible = this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled && (this.set.tussenMuur==true ? true : false)
    } else {
      this.plusIconGroupDisabled.children[0].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled
      this.plusIconGroupDisabled.children[1].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && (this.set.tussenMuur==true ? true : false)
      this.plusIconGroupDisabled.children[2].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled
      this.plusIconGroupDisabled.children[3].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled && (this.set.tussenMuur==true ? true : false)
      this.plusIconGroupDisabled.children[4].visible = this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled
      this.plusIconGroupDisabled.children[5].visible = this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled && (this.set.tussenMuur==true ? true : false)
    }*/
    /*console.log(this.set.tussenMuur)
    this.plusIconGroup.children[0].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled
    this.plusIconGroup.children[1].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && (this.set.tussenMuur==true ? true : false)
    this.plusIconGroup.children[2].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled
    this.plusIconGroup.children[3].visible = this.wallOn && !this.wallButtonIndex[0].enabled && !this.wallButtonIndex[1].enabled && !this.wallButtonIndex[2].enabled && (this.set.tussenMuur==true ? true : false)
    this.plusIconGroup.children[4].visible = this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled
    this.plusIconGroup.children[5].visible = this.wallOn && !this.wallButtonIndex[2].enabled && !this.wallButtonIndex[1].enabled && (this.set.tussenMuur==true ? true : false)*/
    this.changeLockMode(this.settings.binnenMuur || this.settings.buitenMuur)
    if(!this.wallOn) {
      this.deselect()
      this.removeWall()
    }
    // isolatie knoppen - add to hoverLineGroup
    // console.log('this.hoverIsoMainGroup',this.hoverIsoMainGroup)
    // this.group.children[1].add(this.hoverIsoMainGroup);
    // this.mapGroup.add(this.mapIsoMainGroup);

    // if(this.settings.tussenMuur) {
    //   if(this.wallOn) {
    //     if(this.wallStaander == null && this.childWall != null) {
    //       this.showWallStaander(this.staanderObject.frameTexture)
    //       this.wallStaander.position.x = this.set.mx + this.staanderObject.dx/2
    //       this.mapWallStaander.position.x = this.set.mx + this.staanderObject.dx/2
    //     }
    //     if(this.parentWall) {
    //       this.parentWall.showWallStaander(this.staanderObject.frameTexture)
    //       this.parentWall.wallStaander.position.x = this.parentWall.set.mx + this.parentWall.staanderObject.dx/2
    //       this.parentWall.mapWallStaander.position.x = this.parentWall.set.mx + this.parentWall.staanderObject.dx/2
    //     }
    //   } else {
    //     if(this.childWall && !this.childWall.wallOn && this.wallStaander) {
    //       this.wallStaander.removeFromParent()
    //       this.mapWallStaander.removeFromParent()
    //       this.wallStaander = null
    //       this.wallStaanderInfo = null
    //       this.mapWallStaander = null
    //     }
    //     if(this.parentWall && !this.parentWall.wallOn && this.parentWall.wallStaander) {
    //       this.parentWall.wallStaander.removeFromParent()
    //       this.parentWall.mapWallStaander.removeFromParent()
    //       this.parentWall.wallStaander = null
    //       this.wallStaanderInfo = null
    //       this.parentWall.mapWallStaander = null
    //     }
    //   }
    // }
    // this.selfUpdate()

    // isolatie knoppen - add to hoverLineGroup
    // console.log('this.hoverIsoMainGroup',this.hoverIsoMainGroup)
    // this.group.children[1].add(this.hoverIsoMainGroup);
    // this.mapGroup.add(this.mapIsoMainGroup);

    this.updateMuurScalingForHitbox()
    this.updateNiceOffset()
  }

  async removeWall(shouldNotCalculate) {
    //console.log(this)
    //delete this;
    /*this.updateWall ({ binnenMuur: false,
                      binnenMuurTexture: "deens-rabat-onbehandeld.jpg",
                      buitenMuur: false,
                      buitenMuurTexture: "sponning-onbehandeld.jpg",
                      connectionBinnen: "horizontal",
                      connectionBuiten: "horizontal"}) */
    //alert("remove")

    this.gaten = []
    this.wallOn = false
    this.isSlidingWall = false
    const settings = this.settings
    settings.binnenMuur = false
    // settings.binnenMuurTexture = {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'}
    settings.buitenMuur = false
    // settings.buitenMuurTexture = {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'}
    settings.connectionBinnen = "horizontal"
    settings.connectionBuiten = "horizontal"

    this.changeLockMode(false)

    store.dispatch('updateIntersectingObjects')
    // this.update()
    // this.updateMuurAfkapping()
    if(!shouldNotCalculate && !this.isBorstwering) {
      store.dispatch('calcRemoveWall', this)
    }
    // prevent removeWall from executing further, without causing linter errors
    // if(!this.dat)return

    if(this.lockIcon){this.lockIcon.visible=false}
    this.betonBand.betonBand.visible = this.fundering && (this.settings.buitenMuur ? !this.settings.buitenMuurTexture.open : false || this.settings.binnenMuur ? !this.settings.binnenMuurTexture.open : false)


    // console.log(settings)
    const buitenMuur = this.group.children[2]
    const binnenMuur = this.group.children[3]
    const frame = this.group.children[4]
    const s0 = this.group.children[0].children[0]
    const s1 = this.group.children[0].children[1]



    binnenMuur.visible = settings.binnenMuur
    buitenMuur.visible = settings.buitenMuur


    frame.visible = settings.binnenMuur || settings.buitenMuur
    // console.log(frame)

    this.wallOn = settings.binnenMuur || settings.buitenMuur
    if (!settings.binnenMuur && !settings.buitenMuur && this.set.mx >= this.schoor.wallMinWidth) {
      s0.visible = !this.set.tussenMuur && (settings.buitenMuur ? (!!settings.buitenMuurTexture.open) : true) && (settings.binnenMuur ? (!!settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
      s1.visible = !this.set.tussenMuur && (settings.buitenMuur ? (!!settings.buitenMuurTexture.open) : true) && (settings.binnenMuur ? (!!settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)
      this.schoorGroup.visible = this.set.mx >= this.schoor.wallMinWidth && !this.isSlidingWall && !this.isBorstwering
      if (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) {
        if(!this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
          this.schoorGroup.position.z = this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2
        } else {
          this.schoorGroup.position.z = - (this.staanderGrootte.dx - this.schoor.sx)/2
        }
      } else {
        this.schoorGroup.position.z = 0
      }

      this.hoverLine0.group.visible = true
      this.selectFrame.group.visible = false

      binnenMuur.material.opacity = 0.75
      binnenMuur.material.transparent = true
      binnenMuur.material.needsUpdate = true

      buitenMuur.material.opacity = 0.75
      buitenMuur.material.transparent = true
      buitenMuur.material.needsUpdate = true

      binnenMuur.geometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.set.mx, this.set.my, this.wz)), 3))
      binnenMuur.geometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      binnenMuur.geometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      binnenMuur.geometry.setIndex(boxBuffer.indexBuffer());
      binnenMuur.geometry.groups = boxBuffer.groupBuffer()

      const wallWidth = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.mx + 2*this.staanderGrootte.dx : this.set.mx
      const wallHeight = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.my+16 : this.set.my
      buitenMuur.geometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(wallWidth, wallHeight, this.wz)), 3))
      buitenMuur.geometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      buitenMuur.geometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      buitenMuur.geometry.setIndex(boxBuffer.indexBuffer());
      buitenMuur.geometry.groups = boxBuffer.groupBuffer()
    }

    this.settings.binnenMuur = settings.binnenMuur
    this.settings.binnenMuurTexture = settings.binnenMuurTexture
    this.settings.buitenMuur = settings.buitenMuur
    this.settings.buitenMuurTexture = settings.buitenMuurTexture
    this.settings.connectionBinnen = settings.connectionBinnen
    this.settings.connectionBuiten = settings.connectionBuiten
    this.modelGroup.children = []
    this.group.children[8].children.forEach((model) => {
      model.removeFromParent()
    })
    this.wallButtonIndex.forEach((el) => {
      if(el.mapModel !== undefined && el.mapModel !== null) {
        el.mapModel.removeFromParent()
      }
    })

    this.wallButtonIndex = [
      {canFitAtLocation: false, enabled: false, hole: null, side: null, model: null, mapModel: null, modelBox: null, selected: false, component: null, settings: null}, 
      {canFitAtLocation: false, enabled: false, hole: null, side: null, model: null, mapModel: null, modelBox: null, selected: false, component: null, settings: null}, 
      {canFitAtLocation: false, enabled: false, hole: null, side: null, model: null, mapModel: null, modelBox: null, selected: false, component: null, settings: null},
      {canFitAtLocation: false},
      {canFitAtLocation: false},
      {canFitAtLocation: false}]

    if(this.hoekProfiel.left) {
      this.hoekProfiel.left.removeFromParent()
      this.hoekProfiel.left.geometry.dispose()
      this.hoekProfiel.left.material.forEach((material) => {
        // material.map.dispose()
        material.dispose()
      })
      this.hoekProfiel.left = null
    }
    if(this.hoekProfiel.right) {
      this.hoekProfiel.right.removeFromParent()
      this.hoekProfiel.right.geometry.dispose()
      this.hoekProfiel.right.material.forEach((material) => {
        // material.map.dispose()
        material.dispose()
      })
      this.hoekProfiel.right = null
    }

    this.update()
  }

  updateMuurScalingForHitbox(settings = this.settings) {
    const buitenMuur = this.group.children[2]
    const binnenMuur = this.group.children[3]
    if (settings.buitenMuur) {
      buitenMuur.scale.set(1, 1, 1)
    } else if (settings.binnenMuurTexture.open) {
      buitenMuur.scale.set(0, 0, 0)
    }
    if (settings.binnenMuur) {
      binnenMuur.scale.set(1, 1, 1)
    } else if (settings.buitenMuurTexture.open) {
      binnenMuur.scale.set(0, 0, 0)
    }
  }

  updateWall(settings, onlyUpdatePreview = false) {
    // steellooks met een borstwering mogen geen open wandvak hebben
    if (this.isBorstwering && ((settings.binnenMuur && settings.binnenMuurTexture.open) || (settings.buitenMuur && settings.buitenMuurTexture.open))) return

    if (this.isSlidingWall) return
    if(onlyUpdatePreview) {
      let binnenMuurTexture = this.settings.binnenMuur ? this.settings.binnenMuurTexture : settings.binnenMuurTexture
      let binnenMuurConnection = this.settings.binnenMuur ? this.settings.connectionBinnen : settings.connectionBinnen

      let buitenMuurTexture = this.settings.buitenMuur ? this.settings.buitenMuurTexture : settings.buitenMuurTexture 
      let buitenMuurConnection = this.settings.buitenMuur ? this.settings.connectionBuiten : settings.connectionBuiten 

      settings.binnenMuurTexture = binnenMuurTexture
      settings.connectionBinnen = binnenMuurConnection
      settings.buitenMuurTexture = buitenMuurTexture
      settings.connectionBuiten = buitenMuurConnection
    }

    this.settings.binnenMuur = settings.binnenMuur
    this.settings.binnenMuurTexture = settings.binnenMuurTexture
    this.settings.buitenMuur = settings.buitenMuur
    this.settings.buitenMuurTexture = settings.buitenMuurTexture
    this.settings.connectionBinnen = settings.connectionBinnen
    this.settings.connectionBuiten = settings.connectionBuiten
    // console.log('einde')
    this.updateMuurScalingForHitbox()
    this.update(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, () => {

      const buitenMuur = this.group.children[2]
      const binnenMuur = this.group.children[3]
      const frame = this.group.children[4]
      const s0 = this.group.children[0].children[0]
      const s1 = this.group.children[0].children[1]

      const transparentMaterial = new this.THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: this.THREE.DoubleSide,
        transparent: true,
        opacity: 0.0,
        depthWrite: false,
      });

      if (this.settings.binnenMuurTexture !== settings.binnenMuurTexture || this.settings.binnenMuur !== settings.binnenMuur || !this.undefined) {
        this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.binnenMuurTexture.url}`, (newTexture) => {
          if(Array.isArray(binnenMuur.material)) {
            for(const texture of binnenMuur.material) {
              if(!texture.map) continue
              // texture.map.dispose()
              texture.map = newTexture
              if(settings.binnenMuurTexture.open) {
                this.textureSettings(texture.map, 1, 1)
              } else {
                this.textureSettings(texture.map, this.set.mx * 0.000351, this.set.my * 0.0004331)
              }
              texture.map.needsUpdate = true;
              texture.needsUpdate = true;
            }
          } else {
            const texture = binnenMuur.material
            if(texture.map) {
              // texture.map.dispose()
              texture.map = newTexture
              if(settings.binnenMuurTexture.open) {
                // console.log("updatewall open", `${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.binnenMuurTexture.url}`, settings.binnenMuurTexture)
                this.textureSettings(texture.map, 1, 1)
              } else {
                // console.log("updatewall dicht", `${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.binnenMuurTexture.url}`, settings.binnenMuurTexture)
                this.textureSettings(texture.map, this.set.mx * 0.000351, this.set.my * 0.0004331)
              }
              texture.transparent = settings.binnenMuurTexture.open
              texture.opacity = settings.binnenMuurTexture.open ? 0.75 : 1
              texture.map.needsUpdate = true;
              texture.needsUpdate = true;
            }
            this.group.children[3].material = texture
          }

          this.binnenMuurTexture = settings.binnenMuurTexture
        })
        var binnenMuurKleur = settings.binnenMuurTexture.hex
        //this.mapHoverElements.binnenmuurMap.material[1] = this.shaderMaterial(binnenMuurKleur)
        this.hoverLine0.setHover({hover: {inside: settings.binnenMuur}, colorInsideFill: binnenMuurKleur})
        var binnenmuurMapMaterial = (this.settings.binnenMuur && this.settings.binnenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(binnenMuurKleur)
        this.mapHoverElements.binnenmuurMap.material[1] = settings.binnenMuur ? binnenmuurMapMaterial : transparentMaterial
      }

      if (this.settings.buitenMuurTexture !== settings.buitenMuurTexture || this.settings.buitenMuur !== settings.buitenMuur || !this.undefined) {
        this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${settings.buitenMuurTexture.url}`, (newTexture) => {
          const wallWidth = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.mx + 2*this.staanderGrootte.dx : this.set.mx
          const wallHeight = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) ? this.set.my+16 : this.set.my
          if(Array.isArray(buitenMuur.material)) {
            for(const texture of buitenMuur.material) {
              if(!texture.map) continue
              // texture.map.dispose()
              texture.map = newTexture
              if(this.settings.buitenMuurTexture.open) {
                this.textureSettings(texture.map, 1, 1)
              } else {
                this.textureSettings(texture.map, wallWidth * 0.000351, wallHeight * 0.0004331)
              }
              texture.transparent = settings.buitenMuurTexture.open
              texture.opacity = settings.buitenMuurTexture.open ? 0.75 : 1
              texture.map.needsUpdate = true;
              texture.needsUpdate = true;
              if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
                let distanceFromWallsOrigin
                if(this.alignsXAxis) {
                  if(this.alignedRotation) {
                    distanceFromWallsOrigin = this.z - this.group.position.x
                  } else {
                    distanceFromWallsOrigin = this.group.position.x
                  }
                } else {
                  if(this.alignedRotation) {
                    distanceFromWallsOrigin = this.group.position.z
                  } else {
                    distanceFromWallsOrigin = this.x - this.group.position.z
                  }
                }
                if(this.group.position.z === 30 && !this.group.parentWall && !this.group.childWall) {
                  // console.log("antwoord", distanceFromWallsOrigin, this.staanderGrootte.dx, this.z, this.group.position.x)
                }
                texture.map.offset.x = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
                texture.map.needsUpdate = true
                texture.needsUpdate = true
                this.offsetX = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
                this.offsetX2 = texture.map.offset
              }
            }
          } else {
            const texture = buitenMuur.material
            if(texture.map) {
              // texture.map.dispose()
              texture.map = newTexture
              if(settings.buitenMuurTexture.open) {
                this.textureSettings(texture.map, 1, 1)
              } else {
                this.textureSettings(texture.map, wallWidth * 0.000351, wallHeight * 0.0004331)
              }
              texture.transparent = settings.buitenMuurTexture.open
              texture.opacity = settings.buitenMuurTexture.open ? 0.75 : 1
              texture.map.needsUpdate = true;
              texture.needsUpdate = true;
              if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
                let distanceFromWallsOrigin
                if(this.alignsXAxis) {
                  if(this.alignedRotation) {
                    distanceFromWallsOrigin = this.z - this.group.position.x
                  } else {
                    distanceFromWallsOrigin = this.group.position.x
                  }
                } else {
                  if(this.alignedRotation) {
                    distanceFromWallsOrigin = this.group.position.z
                  } else {
                    distanceFromWallsOrigin = this.x - this.group.position.z
                  }
                }
                if(this.group.position.z === 30 && !this.group.parentWall && !this.group.childWall) {
                  // console.log("antwoord", distanceFromWallsOrigin, this.staanderGrootte.dx, this.z, this.group.position.x)
                }
                texture.map.offset.x = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
                texture.map.needsUpdate = true
                texture.needsUpdate = true
                this.offsetX = (distanceFromWallsOrigin - this.staanderGrootte.dx) * 0.000351
                this.offsetX2 = texture.map.offset
              }
            }
            this.group.children[2].material = texture
          }
          this.buitenMuurTexture = settings.buitenMuurTexture
        })
        var buitenMuurKleur = settings.buitenMuurTexture.hex
        //this.mapHoverElements.buitenmuurMap.material[1] = this.shaderMaterial(buitenMuurKleur)
        this.hoverLine0.setHover({hover: {outside: settings.buitenMuur}, colorOutsideFill: buitenMuurKleur})
        var buitenmuurMapMaterial = (this.settings.buitenMuur && this.settings.buitenMuurTexture.open) ? this.transparentMaterial : this.shaderMaterial(buitenMuurKleur)
        this.mapHoverElements.buitenmuurMap.material[1] = settings.buitenMuur ? buitenmuurMapMaterial : transparentMaterial
      }    
      if (!this.undefined) return
      binnenMuur.visible = settings.binnenMuur
      buitenMuur.visible = settings.buitenMuur
      frame.visible = settings.binnenMuur || settings.buitenMuur

      /*for (let i = 0; i < frame.children[4].children.length; i++) {
        //console.log(frame.children[i].children)
        frame.children[4].children[i].visible =
          (settings.binnenMuur ? settings.connectionBinnen === 'vertical' : false) ||
          (settings.buitenMuur ? settings.connectionBuiten === 'vertical' : false)
        frame.children[5].children[i].visible =
          (settings.binnenMuur ? settings.connectionBinnen === 'horizontal' : false) ||
          (settings.buitenMuur ? settings.connectionBuiten === 'horizontal' : false)
      }*/
      // console.log("frame visible", settings.binnenMuurTexture.open, settings.buitenMuurTexture.open, settings.binnenMuur, settings.buitenMuur)
      frame.children[0].visible =
        (settings.binnenMuur ? (settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'vertical') : false) ||
        (settings.buitenMuur ? (settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'vertical') : false)
      frame.children[1].visible =
        (settings.binnenMuur ? (settings.binnenMuurTexture.open ? false : this.settings.connectionBinnen === 'horizontal') : false) ||
        (settings.buitenMuur ? (settings.buitenMuurTexture.open ? false : this.settings.connectionBuiten === 'horizontal') : false)
      // console.log("frame visible result", frame.visible, frame.children[0].visible, frame.children[1].visible)
      // console.log(settings)
      //frame.children[4].visible = false
      //frame.children[5].visible = true

      this.wallOn = settings.binnenMuur || settings.buitenMuur//(settings.binnenMuur && !settings.binnenMuurTexture.open) || (settings.buitenMuur && !settings.buitenMuurTexture.open) 
      if (!settings.binnenMuur && !settings.buitenMuur && this.set.mx >= this.schoor.wallMinWidth) {
        s0.visible = !this.set.tussenMuur && (settings.buitenMuur ? (!!settings.buitenMuurTexture.open) : true) && (settings.binnenMuur ? (!!settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
        s1.visible = !this.set.tussenMuur && (settings.buitenMuur ? (!!settings.buitenMuurTexture.open) : true) && (settings.binnenMuur ? (!!settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)
        this.schoorGroup.visible = this.set.mx >= this.schoor.wallMinWidth && !this.isSlidingWall && !this.isBorstwering
        // this.schoorGroup.position.z = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) ? this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2  : 0
        if (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) {
          if(!this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
            this.schoorGroup.position.z = this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2
          } else {
            this.schoorGroup.position.z = - (this.staanderGrootte.dx - this.schoor.sx)/2
          }
        } else {
          this.schoorGroup.position.z = 0
        }
        
        binnenMuur.material.opacity = 0.75
        binnenMuur.material.transparent = true //this.settings.binnenMuurTexture.open
        binnenMuur.material.needsUpdate = true

        buitenMuur.material.opacity = 0.75
        buitenMuur.material.transparent = true //this.settings.buitenMuurTexture.open
        buitenMuur.material.needsUpdate = true

        for (let i = 0; i < frame.children.length; i++) {
          if(!frame.children[i].children[0]) {
            return
          }
          // console.log("frame", frame.children[i])
          frame.children[i].children[0].material[0].transparent = true
          frame.children[i].children[0].material[0].opacity = 0.75
          frame.children[i].children[0].material[0].needsUpdate = true

          frame.children[i].children[0].material[2].transparent = true
          frame.children[i].children[0].material[2].opacity = 0.75
          frame.children[i].children[0].material[2].needsUpdate = true

          frame.children[i].children[2].material[0].transparent = true
          frame.children[i].children[2].material[0].opacity = 0.75
          frame.children[i].children[2].material[0].needsUpdate = true

          frame.children[i].children[2].material[2].transparent = true
          frame.children[i].children[2].material[2].opacity = 0.75
          frame.children[i].children[2].material[2].needsUpdate = true
        }
      } else {
        s0.visible = !this.set.tussenMuur && (settings.buitenMuur ? (!!settings.buitenMuurTexture.open) : true) && (settings.binnenMuur ? (!!settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
        s1.visible = !this.set.tussenMuur && (settings.buitenMuur ? (!!settings.buitenMuurTexture.open) : true) && (settings.binnenMuur ? (!!settings.binnenMuurTexture.open) : true) && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)
        this.schoorGroup.visible = this.set.mx >= this.schoor.wallMinWidth && !this.isSlidingWall && !this.isBorstwering
        // this.schoorGroup.position.z = (this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) ? this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2  : 0
        if (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) {
          if(!this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
            this.schoorGroup.position.z = this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2
          } else {
            this.schoorGroup.position.z = - (this.staanderGrootte.dx - this.schoor.sx)/2
          }
        } else {
          this.schoorGroup.position.z = 0
        }

        binnenMuur.material.transparent = settings.binnenMuurTexture.open
        binnenMuur.material.opacity = settings.binnenMuurTexture.open ? 0.75 : 1
        binnenMuur.material.needsUpdate = true

        buitenMuur.material.transparent = settings.buitenMuurTexture.open
        buitenMuur.material.opacity = settings.buitenMuurTexture.open ? 0.75 : 1
        buitenMuur.material.needsUpdate = true

        for (let i = 0; i < frame.children.length; i++) {
          if(!frame.children[i].children[0]) {
            return
          }
          frame.children[i].children[0].material[0].transparent = false
          frame.children[i].children[0].material[0].opacity = 1
          frame.children[i].children[0].material[0].needsUpdate = true

          frame.children[i].children[0].material[2].transparent = false
          frame.children[i].children[0].material[2].opacity = 1
          frame.children[i].children[0].material[2].needsUpdate = true

          frame.children[i].children[2].material[0].transparent = false
          frame.children[i].children[2].material[0].opacity = 1
          frame.children[i].children[2].material[0].needsUpdate = true

          frame.children[i].children[2].material[2].transparent = false
          frame.children[i].children[2].material[2].opacity = 1
          frame.children[i].children[2].material[2].needsUpdate = true
        }
      }

      // this.betonBand.betonBand.visible = this.fundering && (this.settings.buitenMuur ? !settings.buitenMuurTexture.open : false || this.settings.binnenMuur ? !settings.binnenMuurTexture.open : false)

      // if (this.wallOn && this.fundering) {
      //   this.betonBand.betonBandShow()
      // } else {
      //   this.betonBand.betonBandHide()
      // }

    })

    // console.log(settings)
    //this.settings = settings
    this.settings.binnenMuur = settings.binnenMuur
    this.settings.binnenMuurTexture = settings.binnenMuurTexture
    this.settings.buitenMuur = settings.buitenMuur
    this.settings.buitenMuurTexture = settings.buitenMuurTexture
    this.settings.connectionBinnen = settings.connectionBinnen
    this.settings.connectionBuiten = settings.connectionBuiten
    // console.log('einde')
    this.updateMuurScalingForHitbox()
    this.updateNiceOffset()
    // this.update(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, () => {
    //   alert("klaar met updaten")
    // })
  }

  select(selectBostweringAsset) {
    this.selected = true
    this.selectedBorstweringAsset = selectBostweringAsset
    if (selectBostweringAsset) {
      this.selectFrameBorstweringAsset.group.visible = true
    } else {
      this.selectFrame.group.visible = true
    }
    if (!this.isBorstwering) {
      this.arrowGroup.visible = true
      this.group.add(this.arrowGroup)
    }
  }

  deselect() {
    this.selected = false
    this.selectBostweringAsset = false
    this.selectFrame.group.visible = false
    if (this.selectFrameBorstweringAsset) this.selectFrameBorstweringAsset.group.visible = false
    this.arrowGroup.visible = false
    this.arrowGroup.removeFromParent()
  }

  updateHoles(holes, model, map, locationIndex, side, component, settings) {
    this.gaten = holes
    this.wallButtonIndex[locationIndex].hole = holes[0]
    //button visibility + update array
    switch(locationIndex) {
      case 0:
        this.plusIconGroupDisabled[0].removeFromParent()
        this.plusIconGroupDisabled[1].removeFromParent()
        this.plusIconGroupDisabled[2].removeFromParent()
        this.plusIconGroupDisabled[3].removeFromParent()
        break
      case 1:
        this.plusIconGroupDisabled[0].removeFromParent()
        this.plusIconGroupDisabled[1].removeFromParent()
        this.plusIconGroupDisabled[2].removeFromParent()
        this.plusIconGroupDisabled[3].removeFromParent()
        this.plusIconGroupDisabled[4].removeFromParent()
        this.plusIconGroupDisabled[5].removeFromParent()
        break
      case 2:
        this.plusIconGroupDisabled[2].removeFromParent()
        this.plusIconGroupDisabled[3].removeFromParent()
        this.plusIconGroupDisabled[4].removeFromParent()
        this.plusIconGroupDisabled[5].removeFromParent()
        break
    }
    this.wallButtonIndex[locationIndex].enabled = true
    this.wallButtonIndex[locationIndex].side = side
    this.wallButtonIndex[locationIndex].model = model
    this.wallButtonIndex[locationIndex].mapModel = map
    this.wallButtonIndex[locationIndex].component = component
    this.wallButtonIndex[locationIndex].settings = settings

    this.settings.modelArray[locationIndex] = {
      lastCode: settings.lastCode, // TASK-1568
      code: component.code,
      color: settings.color,
      option: settings.option,
      side: side
    }

    this.update()
  }

  /** Comments uit updateHoleFunction hier gepaste om mijzelf een beter overzicht te geven van de updateHole functie
   * 
   //console.log(frameArray.length)
    // console.log(this.group.children[4].children.length)
    /*const frameArrayNew = new this.THREE.Group()
    frameArrayNew.name="FrameArray"*/
    //this.group.children[4].children = []
    // const frameArray = this.group.children[4]
    /*for(var i=0; i<this.group.children[4].children.length; i++) {
      console.log("LOOPJE")
      this.group.children[4].remove(this.group.children[4].children[i])
    }*/
    /*for (let i = 0; i < frameArray.children.length; i++) {

      frameArray.children[i].removeFromParent()

    }*/

    /*console.log('frameArray', frameArray)
    console.log('frames', frames)

    for (let i = 0; i < frames.length; i++) {

      let frameSet = frames[i]

      const geometryfh = new this.THREE.BufferGeometry()
      geometryfh.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(frameSet.fx, this.fx, this.fz)), 3))
      geometryfh.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      geometryfh.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      geometryfh.setIndex(boxBuffer.indexBuffer())
      geometryfh.groups = boxBuffer.groupBuffer()

      const fhTextureX = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.staanderObject.frameTexture}`)
      this.textureSettings(fhTextureX, frameSet.fx * 0.0005, this.fz * 0.0125)
      // this.textureSettings(fhTextureX, 1, 0.5)

      const fhMaterialX = new this.THREE.MeshStandardMaterial({
        map: fhTextureX,
        //side: this.THREE.BackSide,
      });

      const fhTextureY = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.staanderObject.frameTexture}`)
      this.textureSettings(fhTextureY, frameSet.fx * 0.0005, this.fx * 0.0125)

      const fhMaterialY = new this.THREE.MeshStandardMaterial({
        map: fhTextureY,
        //side: this.THREE.BackSide,
      });

      const frameH0 = new this.THREE.Mesh(geometryfh, [
        fhMaterialY,
        fhMaterialY,
        fhMaterialX,
        fhMaterialX,
        fhMaterialY,
        fhMaterialY
      ]);

      frameH0.position.y = this.fx / 2

      const frameH1 = frameH0.clone()
      frameH1.position.y = set.my - this.fx / 2

      const geometryfv = new this.THREE.BufferGeometry()
      geometryfv.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(this.fx, set.my - 2 * this.fx, this.fz)), 3))
      geometryfv.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      geometryfv.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      geometryfv.setIndex(boxBuffer.indexBuffer())
      geometryfv.groups = boxBuffer.groupBuffer()

      const fvTextureX = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.staanderObject.frameTexture}`)
      this.textureSettings(fvTextureX, set.my * 0.0005, this.fx * 0.0125, 0.5 * Math.PI)
      // this.textureSettings(fhTextureX, 1, 0.5)

      const fvMaterialX = new this.THREE.MeshStandardMaterial({
        map: fvTextureX,
        //side: this.THREE.BackSide,
      });

      const fvTextureY = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.staanderObject.frameTexture}`)
      this.textureSettings(fvTextureY, set.my * 0.0005, this.fz * 0.0125, 0.5 * Math.PI)

      const fvMaterialY = new this.THREE.MeshStandardMaterial({
        map: fvTextureY,
        //side: this.THREE.BackSide,
      });

      const frameV0 = new this.THREE.Mesh(geometryfv, [
        fvMaterialY,
        fvMaterialY,
        fvMaterialX,
        fvMaterialX,
        fvMaterialY,
        fvMaterialY
      ]);

      frameV0.position.y = set.my / 2
      frameV0.position.x = frameSet.fx / 2 - this.fx / 2 

      const frameV1 = frameV0.clone()
      frameV1.position.x = -frameSet.fx / 2 + this.fx / 2 

      // berekende spanten verticaal
      const svGroup = new this.THREE.Group()

      const nsv = Math.ceil(frameSet.fx / this.fvs)
      const svSpacing = (frameSet.fx - ((2 + nsv) * this.fx)) / (nsv + 1)

      for (let i = 0; i < nsv; i++) {

        const s = frameV0.clone()

        s.position.x = (-frameSet.fx / 2 + this.fx / 2 + this.fx) + svSpacing * (i + 1) + this.fx * i 

        svGroup.add(s)

      }

      this.spantV = frameV0

      // berekende spanten horizontaal
      const shGroup = new this.THREE.Group()

      const geometrysh = new this.THREE.BufferGeometry()
      geometrysh.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(frameSet.fx - 2 * this.fx, this.fx, this.fz)), 3))
      geometrysh.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      geometrysh.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      geometrysh.setIndex(boxBuffer.indexBuffer())
      geometrysh.groups = boxBuffer.groupBuffer()

      const sh = new this.THREE.Mesh(geometrysh, [
        fhMaterialY,
        fhMaterialY,
        fhMaterialX,
        fhMaterialX,
        fhMaterialY,
        fhMaterialY
      ]);

      const nsh = 3 //Math.ceil(set.my / this.fhs)
      const hvSpacing = (set.my - ((2 + nsh) * this.fx)) / (nsh + 1)

      for (let i = 0; i < nsh; i++) {

        const s = sh.clone()

        s.position.y = (this.fx + this.fx / 2) + hvSpacing * (i + 1) + this.fx * i

        shGroup.add(s)
      }

      this.spantH = sh

      svGroup.visible = (this.settings.binnenMuur ? this.settings.connectionBinnen === 'vertical' : false) ||
        (this.settings.buitenMuur ? this.settings.connectionBuiten === 'vertical' : false)
      shGroup.visible = (this.settings.binnenMuur ? this.settings.connectionBinnen === 'horizontal' : false) ||
        (this.settings.buitenMuur ? this.settings.connectionBuiten === 'horizontal' : false)

      // frame groeperen
      const frameGroup = new this.THREE.Group()
      frameGroup.name="FrameGroup"
      frameGroup.add(frameH0)
      frameGroup.add(frameH1)
      frameGroup.add(frameV0)
      frameGroup.add(frameV1)
      frameGroup.add(svGroup)
      frameGroup.add(shGroup)

      frameGroup.position.x = frameSet.fo + frameSet.fx / 2
    
      // frame array 
      frameArray.add(frameGroup)
      frameArray.visible = this.settings.binnenMuur || this.settings.buitenMuur
    }*/

    // console.log('frameArray', frameArray)
    
  selectElement(obj) {
    this.wallButtonIndex[obj.metaData.ModelPosition].selected = true
    this.wallButtonIndex[obj.metaData.ModelPosition].modelBox.visible = true
    // console.log(this.wallButtonIndex[obj.metaData.ModelPosition].component)
    // console.log(this.wallButtonIndex[obj.metaData.ModelPosition].settings)
    return {component: this.wallButtonIndex[obj.metaData.ModelPosition].component, settings: this.wallButtonIndex[obj.metaData.ModelPosition].settings}
  }

  deselectElement(obj) {
    this.wallButtonIndex[obj.metaData.ModelPosition].selected = false
    this.wallButtonIndex[obj.metaData.ModelPosition].modelBox.visible = false
  }

  removeElement(obj) {
    // console.log(obj)

    this.settings.modelArray[obj.metaData.ModelPosition] = null

    this.wallButtonIndex[obj.metaData.ModelPosition].model.removeFromParent()
    this.wallButtonIndex[obj.metaData.ModelPosition].mapModel.removeFromParent()
    this.wallButtonIndex[obj.metaData.ModelPosition].modelBox.removeFromParent()
    this.wallButtonIndex[obj.metaData.ModelPosition].enabled = false

    this.wallButtonIndex[obj.metaData.ModelPosition].hole = null
    this.wallButtonIndex[obj.metaData.ModelPosition].side = null
    this.wallButtonIndex[obj.metaData.ModelPosition].model = null
    this.wallButtonIndex[obj.metaData.ModelPosition].mapModel = null
    this.wallButtonIndex[obj.metaData.ModelPosition].modelBox = null
    this.wallButtonIndex[obj.metaData.ModelPosition].selected = false
    this.wallButtonIndex[obj.metaData.ModelPosition].component = null
    this.wallButtonIndex[obj.metaData.ModelPosition].settings = null

    this.setElementPlacing(true)
    
    this.update()
  }

  // updateTexture (textureFrame) {
  // }

  // showWallStaander functie maakt de staanders aan de voorzijde 

  showWallStaander(texture) {
    if(this.wallStaander) {return}
    // geometry
    const geometry1 = new this.THREE.BufferGeometry()
    geometry1.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(this.staanderObject.dx, this.staanderObject.y, this.staanderObject.dz)), 3))
    geometry1.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry1.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry1.setIndex(boxBuffer.indexBuffer());
    geometry1.groups = boxBuffer.groupBuffer()

    // console.log(this.staanderObject.frameTexture)
    // texture

    this.wallStaander = new this.THREE.Group(); // this.wallStaander is a THREE Group now
    this.wallStaanderInfo = {x:this.staanderObject.dx, y:this.staanderObject.y, z:this.staanderObject.dz}

    this.mapWallStaander = new this.THREE.Group();

    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (map) => {
      const textureX = map.clone()
      this.textureSettings(textureX,  this.staanderObject.y / 2320,  this.staanderObject.dz / 145,  0.5 * Math.PI)
      const materialX = new this.THREE.MeshStandardMaterial({ map: textureX, metalness: 0, roughness: 1})
      materialX.needsUpdate = true
      materialX.map.needsUpdate = true

      const textureY = map.clone()
      this.textureSettings(textureY, this.staanderObject.dx / 2320,  this.staanderObject.dz / 145, 0.5 * Math.PI)
      const materialY = new this.THREE.MeshStandardMaterial({ map: textureY, metalness: 0, roughness: 1})
      materialY.needsUpdate = true
      materialY.map.needsUpdate = true

      const textureZ = map.clone()
      this.textureSettings(textureZ,  this.staanderObject.y / 2320,  this.staanderObject.dz / 145,  1.5 * Math.PI)
      const materialZ = new this.THREE.MeshStandardMaterial({ map: textureZ, metalness: 0, roughness: 1})
      materialZ.needsUpdate = true
      materialZ.map.needsUpdate = true

      // mesh
      const staander0 = new Brush( geometry1, [
        materialX,
        materialX,
        materialY,
        materialY,
        materialZ,
        materialZ,
      ]);
      staander0.castShadow = true; 
      staander0.receiveShadow = true;

      staander0.position.y = this.staanderObject.y/2

      // Making Betonpoer class available
      this.betonPoer = new Betonpoer(this.THREE, this.poer, this.fundering);
      
      if(this.wallStaander) {
        this.wallStaander.add(staander0, this.betonPoer.betonPoer) // adding staander0 and betonPoer to the wallStaander group 
        if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur) {
          this.wallStaander.position.z = this.fz/2 - this.staanderGrootte.dx/2
        }
        this.group.add(this.wallStaander) // adding the wallStaander to the muur group
      }

      // positioning of the betonPoeren 
      this.betonPoer.betonPoer.rotation.x = Math.PI / 2; // 180graden draaien van de betonpoeren op de x as
      this.betonPoer.betonPoer.position.y = -this.poer.y/2; // betonpoeren met de helft van de eigen hoogte naar beneden   
      


      // console.log(texture)
      const geometry2 = new this.THREE.BufferGeometry()
      // console.log(this.staanderObject)
      geometry2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(planeBorderBuffer.positionBuffer(this.staanderObject.dx, this.staanderObject.dz, 7)), 3))
      geometry2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(planeBorderBuffer.uvBuffer()), 2))
      geometry2.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(planeBorderBuffer.normalBuffer()), 3))
      geometry2.groups = planeBorderBuffer.groupBuffer()
      geometry2.translate( -this.staanderObject.dx / 2, 0, -this.staanderObject.dz / 2 );

      const mapTexture = map.clone()
      this.textureSettings(mapTexture,  this.staanderObject.dx/2450,  this.staanderObject.dz/145,  this.set.rotation+Math.PI*0.5)
      const mapMaterial = new this.THREE.MeshStandardMaterial({ map: mapTexture })
      mapMaterial.needsUpdate = true
      mapMaterial.map.needsUpdate = true
      const mapMaterialOutline = this.shaderMaterial('#000000')

      const mapStaander0 = new this.THREE.Mesh( geometry2, [mapMaterialOutline, mapMaterial] );
      mapStaander0.name = "mapStaander"

      mapStaander0.renderOrder = -10
      if(this.mapWallStaander) {
        this.mapWallStaander.add(mapStaander0)
        this.mapGroup.add(this.mapWallStaander)
      }

    })
    
  }

  changeLockMode(mode) {
    if (this.isBorstwering) return // walls with borstwering should always be locked
    if(mode != undefined) {
      this.isLocked = mode
    } else {
      this.isLocked = !this.isLocked
    }
    let lockSVG

    if(this.isLocked) {
      lockSVG = "lock"
    } else {
      lockSVG = "lock-open"
    }

    const svgLoader = new SVGLoader();
    const hoofdthis = this
    svgLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/${lockSVG}.svg`, function(data) {
      const paths = data.paths;
      const group = new hoofdthis.THREE.Group();

      for ( let i = 0; i < paths.length; i ++ ) {

        const path = paths[ i ];

        const material = new hoofdthis.THREE.MeshBasicMaterial( {
          color: hoofdthis.isLocked ? 0xff0000 : 0xD4F028,
          //reflectivity: 0,
          //emissive: hoofdthis.isLocked ? 0xff0000 : 0xD4F028,
          //diffuse: hoofdthis.isLocked ? 0xff0000 : 0xD4F028,
          //ambient: hoofdthis.isLocked ? 0xff0000 : 0xD4F028,
          //shininess: 0,
          side: hoofdthis.THREE.DoubleSide,
        } );

        const shapes = SVGLoader.createShapes( path );

        for ( let j = 0; j < shapes.length; j ++ ) {

          const shape = shapes[ j ];
          const geometry = new hoofdthis.THREE.ShapeGeometry( shape );
          const mesh = new hoofdthis.THREE.Mesh( geometry, material );
          group.add( mesh );

        }

      }
      group.scale.multiplyScalar(25)
      group.rotation.x = Math.PI
      var box1 = new hoofdthis.THREE.Box3().setFromObject(group);
      var height = box1.max.y - box1.min.y
      var width = box1.max.x - box1.min.x
      group.position.set((hoofdthis.set.mx-width-200)/2,height+300,hoofdthis.set.mz/2)
      hoofdthis.lockIcon.removeFromParent()
      hoofdthis.lockIcon = group
      hoofdthis.lockIcon.visible = false
      hoofdthis.group.add(hoofdthis.lockIcon)
    })
    
  }

  updateWallStaanderTexture(texture) {
    this.staanderObject.frameTexture = texture
    // console.log(texture)
    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (map) => {
      const textureX = map.clone()
      this.textureSettings(textureX,  this.staanderObject.y / 2450,  this.staanderObject.dx * 0.0075,  0.5 * Math.PI)

      const textureZ = map.clone()
      this.textureSettings(textureZ,  this.staanderObject.y / 2450,  this.staanderObject.dz * 0.0075,  0.5 * Math.PI)

      // console.log(this.wallStaander)
      if(this.wallStaander) {
        
        this.wallStaander.children[0].material[0].map = textureZ
        this.wallStaander.children[0].material[0].map.needsUpdate = true
        this.wallStaander.children[0].material[0].needsUpdate = true
        this.wallStaander.children[0].material[1].map = textureZ
        this.wallStaander.children[0].material[1].map.needsUpdate = true
        this.wallStaander.children[0].material[1].needsUpdate = true
        this.wallStaander.children[0].material[2].map = textureX
        this.wallStaander.children[0].material[2].map.needsUpdate = true
        this.wallStaander.children[0].material[2].needsUpdate = true
        this.wallStaander.children[0].material[3].map = textureX
        this.wallStaander.children[0].material[3].map.needsUpdate = true
        this.wallStaander.children[0].material[3].needsUpdate = true
        this.wallStaander.children[0].material[4].map = textureX
        this.wallStaander.children[0].material[4].map.needsUpdate = true
        this.wallStaander.children[0].material[4].needsUpdate = true
        this.wallStaander.children[0].material[5].map = textureX
        this.wallStaander.children[0].material[5].map.needsUpdate = true
        this.wallStaander.children[0].material[5].needsUpdate = true
      }

      if(this.mapWallStaander) {
        const mapTexture = map.clone()
        this.textureSettings(mapTexture,  this.staanderObject.dx/2450,  this.staanderObject.dz/145,  this.set.rotation+Math.PI*0.5)
        this.mapWallStaander.children[0].material[1].map = mapTexture
        this.mapWallStaander.children[0].material[1].map.needsUpdate = true
        this.mapWallStaander.children[0].material[1].needsUpdate = true
      }
    })
  }


  textureSettings(texture, repeatX, repeatY, rotation) {
    if (!texture) return
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
  }

  shaderMaterial(hex) {
    const c = new this.THREE.Color(hex)
    const fragmentShader = `void main() {gl_FragColor = vec4(vec3(${c.r},${c.g},${c.b}),1.0);}`
    const material = new this.THREE.ShaderMaterial({fragmentShader})
    return material
  }

  vectorMHighest(vector, index) {
    let numArray = []
    Object.keys(vector).forEach(key => {
      numArray.push(vector[key])
    });
    numArray.sort(function(a, b){return a - b})
    return numArray[numArray.length-index]
  }

  setSchorenVisibility() {
    this.schoorGroup.children[0].visible = !this.set.tussenMuur && !this.wallOn && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? !!this.parentWall : true) : true)
    this.schoorGroup.children[1].visible = !this.set.tussenMuur && !this.wallOn && (this.modelType.isKapschuur && this.alignsXAxis ? (this.alignedRotation ? true : !!this.childWall) : true)
    this.schoorGroup.visible = this.set.mx >= this.schoor.wallMinWidth
  }
  
  updateNiceOffset() {
    this.groupOffset.x = 0
    this.groupOffset.z = 0

    if(this.wallConfig.position === "buiten" && !this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
      if(this.alignsXAxis) {
        if(this.alignedRotation) {
          // linkerwand
          this.groupOffset.z = this.fz/2 - this.staanderGrootte.dx/2
        } else {
          // rechterwand
          this.groupOffset.z = this.staanderGrootte.dx/2 - this.fz/2
        }
      }else{
        if(this.alignedRotation) {
          // voorwand
          this.groupOffset.x = this.fz/2 - this.staanderGrootte.dx/2
        } else {
          // achterwand
          this.groupOffset.x = this.staanderGrootte.dx/2 - this.fz/2
        }
      }
    } else {
      if (this.wallStaander) {
        this.wallStaander.position.z = 0
      }
    }

    if (!this.settings.tussenMuur) {
      if (this.alignsXAxis) {
        this.group.position.z = this.set.x + this.groupOffset.z
      } else {
        this.group.position.x = this.set.z + this.groupOffset.x
      }
    }
    if (this.wallConfig.position === "buiten" && !this.settings.tussenMuur) {
      if(!this.settings.tussenMuur && !((this.settings.buitenMuur && this.settings.buitenMuurTexture.open) || (this.settings.binnenMuur && this.settings.binnenMuurTexture.open))) {
        this.schoorGroup.position.z = this.fz/2 - this.staanderGrootte.dx/2 - (this.staanderGrootte.dx - this.schoor.sx)/2
      } else {
        this.schoorGroup.position.z = - (this.staanderGrootte.dx - this.schoor.sx)/2
      }
    } else {
      this.schoorGroup.position.z = 0
    }
  }

  setBorstweringModel(borstweringComponent) {
    if (borstweringComponent) {
      this.modelCode = borstweringComponent.modelCode
      this.settings.modelCode = borstweringComponent.modelCode

      // remove old meshes
      this.borstweringComponent.model.removeFromParent()
      this.borstweringComponent.map.removeFromParent()

      this.borstweringComponent = borstweringComponent
      this.borstweringAssetHitboxGeometry = new this.THREE.BoxGeometry(this.borstweringComponent.dimensions.x, this.borstweringComponent.dimensions.y - this.borstweringComponent.dimensions.yo, 100)
      this.borstweringAssetHitbox.geometry = this.borstweringAssetHitboxGeometry
      this.borstweringAssetHitbox.name = "borstweringAssetHitbox"
      this.borstweringAssetHitbox.visible = false
      this.group.add(this.borstweringAssetHitbox)
      this.modelGroup.add(this.borstweringComponent.model)
      this.mapGroup.add(this.borstweringComponent.map)
      this.borstweringComponent.model.rotation.y = 0.5 * Math.PI
      this.borstweringComponent.model.position.x = this.borstweringComponent.dimensions.xo
      this.borstweringComponent.model.position.y = this.borstweringComponent.dimensions.yo
      this.borstweringComponent.model.position.z = this.borstweringComponent.dimensions.zo
      this.borstweringComponent.map.rotation.y = 0.5 * Math.PI
      this.borstweringComponent.map.position.y = 1000
      this.borstweringComponent.map.renderOrder = 100
      this.borstweringAssetHitbox.position.set(
        this.borstweringComponent.dimensions.x/2,
        this.borstweringComponent.dimensions.yo + (this.borstweringComponent.dimensions.y - this.borstweringComponent.dimensions.yo) / 2,
        0
      )
    }
  }
}