import { History } from "../functions/history"
import pickBy from "lodash.pickby"
import isEqual from "lodash.isequal"
import * as graphql from '@/functions/graphql.js'
import * as errorHandeling from '@/functions/errorHandeling.js'
import { saveModelConfig, getModelConfig } from '@/graphql/index'
import $ from "jquery";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

const gltfLoader = new GLTFLoader()

const history = new History({
  type: "Plat dak",
  typeId: "629a060f2fbe252c87361bdc", //629a060f2fbe252c87361bdc - platdak       629a060f2fbe252c87361bdd - zadeldak      629a060f2fbe252c87361bde - kapschuur
  model: "Barcelona",
  modelId: "62ac6ec554a242046ed38e55", //62ac6ec554a242046ed38e55 barcelona
  maatwerk: {
    enabled: true,
    width: {
      min: 2500,
      max: 20000
    },
    depth: {
      min: 2000,
      max: 4225
    }
  },
  wall: {
    minWallWidth: 0,
    needsSpaceForStaander: false
  },
  roofFinishes: [
    "62e7c729de63cb0cfe032453",
    "62e8cd2b785b84d9334f67b2",
    "62e8cd2b785b84d9334f67b3",
    "62e8cd2b785b84d9334f67b4",
    "62e8cd2b785b84d9334f67b5",
    "656888c7b94320fe5e92be6b",
  ],
  wallFinishes: {
    wandBuiten: [
      "62d800e76185b381217050fc",
      "62d800c86185b381217050f8",
      "62d800106185b381217050f4",
      "62d508aec795edc5727d9fbf",
      "62d6621dd973225dbe295ee9",
      "62d800fb6185b38121705100",
      "62d8010a6185b38121705104",
      "62d166f687ae1ef94728a5a9",
      "62d1616416d91c337d2d2544",
      "62d16236bc514a81235ad044",
      "62d162d1bc514a81235ad05d",
      "662250b3d0774fabfa45b71c"
    ],
    wandBinnen: [
      "62d800e76185b381217050fc",
      "62d800c86185b381217050f8",
      "62d800106185b381217050f4",
      "62d508aec795edc5727d9fbf",
      "62d6621dd973225dbe295ee9",
      "62d800fb6185b38121705100",
      "62d8010a6185b38121705104",
      "62d166f687ae1ef94728a5a9",
      "62d1616416d91c337d2d2544",
      "62d16236bc514a81235ad044",
      "62d162d1bc514a81235ad05d",
      "662250b3d0774fabfa45b71c"
    ],
    tussenWandLinks: [
      "62d162d1bc514a81235ad05d",
      "62d16236bc514a81235ad044",
      "62d1616416d91c337d2d2544",
      "62d166f687ae1ef94728a5a9",
      "62d8010a6185b38121705104",
      "62d800fb6185b38121705100",
      "62d800e76185b381217050fc",
      "62d6621dd973225dbe295ee9",
      "62d508aec795edc5727d9fbf",
      "62d800106185b381217050f4",
      "62d800c86185b381217050f8",
      "662250b3d0774fabfa45b71c"
    ],
    tussenWandRechts: [
      "62d162d1bc514a81235ad05d",
      "62d16236bc514a81235ad044",
      "62d1616416d91c337d2d2544",
      "62d166f687ae1ef94728a5a9",
      "62d8010a6185b38121705104",
      "62d800fb6185b38121705100",
      "62d800e76185b381217050fc",
      "62d800c86185b381217050f8",
      "62d800106185b381217050f4",
      "62d508aec795edc5727d9fbf",
      "62d6621dd973225dbe295ee9",
      "662250b3d0774fabfa45b71c"
    ],
    topgevelBuiten: [],
    topgevelBinnen: [],
    topgevelLinks: [],
    topgevelRechts: []
  },
  // roofFinishes: [
  //   "639c894bf44387d5ef871c00",
  //   "63d3c97a1f3031cf6c4503b9",
  //   "63a1d494e0c6752e76d8f4de",
  //   "63d3c97a1f3031cf6c4503ba",
  //   "639c894bf44387d5ef871c01",
  //   "63d3c97a1f3031cf6c4503bb",
  //   "63a1d494e0c6752e76d8f4dd",
  //   "63a1d494e0c6752e76d8f4df",
  //   "63d118ace959eb5612cde86a",
  //   "63d3c97a1f3031cf6c4503bc",
  // ],
  // wallFinishes: {
  //   wandBuiten: [
  //     "62d6621dd973225dbe295ee9",
  //     "62d508aec795edc5727d9fbf",
  //     "62d800106185b381217050f4",
  //     "62d800c86185b381217050f8",
  //     "62d800e76185b381217050fc",
  //     "62d800fb6185b38121705100",
  //     "62d8010a6185b38121705104",
  //     "62d166f687ae1ef94728a5a9",
  //     "62d1616416d91c337d2d2544",
  //     "62d16236bc514a81235ad044",
  //     "62d162d1bc514a81235ad05d"
  //   ],
  //   wandBinnen: [
  //     "62d162d1bc514a81235ad05d",
  //     "62d16236bc514a81235ad044",
  //     "62d1616416d91c337d2d2544",
  //     "62d166f687ae1ef94728a5a9",
  //     "62d8010a6185b38121705104",
  //     "62d6621dd973225dbe295ee9",
  //     "62d508aec795edc5727d9fbf",
  //     "62d800106185b381217050f4",
  //     "62d800c86185b381217050f8",
  //     "62d800e76185b381217050fc",
  //     "62d800fb6185b38121705100"
  //   ],
  //   tussenWandLinks: [
  //     "62d162d1bc514a81235ad05d",
  //     "62d16236bc514a81235ad044",
  //     "62d1616416d91c337d2d2544",
  //     "62d166f687ae1ef94728a5a9",
  //     "62d8010a6185b38121705104",
  //     "62d800fb6185b38121705100",
  //     "62d800e76185b381217050fc",
  //     "62d800c86185b381217050f8",
  //     "62d800106185b381217050f4",
  //     "62d508aec795edc5727d9fbf",
  //     "62d6621dd973225dbe295ee9"
  //   ],
  //   tussenWandRechts: [
  //     "62d6621dd973225dbe295ee9",
  //     "62d508aec795edc5727d9fbf",
  //     "62d800106185b381217050f4",
  //     "62d800c86185b381217050f8",
  //     "62d800e76185b381217050fc",
  //     "62d800fb6185b38121705100",
  //     "62d8010a6185b38121705104",
  //     "62d166f687ae1ef94728a5a9",
  //     "62d1616416d91c337d2d2544",
  //     "62d16236bc514a81235ad044",
  //     "62d162d1bc514a81235ad05d"
  //   ],
  //   topgevelBuiten: [
  //     "62d508aec795edc5727d9fbf",
  //     "62d6621dd973225dbe295ee9",
  //     "62d162d1bc514a81235ad05d",
  //     "62d16236bc514a81235ad044",
  //     "62d1616416d91c337d2d2544",
  //     "62d166f687ae1ef94728a5a9",
  //     "62d8010a6185b38121705104",
  //     "62d800fb6185b38121705100",
  //     "62d800e76185b381217050fc",
  //     "62d800c86185b381217050f8",
  //     "62d800106185b381217050f4"
  //   ],
  //   topgevelBinnen: [
  //     "62d6621dd973225dbe295ee9",
  //     "62d508aec795edc5727d9fbf",
  //     "62d800106185b381217050f4",
  //     "62d800c86185b381217050f8",
  //     "62d800fb6185b38121705100",
  //     "62d8010a6185b38121705104",
  //     "62d1616416d91c337d2d2544",
  //     "62d162d1bc514a81235ad05d",
  //     "62d16236bc514a81235ad044",
  //     "62d166f687ae1ef94728a5a9",
  //     "62d800e76185b381217050fc"
  //   ],
  //   topgevelLinks: [
  //     "62d6621dd973225dbe295ee9",
  //     "62d508aec795edc5727d9fbf",
  //     "62d800106185b381217050f4",
  //     "62d800c86185b381217050f8",
  //     "62d800e76185b381217050fc",
  //     "62d800fb6185b38121705100",
  //     "62d8010a6185b38121705104",
  //     "62d166f687ae1ef94728a5a9",
  //     "62d1616416d91c337d2d2544",
  //     "62d16236bc514a81235ad044",
  //     "62d162d1bc514a81235ad05d"
  //   ],
  //   topgevelRechts: [
  //     "62d162d1bc514a81235ad05d",
  //     "62d16236bc514a81235ad044",
  //     "62d1616416d91c337d2d2544",
  //     "62d166f687ae1ef94728a5a9",
  //     "62d8010a6185b38121705104",
  //     "62d800fb6185b38121705100",
  //     "62d800e76185b381217050fc",
  //     "62d800c86185b381217050f8",
  //     "62d800106185b381217050f4",
  //     "62d508aec795edc5727d9fbf",
  //     "62d6621dd973225dbe295ee9"
  //   ]
  // },
  overstekKlosRound: false,
  availableOptions: {
    fundering: true,
    dakgoot: false, //false,
    lichtstraten: true,
    ledverlichting: true
  },
  // afmetingen: {
  //   diepte: 3270,
  //   hoogte: 2300,
  //   breedte: 8020,
  //   bergingType: "643fa111dbdc95357995fbc0",
  //   artikelNummer: "S5100670",
  //   wallDivision: [
  //     {
  //       width: 2480,
  //       bergingEnabled: false
  //     },
  //     {
  //       width: 2480,
  //       bergingEnabled: false
  //     },
  //     {
  //       width: 2480,
  //       bergingEnabled: false
  //     }
  //   ],
  //   maximaleMuurBreedte: 3935,
  //   staanderDikte: 145,
  //   poer: {
  //     z: 150,
  //     y: 600
  //   },
  //   schoor: {
  //     enabled: true,
  //     sz: 530,
  //     sy: 530,
  //     sx: 45,
  //     sox: 205,
  //     wallMinWidth: 1500
  //   },
  //   ringBalk: {
  //     y: 145,
  //     z: 145
  //   },
  //   extraRingBalk: {
  //     enabled: false,
  //     y: 145,
  //     z: 145,
  //     position: null
  //   },
  //   ringbalkTussenPaal: {
  //     enabled: true,
  //     y: 145,
  //     z: 145
  //   },
  //   gordingBalk: {
  //     y: 145,
  //     z: 65,
  //     afstandTussenGordingen: 700,
  //     position: 2300,
  //     ox: null,
  //     oy: null,
  //     oyl: null
  //   },
  //   ringBalkGording: {
  //     y: 145,
  //     z: 145
  //   },
  //   overstekKlos: {
  //     enabled: null,
  //     z: null,
  //     zp: null,
  //     xb: null,
  //     xo: null,
  //     y: null,
  //     yk: null,
  //     position: {
  //       left: true,
  //       right: true
  //     }
  //   },
  //   boeideel: {
  //     enabled: true,
  //     y: 241,
  //     z: 25,
  //     position: 2300,
  //     lijstEnabled: false,
  //     ly: 195,
  //     lz: 25,
  //     lijstPosition: true,
  //     dpy: 25,
  //     dpz: 195,
  //     dpOffsetKopkant: 10,
  //     dpOffsetGootzijde: 10,
  //     nokpositie: 3600,
  //     byl: null,
  //     douglasPlanken: false
  //   },
  //   trim: {
  //     enabled: null,
  //     y: null,
  //     z: null,
  //     overhang: null,
  //     color: [],
  //     position: null
  //   },
  //   dak: {
  //     offset: 0,
  //     helling: 40,
  //     overhang: 155,
  //     beschotDikte: 100,
  //     dakpanOverhang: 100,
  //     dakpanOverhangAchter: 0,
  //     gordingSchoorHoek: null,
  //     gordingSchoorOffset: null
  //   },
  //   dakgoot: {
  //     enabled: true,
  //     y: 2300,
  //     offset: 100,
  //     twoPipesWidth: 7000,
  //     threePipesWidth: 15000
  //   },
  //   wall: {
  //     position: "tussen",
  //     wz: 18,
  //     height: [
  //       {
  //         position: "wandBuiten",
  //         value: 2300
  //       },
  //       {
  //         position: "wandBinnen",
  //         value: 2300
  //       },
  //       {
  //         position: "tussenWandLinks",
  //         value: 2300
  //       },
  //       {
  //         position: "tussenWandRechts",
  //         value: 2300
  //       }
  //     ]
  //   },
  //   omlijstingPalen: true
  // },
  // frameKleur: {name: 'Onbehandeld', url: '62e12a5fd15ec84582a1058e.jpeg', previewURL: '62e12a5fd15ec84582a1058e.jpeg', id: '62e12a5fd15ec84582a1058e', colorId: '62d1675487ae1ef94728a5f4'},
  // boeideelKleur: {name: 'Behandeld', url: '62d6621dd973225dbe295ee9.jpeg', previewURL: '62d6621dd973225dbe295ee9.jpeg', id: '62d6621dd973225dbe295ee9', colorId: '62ea9272d4ac06cce974be8d'},
  // hoekprofielKleur: {name: 'Onbehandeld', url: '643e612b8652e7fdb143e91d.jpeg', previewURL: '643e612b8652e7fdb143e91d.jpeg', id: '643e612b8652e7fdb143e91d', colorId: '62d1675487ae1ef94728a5f4'},
  // trimColor: {name: 'Grijs', hexCode: '#A0A0A0', id: '62d1675487ae1ef94728a5ff'},
  // selectedDakAfwerking: {id: "639c894bf44387d5ef871c00", name: "Keramische pan Mat zwart verglaasd", url: "639c894bf44387d5ef871c00.png", textureType: "62e3e09a990280c3716422dd", plankType: "undefined", color: "62e7c6b9de63cb0cfe032436", locations: [], scalingX: 1.05, scalingY: 1.2, emissive: 0, emissiveColor: "#696969", metalness: 0.15, order: null, previewURL: "639c894bf44387d5ef871c00.png"},
  afmetingen: {
    diepte: 3270,
    hoogte: 2300,
    breedte: 8020,
    bergingType: "643fa111dbdc95357995fbc0",
    artikelNummer: null,
    wallDivision: [
      {
        width: 2480,
        bergingEnabled: false
      },
      {
        width: 2480,
        bergingEnabled: false
      },
      {
        width: 2480,
        bergingEnabled: false
      }
    ],
    maximaleMuurBreedte: 3480,
    staanderDikte: 145,
    poer: {
      z: 150,
      y: 600
    },
    schoor: {
      enabled: true,
      sz: 530,
      sy: 530,
      sx: 65,
      sox: 205,
      wallMinWidth: 1500,
      position: "midden"
    },
    ringBalk: {
      y: 145,
      z: 145
    },
    extraRingBalk: {
      enabled: false,
      gordingVorm: false,
      y: 95,
      z: 65,
      position: false
    },
    ringbalkTussenPaal: {
      enabled: false,
      y: 145,
      z: 65
    },
    gordingBalk: {
      y: 145,
      z: 65,
      afstandTussenGordingen: 700,
      position: 2300,
      ox: 0,
      oy: 0,
      oyl: 0
    },
    ringBalkGording: {
      y: 145,
      z: 145,
      position: 2300
    },
    overstekKlos: {
      enabled: true,
      z: 65,
      zp: 145,
      xb: 220,
      xo: 60,
      y: 145,
      yk: 70,
      position: {
        left: true,
        right: true
      },
      zDik: 65,
      zDikPaal: 145,
      klosVoEnabled: true,
      klosVoEditable: false,
      xBovVo: 220,
      xOndVo: 60,
      yHooVo: 145,
      yKopVo: 70,
      klosAcEnabled: true,
      klosAcEditable: false,
      xBovAc: 220,
      xOndAc: 60,
      yHooAc: 145,
      yKopAc: 70,
      klosLiEnabled: true,
      klosLiEditable: false,
      xBovLi: 220,
      xOndLi: 60,
      yHooLi: 145,
      yKopLi: 70,
      klosReEnabled: true,
      klosReEditable: false,
      xBovRe: 220,
      xOndRe: 60,
      yHooRe: 145,
      yKopRe: 70
    },
    boeideel: {
      enabled: true,
      y: 195,
      z: 25,
      position: 2366,
      lijstEnabled: true,
      ly: 60,
      lz: 25,
      lijstPosition: false,
      dpy: 25,
      dpz: 195,
      dpOffsetKopkant: 10,
      dpOffsetGootzijde: 10,
      nokpositie: 3640,
      byl: 130,
      douglasPlanken: false,
      horizontaleOffset: 0
    },
    trim: {
      enabled: true,
      y: 40,
      z: 15,
      overhang: 8,
      color: ["672cb932f5e685a71e9322f5", "672cb9a9f5e685a71e9322f9"],
      position: 2561
    },
    dak: {
      offset: 0,
      helling: 0,
      overhang: 0,
      beschotDikte: 18,
      dakpanOverhang: 0,
      dakpanOverhangAchter: 0,
      gordingSchoorHoek: 0,
      gordingSchoorOffset: 0
    },
    dakgoot: {
      enabled: false,
      y: 2300,
      offset: 100,
      twoPipesWidth: 7500,
      threePipesWidth: 15000,
    },
    wall: {
      position: 'tussen',
      wz: 18,
      height: [
        {
          position: 'wandBuiten',
          value: 2300
        },
        {
          position: 'wandBinnen',
          value: 2300
        },
        {
          position: 'tussenWandLinks',
          value: 2300
        },
        {
          position: 'tussenWandRechts',
          value: 2300
        },
      ]
    },
    omlijstingPalen: false
  },
  frameKleur: { name: 'Onbehandeld', url: '62e12a5fd15ec84582a1058e.jpeg', previewURL: '62e12a5fd15ec84582a1058e.jpeg', id: '62e12a5fd15ec84582a1058e', colorId: '62d1675487ae1ef94728a5f4' },
  boeideelKleur: { name: 'Onbehandeld', url: '62e10ef13a62808321d03b29.jpeg', previewURL: '62e10ef13a62808321d03b29.jpeg', id: '62e10ef13a62808321d03b29', colorId: '62d1675487ae1ef94728a5f4' },
  hoekprofielKleur: { name: 'Onbehandeld', url: '643e612b8652e7fdb143e91d.jpeg', previewURL: '643e612b8652e7fdb143e91d.jpeg', id: '643e612b8652e7fdb143e91d', colorId: '62d1675487ae1ef94728a5f4' },
  trimColor: {"id": "672cb932f5e685a71e9322f5", "name": "Aluminium grijs", "url": "672cb932f5e685a71e9322f5.jpeg", "textureType": "62e3e09a990280c3716422dd", "color": "62d1675487ae1ef94728a5ff"},//{ name: 'Grijs', hexCode: '#A0A0A0', id: '62d1675487ae1ef94728a5ff' },
  selectedDakAfwerking: { id: "62e7c729de63cb0cfe032453", name: "EPDM", previewURL: "62e7c729de63cb0cfe032453.png" },
  fundering: true,
  muren: [
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
    {
      binnenMuur: false,
      binnenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      buitenMuur: false,
      buitenMuurTexture: { name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc' },
      connectionBinnen: "vertical",
      connectionBuiten: "vertical",
      modelArray: [],
      isSteellook: false
    },
  ],
  topgevels: [],
  dakgoot: {
    front: {
      enabled: false,
      left: false,
      right: true,
    },
    back: {
      enabled: false,
      left: false,
      right: true,
    }
  },
  insulation: null,
  opties: [],
}, 50)

const state = {
  menuUISettings: [],  //
  extraQuestionsWithText: [],
  afmetingen: null,
  frameColors: [],
  boeideelColors: [],
  hoekprofielColors: [],
  wallColors: null,
  selectedWallColorInside: {
    // type: 'Potdeksel',
    // typeImage: '62d129d2a04bd6a5191aeedc.png',
    // connection: 'vertical',
    // color: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
  },
  wallInsideOn: false,
  wallOutsideOn: true,
  selectedWallColorOutside: {
    // type: 'Potdeksel',
    // typeImage: '62d129d2a04bd6a5191aeedc.png',
    // connection: 'vertical',
    // color: {name: 'Zwart', url: '62d8010a6185b38121705104.png', previewURL: '62d129d2a04bd6a5191aeedc.png', hex: '#070707', id: '62d1675487ae1ef94728a5f6', plankType: '62d129d2a04bd6a5191aeedc'},
  },
  wallIsolatieStatus: false,
  isolatieTypeFromSelectedWall: null,
  isolatieTypeDefault: null,
  dakafwerking: [
    /*{name: 'Geen', previewURL: 'dak/geen-onbehandeld.jpg'},
    {name: 'EPDM', previewURL: 'dak/epdm.png'}*/
  ],
  currentPath: '',

  // combineer volledige configuratie in één object zodat deze gebruikt kan worden voor export en versie history
  // deze config kan dan verwerkt worden door een functie die settings op de goed plek insteled
  // of de config moet lijdend worden in de configurator, maar dan moeten alle extra instellingen die niet relevant zijn voor de config ook worden opgeslagen (zoals textures en url namen)
  config: history.getCurrent(),
  history: history,
  productList: [],
  tokenLoggedIn: null,
  inputData: {
    pronoun: '',
    name: '',
    surname: '',
    company: '',
    street: '',
    zip: '',
    city: '',
    phone: '',
    email: '',
    extraQuestions: {},
    privacy: false,
    conditions: false,
    questions: '',
  },
  isFinishingInvoice: false,
  userToken: '',
  productListPriceEXCL: 0,
  productListPriceBTW: 0,
  productListPriceINCL: 0,
  isRetourLink: false,
  wantsToChangeSize: {
    size: false,
    model: false
  },
  tempSize: {},
  tempModel: null,
  shouldNotShowMessage: {
    model: false,
    size: false,
  },
  availableWallFinishes: {},
  schuifwandRichting: null,
  schuifwandKleur: null,
  schuifwandPreference: 0,
  selectedSizeMode: '0',
  maatwerkWidth: history.getCurrent().afmetingen.breedte,
  maatwerkDepth: history.getCurrent().afmetingen.diepte,
  snappingEnabled: true,
  overstekConfig: {
    voEnabled: true,
    acEnabled: true,
    liEnabled: true,
    reEnabled: true,
  },
}

const mutations = {

  setUISettings(state, settings) {
    state.menuUISettings = settings || []  // Ensure we always have an array
  },

  setSize(state, size) {
    state.config.afmetingen = size
    state.maatwerkWidth = size.breedte
    state.maatwerkDepth = size.diepte
  },

  setExtraQuestionsWithText(state, data) {
    state.extraQuestionsWithText = data;
  },

  async setFrameColor(state, color) {
    state.config.frameKleur = color

    // change the no roof covering option to be inline with the actual color

    /*state.dakafwerking[0].previewURL = `dak/geen-${color.name.toLowerCase()}.jpg`
    if (state.selectedDakafwerking.name === 'Geen') {
      state.selectedDakafwerking.previewURL = `dak/geen-${color.name.toLowerCase()}.jpg`
    }*/
  },

  setBoeideelColor(state, color) {
    if (color.hex) {
      //color is  in wallcolor format, need to be formatted
      state.config.boeideelKleur = {
        name: color.name,
        url: color.url,
        previewURL: color.previewURL,
        id: color.url.substring(0, color.url.lastIndexOf(".")),
        colorId: color.id
      }
    } else {
      state.config.boeideelKleur = color
    }
  },

  setHoekprofielColor(state, color) {
    state.config.hoekprofielKleur = color
  },

  setTrimColor(state, color) {
    state.config.trimColor = color
  },

  setWallColorInside(state, color) {
    state.selectedWallColorInside.color = color
  },

  setWallColorOutside(state, color) {
    state.selectedWallColorOutside.color = color
  },

  setWallCategoryInside(state, category) {
    console.log("setWallCategoryInside", state.selectedWallColorInside, category)
    state.selectedWallColorInside = {
      ...category,
      color: category.colors[0],
    }
  },

  setWallCategoryOutside(state, category) {
    console.log("setWallCategoryOutside", state.selectedWallColorInside, category)
    state.selectedWallColorOutside = {
      ...category,
      color: category.colors[0],
    }
  },

  setWallInsideOn(state, status) {
    state.wallInsideOn = status
  },

  setWallOutsideOn(state, status) {
    state.wallOutsideOn = status
  },

  setWallIsolatieStatus(state, status) {
    state.wallIsolatieStatus = status
  },

  setWallIsolatieType(state, type) {
    state.setWallIsolatieType = type
  },

  setDakafwerking(state, color) {
    state.config.selectedDakAfwerking = color
    console.log("roofFinishTextureId", state.config.selectedDakAfwerking.id)
  },

  setWallUI(state, selectedWall) {
    console.log(selectedWall.wall.settings)
    if (selectedWall.wall.name === "topgevelSelectbox") {
      state.wallInsideOn = selectedWall.wall.settings.binnenMuur
      state.wallOutsideOn = selectedWall.wall.settings.buitenMuur
      console.log(state.wallInsideOn, state.wallOutsideOn)
    } else {
      state.wallInsideOn = selectedWall.settings.binnenMuur
      state.wallOutsideOn = selectedWall.settings.buitenMuur
    }

    let wallInside
    let wallOutside

    for (let i = 0; i < state.wallColors.binnen.length; i++) {

      for (let x = 0; x < state.wallColors.binnen[i].colors.length; x++) {

        if (state.wallColors.binnen[i].colors[x].url === (selectedWall.wall.name === "topgevelSelectbox" ? selectedWall.wall.settings.binnenMuurTexture.url : selectedWall.settings.binnenMuurTexture.url)) {
          wallInside = {
            ...state.wallColors.binnen[i],
            color: state.wallColors.binnen[i].colors[x]
          }
        }
      }
    }
    console.log(state.wallColors)
    for (let i = 0; i < state.wallColors.buiten.length; i++) {

      for (let x = 0; x < state.wallColors.buiten[i].colors.length; x++) {

        if (state.wallColors.buiten[i].colors[x].url === (selectedWall.wall.name === "topgevelSelectbox" ? selectedWall.wall.settings.buitenMuurTexture.url : selectedWall.settings.buitenMuurTexture.url)) {
          wallOutside = {
            ...state.wallColors.buiten[i],
            color: state.wallColors.buiten[i].colors[x]
          }
        }
      }
    }

    state.selectedWallColorInside = wallInside
    console.log("wallOutside", wallOutside)
    state.selectedWallColorOutside = wallOutside
  },

  setWallDefault(state) {
    console.log(state)
    /*state.selectedWallColorInside = {
      type: 'Deens rabat',
      typeImage: 'wall/potdeksel.png',
      connection: 'vertical',
      color: {name: 'Onbehandeld', url: 'potdeksel-onbehandeld.jpg', previewURL: 'wall/potdeksel-onbehandeld.png'},
    }*/

    /*state.selectedWallColorOutside = {
      type: 'Sponning',
      typeImage: 'wall/potdeksel.png',
      connection: 'vertical',
      color: {name: 'Onbehandeld', url: 'potdeksel-onbehandeld.jpg', previewURL: 'wall/potdeksel-onbehandeld.png'},
    }*/

    //state.wallInsideOn = false
    //state.wallOutsideOn = true
  },

  setCurrentRoute(state, path) {
    state.currentPath = path
  },

  setConfigOptions(state, options) {
    state.config.opties = options
  },

  setModelType(state, model) {
    state.config.model = model.name
    state.config.modelId = model.id
    state.config.type = model.type.name
    state.config.typeId = model.type.id
    state.config.maatwerk = model.maatwerk
    state.config.standaardmatenDisabled = model.standaardmatenDisabled
    state.config.wall = model.wall
    state.config.roofFinishes = model.roofFinishes
    state.config.wallFinishes = model.wallFinishes
    state.config.overstekKlosRound = model.overstekKlosRound
    state.config.availableOptions = model.availableOptions
    state.afmetingen = model.defaultSizes
    console.log(state.config)
    /*state.config.afmetingen = {
      breedte: model.defaultSizes[1].breedtes[2],
      hoogte: 2300,
      diepte: model.defaultSizes[1].diepte,
      schoor: model.defaultSizes[1].schoor,
      staanderDikte: model.defaultSizes[1].staanderDikte
    }*/

  },

  setType(state, model) {
    state.config.type = model.type.name
    //state.config.typeId = model.type.id
    /*state.config.model = model.name
    state.config.modelId = model.id
    state.config.roofFinishes = model.roofFinishes
    state.config.overstekKlosRound = model.overstekKlosRound
    state.afmetingen = model.defaultSizes*/

    /*state.config.afmetingen = {
      breedte: model.defaultSizes[1].breedtes[2],
      hoogte: 2300,
      diepte: model.defaultSizes[1].diepte,
      schoor: model.defaultSizes[1].schoor,
      staanderDikte: model.defaultSizes[1].staanderDikte
    }*/

    state.history.push(state.config)
  },

  setAfmetingen(state, model) {
    state.afmetingen = model.defaultSizes
  },

  setBergingtypes(state, types) {
    state.bergingtypes = types
  },

  setFoundation(state, value) {
    state.config.fundering = value
  },

  setDakgoot(state, value) {
    // console.log('state.config',state.config)

    // Orginal code
    // const needs2Pipes = state.config.afmetingen.breedte >= state.config.afmetingen.dakgoot.twoPipesWidth  

    // Schakelen op dakplaatbreedte (gelijk aan de gootlengte)
    const needs2Pipes = Math.round(state.dakPlaatSize.dakPlaatBreedteVoor) >= state.config.afmetingen.dakgoot.twoPipesWidth

    // Log t.b.v te kijken of de dakplaatbreedte goed wordt doorgegeven en vergelijken met de instellingen uit het CMS
    // console.log("needs2Pipes")
    // console.log(Math.round(state.dakPlaatSize.dakPlaatBreedteVoor))
    // console.log(state.config.afmetingen.dakgoot.twoPipesWidth)

    if (value.name === "Voorzijde") {
      state.config.dakgoot.front = {
        enabled: value.enabled,
        left: value.enabled ? (needs2Pipes || value.left) : value.left,
        right: value.enabled ? (needs2Pipes || value.right) : value.right,
      }
      console.log(state.config.dakgoot.front)
    } else if (value.name === "Achterzijde") {
      state.config.dakgoot.back = {
        enabled: value.enabled,
        left: value.enabled ? (needs2Pipes || value.left) : value.left,
        right: value.enabled ? (needs2Pipes || value.right) : value.right,
      }
    }
  },

  setConfigInsulation(state, value) {
    console.log("setConfigInsulation", value)
    state.config.insulation = value
  },

  setOldState(state, oldState) {
    state.config = oldState
  },

  updateConfig(state) {
    // console.log("newConfig", state.config)
    state.history.push(state.config)
  },

  updateConfigDefault(state) {
    console.log("current", state.history.getCurrent())
    state.history.default = state.history.getCurrent()
  },

  setWallColors(state, wallColors) {
    state.wallColors = wallColors
  },

  setIsolatieTypes(state, types) {
    state.isolatietypes = types;
  },    
  setIsolatieTypeDefault(state, type) {
    state.isolatieTypeDefault = type;
  },

  handleSelectedWallIsolatie(state, {newStatus, /*wall, muurInstances*/muurWithSettings, newType=null}) {
    // console.log('mutation: handleSelectedWallIsolatie', newStatus, wall, muurInstances, newType);
    // const muurWithSettings = muurInstances.find((muur) => muur.group.uuid === wall.wall.parent.uuid)
    // console.log('muur instance with settings',muurWithSettings);

    // add opposite status to settings
    muurWithSettings.heeftIsolatie = newStatus;

    // add type id if needed
    if (newStatus === true && !muurWithSettings.isolatieId) {
      // set default
      const defaultType = state.isolatieTypeDefault;
      muurWithSettings.isolatieId = defaultType.id;
      state.isolatieTypeFromSelectedWall = defaultType;
      // console.log('state.isolatieTypeFromSelectedWall',state.isolatieTypeFromSelectedWall)
    // note: new types not yet implemented
    } else if (newStatus === true && newType) {
      muurWithSettings.isolatieId = newType.id;
      state.isolatieTypeFromSelectedWall = newType;
    }    
  },

  setFrameColors(state, frameColors) {
    state.frameColors = frameColors
  },

  setBoeideelColors(state, boeideelColors) {
    state.boeideelColors = boeideelColors
  },

  setHoekprofielColors(state, hoekprofielColors) {
    state.hoekprofielColors = hoekprofielColors
  },

  setProductList(state, data) {
    state.productList = data
  },

  setProductListPrice(state, data) {
    state.productListPriceEXCL = data.excl
    state.productListPriceBTW = data.btw
    state.productListPriceINCL = data.incl
    console.log(state.productListPrice)
  },

  setTokenLoggedIn(state, data) {
    state.tokenLoggedIn = data
  },

  setDWSessionData(state, data) {
    console.log("setDWSessionData", data)
    state.dwSessionData = data
  },

  setInputData(state, data) {
    state.inputData = data
  },

  setAddWallConfig(state, { settings, index }) {
    state.config.muren[index] = settings
  },

  setUpdateWallConfig(state, { settings, index }) {
    state.config.muren[index] = settings
  },

  setUpdateTopgevelConfig(state, { settings, index }) {
    state.config.topgevels[index] = settings
  },

  insertWallInconfigArray(state, settings) {
    console.log("InsertedWall", settings)
    state.config.muren.push(settings)
  },

  setWallArrayConfig(state, walls) {
    // console.log("setWallArrayConfig",walls)
    state.config.muren = walls
  },

  setTopgevelArrayConfig(state, topgevels) {
    state.config.topgevels = topgevels
  },

  setDakPlaatSize(state, size) {
    state.dakPlaatSize = size
  },

  setTopgevelSize(state, size) {
    state.topgevelSize = size
  },

  setIsRetourLink(state, boolean) {
    state.isRetourLink = boolean
  },

  setWantsToChangeSize(state, { mode, value }) {
    state.wantsToChangeSize[mode] = value
  },

  setTempSize(state, size) {
    state.tempSize = size
  },

  setTempModel(state, model) {
    state.tempModel = model
  },

  setShowMessage(state, data) {
    state.shouldNotShowMessage = data
  },

  setSavedModelConfig(state, config) {
    state.history.reset(config)
    state.config = state.history.getCurrent()
  },

  setConfigToCurrent(state) {
    state.config = state.history.getCurrent()
  },

  initializeHistory(state) {
    state.history.init(state.history.default)
  },

  setIsFinishingInvoice(state, value) {
    state.isFinishingInvoice = value
  },

  setUserToken(state, token) {
    state.userToken = token
  },

  setConfiguratorURL(state, url) {
    state.configuratorURL = url
  },

  setAvailableWallFinishes(state, data) {
    state.availableWallFinishes = data
  },

  setSchuifwandRichting(state, data) {
    state.schuifwandRichting = data
  },

  setSchuifwandKleur(state, data) {
    state.schuifwandKleur = data
  },

  setSchuifwandPreference(state, data) {
    state.schuifwandPreference = data
  },

  setSelectedSizeMode(state, data) {
    state.selectedSizeMode = data
  },

  setMaatwerkWidth(state, data) {
    state.maatwerkWidth = data
  },

  setMaatwerkDepth(state, data) {
    state.maatwerkDepth = data
  },

  toggleSnapping(state) {
    state.snappingEnabled = !state.snappingEnabled
  },

  setOverstekConfig(state, data) {
    state.overstekConfig = data
  },

  setLichtstratenInConfig(state, data) {
    state.config.lichtstraten = data
  }
}

const actions = {
  async changeSize(context, { size, mode, shouldResetWalls = true}) {
    const oldSize = JSON.parse(JSON.stringify(context.getters.getFormatedSizes))
    if (size.dakgoot.enabled) {
      //check if dakgoot needs to be updated

      // Schakelen op dakplaatbreedte (gelijk aan de gootlengte)
      let dakPlaatBreedteVoor = Math.round(state.dakPlaatSize.dakPlaatBreedteVoor)      
      if (dakPlaatBreedteVoor >= size.dakgoot.twoPipesWidth) {
        if (context.state.config.dakgoot.front.enabled) {
          context.state.config.dakgoot.front.left = true
          context.state.config.dakgoot.front.right = true
        }
        if (context.state.config.dakgoot.back.enabled) {
          context.state.config.dakgoot.back.left = true
          context.state.config.dakgoot.back.right = true
        }
      } else {
        if (context.state.config.dakgoot.front.enabled) {
          context.state.config.dakgoot.front.left = false
          context.state.config.dakgoot.front.right = true
        }
        if (context.state.config.dakgoot.back.enabled) {
          context.state.config.dakgoot.back.left = false
          context.state.config.dakgoot.back.right = true
        }
      }
      //await context.commit('setDakgoot', size.dakgoot)
    }
    // if (mode !== "model") {
    if(shouldResetWalls) {
      await context.dispatch('resetWalls')
      await context.dispatch('resetTopgevels')
    }

    // reset overstekConfig
    if (mode === "model") {
      context.commit('setOverstekConfig', {
        voEnabled: true,
        acEnabled: true,
        liEnabled: true,
        reEnabled: true,
      })
    }

    context.commit('setSize', size)
    console.log(context.getters.getFormatedSizes)
    console.log(context.getters.getConfig)
    const typeId = context.getters.getConfig.typeId
    const modelId = context.getters.getConfig.modelId
    const afmetingen = context.getters.getFormatedSizes

    if (!state.config.roofFinishes.includes(state.config.selectedDakAfwerking.id)) {
      await context.dispatch('getTextures')
      const textures = context.getters.getTextures
      const color = textures.filter(texture => texture.id === state.config.roofFinishes[0])[0]
      color.previewURL = color.url
      context.commit('setDakafwerking', color)
      context.dispatch('toggleEPDM')
      context.dispatch('updateDakafwerkingTexture', color)
    }

    context.dispatch('updateFrame', { typeId, modelId, afmetingen, shouldNotResetWalls: !shouldResetWalls })
    if (size.trim.enabled) {
      let color = context.getters.getTextures.filter(texture => texture.id.toString() === size.trim.color[0].toString())[0]
      context.commit('setTrimColor', color)
    }

    // set wallFinishes
    let boeideelColor = state.config.boeideelKleur
    let colorBuitenSame = state.availableWallFinishes.wandBuiten[0].colors.find(color => color.id === boeideelColor.colorId)
    if (!colorBuitenSame) { colorBuitenSame = state.availableWallFinishes.wandBuiten[0].colors[0] }

    context.getters.getMuurInstances.forEach((muur, index) => {

      const isTopgevel = muur.isTopgevel
      const isTussenMuur = muur.settings.tussenMuur

      const buitenSelector = isTopgevel ? (isTussenMuur ? "topgevelLinks" : "topgevelBuiten") : (isTussenMuur ? "tussenWandLinks" : "wandBuiten")
      const binnenSelector = isTopgevel ? (isTussenMuur ? "topgevelRechts" : "topgevelBinnen") : (isTussenMuur ? "tussenWandRechts" : "wandBinnen")

      let colorBuiten = muur.settings.buitenMuurTexture
      let colorBinnen = muur.settings.binnenMuurTexture

      const buiten_plankTypeAllowed = state.availableWallFinishes[buitenSelector].find(texture => texture.id === colorBuiten.plankType)
      if (!buiten_plankTypeAllowed) {
        const buiten_colorAllowed = state.availableWallFinishes[buitenSelector][0].colors.find(color => color.colorId === colorBuiten.colorId)
        if (!buiten_colorAllowed) {
          colorBuiten = state.availableWallFinishes[buitenSelector][0].colors[0]
        } else {
          colorBuiten = buiten_colorAllowed
        }
      }
      if (context.state.config.afmetingen.wall.position === "buiten") {
        colorBuiten = colorBuitenSame
      }

      const binnen_plankTypeAllowed = state.availableWallFinishes[binnenSelector].find(texture => texture.id === colorBinnen.plankType)
      if (!binnen_plankTypeAllowed) {
        const binnen_colorAllowed = state.availableWallFinishes[binnenSelector][0].colors.find(color => color.colorId === colorBinnen.colorId)
        if (!binnen_colorAllowed) {
          colorBinnen = state.availableWallFinishes[binnenSelector][0].colors[0]
        } else {
          colorBinnen = binnen_colorAllowed
        }
      }
      // if(!state.availableWallFinishes[buitenSelector][0].colors.find(color => color.colorId === "62ea9272d4ac06cce974be8d")) {

      // }


      console.log(colorBuiten.name, colorBinnen.name)

      const settings = {
        binnenMuurTexture: colorBinnen,
        connectionBinnen: colorBinnen.connectionBinnen,
        buitenMuurTexture: colorBuiten,
        connectionBuiten: colorBuiten.connectionBuiten
      }
      console.log(colorBuiten, settings)
      //state.frame.updateWall(null, settings, 0, false)

      context.commit('updateWall', {
        wall: null,
        index,
        settings,
        context,
        updateEntireWall: false
      })

    })




    //if(!state.config.roofFinishes.includes(state.config.boeideelKleur.id)) {
    if (oldSize.wall.position !== size.wall.position) {
      console.log(state.config.wallFinishes.wandBuiten, state.config.boeideelKleur)

      if (state.boeideelColors.length === 0) {
        await context.dispatch('getWallColors')
      }

      if (size.wall.position === "buiten") {
        context.commit('setBoeideelColor', colorBuitenSame)
        context.dispatch('updateBoeideelTexture', colorBuitenSame)

      } else if (oldSize.wall.position === "buiten") {
        const onbehandeld = state.boeideelColors.find(color => color.colorId === "62d1675487ae1ef94728a5f4")
        if (onbehandeld) {
          context.commit('setBoeideelColor', onbehandeld)
          context.dispatch('updateBoeideelTexture', onbehandeld)
        } else {
          const color = state.boeideelColors[0]
          context.commit('setBoeideelColor', color)
          context.dispatch('updateBoeideelTexture', color)
        }
      }
    }




    if (size.omlijstingPalen) {
      await context.commit('setBoeideelColor', state.config.frameKleur)
      await context.commit('updateBoeideelTexture', state.config.frameKleur.url)
    }

    context.commit('updateConfig')
  },

  requestSizeChange(context, { size, model, mode, shouldResetWalls }) {
    const allWallsOff = context.getters.getConfig.muren.every(wall => !wall.binnenMuur && !wall.buitenMuur && !wall.isSteellook && !wall.isSlidingWall)
    if (context.state.shouldNotShowMessage[mode] || !shouldResetWalls || allWallsOff) {
      if (model) {
       context.dispatch('updateModelType', model)
      }
      context.dispatch('changeSize', { size, mode, shouldResetWalls })
    } else {
      context.commit('setWantsToChangeSize', { mode, value: true })
      context.commit('setTempSize', size)
      if (model) {
        context.commit('setTempModel', model)
      }
    }
  },

  confirmSizeChange(context, mode) {
    context.commit('setWantsToChangeSize', { mode, value: false })
    if(context.state.tempModel) {
      context.dispatch('updateModelType', context.state.tempModel)
      context.state.tempModel = null
    }
    context.dispatch('changeSize', { size: context.state.tempSize, mode })
  },

  cancelSizeChange(context, mode) {
    context.commit('setWantsToChangeSize', { mode, value: false })
  },

  saveShowMessage(context, data) {
    context.commit('setShowMessage', data)
  },

  async updateFrameTexture(context, color) {
    context.commit('setFrameColor', color)
    context.commit('updateFrameTexture', color.url)
    context.commit('updateConfig')

    await context.dispatch('getTextures')
    const textures = context.getters.getTextures

    if (state.config.selectedDakAfwerking.name.toLowerCase() === 'geen') {
      const filteredTextures = textures.filter(texture => texture.textureType === "62e3e09a990280c3716422dd")
      for (let i = 0; i < filteredTextures.length; i++) {
        if (filteredTextures[i].name.toLowerCase() === "geen" ? color.colorId === filteredTextures[i].color : false) {
          state.config.selectedDakAfwerking = {
            name: filteredTextures[i].name,
            previewURL: filteredTextures[i].url,
            id: filteredTextures[i].id,
          }
          console.log("roofFinishTextureId", state.config.selectedDakAfwerking.id)
          break;
        }
      }
    }
  },

  updateBoeideelTexture(context, color) {
    context.commit('setBoeideelColor', color)
    context.commit('updateBoeideelTexture', color.url)
    context.commit('updateConfig')
  },

  updateHoekprofielTexture(context, color) {
    context.commit('setHoekprofielColor', color)
    context.commit('updateHoekprofielTexture', color.url)
    context.commit('updateConfig')
  },

  updateTrimColor(context, color) {
    context.commit('setTrimColor', color)
    context.commit('updateTrimColor', color)
    context.commit('updateConfig')
  },

  updateWallTextureInside(context, color) {
    context.commit('setWallColorInside', color)

    if (context.rootState.frame.selectedWall) {

      // turn off outside wall if inside wall is being turned on while they are different
      if (
        // context.rootState.config.selectedWallColorOutside.color.id != context.rootState.config.selectedWallColorInside.color.id ||
        // context.rootState.config.selectedWallColorOutside.color.open ||
        // context.rootState.config.selectedWallColorInside.color.open
        context.rootState.config.selectedWallColorOutside.color.open || context.rootState.config.selectedWallColorInside.color.open
      ) {
        context.commit('setWallOutsideOn', false)
      }

      context.dispatch('updateWall', { index: null, settings: null })
    }

    context.commit('updateConfig')
  },

  updateWallTextureOutside(context, color) {
    context.commit('setWallColorOutside', color)
    if (context.state.config.afmetingen.wall.position === "buiten" &&
      context.getters.getSelectedWall &&
      !context.getters.getSelectedWall.isTopgevel &&
      !context.getters.getSelectedWall.settings.tussenMuur &&
      !color.open
    ) {
      context.commit('setBoeideelColor', color)
      context.commit('updateBoeideelTexture', color.url)
    }

    if (context.rootState.frame.selectedWall) {

      // turn off inside wall if outside wall is being turned on while they are different
      if (
        // context.rootState.config.selectedWallColorOutside.color.id != context.rootState.config.selectedWallColorInside.color.id ||
        // context.rootState.config.selectedWallColorOutside.color.open ||
        // context.rootState.config.selectedWallColorInside.color.open
        context.rootState.config.selectedWallColorOutside.color.open || context.rootState.config.selectedWallColorInside.color.open
      ) {
        context.commit('setWallInsideOn', false)
      }

      context.dispatch('updateWall', { index: null, settings: null })
    }

    context.commit('updateConfig')
  },

  updateWallCategoryInside(context, category) {
    context.commit('setWallCategoryInside', category)

    if (context.rootState.frame.selectedWall) {

      // turn off outside wall if inside wall is being turned on while they are different
      if (
        // context.rootState.config.selectedWallColorOutside.color.id != context.rootState.config.selectedWallColorInside.color.id ||
        // context.rootState.config.selectedWallColorOutside.color.open ||
        // context.rootState.config.selectedWallColorInside.color.open
        context.rootState.config.selectedWallColorOutside.color.open || context.rootState.config.selectedWallColorInside.color.open
      ) {
        context.commit('setWallOutsideOn', false)
      }

      context.dispatch('updateWall', { index: null, settings: null })
    }

    context.commit('updateConfig')
  },

  updateWallCategoryOutside(context, category) {
    context.commit('setWallCategoryOutside', category)

    if (context.rootState.frame.selectedWall) {

      // turn off inside wall if outside wall is being turned on while they are different
      if (
        // context.rootState.config.selectedWallColorOutside.color.id != context.rootState.config.selectedWallColorInside.color.id ||
        // context.rootState.config.selectedWallColorOutside.color.open ||
        // context.rootState.config.selectedWallColorInside.color.open
        context.rootState.config.selectedWallColorOutside.color.open || context.rootState.config.selectedWallColorInside.color.open
      ) {
        context.commit('setWallInsideOn', false)
      }

      context.dispatch('updateWall', { index: null, settings: null })
    }

    context.commit('updateConfig')
  },

  updateWallInsideOn(context, status) {
    context.commit('setWallInsideOn', status)

    if (context.rootState.frame.selectedWall) {

      // turn off outside wall if inside wall is being turned on while they are different
      if (
        // context.rootState.config.selectedWallColorOutside.color.id != context.rootState.config.selectedWallColorInside.color.id ||
        // context.rootState.config.selectedWallColorOutside.color.open ||
        // context.rootState.config.selectedWallColorInside.color.open
        context.rootState.config.selectedWallColorOutside.color.open || context.rootState.config.selectedWallColorInside.color.open
      ) {
        context.commit('setWallOutsideOn', false)
      }

      context.dispatch('updateWall', { index: null, settings: null })
    }

    context.commit('updateConfig')
  },

  updateWallOutsideOn(context, status) {
    context.commit('setWallOutsideOn', status)

    if (context.rootState.frame.selectedWall) {

      // turn off inside wall if outside wall is being turned on while they are different
      if (
        // context.rootState.config.selectedWallColorOutside.color.id != context.rootState.config.selectedWallColorInside.color.id ||
        // context.rootState.config.selectedWallColorOutside.color.open ||
        // context.rootState.config.selectedWallColorInside.color.open
        context.rootState.config.selectedWallColorOutside.color.open || context.rootState.config.selectedWallColorInside.color.open
      ) {
        context.commit('setWallInsideOn', false)
      }

      context.dispatch('updateWall', { index: null, settings: null })
    }

    context.commit('updateConfig')
  },

  updateWallIsolatieStatus(context, status) {
    console.log('action: updateWallIsolatieStatus', status)
    context.commit('setWallIsolatieStatus', status)
    // console.log('context.rootState.frame.selectedWall',context.rootState.frame.selectedWall)
    if (context.rootState.frame.selectedWall) {
      const wall = context.rootState.frame.selectedWall
      const muurInstances = context.getters.getMuurInstances;
      const newType = context.getters.getIsolatieTypeFromSelectedWall;
      const muurWithSettings = muurInstances.find((muur) => muur.group.uuid === wall.wall.parent.uuid)
      // console.log('selectedWall muurWithSettings',muurWithSettings)
      
      const currentStatusString = status ? 'Inactive' : 'Active';
      const buttonGroupTargetName = `isolatieHoverButtonGroup${currentStatusString}` // example: isolatieHoverButtonGroupActive
      const hoverButtonGroup = muurWithSettings.hoverIsoMainGroup.children.find(c => c.name === buttonGroupTargetName);

      context.commit('handleSelectedWallIsolatie', {newStatus:status, /*wall, muurInstances*/muurWithSettings, newType})

      context.dispatch('toggleWandIsolatie', {currentStatus:currentStatusString, buttonGroup:hoverButtonGroup, viewType:'Hover', isSmall:false, skipDataUpdate:true});

      // also flip the status on the map (now redundant - toggleWandIsolatie does this internally)
      // const mapButtonGroupTargetName = `isolatieMapButtonGroup${currentStatusString}`
      // const mapButtonGroup = muurWithSettings.mapIsoMainGroup.children.find(c => c.name === mapButtonGroupTargetName);
      // context.dispatch('toggleWandIsolatie', {currentStatus:currentStatusString, buttonGroup:mapButtonGroup, viewType:'Map', skipDataUpdate:true});

      context.dispatch('updateWall', { index: null, settings: null })
    }
    //
    // context.commit('updateConfig');
  }, 

  updateWallIsolatieType(context, type) {
      context.commit('setWallIsolatieType', type)

      if (context.rootState.frame.selectedWall) {
        console.log("updateWallIsolatieType", context.rootState.config.IsolatieTypeFromSelectedWall);
      }
      // context.commit('updateConfig')
  },

  updateDakafwerking(context, color) {
    context.commit('setDakafwerking', color)
    context.dispatch('toggleEPDM')
    context.dispatch('updateDakafwerkingTexture', color)
    context.commit('updateConfig')
    context.commit('setDakpanMeshVisibility', color.name !== 'Geen' && (context.state.config.typeId==="629a060f2fbe252c87361bdd"||context.state.config.typeId==="629a060f2fbe252c87361bde"))
  },

  toggleEPDM(context) {
    context.commit('toggleEPDM', context.state.config.selectedDakAfwerking.name === 'EPDM')
  },

  updateDakafwerkingTexture(context, color) {
    context.commit('updateDakafwerkingTexture', color)
  },

  setCurrentRoute(context, path) {
    context.commit('setCurrentRoute', path)
  },

  updateConfigOptions(context, options) {
    context.commit('setConfigOptions', options)
  },

  updateModelType(context, model) {
    context.commit('setModelType', model)
    context.dispatch('setWallFinishes')
  },

  updateType(context, model) {
    context.commit('setType', model)
  },

  updateFoundation(context, value) {
    context.commit('setFoundation', value)
    context.commit('setConcreteFoundation', value)
    context.commit('updateConfig')
  },

  updateDakgoot(context, value) {
    const previousDakgoot = JSON.parse(JSON.stringify(context.state.config.dakgoot))
    context.commit('setDakgoot', value)
    const newDakgoot = JSON.parse(JSON.stringify(context.state.config.dakgoot))
    if (newDakgoot.front.enabled !== previousDakgoot.front.enabled ||
      newDakgoot.back.enabled !== previousDakgoot.back.enabled ||
      (newDakgoot.front.enabled && (newDakgoot.front.left !== previousDakgoot.front.left || newDakgoot.front.right !== previousDakgoot.front.right)) ||
      (newDakgoot.back.enabled && (newDakgoot.back.left !== previousDakgoot.back.left || newDakgoot.back.right !== previousDakgoot.back.right))) {
      context.commit('setGoot', context.state.config.dakgoot)
      context.commit('updateConfig')
    }
  },

  addWallConfig(context, { index, settings }) {
    context.commit('setAddWallConfig', { index, settings })
    context.commit('updateConfig')
  },

  updateWallConfig(context, { index, settings }) {
    context.commit('setUpdateWallConfig', { index, settings })
    context.commit('updateConfig')
  },

  setUpdateWallConfig(context, { index, settings }) {
    context.commit('setUpdateWallConfig', { index, settings })
  },

  insertWallInconfigArray(context, settings) {
    context.commit('insertWallInconfigArray', settings)
  },

  updateWallArrayConfig(context, walls) {
    context.commit('setWallArrayConfig', walls)
    // context.commit('updateConfig')
  },

  updateTopgevelArrayConfig(context, topgevels) {
    context.commit('setTopgevelArrayConfig', topgevels)
  },

  updateDakPlaatSize(context, size) {
    context.commit('setDakPlaatSize', size)
  },

  updateTopgevelSize(context, size) {
    context.commit('setTopgevelSize', size)
  },

  async changeConfig(context, { oldState, currentState }) {
    console.log("OLDSTATE", oldState)

    const difference = pickBy(oldState, (v, k) => !isEqual(currentState[k], v))
    console.log("oldState", oldState)
    const typeId = context.getters.getConfig.typeId
    const modelId = context.getters.getConfig.modelId
    const afmetingen = context.getters.getFormatedSizes
    if (difference && difference.afmetingen) {
      context.dispatch('updateFrame', { typeId, modelId, afmetingen })
    } else if (difference && difference.frameKleur) {
      context.commit('updateFrameTexture', oldState.frameKleur.url)
    } else if (difference && difference.boeideelKleur) {
      context.commit('updateBoeideelTexture', oldState.boeideelKleur.url)
    } else if (difference && difference.hoekprofielKleur) {
      context.commit('updateBoeideelTexture', oldState.hoekprofielKleur.url)
    } else if (difference && difference.selectedDakAfwerking) {
      context.commit('toggleEPDM', oldState.selectedDakAfwerking.name === 'EPDM')
    } else if (difference && difference.topgevels) {
      console.log(currentState.topgevels, oldState.topgevels)
      context.commit('updateTopgevels', oldState.topgevels)
    } else if (difference && difference.dakgoot) {
      console.log(context.state.config.dakgoot = oldState.dakgoot)
      context.commit('setGoot', oldState.dakgoot)
    } else if (difference && difference.muren) {
      const wallDifference = pickBy(oldState.muren, (v) => !isEqual(currentState.muren.find(muur => v.uuid == muur.uuid), v))
      const changedIndexes = Object.keys(wallDifference)
      const indexes = changedIndexes.map((i) => { return parseInt(i) })

      console.log("history", wallDifference)
      console.log("indexes", indexes)
      console.log("difference", oldState.muren, currentState.muren)
      console.log(oldState.muren)

      //for(let i=oldState.muren.length; i<currentState.muren.length; i++) {
      //alert("removeWall")
      let wallToBeRemoved
      await currentState.muren.forEach(async (muur) => {
        if (!oldState.muren.find(current => current.uuid === muur.uuid)) {
          //alert("gevonden!")
          wallToBeRemoved = muur

          console.log("wallToBeRemoved", wallToBeRemoved)
          await context.dispatch('removeLastWall', wallToBeRemoved) //is geen last wall meer, maar nu een opgegeven muur
        }
      })
      //}

      console.log("lengthDiff", currentState.muren.length, oldState.muren.length)
      //for(let i=currentState.muren.length; i<oldState.muren.length; i++) {
      //alert("insertWall")
      let newWallSettings = null
      await oldState.muren.forEach(async (old) => {
        if (!currentState.muren.find(muur => muur.uuid === old.uuid)) {
          newWallSettings = old

          console.log("all muren: ", oldState.muren)
          console.log("new: ", newWallSettings)
          console.log("INSERTSTEELLOOK", newWallSettings)
          if (newWallSettings.isSteellook) {
            await context.dispatch('insertSteellook', newWallSettings)
          } else {
            await context.dispatch('insertWall', newWallSettings)
          }
        }
      });



      /*context.dispatch('updateWall', { index: indexes[i], settings: oldState.muren[indexes[i]] })
  
      context.dispatch('updateWallWidth', { index: indexes[i], settings: oldState.muren[indexes[i]] })
      context.dispatch('updateWallPosition', { index: indexes[i], settings: oldState.muren[indexes[i]] })*/
      //}

      /*console.log("oldState.muren", oldState.muren)
      currentState.muren.forEach(wall => {console.log("steellook check current", wall.isSteellook)})
      oldState.muren.forEach(wall => {console.log("steellook check old", wall.isSteellook)})
      for(let i=0; i<oldState.muren.length; i++) {
        //alert("insertWall")
        //oldState.muren.forEach(old => {
          const newConfig = currentState.muren.find(muur => muur.uuid === oldState.muren[i].uuid)
          console.log("nieuwe log", oldState.muren, i)
          if(oldState.muren[i].isSteellook !== newConfig.isSteellook) {
            alert("STEELLOOK VERANDERD")
          }
          console.log("STEELLOOKLOG: ", oldState.muren[i].isSteellook, newConfig.isSteellook)
        //});
      }*/




      const wallArray = []
      for (let i = 0; i < context.getters.getMuurInstances.length; i++) {
        wallArray.push(context.getters.getMuurInstances[i].settings)
      }
      console.log("wallArray", wallArray)
      context.dispatch('updateWallArrayConfig', wallArray)

      for (let i = 0; i < indexes.length; i++) {
        const muurInstances = context.getters.getMuurInstances
        if (muurInstances[indexes[i]]) {
          console.log("settings", oldState.muren[indexes[i]])
          context.dispatch('updateWallWidth', { index: indexes[i], settings: oldState.muren[indexes[i]] })
          context.dispatch('updateWallPosition', { index: indexes[i], settings: oldState.muren[indexes[i]] })
          context.dispatch('updateWall', { index: indexes[i], settings: oldState.muren[indexes[i]], updateEntireWall: true })
        }
      }

      // context.getters.getMuurInstances.forEach((muur) => {
      //   context.commit('setHoekProfielen', muur)
      // hoekprofield bug
      // })



      const modelArrayDifference = pickBy(oldState.muren, (v, k) => !isEqual(currentState.muren[k].modelArray, v.modelArray))
      const changedModelArrayIndexes = Object.keys(modelArrayDifference)
      const modelArrayIndexes = changedModelArrayIndexes.map((i) => { return parseInt(i) })

      for (let i = 0; i < modelArrayIndexes.length; i++) {
        const muurInstances = context.getters.getMuurInstances
        if (muurInstances[indexes[i]]) {
          const index = indexes[i]

          console.log("hierrr", currentState.muren, modelArrayDifference[index], modelArrayDifference, index, modelArrayIndexes)
          const currentModelArray = currentState.muren.find(muur => muur.uuid == modelArrayDifference[index].uuid).modelArray
          const oldModelArray = oldState.muren.find(muur => muur.uuid == modelArrayDifference[index].uuid).modelArray
          for (let k = 0; k < 3; k++) {
            if (currentModelArray[k] && !oldModelArray[k]) {
              //remove door/window
              console.log("REMOVE", currentModelArray[k], oldModelArray[k])

              const muurInstance = muurInstances[indexes[i]]
              console.log("muurInstance", muurInstance)
              context.commit('removeElement', muurInstance.wallButtonIndex[k].modelBox)
            }

            if (!currentModelArray[k] && oldModelArray[k]) {
              //add door/window
              console.log("ADD", currentModelArray[k], oldModelArray[k])

              const muurInstance = muurInstances[indexes[i]]
              const components = context.getters.getInWallComponents
              const steellookComponents = context.getters.getSteellookComponents
              console.log(components, oldModelArray[k], components.find(component => component.code === oldModelArray[k].code))
              let modelComponent = components.find(component => component.code === oldModelArray[k].code)
              if (!modelComponent) {
                modelComponent = steellookComponents.find(component => component.code === oldModelArray[k].code)
              }

              console.log("klopt deze? ", components, steellookComponents, modelComponent, oldModelArray[k].code)


              let component

              const model = modelComponent//context.rootState.models.selectedWallComponent
              const modelSettings = { option: oldModelArray[k].option, color: oldModelArray[k].color }//context.rootState.models.selectedWallComponentSettings
              // console.log(modelSettings)
              // console.log(context)
              const { x, y, z, xo, yo, zo } = model.dimensions

              // console.log(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelSettings.color == 0 ? model.code : modelSettings.color.code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`)
              // console.log(`${model.code}${model.options.length > 0 ? model.options[0].subCode : ''}.glb`)
              console.log(model, modelSettings, `${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${model.code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`)
              gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${model.code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`, (glb) => {
                gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${model.code}${model.options.length > 0 ? model.options[0].subCode : ''}.glb`, (glb_map) => {
                  glb.scene.scale.multiplyScalar(1000)
                  component = glb.scene
                  glb_map.scene.scale.multiplyScalar(1000)
                  context.commit('updateWallHoles', {
                    wall: muurInstance.plusIconGroupDisabled[2 * k + oldModelArray[k].side],
                    holes: [
                      { x: x, y: y, z: z, xo: xo, yo: yo, zo: zo }
                    ],
                    model: component,
                    map: glb_map.scene,
                    component: model,
                    settings: modelSettings,
                  })
                })
              })








            }
          }
        }
      }




      /*if (indexes.length === 1) {
        console.log(indexes[0], oldState.muren[indexes[0]])
        context.dispatch('updateWall', { index: indexes[0], settings: oldState.muren[indexes[0]] })
    
        context.dispatch('updateWallWidth', { index: indexes[0], settings: oldState.muren[indexes[0]] })
        context.dispatch('updateWallPosition', { index: indexes[0], settings: oldState.muren[indexes[0]] })
      } else if (indexes.length > 1) {
        for(let i=0; i<indexes.length; i++) {
          console.log("loop", indexes[i], oldState.muren[indexes[i]])
          context.dispatch('updateWall', { index: indexes[i], settings: oldState.muren[indexes[i]] })
    
          context.dispatch('updateWallWidth', { index: indexes[i], settings: oldState.muren[indexes[i]] })
          context.dispatch('updateWallPosition', { index: indexes[i], settings: oldState.muren[indexes[i]] })
        }

        //context.dispatch('updateFrame',{x: oldState.afmetingen.breedte, y: oldState.afmetingen.hoogte, z: oldState.afmetingen.diepte, ringBalkGrootte: {dy: oldState.afmetingen.staanderDikte, dz: oldState.afmetingen.staanderDikte}, staanderGrootte: {dx: oldState.afmetingen.staanderDikte, dz: oldState.afmetingen.staanderDikte}, schoor: oldState.afmetingen.schoor})
      }*/

    } else if (difference) {
      context.commit('setConcreteFoundation', oldState.fundering)
    }

    context.commit('updatePlusIcons')

    context.commit('updateIntersectingObjects')
    context.commit('setOldState', oldState)
  },

  back(context) {

    if (context.state.history.backList.length > 0) {

      const oldState = context.state.history.back()
      const currentState = JSON.parse(JSON.stringify(context.state.config))

      context.dispatch('changeConfig', { oldState, currentState })
    }
  },

  forward(context) {
    if (context.state.history.forwardList.length > 0) {

      const oldState = context.state.history.forward()
      const currentState = JSON.parse(JSON.stringify(context.state.config))

      context.dispatch('changeConfig', { oldState, currentState })
    }
  },

  updateConfig(context) {
    context.commit('updateConfig')
  },

  updateConfigDefault(context) {
    context.commit('updateConfigDefault')
  },

  async createShareLink(context) {

    console.log("SHARE DATA", context.rootState.config.config)
    const wallArray = []
    for (let i = 0; i < context.getters.getMuurInstances.length; i++) {
      wallArray.push(context.getters.getMuurInstances[i].settings)
    }
    context.commit('setWallArrayConfig', wallArray)
    const id = await saveModelConfig(context.rootState.config.config)



    function fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      const currentIframeHref = new URL(document.location.href);
      const urlOrigin = currentIframeHref.origin;
      const urlFilePath = decodeURIComponent(currentIframeHref.pathname);
      console.log(currentIframeHref, urlOrigin, urlFilePath)
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        navigator.clipboard.writeText(text)
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    }

    let link = ''
    if (context.getters.getConfiguratorURL) {
      link = 'https://' + context.getters.getConfiguratorURL + context.getters.getUserToken + '#&configId=' + id.data
    } else {
      link = import.meta.env.VITE_VUE_APP_SERVER + '/cfg/' + '?&configId=' + id.data
    }
    fallbackCopyTextToClipboard(link);
    /*if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(import.meta.env.VITE_VUE_APP_SERVER + '/cfg/' + id.data);
    } else {
      
    }*/


    context.dispatch('showSucces', 'Deel link gekopieerd naar uw clipboard')

  },

  async saveModelConfig(context) {
    console.log("DATA SENT:", context.rootState.config.config)
    const wallArray = []
    for (let i = 0; i < context.getters.getMuurInstances.length; i++) {
      wallArray.push(context.getters.getMuurInstances[i].settings)
    }
    context.commit('setWallArrayConfig', wallArray)
    const id = await saveModelConfig(context.rootState.config.config)

    let link = ''
    if (context.getters.getConfiguratorURL) {
      link = context.getters.getConfiguratorURL + context.getters.getUserToken + '#&configId=' + id.data
    } else {
      link = import.meta.env.VITE_VUE_APP_SERVER + '/cfg/' + id.data
    }

    return link
  },

  async getModelConfig(context, id) {
    const config = await getModelConfig(id)
    console.log(config)
    context.getters.getModelTypes
    console.log(config, context.getters.getModelTypes)
    const model = context.getters.getModelTypes.filter(model => model.id === config.data.modelId)[0]

    let sameDepth, sameWidth, diepte, breedte, isMaatwerk

    sameDepth = model.defaultSizes.find(size => size.diepte == config.data.afmetingen.diepte)
    if (sameDepth) {
      diepte = sameDepth.diepte
    } else {
      isMaatwerk = true
      const sortedDepths = model.defaultSizes.map(size => size).sort((a, b) => b.diepte - a.diepte)
      sameDepth = sortedDepths.find(size => size.diepte <= config.data.afmetingen.diepte)
      sameDepth = sameDepth ? sameDepth : sortedDepths[0]
      diepte = config.data.afmetingen.diepte
    }

    sameWidth = sameDepth.breedtes.find(x => x.breedte == config.data.afmetingen.breedte)
    if (sameWidth) {
      breedte = sameWidth.breedte
    } else {
      isMaatwerk = true
      const sortedWidths = sameDepth.breedtes.map(breedte => breedte).sort((a, b) => b.breedte - a.breedte)
      sameWidth = sortedWidths.find(breedte => breedte.breedte <= config.data.afmetingen.breedte)
      if (!sameWidth) {
        sameWidth = sortedWidths.sort((a, b) => a.breedte - b.breedte)[0]
      }
      breedte = config.data.afmetingen.breedte
    }

    // get all frontwalls and sort them by z position (left to right)
    const sortedBackWalls =config.data.muren.filter(muur => muur.rotation === Math.PI*0.5).sort((a, b) => a.position.z - b.position.z)
    // check if wallDivision is valid
    // const wallDivisionValid = sameWidth.wallDivision.every((division, index) => division.width === sortedBackWalls[index].width) && sameWidth.wallDivision.length === sortedBackWalls.length

    let wallDivision
    // if (!wallDivisionValid) {
      // wallDivision not valid, create new division from saved muren
      wallDivision = sortedBackWalls.map((frontWall) => {
        return {
          width: frontWall.width,
          bergingEnabled: false
        }
      })
    // } else {
    //   // wallDivision is valid, use saved wallDivision
    //   wallDivision = sameWidth.wallDivision
    // }
    
    console.log(sameDepth, sameWidth)
    const modelConfig = {
      diepte,
      hoogte: sameDepth.hoogtePaal,
      breedte,
      bergingType: sameWidth.bergingType,
      artikelNummer: isMaatwerk ? null : sameWidth.artikelNummer,
      wallDivision: wallDivision,
      maximaleMuurBreedte: sameDepth.maximaleMuurBreedte,
      staanderDikte: sameDepth.staanderDikte,
      poer: sameDepth.poer,
      schoor: sameDepth.schoor,
      ringBalk: sameDepth.ringBalk,
      extraRingBalk: sameDepth.extraRingBalk,
      ringbalkTussenPaal: sameDepth.ringbalkTussenPaal,
      gordingBalk: sameDepth.gordingBalk,
      ringBalkGording: sameDepth.ringBalkGording,
      overstekKlos: sameDepth.overstekKlos,
      boeideel: sameDepth.boeideel,
      trim: sameDepth.trim,
      dak: sameDepth.dak,
      dakgoot: sameDepth.dakgoot,
      wall: sameDepth.wall,
      omlijstingPalen: sameDepth.omlijstingPalen,
      veranda: sameDepth.veranda,
    } // dit is hetzelfde als het riedeltje op de models pagina
    console.log(modelConfig)
    config.data.afmetingen = modelConfig
    state.maatwerkWidth = breedte
    state.maatwerkDepth = diepte
    if (config.data.overstek) {
      state.overstekConfig = JSON.parse(JSON.stringify(config.data.overstek))
      delete config.data.overstek
    }

    console.log("retourlink data: ", config.data)

    context.commit('setIsRetourLink', true)
    context.commit('setSavedModelConfig', config.data)
    context.commit('setModelType', model)
    await context.dispatch('getTextures')
    await context.dispatch('getWallColors')
    context.dispatch('setWallFinishes')

    // set insulation
    await context.dispatch('getInsulation')
    const insulations = context.getters.getInsulation
    const selectedInsulation = insulations.find(i => i.id === config.data.insulation)
    console.log("selectedInsulation", selectedInsulation) 
    context.commit('setSelectedInsulation', selectedInsulation ? selectedInsulation.code : '')
  },

  async setConfigToCurrent(context) {
    context.commit('setConfigToCurrent')
  },

  async initializeHistory(context) {
    context.commit('initializeHistory')
  },

  async getWallColors(context) {
    // config graphQL data
    const graphqlQuery = {
      query: `
        {
          getTextures {
            id
            name
            url
            textureType
            plankType
            color
            locations
            scalingX
            scalingY
            emissive
            emissiveColor
            metalness
          }
          getPlankTypes {
            id
            name
            image
            connection
          }
          getIsolatieTypes {
            id
            name
            description
          }
          getMenuColors {
            id
            name
            hexCode
            open
          }
          getTextureTypes {
            id
            name
          }
        }
      `
    }

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch texture files.')
    }
    console.log('response',response.data)
    const textures = response.data.getTextures
    const planks = response.data.getPlankTypes
    const isolatietypes = response.data.getIsolatieTypes
    const colors = response.data.getMenuColors

    context.commit('setPlankTypes', planks)
    context.commit('setMenuColors', colors)
    context.commit('setIsolatieTypes', isolatietypes)
    context.commit('setIsolatieTypeDefault', isolatietypes[0])

    const wallColors = { buiten: [], binnen: [] }
    for (let i = 0; i < planks.length; i++) {
      const plank = planks[i]
      const typeBuiten = {
        type: plank.name,
        typeImage: plank.image,
        connection: plank.connection,
        colors: []
      }
      const typeBinnen = {
        type: plank.name,
        typeImage: plank.image,
        connection: plank.connection,
        colors: []
      }
      for (let j = 0; j < textures.length; j++) {
        const texture = textures[j]
        if (texture.plankType === plank.id && texture.textureType === "62cd857a7c20bc17b85af2cd") {
          if (texture.locations.includes('62d68223d0808b4d7537d39f')) {
            typeBuiten.colors.push({
              name: colors.filter(color => color.id === texture.color)[0].name,
              url: texture.url,
              previewURL: texture.url,
              hex: colors.filter(color => color.id === texture.color)[0].hexCode,
              id: colors.filter(color => color.id === texture.color)[0].id,
              plankType: plank.id,
              open: colors.filter(color => color.id === texture.color)[0].open
            })
          }
          if (texture.locations.includes('62d6824bd0808b4d7537d3a0')) {
            typeBinnen.colors.push({
              name: colors.filter(color => color.id === texture.color)[0].name,
              url: texture.url,
              previewURL: texture.url,
              hex: colors.filter(color => color.id === texture.color)[0].hexCode,
              id: colors.filter(color => color.id === texture.color)[0].id,
              plankType: plank.id,
              open: colors.filter(color => color.id === texture.color)[0].open
            })
          }
        }
      }

      if (typeBuiten.colors.length > 0) {
        wallColors.buiten.push(typeBuiten)
      }
      if (typeBinnen.colors.length > 0) {
        wallColors.binnen.push(typeBinnen)
      }

    }

    console.log(wallColors.buiten)

    context.commit('setWallColors', wallColors)

    let frameTextures = []
    let boeidelenTextures = []
    let hoekprofielTextures = []

    //set frame and boeidelen textures
    for (let i = 0; i < textures.length; i++) {
      //if frametexture:
      if (textures[i].textureType === "62cd85647c20bc17b85af2cb") {
        frameTextures.push({
          name: colors.filter(color => color.id === textures[i].color)[0].name,
          url: textures[i].url,
          previewURL: textures[i].url,
          id: textures[i].id,
          colorId: colors.filter(color => color.id === textures[i].color)[0].id,
        })

        //if boeidelentexture:
      } else if (textures[i].textureType === "62cd85727c20bc17b85af2cc") {
        boeidelenTextures.push({
          name: colors.filter(color => color.id === textures[i].color)[0].name,
          url: textures[i].url,
          previewURL: textures[i].url,
          id: textures[i].id,
          colorId: colors.filter(color => color.id === textures[i].color)[0].id,
        })

        //if hoekprofieltexture:
      } else if (textures[i].textureType === "643e571d99fec6cfb5811551") {
        hoekprofielTextures.push({
          name: colors.filter(color => color.id === textures[i].color)[0].name,
          url: textures[i].url,
          previewURL: textures[i].url,
          id: textures[i].id,
          colorId: colors.filter(color => color.id === textures[i].color)[0].id,
        })
      }
    }

    context.commit('setFrameColors', frameTextures)
    context.commit('setBoeideelColors', boeidelenTextures)
    context.commit('setHoekprofielColors', hoekprofielTextures)

    console.log(context)
  },

  setWallFinishes(context) {

    // set needed constants
    const textures = context.getters.getTextures
    const menuColors = context.getters.getMenuColors

    //create empty object to fill with the available wall finishes
    const wallFinishes = {}
    console.log(textures)
    //sort all textures
    Object.keys(context.state.config.wallFinishes).forEach(key => {
      const wallFinish = context.state.config.wallFinishes[key]
      wallFinish.sort((a, b) => {
        const textureA = textures.find((texture) => texture.id === a).order
        const textureB = textures.find((texture) => texture.id === b).order
        return textureA - textureB
      })
    })


    // loop through all wall finishes types (e.g. buiten, binnen, links, rechts, en hetzelfde voor de topgevels)
    Object.keys(context.state.config.wallFinishes).forEach(key => {

      // set current wall finish type
      const wallFinish = context.state.config.wallFinishes[key]
      wallFinishes[key] = []

      // loop through all wall finishes of the current type
      wallFinish.forEach((textureId) => {
        const texture = textures.find((texture) => texture.id === textureId)
        const plankType = context.getters.getPlankTypes.find((plankType) => plankType.id === texture.plankType)

        // if planktype already in array, add color to planktype
        if (wallFinishes[key].find((wallFinish) => wallFinish.id === plankType.id)) {
          const newPlankType = wallFinishes[key].find((wallFinish) => wallFinish.id === plankType.id)
          newPlankType.colors.push({
            name: menuColors.filter(color => color.id === texture.color)[0].name,
            url: texture.url,
            previewURL: texture.url,
            hex: menuColors.filter(color => color.id === texture.color)[0].hexCode,
            id: menuColors.filter(color => color.id === texture.color)[0].id,
            plankType: plankType.id,
            open: menuColors.filter(color => color.id === texture.color)[0].open
          })
          // else create new planktype and add color
        } else {
          const newPlankType = {
            id: plankType.id,
            type: plankType.name,
            typeImage: plankType.image,
            connection: plankType.connection,
            colors: []
          }
          newPlankType.colors.push({
            name: menuColors.filter(color => color.id === texture.color)[0].name,
            url: texture.url,
            previewURL: texture.url,
            hex: menuColors.filter(color => color.id === texture.color)[0].hexCode,
            id: menuColors.filter(color => color.id === texture.color)[0].id,
            plankType: plankType.id,
            open: menuColors.filter(color => color.id === texture.color)[0].open
          })
          wallFinishes[key].push(newPlankType)
        }

      })
    })
    console.log("wallFinishes", wallFinishes)
    // set to store
    context.commit('setAvailableWallFinishes', wallFinishes)
  },

  async updateDWTables(context, { succesText, tableData, menuName }) {

    let host = ''
    switch (location.hostname) {
      case 'localhost':
      case 'tw-js-dev.mtest.nl':
      case 'tw-v01-js-dev.mtest.nl':
        host = "Dev";
        break;
      case 'tw-js-tes.mtest.nl':
      case 'tw-v02-js-tes.mtest.nl':
        host = "Tes";
        break;
      case 'tw-js-pro.mtest.nl':
      case 'standaardmaten.toplawood.nl':
      case 'toplawood.moogue.com':
        host = "Pro";
        break;
    }
    let host_LC = host.toLowerCase()

    updateDW();

    async function updateDW() {

      return new Promise((resolve) => {
        const mainUrl = `https://scripts-tw-${host_LC}.mtest.nl/api/DWAliasTW_${host}/`

        //login
        $.ajax({
          url: mainUrl + 'login',
          type: "POST", dataType: "json",
          data: { username: "Api", password: "" },
          contentType: "application/json",
          success: (data) => {
            console.log('dwLogin')
            console.log(data.sessionId)

            let succesTextText = JSON.stringify(succesText)
            console.log('succesTextText')
            console.log(succesTextText)
            console.log(succesText)

            //start new specification          
            const sessionId = data.sessionId
            $.ajax({
              url: mainUrl + 'specifications/DW17_ToplaWoodGetDataMongoDB_V01', type: "POST", dataType: "json", contentType: "application/json", headers: { 'Session-Id': sessionId },
              success: (data) => {
                const specId = data.specificationId
                console.log("specId:", specId)

                //get specification constants
                $.ajax({
                  url: mainUrl + 'specifications/' + specId + '/constants', type: "GET", dataType: "json", contentType: "application/json", headers: { 'Session-Id': sessionId },
                  success: (spec_constants) => {
                    const updateDwConstant = spec_constants.filter(updateDwConstant => updateDwConstant.name === "DWConstantupdatedData")[0]


                    //settings constant value
                    $.ajax({
                      url: mainUrl + 'specifications/' + specId + '/constants/updatedData', type: "POST", dataType: "json", contentType: "application/json", headers: { 'Session-Id': sessionId, 'If-Match': updateDwConstant.eTag, 'accept': 'application/json;odata.metadata=minimal;odata.streaming=true' }, data: JSON.stringify({
                        "constantValue": menuName + '|' + JSON.stringify(tableData)
                      }),
                      success: () => {


                        //get specification actions
                        $.ajax({
                          url: mainUrl + 'specifications/' + specId + '/actions', type: "GET", dataType: "json", contentType: "application/json", headers: { 'Session-Id': sessionId },
                          success: (data) => {


                            //invoke specification transition
                            console.log(data)
                            const transition = data.filter(transition => transition.name === "ToAutoFromApiFrontend")[0]
                            console.log(transition)
                            $.ajax({
                              url: mainUrl + 'specifications/' + specId + '/transitions/' + transition.name, type: "POST", contentType: "application/json", headers: { 'Session-Id': sessionId, 'If-Match': transition.eTag, 'accept': 'application/json;odata.metadata=minimal;odata.streaming=true' },
                              success: () => {


                                //do something
                                $.ajax({
                                  url: `https://scripts-tw-${host_LC}.mtest.nl/logout`, type: "POST", contentType: "application/json", headers: { 'Session-Id': sessionId, 'If-Match': transition.eTag },
                                  success: () => {

                                    console.log("Driveworks tables succesfully updated!")
                                    context.dispatch('showSucces', succesText)
                                    resolve()
                                  },
                                  error: (error => { console.log("logout failed", error) })
                                });
                              },
                              error: (error => { console.log("invoking transition failed", error) })
                            });
                          },
                          error: (error => { console.log("getting actions failed", error) })
                        });
                      },
                      error: (error => { console.log("settings constant value failed", error) })
                    });
                  },
                  error: (error => { console.log("getting constants failed", error) })
                });
              },
              error: (error => { console.log("creating spec failed", error) })
            });
          },
          error: (error => { console.log("login failed", error) })
        });
      })
    }
  },

  async getProductList(context, data) {
    const token = data.token

    //extract data from walls
    const muurInstances = context.getters.getMuurInstances
    console.log("muurInstances", muurInstances)
    let wallArray = []
    let doorsWindowsArray = []
    let steellookArray = []
    for (let i = 0; i < muurInstances.length; i++) {
      const wall = muurInstances[i]
      if (wall.isSteellook || wall.isSlidingWall) {
        //steellook
        if (steellookArray.filter(component => component.code === wall.modelCode)[0]) {
          //increment
          steellookArray.filter(component => component.code === wall.modelCode)[0].quantity++
        } else {
          steellookArray.push({
            code: wall.modelCode,
            quantity: 1,
            quantityInWandGeplaatst: 0,
            hasBetonBand: (wall.settings.hasBetonBand !== undefined && wall.settings.hasBetonBand !== null) ? wall.settings.hasBetonBand : true
            //changed to true because default is true // new edit with null check for TASK-1568
          })
        }
      }
      if ((!wall.isSteellook && !wall.isSlidingWall) || wall.isBorstwering) {
        //wall

        if ((wall.settings.binnenMuur && !wall.settings.binnenMuurTexture.open) || (wall.settings.buitenMuur && !wall.settings.buitenMuurTexture.open)) {
          const wallObject = {}
          const isTussenMuur = wall.settings.tussenMuur

          const buitenSelector = (isTussenMuur ? "tussenWandLinks" : "wandBuiten")
          const binnenSelector = (isTussenMuur ? "tussenWandRechts" : "wandBinnen")

          let buitenHeight = context.getters.getConfig.afmetingen.wall.height.find(height => height.position === buitenSelector).value
          console.log("buitenHeight", buitenHeight, context.getters.getConfig.afmetingen.wall)
          let binnenHeight = context.getters.getConfig.afmetingen.wall.height.find(height => height.position === binnenSelector).value

          if(context.getters.getConfig.typeId === "629a060f2fbe252c87361bde") {
            if(!wall.alignsXAxis && !wall.alignedRotation) {
              buitenHeight = 1520
              binnenHeight = 1520
            }
          }

          if (wall.isBorstwering) {
            buitenHeight = 700 //wall.borstweringComponent.dimensions.yo
            binnenHeight = 700 //wall.borstweringComponent.dimensions.yo
          }

          let buitenWidth = wall.set.mx
          if (context.getters.getConfig.afmetingen.wall.position === "buiten" && !isTussenMuur) {
            if (wall.hoekProfielConfig.shouldLeft) {
              buitenWidth += 104 - 15.5
            } else {
              buitenWidth += wall.staanderGrootte.dx / 2
            }

            if (wall.hoekProfielConfig.shouldRight) {
              buitenWidth += 104 - 15.5
            } else {
              buitenWidth += wall.staanderGrootte.dx / 2
            }
          }
          console.log(buitenWidth)

          // console.log('wall.heeftIsolatie',wall.heeftIsolatie)
          // console.log('wall.isolatieId',wall.isolatieId)
          if (wall.settings.binnenMuur) {
            wallObject.binnen = {
              plankTypeId: wall.settings.binnenMuurTexture.plankType,
              colorId: wall.settings.binnenMuurTexture.id,
              length: wall.set.mx,
              height: binnenHeight,
              isolatieId: wall.isolatieId
            }
            // if(wall.heeftIsolatie) wallObject.binnen.isolatieId = wall.isolatieId;
          } else {
            wallObject.binnen = {
              plankTypeId: '',
              colorId: '',
              length: wall.set.mx,
              height: binnenHeight,
              isolatieId: wall.isolatieId
            }
            // if(wall.heeftIsolatie) wallObject.binnen.isolatieId = wall.isolatieId;
          }

          if (wall.settings.buitenMuur) {
            wallObject.buiten = {
              plankTypeId: wall.settings.buitenMuurTexture.plankType,
              colorId: wall.settings.buitenMuurTexture.id,
              length: buitenWidth,
              height: buitenHeight,
              isolatieId: wall.isolatieId
            }
            // if(wall.heeftIsolatie) wallObject.buiten.isolatieId = wall.isolatieId;
          } else {
            wallObject.buiten = {
              plankTypeId: '',
              colorId: '',
              length: buitenWidth,
              height: buitenHeight,
              isolatieId: wall.isolatieId
            }
            // if(wall.heeftIsolatie) wallObject.buiten.isolatieId = wall.isolatieId;
          }
          wallArray.push(wallObject)


          console.log(wall.wallButtonIndex)
          for (let i = 0; i < 3; i++) {
            if (wall.wallButtonIndex[i].enabled) {
              if (wall.wallButtonIndex[i].component.category === "Steellook") {
                console.log(wall.wallButtonIndex, i)
                if (steellookArray.filter(component => component.code === (
                  wall.wallButtonIndex[i].settings.lastCode ? wall.wallButtonIndex[i].settings.lastCode : 
                  wall.wallButtonIndex[i].component.code))[0]) {
                  //increment
                  steellookArray.filter(component => component.code === (
                    wall.wallButtonIndex[i].settings.lastCode ? wall.wallButtonIndex[i].settings.lastCode :
                    wall.wallButtonIndex[i].component.code))[0].quantityInWandGeplaatst++
                } else {
                  console.log(wall.wallButtonIndex[i])
                  steellookArray.push({
                    code: wall.wallButtonIndex[i].settings.lastCode ? wall.wallButtonIndex[i].settings.lastCode : 
                      wall.wallButtonIndex[i].component.code,
                    quantity: 0,
                    quantityInWandGeplaatst: 1,
                    hasBetonBand: (wall.settings.hasBetonBand !== undefined && wall.settings.hasBetonBand !== null) ? wall.settings.hasBetonBand : true 
                    //changed to true because default is true // new edit with null check for TASK-1568
                  })
                }
              } else {
                // console.log('wall.wallButtonIndex[i]',wall.wallButtonIndex[i])
                if (doorsWindowsArray.filter(component => component.code === (
                  wall.wallButtonIndex[i].settings.lastCode ? wall.wallButtonIndex[i].settings.lastCode :
                  wall.wallButtonIndex[i].component.colors.length > 0 ? wall.wallButtonIndex[i].settings.color.code : 
                  wall.wallButtonIndex[i].component.code))[0]) {
                  //increment
                  doorsWindowsArray.filter(
                    component => component.code === (
                      wall.wallButtonIndex[i].settings.lastCode ? wall.wallButtonIndex[i].settings.lastCode :
                      wall.wallButtonIndex[i].component.colors.length > 0 ? wall.wallButtonIndex[i].settings.color.code : 
                      wall.wallButtonIndex[i].component.code))[0].quantity++
                } else {
                  console.log('else wall.wallButtonIndex[i]',wall.wallButtonIndex[i])
                  console.log(wall.wallButtonIndex[i].component.colors, (wall.wallButtonIndex[i].component.colors.length > 0))
                  doorsWindowsArray.push({
                    code: wall.wallButtonIndex[i].settings.lastCode ? wall.wallButtonIndex[i].settings.lastCode : 
                      wall.wallButtonIndex[i].component.colors.length > 0 ? wall.wallButtonIndex[i].settings.color.code : 
                      wall.wallButtonIndex[i].component.code,
                    quantity: 1,
                  })
                }
              }
            }
          }


        }
      }
    }
    console.log(state, context)

    const topgevelArray = []
    console.log(state.config.topgevels)
    if (state.config.typeId === "629a060f2fbe252c87361bdd" || state.config.typeId === "629a060f2fbe252c87361bde") {
      for (let i = 0; i < state.config.topgevels.length; i++) {
        if (state.config.topgevels[i].buitenMuur || state.config.topgevels[i].binnenMuur) {
          topgevelArray.push({
            binnen: {
              plankTypeId: state.config.topgevels[i].binnenMuur ? state.config.topgevels[i].binnenMuurTexture.plankType : "",
              colorId: state.config.topgevels[i].binnenMuur ? state.config.topgevels[i].binnenMuurTexture.id : "",
              isolatieId: state.config.topgevels[i].isolatieId
            },
            length: Math.round(state.topgevelSize.length),
            height: Math.round(state.topgevelSize.height),
            buiten: {
              plankTypeId: state.config.topgevels[i].buitenMuur ? state.config.topgevels[i].buitenMuurTexture.plankType : "",
              colorId: state.config.topgevels[i].buitenMuur ? state.config.topgevels[i].buitenMuurTexture.id : "",
              isolatieId: state.config.topgevels[i].isolatieId
            },
            // isolatieId: state.config.topgevels[i].isolatieId
          })
        }
      }
    }

    const optionArray = []
    for (let i = 0; i < state.config.opties.length; i++) {
      if (state.config.opties[i].aantal > 0) {
        optionArray.push({
          code: state.config.opties[i].code,
          quantity: state.config.opties[i].aantal
        })
      }
    }
    // add lichtstraten to optionArray
    for (const lichtstraat of state.config.lichtstraten) {
      const matchingCodeIndex = optionArray.findIndex(option => option.code === lichtstraat.code)
      if (matchingCodeIndex !== -1) {
        optionArray[matchingCodeIndex].quantity++
      } else {
        optionArray.push({
          code: lichtstraat.code,
          quantity: 1
        })
      }
    }

    const staanders = context.getters.getFrame.staanders.staanderInfoArray.map((x) => x);
    for (let i = 0; i < muurInstances.length; i++) {
      if (muurInstances[i].wallStaander) {
        staanders.push(muurInstances[i].wallStaanderInfo)
      }
    }

    const staandersArray = []
    for (let i = 0; i < staanders.length; i++) {
      if (staandersArray.filter(staander => staander.width === staanders[i].x && staander.height === staanders[i].y).length) {
        //increment
        staandersArray.filter(staander => staander.width === staanders[i].x && staander.height === staanders[i].y)[0].quantity++
      } else {
        //add to array
        staandersArray.push({
          width: staanders[i].x,
          height: staanders[i].y,
          quantity: 1
        })
      }
    }
    // convert staander height 1523 to 1520
    staandersArray.forEach(staander => {
      if(staander.height === 1523) {
        staander.height = 1520
      }
    })

    const gootEnabled = state.config.afmetingen.dakgoot.enabled
    // variable t.b.v. dakplaat breedte voor
    let dakPlaatBreedteVoor = Math.round(state.dakPlaatSize.dakPlaatBreedteVoor)
    // console.log('dakPlaatBreedteVoor', dakPlaatBreedteVoor)
    // console.log('state.config.afmetingen.dakgoot.threePipesWidth', state.config.afmetingen.dakgoot.threePipesWidth)
    const dakgoot = {
      front: {
        left: gootEnabled ? state.config.dakgoot.front.enabled ? state.config.dakgoot.front.left : false : false,
        right: gootEnabled ? state.config.dakgoot.front.enabled ? state.config.dakgoot.front.right : false : false,
        middle: gootEnabled ? state.config.dakgoot.front.enabled ? dakPlaatBreedteVoor >= state.config.afmetingen.dakgoot.threePipesWidth : false : false // aangepast naar dakplaatbreedte voor ipv staanderbreedte
      },
      back: {
        left: gootEnabled ? state.config.dakgoot.back.enabled ? state.config.dakgoot.back.left : false : false,
        right: gootEnabled ? state.config.dakgoot.back.enabled ? state.config.dakgoot.back.right : false : false,
        middle: gootEnabled ? state.config.dakgoot.back.enabled ? dakPlaatBreedteVoor >= state.config.afmetingen.dakgoot.threePipesWidth : false : false // aangepast naar dakplaatbreedte voor ipv staanderbreedte
      }
    }

    console.log("doorsWindowsArray", doorsWindowsArray)
    console.log(state.config.afmetingen)
    console.log(state.config.boeideelKleur)
    const dwData = {
      modelId: state.config.modelId,
      modelArtikelNr: state.config.afmetingen.artikelNummer,
      modelWidth: state.config.afmetingen.breedte,
      modelDepth: state.config.afmetingen.diepte,
      modelHeight: state.config.afmetingen.hoogte,
      modelAngle: state.config.afmetingen.dak.helling,
      dakPlaatBreedteVoor: Math.round(state.dakPlaatSize.dakPlaatBreedteVoor),
      dakplaatLengteVoor: Math.round(state.dakPlaatSize.dakplaatLengteVoor),
      dakPlaatBreedteAchter: Math.round(state.dakPlaatSize.dakPlaatBreedteAchter),
      dakplaatLengteAchter: Math.round(state.dakPlaatSize.dakplaatLengteAchter),
      frameTextureId: state.config.frameKleur.id,
      boeideelTextureId: state.config.boeideelKleur.id,
      hoekprofielTextureId: state.config.hoekprofielKleur.id,
      roofFinishTextureId: state.config.selectedDakAfwerking.id,
      daktrimTextureId: state.config.trimColor.id,
      walls: wallArray,
      topgevels: topgevelArray,
      doorsWindows: doorsWindowsArray,
      steellooks: steellookArray,
      options: optionArray,
      foundation: {
        enabled: state.config.fundering,
        width: state.config.afmetingen.poer.z,
        length: state.config.afmetingen.poer.y,
      },
      staanders: staandersArray,
      insulation: context.getters.getSelectedInsulation,
      dakgoot: dakgoot
    }

    console.log(dwData)
    console.log(`{"dwData": ${JSON.stringify(dwData)}}`)
    const graphqlQuery = {
      query: `
        mutation {
          getProductList (token: "${token}", dwData: ${JSON.stringify(dwData).replace(/"([^"]+)":/g, '$1:')})
          {
            loggedIn
            sessionData {
              sessionId
              specId
            }
            products {
              ID
              Code
              Quantity
              DescriptionShort
              Price
            }
          }
        }
      `
    }
    console.log(graphqlQuery)
    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch product list.')
    }

    console.log(response.data.getProductList)

    context.commit('setProductList', response.data.getProductList.products)
    context.commit('setTokenLoggedIn', response.data.getProductList.loggedIn)
    context.commit('setDWSessionData', response.data.getProductList.sessionData)
    //console.log(response.data.tokenLogin)
  },

  async getIsPublicUser(context) {
    const graphqlQuery = {
      query: `
        mutation {
          getIsPublicUser (token: "${context.getters.getUserToken}")
        }
      `
    }

    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch components.')
    }

    return response.data.getIsPublicUser
  },

  async dwLogout(context) {
    const sessionData = context.state.dwSessionData
    if (sessionData == undefined) {
      return
    }

    context.commit('setDWSessionData', {})

    context.commit('setProductList', [])
    context.commit('setTokenLoggedIn', null)

    const graphqlQuery = {
      query: `
        mutation {
          dwLogout (sessionData: {
            sessionId: "${sessionData.sessionId}"
            specId: ${sessionData.specId}
          })
        }
      `
    }
    console.log(graphqlQuery)
    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery)

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to fetch components.')
    }
  },

  async getProductListPrice(context, data) {
  try {
    const token = data.token;
    const sessionData = context.state.dwSessionData;
    let inputData = data.inputData;
    if (inputData.extraQuestions) {
      console.log('1. Initial extraQuestions:', inputData.extraQuestions);
      
      const extraQuestionsWithText = Object.entries(inputData.extraQuestions).map(([questionId, answerId]) => {
        const matchingQuestion = context.rootState.cms.menuUISettings[0].extraQuestions.find(q => q.id === questionId);
        const matchingAnswer = matchingQuestion?.options?.find(opt => opt.id === answerId);
        
        return {
          questionId: String(questionId),    // Ensure it's a string
          answerId: String(answerId),        // Ensure it's a string
          questionText: matchingQuestion?.text || '',
          answerText: matchingAnswer?.text || ''
        };
      });
    
      console.log('2. Questions with text:', extraQuestionsWithText);
      context.commit('setExtraQuestionsWithText', extraQuestionsWithText);
    
      // Format for GraphQL query according to inputDataExtraQuestions schema
      inputData.extraQuestions = extraQuestionsWithText;
    }
    
    console.log('3. Final inputData:', inputData);
    
    const graphqlQuery = {
      query: `
        mutation {
          getProductListPrice (
            token: "${token}",
            sessionData: {
              sessionId: "${sessionData.sessionId}",
              specId: ${sessionData.specId}
            },
            inputData: {
              extraQuestions: [
                ${inputData.extraQuestions.map(q => `{
                  questionId: "${q.questionId}",
                  answerId: "${q.answerId}",
                  questionText: "${q.questionText}",
                  answerText: "${q.answerText}"
                }`).join(',')}
              ]
            }
          ) {
            loggedIn
            sessionData {
              sessionId
              specId
            }
            products {
              ID
              Code
              Quantity
              DescriptionShort
              Price
            }
            totalPriceBTW
            totalPriceBTWIncl
            totalPriceBTWExcl
          }
        }
      `
    };
    
    console.log('4. GraphQL Query:', graphqlQuery.query);

    const response = await graphql.fetchGraphQL(context, graphqlQuery);

    if (response.errors) {
      errorHandeling.throwError(context, response, 'Ophalen van prijzen mislukt.');
      return;
    }

    context.commit('setProductList', response.data.getProductListPrice.products);
    context.commit('setProductListPrice', { 
      btw: response.data.getProductListPrice.totalPriceBTW, 
      excl: response.data.getProductListPrice.totalPriceBTWExcl, 
      incl: response.data.getProductListPrice.totalPriceBTWIncl 
    });
    context.commit('setTokenLoggedIn', response.data.getProductListPrice.loggedIn);
  } catch (error) {
    errorHandeling.throwError(context, error, 'Ophalen van prijzen mislukt.');
  }
},


  async getQuotation(context, data) {
    const token = data.token
    const sessionData = context.state.dwSessionData
    const inputData = data.inputData
    const imageNameArray = context.getters.getImageNameArray
    const retourlink = await context.dispatch('saveModelConfig')
    console.log("retourlink", retourlink)
    imageNameArray.push(retourlink)
    console.log("imageNameArray", imageNameArray)

    if (inputData.extraQuestions) {
      console.log('1. Initial extraQuestions:', inputData.extraQuestions);
      
      const extraQuestionsWithText = Object.entries(inputData.extraQuestions).map(([questionId, answerId]) => {
        const matchingQuestion = context.rootState.cms.menuUISettings[0].extraQuestions.find(q => q.id === questionId);
        const matchingAnswer = matchingQuestion?.options?.find(opt => opt.id === answerId);
        
        return {
          questionId: String(questionId),
          answerId: String(answerId),
          questionText: matchingQuestion?.text || '',
          answerText: matchingAnswer?.text || ''
        };
      });

      console.log('2. Questions with text:', extraQuestionsWithText);
      context.commit('setExtraQuestionsWithText', extraQuestionsWithText);

      // Update inputData.extraQuestions with the full data
      inputData.extraQuestions = extraQuestionsWithText;
    }

    console.log('3. Final inputData:', inputData);
    
    const graphqlQuery = {
      query: `
        mutation {
          getQuotation (
            token: "${token}",
            sessionData: {
              sessionId: "${sessionData.sessionId}"
              specId: ${sessionData.specId}
            },
            inputData: {
              ${Object.entries(inputData)
                .filter(([key]) => key !== 'extraQuestions')
                .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
                .join(',')},
              extraQuestions: [
                ${inputData.extraQuestions ? inputData.extraQuestions.map(q => `{
                  questionId: "${q.questionId}",
                  answerId: "${q.answerId}",
                  questionText: "${q.questionText}",
                  answerText: "${q.answerText}"
                }`).join(',') : ''}
              ]
            },
            imageNameArray: ${JSON.stringify(imageNameArray)}
          )
        }
      `
    };

    console.log('4. GraphQL Query:', graphqlQuery.query);

    // sending request to server
    const response = await graphql.fetchGraphQL(context, graphqlQuery);

    // if error
    if (response.errors) {
      errorHandeling.throwError(context, response, 'Failed to get quotation.')
    }

    switch (location.hostname) {
      case 'localhost':
      case 'tw-js-dev.mtest.nl':
      case 'tw-v01-js-dev.mtest.nl':
        if(state.tokenLoggedIn) {
          window.location.href = "https://dealerportal.toplawood.moogue.com/confirmation";
        } else {
          window.location.href = "/invoice-requested";
        }
        break;
      case 'tw-js-tes.mtest.nl':
      case 'tw-v02-js-tes.mtest.nl':
        if(state.tokenLoggedIn) {
          window.location.href = "https://dealerportal.toplawood.moogue.com/confirmation";
        } else {
          window.location.href = "/invoice-requested";
        }
        break;
      case 'tw-js-pro.mtest.nl':
      case 'standaardmaten.toplawood.nl':
      case 'toplawood.moogue.com':
        if(state.tokenLoggedIn) {
          window.location.href = "https://dealerportal.toplawood.nl/confirmation";
        } else {
          window.location.href = "/invoice-requested";
        }
        break;
    }
  },

  async setIsFinishingInvoice(context, value) {
    context.commit('setIsFinishingInvoice', value)
  },

  async setUserToken(context, token) {
    context.commit('setUserToken', token)
    context.dispatch('setSessionTrackerToken', token)
  },

  async setConfiguratorURL(context, url) {
    context.commit('setConfiguratorURL', url)
  },

  setSchuifwandRichting(context, value) {
    context.commit('setSchuifwandRichting', value)
  },

  setSchuifwandKleur(context, value) {
    context.commit('setSchuifwandKleur', value)
  },

  setSchuifwandPreference(context, value) {
    context.commit('setSchuifwandPreference', value)
  },

  setSelectedSizeMode(context, value) {
    context.commit('setSelectedSizeMode', value)
  },

  setMaatwerkWidth(context, value) {
    context.commit('setMaatwerkWidth', value)
  },

  setMaatwerkDepth(context, value) {
    context.commit('setMaatwerkDepth', value)
  },

  toggleSnapping(context) {
    context.commit('toggleSnapping')
  },

  overstekConfigChanged(context, data) {
    context.commit('setOverstekConfig', data)
    context.dispatch('changeSize', {
      size: context.state.config.afmetingen,
      shouldResetWalls: false
    })
  }
}

const getters = {
  getSelectedSizes(state) {
    return state.config.afmetingen
  },

  getSizes(state) {
    return state.afmetingen
  },

  getFrameColors(state) {
    return state.frameColors
  },

  getSelectedFrameColor(state) {
    return state.config.frameKleur
  },

  getBoeideelColors(state) {
    return state.boeideelColors
  },

  getSelectedBoeideelColor(state) {
    return state.config.boeideelKleur
  },

  getHoekprofielColors(state) {
    return state.hoekprofielColors
  },

  getSelectedHoekprofielColor(state) {
    return state.config.hoekprofielKleur
  },

  getSelectedTrimColor(state) {
    return state.config.trimColor
  },

  getDakgoot(state) {
    return state.config.dakgoot
  },

  getWallColors(state) {
    return state.wallColors
  },

  getIsolatieTypes(state) {
    return state.isolatietypes
  },
  getIsolatieTypeDefault(state) {
    return state.isolatieTypeDefault
  },

  getSelectedWallColorInside(state) {
    return state.selectedWallColorInside
  },

  getSelectedWallColorOutside(state) {
    return state.selectedWallColorOutside
  },

  getWallInsideOn(state) {
    return state.wallInsideOn
  },

  getWallOutsideOn(state) {
    return state.wallOutsideOn
  },

  getWallIsolatieStatus(state) {
    return state.wallIsolatieStatus
  },

  getRoofFinishes(state) {
    return state.config.roofFinishes
  },

  getWallFinishes(state) {
    return state.config.wallFinishes
  },

  getSelectedDakafwerking(state) {
    return state.config.selectedDakAfwerking
  },

  getCurrentPath(state) {
    return state.currentPath
  },

  getConfig(state) {
    return state.config
  },

  getTypeId(state) {
    return state.config.typeId
  },

  getFormatedSizes(state) {

    return {
      x: state.config.afmetingen.breedte,
      y: state.config.afmetingen.hoogte,
      z: state.config.afmetingen.diepte,
      division: state.config.afmetingen.wallDivision,
      ringBalk: { dy: state.config.afmetingen.ringBalk.y, dz: state.config.afmetingen.ringBalk.z },
      extraRingBalk: state.config.afmetingen.extraRingBalk,
      ringbalkTussenPaal: state.config.afmetingen.ringbalkTussenPaal,
      staanderGrootte: { dx: state.config.afmetingen.staanderDikte, dz: state.config.afmetingen.staanderDikte },
      poer: state.config.afmetingen.poer,
      schoor: state.config.afmetingen.schoor,
      maximaleMuurBreedte: state.config.afmetingen.maximaleMuurBreedte,
      gordingBalk: state.config.afmetingen.gordingBalk,
      ringBalkGording: state.config.afmetingen.ringBalkGording,
      overstekKlos: state.config.afmetingen.overstekKlos,
      boeideel: state.config.afmetingen.boeideel,
      trim: state.config.afmetingen.trim,
      dak: state.config.afmetingen.dak,
      dakgoot: state.config.afmetingen.dakgoot,
      omlijstingPalen: state.config.afmetingen.omlijstingPalen,
      veranda: state.config.afmetingen.veranda,
      round: state.config.overstekKlosRound,
      wall: state.config.afmetingen.wall,
    }
  },

  getProductList(state) {
    return state.productList
  },

  getProductListPriceEXCL(state) {
    return state.productListPriceEXCL
  },
  getProductListPriceBTW(state) {
    return state.productListPriceBTW
  },
  getProductListPriceINCL(state) {
    return state.productListPriceINCL
  },

  getTokenLoggedIn(state) {
    return state.tokenLoggedIn
  },

  getDWSessionData(state) {
    return state.dwSessionData
  },

  getInputData(state) {
    return state.inputData
  },

  getIsFinishingInvoice(state) {
    return state.isFinishingInvoice
  },

  getUserToken(state) {
    return state.userToken
  },

  getConfiguratorURL(state) {
    return state.configuratorURL
  },

  getIsRetourLink(state) {
    return state.isRetourLink
  },

  getNeedsToConfirmSizeChange(state) {
    return state.needsToConfirmSizeChange
  },

  getWantsToChangeSize(state) {
    return state.wantsToChangeSize
  },

  getAvailableWallFinishes(state) {
    return state.availableWallFinishes
  },

  getSchuifwandRichting(state) {
    return state.schuifwandRichting
  },

  getSchuifwandKleur(state) {
    return state.schuifwandKleur
  },

  getSchuifwandPreference(state) {
    return state.schuifwandPreference
  },

  getSelectedSizeMode (state) {
    return state.selectedSizeMode
  },

  getMaatwerkWidth (state) {
    return state.maatwerkWidth
  },

  getMaatwerkDepth (state) {
    return state.maatwerkDepth
  },

  getSnapping (state) {
    return state.snappingEnabled
  },

  getOverstekConfig (state) {
    return state.overstekConfig
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}