// defines position of the vertices
function positionBuffer (hoogte, dikte, degrees) {
  const radians = degrees*(Math.PI/180)
  const y = hoogte
  const z = dikte/2
  const b = y/Math.tan(radians)
  console.log(hoogte, dikte, radians)
  return [
    //front
    0, y, -z,
    b, 0, -z,
    -b, 0, -z,

    //back
    b, 0, z,
    0, y, z,
    -b, 0, z,

    //bottom
    -b, 0, -z,
    b, 0, -z,
    b, 0, z,

    b, 0, z,
    -b, 0, z,
    -b, 0, -z,

    //left
    b, 0, z,
    b, 0, -z,
    0, y, -z,

    b, 0, z,
    0, y, -z,
    0, y, z,

    //right
    0, y, -z,
    -b, 0, -z,
    -b, 0, z,

    0, y, z,
    0, y, -z,
    -b, 0, z,
  ]
}

// defines position of the textures
function uvBuffer (hoogte, dikte, degrees, maxTextureWith, maxTextureHeight) { 
  //return [0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0]
  //return [0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1,0]
  const radians = degrees*(Math.PI/180)
  const y = hoogte
  const z = dikte/2
  const b = y/Math.tan(radians)
  const c = (b/maxTextureWith) * (1/(b/maxTextureWith)*Math.random())
  const d = c+b/maxTextureWith/2
  const e = c+b/maxTextureWith

  const k = (b/maxTextureWith) * (1/(b/maxTextureWith)*Math.random())
  const l = k+b/maxTextureWith

  const o = (y/maxTextureWith) * (1/(y/maxTextureWith)*Math.random())
  const p = o+y/maxTextureWith

  const m = (y/maxTextureWith) * (1/(y/maxTextureWith)*Math.random())
  const n = m+y/maxTextureWith
  console.log(hoogte, dikte, radians, y, z, b)
  console.log(c,d,e)
  return [
    d, hoogte/maxTextureHeight, 
    c, 0,
    e, 0, 

    c, 0, 
    d, hoogte/maxTextureHeight,
    e, 0,

    l, 0, 
    k, 0, 
    k, dikte/maxTextureHeight,

    k, dikte/maxTextureHeight, 
    l, dikte/maxTextureHeight, 
    l, 0,

    o, dikte/maxTextureHeight,
    o, 0, 
    p, 0,

    o, dikte/maxTextureHeight,
    p, 0,
    p, dikte/maxTextureHeight,

    m, 0, 
    n, 0, 
    n, dikte/maxTextureHeight,

    m, dikte/maxTextureHeight, 
    m, 0,
    n, dikte/maxTextureHeight,
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  //return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
  //return [0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0,]
  //return [0,0,1]
  //return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
  return [
    0, 0, -1,
    0, 0, -1,
    0, 0, -1,

    0, 0, 1,
    0, 0, 1,
    0, 0, 1,

    0, -1, 0,
    0, -1, 0,
    0, -1, 0,
    0, -1, 0,
    0, -1, 0,
    0, -1, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,
  ]
}

// group the vertices belonging to each of the 6 sides, allows for 6 different textures one for each side.
function groupBuffer () {
  return [
    {
      start: 0,
      count: 6,
      materialIndex: 0
    },
    {
      start: 6,
      count: 6,
      materialIndex: 1
    },
    {
      start: 12,
      count: 6,
      materialIndex: 2
    },
    {
      start: 18,
      count: 6,
      materialIndex: 3
    },
    {
      start: 24,
      count: 6,
      materialIndex: 4
    },
    {
      start: 30,
      count: 6,
      materialIndex: 5
    }
  ]
}

function groupBufferSelectFrame () {
  return [
    {
      start: 0,
      count: 3,
      materialIndex: 0
    },
    {
      start: 3,
      count: 3,
      materialIndex: 1
    },
    {
      start: 6,
      count: 6,
      materialIndex: 2
    },
    {
      start: 12,
      count: 6,
      materialIndex: 3
    },
    {
      start: 28,
      count: 6,
      materialIndex: 4
    }
  ]
}

export { positionBuffer, uvBuffer, normalBuffer, groupBuffer, groupBufferSelectFrame }