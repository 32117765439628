function positionBuffer (x, z) {
  // a: L height
  // b: outer width
  // c: inner width
  // d: L depth

  return [
    // top
    0, 0, 0, //bottom left
    0, 0, x, //top right
    z, 0, 0, //top left
    
    z, 0, x, //bottom right
    z, 0, 0, //bottom left
    0, 0, x, //top right
  ]
}

// defines position of the textures
function uvBuffer () { 
  return [
    // top
    1, 0,
    1, 1,
    0, 0,

    0, 1,
    0, 0,
    1, 1,
  ]
}

function uvBufferVoor () { 
  return [
    // top
    1, 1,
    0, 1,
    1, 0,

    0, 0,
    1, 0,
    0, 1,
  ]
}

function uvBufferAchter () { 
  return [
    // top
    0, 1,
    1, 1,
    0, 0,

    1, 0,
    0, 0,
    1, 1,
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  return [
    // top
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
  ]
}

export { positionBuffer, uvBuffer, uvBufferVoor, uvBufferAchter, normalBuffer }