import { ParijsFrame } from '@/three/frames/index.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as graphql from '@/functions/graphql.js'
import * as errorHandeling from '@/functions/errorHandeling.js'
import { markRaw } from 'vue'
import store from './'

const gltfLoader = new GLTFLoader()

const envServ = import.meta.env.VITE_VUE_APP_SERVER;
const envLoc = import.meta.env.VITE_VUE_APP_MODELS_LOCATION;

const state = {
  frame: null,
  renderData: null,
  loadingManger: null,
  textureLoader: null,
  loading: true,
  progress: 0,
  intersectionOn: true,
  intersectingObjects: [],
  selectedWall: null,
  selectedWallObject: null,
  selectedWallWidth: 0,
  hoveringWall: null,
  selectedElement: null,
  isMapActive: false,
  isFullScreen: false,
  shouldRenderSteellook: false,
  imageNameArray: [],
  wallHover: false,
  cameraDirection: null,
  currentIntersectingLichtstraat: null,
  selectedLichtstraat: null,
  selectedLichtstraatCode: null,
  invalidLichtstraten: [],
  currentIntersectingLichtstraatDimension: null,
  selectedLichtstraatDimension: null,
  selectedLichtstraatDimensionValue: {
    value: 0, // actual number of the current dimension of the selected lichtstraat maatlijn
    max: 0 // maximum size of dimension EXCLUDING the border boundaries
  },
}

const mutations = {

  // TODO remove - just for steellook demo purposes
  toggleSteellook(state) {
    state.shouldRenderSteellook = !state.shouldRenderSteellook;
  },

  setFrame(state, {THREE, scene, textureLoader, cubeLoader, typeId, modelId, afmetingen, modelSettings, textureFrame, textureBoeidelen, textureHoekprofielen, colorTrim, selectedDakAfwerking, fundering, walls, topgevels, hover, retourLink, modelsObjArray, dakgoot, lichtstraten}) {
    state.wallHover = hover
    console.log(modelsObjArray)
    state.frame = markRaw(new ParijsFrame(THREE, scene, textureLoader, cubeLoader, typeId, modelId, afmetingen, modelSettings, textureFrame, textureBoeidelen, textureHoekprofielen, colorTrim, selectedDakAfwerking, fundering, walls, topgevels, hover, retourLink, modelsObjArray, dakgoot, lichtstraten))
    //alert("setFrame")
  },

  updateFrame(state, { typeId, modelId, afmetingen, modelSettings, fundering, dakgoot, shouldNotResetWalls, context }) {
    const oldBreedte = state.frame.x
    const oldDiepte = state.frame.z
    const info = state.frame.update(typeId, modelId, afmetingen, modelSettings, fundering, dakgoot, shouldNotResetWalls)
    context.dispatch('updateWallArrayConfig', info.walls)
    context.dispatch('updateTopgevelArrayConfig', info.topgevels)
    console.log(oldBreedte, oldDiepte)
    //const oldTarget = state.renderData.controls.target
    //const oldCamera = state.renderData.camera.position
    //state.renderData.controls.target.set(afmetingen.z/2,afmetingen.y/2,afmetingen.x/2)
    state.renderData.controls.target.x += (afmetingen.z/2)-(oldDiepte/2)
    state.renderData.controls.target.z += (afmetingen.x/2)-(oldBreedte/2)
    //state.renderData.camera.position.set(afmetingen.z/2-10000, afmetingen.y/2+3500, afmetingen.x/2-4000)
    state.renderData.camera.position.x += (afmetingen.z/2)-(oldDiepte/2)
    state.renderData.camera.position.z += (afmetingen.x/2)-(oldBreedte/2)
  },

  async resetWalls(state) {
    await state.frame.resetWalls()
  },

  resetTopgevels(state) {
    state.frame.resetTopgevels()
  },

  updateFrameTexture(state, texture) {
    state.frame.updateTextureFrame(texture)
  },

  updateBoeideelTexture(state, texture) {
    state.frame.updateTextureBoeidelen(texture)
  },

  updateHoekprofielTexture(state, texture) {
    state.frame.updateTextureHoekprofielen(texture)
  },
  
  updateTrimColor(state, color) {
    state.frame.updateTrimColor(color)
  },

  toggleEPDM (state, boolean) {
    state.frame.toggleEPDM(boolean)
  },

  setDakpanMeshVisibility (state, boolean) {
    state.frame.setDakpanMeshVisibility(boolean)
  },

  updateDakafwerkingTexture (state, color) {
    state.frame.updateDakafwerkingTexture(color)
  },

  setLoading(state, loading) {
    state.loading = loading
  },

  setProgress(state, progress) {
    state.progress = progress
  },

  setIntersectionOn (state, on) {
    state.intersectionOn = on
  },

  setWallHover (state, on) {
    function setWallHover(on) {
      if (state.frame) {
        state.frame.setWallHover(on)
        state.wallHover = on
        // if(!on && state.selectedWall && !state.selectedWall.wall.name === 'wallBox') {
        // task 1547 fix console error attempting to deselect wall when going to invoice
        if (!on && state.selectedWall && !state.selectedWall.wall.parent.name === 'wallBox') {
          state.frame.deselectWall(state.selectedWall.wall)
          state.selectedWall = null
        }
      } else {
        setTimeout(() => {
          setWallHover(on)
        }, 100)
      }
    }
    setWallHover(on)
  },

  setWallHoverEnter (state, data) {
    if (data.wall) {  
      state.frame.wallHoverEnter(data)
    }

    let muur = null
    if(data.wall.parent.name === "wall") { //if is map element
      muur = state.frame.muurInstances.find((muur) => muur.group.uuid === data.wall.parent.uuid)
    } else {
      muur = state.frame.muurInstances.find((muur) => muur.group.uuid === data.wall.parent.parent.parent.uuid)
    }

    if(data.settings.binnenMuur ? !(muur.showInFuture.binnen === data.uuid) : (data.settings.buitenMuur ? !(muur.showInFuture.buiten === data.uuid) : true)) {return}

    state.hoveringWall = muur
  },

  setWallHoverLeave (state, hoveredWall) {
    state.frame.wallHoverLeave(hoveredWall)

    state.hoveringWall = null
  },

  setWallHoverShowInFuture(state, data) {
    state.frame.setWallHoverShowInFuture(data)
  },

  setTopgevelHoverEnter (state, data) {
    if (data.topgevel) {
      state.frame.topgevelHoverEnter(data)
    }
  },

  setTopgevelHoverLeave (state, hoveredTopgevel) {
    state.frame.topgevelHoverLeave(hoveredTopgevel)
  },

  setTopgevelHoverShowInFuture(state, data) {
    state.frame.setTopgevelHoverShowInFuture(data)
  },

  setElementPlacing (state, on) {
    if (state.frame) {
      state.frame.setElementPlacing(on)
      state.frame.shouldShowPlusIcons = on
    }
  },

  addWall (state, { wall, settings, context }) {
    // console.log('ADDWALL',context.rootState.config.wallIsolatieStatus)
    // settings.heeftIsolatie = context.rootState.config.wallIsolatieStatus;
    const info = state.frame.addWall(wall, settings)
    console.log(info)
    context.dispatch('addWallConfig', info)
  },

  removeWall (state, wall) {
    state.frame.deselectWall(wall)
    state.frame.removeWall(wall)
    // eslint-disable-next-line no-unused-vars
    var wallIndex = getWallIndex();
    function getWallIndex() {
      for(var i=0; i<state.intersectingObjects.length; i++) {
        var muur = state.intersectingObjects[i]
        // console.log(muur.uuid); 
        if(wall.parent != undefined) {
          if(wall.parent.children[1] != undefined) {
            if(muur.uuid === wall.parent.children[1].uuid) {
              return i;
            }
          }
        }
      }
    }
    //console.log(state.intersectingObjects)
    //console.log(wall.parent.children[1].uuid)
    // console.log(wallIndex)
    // console.log(state.intersectingObjects)
    //state.intersectingObjects.splice([wallIndex],1)
    //state.frame.muurInstances.splice([wallIndex],1)
    // console.log(state.intersectingObjects.length)
  },

  setSelectedWall (state, {wall, clickedOnBorstweringAsset}) {
    // console.log(wall)
    if(wall.name === "topgevelSelectbox") {
      let settings = state.frame.topgevel.topgevelArray.find(topgevel => wall.parent.id === topgevel.group.id)
      //wall.settings = settings
      state.selectedWall = {
        wall: wall,
        settings: settings,
        isTopgevel: true
      }
      state.frame.topgevel.selectTopgevel(wall)
    }else if(wall.parent.name != "wallBox") {
      const settings = state.frame.getWallSettings(wall)

      if (!settings) {
        if (location.hostname === 'localhost' || location.hostname === 'tw-js-dev.mtest.nl') console.log('No wall settings found.')
        return;
      }

      if (state.selectedWall) {
        state.frame.deselectWall(state.selectedWall.wall)
      }

      state.selectedWall = {
        wall: wall,
        settings: settings
      }

      state.frame.selectWall(wall, clickedOnBorstweringAsset)
    } else {
      state.selectedWall = {
        wall: wall
      }
      state.frame.selectWall(wall, clickedOnBorstweringAsset)
    }
    if(state.selectedWall.wall.parent.name != "wallBox") {
      // muur
      state.selectedWallObject = state.frame.muurInstances.find((muur) => muur.group.uuid === state.selectedWall.wall.parent.uuid)
    } else {
      // steellook
      state.selectedWallObject = state.frame.muurInstances.find((muur) => muur.group.uuid === state.selectedWall.wall.parent.parent.uuid)
    }
    state.selectedWallWidth = state.selectedWallObject.set.mx
  },

  setSelectedWallWidth (state, width) {
    state.selectedWallWidth = width
  },

  updateWall (state, { wall, index, settings, context, updateEntireWall, onlyUpdatePreview}) {
    console.log(settings, index, state.frame.muurInstances)
    let info
    console.log(wall)
    if(wall && wall.name === "topgevelSelectbox") {
      info = state.frame.topgevel.updateTopgevel(wall, settings)
    } else {
      info = state.frame.updateWall(wall, settings, index, updateEntireWall, onlyUpdatePreview)
    }

    // if (!settings.buitenMuur && !settings.binnenMuur) {
    //   state.selectedWall = null
    // }

    if(wall && wall.name === "topgevelSelectbox") {
      //context.commit('setUpdateTopgevelConfig', info)
    } else {
      context.commit('setUpdateWallConfig', info)
    }
  },

  updateWallWidth (state, { wall, index, settings}) {
    const info = state.frame.updateWallWidth(wall, settings, index)

    if (!settings.buitenMuur && !settings.binnenMuur) {
      if(state.selectedWall) {
        if(state.selectedWall.wall.name === "topgevelSelectbox") {	
          state.frame.topgevel.deselectTopgevel(state.selectedWall.wall)
        } else {
          state.frame.deselectWall(state.selectedWall.wall)
        }
        state.selectedWall = null
      }
    }
    console.log(info)
    //context.commit('setUpdateWallConfig', info)
  },

  updateWallPosition (state, { wall, index, settings}) {
    const info = state.frame.updateWallPosition(wall, settings, index)

    if (!settings.buitenMuur && !settings.binnenMuur) {
      if(state.selectedWall) {
        if(state.selectedWall.wall.name === "topgevelSelectbox") {	
          state.frame.topgevel.deselectTopgevel(state.selectedWall.wall)
        } else {
          state.frame.deselectWall(state.selectedWall.wall)
        }
        state.selectedWall = null
      }
    }
    console.log(info)
    //context.commit('setUpdateWallConfig', info)
  },

  updateWallHoles (state, data) {
    state.frame.updateWallHoles(data.wall, data.holes, data.model, data.map, data.component, data.settings, data.Ewall, data.Elocation, data.Eside)
  },

  addSteelLookComponent (state, data) {
    // console.log("addSteelLookComponent",data)
    state.frame.addSteelLookComponent(data.wall, data.dimensions, data.model, data.map, data.component, data.settings, data.modelCode)
  },

  addSschuifwandComponent (state, data) {
    state.frame.addSchuifwandComponent(data.wall, data.settings)
  },

  removeSelectedWall (state) {
    if(state.selectedWall) {
      if(state.selectedWall.wall.name === "topgevelSelectbox") {	
        state.frame.topgevel.deselectTopgevel(state.selectedWall.wall)
      } else {
        state.frame.deselectWall(state.selectedWall.wall)
      }
      state.selectedWall = null
    }
  },

  addTopgevel (state, {topgevel, settings, context}) {
    state.frame.topgevel.addTopgevel({topgevel, settings})
    const topgevels = []
    if(state.frame.topgevel) {
      state.frame.topgevel.topgevelArray.forEach((topgevel) => {
        topgevels.push({
          binnenMuur: topgevel.binnenMuur,
          binnenMuurTexture: topgevel.binnenMuurTexture,
          buitenMuur: topgevel.buitenMuur,
          buitenMuurTexture: topgevel.buitenMuurTexture,
          heeftIsolatie: topgevel.heeftIsolatie,
          isolatieId: topgevel.isolatieId
        })
      })
    }
    context.dispatch('updateTopgevelArrayConfig', topgevels)
  },

  selectTopgevel (state, data) {
    state.frame.topgevel.selectTopgevel(data)
  },

  updateTopgevels (state, data) {
    for(let i = 0; i < data.length; i++) {
      state.frame.topgevel.updateTopgevel(state.frame.topgevel.topgevelArray[i].group.getObjectByName("topgevelSelectbox"), data[i])
    }
  },

  setMapMode(state, val) {
    state.isMapActive = val
    if (val) {
      state.frame.lichtstraatManager.updateMapMaatLijnen()
    } else {
      // hetzelfde als bij setSelectedLichtstraatDimension(null)
      state.selectedLichtstraatDimension = null
      state.frame.lichtstraatManager.setSelectedLichtstraatDimension(null)
    }
  },

  setFullScreen(state, val) {
    state.fullScreen = val
  },

  setFullScreenPossible(state, val) {
    state.fullScreenPossible = val
  },

  setSelectedElement(state, obj) {

    if (state.selectedElement) {
      state.frame.deselectElement(state.selectedWall)
    }

    state.selectedElement = obj

    state.frame.selectElement(obj)
    
    
  },

  // setSelectedSteellookModel(state, data){
  //   console.log('test state data setSelectedSteellookModel',state,data)
  // },

  removeSelectedElement (state) {
    state.frame.deselectElement(state.selectedElement)
    state.selectedElement = null
  },

  removeElement (state, obj) {
    console.log("removeElement", obj)
    state.frame.removeElement(obj)
    state.selectedElement = null
  },

  changeLockMode (state, obj) {
    state.frame.changeLockMode(obj)
  },

  setMovingBeam (state, data) {
    console.log("setMovingBeam", data.beam)
    state.frame.movingBeam = data.beam
    state.frame.movingStartPosition = data.mousePosition
    state.frame.movingStartPlanePosition = data.planePosition
    state.frame.setMovingBeam(data.beam)
  },

  removeMovingBeam (state) {
    state.frame.movingBeam = null
    state.frame.removeMovingBeam()
  },

  updateMovingBeam (state, data) {
    //console.log(data.mousePosition - state.frame.movingStartPosition)
    //console.log(state.frame.movingStartPlanePosition.x - data.planePosition.x)
    state.frame.updateMovingBeam(data.planePosition)
  },

  setCurrentIntersectingLichtstraat (state, lichtstraat) {
    if (!state.frame.lichtstraatManager) return
    state.currentIntersectingLichtstraat = lichtstraat

    // set outline
    const outlinedObjects = []
    if (state.currentIntersectingLichtstraat) outlinedObjects.push(state.currentIntersectingLichtstraat)
    if (state.selectedLichtstraat) outlinedObjects.push(state.selectedLichtstraat)
    state.renderData.lichtstraatOutlineEffect.selection.set(outlinedObjects)

    state.frame.lichtstraatManager.setCurrentIntersectingLichtstraat(lichtstraat)
  },

  setSelectedLichtstraat (state, data) {
    state.selectedLichtstraat = data.lichtstraat

    // set oultine
    const outlinedObjects = []
    if (state.currentIntersectingLichtstraat) outlinedObjects.push(state.currentIntersectingLichtstraat)
    if (state.selectedLichtstraat) outlinedObjects.push(state.selectedLichtstraat)
    state.renderData.lichtstraatOutlineEffect.selection.set(outlinedObjects)

    state.frame.lichtstraatManager.setSelectedLichtstraat(data)
  },

  updateLichtstraatPosition (state, data) {
    state.frame.lichtstraatManager.updateLichtstraatPosition(data)
  },

  addLichtstraat (state, data) {
    state.frame.lichtstraatManager.addLichtstraat(data)
  },

  removeSelectedLichtstraat (state) {
    state.frame.lichtstraatManager.removeSelectedLichtstraat()
    state.selectedLichtstraat = null
  },

  setSelectedLichtstraatCode (state, code) {
    state.selectedLichtstraatCode = code
  },

  setInvalidLichtstraten (state, data) {
    state.invalidLichtstraten = data
  },

  setCurrentIntersectingLichtstraatDimension (state, data) {
    if (state.currentIntersectingLichtstraatDimension !== data) {
      state.currentIntersectingLichtstraatDimension = data
      state.frame.lichtstraatManager.setCurrentIntersectingLichtstraatDimension(data)
    }
  },

  setSelectedLichtstraatDimension (state, data) {
    // als je hier iets veranderd, doe dat dan ook bij setMapMode
    state.selectedLichtstraatDimension = data
    state.frame.lichtstraatManager.setSelectedLichtstraatDimension(data)
  },

  setSelectedLichtstraatDimensionValue (state, data) {
    state.selectedLichtstraatDimensionValue = data
  },

  setSelectedLichtstraatNewDimensionValue (state, data) {
    state.frame.lichtstraatManager.setSelectedLichtstraatNewDimensionValue(data)
  },

  setWallWidth (state, data) {
    state.frame.setWallWidth(data)
  },

  updateIntersectingObjects (state) {
    // console.log(state.intersectingObjects.length)
    state.intersectingObjects = []
    if (!state.frame) return
    for (let i = 0; i < state.frame.muurInstances.length; i++) {
      if (!state.isMapActive && state.frame.muurInstances[i].hoverLineGroup && !state.frame.muurInstances[i].isSlidingWall) {
        state.intersectingObjects.push(state.frame.muurInstances[i].hoverLineGroup)
      }

      if (state.isMapActive && state.frame.muurInstances[i].mapGroup) {
        state.intersectingObjects.push(state.frame.muurInstances[i].mapGroup)
      }

      if ((state.frame.muurInstances[i].wallOn/* || state.frame.muurInstances[i].isBorstwering*/) && !state.isMapActive && state.frame.muurInstances[i].group) {
        state.intersectingObjects.push(state.frame.muurInstances[i].group)
      } else if (state.frame.muurInstances[i].isBorstwering && !state.isMapActive && state.frame.muurInstances[i].borstweringAssetHitbox) {
        // zorgt dat je alleen borstweringhitbox op kan klikken
        state.intersectingObjects.push(state.frame.muurInstances[i].borstweringAssetHitbox)
      }

      if (!state.isMapActive && state.frame.muurInstances[i].plusIconGroup) {
        state.intersectingObjects.push(state.frame.muurInstances[i].plusIconGroup)
      }
      // console.log(state.frame.muurInstances[i].modelGroup)
    }
    for (let i = 0; i < state.frame.topgevel.topgevelArray.length; i++) {
      if (!state.isMapActive && state.frame.topgevel.topgevelArray[i].group) {
        state.intersectingObjects.push(state.frame.topgevel.topgevelArray[i].group.getObjectByName("plusIconTopgevelBuiten"))
        state.intersectingObjects.push(state.frame.topgevel.topgevelArray[i].group.getObjectByName("plusIconTopgevelBinnen"))
        if (state.frame.topgevel.topgevelArray[i].binnenMuur || state.frame.topgevel.topgevelArray[i].buitenMuur) {

          state.intersectingObjects.push(state.frame.topgevel.topgevelArray[i].group.getObjectByName("topgevelSelectbox"))

          const activeTopgevelHoverBtn = markRaw(state.frame.topgevel.topgevelArray[i].hoverTopIsoMainGroup
          ?.children.find(c => c.name ==="topIsolatieHoverButtonGroupActive").getObjectByName("topIsolatieHoverButtonActive"));
          const inactiveTopgevelHoverBtn = markRaw(state.frame.topgevel.topgevelArray[i].hoverTopIsoMainGroup
          ?.children.find(c => c.name ==="topIsolatieHoverButtonGroupInactive").getObjectByName("topIsolatieHoverButtonInactive"));

          if(activeTopgevelHoverBtn?.visible) state.intersectingObjects.push(activeTopgevelHoverBtn);
          if(inactiveTopgevelHoverBtn?.visible) state.intersectingObjects.push(inactiveTopgevelHoverBtn);
        }
      }

      if (state.isMapActive && state.frame.topgevel.topgevelArray[i].mapGroup) {
        state.intersectingObjects.push(state.frame.topgevel.topgevelArray[i].mapGroup)
      }
    }
    if (!state.isMapActive && state.frame.lichtstraatManager) {
      if (store.getters.getCurrentPath.includes('/cfg/roof/lichtstraten')) {
        state.intersectingObjects.push(state.frame.dakbeschot.group)
      }
      for (const lichtstraat of state.frame.lichtstraatManager.lichtstraten) {
        state.intersectingObjects.push(lichtstraat.hitbox)
      }
    } else if (state.isMapActive && state.frame.lichtstraatManager) {
      // voeg alleen plattegrond hitboxen toe
      for (const lichtstraat of state.frame.lichtstraatManager.lichtstraten) {
        state.intersectingObjects.push(lichtstraat.map)
      }
    }
    // if(!state.isMapActive) {
    //   state.intersectingObjects.push(state.frame.dakbeschot.group)
    // }
    // console.log(state.intersectingObjects.length)
    //state.intersectingObjects[state.intersectingObjects.length-1].position.y = 1500
    // console.log(state.intersectingObjects.filter(obj => obj.name === "topgevelSelectbox"))
  },

  calcRemoveWall (state, wall) {
    state.frame.calcRemoveWall(wall)
  },

  setConcreteFoundation(state, value) {
    state.frame.setFoundation(value)
  },

  setWallPlusIcons(state, data) {
    if (!state.frame) return
    state.frame.setWallPlusIcons(data)
  },

  updatePlusIcons(state) {
    if(state.frame.shouldShowPlusIcons) {
      state.frame.setWallPlusIcons(state.frame.selectedWallComponent)
      state.frame.setElementPlacing(state.frame.selectedWallComponent)
    }
  },

  setRenderData(state, renderData) {
    state.renderData = renderData
  },

  updateRenderData(state, data) {
    state.renderData[data.key] = data.value
  },

  removeLastWall(state, wallToBeRemoved) {
    console.log("wallToBeRemoved", wallToBeRemoved)
    let muur = state.frame.muurInstances.find(wall => wall.settings.uuid === wallToBeRemoved.uuid)
    console.log("wallToBeRemoved", wallToBeRemoved, muur)

    muur.group.removeFromParent()
    muur.mapGroup.removeFromParent()
    muur.modelGroup.removeFromParent()

    if(muur.parentWall) {
      muur.parentWall.childWall = muur.childWall 
      muur.parentWall.settings.Cuuid = muur.parentWall.childWall ? muur.parentWall.childWall.settings.uuid : null
      if(muur.childWall) {
        muur.childWall.parentWall = muur.parentWall
        muur.childWall.settings.Puuid = muur.childWall.parentWall ? muur.childWall.parentWall.settings.uuid : null

      }
    } else if(muur.childWall) {
      muur.childWall.parentWall = muur.parentWall
      muur.childWall.settings.Puuid = muur.childWall.parentWall ? muur.childWall.parentWall.settings.uuid : null
    }

    state.frame.muurInstances.splice(state.frame.muurInstances.indexOf(muur), 1)
  },

  insertWall (state, settings) {
    console.log(settings)
    console.log("state.frame.muurInstances", state.frame.muurInstances)
    state.frame.muurInstances.map((x) => {
      console.log({uuid: x.settings.uuid, Puuid: x.settings.Puuid, Cuuid: x.settings.Cuuid})
    })

    let relativeWall = null
    let isParent
    let isChild
    if(settings.Puuid) {
      isParent = state.frame.muurInstances.find(x => x.settings.uuid === settings.Puuid)
      if(isParent) {
        relativeWall = isParent
      }
    } else if(settings.Cuuid) {
      isChild = state.frame.muurInstances.find(x => x.settings.uuid === settings.Cuuid)
      if(isChild) {
        relativeWall = isChild
      }
    }

    console.log("relativeWall", relativeWall, settings, state.frame.staanderObject, state.frame.staanderGrootte)

    const set = {
      mx: settings.width,
      my: 2300,
      mz: state.frame.staanderGrootte.dx,
      rotation: settings.rotation, //relativeWall.set.rotation,
      x: settings.position.z,
      y: settings.position.y,
      z: settings.position.x,//2600,
      tussenMuur: settings.tussenMuur //relativeWall.set.tussenMuur
    }
    console.log("isParent", isParent)
    /*setTimeout(() => {
      //relativeWall.group.position.y=2000
      const test = state.frame.muurInstances.find(x => x.settings.uuid === settings.uuid)
      test.group.position.y=1000
    }, 3000);*/
    console.log("relativeWall", relativeWall)

    const callBackFunction = () => {
      //alert("klaar")
    }

    state.frame.insertWall(relativeWall, set, 2300, isParent, callBackFunction, state.frame.textureFrame, !state.frame.hover, null, settings)

    /*console.log("INSERTEDWALLSETTINGS", settings)

    const checkedWalls = []

    let foundParentWall = null
    for(let i=0; i<state.frame.muurInstances.length; i++) {
      const currentWall = state.frame.muurInstances[i]
      console.log(currentWall)
      if(!checkedWalls.find(uuid => uuid == currentWall.group.uuid) && !(!currentWall.alignsXAxis && !currentWall.alignedRotation)) {
        let currentWallLoop = currentWall


        while(currentWallLoop.parentWall) {
          console.log("parentloop", currentWallLoop)
          checkedWalls.push(currentWallLoop.group.uuid)
          currentWallLoop = currentWallLoop.parentWall
        }

        let totalWidth = 0
        let totalWidthFrame = 0
        let axis = ''
        if(currentWall.alignsXAxis) {
          axis = 'x'
          totalWidth = state.frame.z - currentWall.group.position[axis]
          totalWidthFrame = state.frame.z 
        } else {
          axis = 'z'
          totalWidth = state.frame.x - currentWall.group.position[axis]
          totalWidthFrame = state.frame.x
        }

        let predictedlocation = currentWallLoop.group.position[axis]
        //let currentWidth = 0
        while(currentWallLoop) {
          checkedWalls.push(currentWallLoop.group.uuid)
          console.log(currentWallLoop.group.position[axis] !== predictedlocation, currentWallLoop.group.position[axis], predictedlocation)
          if(currentWallLoop.group.position[axis] !== predictedlocation) {
            console.log("klopt niet", currentWallLoop.group.position[axis], predictedlocation)
            console.log("klopt niet", Math.abs(currentWallLoop.group.position[axis]-predictedlocation), settings.width)
            console.log("klopt niet", currentWallLoop.group.position[axis], predictedAndStaander+settings.width)
            alert("klopt niet")
            if(Math.abs((currentWallLoop.group.position[axis]) - (predictedAndStaander+settings.width)) < 100) {
              foundParentWall = currentWallLoop
              return
            }
          }

          let predictedAndStaander


          if(currentWallLoop.alignsXAxis) {
            if(currentWallLoop.alignedRotation) {
              console.log("1")
              predictedlocation -= (currentWallLoop.set.mx + currentWallLoop.staanderObject.dx)
              predictedAndStaander = (predictedlocation + currentWallLoop.staanderObject.dx)
            } else {
              console.log("2")
              predictedlocation += (currentWallLoop.set.mx + (currentWallLoop.childWall ? currentWallLoop.staanderObject.dx : -currentWallLoop.staanderObject.dx))
              predictedAndStaander = (predictedlocation + currentWallLoop.staanderObject.dx)
            }
          } else {
            if(currentWallLoop.alignedRotation) {
              console.log("3")
              console.log("predictedlocation", predictedlocation)
              predictedlocation += (currentWallLoop.set.mx + currentWallLoop.staanderObject.dx)
              predictedAndStaander = (predictedlocation + currentWallLoop.staanderObject.dx)
              console.log("predictedlocation", predictedlocation)
            }
          }

          //currentWidth += currentWallLoop.set.mx

          if(!currentWallLoop.childWall) {
            if(predictedAndStaander !== totalWidth) {
              console.log(totalWidthFrame, predictedAndStaander+settings.width)
              if(totalWidthFrame === predictedAndStaander+settings.width) {
                foundParentWall = currentWallLoop
              }
            }
          }
          
          currentWallLoop = currentWallLoop.childWall
        }
        currentWallLoop = currentWall.parentWall
        


        
      }
    }
    console.log("GEOVNDEN MUUR: ", foundParentWall)

    const set = {
      mx: settings.width,
      my: 2300,
      mz: foundParentWall.staanderObject.dx,
      rotation: foundParentWall.set.rotation,
      x: settings.position.z,
      y: settings.position.y,
      z: settings.position.x,//2600,
      tussenMuur: foundParentWall.set.tussenMuur
    }
    state.frame.insertWall(foundParentWall, set, 2300, true, null, state.frame.textureFrame)*/
  },

  insertSteellook (state, settings) {
    console.log("insertSteellookSettings", settings)
    console.log("state.frame.muurInstances", state.frame.muurInstances)
    state.frame.muurInstances.map((x) => {
      console.log({uuid: x.settings.uuid, Puuid: x.settings.Puuid, Cuuid: x.settings.Cuuid})
    })

    let relativeWall = null
    let isParent
    let isChild
    if(settings.Puuid) {
      //alert("has parent")
      //isParent = state.frame.muurInstances.find(x => x.settings.Cuuid === settings.uuid)
      isParent = state.frame.muurInstances.find(x => x.settings.uuid === settings.Puuid)
      console.log("hieronder")
      console.log(settings.Puuid)
      state.frame.muurInstances.forEach(element => {
        console.log(element.settings.uuid)
      });
      if(isParent) {
        relativeWall = isParent
        //alert("PARENT GEVONDEN!")
      }
    } else if(settings.Cuuid) {
      //alert("has child")
      //isChild = state.frame.muurInstances.find(x => x.settings.Puuid === settings.uuid)
      isChild = state.frame.muurInstances.find(x => x.settings.uuid === settings.Cuuid)
      if(isChild) {
        relativeWall = isChild
        //alert("CHILD GEVONDEN!")
      }
    }

    console.log("relativeWall", relativeWall)

    const set = {
      mx: settings.width,
      my: 2300,
      mz: relativeWall.staanderObject.dx,
      rotation: relativeWall.set.rotation,
      x: settings.position.z,
      y: settings.position.y,
      z: settings.position.x,//2600,
      tussenMuur: relativeWall.set.tussenMuur
    }
    console.log("isParent", isParent)
    /*setTimeout(() => {
      //relativeWall.group.position.y=2000
      const test = state.frame.muurInstances.find(x => x.settings.uuid === settings.uuid)
      test.group.position.y=1000
    }, 3000);*/
    console.log("relativeWall", relativeWall)

    const code = settings.modelCode

    gltfLoader.load(`${import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${code}.glb`, (glb) => {
      gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${code}.glb`, (glb_map) => {
        glb.scene.scale.multiplyScalar(1000)
        let component = glb.scene
        component.traverse(n => {
          if(n.isMesh) {
            n.castShadow = true
            n.receiveShadow = true
            if(n.material) {
              if(Array.isArray(n.material)) {
                n.material.forEach((a) => {
                  a.metalness = 0;
                  console.log(a)
                  if(a.material.map) {
                    a.material.map.anisotropy = 16
                    a.material.map.encoding = state.frame.THREE.sRGBEncoding
                    a.material.map.flipY = false;
                  }
                })
              } else {
                n.material.metalness = 0;
                console.log(n)
                if(n.material.map){
                  n.material.map.anisotropy = 16
                  n.material.map.encoding = state.frame.THREE.sRGBEncoding
                  n.material.map.flipY = false;
                }
              }
            }
          }
        })
        glb_map.scene.scale.multiplyScalar(1000)

        state.frame.insertSteellook(relativeWall, isParent, set, component, glb_map.scene, code, settings.dimensions, settings)
      })
    })
  },

  setSelectedWallComponentInFrame(state, component) {
    if (!state.frame) return
    state.frame.selectedWallComponent = component
    state.frame.setElementPlacing(true)
  },

  setGoot(state, data) {
    state.frame.goot.setGoot(data)
  },

  setHoekProfielen(state, muur) {
    state.frame.setHoekProfielen(muur)
  },

  async removeTussenChildWalls(state, muur) {
    await state.frame.removeTussenChildWalls(muur)
  },

  setCameraDirection(state, direction) {
    state.cameraDirection = direction
  },
}


const actions = {
  toggleSteellook(context) {
    context.commit('toggleSteellook');
  },
  async createFrame (context, {THREE, scene, typeId, modelId, afmetingen, hover}) {

    await context.dispatch('getTextures')
    await context.dispatch('getMenuColors')
    await context.dispatch('getPlankTypes')
    await context.dispatch('setWallFinishes')
    await context.dispatch('getGlobalSettings')

    const myPromise = new Promise((resolve) => {

      // initiate loading manager
      const loadingManger = new THREE.LoadingManager()
      loadingManger.onLoad = function () {
        // console.log(`loading copmleted`)
        setTimeout(() => {
          context.commit('setLoading', false)
        }, 1000)
      }

      loadingManger.onProgress = function ( url, itemsLoaded, itemsTotal ) {
        // console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        context.commit('setProgress', itemsLoaded / itemsTotal)
      }

      // initiate texture loader
      const textureLoader = new THREE.TextureLoader(loadingManger)
      const cubeLoader = new THREE.CubeTextureLoader(loadingManger);
      this.textureLoader = textureLoader
      this.loadingManger = loadingManger
      this.cubeLoader = cubeLoader

      // load frame textures
      const textureFrame = context.rootState.config.config.frameKleur.url
      const textureBoeidelen = context.rootState.config.config.boeideelKleur.url
      const textureHoekprofielen = context.rootState.config.config.hoekprofielKleur.url
      const colorTrim = context.rootState.config.config.trimColor.url
      const selectedDakAfwerking = context.rootState.config.config.selectedDakAfwerking
      const fundering = context.rootState.config.config.fundering
      const walls = context.rootState.config.config.muren
      const topgevels = context.rootState.config.config.topgevels
      const dakgoot = context.rootState.config.config.dakgoot
      const lichtstraten = context.rootState.config.config.lichtstraten
      console.log("WALLS", walls, context.rootState.config.history.getCurrent())
      const retourLink = context.getters.getIsRetourLink

      const modelSettings = {
        minWallWidth: context.rootState.config.config.wall.minWallWidth,
        needsSpaceForStaander: context.rootState.config.config.wall.needsSpaceForStaander,
      }

      const modelsArray = []
      // console.log('store-frame.js walls',walls)
      walls.forEach(wall => {
        wall.modelArray.forEach(modelArrayItem => {
          if(modelArrayItem) {
            // console.log('for each - modelArrayItem',modelArrayItem)
            // console.log(modelArrayItem.color)
            // console.log(JSON.parse(JSON.stringify(modelsArray.includes(modelArrayItem))),JSON.parse(JSON.stringify(modelsArray)), JSON.parse(JSON.stringify(modelArrayItem)))
            // console.log('modelsArray',modelsArray)

            // if(!modelsArray.filter(e => ((modelArrayItem.color && e.color) ? e.color.code === modelArrayItem.color.code : e.code === modelArrayItem.code) && e.option.subCode === modelArrayItem.option.subCode).length) {
            //   modelsArray.push(modelArrayItem)
            // }

            // use lastCode - TASK-1568
            // console.log('modelArrayItem.lastCode',modelArrayItem.lastCode,modelArrayItem.code,modelArrayItem.color.code)
            if(!modelsArray.filter(e => (
                modelArrayItem.lastCode ? e.lastCode === modelArrayItem.lastCode :
                (modelArrayItem.color && e.color) ? e.color.code === modelArrayItem.color.code : 
                e.code === modelArrayItem.code
                ) 
              && e.option.subCode === modelArrayItem.option.subCode).length) {
              // console.log('parse modelArrayItem',JSON.parse(JSON.stringify(modelArrayItem)));
              modelsArray.push(modelArrayItem);
            }

            /* await gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelArrayItem.color == 0 || !modelArrayItem.color ? modelArrayItem.code : modelArrayItem.color.code}${modelArrayItem.options.length > 0 ? modelArrayItem.option.subCode : ''}.glb`, async(glb) => {
              await gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${modelArrayItem.code}${modelArrayItem.options.length > 0 ? modelArrayItem.options[0].subCode : ''}.glb`, async(glb_map) => {
                glb.scene.scale.multiplyScalar(1000)
                glb_map.scene.scale.multiplyScalar(1000)

        
                modelsArray.push({
                  code: modelArrayItem.code,
                  color: modelArrayItem.color,
                  option: modelArrayItem.option,
                  side: modelArrayItem.side,
                  model: glb.scene,
                  map: glb_map.scene
                })
              })
            }) */


          }
        })
        // console.log('wall',wall)
        if(wall.isSteellook) {
          // console.log('isSteellook wall',wall,JSON.parse(JSON.stringify(modelsArray)))
          // add lastCode - TASK-1568
          if(!modelsArray.filter(e => e.code === wall.modelCode && e.lastCode === wall.modelCode ).length) {
            modelsArray.push({code: wall.modelCode, lastCode: wall.modelCode, option: {}})
          }
        }

        if (wall.isBorstwering) {
          if(!modelsArray.filter(e => e.code === wall.modelCode && e.lastCode === wall.modelCode ).length) {
            modelsArray.push({code: wall.modelCode, lastCode: wall.modelCode, option: {}})
          }
        }
      })
      // console.log('modelsArray after for each',JSON.parse(JSON.stringify(modelsArray)))

      const modelsObjArray = []

      loadModel()

      async function loadModel() {
        if(modelsArray[0]) {
          const modelArrayItem = modelsArray[0]

          // Refactoring to use lastCode - TASK-1568
          const modelSubcode = (modelArrayItem.option?modelArrayItem.option.subCode:false) ? modelArrayItem.option.subCode : ''
          let modelCode = !(modelArrayItem.color?modelArrayItem.color.code:false) ? modelArrayItem.code : modelArrayItem.color.code
          // see: ComponentCard.vue for lastCode
          console.log('loadModel() modelArrayItem',modelArrayItem)
          if (modelArrayItem.lastCode) modelCode = modelArrayItem.lastCode;

          // console.log(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${!(modelArrayItem.color?modelArrayItem.color.code:false) ? modelArrayItem.code : modelArrayItem.color.code}${(modelArrayItem.option?modelArrayItem.option.subCode:false) ? modelArrayItem.option.subCode : ''}.glb`)
          // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${!(modelArrayItem.color?modelArrayItem.color.code:false) ? modelArrayItem.code : modelArrayItem.color.code}${(modelArrayItem.option?modelArrayItem.option.subCode:false) ? modelArrayItem.option.subCode : ''}.glb`, async(glb) => {
            // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${modelArrayItem.code}${(modelArrayItem.option?modelArrayItem.option.subCode:false) ? modelArrayItem.option.subCode : ''}.glb`, async(glb_map) => {
          console.log(`${envServ}${envLoc}${modelCode}${modelSubcode}.glb`)
          gltfLoader.load(`${envServ}${envLoc}${modelCode}${modelSubcode}.glb`, async(glb) => {            
            gltfLoader.load(`${envServ}${envLoc}plattegrond/${modelCode}${modelSubcode}.glb`, async(glb_map) => {
              glb.scene.scale.multiplyScalar(1000)
              let component = glb.scene
              component.traverse(n => {
                if(n.isMesh) {
                  n.castShadow = true
                  n.receiveShadow = true
                  if(n.material) {
                    if(Array.isArray(n.material)) {
                      n.material.forEach((a) => {
                        a.metalness = 0;
                        // console.log(a)
                        if(a.material.map) {
                          a.material.map.anisotropy = 16
                          a.material.map.encoding = THREE.sRGBEncoding
                          a.material.map.flipY = false;
                        }
                      })
                    } else {
                      n.metalness = 0;
                      // console.log(n)
                      if(n.material.map){
                        n.material.map.anisotropy = 16
                        n.material.map.encoding = THREE.sRGBEncoding
                        n.material.map.flipY = false;
                      }
                    }
                  }
                }
              })
              glb_map.scene.scale.multiplyScalar(1000)


              const graphqlQuery = {
                // getComponentInformation(id: "${modelArrayItem.code}") {
                query: `
                  {
                    getComponentInformation(id: "${modelCode}") {
                      id
                      code
                      combinedCode
                      category
                      name
                      shortName
                      size
                      trueOnline
                      keyWords
                      options {
                        name
                        subCode
                      }
                      colors {
                        name
                        hexCode
                        code
                      }
                      dimensions {
                        x
                        y
                        z
                        xo
                        yo
                        zo
                      }
                      margins {
                        xPos1
                        xPos2
                        xNeg1
                        xNeg2
                        yPos1
                        yPos2
                        yNeg1
                        yNeg2
                        zPos1
                        zPos2
                        zNeg1
                        zNeg2
                      }
                      wallOptions {
                        left
                        center
                        right
                      }
                      facadeOptions {
                        left
                        center
                        right
                      }
                    }
                  }
                `
              }
          
              // sending request to server
              const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

      
              modelsObjArray.push({
                // code: modelArrayItem.code,
                code: modelCode, // use lastCode - TASK-1568
                color: modelArrayItem.color,
                option: modelArrayItem.option,
                side: modelArrayItem.side,
                model: glb.scene,
                map: glb_map.scene,
                component: response.data.getComponentInformation
              })

              modelsArray.splice(modelArrayItem,1)
              await loadModel()
            })
          })

        } else {
          console.log(textureHoekprofielen)
          context.commit('setFrame', {THREE, scene, textureLoader, cubeLoader, typeId, modelId, afmetingen, modelSettings, textureFrame, textureBoeidelen, textureHoekprofielen, colorTrim, selectedDakAfwerking, fundering, walls, topgevels, hover, retourLink, modelsObjArray, dakgoot, lichtstraten})
          context.commit('updateIntersectingObjects')
          resolve();
        }
      }

    
    });
    return myPromise



    
  },

  updateFrame (context, {typeId, modelId, afmetingen, shouldNotResetWalls}) {
    context.commit('updateFrame',{ 
      typeId,
      modelId,
      afmetingen,
      modelSettings: {
        minWallWidth: context.rootState.config.config.wall.minWallWidth,
        needsSpaceForStaander: context.rootState.config.config.wall.needsSpaceForStaander,
      },
      fundering: context.rootState.config.config.fundering,
      dakgoot: context.rootState.config.config.dakgoot,
      shouldNotResetWalls,
      context
    })
    context.commit('updateIntersectingObjects')
  },

  resetWalls (context) {
    context.commit('resetWalls')
  },

  resetTopgevels (context) {
    context.commit('resetTopgevels')
  },

  changeWallHover (context, on) {
    context.commit('setWallHover', on)
  },

  setElementPlacing (context, on) {
    context.commit('setElementPlacing', on)
  },

  setIntersection (context, on) {
    context.commit('setIntersectionOn', on)  
  },

  wallHoverEnter (context, data) {
    const hoveredWall = data.object

    let muur = null
    if(data.object.parent.name === "wall") { //if is map element
      muur = state.frame.muurInstances.find((muur) => muur.group.uuid === data.object.parent.uuid)
    } else {
      muur = state.frame.muurInstances.find((muur) => muur.group.uuid === data.object.parent.parent.parent.uuid)
    }

    if(muur == null) {
      return
    }

    const settings = {
      binnenMuur: hoveredWall.name==='hoverLineBinnen' ? true : false, //context.rootState.config.wallInsideOn,
      buitenMuur: hoveredWall.name==='hoverLineBuiten' ? true : false, //context.rootState.config.wallOutsideOn,
      binnenMuurTexture: context.rootState.config.selectedWallColorInside.color, //muur.settings.binnenMuurTexture,
      buitenMuurTexture: context.rootState.config.selectedWallColorOutside.color, //muur.settings.buitenMuurTexture,
      connectionBinnen: context.rootState.config.selectedWallColorInside.connection, //muur.settings.connectionBinnen,
      connectionBuiten: context.rootState.config.selectedWallColorOutside.connection //muur.settings.connectionBuiten
    }
    // console.log(data)
    context.commit('setWallHoverEnter', {wall: hoveredWall, settings: settings, uuid: data.uuid})
  },

  wallHoverLeave (context, hoveredWall) {
    context.commit('setWallHoverLeave', hoveredWall)
  },

  setWallHoverShowInFuture (context, data) {
    context.commit('setWallHoverShowInFuture', data)

    if(data.value !== '') {
      
      let muur = null
      let index
      if(data.wall.parent.name === "wall") { //if is map element
        muur = state.frame.muurInstances.find((muur) => muur.group.uuid === data.wall.parent.uuid)
        index = state.frame.muurInstances.findIndex((muur) => muur.group.uuid === data.wall.parent.uuid)
      } else {
        muur = state.frame.muurInstances.find((muur) => muur.group.uuid === data.wall.parent.parent.parent.uuid)
        index = state.frame.muurInstances.findIndex((muur) => muur.group.uuid === data.wall.parent.parent.parent.uuid)
      }
      
      if(muur == null) {
        return
      }

      const isTopgevel = muur.isTopgevel
      const isTussenMuur = muur.settings.tussenMuur

      const buitenSelector = isTopgevel ? (isTussenMuur ? "topgevelLinks" : "topgevelBuiten") : (isTussenMuur ? "tussenWandLinks" : "wandBuiten")
      const binnenSelector = isTopgevel ? (isTussenMuur ? "topgevelRechts" : "topgevelBinnen") : (isTussenMuur ? "tussenWandRechts" : "wandBinnen")

      const availableWallFinsihes = context.getters.getAvailableWallFinishes

      let binnenMuurFinishes = availableWallFinsihes[binnenSelector]
      let buitenMuurFinishes = availableWallFinsihes[buitenSelector]
      console.log(buitenMuurFinishes)
      console.log(JSON.parse(JSON.stringify(binnenMuurFinishes)), JSON.parse(JSON.stringify(context.rootState.config.selectedWallColorInside.color)))

      let textureBinnen = context.rootState.config.selectedWallColorInside.color
      let connectionBinnen = context.rootState.config.selectedWallColorInside.connectionBinnen
      const binnenPlankTypeAllowed = binnenMuurFinishes.find((category) => category.id === textureBinnen.plankType)
      if(binnenPlankTypeAllowed) {
        connectionBinnen = binnenPlankTypeAllowed.connection
        const binnenColorAllowed = binnenPlankTypeAllowed.colors.find((color) => color.id === textureBinnen.id)
        if(!binnenColorAllowed) {
          textureBinnen = binnenPlankTypeAllowed.colors[0]
        } else {
          textureBinnen = binnenColorAllowed
        }
      } else {
        connectionBinnen = binnenMuurFinishes[0].connection
        const binnenColorAllowed = binnenMuurFinishes[0].colors.find((color) => color.id === textureBinnen.id)
        if(!binnenColorAllowed) {
          textureBinnen = binnenMuurFinishes[0].colors[0]
        } else {
          textureBinnen = binnenColorAllowed
        }
      }

      let textureBuiten = context.rootState.config.selectedWallColorOutside.color
      let connectionBuiten = context.rootState.config.selectedWallColorOutside.connectionBuiten
      const buitenPlankTypeAllowed = buitenMuurFinishes.find((category) => category.id === textureBuiten.plankType)
      if(buitenPlankTypeAllowed) {
        connectionBuiten = buitenPlankTypeAllowed.connection
        const buitenColorAllowed = buitenPlankTypeAllowed.colors.find((color) => color.id === textureBuiten.id)
        if(!buitenColorAllowed) {
          textureBuiten = buitenPlankTypeAllowed.colors[0]
        } else {
          textureBuiten = buitenColorAllowed
        }
      } else {
        connectionBuiten = buitenMuurFinishes[0].connection
        const buitenColorAllowed = buitenMuurFinishes[0].colors.find((color) => color.id === textureBuiten.id)
        if(!buitenColorAllowed) {
          textureBuiten = buitenMuurFinishes[0].colors[0]
        } else {
          textureBuiten = buitenColorAllowed
        }
      }

      let settings = {
        binnenMuur: muur.settings.binnenMuur,
        buitenMuur: muur.settings.buitenMuur,
        binnenMuurTexture: textureBinnen, 
        buitenMuurTexture: textureBuiten,
        connectionBinnen: connectionBinnen,
        connectionBuiten: connectionBuiten
      }

      //if(muur.settings.binnenMuurTexture.id != settings.binnenMuurTexture.id || muur.settings.buitenMuurTexture.id != settings.buitenMuurTexture.id) {
      console.log("settings.binnenMuurTexture.url", settings.binnenMuurTexture.url)
      context.commit('updateWall',{ 
        settings: settings,
        context: context,
        index: index,
        onlyUpdatePreview: true,
      })
      //}

      console.log(data, muur)
    }
  },

  topgevelHoverEnter (context, data) {
    const hoveredTopgevel = data.object
    context.commit('setTopgevelHoverEnter', {topgevel: hoveredTopgevel, uuid: data.uuid})
  },

  topgevelHoverLeave (context, hoveredTopgevel) {
    context.commit('setTopgevelHoverLeave', hoveredTopgevel)
  },

  setTopgevelHoverShowInFuture (context, data) {
    context.commit('setTopgevelHoverShowInFuture', data)
  },

   /**
   * Toggles the visibility and position of button groups based on current activation status and view type.
   * @param {Object} context - The Vuex context object.
   * @param {Object} payload - The object containing the parameters. Please don't change the property names.
   * @param {string} payload.currentStatus - The current status of the button group ('Active' or 'Inactive').
   * @param {Object} payload.buttonGroup - The button group object to be toggled.
   * @param {string} payload.viewType - The view type ('Hover' for 3D or 'Map' for 2D).
   * @param {boolean} [payload.skipDataUpdate=false] - Flag indicating if we are skipping data changes for wall settings.
   * @returns {void}
   */
  toggleWandIsolatie (context, {currentStatus, buttonGroup, viewType, skipDataUpdate=false}) {
    // if skipDataUpdate === true, you can assume that the data update has already been triggered via submenu UI
    if(!currentStatus || !buttonGroup || !viewType) {
      console.error(`Missing parameter(s):${currentStatus ? '' : ' currentStatus'}${buttonGroup ? '' : ' buttonGroup'}${viewType ? '' : ' viewType'}.`);
      return;
    }
    if(currentStatus !== 'Active' && currentStatus !== 'Inactive') {
      console.error('Parameter contains unknown status.');
      return;
    }
    const currentGroup = buttonGroup;
    const mainGroup = buttonGroup.parent;
    console.log('currentStatus',currentStatus,'currentGroup',currentGroup,'mainGroup',mainGroup)

    let wall; 
    // example: isolatieHoverButtonGroupActive > isolatieHoverButtonMainGroup > hoverLineGroup > wall
    if(viewType === 'Hover') wall = buttonGroup.parent.parent.parent;
    if(viewType === 'Map') wall = buttonGroup.parent.parent;
    // const muurWithSettings = state.frame.muurInstances.find((muur) => muur.group.uuid === wall.uuid)
    let muurWithSettings;
    if(viewType === 'Hover') muurWithSettings = state.frame.muurInstances.find((muur) => muur.group.uuid === wall.uuid);
    if(viewType === 'Map') muurWithSettings = state.frame.muurInstances.find((muur) => muur.mapGroup.uuid === wall.uuid);
    // console.log('muur instance with settings',muurWithSettings);

    // add opposite status to settings
    if(!skipDataUpdate) {
      muurWithSettings.heeftIsolatie = currentStatus === 'Inactive' ? true : false;
      muurWithSettings.settings.heeftIsolatie = muurWithSettings.heeftIsolatie;
      if (currentStatus === 'Inactive' && !muurWithSettings.isolatieId) {
        // set default
        muurWithSettings.isolatieId = context.getters.getIsolatieTypeDefault.id;
        muurWithSettings.settings.isolatieId = muurWithSettings.isolatieId;
      }
    }

    // switch between map and hover options for the correct name
    let targetName = '';
    let secondaryTargetName = '';
    switch (viewType) {
      case 'Hover':
        targetName = 'isolatieHoverButtonGroup';
        secondaryTargetName = 'isolatieMapButtonGroup';
        break;
      case 'Map':
        targetName = 'isolatieMapButtonGroup';
        secondaryTargetName = 'isolatieHoverButtonGroup';
        break;
      default:
        break;
    }
    // append opposite status to targetName
    targetName = `${targetName}${currentStatus === 'Active' ? 'Inactive' : 'Active'}`;

    if(currentStatus === 'Inactive') {
      currentGroup.visible = false;
      // lower the position of the grey button to help the raycaster hit the green button on a new click
      currentGroup.position.y = -1;
      const ONbuttonGroup = mainGroup.children.find((child) => child.name === targetName/*'isolatieHoverButtonGroupActive'*/);
      ONbuttonGroup.visible = true;
      ONbuttonGroup.position.y = 0;
      // console.log('ONbuttonGroup',ONbuttonGroup,'position',ONbuttonGroup.position)
      // also set the button status on the other view
      let theOtherViewGroup;
      if(viewType==='Hover') theOtherViewGroup = muurWithSettings.mapIsoMainGroup;
      if(viewType==='Map') theOtherViewGroup = muurWithSettings.hoverIsoMainGroup;
      const theOtherOFFbuttonGroup = theOtherViewGroup.children.find((child) => child.name === `${secondaryTargetName}Inactive`);
      const theOtherONbuttonGroup = theOtherViewGroup.children.find((child) => child.name === `${secondaryTargetName}Active`);
      // hide OFF button and reveal ON button
      theOtherOFFbuttonGroup.visible = false;
      theOtherOFFbuttonGroup.position.y = -1;
      theOtherONbuttonGroup.visible = true;
      theOtherONbuttonGroup.position.y = 0;
    } else if (currentStatus === 'Active') {
      currentGroup.visible = false;
      // lower the position of the green button to help the raycaster hit the grey button on a new click
      currentGroup.position.y = -1;
      const OFFbuttonGroup = mainGroup.children.find((child) => child.name === targetName/*'isolatieHoverButtonGroupInactive'*/);
      OFFbuttonGroup.visible = true;
      OFFbuttonGroup.position.y = 0;
      // console.log('OFFbuttonGroup',OFFbuttonGroup,'position',OFFbuttonGroup.position)
      // also set the button status on the other view
      let theOtherViewGroup;
      if(viewType==='Hover') theOtherViewGroup = muurWithSettings.mapIsoMainGroup;
      if(viewType==='Map') theOtherViewGroup = muurWithSettings.hoverIsoMainGroup;
      const theOtherONbuttonGroup = theOtherViewGroup.children.find((child) => child.name === `${secondaryTargetName}Active`);
      const theOtherOFFbuttonGroup = theOtherViewGroup.children.find((child) => child.name === `${secondaryTargetName}Inactive`);
      // hide ON button and reveal OFF button
      theOtherONbuttonGroup.visible = false;
      theOtherONbuttonGroup.position.y = -1;
      theOtherOFFbuttonGroup.visible = true;
      theOtherOFFbuttonGroup.position.y = 0;
    }
    // console.log('currentGroup.position',currentGroup.position);
  },

  toggleMapTopgevelIsolatie(context, {currentStatus, buttonGroup, skipDataUpdate=false}) {
		if(!currentStatus || !buttonGroup) {
		  console.error(`Missing parameter(s):${currentStatus ? '' : ' currentStatus'}${buttonGroup ? '' : ' buttonGroup'}.`);
		  return;
		}
		if(currentStatus !== 'Active' && currentStatus !== 'Inactive') {
		  console.error('Parameter contains unknown status.');
		  return;
		}
		const currentGroup = buttonGroup;
		const mainGroup = buttonGroup.parent;
		// console.log('currentStatus',currentStatus,'currentGroup',currentGroup,'mainGroup',mainGroup,'skipDataUpdate',skipDataUpdate)

    let gevelWithSettings;
    // console.log('topgevelArray:',state.frame.topgevel.topgevelArray);
    // state.frame.topgevel.topgevelArray.find(topgevel => wall.parent.id === topgevel.group.id)
    gevelWithSettings = state.frame.topgevel.topgevelArray.find(item => mainGroup.uuid === item.mapTopIsoMainGroup.uuid);
    // console.log('gevelWithSettings:',gevelWithSettings);

    // add opposite status to settings
    if(!skipDataUpdate) {
      gevelWithSettings.heeftIsolatie = currentStatus === 'Inactive' ? true : false;
      if (currentStatus === 'Inactive' && !gevelWithSettings.isolatieId) {
        // set default
        gevelWithSettings.isolatieId = context.getters.getIsolatieTypeDefault.id;
      }
      // activate store method
      context.dispatch('updateTopgevelArrayConfig', state.frame.topgevel.topgevelArray)
    }

    // append opposite status to targetName
    const targetName = `topIsolatieMapButtonGroup${currentStatus === 'Active' ? 'Inactive' : 'Active'}`;
    // get ready to change the 3D view
    const secondaryTarget = 'topIsolatieHoverButtonGroup';
    const hoverViewGroup = gevelWithSettings.hoverTopIsoMainGroup;
    const hoverOFFbutton = hoverViewGroup.children.find((child) => child.name === `${secondaryTarget}Inactive`)
    .children.find((child) => child.name === "topIsolatieHoverButtonInactive");
    const hoverONbutton = hoverViewGroup.children.find((child) => child.name === `${secondaryTarget}Active`)
    .children.find((child) => child.name === "topIsolatieHoverButtonActive");

    if(currentStatus === 'Inactive') { 
      currentGroup.visible = false;
      // lower the position of the grey button to help the raycaster hit the green button on a new click
      currentGroup.position.y = -1;
      const ONbuttonGroup = mainGroup.children.find((child) => child.name === targetName);
      ONbuttonGroup.visible = true;
      ONbuttonGroup.position.y = 0;
      // also set the button status on the other view
      // hide OFF button and reveal ON button
      hoverOFFbutton.visible = false;
      hoverONbutton.visible = true;
    } else if(currentStatus === 'Active') {
      currentGroup.visible = false;
      // lower the position of the green button to help the raycaster hit the grey button on a new click
      currentGroup.position.y = -1;
      const OFFbuttonGroup = mainGroup.children.find((child) => child.name === targetName);
      OFFbuttonGroup.visible = true;
      OFFbuttonGroup.position.y = 0;
      // also set the button status on the other view
      // hide ON button and reveal OFF button
      hoverONbutton.visible = false;
      hoverOFFbutton.visible = true;
    }
	},

  toggleHoverTopgevelIsolatie(context, {currentStatus, buttonGroup, skipDataUpdate=false}) {
		if(!currentStatus || !buttonGroup) {
		  console.error(`Missing parameter(s):${currentStatus ? '' : ' currentStatus'}${buttonGroup ? '' : ' buttonGroup'}.`);
		  return;
		}
		if(currentStatus !== 'Active' && currentStatus !== 'Inactive') {
		  console.error('Parameter contains unknown status.');
		  return;
		}
		const currentGroup = buttonGroup;
		const mainGroup = buttonGroup.parent;
		console.log('currentStatus',currentStatus,'currentGroup',currentGroup,'mainGroup',mainGroup,'skipDataUpdate',skipDataUpdate)

    let gevelWithSettings;
    gevelWithSettings = state.frame.topgevel.topgevelArray.find(item => mainGroup.uuid === item.hoverTopIsoMainGroup.uuid);

    // add opposite status to settings
    if(!skipDataUpdate) {
      gevelWithSettings.heeftIsolatie = currentStatus === 'Inactive' ? true : false;
      if (currentStatus === 'Inactive' && !gevelWithSettings.isolatieId) {
        // set default
        gevelWithSettings.isolatieId = context.getters.getIsolatieTypeDefault.id;
      }
      // activate store method
      context.dispatch('updateTopgevelArrayConfig', state.frame.topgevel.topgevelArray)
    }

    // append opposite status to targetName
    const targetName = `topIsolatieHoverButtonGroup${currentStatus === 'Active' ? 'Inactive' : 'Active'}`;
    // get ready to change the 2D view
    const secondaryTarget = 'topIsolatieMapButtonGroup';
    const mapViewGroup = gevelWithSettings.mapTopIsoMainGroup;
    const mapONbuttonGroup = mapViewGroup.children.find((child) => child.name === `${secondaryTarget}Active`);
    const mapOFFbuttonGroup = mapViewGroup.children.find((child) => child.name === `${secondaryTarget}Inactive`);

    if(currentStatus === 'Inactive') { 
      currentGroup.getObjectByName("topIsolatieHoverButtonInactive").visible = false;
      const ONbuttonGroup = mainGroup.children.find((child) => child.name === targetName);
      ONbuttonGroup.getObjectByName("topIsolatieHoverButtonActive").visible = true;
      // also set the button status on the other view
      // hide OFF button and reveal ON button
      mapOFFbuttonGroup.visible = false;
      mapOFFbuttonGroup.position.y = -1;
      mapONbuttonGroup.visible = true;
      mapONbuttonGroup.position.y = 0;
    } else if(currentStatus === 'Active') {
      currentGroup.getObjectByName("topIsolatieHoverButtonActive").visible = false;
      const OFFbuttonGroup = mainGroup.children.find((child) => child.name === targetName);
      OFFbuttonGroup.getObjectByName("topIsolatieHoverButtonInactive").visible = true;
      // also set the button status on the other view
      // hide ON button and reveal OFF button
      mapONbuttonGroup.visible = false;
      mapONbuttonGroup.position.y = -1;
      mapOFFbuttonGroup.visible = true;
      mapOFFbuttonGroup.position.y = 0;
    }
    // console.log('mapOFFbuttonGroup',mapOFFbuttonGroup)
    // console.log('mapONbuttonGroup',mapONbuttonGroup)
    context.commit('updateIntersectingObjects')
	},

  addWall (context, wall) {
    // console.log(wall)
    let muur = null
    if(wall.parent.name === "wall") { //if is map element
      muur = state.frame.muurInstances.find((muur) => muur.group.uuid === wall.parent.uuid)
    } else {
      muur = state.frame.muurInstances.find((muur) => muur.group.uuid === wall.parent.parent?.parent?.uuid)
    }
    console.log(muur)
    if (!muur) return
    //alert(wall.name==='hoverLineBinnen' ? 'plaats binnenmuur' : wall.name==='hoverLineBuiten' ? 'plaats buitenmuur' : 'doe niks')
    console.log(JSON.parse(JSON.stringify(context.rootState.config.selectedWallColorOutside.color)))

    const isTopgevel = muur.isTopgevel
    const isTussenMuur = muur.settings.tussenMuur

    const buitenSelector = isTopgevel ? (isTussenMuur ? "topgevelLinks" : "topgevelBuiten") : (isTussenMuur ? "tussenWandLinks" : "wandBuiten")
    const binnenSelector = isTopgevel ? (isTussenMuur ? "topgevelRechts" : "topgevelBinnen") : (isTussenMuur ? "tussenWandRechts" : "wandBinnen")

    const availableWallFinsihes = context.getters.getAvailableWallFinishes

    let binnenMuurFinishes = availableWallFinsihes[binnenSelector]
    let buitenMuurFinishes = availableWallFinsihes[buitenSelector]
    console.log(buitenMuurFinishes)
    console.log(JSON.parse(JSON.stringify(binnenMuurFinishes)), JSON.parse(JSON.stringify(context.rootState.config.selectedWallColorInside.color)))

    let textureBinnen = context.rootState.config.selectedWallColorInside.color
    let connectionBinnen = context.rootState.config.selectedWallColorInside.connectionBinnen
    const binnenPlankTypeAllowed = binnenMuurFinishes.find((category) => category.id === textureBinnen.plankType)
    if(binnenPlankTypeAllowed) {
      connectionBinnen = binnenPlankTypeAllowed.connection
      const binnenColorAllowed = binnenPlankTypeAllowed.colors.find((color) => color.id === textureBinnen.id)
      if(!binnenColorAllowed) {
        textureBinnen = binnenPlankTypeAllowed.colors[0]
      } else {
        textureBinnen = binnenColorAllowed
      }
    } else {
      connectionBinnen = binnenMuurFinishes[0].connection
      const binnenColorAllowed = binnenMuurFinishes[0].colors.find((color) => color.id === textureBinnen.id)
      if(!binnenColorAllowed) {
        textureBinnen = binnenMuurFinishes[0].colors[0]
      } else {
        textureBinnen = binnenColorAllowed
      }
    }

    let textureBuiten = context.rootState.config.selectedWallColorOutside.color
    let connectionBuiten = context.rootState.config.selectedWallColorOutside.connectionBuiten
    const buitenPlankTypeAllowed = buitenMuurFinishes.find((category) => category.id === textureBuiten.plankType)
    if(buitenPlankTypeAllowed) {
      connectionBuiten = buitenPlankTypeAllowed.connection
      const buitenColorAllowed = buitenPlankTypeAllowed.colors.find((color) => color.id === textureBuiten.id)
      if(!buitenColorAllowed) {
        textureBuiten = buitenPlankTypeAllowed.colors[0]
      } else {
        textureBuiten = buitenColorAllowed
      }
    } else {
      connectionBuiten = buitenMuurFinishes[0].connection
      const buitenColorAllowed = buitenMuurFinishes[0].colors.find((color) => color.id === textureBuiten.id)
      if(!buitenColorAllowed) {
        textureBuiten = buitenMuurFinishes[0].colors[0]
      } else {
        textureBuiten = buitenColorAllowed
      }
    }

    console.log(JSON.parse(JSON.stringify(textureBinnen)), JSON.parse(JSON.stringify(textureBuiten)))
    

    context.commit('addWall', {
      wall: wall, 
      settings: {
        binnenMuur: wall.name==='hoverLineBinnen' ? true : false,// context.rootState.config.wallInsideOn,
        buitenMuur: wall.name==='hoverLineBuiten' ? true : false,//context.rootState.config.wallOutsideOn,
        binnenMuurTexture: wall.name==='hoverLineBinnen' ? textureBinnen : muur.settings.binnenMuurTexture,
        buitenMuurTexture: wall.name==='hoverLineBuiten' ? textureBuiten : muur.settings.buitenMuurTexture,
        connectionBinnen: wall.name==='hoverLineBinnen' ? connectionBinnen : muur.settings.connectionBinnen,
        connectionBuiten: wall.name==='hoverLineBuiten' ? connectionBuiten : muur.settings.connectionBuiten,
        // heeftIsolatie: context.rootState.config.wallIsolatieStatus ? true : false
        
        // binnenMuur: wall.name==='hoverLineBinnen' ? true : false,// context.rootState.config.wallInsideOn,
        // buitenMuur: wall.name==='hoverLineBuiten' ? true : false,//context.rootState.config.wallOutsideOn,
        // binnenMuurTexture: context.rootState.config.selectedWallColorInside.color,
        // buitenMuurTexture: context.rootState.config.selectedWallColorOutside.color,
        // connectionBinnen: context.rootState.config.selectedWallColorInside.connection,
        // connectionBuiten: context.rootState.config.selectedWallColorOutside.connection
      },
      context: context,
    })

    if(context.getters.getConfig.afmetingen.wall.position === "buiten" && wall.name==='hoverLineBuiten' && !textureBuiten.open) {
      context.commit('setBoeideelColor', textureBuiten)
      context.dispatch('updateBoeideelTexture', textureBuiten)
    }
    
    context.commit('updateIntersectingObjects')
  },

  removeWall (context, wall) {
    context.commit('removeWall', wall)
  },

  updateWall (context, {index, settings, updateEntireWall}) {
    if (settings) {
      context.commit('updateWall',{ 
        settings: settings,
        context: context,
        index: index,
        updateEntireWall: updateEntireWall
      })
    } else {
      context.commit('updateWall',{
        wall: context.state.selectedWall.wall, 
        settings: {
          binnenMuur: context.rootState.config.wallInsideOn,
          buitenMuur: context.rootState.config.wallOutsideOn,
          binnenMuurTexture: context.rootState.config.selectedWallColorInside.color,
          buitenMuurTexture: context.rootState.config.selectedWallColorOutside.color,
          connectionBinnen: context.rootState.config.selectedWallColorInside.connection,
          connectionBuiten: context.rootState.config.selectedWallColorOutside.connection,
          // heeftIsolatie: context.rootState.config.wallIsolatieStatus ? true : false
        },
        context: context,
        index: index,
        updateEntireWall: updateEntireWall
      })

      if (!context.rootState.config.wallInsideOn && !context.rootState.config.wallOutsideOn) {
        context.commit('setWallDefault')
      }
    }

    context.commit('updateIntersectingObjects')
  },

  updateWallWidth (context, {index, settings}) {
    context.commit('updateWallWidth',{ 
      settings: settings,
      index: index,
    })
  },

  updateWallPosition (context, {index, settings}) {
    context.commit('updateWallPosition',{ 
      settings: settings,
      index: index,
    })
  },

  updateWallHoles (context, data/*wall, wcomponent, settings*/) {
    // console.log(data.component)
    console.log("doorWindowData",data,context.rootState.models.selectedWallComponent)
    const model = data.component//context.rootState.models.selectedWallComponent
    const modelSettings = data.settings//context.rootState.models.selectedWallComponentSettings
    // console.log(modelSettings)
    // console.log(context)
    const { x, y, z, xo, yo, zo } = model.dimensions

    let component

    // console.log(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelSettings.color == 0 ? model.code : modelSettings.color.code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`)
    // console.log(`${model.code}${model.options.length > 0 ? model.options[0].subCode : ''}.glb`)
    // console.log('modelSettings',modelSettings)
    const modelSubcode = model.options.length > 0 ? modelSettings.option.subCode : ''
    let modelCode = modelSettings.color == 0 || !modelSettings.color ? model.code : modelSettings.color.code
    // see: ComponentCard.vue
    if (modelSettings.lastCode) modelCode = modelSettings.lastCode;

    // console.log('store-frame',model, modelSettings, `${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelSettings.color == 0 || !modelSettings.color ? model.code : modelSettings.color.code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`)
    // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelSettings.color == 0 || !modelSettings.color ? model.code : modelSettings.color.code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`, (glb) => {    
    gltfLoader.load(`${envServ}${envLoc}${modelCode}${modelSubcode}.glb`, (glb) => {
      // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${model.code}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`, (glb_map) => {
      gltfLoader.load(`${envServ}${envLoc}plattegrond/${modelCode}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`, (glb_map) => {        
        glb.scene.scale.multiplyScalar(1000)
        component = glb.scene
        component.traverse(n => {
          if(n.isMesh) {
            n.castShadow = true
            n.receiveShadow = true
            if(n.material) {
              if(Array.isArray(n.material)) {
                n.material.forEach((a) => {
                  a.metalness = 0;
                  console.log(a)
                  if(a.material.map) {
                    a.material.map.anisotropy = 16
                    a.material.map.encoding = context.state.frame.THREE.sRGBEncoding
                    a.material.map.flipY = false;
                  }
                })
              } else {
                n.metalness = 0;
                console.log(n)
                if(n.material.map){
                  n.material.map.anisotropy = 16
                  n.material.map.encoding = context.state.frame.THREE.sRGBEncoding
                  n.material.map.flipY = false;
                }
              }
            }
          }
        })
        glb_map.scene.scale.multiplyScalar(1000)

        context.commit('updateWallHoles', {
          wall: data.wall,
          holes: [
            {x: x, y: y, z: z, xo: xo, yo: yo, zo: zo}
          ],
          model: component,
          map: glb_map.scene,
          component: data.component,
          settings: data.settings,
        })
        context.commit('updateConfig')
      })
    })
  },

  addSteelLook( context, data) {
    console.log(data.settings)
    const model = context.rootState.models.selectedWallComponent
    const modelSettings = context.rootState.models.selectedWallComponentSettings
    // console.log(model)
    const { x, y, z, xo, yo, zo } = model.dimensions

    let component

    // console.log(model)
    console.log('modelSettings',modelSettings)

    let code = modelSettings.color ? modelSettings.color.code : model.code
    // see: ComponentCard.vue
    if (modelSettings.lastCode) code = modelSettings.lastCode;

    // console.log("PATH", `${import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`)
    gltfLoader.load(`${import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`, (glb) => {
      // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${model.code}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`, (glb_map) => {
      gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${code}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`, (glb_map) => {        
        glb.scene.scale.multiplyScalar(1000)
        component = glb.scene
        component.traverse(n => {
          if(n.isMesh) {
            n.castShadow = true
            n.receiveShadow = true
            if(n.material) {
              if(Array.isArray(n.material)) {
                n.material.forEach((a) => {
                  a.metalness = 0;
                  console.log(a)
                  if(a.material.map) {
                    a.material.map.anisotropy = 16
                    a.material.map.encoding = context.state.frame.THREE.sRGBEncoding
                    a.material.map.flipY = false;
                  }
                })
              } else {
                n.metalness = 0;
                console.log(n)
                if(n.material.map){
                  n.material.map.anisotropy = 16
                  n.material.map.encoding = context.state.frame.THREE.sRGBEncoding
                  n.material.map.flipY = false;
                }
              }
            }
          }
        })
        glb_map.scene.scale.multiplyScalar(1000)

        context.commit('addSteelLookComponent', {
          wall: data.wall,
          dimensions: {x: x, y: y, z: z, xo: xo, yo: yo, zo: zo},
          model: component,
          map: glb_map.scene,
          component: data.component,
          settings: data.settings,
          modelCode: `${code}${model.options.length > 0 ? modelSettings.option.subCode : ''}`
        })
        context.commit('updateConfig')
      })
    })
  },

  addSchuifwand(context, data) {
    context.commit('addSschuifwandComponent', {
      wall: data.wall,
      settings: data.settings
    })
    context.commit('updateConfig')
  },

  selectWall (context, {wall, clickedOnBorstweringAsset}) {
    console.log('store-frame.js setSelectedWall',wall)
    context.commit('setSelectedWall', {wall, clickedOnBorstweringAsset})
    const muur = context.state.frame.muurInstances.find((muur) => muur.group.uuid === wall.parent.uuid)

    if (!muur) {
      if (location.hostname === 'localhost' || location.hostname === 'tw-js-dev.mtest.nl') console.log('Cannot find muur in muurInstances.')
      return;
    }

    context.commit('setWallIsolatieStatus', muur.heeftIsolatie);
    if(wall.parent.name != "wallBox" && context.state.selectedWall && !muur.isSlidingWall) {
      context.commit('setWallUI', context.state.selectedWall)
    }
  },

  deselectWall (context) {
    context.commit('removeSelectedWall')
    context.commit('setWallIsolatieStatus', false);
  },

  addTopgevel (context, data) {
    context.commit('addTopgevel', {
      topgevel: data, 
      settings: {
        topgevelBinnen: context.rootState.config.wallInsideOn,
        topgevelBuiten: context.rootState.config.wallOutsideOn,
        topgevelBinnenTexture: context.rootState.config.selectedWallColorInside.color,
        topgevelBuitenTexture: context.rootState.config.selectedWallColorOutside.color,
      },
      context: context
    })
    context.commit('updateIntersectingObjects')
    context.commit('updateConfig')
  },

  selectTopgevel (context, topgevel) {
    //context.commit('setSelectedWall', topgevel)
    context.commit('selectTopgevel', topgevel)
  },

  enableMapMode (context, val) {
    context.commit('setMapMode', val)
    context.commit('updateIntersectingObjects')
  },

  toggleFullScreen(context) {
    context.commit('setFullScreen', !context.state.fullScreen)
    window.parent.postMessage({
      name: 'TW_CONFIGURATOR',
      action: 'FULLSCREEN',
      value: context.state.fullScreen
    }, '*');
  },

  selectElement (context, obj) {
    context.commit('setSelectedElement', obj)
  },

  deselectElement (context) {
    context.commit('removeSelectedElement')
  },

  removeElement (context, obj) {
    context.commit('removeElement', obj)
  },

  changeLockMode (context, obj) {
    context.commit('changeLockMode', obj)
  },

  changeElement (context, data) {
    context.commit('removeElement', data.obj)
    
    const model = context.rootState.models.selectedWallComponent
    const modelSettings = context.rootState.models.selectedWallComponentSettings
    // console.log('changeElement modelSettings',modelSettings)
    // console.log(context)
    const { x, y, z, xo, yo, zo } = model.dimensions
    //alert("changeElement")
    let component

    let modelCode = modelSettings.color == 0 || !modelSettings.color ? model.code : modelSettings.color.code
    // see: ComponentCard.vue
    if (modelSettings.lastCode) modelCode = modelSettings.lastCode;

    // console.log(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelSettings.color == 0 ? model.code : modelSettings.color.code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`)
    // console.log(`${model.code}${model.options.length > 0 ? model.options[0].subCode : ''}.glb`)
    // console.log(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelSettings.color == 0 || !modelSettings.color ? model.code : modelSettings.color.code}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`)
    // console.log(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${model.code}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`)
    gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelCode}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`, (glb) => {
      // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${model.code}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`, (glb_map) => {
      gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${modelCode}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`, (glb_map) => {
        glb.scene.scale.multiplyScalar(1000)
        component = glb.scene
        component.traverse(n => {
          if(n.isMesh) {
            n.castShadow = true
            n.receiveShadow = true
            if(n.material) {
              if(Array.isArray(n.material)) {
                n.material.forEach((a) => {
                  a.metalness = 0;
                  console.log(a)
                  if(a.material.map) {
                    a.material.map.anisotropy = 16
                    a.material.map.encoding = context.state.frame.THREE.sRGBEncoding
                    a.material.map.flipY = false;
                  }
                })
              } else {
                n.metalness = 0;
                console.log(n)
                if(n.material.map){
                  n.material.map.anisotropy = 16
                  n.material.map.encoding = context.state.frame.THREE.sRGBEncoding
                  n.material.map.flipY = false;
                }
              }
            }
          }
        })
        glb_map.scene.scale.multiplyScalar(1000)

        context.commit('updateWallHoles', {
          wall: data.wall,
          holes: [
            {x: x, y: y, z: z, xo: xo, yo: yo, zo: zo}
          ],
          model: component,
          map: glb_map.scene,
          component: data.component,
          settings: data.settings,
          Ewall: data.Ewall,
          Elocation: data.Elocation,
          Eside: data.Eside
        })
        // let location;
        // if(data.Ewall === undefined) {
        //   location = data.wall.metaData.plusIndex
        // } else {
        //   location = data.Elocation
        // }
        console.log('changeElement() before commit setSelectedElement',data.Ewall.wallButtonIndex, data.Elocation)
        context.commit('setSelectedElement', data.Ewall.wallButtonIndex[data.Elocation].modelBox)
      })
    })
  },

  // Added to support changing variant/glass type etc. on steellook placed as a wall
  changeSelectedSteellookModel (context, data) {
    // context.commit('removeElement', data.obj)
    
    const model = context.rootState.models.selectedWallComponent
    const modelSettings = context.rootState.models.selectedWallComponentSettings
    // console.log('changeElement modelSettings',modelSettings)
    // console.log(context)
    // const { x, y, z, xo, yo, zo } = model.dimensions
    //alert("changeElement")
    let component

    let modelCode = modelSettings.color == 0 || !modelSettings.color ? model.code : modelSettings.color.code
    // see: ComponentCard.vue
    if (modelSettings.lastCode) modelCode = modelSettings.lastCode;

    gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}${modelCode}${model.options.length > 0 ? modelSettings.option.subCode : ''}.glb`, (glb) => {
      // gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${model.code}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`, (glb_map) => {
      gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}plattegrond/${modelCode}${modelSettings.option ? modelSettings.option.subCode : ''}.glb`, (glb_map) => {        
        glb.scene.scale.multiplyScalar(1000)
        component = glb.scene
        component.traverse(n => {
          if(n.isMesh) {
            n.castShadow = true
            n.receiveShadow = true
            if(n.material) {
              if(Array.isArray(n.material)) {
                n.material.forEach((a) => {
                  a.metalness = 0;
                  console.log(a)
                  if(a.material.map) {
                    a.material.map.anisotropy = 16
                    a.material.map.encoding = context.state.frame.THREE.sRGBEncoding
                    a.material.map.flipY = false;
                  }
                })
              } else {
                n.metalness = 0;
                console.log(n)
                if(n.material.map){
                  n.material.map.anisotropy = 16
                  n.material.map.encoding = context.state.frame.THREE.sRGBEncoding
                  n.material.map.flipY = false;
                }
              }
            }
          }
        })
        glb_map.scene.scale.multiplyScalar(1000)

        // context.commit('updateWallHoles', {
        //   wall: data.wall,
        //   holes: [
        //     {x: x, y: y, z: z, xo: xo, yo: yo, zo: zo}
        //   ],
        //   model: component,
        //   map: glb_map.scene,
        //   component: data.component,
        //   settings: data.settings,
        //   Ewall: data.Ewall,
        //   Elocation: data.Elocation,
        //   Eside: data.Eside
        // })
        // let location;
        // if(data.Ewall === undefined) {
        //   location = data.wall.metaData.plusIndex
        // } else {
        //   location = data.Elocation
        // }
        // context.commit('setSelectedElement', data.Ewall.wallButtonIndex[data.Elocation].modelBox)
        // context.commit('setSelectedSteellookModel', {
        //   wall: data.wall, 
        //   component: data.component, 
        //   settings: data.settings,
        //   model: component,
        //   map: glb_map.scene,          
        // })
        if (data.wall.isBorstwering) {
          // is steellook borstwering
          data.wall.setBorstweringModel({
            wall: data.wall,
            dimensions: data.component.dimensions,
            model: component,
            map: glb_map.scene,
            component: data.component,
            settings: data.settings,
            modelCode: modelCode
          }) //{wall, dimensions, model, map, component, settings, modelCode}
        } else {
          // is steellook zonder borstwering
          data.wall.setModel(data,component,glb_map.scene,modelCode)
        }
      })
    })
  },  

  enableMoveBeam (context, data) {
    console.log("enablemovingbeam", data.beam)
    context.commit('setMovingBeam', data)
  },

  disableMoveBeam (context) {
    context.commit('removeMovingBeam')
  },

  updateMovingBeam (context, data) {
    context.commit('updateMovingBeam', data)
  },

  setSelectedLichtstraat (context, data) {
    context.commit('setSelectedLichtstraat', data)
  },

  updateLichtstraatPosition (context, data) {
    context.commit('updateLichtstraatPosition', data)
  },

  addLichtstraat (context, data) {
    context.commit('addLichtstraat', data)
  },

  setWallWidth (context, data) {
    context.commit('setWallWidth', data)
  },

  updateIntersectingObjects (context) {
    context.commit('updateIntersectingObjects')
  },

  calcRemoveWall (context, wall) {
    context.commit('calcRemoveWall', wall)
  },

  setConcreteFoundation (context, value) {
    context.commit('setConcreteFoundation', value)
  },

  // updateFrameTexture(context, color) {
  //   context.commit('updateFrameTexture', color)
  // }

  async renderImages (context, specID) {

    const breedte = context.getters.getSelectedSizes.breedte
    const diepte = context.getters.getSelectedSizes.diepte
    const hoogte = context.getters.getSelectedSizes.hoogte

    // code to calculate horizontal FOV if aspect ratio is not 1:1
    // const verticalFOV = 40; // Vertical FOV in degrees
    // const aspect = 1024 / 1024; // Aspect ratio
    // // Convert vertical FOV to radians
    // const verticalFOVRadians = state.renderData.THREE.MathUtils.degToRad(verticalFOV / 2);
    // // Calculate horizontal FOV in radians
    // const horizontalFOVRadians = 2 * Math.atan(Math.tan(verticalFOVRadians) * aspect);
    // // Convert horizontal FOV to degrees
    // const horizontalFOV = state.renderData.THREE.MathUtils.radToDeg(horizontalFOVRadians);
    // alert('Horizontal FOV: ' + horizontalFOV + "   " + diepte);

    function widthToDistance(width, fov=40) {
      // sinus regel om schuine zijde te berekenen
      const schuineZijde = width/Math.sin(fov*Math.PI/180) * Math.sin((180-fov)/2*Math.PI/180)
      // pythagoras om afstand te berekenen
      const afstand = Math.sqrt(schuineZijde**2 - (width/2)**2)
      return afstand
    }

    function getAfstand(width) {
      return Math.max(widthToDistance(width+1000), widthToDistance(hoogte*2+500))
    }

    const overkappingBreedte = breedte+state.frame.totalOverhang.l+state.frame.totalOverhang.r
    const overkappingDiepte = diepte+state.frame.totalOverhang.a+state.frame.totalOverhang.v

    //camera:   1->normalcamera   2->mapcamera 
    console.log("RV POSITIE", Math.sqrt(getAfstand(overkappingBreedte)**2 + getAfstand(overkappingDiepte)))
    const imagesConfig = [
      {code: 'PG', camera: 2, position: {x: diepte / 2, y: 10000, z: breedte/2 }, target: {x: diepte / 2, y: hoogte / 2, z: breedte/2}},
      {code: 'RV', camera: 1, position: {x: - (getAfstand(overkappingBreedte) + getAfstand(overkappingDiepte))/2, y: hoogte, z: breedte + (getAfstand(overkappingBreedte) + getAfstand(overkappingDiepte))/2}, target: {x: diepte / 2, y: hoogte, z: breedte/2}}, 
      {code: 'LV', camera: 1, position: {x: - (getAfstand(overkappingBreedte) + getAfstand(overkappingDiepte))/2, y: hoogte, z: - (getAfstand(overkappingBreedte) + getAfstand(overkappingDiepte))/2}, target: {x: diepte / 2, y: hoogte, z: breedte/2}},
      {code: 'RA', camera: 1, position: {x: diepte + (getAfstand(overkappingBreedte) + getAfstand(overkappingDiepte))/2, y: hoogte, z: breedte + (getAfstand(overkappingBreedte) + getAfstand(overkappingDiepte))/2}, target: {x: diepte / 2, y: hoogte, z: breedte/2}}, 
      {code: 'LA', camera: 1, position: {x: diepte + (getAfstand(overkappingBreedte) + getAfstand(overkappingDiepte))/2, y: hoogte, z: - (getAfstand(overkappingBreedte) + getAfstand(overkappingDiepte))/2}, target: {x: diepte / 2, y: hoogte, z: breedte/2}},
      {code: 'AZ', camera: 1, position: {x: diepte+getAfstand(overkappingBreedte), y: hoogte, z: breedte/2}, target: {x: diepte / 2, y: hoogte, z: breedte/2}},
      {code: 'RZ', camera: 1, position: {x: diepte / 2, y: hoogte, z: breedte+getAfstand(overkappingDiepte)}, target: {x: diepte / 2, y: hoogte, z: breedte/2}},
      {code: 'VZ', camera: 1, position: {x: -getAfstand(overkappingBreedte), y: hoogte, z: breedte/2}, target: {x: diepte / 2, y: hoogte, z: breedte/2}},
      {code: 'LZ', camera: 1, position: {x: diepte / 2, y: hoogte, z: -getAfstand(overkappingDiepte)}, target: {x: diepte / 2, y: hoogte, z: breedte/2}},
    ]

    const sizeVector = new state.renderData.THREE.Vector2()
    state.renderData.renderer.getSize(sizeVector)
    const cameraStartPosition = state.renderData.camera.position
    const cameraStartTarget = state.renderData.controls.target

    //state.renderData.mapCamera.aspect = 1
    //state.renderData.mapCamera.updateProjectionMatrix();

    console.log(state, context)

    // state.imageNameArray = []

    const isMobileRegex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobile = isMobileRegex.test(navigator.userAgent)

    // dit lijkt niet te werken:
    if(!isMobile) {
      state.renderData.ssaoEffect.spp = 32
      state.renderData.ssaoEffect.samples = 32
    }

    await new Promise((resolve) => setTimeout(resolve, 100))
    for(let i=0; i<imagesConfig.length; i++) {
      const imageConfig = imagesConfig[i]

      state.renderData.scene.getObjectByName("FrameGroup").visible=imageConfig.camera === 1;
      state.renderData.scene.getObjectByName("ModelGroup").visible=imageConfig.camera === 1;
      state.renderData.scene.getObjectByName("MapGroup").visible=imageConfig.camera === 2;

      if(imageConfig.camera === 1) {
        state.renderData.renderer.setSize(1024,1024)
        state.renderData.composer.setSize(1024,1024)
        state.renderData.camera.aspect = 1
        state.renderData.camera.updateProjectionMatrix();
        context.commit('setMapMode', false)
        state.renderData.scene.background = null //new state.renderData.THREE.Color( 0x00FF00 );
        state.renderData.mapControls.enabled = false
        state.renderData.controls.enabled = true
        state.renderData.controls.target.set( imageConfig.target.x, imageConfig.target.y, imageConfig.target.z);
        state.renderData.camera.position.set( imageConfig.position.x, imageConfig.position.y, imageConfig.position.z );
        await new Promise((resolve) => setTimeout(resolve, 50))
        state.renderData.controls.update();
        if(isMobile) {
          state.renderData.renderer.render(state.renderData.scene, state.renderData.camera)
        } else {
          state.renderData.composer.render(state.renderData.scene, state.renderData.camera)
        }
        // DEBUGGING FOR OUTPUT:
        // const base64Image = state.renderData.renderer.domElement.toDataURL("image/png")
        // console.log("plaatje", base64Image)
        // window.open(base64Image, '_blank').focus();
        // state.renderData.renderer.a.b.c = 'd'
      } else if(imageConfig.camera === 2){

        const betweenLines = 200
        const betweenTextAndLine = 20
        const lineWidth = 20
        const textSize = 250
        const overstekOffTextSize = 125
        const overstekConfig = store.getters.getOverstekConfig
        const extraForOverstekVoor = overstekConfig.voEnabled ? 0 : overstekOffTextSize+lineWidth
        const extraForOverstekAchter = overstekConfig.acEnabled ? 0 : overstekOffTextSize+lineWidth
        const extraForOverstekLinks = overstekConfig.liEnabled ? 0 : overstekOffTextSize+lineWidth
        const extraForOverstekRechts = overstekConfig.reEnabled ? 0 : overstekOffTextSize+lineWidth

        let fullWidth = breedte+state.frame.totalOverhang.l+state.frame.totalOverhang.r+betweenLines*6+lineWidth*4+betweenTextAndLine*2+textSize*2 + state.frame.topgevelSpace*2 + extraForOverstekLinks + extraForOverstekRechts
        let fullDepth = diepte+state.frame.totalOverhang.a+state.frame.totalOverhang.v+betweenLines*6+lineWidth*4+betweenTextAndLine*2+textSize*1.8 + extraForOverstekVoor + extraForOverstekAchter

        const imageWidth = 2048
        const margin =  50//in pixels
        const mmPerPixel =  fullWidth/(imageWidth-(2*margin))
        const imageHeight = (fullDepth/mmPerPixel)+(2*margin)

        const marginInMM = mmPerPixel*margin

        fullWidth = breedte+state.frame.totalOverhang.l+state.frame.totalOverhang.r+betweenLines*6+lineWidth*4+betweenTextAndLine*2+textSize*2 +state.frame.topgevelSpace*2 + extraForOverstekLinks + extraForOverstekRechts +2*marginInMM
        fullDepth = diepte+state.frame.totalOverhang.a+state.frame.totalOverhang.v+betweenLines*6+lineWidth*4+betweenTextAndLine*2+textSize*1.8 + extraForOverstekVoor + extraForOverstekAchter +2*marginInMM

        const leftBorder = -state.frame.totalOverhang.l-betweenLines*4-lineWidth*3-betweenTextAndLine-textSize -state.frame.topgevelSpace - marginInMM - extraForOverstekLinks
        const bottomBorder = -state.frame.totalOverhang.v-betweenLines*2-lineWidth-betweenTextAndLine-textSize*0.9 - marginInMM - extraForOverstekVoor

        //const whiteSpace = 20
        state.renderData.mapCamera.aspect = imageWidth/imageHeight
        state.renderData.mapCamera.updateProjectionMatrix();
        state.renderData.renderer.setSize(imageWidth,imageHeight)
        context.commit('setMapMode', true)
        state.renderData.scene.background = new state.renderData.THREE.Color( 0xDCDCC8 );
        state.renderData.controls.enabled = false
        state.renderData.mapControls.enabled = true
        state.renderData.mapControls.target.set( bottomBorder+(fullDepth/2), imageConfig.target.y, leftBorder+(fullWidth/2));
        state.renderData.mapCamera.position.set( bottomBorder+(fullDepth/2), imageConfig.position.y, leftBorder+(fullWidth/2) );
        state.renderData.mapControls.update();
        state.renderData.mapCamera.left = -fullWidth/2//leftBorder-(breedte/2)//-breedte*0.5-1200
        state.renderData.mapCamera.right = fullWidth/2//Math.abs(leftBorder)+(breedte/2)
        state.renderData.mapCamera.top = fullDepth/2
        state.renderData.mapCamera.bottom = -fullDepth/2
        state.renderData.mapCamera.zoom = 1
        /*state.renderData.mapCamera.near = 1
        state.renderData.mapCamera.far = 10000000000000*/
        state.renderData.mapCamera.updateProjectionMatrix();
        console.log(state.renderData.mapCamera.left, state.renderData.mapCamera.right, state.renderData.mapCamera.top, state.renderData.mapCamera.bottom, state.renderData.mapCamera.zoom)
        await new Promise((resolve) => setTimeout(resolve, 50))
        state.renderData.renderer.render(state.renderData.scene, state.renderData.mapCamera)
      }


      
      var image = state.renderData.renderer.domElement.toDataURL("image/png")

      console.log(image)
      
      const dwSessionData = context.getters.getDWSessionData
      console.log("context.getters.getDWSessionData", dwSessionData)
      const specId = specID ? specID : context.getters.getDWSessionData.specId
      // config graphQL data
      const graphqlQuery = {
        query: `
          mutation {
            uploadQuotationImage (image: "${image}", code: "${imageConfig.code}", specId: "${specId}")
          }
        `
      }
      console.log(graphqlQuery)

      // sending request to server
      const response = await graphql.fetchGraphQL(context, graphqlQuery, context.rootState.user.userAccount.token)

      //orgineel
      // state.imageNameArray.push(location.origin + "/specImages/" + response.data.uploadQuotationImage)
          
      // URL per environment
      let imageBaseUrl = import.meta.env.VITE_VUE_APP_IMAGE_BASE_URL;

      // full URL path to image
      state.imageNameArray.push(imageBaseUrl + "/specImages/" + response.data.uploadQuotationImage)


      // if error
      if (response.errors) {
        errorHandeling.throwError(context, response, 'Failed to fetch components.')
      }
    }

    //send imageUrls to driveworks

    state.isMapActive = false
    state.renderData.scene.background = null

    state.renderData.mapControls.enabled = false
    state.renderData.controls.enabled = true
    state.renderData.renderer.setSize(sizeVector.x,sizeVector.y)
    state.renderData.camera.aspect = sizeVector.x / (sizeVector.x >= 1000 ? sizeVector.y : (sizeVector.y / 2) + 15 )
    state.renderData.camera.updateProjectionMatrix();
    state.renderData.mapCamera.aspect = sizeVector.x / (sizeVector.x >= 1000 ? sizeVector.y : (sizeVector.y / 2) + 15 )
    state.renderData.mapCamera.updateProjectionMatrix();
    state.renderData.camera.position.set(cameraStartPosition.x, cameraStartPosition.y, cameraStartPosition.z)
    state.renderData.controls.target.set(cameraStartTarget.x, cameraStartTarget.y, cameraStartTarget.z)
    state.renderData.controls.update();
  },

  removeLastWall (context, wallToBeRemoved) {
    context.commit('removeLastWall', wallToBeRemoved)
    context.commit('updateIntersectingObjects')
  },

  async insertWall (context, settings) {
    console.log("context.rootState.models.selectedWallComponent", context.rootState.models.selectedWallComponent)
    context.commit('insertWall', settings)
  },

  async insertSteellook (context, settings) {
    context.commit('insertSteellook', settings)
  },

  async removeTussenChildWalls (context, wallToBeRemoved) {
    await context.commit('removeTussenChildWalls', wallToBeRemoved)
  }
}

const getters = {
  getFrame (state) {
    return state.frame
  },

  getLoading (state) {
    return state.loading
  },

  getProgress (state) {
    return state.progress
  },

  getIntersectionOn (state) {
    return state.intersectionOn
  },

  getIntersectingObjects (state) {
    return state.intersectingObjects
  },

  getSelectedWall (state) {
    return state.selectedWall
  },

  getHoveringWall (state) {
    return state.hoveringWall
  },

  getSelectedWallObject (state) {
    // console.log
    return state.selectedWallObject
    // var object = state.frame.muurInstances.find((muur) => muur.group.uuid === state.selectedWall.wall.parent.uuid)
    // return object
  },

  getSelectedWallWidth (state) {
    return state.selectedWallWidth
  },

  getLastEditedWall (state) {
    return state.frame.lastEditedWall
  },

  getWallMinSize (state) {
    return state.frame?.config?.minWallWidth
  },

  getWallMaxSize (state) {
    return state.frame?.config?.maxWallWidth
  },

  getSelectedElement (state) {
    return state.selectedElement
  },

  getIsMapActive (state) {
    return state.isMapActive
  },

  getFullScreen (state) {
    return state.fullScreen
  },

  getFullScreenPossible (state) {
    return state.fullScreenPossible
  },

  getShouldRenderSteellook(state) {
    return state.shouldRenderSteellook
  },

  getMuurInstances(state) {
    return state.frame.muurInstances
  },

  getMuren(state) {
    return state.frame.muren
  },

  getMovingBeam(state) {
    console.log("store-frame.js getMovingBeam",state.frame.movingBeam)
    return state.frame.movingBeam
  },

  getImageNameArray(state) {
    return state.imageNameArray
  },

  getWallHover(state) {
    return state.wallHover
  },

  getCameraDirection(state) {
    return state.cameraDirection
  },

  getSelectedLichtstraat(state) {
    return state.selectedLichtstraat
  },

  getSelectedLichtstraatCode(state) {
    return state.selectedLichtstraatCode
  },

  getInvalidLichtstraten (state) {
    return state.invalidLichtstraten
  },

  getCurrentIntersectingLichtstraatDimension(state) {
    return state.currentIntersectingLichtstraatDimension
  },

  getSelectedLichtstraatDimension(state) {
    return state.selectedLichtstraatDimension
  },

  getSelectedLichtstraatDimensionValue(state) {
    return state.selectedLichtstraatDimensionValue
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}