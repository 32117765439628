// this is a version of the plane border that does not use uv 0-1 but the actual size of the plane
function positionBuffer (x, z) {
  return [0, 0, z, x, 0, z, 0, 0, 0, x, 0, 0]
}

// defines position of the textures
function uvBuffer (x, z) {
  return [0, z, x, z, 0, 0, x, 0]
}

function indexBuffer() {
  return [0, 1, 2, 2, 1, 3];
}

export { positionBuffer, uvBuffer, indexBuffer }