import * as triangleBuffer from '@/three/buffer-geometries/triangle.js'

export default class SelectFrame {
  constructor (THREE, height, dikte, degrees) {
    this.THREE = THREE
    this.height = height
    this.dikte = dikte
    this.degrees = degrees
    this.selectbox = null
    this.bouw(THREE, height, dikte, degrees)
  }

  bouw(THREE, height, dikte, degrees) {

    // geometry
    
    const geometry = new THREE.BufferGeometry()
    console.log(height, dikte, degrees)
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(triangleBuffer.positionBuffer(height, dikte, degrees)), 3))
    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(triangleBuffer.uvBuffer(height, dikte, degrees, 1000, 1000)), 2))
    geometry.computeVertexNormals()
    geometry.groups = triangleBuffer.groupBufferSelectFrame()

    // texture
    const transparent = new this.THREE.MeshBasicMaterial({ 
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.0,
      depthWrite: false,
    });

    const gradient = new THREE.ShaderMaterial({
      uniforms: {
        color1: {
          value: new THREE.Color("#ffffff")
        },
        color2: {
          value: new THREE.Color("#ffffff")
        },
        base: {
          value: height/Math.tan(degrees * Math.PI / 180)
        },
        height: {
          value: height
        },
        angle: {
          value: degrees * Math.PI / 180
        }
      },
      vertexShader: `
        varying vec3 pos;
        varying vec2 vUv;
    
        void main() {
          vUv = uv;
          pos = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color1;
        uniform vec3 color2;
        uniform float base;
        uniform float height;
        uniform float angle;

        float border = 100.0;
      
        varying vec2 vUv;

        varying vec3 pos;
        
        void main() {
          // y < 0 = transparent, > 1 = opaque



          //pos.y <= border ? pos.y : 1000
          float distanceToBorder =      min(pos.y <= border ? pos.y : 1000.0 ,    (height-((height/base)*abs(pos.x)) - pos.y) * cos(angle));          //(pos.y) * ((base/2.0)/height*-1.0))   -abs(pos.x);

          float alpha = smoothstep(border, 0.0, distanceToBorder);

          gl_FragColor = vec4(color1, alpha);

          // if(pos.y < border) {
          //  gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
          // } else {
          //  gl_FragColor = vec4(0.0, 1.0, 0.0, 1.0);
          // }
        }
      `,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
    });

    // mesh
    const selectbox = new THREE.Mesh(geometry, [
      gradient,
      gradient,
      transparent,
      transparent,
      transparent,
    ])
    selectbox.name = "topgevelSelectbox"
    selectbox.visible = false

    this.selectbox = selectbox
  }

  update (height, dikte, degrees) {
    // UPDATE FUNCTIE DEPRECATED
    // FUNCTIE WERKT NITE MEER GOED NA UPDATEN THREEJS
    // NU MOET GEWOON STEEDS EEN NIEUWE SELECTBOX WORDEN AANGEMAAKT

    this.selectbox.geometry.attributes.position.array = new Float32Array(triangleBuffer.positionBuffer(height, dikte, degrees))
    this.selectbox.geometry.attributes.position.needsUpdate = true

    const gradient = new this.THREE.ShaderMaterial({
      uniforms: {
        color1: {
          value: new this.THREE.Color("#ffffff")
        },
        base: {
          value: height/Math.tan(degrees * Math.PI / 180)
        },
        height: {
          value: height
        },
        angle: {
          value: degrees * Math.PI / 180
        }
      },
      vertexShader: `
        varying vec3 pos;
    
        void main() {
          pos = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color1;
        uniform float base;
        uniform float height;
        uniform float angle;

        float border = 100.0;
        varying vec3 pos;
        
        void main() {
          float distanceToBorder = min(pos.y <= border ? pos.y : 1000.0, (height-((height/base)*abs(pos.x)) - pos.y) * cos(angle));
          float alpha = smoothstep(border, 0.0, distanceToBorder);
          gl_FragColor = vec4(color1, alpha);
        }
      `,
      transparent: true,
      side: this.THREE.DoubleSide,
      depthWrite: false,
    });

    this.selectbox.material[0] = gradient
    this.selectbox.material[1] = gradient

    this.selectbox.geometry.computeBoundingBox()
    this.selectbox.geometry.computeBoundingSphere();
  }
}