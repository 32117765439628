function positionBuffer (x, z) {
  // a: L height
  // b: outer width
  // c: inner width
  // d: L depth

  return [
    // top
    0, 0, 0,
    z/2, 0, x,
    z, 0, 0,
  ]
}

// defines position of the textures
function uvBuffer () { 
  return [
    // top
    1, 0,
    1, 1,
    0, 0,
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  return [
    // top
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
  ]
}

export { positionBuffer, uvBuffer, normalBuffer }