import * as planeBuffer from '@/three/buffer-geometries/plane.js'
import * as triangleBuffer from '@/three/buffer-geometries/triangleBuffer.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import store from '../../../store'

export default class Daklijn {
  constructor (THREE, textureLoader, x, z, totalOverhang, topgevelSpace, veranda) {
    this.THREE = THREE
    this.x = x
    this.z = z
    this.totalOverhang = totalOverhang
    this.topgevelSpace = topgevelSpace
    this.veranda = veranda
    this.mapGroup = null
    this.box = null
    this.vector = null
    this.font = null
    this.bouw(THREE, textureLoader, x, z, totalOverhang, veranda)
  }

  bouw (THREE, textureLoader, x, z, totalOverhang, veranda) {

    const betweenLines = 200
    const betweenTextAndLine = 20
    const lineWidth = 20
    const alignLineWidth = 10
    const arrowLength = 200
    const arrowWidth = 120

    const overhangAchter = (veranda?.enabled?0:totalOverhang.a)

    const daklijnTextureHorizontaal = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}plattegrond/daklijn.jpg`)
    daklijnTextureHorizontaal.magFilter = THREE.NearestFilter
    const daklijnTextureVerticaal = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}plattegrond/daklijn.jpg`)
    daklijnTextureVerticaal.magFilter = THREE.NearestFilter

    this.textureSettings(daklijnTextureHorizontaal,  (x+(totalOverhang.l+totalOverhang.r))/250,  1,  0.5 * Math.PI)
    this.textureSettings(daklijnTextureVerticaal,  (z+(overhangAchter+totalOverhang.v))/250,  1,  0.5 * Math.PI)

    const horizontalMaterial = new THREE.MeshBasicMaterial({ map: daklijnTextureHorizontaal })
    horizontalMaterial.side = THREE.FrontSide
    const verticalMaterial = new THREE.MeshBasicMaterial({ map: daklijnTextureVerticaal })
    verticalMaterial.side = THREE.FrontSide

    const geometry2 = new THREE.BufferGeometry()
    geometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(x+(totalOverhang.l+totalOverhang.r), lineWidth)), 3))
    geometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    geometry2.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))

    const dakRand0 = new THREE.Mesh( geometry2, horizontalMaterial );
    dakRand0.frustumCulled = false;
    const dakRand1 = new THREE.Mesh(new THREE.BufferGeometry().copy(geometry2), verticalMaterial);
    dakRand1.frustumCulled = false;
    const dakRand2 = dakRand0.clone()
    const dakRand3 = dakRand1.clone()
    dakRand0.position.set(z + overhangAchter - lineWidth, 1000, -totalOverhang.l)
    dakRand1.position.set(z + overhangAchter, 1000, x + totalOverhang.r - lineWidth)
    dakRand1.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(z+overhangAchter+totalOverhang.v, lineWidth))
    dakRand1.geometry.attributes.position.needsUpdate = true
    dakRand1.rotation.y = 270*Math.PI/180
    dakRand2.position.set(-totalOverhang.v, 1000, -totalOverhang.l)
    dakRand2.rotation.y = 0*Math.PI/180
    dakRand3.position.set(z + overhangAchter, 1000, -totalOverhang.l)
    dakRand3.rotation.y = 270*Math.PI/180

    // hide top daklijn if veranda is enabled
    dakRand0.visible = !veranda?.enabled

    const arrowGeometry = new THREE.BufferGeometry()
    arrowGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(triangleBuffer.positionBuffer(arrowLength, arrowWidth)), 3))
    arrowGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(triangleBuffer.uvBuffer()), 2))
    arrowGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(triangleBuffer.normalBuffer()), 3))

    const triangleMaterial = this.shaderMaterial('#505051')
    
    const lineGeometry = new THREE.BufferGeometry()
    lineGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(x+(totalOverhang.l+totalOverhang.r)-arrowLength*2, lineWidth)), 3))
    lineGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    lineGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))



    const arrow0 = new THREE.Mesh( arrowGeometry, triangleMaterial );
    const arrow1 = arrow0.clone()
    const arrow2 = arrow0.clone()
    const arrow3 = arrow0.clone()
    const line0 = new THREE.Mesh( lineGeometry, triangleMaterial );
    const line1 = new THREE.Mesh(new THREE.BufferGeometry().copy(lineGeometry), triangleMaterial);
    line1.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(z+(overhangAchter+totalOverhang.v)-arrowLength*2, lineWidth))
    line1.geometry.attributes.position.needsUpdate = true
    
    arrow0.rotation.y = 180*Math.PI/180
    arrow0.position.set(z+this.totalOverhang.a+betweenLines*3+lineWidth*2.5+arrowWidth/2,100,-totalOverhang.l+arrowLength)
    arrow1.position.set(z+this.totalOverhang.a+betweenLines*3+lineWidth*2.5-arrowWidth/2,100,x+totalOverhang.r-arrowLength)
    line0.position.set(z+this.totalOverhang.a+betweenLines*3+lineWidth*2 ,100,-totalOverhang.l +arrowLength)
    line1.position.set(z + overhangAchter - arrowLength, 100, -this.totalOverhang.l-betweenLines*3-lineWidth*3 -this.topgevelSpace)
    line1.rotation.y = 270*Math.PI/180

    arrow2.rotation.y = 90*Math.PI/180
    arrow2.position.set(z+overhangAchter-arrowLength,100,-this.totalOverhang.l-betweenLines*3-lineWidth*2.5+arrowWidth/2 -this.topgevelSpace)
    arrow3.rotation.y = 270*Math.PI/180
    arrow3.position.set(-totalOverhang.v+arrowLength,100,-this.totalOverhang.l-betweenLines*3-lineWidth*2.5-arrowWidth/2 -this.topgevelSpace)


    const alignLineGeometry = new THREE.BufferGeometry()
    alignLineGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(betweenLines*3+lineWidth*2.5+arrowWidth/2+(totalOverhang.a-overhangAchter), alignLineWidth)), 3))
    alignLineGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    alignLineGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))

    const alignLineGeometry2 = new THREE.BufferGeometry()
    alignLineGeometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(betweenLines*3+lineWidth*2.5+arrowWidth/2 +this.topgevelSpace, alignLineWidth)), 3))
    alignLineGeometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    alignLineGeometry2.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))

    const alignLine0 = new THREE.Mesh( alignLineGeometry, triangleMaterial );
    const alignLine1 = alignLine0.clone()
    const alignLine2 = new THREE.Mesh( alignLineGeometry2, triangleMaterial );
    const alignLine3 = alignLine2.clone()

    alignLine0.position.set(z+totalOverhang.a+betweenLines*3+lineWidth*2.5+arrowWidth/2,1000,-totalOverhang.l-alignLineWidth)
    alignLine0.rotation.y = 270*Math.PI/180
    alignLine1.position.set(z+totalOverhang.a+betweenLines*3+lineWidth*2.5+arrowWidth/2,1000,x+totalOverhang.r)
    alignLine1.rotation.y = 270*Math.PI/180
    alignLine2.position.set(z+overhangAchter,1000,-this.totalOverhang.l-betweenLines*3-lineWidth*2.5-arrowWidth/2 -this.topgevelSpace)
    alignLine3.position.set(-totalOverhang.v-alignLineWidth,1000,-this.totalOverhang.l-betweenLines*3-lineWidth*2.5-arrowWidth/2 -this.topgevelSpace)




    const totalWidth = x+(totalOverhang.l+totalOverhang.r)
    const visible = (overhangAchter===totalOverhang.v===totalOverhang.l===totalOverhang.r) ? overhangAchter!=0 : true
    const textloader = new FontLoader()
    textloader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/fonts/Calibri_Regular.json`, (font) => {
    // textloader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {      
      const textGeometry = new TextGeometry(totalWidth.toString(), {
        font: font,
        size: 125,
        height: 1,
      })
      this.font = font

      const text0 = new THREE.Mesh(textGeometry, triangleMaterial)

      const box0 = new THREE.Box3().setFromObject( text0 );
      const vector0 = new THREE.Vector3()
      box0.getSize(vector0)
      this.box = box0
      this.vector = vector0

      text0.position.set(z+totalOverhang.a+betweenLines*3+lineWidth*3+betweenTextAndLine,1000,(x-vector0.x)/2)
      text0.rotation.x = 270*Math.PI/180
      text0.rotation.z = 270*Math.PI/180



      
      const totalDepth = z+(overhangAchter+totalOverhang.v)
      const text1 = new THREE.Mesh(new TextGeometry(totalDepth.toString(), {
        font: font,
        size: 125,
        height: 1,
      }), triangleMaterial)
      box0.setFromObject(text1)
      box0.getSize(vector0)
      text1.position.set((z-vector0.x)/2,1000,-totalOverhang.l-betweenLines*3-lineWidth*3-betweenTextAndLine -this.topgevelSpace)
      text1.rotation.x = 270*Math.PI/180


      text0.visible = visible
      text1.visible = visible

      this.mapGroup.add(text0)
      this.mapGroup.add(text1)






      const textSize = 250

      const textFront = new THREE.Mesh(new TextGeometry("VOORZIJDE", {
        font: font,
        size: textSize,
        height: 1,
      }), triangleMaterial)
      box0.setFromObject(textFront)
      box0.getSize(vector0)
      textFront.position.set(-totalOverhang.v-betweenLines*2-lineWidth+betweenTextAndLine-textSize, -50, x/2-vector0.x/2)
      textFront.rotation.x = 270*Math.PI/180
      textFront.rotation.z = 270*Math.PI/180
      // console.log("vector0.y",vector0.y)

      const textLeft = new THREE.Mesh(new TextGeometry("LINKERZIJDE", {
        font: font,
        size: textSize,
        height: 1,
      }), triangleMaterial)
      box0.setFromObject(textLeft)
      box0.getSize(vector0)
      textLeft.position.set(z-(z/2-vector0.x/2), -50, -totalOverhang.l-betweenLines*4-lineWidth*3-betweenTextAndLine-textSize -this.topgevelSpace)
      textLeft.rotation.x = 270*Math.PI/180
      textLeft.rotation.z = 180*Math.PI/180
      // console.log("vector0.y",vector0.y)

      const textBack = new THREE.Mesh(new TextGeometry("ACHTERZIJDE", {
        font: font,
        size: textSize,
        height: 1,
      }), triangleMaterial)
      box0.setFromObject(textBack)
      box0.getSize(vector0)
      textBack.position.set(z+totalOverhang.a+betweenLines*4+lineWidth*3+betweenTextAndLine , -50, x/2-vector0.x/2)
      textBack.rotation.x = 270*Math.PI/180
      textBack.rotation.z = 270*Math.PI/180
      // console.log("vector0.y",vector0.y)

      const textRight = new THREE.Mesh(new TextGeometry("RECHTERZIJDE", {
        font: font,
        size: textSize,
        height: 1,
      }), triangleMaterial)
      box0.setFromObject(textRight)
      box0.getSize(vector0)
      textRight.position.set((z/2-vector0.x/2), -50, x+totalOverhang.r+betweenLines*2+lineWidth+betweenTextAndLine+textSize +this.topgevelSpace)
      textRight.rotation.x = 270*Math.PI/180
      textRight.rotation.z = 0*Math.PI/180
      // console.log("vector0.y",vector0.y)


      console.log(textLeft.position, textRight.position, vector0.y)

      this.mapGroup.add(textFront)
      this.mapGroup.add(textLeft)
      this.mapGroup.add(textBack)
      this.mapGroup.add(textRight)

      // overstek uit teksten
      const overstekConfig = store.getters.getOverstekConfig
      const overstekOffTextSize = 125
      const textParameters = {
        font: font,
        size: overstekOffTextSize,
        height: 1,
      }

      // voor
      this.overstekOffVoor = new THREE.Mesh(new TextGeometry("overstek uit", textParameters), triangleMaterial)
      box0.setFromObject(this.overstekOffVoor)
      box0.getSize(vector0)
      this.overstekOffVoor.position.set(-totalOverhang.v-betweenLines*2-lineWidth*2+betweenTextAndLine-textSize-overstekOffTextSize, -50, x/2-vector0.x/2)
      this.overstekOffVoor.rotation.x = 270*Math.PI/180
      this.overstekOffVoor.rotation.z = 270*Math.PI/180
      if (!overstekConfig.voEnabled) this.mapGroup.add(this.overstekOffVoor)

      // achter
      this.overstekOffAchter = new THREE.Mesh(new TextGeometry("overstek uit", textParameters), triangleMaterial)
      box0.setFromObject(this.overstekOffAchter)
      box0.getSize(vector0)
      this.overstekOffAchter.position.set(z+totalOverhang.a+betweenLines*4+lineWidth*4+betweenTextAndLine+textSize, -50, x/2-vector0.x/2)
      this.overstekOffAchter.rotation.x = 270*Math.PI/180
      this.overstekOffAchter.rotation.z = 270*Math.PI/180
      if (!overstekConfig.acEnabled) this.mapGroup.add(this.overstekOffAchter)

      // links
      this.overstekOffLinks = new THREE.Mesh(new TextGeometry("overstek uit", textParameters), triangleMaterial)
      box0.setFromObject(this.overstekOffLinks)
      box0.getSize(vector0)
      this.overstekOffLinks.position.set(z-(z/2-vector0.x/2), -50, -totalOverhang.l-betweenLines*4-lineWidth*4-betweenTextAndLine-textSize-overstekOffTextSize -this.topgevelSpace)
      this.overstekOffLinks.rotation.x = 270*Math.PI/180
      this.overstekOffLinks.rotation.z = 180*Math.PI/180
      if (!overstekConfig.liEnabled) this.mapGroup.add(this.overstekOffLinks)

      // rechts
      this.overstekOffRechts = new THREE.Mesh(new TextGeometry("overstek uit", textParameters), triangleMaterial)
      box0.setFromObject(this.overstekOffRechts)
      box0.getSize(vector0)
      this.overstekOffRechts.position.set((z/2-vector0.x/2), -50, x+totalOverhang.r+betweenLines*2+lineWidth*2+betweenTextAndLine+textSize+overstekOffTextSize +this.topgevelSpace)
      this.overstekOffRechts.rotation.x = 270*Math.PI/180
      this.overstekOffRechts.rotation.z = 0*Math.PI/180
      if (!overstekConfig.reEnabled) this.mapGroup.add(this.overstekOffRechts)
    })
      
    arrow0.visible = visible
    arrow1.visible = visible
    arrow2.visible = visible
    arrow3.visible = visible
    line0.visible = visible
    line1.visible = visible
    alignLine0.visible = visible
    alignLine1.visible = visible
    alignLine2.visible = visible
    alignLine3.visible = visible

    const group = new this.THREE.Group()
    group.add(dakRand0, dakRand1, dakRand2, dakRand3, arrow0, arrow1, line0, arrow2, arrow3, line1, alignLine0, alignLine1, alignLine2, alignLine3)
    this.mapGroup = group

    
    


  }

  update (x, z, totalOverhang, topgevelSpace, veranda) {

    const betweenLines = 200
    const betweenTextAndLine = 20
    const lineWidth = 20
    const alignLineWidth = 10
    const arrowLength = 200
    const arrowWidth = 120

    const overhangAchter = (veranda?.enabled?0:totalOverhang.a)

    this.x = x
    this.z = z
    this.totalOverhang = totalOverhang
    this.topgevelSpace = topgevelSpace
    this.veranda = veranda

    const dakRand0 = this.mapGroup.children[0]
    const dakRand1 = this.mapGroup.children[1]
    const dakRand2 = this.mapGroup.children[2]
    const dakRand3 = this.mapGroup.children[3]

    dakRand0.position.set(z + overhangAchter - 20, 1000, -totalOverhang.l)
    dakRand1.position.set(z + overhangAchter, 1000, x + totalOverhang.r - 20)
    dakRand2.position.set(-totalOverhang.v, 1000, -totalOverhang.l)
    dakRand3.position.set(z + overhangAchter, 1000, -totalOverhang.l)

    dakRand0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(x+(totalOverhang.l+totalOverhang.r), lineWidth))
    dakRand0.geometry.attributes.position.needsUpdate = true
    dakRand1.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(z+(overhangAchter+totalOverhang.v), lineWidth))
    dakRand1.geometry.attributes.position.needsUpdate = true

    this.textureSettings(dakRand0.material.map, (x+(totalOverhang.l+totalOverhang.r))/250,  1,  0.5 * Math.PI)
    this.textureSettings(dakRand1.material.map, (z+(overhangAchter+totalOverhang.v))/250,  1,  0.5 * Math.PI)

    // hide top daklijn if veranda is enabled
    dakRand0.visible = !veranda?.enabled
    
    const arrow0 = this.mapGroup.children[4]
    const arrow1 = this.mapGroup.children[5]
    const arrow2 = this.mapGroup.children[7]
    const arrow3 = this.mapGroup.children[8]
    const line0 = this.mapGroup.children[6]
    const line1 = this.mapGroup.children[9]
    const alignLine0 = this.mapGroup.children[10]
    const alignLine1 = this.mapGroup.children[11]
    const alignLine2 = this.mapGroup.children[12]
    const alignLine3 = this.mapGroup.children[13]
    const text0 = this.mapGroup.children[14]
    const text1 = this.mapGroup.children[15]

    const text2 = this.mapGroup.children[16]
    const text3 = this.mapGroup.children[17]
    const text4 = this.mapGroup.children[18]
    const text5 = this.mapGroup.children[19]

    const box0 = this.box
    const textSizes = this.vector
    

    line0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(x+(totalOverhang.l+totalOverhang.r)-arrowLength*2, lineWidth))
    line0.geometry.attributes.position.needsUpdate = true
    line1.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(z+(totalOverhang.a+totalOverhang.v)-arrowLength*2, lineWidth))
    line1.geometry.attributes.position.needsUpdate = true
    alignLine0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(betweenLines*3+lineWidth*2.5+arrowWidth/2+(totalOverhang.a-overhangAchter), alignLineWidth))
    alignLine0.geometry.attributes.position.needsUpdate = true
    alignLine2.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(betweenLines*3+lineWidth*2.5+arrowWidth/2 +this.topgevelSpace, alignLineWidth))
    alignLine2.geometry.attributes.position.needsUpdate = true
    
    arrow0.position.set(z+this.totalOverhang.a+betweenLines*3+lineWidth*2.5+arrowWidth/2,100,-totalOverhang.l+arrowLength)
    arrow1.position.set(z+this.totalOverhang.a+betweenLines*3+lineWidth*2.5-arrowWidth/2,100,x+totalOverhang.r-arrowLength)
    line0.position.set(z+this.totalOverhang.a+betweenLines*3+lineWidth*2 ,100,-totalOverhang.l +arrowLength)
    line1.position.set(z + totalOverhang.a - arrowLength, 100, -this.totalOverhang.l-betweenLines*3-lineWidth*3 -this.topgevelSpace)
    arrow2.position.set(z+overhangAchter-arrowLength,100,-this.totalOverhang.l-betweenLines*3-lineWidth*2.5+arrowWidth/2 -this.topgevelSpace)
    arrow3.position.set(-totalOverhang.v+arrowLength,100,-this.totalOverhang.l-betweenLines*3-lineWidth*2.5-arrowWidth/2 -this.topgevelSpace)
    alignLine0.position.set(z+this.totalOverhang.a+betweenLines*3+lineWidth*2.5+arrowWidth/2,1000,-totalOverhang.l-alignLineWidth)
    alignLine1.position.set(z+this.totalOverhang.a+betweenLines*3+lineWidth*2.5+arrowWidth/2,1000,x+totalOverhang.r)
    alignLine2.position.set(z+overhangAchter,1000,-this.totalOverhang.l-betweenLines*3-lineWidth*2.5-arrowWidth/2 -this.topgevelSpace)
    alignLine3.position.set(-totalOverhang.v-alignLineWidth,1000,-this.totalOverhang.l-betweenLines*3-lineWidth*2.5-arrowWidth/2 -this.topgevelSpace)




    const totalWidth = x+(totalOverhang.l+totalOverhang.r)
    text0.geometry = new TextGeometry(totalWidth.toString(), {
      font: this.font,
      size: 125,
      height: 1,
    })
    box0.setFromObject(text0)
    box0.getSize(textSizes)
    text0.position.set(z+totalOverhang.a+betweenLines*3+lineWidth*3+betweenTextAndLine,1000,(x-textSizes.z)/2)

    const totalDepth = z+(overhangAchter+totalOverhang.v)
    text1.geometry = new TextGeometry(totalDepth.toString(), {
      font: this.font,
      size: 125,
      height: 1,
    })
    box0.setFromObject(text1)
    box0.getSize(textSizes)
    text1.position.set((z-textSizes.x)/2,1000,-totalOverhang.l-betweenLines*3-lineWidth*3-betweenTextAndLine -this.topgevelSpace)


    //plattegrond teksten
    const textSize = 250
    box0.setFromObject(text2)
    box0.getSize(textSizes)
    console.log(textSizes)
    text2.position.set(-totalOverhang.v-betweenLines*2-lineWidth+betweenTextAndLine-textSize, -50, x/2-textSizes.z/2)
    box0.setFromObject(text3)
    box0.getSize(textSizes)
    text3.position.set(z-(z/2-textSizes.x/2), -50, -totalOverhang.l-betweenLines*4-lineWidth*3-betweenTextAndLine-textSize -this.topgevelSpace)
    box0.setFromObject(text4)
    box0.getSize(textSizes)
    text4.position.set(totalOverhang.a+betweenLines*4+lineWidth*3+betweenTextAndLine + z, -50, x/2-textSizes.z/2)
    box0.setFromObject(text5)
    box0.getSize(textSizes)
    text5.position.set((z/2-textSizes.x/2), -50, x+totalOverhang.r+betweenLines*2+lineWidth+betweenTextAndLine+textSize +this.topgevelSpace)


    // overstek uit teksten
    const overstekConfig = store.getters.getOverstekConfig
    console.log("vloer overstekConfig", overstekConfig)
    const overstekOffTextSize = 125
    box0.setFromObject(this.overstekOffVoor)
    box0.getSize(textSizes)
    this.overstekOffVoor.position.set(-totalOverhang.v-betweenLines*2-lineWidth*2+betweenTextAndLine-textSize-overstekOffTextSize, -50, x/2-textSizes.z/2)
    box0.setFromObject(this.overstekOffAchter)
    box0.getSize(textSizes)
    this.overstekOffAchter.position.set(z+totalOverhang.a+betweenLines*4+lineWidth*4+betweenTextAndLine+textSize, -50, x/2-textSizes.z/2)
    box0.setFromObject(this.overstekOffLinks)
    box0.getSize(textSizes)
    this.overstekOffLinks.position.set(z-(z/2-textSizes.x/2), -50, -totalOverhang.l-betweenLines*4-lineWidth*4-betweenTextAndLine-textSize-overstekOffTextSize -this.topgevelSpace)
    box0.setFromObject(this.overstekOffRechts)
    box0.getSize(textSizes)
    this.overstekOffRechts.position.set((z/2-textSizes.x/2), -50, x+totalOverhang.r+betweenLines*2+lineWidth*2+betweenTextAndLine+textSize+overstekOffTextSize +this.topgevelSpace)
    // toggle visibility
    this.mapGroup[overstekConfig.voEnabled ? 'remove' : 'add'](this.overstekOffVoor);
    this.mapGroup[overstekConfig.acEnabled ? 'remove' : 'add'](this.overstekOffAchter);
    this.mapGroup[overstekConfig.liEnabled ? 'remove' : 'add'](this.overstekOffLinks);
    this.mapGroup[overstekConfig.reEnabled ? 'remove' : 'add'](this.overstekOffRechts);


    const visible = (overhangAchter===totalOverhang.v===totalOverhang.l===totalOverhang.r) ? overhangAchter!=0 : true
    arrow0.visible = visible
    arrow1.visible = visible
    arrow2.visible = visible
    arrow3.visible = visible
    line0.visible = visible
    line1.visible = visible
    alignLine0.visible = visible
    alignLine1.visible = visible
    alignLine2.visible = visible
    alignLine3.visible = visible
    text0.visible = visible
    text1.visible = visible

    

  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
  }

  shaderMaterial(hex) {
    const c = new this.THREE.Color(hex)
    const fragmentShader = `void main() {gl_FragColor = vec4(vec3(${c.r},${c.g},${c.b}),1.0);}`
    const material = new this.THREE.ShaderMaterial({fragmentShader})
    return material
  }
}