export default class VerandaGevel {
  constructor (THREE, modelType, x, z, verandaConfig) {
    this.THREE = THREE
    this.modelType = modelType
    this.x = x
    this.z = z
    this.verandaConfig = verandaConfig
    this.material = {}
    this.group = new THREE.Group()
    this.mapGroup = new THREE.Group()

    this.textureLoader = new THREE.TextureLoader()
    this.textureTypes = [
      { cms: 'diffusemap', three: 'map' },
      { cms: 'normalmap', three: 'normalMap' },
      { cms: 'aomap', three: 'aoMap' },
      { cms: 'metalnessmap', three: 'metalnessMap' },
      { cms: 'roughnessmap', three: 'roughnessMap' }
    ]

    this.bouw(THREE)
    this.update(modelType, x, z, verandaConfig)
  }

  bouw (THREE) {
    const muurGeometry = new THREE.BoxGeometry(1, 1, 1)
    this.muurMaterialVoor = new THREE.MeshStandardMaterial()
    this.muurMaterialZijkant = new THREE.MeshStandardMaterial()
    this.muurMaterialBoven = new THREE.MeshStandardMaterial()
    this.muurMesh = new THREE.Mesh(muurGeometry, [
      this.muurMaterialVoor,
      this.muurMaterialVoor,
      this.muurMaterialBoven,
      this.muurMaterialBoven,
      this.muurMaterialZijkant,
      this.muurMaterialZijkant
    ])
    this.muurMesh.castShadow = true
    this.muurMesh.receiveShadow = true

    const muurMapGeometry = new THREE.PlaneGeometry(1, 1)
    this.muurMapMaterial = new THREE.MeshBasicMaterial({color: 0xff0000})
    this.muurMapMesh = new THREE.Mesh(muurMapGeometry, this.muurMapMaterial)
    this.muurMapMesh.rotation.x = -Math.PI/2
  }

  update (modelType, x, z, verandaConfig) {
    this.modelType = modelType
    this.x = x
    this.z = z
    this.verandaConfig = verandaConfig

    // verandaConfig is not defined on model, so it is not used
    if (!this.verandaConfig || !this.verandaConfig.enabled) {
      // misschien ook nog this.verandaConfig.enabled op false zetten
      this.group.remove(this.muurMesh)
      this.mapGroup.remove(this.muurMapMesh)
      return
    }

    this.dikte = this.verandaConfig.dikte
    this.hoogte = this.verandaConfig.hoogte
    this.extraBreedte = this.verandaConfig.extraBreedte
    this.material = this.verandaConfig.material

    const muurMeshBreedte = x + 2*this.extraBreedte
    const muurMeshHoogte = this.hoogte
    const muurMeshDikte = this.dikte

    this.muurMesh.scale.set(muurMeshDikte, muurMeshHoogte, muurMeshBreedte)
    this.muurMesh.position.set(
      z + muurMeshDikte/2 - 2, // -2 is voor z-fighting met boeideel
      muurMeshHoogte/2 - 50,
      x / 2
    )

    // update material
    for (let i = 0; i < this.textureTypes.length; i++) {
      if (this.material[this.textureTypes[i].cms]) {
        this.textureLoader.load(import.meta.env.VITE_VUE_APP_SERVER+import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION+this.material[this.textureTypes[i].cms].url, (loadedTexture) => {
          const textureVoor = loadedTexture.clone()
          this.textureSettings(textureVoor, muurMeshBreedte/this.material.width, muurMeshHoogte/this.material.height)
          this.muurMaterialVoor[this.textureTypes[i].three] = textureVoor
          this.muurMaterialVoor.needsUpdate = true

          const texturezijkant = loadedTexture.clone()
          this.textureSettings(texturezijkant, muurMeshDikte/this.material.width, muurMeshHoogte/this.material.height)
          this.muurMaterialZijkant[this.textureTypes[i].three] = texturezijkant
          this.muurMaterialZijkant.needsUpdate = true

          const textureboven = loadedTexture.clone()
          this.textureSettings(textureboven, muurMeshBreedte/this.material.width, muurMeshDikte/this.material.height, Math.PI/2)
          this.muurMaterialBoven[this.textureTypes[i].three] = textureboven
          this.muurMaterialBoven.needsUpdate = true
        })
      }
    }

    // update plattegrond mesh
    this.muurMapMesh.scale.set(muurMeshDikte, muurMeshBreedte)
    this.muurMapMesh.position.set(
      z + muurMeshDikte/2,
      -100000,
      x / 2
    )
    this.muurMapMaterial.color = new this.THREE.Color(this.material.color)

    // add wall mesh to group
    this.group.add(this.muurMesh)
    this.mapGroup.add(this.muurMapMesh)
  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    // console.log(texture)
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    // texture.offset.set(Math.random(), 0)
  }
}