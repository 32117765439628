import { createClient } from '@urql/core'

const client = createClient({
  url: import.meta.env.VITE_VUE_APP_GRAPHQL,
  maskTypename: false,
  fetchOptions: () => {
    const token = localStorage.getItem('loginToken')

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  },
})

function clean(data) {
  return JSON.parse(JSON.stringify(data, (name, val) => {
    if (name === '__typename') {
        delete val[name];
    } else {
        return val;
    }
  }));
}

export { client, clean }