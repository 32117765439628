// defines position of the vertices
function positionBuffer (dx, dy, dz) {
  const x = dx / 2
  const y = dy / 2
  const z = dz / 2

  // console.log(x,y,z)
  //return [x, y, z, x, y, -z, x, -y, z, x, -y, -z, -x, y, -z, -x, y, z, -x, -y, -z, -x, -y, z, -x, y, -z, x, y, -z, -x, y, z, x, y, z, -x, -y, z, x, -y, z, -x, -y, -z, x, -y, -z, -x, y, z, x, y, z, -x, -y, z, x, -y, z, x, y, -z, -x, y, -z, x, -y, -z, -x, -y, -z]
  //return [x, -y, z, -x, -y, z, -x, -y, -z,        -x, -y, -z, x, -y, -z, x, -y, z,]
  return [x, y, z, x, y, -z, x, -y, z, x, -y, -z, -x, y, -z, -x, y, z, -x, -y, -z, -x, -y, z, -x, y, -z, x, y, -z, -x, y, z, x, y, z, -x, -y, z, x, -y, z, -x, -y, -z, x, -y, -z, -x, y, z, x, y, z, -x, -y, z, x, -y, z, x, y, -z, -x, y, -z, x, -y, -z, -x, -y, -z]
}

function positionBufferScaled (dx, dy, dz) {
  const x = dx
  const y = dy
  const z = dz / 2

  // console.log(x,y,z)
  //return [x, y, z, x, y, -z, x, -y, z, x, -y, -z, -x, y, -z, -x, y, z, -x, -y, -z, -x, -y, z, -x, y, -z, x, y, -z, -x, y, z, x, y, z, -x, -y, z, x, -y, z, -x, -y, -z, x, -y, -z, -x, y, z, x, y, z, -x, -y, z, x, -y, z, x, y, -z, -x, y, -z, x, -y, -z, -x, -y, -z]
  //return [x, -y, z, -x, -y, z, -x, -y, -z,        -x, -y, -z, x, -y, -z, x, -y, z,]
  return [
    x, y, z, 
    x, y, -z, 
    x, 0, z, 
    x, 0, -z, 

    0, y, -z, 
    0, y, z, 
    0, 0, -z, 
    0, 0, z, 

    0, y, -z, 
    x, y, -z, 
    0, y, z, 
    x, y, z, 
    
    0, 0, z, 
    x, 0, z, 
    0, 0, -z, 
    x, 0, -z, 

    0, y, z, 
    x, y, z, 
    0, 0, z, 
    x, 0, z, 

    x, y, -z, 
    0, y, -z, 
    x, 0, -z, 
    0, 0, -z,
  ]
}

// defines position of the textures
function uvBuffer () { 
  //return [0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0]
  return [0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0]
}

function uvBufferScaled (dx, dy, dz, maxTextureWith, maxTextureHeight) {
  const tw = maxTextureWith || 2330
  const th = maxTextureHeight || 145

  const x = dx 
  const y = dy 
  const z = dz 

  console.log(tw, th, x, y, z)

  return [
    0, y/th, 
    z/tw, y/th, 
    0, 0, 
    z/tw, 0, 
    
    0, y/th, 
    z/tw, y/th, 
    0, 0, 
    z/tw, 0, 
    
    0, z/th, 
    x/tw, z/th, 
    0, 0, 
    x/tw, 0, 
    
    0, z/th, 
    x/tw, z/th, 
    0, 0, 
    x/tw, 0, 
    
    0, y/th, 
    x/tw, y/th, 
    0, 0, 
    x/tw, 0, 
    
    0, y/th, 
    x/tw, y/th, 
    0, 0, 
    x/tw, 0,

    x, y, z, 
    x, y, -z, 
    x, 0, z, 
    x, 0, -z, 

    0, y, -z, 
    0, y, z, 
    0, 0, -z, 
    0, 0, z, 

    0, y, -z, 
    x, y, -z, 
    0, y, z, 
    x, y, z, 
    
    0, 0, z, 
    x, 0, z, 
    0, 0, -z, 
    x, 0, -z, 

    0, y, z, 
    x, y, z, 
    0, 0, z, 
    x, 0, z, 

    x, y, -z, 
    0, y, -z, 
    x, 0, -z, 
    0, 0, -z,
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  //return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
  //return [0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0,]
  //return [0,0,1]
  return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
}

// defines the shared vertices of position and uv buffer (corners have overlapping vertices which can be refenreced by index instead of defining an new verticie)
function indexBuffer () {
  /*return [
    0,  1,  2,   2,  1,  3,  // front
    4,  5,  6,   6,  5,  7,  // right
    8,  9, 10,  10,  9, 11,  // back
   12, 13, 14,  14, 13, 15,  // left
   16, 17, 18,  18, 17, 19,  // top
   20, 21, 22,  22, 21, 23,  // bottom
  ]*/
  return [0, 2, 1, 2, 3, 1, 4, 6, 5, 6, 7, 5, 8, 10, 9, 10, 11, 9, 12, 14, 13, 14, 15, 13, 16, 18, 17, 18, 19, 17, 20, 22, 21, 22, 23, 21]
}

// group the vertices belonging to each of the 6 sides, allows for 6 different textures one for each side.
function groupBuffer () {
  return [
    {
      start: 0,
      count: 6,
      materialIndex: 0
    },
    {
      start: 6,
      count: 6,
      materialIndex: 1
    },
    {
      start: 12,
      count: 6,
      materialIndex: 2
    },
    {
      start: 18,
      count: 6,
      materialIndex: 3
    },
    {
      start: 24,
      count: 6,
      materialIndex: 4
    },
    {
      start: 30,
      count: 6,
      materialIndex: 5
    }
  ]
}

export { positionBuffer, positionBufferScaled, uvBuffer, uvBufferScaled, normalBuffer, indexBuffer, groupBuffer }