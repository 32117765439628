<template>
  <transition name="snack" appear>
    <div class="modal-wrapper">
      <div class="error-bar">
        <p class="error-bar-p">
          <span class="error-bar-error"></span>
          <span class="error-bar-message">{{ succes.message }}</span>
        </p>
        <div style="display: flex"  @click="closeError()">
          <mdicon name="close-circle" class="error-bar-icon" size="25px"  />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "succesBar",

    methods: {
      closeError () {
        this.$store.dispatch('dismissSucces')
      }
    },
    computed: {
      ...mapGetters({
        succes: 'getSucces',
      }),
    }
  }
</script>

<style scoped lang="scss">

.modal-wrapper {
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  bottom: 0;
  z-index: 99;
  pointer-events: none;

  @media screen and (min-width: 500px) {
    width: calc(100% - 65px);
  }
}

.error-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 10px;
  border-radius: $radius-m;
  box-shadow: $shadow-1;
  cursor: default;
  // width: 70vw;
  max-width: min(75ch, 80vw);
  line-height: 1.4rem;
  pointer-events: all;
  background-color: white;
  
  &-error {
    //color: white;
    font-size: 1.2rem;
    font-weight: 600;
  }

  &-p {
    margin: 0px;
    word-wrap: break-word;
    max-width: 85%;
    line-height: 1.6rem;
  }

  &-message {
    //color: black;
    font-size: 1.2rem;
    font-weight: 500;
    word-wrap: break-word;
  }

  &-icon {
    color: $c-primary;
    padding: 5px;
    margin-left: 20px;
    border-radius: 50px;
    transition: background-color 150ms ease, transform 150ms ease;
    transform: rotate(0deg);
    cursor: pointer;

    &:hover {
      background-color:  $c-primary-background-hover;
      transform: rotate(90deg);
    }
  }
}

// modal vue animation
.snack-enter-active {
  transition: transform 200ms ease-out;
}

.snack-leave-active {
  transition: transform 200ms ease-in;
}

.snack-enter,
.snack-leave-to {
  transform: translateY(calc(100% + 20px))
}
</style>