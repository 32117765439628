// defines position of the vertices
function positionBuffer (dx, dy, dz) {
  const x = dx / 2
  const y = dy / 2
  const z = dz / 2

  return [
    x, y, z,
    x, y, -z,
    x, -y, z,
    x, -y, -z,

    -x, y, -z,
    -x, y, z,
    -x, -y, -z,
    -x, -y, z,

    -x, y, -z,
    x, y, -z,
    -x, y, z,
    x, y, z,

    -x, -y, z,
    x, -y, z,
    -x, -y, -z,
    x, -y, -z,

    -x, y, z,
    x, y, z,
    -x, -y, z,
    x, -y, z,

    x, y, -z,
    -x, y, -z,
    x, -y, -z,
    -x, -y, -z,
  ]
}

function uvBuffer (dx, dy, dz) {
  const x = dx
  const y = dy
  const z = dz

  return [
    0, y,
    z, y,
    0, 0,
    z, 0,

    0, y,
    z, y,
    0, 0,
    z, 0,

    0, z,
    x, z,
    0, 0,
    x, 0,

    0, z,
    x, z,
    0, 0,
    x, 0,

    0, y,
    x, y,
    0, 0,
    x, 0,

    0, y,
    x, y,
    0, 0,
    x, 0,
  ]
}

// defines orientation of the faces (texture inside or outside)
function normalBuffer () {
  return [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1]
}

// defines the shared vertices of position and uv buffer (corners have overlapping vertices which can be refenreced by index instead of defining an new verticie)
function indexBuffer () {
  return [0, 2, 1, 2, 3, 1, 4, 6, 5, 6, 7, 5, 8, 10, 9, 10, 11, 9, 12, 14, 13, 14, 15, 13, 16, 18, 17, 18, 19, 17, 20, 22, 21, 22, 23, 21]
}

// group the vertices belonging to each of the 6 sides, allows for 6 different textures one for each side.
function groupBuffer () {
  return [
    {
      start: 0,
      count: 6,
      materialIndex: 0
    },
    {
      start: 6,
      count: 6,
      materialIndex: 1
    },
    {
      start: 12,
      count: 6,
      materialIndex: 2
    },
    {
      start: 18,
      count: 6,
      materialIndex: 3
    },
    {
      start: 24,
      count: 6,
      materialIndex: 4
    },
    {
      start: 30,
      count: 6,
      materialIndex: 5
    }
  ]
}

export { positionBuffer, uvBuffer, normalBuffer, indexBuffer, groupBuffer }