import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'
import * as planeBuffer from '@/three/buffer-geometries/newPlane.js'
import shaderMaterial from '../../functions/shaderMaterial.js';
import * as triangleBuffer from '@/three/buffer-geometries/triangleBuffer.js'

const fontSize = 125
const fontPadding = 20
const fontHeight = 1
const arrowLength = 100
const arrowWidth = 50

export default class SimpleMaatlijn {
  constructor (THREE, font, length, color, selectedColor, lineThickness, originRight, side) {
    this.THREE = THREE
    this.front = font
    this.length = length
    this.color = color
    this.selectedColor = selectedColor
    this.lineThickness = lineThickness
    this.originRight = originRight
    this.side = side
    this.bouw()
  }

  bouw () {
    this.group = new this.THREE.Group()

    this.mainMaterial = shaderMaterial(this.color)
    this.hoverMaterial = shaderMaterial(this.selectedColor)
    this.testMaterial = new this.THREE.MeshBasicMaterial({color: 0xff0000, side: this.THREE.DoubleSide})
    this.invisibleMaterial = new this.THREE.ShaderMaterial({
      transparent: true, // Ensures it doesn't block objects behind
      depthWrite: false, // Prevents writing to the depth buffer
      depthTest: false,  // Prevents occlusion
      side: this.THREE.DoubleSide, // Ensures it's invisible from both sides
      fragmentShader: `
        void main() {
            gl_FragColor = vec4(0.0); // Transparent black
        }
      `,
      vertexShader: `
        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
    });


    // main line
    const mainLineGeometry = new this.THREE.BufferGeometry()
    mainLineGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(this.lineThickness, this.length-arrowLength*2)), 3));
    mainLineGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer(this.lineThickness, this.length-arrowLength*2)), 2));
    mainLineGeometry.setIndex(planeBuffer.indexBuffer());
    mainLineGeometry.computeVertexNormals();
    mainLineGeometry.computeBoundingSphere();
    mainLineGeometry.translate(-this.lineThickness/2, 0, this.originRight ? -this.length+arrowLength : arrowLength)
    this.mainLineMesh = new this.THREE.Mesh(mainLineGeometry, this.mainMaterial)

    // text
    const textGeometry = new TextGeometry(String(Math.round(this.length)), { font: this.front, size: fontSize, height: fontHeight })
    textGeometry.center()
    this.textMesh = new this.THREE.Mesh(textGeometry, this.mainMaterial)
    this.textMesh.position.set(fontSize/2 + fontPadding, 0, this.originRight ? -this.length/2 : this.length/2)
    this.textMesh.rotateOnWorldAxis(new this.THREE.Vector3(1, 0, 0), -Math.PI / 2);
    this.textMesh.rotateOnWorldAxis(new this.THREE.Vector3(0, 1, 0), -Math.PI / 2);

    // arrow left
    const arrowLeftGeometry = new this.THREE.BufferGeometry()
    arrowLeftGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(triangleBuffer.positionBuffer(arrowLength, arrowWidth)), 3))
    arrowLeftGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(triangleBuffer.uvBuffer()), 2))
    arrowLeftGeometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(triangleBuffer.normalBuffer()), 3))
    arrowLeftGeometry.computeBoundingSphere();
    arrowLeftGeometry.translate(-arrowWidth/2, 0, -arrowLength)
    this.arrowLeftMesh = new this.THREE.Mesh(arrowLeftGeometry, this.mainMaterial)
    this.arrowLeftMesh.position.set(0, 0, this.originRight ? -this.length : 0)
    this.arrowLeftMesh.rotation.y = Math.PI

    // arrow right
    const arrowRightGeometry = new this.THREE.BufferGeometry()
    arrowRightGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(triangleBuffer.positionBuffer(arrowLength, arrowWidth)), 3))
    arrowRightGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(triangleBuffer.uvBuffer()), 2))
    arrowRightGeometry.setAttribute('normal', new this.THREE.BufferAttribute(new Float32Array(triangleBuffer.normalBuffer()), 3))
    arrowRightGeometry.computeBoundingSphere();
    arrowRightGeometry.translate(-arrowWidth/2, 0, -arrowLength)
    this.arrowRightMesh = new this.THREE.Mesh(arrowRightGeometry, this.mainMaterial)
    this.arrowRightMesh.position.set(0, 0, this.originRight ? 0 : this.length)

    // line hitbox
    const lineHitboxGeometry = new this.THREE.PlaneGeometry(1, 1)
    this.lineHitboxMesh = new this.THREE.Mesh(lineHitboxGeometry, this.invisibleMaterial)
    this.lineHitboxMesh.scale.set(arrowWidth, this.length, 1)
    this.lineHitboxMesh.rotation.x = -Math.PI / 2
    this.lineHitboxMesh.position.z = this.originRight ? -this.length/2 : this.length/2
    this.lineHitboxMesh.userData.type = "lichtstraatDimensionHitbox"
    this.lineHitboxMesh.userData.side = this.side
    this.lineHitboxMesh.name = "lichtstraatDimensionHitbox"

    // text hitbox
    const boundingBox = new this.THREE.Box3().setFromObject(this.textMesh)
    const width = boundingBox.max.z - boundingBox.min.z
    const depth = boundingBox.max.x - boundingBox.min.x
    const textHitboxGeometry = new this.THREE.PlaneGeometry(1, 1)
    this.textHitboxMesh = new this.THREE.Mesh(textHitboxGeometry, this.invisibleMaterial)
    this.textHitboxMesh.scale.set(depth, width, 1)
    this.textHitboxMesh.rotation.x = -Math.PI / 2
    this.textHitboxMesh.position.x = depth/2+fontPadding+this.lineThickness/2
    this.textHitboxMesh.position.z = this.originRight ? -this.length/2 : this.length/2
    this.textHitboxMesh.userData.type = "lichtstraatDimensionHitbox"
    this.textHitboxMesh.userData.side = this.side
    this.textHitboxMesh.name = "lichtstraatDimensionHitbox"

    const zooiGeometry = new this.THREE.PlaneGeometry(1000, 1000)
    const zooiMaterial = new this.THREE.MeshBasicMaterial({color: 0x00ff00, side: this.THREE.DoubleSide})
    const zooiMesh = new this.THREE.Mesh(zooiGeometry, zooiMaterial)
    zooiMesh.rotation.x = -Math.PI / 2

    this.group.add(this.mainLineMesh, this.textMesh, this.arrowLeftMesh, this.arrowRightMesh, this.lineHitboxMesh, this.textHitboxMesh)
  }

  update (length) {
    if (length === this.length) return
    this.length = length

    // update main line
    this.mainLineMesh.geometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(this.lineThickness, this.length-arrowLength*2)), 3));
    this.mainLineMesh.geometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer(this.lineThickness, this.length-arrowLength*2)), 2));
    this.mainLineMesh.geometry.setIndex(planeBuffer.indexBuffer());
    this.mainLineMesh.geometry.computeVertexNormals();
    this.mainLineMesh.geometry.computeBoundingSphere();
    this.mainLineMesh.geometry.translate(-this.lineThickness/2, 0, this.originRight ? -this.length+arrowLength : arrowLength)

    // update text
    this.textMesh.geometry.dispose()
    const textGeometry = new TextGeometry(String(Math.round(this.length)), { font: this.front, size: fontSize, height: fontHeight })
    textGeometry.center()
    this.textMesh.geometry = textGeometry
    this.textMesh.position.set(fontSize/2 + fontPadding, 0, this.originRight ? -this.length/2 : this.length/2)

    // update oblique left
    this.arrowLeftMesh.position.set(0, 0, this.originRight ? -this.length : 0)

    // update oblique right
    this.arrowRightMesh.position.set(0, 0, this.originRight ? 0 : this.length)

    // update line hitbox
    this.lineHitboxMesh.scale.set(arrowWidth, this.length, 1)
    this.lineHitboxMesh.position.z = this.originRight ? -this.length/2 : this.length/2

    // update text hitbox
    const boundingBox = new this.THREE.Box3().setFromObject(this.textMesh)
    let width, depth
    if (this.group.rotation.y === 0) {
      width = boundingBox.max.z - boundingBox.min.z
      depth = boundingBox.max.x - boundingBox.min.x
    } else {
      width = boundingBox.max.x - boundingBox.min.x
      depth = boundingBox.max.z - boundingBox.min.z
    }
    this.textHitboxMesh.scale.set(depth, width, 1)
    this.textHitboxMesh.position.x = depth/2+fontPadding+this.lineThickness/2
    this.textHitboxMesh.position.z = this.originRight ? -this.length/2 : this.length/2
  }

  setSelected (selected) {
    this.mainLineMesh.material = selected ? this.hoverMaterial : this.mainMaterial
    this.arrowLeftMesh.material = selected ? this.hoverMaterial : this.mainMaterial
    this.arrowRightMesh.material = selected ? this.hoverMaterial : this.mainMaterial
    this.textMesh.material = selected ? this.hoverMaterial : this.mainMaterial
  }
}