import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry.js'; // Import the ConvexGeometry class
import store from '../../../store';
import * as planeBorderBuffer from '@/three/buffer-geometries/planeBorder.js'
import * as planeBuffer from '@/three/buffer-geometries/newPlane.js'
import shaderMaterial from '../../functions/shaderMaterial.js';
import SimpleMaatlijn from './simpleMaatlijn.js'
import { positionBuffer, uvBuffer, indexBuffer, groupBuffer } from '@/three/buffer-geometries/boxBufferSameUV.js';

const outsideMargin = 250;
const insideMargin = 500;
const mapStippellijnLengte = 100

export default class LichtstraatManager {
  constructor (THREE, modelType, textureFrame, x, y, z, verticalePositie, staanderDikte, dakbeschotDikte, gy, gz, font, lichtstratenFromConfig) {
    this.THREE = THREE
    this.modelType = modelType
    this.textureFrame = textureFrame
    this.x = x
    this.y = y
    this.z = z
    this.verticalePositie = verticalePositie
    this.staanderDikte = staanderDikte
    this.dakbeschotDikte = dakbeschotDikte
    this.gy = gy
    this.gz = gz
    this.font = font
    this.lichtstratenFromConfig = lichtstratenFromConfig

    const globalSettings = store.getters.getGlobalSettings

    this.mapColor = new THREE.Color(globalSettings.lichtstraatMapColor)
    this.lineColor = globalSettings.lichtstraatMapLineColor
    this.linecolorSelected = globalSettings.lichtstraatMapLineColorSelected

    this.group = new THREE.Group()
    this.modelGroup = new THREE.Group()
    this.mapGroup = new THREE.Group()

    this.lichtstraten = []

    this.gltfLoader = new GLTFLoader()
    this.textureLoader = new THREE.TextureLoader()

    this.convexErrorMaterial = new this.THREE.MeshBasicMaterial({
      color: 0xff0000,
      // wireframe: true, // Optional: Use wireframe to visualize the convex hull
      transparent: true,
      // depthWrite: false, // Prevents writing to the depth buffer
      // depthTest: false,  // Prevents occlusion
      opacity: 0.5,
    });
    this.convexInvisibleMaterial = new this.THREE.ShaderMaterial({
      transparent: true, // Ensures it doesn't block objects behind
      depthWrite: false, // Prevents writing to the depth buffer
      depthTest: false,  // Prevents occlusion
      side: this.THREE.DoubleSide, // Ensures it's invisible from both sides
      fragmentShader: `
        void main() {
            gl_FragColor = vec4(0.0); // Transparent black
        }
      `,
      vertexShader: `
        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
    });

    this.plattegrondMaterial = shaderMaterial("#505051")
    this.transparentMaterial = new this.THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0})

    function toDecimalString(num) {
      // Check if the number has any decimal part
      if (Number.isInteger(num)) {
        // If it's an integer, format it with one decimal place
        return num.toFixed(1);
      } else {
        // If it already has a decimal part, return it as a string without changing it
        return num.toString();
      }
    }
    // Materiaal aanmaken
    const vertexShader = `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
    `
    const fragmentShader = `
      precision highp float;
      varying vec2 vUv;
      void main() {
        if (mod(vUv.x, ${toDecimalString(mapStippellijnLengte*2)}) < ${toDecimalString(mapStippellijnLengte)}) {
            gl_FragColor = vec4(${this.mapColor.r}, ${this.mapColor.g}, ${this.mapColor.b}, 1.0);
        } else {
            gl_FragColor = vec4(1.0, 0.0, 0.0, 0.0);
        }
      }
    `
    this.mapStippellijnMaterial = new THREE.ShaderMaterial({vertexShader, fragmentShader, transparent: true});
    this.lichtstraatLijnDikte = 10

    // this.cube = new this.THREE.Mesh(
    //   new this.THREE.BoxGeometry(500, 1000, 500),
    //   new this.THREE.MeshBasicMaterial({ color: 0x00ff00 })
    // )
    // this.cube.position.set(this.z/2, this.verticalePositie, this.x/2)
    // this.group.add(this.cube)
    // this.mapCube = new this.THREE.Mesh(
    //   new this.THREE.BoxGeometry(500, 1000, 500),
    //   new this.THREE.MeshBasicMaterial({ color: 0x00ff00 })
    // )
    // this.mapCube.position.set(this.z/2, this.verticalePositie, this.x/2)
    // this.mapGroup.add(this.mapCube)

    this.update(modelType, x, y, z, verticalePositie, staanderDikte, dakbeschotDikte, gy, gz)

    if (lichtstratenFromConfig) {
      this.loadConfig(lichtstratenFromConfig)
    }
  }

  async loadConfig (lichtstratenFromConfig) {
    const allLichtstraten = store.getters.getOptions
    for (const lichtstraat of lichtstratenFromConfig) {
      const foundLichtstraat = allLichtstraten.find(l => l.code === lichtstraat.code)
      await this.addLichtstraat({lichtstraat: foundLichtstraat, color: {code: lichtstraat.code}})
      this.setSelectedLichtstraatNewDimensionValue (lichtstraat.positionFront, this.lichtstraten[this.lichtstraten.length-1], 'voor')
      this.setSelectedLichtstraatNewDimensionValue (lichtstraat.positionLeft, this.lichtstraten[this.lichtstraten.length-1], 'links')
      this.updateLichtstraatMaatlijnen(this.lichtstraten[this.lichtstraten.length-1])
    }
  }

  asyncLoadGLTF (url) {
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(url, resolve, undefined, reject)
    })
  }

  asyncLoadTexture (url) {
    return new Promise((resolve, reject) => {
      this.textureLoader.load(url, resolve, undefined, reject)
    })
  }

  traverseMesh (mainObject, newTexture) {
    const forEachMaterial = (material, newTexture) => {
      if (material.map) {
        const repeatX = material.map.repeat.x
        const repeatY = material.map.repeat.y
        const rotation = material.map.rotation
        material.map = newTexture
        material.map.needsUpdate = true;
        material.needsUpdate = true;
        material.metalness = 0
        material.roughness = 1
        material.normalScale = new this.THREE.Vector2(1, 1)
        material.normalScale._alphaTest = 0
        this.textureSettings(material.map, repeatX, repeatY, rotation)
      }
    }

    mainObject.traverse((object) => {
      if (object instanceof this.THREE.Mesh) {
        object.castShadow = true
        object.receiveShadow = true
      }
      if (object.material) {
        if (Array.isArray(object.material)) {
          object.material.forEach(m => forEachMaterial(m, newTexture))
        } else {
          forEachMaterial(object.material, newTexture)
        }
      }
    })
  }

  update (modelType, x, y, z, verticalePositie, staanderDikte, dakbeschotDikte, gy, gz) {
    this.modelType = modelType
    this.x = x
    this.y = y
    this.z = z
    this.verticalePositie = verticalePositie
    this.staanderDikte = staanderDikte
    this.dakbeschotDikte = dakbeschotDikte
    this.gy = gy
    this.gz = gz

    // // loop through all lichtstraten and update their y position
    // if (this.modelType.isPlatdak || this.modelType.isVeranda) {
    //   this.lichtstraten.forEach(lichtstraat => {
    //     lichtstraat.model.position.y = this.verticalePositie + lichtstraat.dimensions.yo
    //     lichtstraat.hitbox.position.y = lichtstraat.model.position.y
    //   })
    // } else {
    //   // remove all lichtstraten
    //   this.lichtstraten.forEach(lichtstraat => {
    //     this.modelGroup.remove(lichtstraat.model)
    //     this.group.remove(lichtstraat.hitbox)
    //     this.mapGroup.remove(lichtstraat.map)
    //   })
    //   this.lichtstraten = []
    // }

    // remove all lichtstraten
    for (const lichtstraat of this.lichtstraten) {
      this.selectedLichtstraat = lichtstraat
      this.removeSelectedLichtstraat()
    }

    this.validateLichtstratenPlacement()
  }

  async addLichtstraat ({lichtstraat, color}) {
    if (!this.modelType.isPlatdak && !this.modelType.isVeranda) return
    const dimensions = lichtstraat.dimensions
    const margins = lichtstraat.margins

    const loadedObjects = await Promise.all([
      this.asyncLoadGLTF(`${import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}/lichtstraten/${color.code}.glb`),
      this.asyncLoadGLTF(`${import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER}${import.meta.env.VITE_VUE_APP_MODELS_LOCATION}/lichtstraten/${color.code}li.glb`),
      this.asyncLoadTexture(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.textureFrame}`)
    ])
    const bovenprofiel = loadedObjects[0].scene
    const frame = loadedObjects[1].scene
    const loadedTexture = loadedObjects[2]

    bovenprofiel.scale.multiplyScalar(1000)
    frame.scale.multiplyScalar(1000)
    this.traverseMesh(frame, loadedTexture)

    // cereate convex geometry
    const vertices = [];
    const addVerticesFromObject = (object) => {
      object.traverse((child) => {
        if (child.isMesh) { // Check if the child is a mesh
          const geometry = child.geometry;
          if (geometry.isBufferGeometry) {
            const position = geometry.attributes.position;
            for (let i = 0; i < position.count; i++) {
              const vertex = new this.THREE.Vector3(
                position.getX(i),
                position.getY(i),
                position.getZ(i)
              );
              // Apply the mesh's world transformation to the vertex
              child.localToWorld(vertex);
              // vertex.multiplyScalar(1.01)
              vertices.push(vertex);
            }
          }
        }
      });
    }
    addVerticesFromObject(bovenprofiel)
    addVerticesFromObject(frame)
    // move all vertices with the lowest position lower by 50 (margin by 1)
    const lowestX = Math.min(...vertices.map(v => v.x))
    vertices.forEach(v => {if (Math.abs(v.x - lowestX) < 1) {v.x -= 2}})
    const highestX = Math.max(...vertices.map(v => v.x))
    vertices.forEach(v => {if (Math.abs(v.x - highestX) < 1) {v.x += 2}})

    // limit bottom of mesh, so that the selection does not go below the bedekking
    const spaceBetweenBedekkingAndOrigin = (dimensions.yo - this.dakbeschotDikte-10)
    vertices.forEach(v => {if (v.y < -spaceBetweenBedekkingAndOrigin) {v.y = -spaceBetweenBedekkingAndOrigin}})
    // move the bottom vertices of the negx side to the dakbeddeking level
    const lowestXNew = Math.min(...vertices.map(v => v.x))
    const allLowestXNew = vertices.filter(v => Math.abs(v.x - lowestXNew) < 10)
    const lowestXNewlowestY = Math.min(...allLowestXNew.map(v => v.y))
    vertices.forEach(v => {if (Math.abs(v.x - lowestXNew) < 10 && Math.abs(v.y - lowestXNewlowestY) < 10) {v.y = -spaceBetweenBedekkingAndOrigin}})
    // move the bottom vertices of the posx side to the dakbeddeking level
    const highestXNew = Math.max(...vertices.map(v => v.x))
    const allHighestXNew = vertices.filter(v => Math.abs(v.x - highestXNew) < 10)
    const highestXNewlowestY = Math.min(...allHighestXNew.map(v => v.y))
    vertices.forEach(v => {if (Math.abs(v.x - highestXNew) < 10 && Math.abs(v.y - highestXNewlowestY) < 10) {v.y = -spaceBetweenBedekkingAndOrigin}})

    const lowestZ = Math.min(...vertices.map(v => v.z))
    vertices.forEach(v => {if (Math.abs(v.z - lowestZ) < 10) {v.z -= 2}})
    const highestY = Math.max(...vertices.map(v => v.y))
    vertices.forEach(v => {if (Math.abs(v.y - highestY) < 1) {v.y += 2}})
    const highestZ = Math.max(...vertices.map(v => v.z))
    vertices.forEach(v => {if (Math.abs(v.z - highestZ) < 10) {v.z += 2}})
    const convexGeometry = new ConvexGeometry(vertices);
    convexGeometry.translate(-1, 0, -1)

    const convexMesh = new this.THREE.Mesh(convexGeometry, this.convexInvisibleMaterial);
    convexMesh.name = "lichtstraatHitbox"
    convexMesh.visible = true

    const mapElements = new this.THREE.Group()
    const mapOuterBorderGeometry = new this.THREE.BufferGeometry()
    mapOuterBorderGeometry.setAttribute( 'position', new this.THREE.BufferAttribute(
      new Float32Array(planeBorderBuffer.positionBuffer(
        dimensions.x+margins.xNeg1+margins.xPos1,
        dimensions.z+margins.zNeg1+margins.zPos1,
        this.lichtstraatLijnDikte
      )), 3))
    mapOuterBorderGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(planeBorderBuffer.uvBuffer()), 2))
    mapOuterBorderGeometry.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(planeBorderBuffer.normalBuffer()), 3))
    mapOuterBorderGeometry.groups = planeBorderBuffer.groupBuffer()
    const mapOuterBorder = new this.THREE.Mesh(mapOuterBorderGeometry, [this.plattegrondMaterial, this.transparentMaterial])
    mapElements.add(mapOuterBorder)
    // breedte
    const innerBreedte = dimensions.x-margins.xNeg2-margins.xPos2
    const innerBorderGeometryX = new this.THREE.BufferGeometry()
    innerBorderGeometryX.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(innerBreedte, this.lichtstraatLijnDikte)), 3));
    innerBorderGeometryX.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer(innerBreedte, this.lichtstraatLijnDikte)), 2));
    innerBorderGeometryX.setIndex(planeBuffer.indexBuffer());
    innerBorderGeometryX.computeVertexNormals();
    innerBorderGeometryX.computeBoundingSphere();
    innerBorderGeometryX.translate(0, 0, -this.lichtstraatLijnDikte)
    const innerBorderXAchter = new this.THREE.Mesh(innerBorderGeometryX, this.mapStippellijnMaterial)
    innerBorderXAchter.position.set(dimensions.z+margins.zNeg1-margins.zPos2, 0, dimensions.x+margins.xNeg1-margins.xPos2)
    innerBorderXAchter.rotation.y = Math.PI/2
    const innerBorderXVoor = new this.THREE.Mesh(innerBorderGeometryX, this.mapStippellijnMaterial)
    innerBorderXVoor.position.set(margins.zNeg1+margins.zNeg2, 0, margins.xNeg1+margins.xNeg2)
    innerBorderXVoor.rotation.y = -Math.PI/2
    // diepte
    const innerDiepte = dimensions.z-margins.zNeg2-margins.zPos2
    const innerBorderGeometryZ = new this.THREE.BufferGeometry()
    innerBorderGeometryZ.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(innerDiepte, this.lichtstraatLijnDikte)), 3));
    innerBorderGeometryZ.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer(innerDiepte, this.lichtstraatLijnDikte)), 2));
    innerBorderGeometryZ.setIndex(planeBuffer.indexBuffer());
    innerBorderGeometryZ.computeVertexNormals();
    innerBorderGeometryZ.computeBoundingSphere();
    innerBorderGeometryZ.translate(0, 0, -this.lichtstraatLijnDikte)
    const innerBorderZLinks = new this.THREE.Mesh(innerBorderGeometryZ, this.mapStippellijnMaterial)
    innerBorderZLinks.position.set(dimensions.z+margins.zNeg1-margins.zPos2, 0, margins.xNeg1+margins.xNeg2)
    innerBorderZLinks.rotation.y = Math.PI
    const innerBorderZRechts = new this.THREE.Mesh(innerBorderGeometryZ, this.mapStippellijnMaterial)
    innerBorderZRechts.position.set(margins.zNeg1+margins.zNeg2, 0, dimensions.x+margins.xNeg1-margins.xPos2)
    // add inner lines to group
    mapElements.add(innerBorderXAchter, innerBorderZLinks, innerBorderXVoor, innerBorderZRechts)
    // diagonal
    const lijnRotatie = Math.atan(innerBreedte/innerDiepte)
    const offset = Math.tan(lijnRotatie)*this.lichtstraatLijnDikte/2
    const innerBorderGeometryDiagonal = new this.THREE.BufferGeometry()
    innerBorderGeometryDiagonal.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(Math.sqrt((innerBreedte)**2+(innerDiepte)**2)-offset*2, this.lichtstraatLijnDikte)), 3));
    innerBorderGeometryDiagonal.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer(Math.sqrt((innerBreedte)**2+(innerDiepte)**2)-offset*2-this.lichtstraatLijnDikte, this.lichtstraatLijnDikte)), 2));
    innerBorderGeometryDiagonal.setIndex(planeBuffer.indexBuffer());
    innerBorderGeometryDiagonal.computeVertexNormals();
    innerBorderGeometryDiagonal.computeBoundingSphere();
    innerBorderGeometryDiagonal.translate(offset, 0, -this.lichtstraatLijnDikte/2)
    const innerBorderDiagonal1 = new this.THREE.Mesh(innerBorderGeometryDiagonal, this.mapStippellijnMaterial)
    innerBorderDiagonal1.position.set(margins.zNeg1+margins.zNeg2, 0, margins.xNeg1+margins.xNeg2)
    innerBorderDiagonal1.rotation.y = -lijnRotatie
    const innerBorderDiagonal2 = new this.THREE.Mesh(innerBorderGeometryDiagonal, this.mapStippellijnMaterial)
    innerBorderDiagonal2.position.set(margins.zNeg1+margins.zNeg2, 0, dimensions.x+margins.xNeg1-margins.xPos2)
    innerBorderDiagonal2.rotation.y = lijnRotatie
    mapElements.add(innerBorderDiagonal1, innerBorderDiagonal2)
    // maatlijnen
    // const textGeometry = new TextGeometry("teststring", {font: this.font, size: 125, height: 1})
    // const textMaterial = shaderMaterial(this.lineColor)
    // const textMesh = new this.THREE.Mesh(textGeometry, textMaterial)
    // textMesh.rotation.x = -Math.PI/2
    // mapElements.add(textMesh)
    const maatlijnVoor = new SimpleMaatlijn(this.THREE, this.font, (this.z/2-(dimensions.z+margins.zNeg1+margins.zPos1)/2)+margins.zNeg1+margins.zNeg2-this.staanderDikte, this.lineColor, this.linecolorSelected, this.lichtstraatLijnDikte, false, 'voor')
    maatlijnVoor.group.position.z = dimensions.x/2+margins.xNeg1
    maatlijnVoor.group.position.x = margins.zNeg1+margins.zNeg2
    maatlijnVoor.group.rotation.y = -Math.PI/2
    const maatlijnAchter = new SimpleMaatlijn(this.THREE, this.font, this.z-this.staanderDikte-(this.z/2-(dimensions.z+margins.zNeg1+margins.zPos1)/2)-(dimensions.z+margins.zNeg1-margins.zPos2), this.lineColor, this.linecolorSelected, this.lichtstraatLijnDikte, true, 'achter')
    maatlijnAchter.group.position.z = dimensions.x/2+margins.xNeg1
    maatlijnAchter.group.position.x = dimensions.z+margins.zNeg1-margins.zPos2
    maatlijnAchter.group.rotation.y = -Math.PI/2
    const maatlijnLinks = new SimpleMaatlijn(this.THREE, this.font, this.x/2-dimensions.x/2-margins.xNeg1+margins.xNeg2-this.staanderDikte, this.lineColor, this.linecolorSelected, this.lichtstraatLijnDikte, true, 'links')
    maatlijnLinks.group.position.x = dimensions.z/2+margins.zNeg1 // moet met index
    maatlijnLinks.group.position.z = margins.xNeg2
    const maatlijnRechts = new SimpleMaatlijn(this.THREE, this.font, this.x/2-dimensions.x/2-margins.xNeg1+margins.xNeg2-this.staanderDikte, this.lineColor, this.linecolorSelected, this.lichtstraatLijnDikte, false, 'rechts')
    maatlijnRechts.group.position.x = dimensions.z/2+margins.zNeg1 // moet met index
    maatlijnRechts.group.position.z = dimensions.x+margins.xNeg1-margins.xNeg2
    mapElements.add(maatlijnVoor.group, maatlijnAchter.group, maatlijnLinks.group, maatlijnRechts.group)

    // ringbalk om de lichtstraat
    // ringbalk x
    // const ringbalkXGeometry = new this.THREE.BoxGeometry(this.gz, this.gy, dimensions.x-margins.xNeg2-margins.xPos2+this.gz*2)
    const ringbalkXGeometry = new this.THREE.BufferGeometry();
    ringbalkXGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(positionBuffer(dimensions.x-margins.xNeg2-margins.xPos2+this.gz*2, this.gy, this.gz)), 3));
    ringbalkXGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(uvBuffer(dimensions.x-margins.xNeg2-margins.xPos2+this.gz*2, this.gy, this.gz)), 2));
    ringbalkXGeometry.setIndex(indexBuffer());
    ringbalkXGeometry.computeVertexNormals();
    ringbalkXGeometry.computeBoundingSphere();
    ringbalkXGeometry.groups = groupBuffer();
    const ringbalkXMap = loadedTexture.clone()
    this.textureSettings(ringbalkXMap, 1/2300, 1/145, 0)
    const ringbalkXMaterial = new this.THREE.MeshStandardMaterial({map: ringbalkXMap})
    const ringbalkVoor = new this.THREE.Mesh(ringbalkXGeometry, ringbalkXMaterial)
    ringbalkVoor.castShadow = true
    ringbalkVoor.receiveShadow = true
    ringbalkVoor.position.set(-this.gz/2+margins.zNeg1+margins.zNeg2, -dimensions.yo-this.gy/2+1, dimensions.x/2+margins.xNeg1)
    ringbalkVoor.rotation.y = Math.PI/2
    const rinbalkAchter = ringbalkVoor.clone()
    rinbalkAchter.position.x = dimensions.z+this.gz/2+margins.zNeg1-margins.zPos2
    // ringbalk z
    // const ringbalkZGeometry = new this.THREE.BoxGeometry(dimensions.z-margins.zNeg2-margins.zPos2, this.gy, this.gz)
    const ringbalkZGeometry = new this.THREE.BufferGeometry();
    ringbalkZGeometry.setAttribute('position', new this.THREE.BufferAttribute(new Float32Array(positionBuffer(dimensions.z-margins.zNeg2-margins.zPos2, this.gy, this.gz)), 3));
    ringbalkZGeometry.setAttribute('uv', new this.THREE.BufferAttribute(new Float32Array(uvBuffer(dimensions.z-margins.zNeg2-margins.zPos2, this.gy, this.gz)), 2));
    ringbalkZGeometry.setIndex(indexBuffer());
    ringbalkZGeometry.computeVertexNormals();
    ringbalkZGeometry.computeBoundingSphere();
    ringbalkZGeometry.groups = groupBuffer();
    const ringbalkZMap = loadedTexture.clone()
    this.textureSettings(ringbalkZMap, 1/2300, 1/145, 0)
    const ringbalkZMaterial = new this.THREE.MeshStandardMaterial({map: ringbalkZMap})
    const ringbalkLinks = new this.THREE.Mesh(ringbalkZGeometry, ringbalkZMaterial)
    ringbalkLinks.castShadow = true
    ringbalkLinks.receiveShadow = true
    ringbalkLinks.position.set((dimensions.z-margins.zNeg2-margins.zPos2)/2+margins.zNeg1+margins.zNeg2, -dimensions.yo-this.gy/2+1, -this.gz/2+margins.xNeg2)
    const ringbalkRechts = ringbalkLinks.clone()
    ringbalkRechts.position.z = dimensions.x+this.gz/2-margins.xPos2

    const lichtstraatGroup = new this.THREE.Group()
    lichtstraatGroup.add(bovenprofiel, frame, ringbalkVoor, rinbalkAchter, ringbalkLinks, ringbalkRechts)
    this.lichtstraten.push({
      hitbox: convexMesh,
      model: lichtstraatGroup,
      map: mapElements,
      dimensions: dimensions,
      margins: margins,
      code: color.code,
      maatlijnen: {
        voor: maatlijnVoor,
        achter: maatlijnAchter,
        links: maatlijnLinks,
        rechts: maatlijnRechts
      },
      frame,
      ringbalkXMaterial,
      ringbalkZMaterial
    })
    lichtstraatGroup.position.set(this.z/2-(dimensions.z+margins.zNeg1+margins.zPos1)/2, this.verticalePositie + dimensions.yo, this.x/2-dimensions.x/2-margins.xNeg1)
    lichtstraatGroup.position.x = this.z/2 - (dimensions.z+margins.zNeg1+margins.zPos1)/2 + ((margins.zPos1+margins.zPos2)-(margins.zNeg1+margins.zNeg2))/2
    convexMesh.position.copy(lichtstraatGroup.position)
    mapElements.position.copy(lichtstraatGroup.position)
    this.group.add(convexMesh)
    this.modelGroup.add(lichtstraatGroup)
    this.mapGroup.add(mapElements)

    if (this.selectedLichtstraat) {
      lichtstraatGroup.position.copy(this.selectedLichtstraat.model.position)
      convexMesh.position.copy(this.selectedLichtstraat.model.position)
      mapElements.position.copy(this.selectedLichtstraat.model.position)
      this.removeSelectedLichtstraat()
      store.commit('setSelectedLichtstraat', {lichtstraat: convexMesh})
    }

    store.dispatch('updateIntersectingObjects')
    this.validateLichtstratenPlacement()
    this.updateMapMaatLijnen()
  }

  removeSelectedLichtstraat () {
    if (!this.selectedLichtstraat) return
    this.modelGroup.remove(this.selectedLichtstraat.model)
    this.group.remove(this.selectedLichtstraat.hitbox)
    this.mapGroup.remove(this.selectedLichtstraat.map)
    this.lichtstraten = this.lichtstraten.filter(l => l !== this.selectedLichtstraat)
    this.selectedLichtstraat = null
    this.validateLichtstratenPlacement()
    store.dispatch('updateIntersectingObjects')
  }

  updateLichtstratenInConfig () {
    const lichtstratenforconfig = []
    for (const lichtstraat of this.lichtstraten) {
      const margins = lichtstraat.margins
      const model = lichtstraat.model
      const dimensionVoor = (model.position.x)+margins.zNeg1+margins.zNeg2-this.staanderDikte
      const dimensionLinks = model.position.z+margins.xNeg1+margins.xNeg2-this.staanderDikte
      lichtstratenforconfig.push({
        code: lichtstraat.code,
        positionFront: dimensionVoor,
        positionLeft:  dimensionLinks
      })
    }
    store.commit('setLichtstratenInConfig', lichtstratenforconfig)
  }

  setCurrentIntersectingLichtstraat (lichtstraat) {
    if (!lichtstraat) {
      this.currentIntersectingLichtstraat = null
      return
    }
    this.currentIntersectingLichtstraat = this.lichtstraten.find(l => l.hitbox.uuid === lichtstraat.uuid)
  }

  setSelectedLichtstraat ({lichtstraat, planePosition}) {
    if (lichtstraat) {
      this.selectedLichtstraat = this.lichtstraten.find(l => l.hitbox.uuid === lichtstraat.uuid)
      store.commit('setSelectedLichtstraatCode', this.selectedLichtstraat.code)
    } else {
      this.selectedLichtstraat = null
      store.commit('setSelectedLichtstraatCode', null)
    }
    if (!this.selectedLichtstraat) return
    this.lichtstraatStartPositionOffset = planePosition.sub(this.selectedLichtstraat.model.position)
  }

  setCurrentIntersectingLichtstraatDimension () {
    this.setMaatlijnHoverColor()
  }

  setSelectedLichtstraatDimension (lichtstraatDimension) {
    this.setMaatlijnHoverColor()

    if (!lichtstraatDimension) return
    let foundLichtstraat
    const side = lichtstraatDimension.userData.side
    for (const lichtstraat of this.lichtstraten) {
      if (lichtstraat.map.uuid === lichtstraatDimension.parent.parent.uuid) {
        foundLichtstraat = lichtstraat
        break
      }
    }

    if (!foundLichtstraat) return

    const dimensions = foundLichtstraat.dimensions
    const margins = foundLichtstraat.margins
    const model = foundLichtstraat.model

    const dimensionVoor = (model.position.x)+margins.zNeg1+margins.zNeg2-this.staanderDikte
    const dimensionAchter = this.z-this.staanderDikte-(model.position.x)-(dimensions.z+margins.zNeg1-margins.zPos2)
    const dimensionLinks = model.position.z+margins.xNeg1+margins.xNeg2-this.staanderDikte
    const dimensionRechts = this.x-(model.position.z+dimensions.x+margins.xNeg1-margins.xPos2+this.staanderDikte)

    let value
    switch (side) {
      case 'voor':
        value = {
          value: dimensionVoor,
          max: dimensionVoor+dimensionAchter
        }
        break
      case 'achter':
        value = {
          value: dimensionAchter,
          max: dimensionAchter+dimensionVoor
        }
        break
      case 'links':
        value = {
          value: dimensionLinks,
          max: dimensionLinks+dimensionRechts
        }
        break
      case 'rechts':
        value = {
          value: dimensionRechts,
          max: dimensionRechts+dimensionLinks
        }
        break
    }
    store.commit('setSelectedLichtstraatDimensionValue', value)
  }

  setMaatlijnHoverColor () {
    const intersectingLichtstraatdimension = store.getters.getCurrentIntersectingLichtstraatDimension
    const selectedLichtstraatDimension = store.getters.getSelectedLichtstraatDimension
    const intersectingSide = intersectingLichtstraatdimension?.userData?.side
    const selectedSide = selectedLichtstraatDimension?.userData?.side
    let intersectingLichtstraat, selectedLichtstraat
    for (const lichtstraat of this.lichtstraten) {
      if (lichtstraat.map.uuid === intersectingLichtstraatdimension?.parent?.parent?.uuid) {
        intersectingLichtstraat = lichtstraat
      }
      if (lichtstraat.map.uuid === selectedLichtstraatDimension?.parent?.parent?.uuid) {
        selectedLichtstraat = lichtstraat
      }
    }

    for (const lichtstraat of this.lichtstraten) {
      for (const currentSide of ['voor', 'achter', 'links', 'rechts']) {
        lichtstraat.maatlijnen[currentSide].setSelected(
          (currentSide === intersectingSide && lichtstraat === intersectingLichtstraat) ||
          (currentSide === selectedSide && lichtstraat === selectedLichtstraat)
        )
      }
    }
  }

  setSelectedLichtstraatNewDimensionValue (value, providedLichtstraat, providedSide) {
    let foundLichtstraat, side, lichtstraatDimension
    if (!providedLichtstraat || !providedSide) {
      lichtstraatDimension = store.getters.getSelectedLichtstraatDimension
      side = lichtstraatDimension.userData.side
      for (const lichtstraat of this.lichtstraten) {
        if (lichtstraat.map.uuid === lichtstraatDimension.parent.parent.uuid) {
          foundLichtstraat = lichtstraat
          break
        }
      }
      if (!foundLichtstraat) return
    } else {
      foundLichtstraat = providedLichtstraat
      side = providedSide
      lichtstraatDimension = foundLichtstraat.maatlijnen[side].group.children[0]
    }

    const dimensions = foundLichtstraat.dimensions
    const margins = foundLichtstraat.margins

    switch(side) {
      case 'voor':
        foundLichtstraat.model.position.x = this.staanderDikte + value - margins.zNeg1 - margins.zNeg2
        break
      case 'achter':
        foundLichtstraat.model.position.x = this.z-this.staanderDikte-value-dimensions.z-margins.zNeg1+margins.zPos2
        break
      case 'links':
        foundLichtstraat.model.position.z = this.staanderDikte + value - margins.xNeg1 - margins.xNeg2
        break
      case 'rechts':
        foundLichtstraat.model.position.z = this.x-this.staanderDikte-value-dimensions.x-margins.xNeg1+margins.xPos2
        break
    }
    foundLichtstraat.hitbox.position.copy(foundLichtstraat.model.position)
    foundLichtstraat.map.position.copy(foundLichtstraat.model.position)
    this.validateLichtstratenPlacement()
    this.updateLichtstraatMaatlijnen(foundLichtstraat)
    this.setSelectedLichtstraatDimension(lichtstraatDimension)
  }

  updateLichtstraatPosition ({planePosition}) {
    if (!this.selectedLichtstraat) return
    let xPosition = planePosition.x - this.lichtstraatStartPositionOffset.x
    let zPosition = planePosition.z - this.lichtstraatStartPositionOffset.z

    const dimensions = this.selectedLichtstraat.dimensions
    const margins = this.selectedLichtstraat.margins

    // push all boundaries in an array
    const lichtstratenBoundaries = []
    for (const lichtstraat of this.lichtstraten) {
      if (lichtstraat === this.selectedLichtstraat) continue
      lichtstratenBoundaries.push({
        minX: lichtstraat.model.position.x + lichtstraat.margins.zNeg1 + lichtstraat.margins.zNeg2,
        maxX: lichtstraat.model.position.x + lichtstraat.dimensions.z + lichtstraat.margins.zNeg1 - lichtstraat.margins.zPos2,
        minZ: lichtstraat.model.position.z + lichtstraat.margins.xNeg1 + lichtstraat.margins.xNeg2,
        maxZ: lichtstraat.model.position.z + lichtstraat.dimensions.x + lichtstraat.margins.xNeg1 - lichtstraat.margins.xPos2
      })
    }
    let otherBoundaryZPosition =  zPosition // new position to not enter boundary of another lichtstraat for z axis
    let otherBoundaryXPosition =  xPosition // new position to not enter boundary of another lichtstraat for x axis
    let overlappingLichtstraatX = null // lichtstraat that is overlapping on x axis
    let overlappingLichtstraatZ = null // lichtstraat that is overlapping on z axis
    for (let i = 0; i < lichtstratenBoundaries.length; i++) {
      const isOverlappingOnX = xPosition + margins.zNeg1 + margins.zNeg2 - insideMargin < lichtstratenBoundaries[i].maxX && xPosition + dimensions.z + margins.zNeg1 - margins.zPos2 + insideMargin > lichtstratenBoundaries[i].minX
      if (isOverlappingOnX) {
        if (Math.abs(lichtstratenBoundaries[i].minZ - (zPosition + dimensions.x + margins.xNeg1 - margins.xPos2)) < insideMargin) {
          otherBoundaryZPosition = lichtstratenBoundaries[i].minZ - dimensions.x - margins.xNeg1 + margins.xPos2 - insideMargin
          overlappingLichtstraatX = lichtstratenBoundaries[i]
        }
        if (Math.abs(lichtstratenBoundaries[i].maxZ - (zPosition + margins.xNeg1 + margins.xNeg2)) < insideMargin) {
          otherBoundaryZPosition = lichtstratenBoundaries[i].maxZ - margins.xNeg1 - margins.xNeg2 + insideMargin
          overlappingLichtstraatX = lichtstratenBoundaries[i]
        }
      }
      const isOverlappingOnZ = zPosition + margins.xNeg1 + margins.xNeg2 - insideMargin < lichtstratenBoundaries[i].maxZ && zPosition + dimensions.x + margins.xNeg1 - margins.xPos2 + insideMargin > lichtstratenBoundaries[i].minZ
      if (isOverlappingOnZ) {
        if (Math.abs(lichtstratenBoundaries[i].minX - (xPosition + dimensions.z + margins.zNeg1 - margins.zPos2)) < insideMargin) {
          otherBoundaryXPosition = lichtstratenBoundaries[i].minX - dimensions.z - margins.zNeg1 + margins.zPos2 - insideMargin
          overlappingLichtstraatZ = lichtstratenBoundaries[i]
        }
        if (Math.abs(lichtstratenBoundaries[i].maxX - (xPosition + margins.zNeg1 + margins.zNeg2)) < insideMargin) {
          otherBoundaryXPosition = lichtstratenBoundaries[i].maxX - margins.zNeg1 - margins.zNeg2 + insideMargin
          overlappingLichtstraatZ = lichtstratenBoundaries[i]
        }
      }
    }

    const xDiff = Math.abs(otherBoundaryXPosition - xPosition)
    const zDiff = Math.abs(otherBoundaryZPosition - zPosition)
    // check if overlapping lichtstraatGroup is the same for x and z axis
    // if it is the same the corners are a bit more complicated, so it only has to update 1 axis if it has a smaller difference
    if (overlappingLichtstraatX === overlappingLichtstraatZ) {
      if (xDiff && !zDiff) { // only x axis changed
        xPosition = otherBoundaryXPosition
      } else if (zDiff && !xDiff) { // only z axis changed
        zPosition = otherBoundaryZPosition
      } else if (xDiff && zDiff) {
        if (xDiff < zDiff) { // x axis difference is less, so closer, so move only x axis
          xPosition = otherBoundaryXPosition
        } else if (zDiff < xDiff) { // z axis difference is less, so closer, so move only z axis
          zPosition = otherBoundaryZPosition
        } else { // both are equal, so move both (probably doesn't happen often)
          zPosition = otherBoundaryZPosition
          xPosition = otherBoundaryXPosition
        }
      }
    } else { // overlapping lichtstraat is different for x and z axis
      xPosition = otherBoundaryXPosition
      zPosition = otherBoundaryZPosition
    }

    // limit at borders
    xPosition = Math.max(
      outsideMargin-margins.zNeg1-margins.zNeg2+this.staanderDikte,
      Math.min(
        this.z-outsideMargin-dimensions.z-margins.zNeg1+margins.zPos2-this.staanderDikte,
        xPosition
      )
    )
    zPosition = Math.max(
      outsideMargin-margins.xNeg1-margins.xNeg2+this.staanderDikte,
      Math.min(
        this.x-outsideMargin-dimensions.x-margins.xNeg1+margins.xPos2-this.staanderDikte,
        zPosition
      )
    )

    this.selectedLichtstraat.model.position.x = xPosition
    this.selectedLichtstraat.model.position.z = zPosition
    this.selectedLichtstraat.hitbox.position.copy(this.selectedLichtstraat.model.position)
    this.selectedLichtstraat.map.position.copy(this.selectedLichtstraat.model.position)
    this.validateLichtstratenPlacement()
  }

  // word opgeroepen in store-frame.js en bij setLichtstraatDimension
  // is apart omdat het nogal wat performance kost
  updateMapMaatLijnen () {
    for (const lichtstraat of this.lichtstraten) {
      this.updateLichtstraatMaatlijnen(lichtstraat)
    }
  }
  updateLichtstraatMaatlijnen (lichtstraat) {
    const sortedLichtstraten = [...this.lichtstraten].sort((a, b) => a.model.position.z - b.model.position.z );
    const i = sortedLichtstraten.findIndex(l => l === lichtstraat)
    const dimensions = lichtstraat.dimensions
    const margins = lichtstraat.margins
    const maatlijnen = lichtstraat.maatlijnen
    const model = lichtstraat.model
    maatlijnen.voor.update((model.position.x)+margins.zNeg1+margins.zNeg2-this.staanderDikte)
    maatlijnen.achter.update(this.z-this.staanderDikte-(model.position.x)-(dimensions.z+margins.zNeg1-margins.zPos2))
    maatlijnen.links.update(model.position.z+margins.xNeg1+margins.xNeg2-this.staanderDikte)
    maatlijnen.links.group.position.x = dimensions.z + margins.zNeg1 + margins.zPos1 - ((i+1)*200)
    maatlijnen.rechts.update(this.x-(model.position.z+dimensions.x+margins.xNeg1-margins.xPos2+this.staanderDikte))
    maatlijnen.rechts.group.position.x = maatlijnen.links.group.position.x
  }

  validateLichtstratenPlacement () {
    let invalidLichtstraten = []
    for (const lichtstraat of this.lichtstraten) {
      lichtstraat.hitbox.material = this.convexInvisibleMaterial
    }
    for (const lichtstraat of this.lichtstraten) {
      const minX = lichtstraat.model.position.x + lichtstraat.margins.zNeg1 + lichtstraat.margins.zNeg2
      const maxX = lichtstraat.model.position.x + lichtstraat.dimensions.z + lichtstraat.margins.zNeg1 - lichtstraat.margins.zPos2
      const minZ = lichtstraat.model.position.z + lichtstraat.margins.xNeg1 + lichtstraat.margins.xNeg2
      const maxZ = lichtstraat.model.position.z + lichtstraat.dimensions.x + lichtstraat.margins.xNeg1 - lichtstraat.margins.xPos2
      for (const otherLichtstraat of this.lichtstraten) {
        if (lichtstraat === otherLichtstraat) continue
        const otherMinX = otherLichtstraat.model.position.x + otherLichtstraat.margins.zNeg1 + otherLichtstraat.margins.zNeg2
        const otherMaxX = otherLichtstraat.model.position.x + otherLichtstraat.dimensions.z + otherLichtstraat.margins.zNeg1 - otherLichtstraat.margins.zPos2
        const otherMinZ = otherLichtstraat.model.position.z + otherLichtstraat.margins.xNeg1 + otherLichtstraat.margins.xNeg2
        const otherMaxZ = otherLichtstraat.model.position.z + otherLichtstraat.dimensions.x + otherLichtstraat.margins.xNeg1 - otherLichtstraat.margins.xPos2
        /*console.log(
          "rode gloed?",
          this.lichtstraten.findIndex(l => l === lichtstraat),
          this.lichtstraten.findIndex(l => l === otherLichtstraat),
          minX - insideMargin < otherMaxX,
          maxX + insideMargin > otherMinX,
          minZ - insideMargin < otherMaxZ,
          maxZ + insideMargin > otherMinZ,
          "-------",
          minZ-insideMargin, otherMaxZ
        )*/
        if (minX - insideMargin < otherMaxX && maxX + insideMargin > otherMinX && minZ - insideMargin < otherMaxZ && maxZ + insideMargin > otherMinZ) {
          lichtstraat.hitbox.material = this.convexErrorMaterial
          invalidLichtstraten.push(lichtstraat.hitbox.uuid)
        }
      }
    }
    // remove duplicate strings
    invalidLichtstraten = invalidLichtstraten.filter((item, index) => invalidLichtstraten.indexOf(item) === index)
    store.commit('setInvalidLichtstraten', invalidLichtstraten)
    this.updateLichtstratenInConfig()
  }

  async updateFrameTexture (textureFrame) {
    this.textureFrame = textureFrame
    const loadedTexture = await this.asyncLoadTexture(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.textureFrame}`)
    for (const lichtstraat of this.lichtstraten) {
      // update frame texture
      this.traverseMesh(lichtstraat.frame, loadedTexture.clone())

      // update ringbalk map
      const ringbalkMapX = loadedTexture.clone()
      this.textureSettings(ringbalkMapX, lichtstraat.ringbalkXMaterial.map.repeat.x, lichtstraat.ringbalkXMaterial.map.repeat.y, 0)
      const ringbalkZMap = loadedTexture.clone()
      this.textureSettings(ringbalkZMap, lichtstraat.ringbalkZMaterial.map.repeat.x, lichtstraat.ringbalkZMaterial.map.repeat.y, 0)
      lichtstraat.ringbalkXMaterial.map = ringbalkMapX
      lichtstraat.ringbalkXMaterial.map.needsUpdate = true
      lichtstraat.ringbalkXMaterial.needsUpdate = true
      lichtstraat.ringbalkZMaterial.map = ringbalkZMap
      lichtstraat.ringbalkZMaterial.map.needsUpdate = true
      lichtstraat.ringbalkZMaterial.needsUpdate = true
    }
  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    // console.log(texture)
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    // texture.offset.set(Math.random(), 0)
  }
}