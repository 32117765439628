function throwError( context, responseData, message) {
  if (responseData.errors[0].data) {
    const error = new Error(
      responseData.errors[0].data[0].message || message
    )
    
    context.dispatch('showError', responseData.errors[0].data[0].message || message)

    throw error
  } else {
    const error = new Error(
      message
    )
    
    context.dispatch('showError', message)

    throw error
  }
}

export { throwError } 