import { createStore } from 'vuex'

import messages from './store-messages'
import config from './store-config'
import frame from './store-frame'
import models from './store-models'
import user from './store-user'
import cms from './store-cms'
import tracker from './store-tracker'

export default new createStore({
  modules: {
    messages,
    config,
    frame,
    models,
    user,
    cms,
    tracker
  }
})
