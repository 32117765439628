import { createApp } from "vue"
import router from "./router"
import App from "./App.vue"
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

import store from './store/index.js'

createApp(App)
  .use(router)
  .use(store)
  .use(mdiVue, {
    icons: mdijs
  })
  .mount("#app")