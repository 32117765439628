import * as Schoor from '@/three/buffer-geometries/schoor.js'

export default class Muur {
  constructor (THREE, textureLoader, textureFrame, schoor) {
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.textureFrame = textureFrame,
    this.schoor = schoor
    this.group = null
    this.schoorWrapX = 0.0000007
    this.schoorWrapYX = 0.000025
    this.schoorWrapYZ = 0.00025
    this.bouw(THREE, textureLoader, textureFrame, schoor)
  }

  bouw (THREE, textureLoader, textureFrame, schoor) {
    let sy = schoor.sy
    let sz = schoor.sz
    let sx = schoor.sx
    let sox = schoor.sox

    // const a = sz // 400
    // const b = sy // 500
    // const c = sox // 100
    // const d = sz - sox // 300
    // const e = sx // 45

    // const coordinatesList = [
    //   new this.THREE.Vector3(0, 0, 0),
    //   new this.THREE.Vector3(0, sox, 0),
    //   new this.THREE.Vector3(sz - sox, sy, 0),
    //   new this.THREE.Vector3(sz, sy, 0),
    // ];

    // const extrudeSettings = {
    //   steps: 1,
    //   depth: sx,
    //   bevelEnabled: false,
    // };

    // console.log(new this.THREE.Shape(coordinatesList))

    // const geometry = new this.THREE.ExtrudeGeometry( new this.THREE.Shape(coordinatesList), extrudeSettings );

    // console.log(schoor)

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(Schoor.positionBuffer(sz, sy, sx, sox)), 3))
    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(Schoor.uvBuffer(sz, sy, sx, sox)), 2))
    geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(Schoor.normalBuffer()), 3))
    geometry.groups = Schoor.groupBuffer()

    const textureZ = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${textureFrame}`)
    textureZ.offset.x = 0.2
    this.textureSettings(textureZ,  Math.sqrt(sy * sy + sz * sz) * this.schoorWrapX, sox * this.schoorWrapYX, 0.25 * Math.PI)

    const materialZ = new THREE.MeshPhysicalMaterial({ map: textureZ })

    const textureY = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${textureFrame}`)
    this.textureSettings(textureY,  Math.sqrt(sy * sy + sz * sz) * this.schoorWrapX, sx * this.schoorWrapYZ)

    const materialY = new THREE.MeshPhysicalMaterial({ map: textureY })

    const schoor0 = new this.THREE.Mesh( geometry, [
      materialZ,
      materialZ,
      materialY,
      materialY,
      materialY,
      materialY,
    ]);

    schoor0.castShadow = true; 
    schoor0.receiveShadow = true;
    // schoor0.position.x = staanderGrootte.dx
    // schoor0.position.y = y - sy

    // const schoor1 = schoor0.clone()
    // schoor1.rotation.y = Math.PI
    // schoor1.position.z = sx
    // schoor1.position.x = z

    // group
    // const group = new this.THREE.Group()
    // group.add(schoor0)
    // group.add(schoor1)

    this.group = schoor0
  }

  update (schoor) {
    this.schoor = schoor

    let sy = schoor.sy
    let sz = schoor.sz
    let sx = schoor.sx
    let sox = schoor.sox

    const schoor0 = this.group

    schoor0.geometry.attributes.position.array = new Float32Array(Schoor.positionBuffer(sz, sy, sx, sox))
    schoor0.geometry.attributes.uv.array = new Float32Array(Schoor.uvBuffer(sz, sy, sx, sox))
    schoor0.geometry.attributes.position.needsUpdate = true
    schoor0.geometry.attributes.uv.needsUpdate = true

    this.textureSettings(schoor0.material[0].map, Math.sqrt(sy * sy + sz * sz) * this.schoorWrapX, sox * this.schoorWrapYX, 0.25 * Math.PI)
    schoor0.material[0].map.needsUpdate = true
    schoor0.material[0].needsUpdate = true

    this.textureSettings(schoor0.material[2].map, Math.sqrt(sy * sy + sz * sz) * this.schoorWrapX, sx * this.schoorWrapYZ)
    schoor0.material[2].map.needsUpdate = true
    schoor0.material[2].needsUpdate = true

  }

  updateTexture (textureFrame) {
    this.textureFrame = textureFrame
    let sy = this.schoor.sy
    let sz = this.schoor.sz
    let sx = this.schoor.sx
    let sox = this.schoor.sox

    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${textureFrame}`, (newTexture) => {
      const schoor0 = this.group.material[0]
      schoor0.map.dispose()
      schoor0.map = newTexture
      this.textureSettings(schoor0.map, Math.sqrt(sy * sy + sz * sz) * this.schoorWrapX, sox * this.schoorWrapYX, 0.25 * Math.PI)
      schoor0.map.needsUpdate = true;
      schoor0.needsUpdate = true;
    })

    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${textureFrame}`, (newTexture) => {
      const schoor1 = this.group.material[2]
      schoor1.map.dispose()
      schoor1.map = newTexture
      this.textureSettings(schoor1.map, Math.sqrt(sy * sy + sz * sz) * this.schoorWrapX, sx *this.schoorWrapYZ)
      schoor1.map.needsUpdate = true;
      schoor1.needsUpdate = true;
    })
  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    // texture.offset.set(Math.random(), 0)
  }
}